import { List } from '@mui/material';

import { useParticipants, useRoomHost, useVideo } from 'hooks';
import { ParticipantListItem } from './components';
import { IVideoContext } from 'context';

import styles from './ParticipantList.module.scss';

export function ParticipantList() {
  const participants = useParticipants();
  const { room }: IVideoContext = useVideo();
  const { isHost } = useRoomHost();

  return (
    <List sx={{ width: '100%', bgcolor: 'transparent' }} className={styles.patientList}>
      {isHost && <ParticipantListItem participant={room!.localParticipant} />}

      {participants.map((participant) => (
        <ParticipantListItem key={participant.sid} participant={participant} />
      ))}

      {!isHost && <ParticipantListItem participant={room!.localParticipant} />}
    </List>
  );
}
