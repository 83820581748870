export type WhiteboardItem = {
  id: string;
  type: WhiteboardItemTypeEnum;
  data: WhiteboardLineItemData | WhiteboardFigureItemData | WhiteboardTextItemData;
};

export type XY = {
  x: number;
  y: number;
};

export type WhiteboardLineItemData = {
  line: XY[];
  color: string;
  width: number;
};

export type WhiteboardFigureItemData = {
  boundaries: number[];
  color: string;
  lineWidth: number;
  figureType: WhiteboardFigureTypeEnum;
};

export type WhiteboardTextItemData = {
  x: number;
  y: number;
  width: number;
  texts: string[];
  font: string;
  size: number;
  color: string;
  isBold: boolean;
  isItalic: boolean;
  aligment: 'center' | 'left' | 'right';
};

export enum WhiteboardItemTypeEnum {
  Line = 0,
  Figure,
  Text,
}

export enum WhiteboardFigureTypeEnum {
  Rectangle = 0,
  Circle,
  Triangle,
  Pentagon,
  Hexagon,
  Hart,
  Star,
  Flower,
  Message,
  Arrow,
}
