import { useState } from 'react';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';

import { Spinner } from 'components';
import { CreateRoomForm } from './components';
import { IApiRequests, useApiRequests, useUser } from 'hooks';
import { IUserContext } from 'context';
import { useAppDispatch } from 'store';
import { getUser } from 'store/user';
import { UpdateClinicUserRequest, UpdateUserRequest } from 'types';

import styles from './RegisterSecondAccountPage.module.scss';
import image from '../../assets/images/LoginLeftImage.png';

export function RegisterSecondAccountPage() {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { enqueueSnackbar } = useSnackbar();

  const { updateClinicUser, updateProfilePicture, updateUser, skipCreateRoom, ensureRoomNameIsUnique }: IApiRequests =
    useApiRequests();

  const { registerSecondAccount }: IUserContext = useUser();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (
    profilePicture: File | null,
    profilePictureChanged: boolean,
    user: UpdateUserRequest,
    clinicUser: UpdateClinicUserRequest,
    organization: string,
  ): Promise<void> => {
    setLoading(true);

    const ensureRoomNameIsUniquePromise = (): Promise<void> => {
      return ensureRoomNameIsUnique(clinicUser.roomName ?? '').catch((error) => {
        return Promise.reject(`Register second account failed: ${error.message}`);
      });
    };

    const registerPromise = (): Promise<void> => {
      return registerSecondAccount(organization).catch((e) => Promise.reject(`Register second account failed: ${e}`));
    };

    const updateImagePromise = (): Promise<void> => {
      if (!profilePictureChanged) {
        return Promise.resolve();
      }

      return updateProfilePicture(profilePicture).catch((error) => {
        return Promise.reject(`Unable to update profile picture: ${error.message}`);
      });
    };

    const updateClinicUserPromise = (): Promise<any> => {
      return updateClinicUser(clinicUser).catch((error: any) => {
        return Promise.reject(`Update clinic user failed: ${error.message}`);
      });
    };

    const skipCreateRoomPromise = (): Promise<any> => {
      return skipCreateRoom().catch((error: any) => {
        return Promise.reject(`Update clinic user failed: ${error.message}`);
      });
    };

    const updateUserPromise = (): Promise<any> => {
      return updateUser(user).catch((error: any) => {
        return Promise.reject(`Update user failed: ${error.message}`);
      });
    };

    return ensureRoomNameIsUniquePromise()
      .then(() => registerPromise())
      .then(() => updateImagePromise())
      .then(() => updateClinicUserPromise())
      .then(() => skipCreateRoomPromise())
      .then(() => updateUserPromise())
      .then(() => dispatch(getUser()).unwrap())
      .then(() => navigate('../'))
      .catch((error) => {
        enqueueSnackbar(error, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box className={styles.registerContainer}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={classnames(styles.headerItem, styles.headerItem1)}>
            <Typography variant='body1'>1</Typography>
            <Typography variant='body4'>Profile Info</Typography>
          </div>
          <div className={classnames(styles.headerItem, styles.headerItem2)}>
            <Typography variant='body1'>2</Typography>
            <Typography variant='body4'>Create Room</Typography>
          </div>
          <div className={styles.headerItem}>
            <Typography variant='body1'>3</Typography>
            <Typography variant='body4'>Get Started</Typography>
          </div>
        </div>

        <div className={styles.body}>
          {loading && <Spinner small />}
          <Box className={styles.leftSide}>
            <img src={image} />

            <Typography variant={isDesktop ? 'h1' : 'h4'} component={isDesktop ? 'h1' : 'h4'}>
              Create Additional Business Profile
            </Typography>

            <Typography variant='body2' component='span'>
              This will be the room for your new admin account. Create organization name and your room.
            </Typography>

            <Typography variant='body2' component='span'>
              You can easily switch between organizations on the top navigation or in settings&nbsp;
            </Typography>
          </Box>
          <Box className={styles.rigthSide}>
            <CreateRoomForm onSubmit={handleSubmit} />
          </Box>
        </div>
      </div>
    </Box>
  );
}
