import React, { useMemo, useRef, useState } from 'react';
import { Box, ClickAwayListener, Collapse, Stack, Typography } from '@mui/material';
import classnames from 'classnames';
import { CrossIcon, PinIcon, UserIcon } from 'assets';
import { useRoomRightSidebar, useScreenSize } from 'hooks';
import { useAppSelector } from 'store';
import { selectPatients } from 'store/patientQueue';
import { PatientList } from 'components';

import styles from './WaitingQueue.module.scss';

export function WaitingQueue(): JSX.Element {
  const patients = useAppSelector(selectPatients);
  const { isMobile, isDesktop } = useScreenSize();
  const { toggleWaitingQueue } = useRoomRightSidebar();

  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [pin, setPin] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const isOpen: boolean = useMemo(() => {
    return open && !isMobile;
  }, [open, isMobile]);

  const isHover: boolean = useMemo(() => {
    return hover && !isMobile;
  }, [hover, isMobile]);

  const isPin: boolean = useMemo(() => {
    return pin && !isMobile;
  }, [pin, isMobile]);

  const handleToggle = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen((prevOpen) => !prevOpen);

    if (isMobile) {
      toggleWaitingQueue();
    }
  };

  const handlePinToggle = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setPin((prevPin) => !prevPin);
  };

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const handleClose = () => {
    if (!isPin) {
      setOpen(false);
      setHover(false);
    }
  };

  const isActive = (isHover || isOpen) && !isPin;

  return (
    <Box className={classnames(styles.container, { [styles.containerMobile]: isMobile })}>
      <ClickAwayListener onClickAway={handleClose}>
        <Box className={styles.innerContainer} sx={{ background: '#FFF' }}>
          {!isMobile && (
            <Collapse in={isOpen} timeout='auto' unmountOnExit>
              <Box className={styles.listContainer}>
                <PatientList isCallStarted isListPinned={isPin} />
              </Box>
            </Collapse>
          )}
          <Box
            ref={anchorRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleToggle}
            className={classnames(styles.toggleButtonContainer, {
              [styles.toggleButtonContainerActive]: isOpen,
              [styles.toggleButtonContainerPinned]: isPin,
              [styles.toggleButtonContainerHovered]: isHover,
              [styles.pointer]: !isOpen,
            })}
            sx={{ background: '#81CFDD' }}
          >
            {(isDesktop || !isActive) && (
              <Stack direction='row' alignItems='baseline' spacing='2px'>
                <UserIcon sx={{ color: '#FFF', fontSize: '24px' }} />
                <Typography component='p' variant='body3' fontWeight={700} sx={{ color: '#FFF' }}>
                  {patients.length}
                </Typography>
              </Stack>
            )}
            {isActive && (
              <>
                <Typography component='p' textTransform='uppercase' variant='body4' sx={{ color: '#FFF' }}>
                  Client Queue
                </Typography>
              </>
            )}
            {isHover && !isOpen && !!patients.length && (
              <Box sx={{ background: '#FF3D3D', borderRadius: '12px', padding: '5px 7px' }} height={20}>
                <Typography
                  component='p'
                  textTransform='uppercase'
                  variant='body4'
                  lineHeight='0.9rem'
                  sx={{ color: '#FFF' }}
                >
                  {!!patients.length && patients.length}
                </Typography>
              </Box>
            )}
            {isOpen && (
              <Stack direction='row' alignItems='center' justifySelf='flex-end' spacing='20px' ml='auto'>
                <PinIcon
                  sx={{ color: '#FFF', fontSize: '16px' }}
                  className={classnames({
                    [styles.pointer]: isOpen,
                  })}
                  onClick={handlePinToggle}
                />
                {!isPin && (
                  <CrossIcon
                    sx={{ color: '#FFF', fontSize: '13px' }}
                    className={classnames({
                      [styles.pointer]: isOpen,
                    })}
                    onClick={(e) => {
                      handleToggle(e);
                      isPin && handlePinToggle(e);
                      handleMouseLeave();
                    }}
                  />
                )}
              </Stack>
            )}
          </Box>

          {isMobile && (
            <Collapse in={isOpen} timeout='auto' unmountOnExit>
              <Box className={styles.listContainer}>
                <PatientList isCallStarted isListPinned={isPin} />
              </Box>
            </Collapse>
          )}
        </Box>
      </ClickAwayListener>
    </Box>
  );
}
