import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function LastPageIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M10 21.4725L14.9447 16.5L10 11.5275L11.5223 10L18 16.5L11.5223 23L10 21.4725Z' fill='#626363' />
      <path fillRule='evenodd' clipRule='evenodd' d='M22 10L22 23L20 23L20 10L22 10Z' fill='#626363' />
    </SvgIcon>
  );
}
