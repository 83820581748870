import { EMDRSettings } from 'types';

export const deserializeSettings = (settings: string): EMDRSettings | null => {
  try {
    return JSON.parse(settings) as EMDRSettings;
  } catch {
    return null;
  }
};

export const serializeSettings = (settings: EMDRSettings): string => {
  return JSON.stringify(settings);
};

export const deserializeSettingsPresets = (settings: string): EMDRSettings[] | null => {
  try {
    return JSON.parse(settings) as EMDRSettings[];
  } catch {
    return null;
  }
};

export const serializeSettingsPresets = (settings: EMDRSettings[]): string => {
  return JSON.stringify(settings);
};

export const secondsToStringWithHours = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  if (hours > 0) {
    return `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  } else if (minutes > 0) {
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  } else {
    return `0:${remainingSeconds.toString().padStart(2, '0')}`;
  }
};
