import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function AppleIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='19' viewBox='0 0 16 19' fill='none' {...props}>
      <path d='M13.3491 10.0407C13.3406 8.48399 14.0447 7.309 15.47 6.44366C14.6725 5.30261 13.4679 4.67482 11.8772 4.5518C10.3713 4.43303 8.72547 5.42986 8.12313 5.42986C7.48685 5.42986 6.02766 4.59422 4.88236 4.59422C2.51541 4.6324 0 6.48184 0 10.2444C0 11.3557 0.203608 12.5038 0.610825 13.6887C1.15378 15.2455 3.11351 19.0631 5.15808 18.9995C6.22703 18.9741 6.98207 18.2402 8.3734 18.2402C9.7223 18.2402 10.4222 18.9995 11.6142 18.9995C13.6757 18.9698 15.4488 15.5 15.9663 13.939C13.2006 12.6368 13.3491 10.1213 13.3491 10.0407ZM10.9482 3.07564C12.1062 1.70128 12.0002 0.449941 11.9662 0.000305176C10.944 0.059691 9.76048 0.695967 9.08603 1.48071C8.34371 2.32059 7.9068 3.35984 8.00012 4.53059C9.10724 4.61543 10.1168 4.04702 10.9482 3.07564Z' />
    </SvgIcon>
  );
}
