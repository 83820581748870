import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function HangUpIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='25' height='12' viewBox='0 0 25 12' fill='none' {...props}>
      <path d='M24.8381 6.75029C25.0334 8.10504 25.1615 9.95826 24.5537 10.6677C23.549 11.8412 17.1877 11.8412 17.1877 9.49417C17.1877 8.31287 18.2346 7.53783 17.2299 6.36434C16.2424 5.21116 14.4704 5.19241 12.5 5.19084C10.5296 5.18928 8.7592 5.2096 7.77009 6.36434C6.76536 7.53783 7.81228 8.31287 7.81228 9.49417C7.81228 11.8396 1.45105 11.8396 0.446312 10.6677C-0.161529 9.95826 -0.0333977 8.10504 0.161924 6.75029C0.311931 5.84556 0.691636 4.87052 1.90888 3.62515C3.73397 1.92194 6.49503 0.531251 12.4031 0.5H12.5016C12.5016 0.5 12.5656 0.5 12.6 0.5C18.5081 0.529689 21.2692 1.92194 23.0942 3.62515C24.3099 4.87052 24.6912 5.84556 24.8412 6.75029H24.8381Z' />
    </SvgIcon>
  );
}
