import { MutableRefObject, RefObject, useCallback, useEffect, useRef } from 'react';

import { IEMDRContext } from 'context';
import { useEMDR, useScreenSize } from 'hooks';
import { EMDRSettings, MovementEnum } from 'types';

export type MoveProps = {
  ctx: MutableRefObject<CanvasRenderingContext2D | null>;
  canvasRef: RefObject<HTMLCanvasElement>;
  containerRef: RefObject<HTMLDivElement>;
};

export type MoveType = {
  moveObject(newDuration: number, newDirection: number): void;
  stopMoveObject(): void;
};

const OBJECT_SIZES: number[] = [60, 80, 100, 120, 140];

export function useMove({ ctx, canvasRef, containerRef }: MoveProps): MoveType {
  const { state, settings, imagesRef, isImgPresetsLoaded }: IEMDRContext = useEMDR();
  const { isDesktop } = useScreenSize();

  const timeRef: MutableRefObject<Date | null> = useRef<Date | null>(null);
  const isMovingRef: MutableRefObject<boolean> = useRef<boolean>(false);
  const duration: MutableRefObject<number> = useRef<number>(0);
  const direction: MutableRefObject<number> = useRef<number>(0);
  const settingsRef: MutableRefObject<EMDRSettings> = useRef<EMDRSettings>(settings);
  const prevXYAndObjectSizeRef: MutableRefObject<number[]> = useRef<number[]>([0, 0, 0]);
  const redrawCountRef: MutableRefObject<number> = useRef<number>(0);

  const getProgress = useCallback((timeHasPassed: number): number => {
    const progress: number = Math.round((timeHasPassed / duration.current) * 100000) / 100000;
    return direction.current === 0 ? progress : 1 - progress;
  }, []);

  const clearCnavasAndSetDefaultStyles = useCallback(() => {
    if (ctx.current) {
      ctx.current!.fillStyle = '#' + settingsRef.current.backgroundColor;
      ctx.current!.fillRect(0, 0, canvasRef.current?.width ?? 0, canvasRef.current?.height ?? 0);
    }
  }, []);

  const drawFigureAndClearPervious = useCallback((x: number, y: number, objectSize: number): void => {
    ctx.current!.fillRect(
      prevXYAndObjectSizeRef.current[0] - 5,
      prevXYAndObjectSizeRef.current[1] - 5,
      prevXYAndObjectSizeRef.current[2] + 10,
      prevXYAndObjectSizeRef.current[2] + 10,
    );

    const img =
      imagesRef.current[`${settingsRef.current.shape.toString()}#${settingsRef.current.objectColor}`] ?? new Image();
    ctx.current!.drawImage(img, x, y, objectSize, objectSize);

    prevXYAndObjectSizeRef.current = [x, y, objectSize];
  }, []);

  const drawObject = useCallback((x: number, y: number): void => {
    if (!ctx.current) {
      return;
    }

    const objectSize: number = OBJECT_SIZES[settingsRef.current.size - 1];
    window.requestAnimationFrame(() => drawFigureAndClearPervious(x, y, objectSize));
  }, []);

  const moveLeftToRight = useCallback(
    (progress: number): void => {
      const objectSize: number = OBJECT_SIZES[settingsRef.current.size - 1];
      const x: number = (canvasRef.current!.width - objectSize) * progress;
      const y: number = (canvasRef.current!.height - objectSize) / 2;
      drawObject(x, y);
    },
    [drawObject],
  );

  const moveTopToBottom = useCallback(
    (progress: number): void => {
      const objectSize: number = OBJECT_SIZES[settingsRef.current.size - 1];
      const x: number = (canvasRef.current!.width - objectSize) / 2;
      const y: number = (canvasRef.current!.height - objectSize) * progress;
      drawObject(x, y);
    },
    [drawObject],
  );

  const moveTopLeftToBottomRight = useCallback(
    (progress: number): void => {
      const objectSize: number = OBJECT_SIZES[settingsRef.current.size - 1];
      const x: number = (canvasRef.current!.width - objectSize) * progress;
      const y: number = (canvasRef.current!.height - objectSize) * progress;
      drawObject(x, y);
    },
    [drawObject],
  );

  const moveBottomLeftToTopRight = useCallback(
    (progress: number): void => {
      const objectSize: number = OBJECT_SIZES[settingsRef.current.size - 1];
      const x: number = (canvasRef.current!.width - objectSize) * progress;
      const y: number = (canvasRef.current!.height - objectSize) * (1 - progress);
      drawObject(x, y);
    },
    [drawObject],
  );

  const moveInfinity = useCallback(
    (progress: number): void => {
      const objectSize: number = OBJECT_SIZES[settingsRef.current.size - 1];
      const x: number = (canvasRef.current!.width - objectSize) * progress;

      let y = 0;
      if (direction.current === 0) {
        if (progress <= 0.5) {
          const xPow: number = 16 * (progress - 0.25) * (progress - 0.25);
          const yCoef = Math.sqrt(1 - xPow);
          y = ((canvasRef.current!.height - objectSize) / 2) * (1 - yCoef);
        } else {
          const xPow: number = 16 * (progress - 0.75) * (progress - 0.75);
          const yCoef: number = Math.sqrt(1 - xPow);
          y = ((canvasRef.current!.height - objectSize) / 2) * (yCoef + 1);
        }
      } else {
        if (progress <= 0.5) {
          const xPow: number = 16 * (progress - 0.25) * (progress - 0.25);
          const yCoef = Math.sqrt(1 - xPow);
          y = ((canvasRef.current!.height - objectSize) / 2) * (yCoef + 1);
        } else {
          const xPow: number = 16 * (progress - 0.75) * (progress - 0.75);
          const yCoef: number = Math.sqrt(1 - xPow);
          y = ((canvasRef.current!.height - objectSize) / 2) * (1 - yCoef);
        }
      }
      drawObject(x, y);
    },
    [drawObject],
  );

  const move = useCallback((): void => {
    const timeHasPassed: number = new Date().getTime() - (timeRef.current ?? new Date()).getTime();

    if (timeHasPassed >= duration.current || !isMovingRef.current) {
      isMovingRef.current = false;
      return;
    }

    const progress = getProgress(timeHasPassed);

    switch (settingsRef.current.movement) {
      case MovementEnum.LeftToRight:
        moveLeftToRight(progress);
        break;
      case MovementEnum.TopToBottom:
        moveTopToBottom(progress);
        break;
      case MovementEnum.LeftTopToRightBottom:
        moveTopLeftToBottomRight(progress);
        break;
      case MovementEnum.LeftBottomToRightTop:
        moveBottomLeftToTopRight(progress);
        break;
      case MovementEnum.Infinity:
        moveInfinity(progress);
        break;
    }

    if (redrawCountRef.current < 2) {
      redrawCountRef.current++;
      move();
    } else {
      redrawCountRef.current = 0;
      setTimeout(move, 0);
    }
  }, [getProgress, moveLeftToRight, moveTopToBottom, moveTopLeftToBottomRight, moveBottomLeftToTopRight]);

  const moveToDefault = useCallback((): void => {
    clearCnavasAndSetDefaultStyles();

    switch (settingsRef.current.movement) {
      case MovementEnum.LeftToRight:
        moveLeftToRight(0);
        break;
      case MovementEnum.TopToBottom:
        moveTopToBottom(0);
        break;
      case MovementEnum.LeftTopToRightBottom:
        moveTopLeftToBottomRight(0);
        break;
      case MovementEnum.LeftBottomToRightTop:
        moveBottomLeftToTopRight(0);
        break;
      case MovementEnum.Infinity:
        moveInfinity(0);
        break;
    }
  }, [
    moveLeftToRight,
    moveTopToBottom,
    moveTopLeftToBottomRight,
    moveBottomLeftToTopRight,
    moveInfinity,
    clearCnavasAndSetDefaultStyles,
  ]);

  const moveObject = useCallback(
    (newDuration: number, newDirection: number): void => {
      timeRef.current = new Date();
      duration.current = newDuration;
      direction.current = newDirection;

      if (!isMovingRef.current) {
        isMovingRef.current = true;

        window.requestAnimationFrame(clearCnavasAndSetDefaultStyles);
        move();
      }
    },
    [move, clearCnavasAndSetDefaultStyles],
  );

  const stopMoveObject = useCallback((): void => {
    isMovingRef.current = false;
    moveToDefault();
  }, [moveToDefault]);

  useEffect(() => {
    settingsRef.current = settings;

    // this timeout for mobile safari, because it is not first background update on EMDR start
    setTimeout(() => {
      if (!isMovingRef.current) {
        moveToDefault();
      } else {
        clearCnavasAndSetDefaultStyles();
      }
    }, 150);
  }, [settings, isImgPresetsLoaded, clearCnavasAndSetDefaultStyles, moveToDefault]);

  useEffect(() => {
    if (containerRef.current && canvasRef.current && isDesktop) {
      const resizeObserver = new ResizeObserver(() => {
        if (
          canvasRef.current!.width !== containerRef.current?.clientWidth ||
          canvasRef.current!.height !== containerRef.current?.clientHeight
        ) {
          canvasRef.current!.width = containerRef.current?.clientWidth ?? 0;
          canvasRef.current!.height = containerRef.current?.clientHeight ?? 0;
        }

        moveToDefault();
        setTimeout(() => {
          moveToDefault();
        }, 100);
      });
      resizeObserver.observe(canvasRef.current!);

      return () => {
        if (canvasRef.current) {
          resizeObserver.unobserve(canvasRef.current!);
        }
      };
    }
  }, [state, isDesktop, moveToDefault]);

  return {
    moveObject,
    stopMoveObject,
  };
}
