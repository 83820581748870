import { createContext } from 'react';

export interface IBurgerMenuContext {
  isOpen: boolean;
  showMenu(): void;
  hideMenu(): void;
}

export const BurgerMenuContext = createContext<IBurgerMenuContext | null>(null);
export const BurgerMenuConsumer = BurgerMenuContext.Consumer;
