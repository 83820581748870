import { CLINIC_ADMIN, ADMIN, CLINIC_PROVIDER } from 'constants/roles';
import { ApiLinkProfileSuffix, ApiUser, ApiUserTitle, ApiWaitingRoomLinkProfile } from 'types';

export function getRoleByRoles(roles: string[]): string {
  if (roles.some((role: string) => role === ADMIN || role === CLINIC_ADMIN)) {
    return 'Admin';
  }

  return 'Provider';
}

export function getIsAdminByRoles(roles: string[] = []): boolean {
  if (roles.some((role: string) => role === ADMIN || role === CLINIC_ADMIN)) {
    return true;
  }

  return false;
}

export function getIsProviderRoles(roles: string[] = []): boolean {
  return roles.some((role: string) => role === CLINIC_PROVIDER);
}

export function getTitlesOptionsForUser(): { id: string; value: string }[] {
  return Object.values(ApiUserTitle)
    .filter((v) => isNaN(Number(v)))
    .map((value) => {
      return {
        id: value as string,
        value: value as string,
      };
    });
}

export function getSuffixesOptionsForUser(): { id: string; value: string }[] {
  return Object.values(ApiLinkProfileSuffix)
    .filter((v) => isNaN(Number(v)))
    .map((value) => {
      return {
        id: value as string,
        value: value as string,
      };
    });
}

export function getPreferredFullName(displayName: string | null | undefined, fallback: string | null): string | null {
  return displayName && displayName !== '' ? displayName : fallback;
}

export function getFullName(user: ApiUser | null): string {
  if (!user) {
    return '';
  }

  let fullName = user.clinicUser.preferedDisplayName;
  if (!fullName) {
    fullName = [user.title, user.firstName, user.lastName].filter((t) => t && t).join(' ');

    if (user.clinicUser.suffix) {
      fullName += `, ${user.clinicUser.suffix}`;
    }
  }

  return fullName;
}

export function getProviderFullName(profile: ApiWaitingRoomLinkProfile | null): string {
  if (!profile) {
    return '';
  }

  let fullName = profile.preferedDisplayName;
  if (!fullName) {
    fullName = [profile.title, profile.firstName, profile.lastName].filter((t) => t && t).join(' ');

    if (profile.suffix) {
      fullName += `, ${profile.suffix}`;
    }
  }

  return fullName;
}
