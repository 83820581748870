import { useLocalStorageState } from 'hooks/useLocalStorageState';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { ApiUser } from 'types';
import { SHOW_MOBILE_LANDSCAPE_WARNING } from 'constants/index';
import { PersistentInfoDialog } from 'components';
import { IDialogContext } from 'context';
import { useDialog } from 'hooks/useDialog';

export function useShowMobileLandscapeWarningDialog() {
  const user: ApiUser | null = useAppSelector(selectUser);
  const { showDialog }: IDialogContext = useDialog();

  const [showMobileLandscapeWarningForRoom, setShowMobileLandscapeWarningForRoom] = useLocalStorageState<string | null>(
    SHOW_MOBILE_LANDSCAPE_WARNING,
    null,
  );

  return (roomSid: string): void => {
    const isProvider = !!user;
    const isNewCall = showMobileLandscapeWarningForRoom != roomSid;

    if (isProvider && isNewCall) {
      const modal = showDialog(PersistentInfoDialog, {
        text: 'If your client is using a mobile device, please have them view in landscape mode.',
        onConfirm: () => {
          modal.destroy();
        },
        onCheckboxChange: () => {
          setShowMobileLandscapeWarningForRoom(roomSid);
        },
      });
    }
  };
}
