import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function HartIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M11.4879 3.40913C13.1298 1.71503 15.7171 1.50088 17.4095 2.94256C19.4174 4.65625 19.5267 7.74598 17.7234 9.60698L17.7232 9.60718L10.1649 17.4115C10.0696 17.5099 9.9271 17.5099 9.83174 17.4115L2.2739 9.60762    
        C0.473784 7.74615 0.583262 4.65644 2.5908 2.94276
        C4.28358 1.50052 6.87456 1.71553 8.51199 3.40842
        L8.51199 3.40842
        L8.51314 3.4096
        L9.28265 4.20255
        L10.0003 4.94204
        L10.7179 4.20255
        Z'
        fill='none'
        strokeWidth='2'
      />
    </SvgIcon>
  );
}
