import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function AndroidIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='85' height='100' viewBox='0 0 85 100' fill='none' {...props}>
      <path
        d='M6.17095 32.3449C2.76301 32.3432 0.000840844 35.1121 0 38.5175L0.00420351 64.3617C0.00504436 67.7789 2.7689 70.5368 6.17768 70.536C9.58814 70.536 12.3512 67.778 12.3478 64.3625V38.5141C12.3461 35.1096 9.58057 32.3432 6.17095 32.3449ZM55.943 9.13588L60.2507 1.26978C60.4802 0.858607 60.3297 0.335602 59.9135 0.102688C59.4931 -0.120135 58.9743 0.0312166 58.7515 0.444912L54.4001 8.39341C50.739 6.76385 46.6416 5.8507 42.3062 5.8549C37.9826 5.8507 33.8869 6.76133 30.241 8.38164L25.8845 0.45332C25.6584 0.0354206 25.137 -0.115931 24.7259 0.111938C24.3071 0.338125 24.1532 0.86197 24.3862 1.2723L28.6955 9.13335C20.2282 13.4999 14.5071 21.8158 14.5121 31.3636L70.107 31.3569C70.1087 21.8124 64.3977 13.5133 55.943 9.13588ZM29.6616 21.292C28.3827 21.2894 27.3384 20.2485 27.3384 18.962C27.3384 17.6814 28.3793 16.6286 29.6641 16.6295C30.9531 16.6286 31.9958 17.6814 31.9975 18.962C31.9975 20.2468 30.9523 21.2911 29.6616 21.292ZM54.9761 21.2878C53.6912 21.2894 52.6452 20.2476 52.6452 18.9578C52.6503 17.6797 53.6896 16.627 54.9769 16.6219C56.2558 16.6261 57.3044 17.6797 57.3035 18.9586C57.306 20.2451 56.2609 21.2886 54.9761 21.2878ZM14.7392 33.5111L14.7484 73.5647C14.7459 77.2081 17.693 80.1494 21.3339 80.1494L25.8181 80.151L25.8232 93.8265C25.8223 97.2319 28.5904 100 31.9908 100C35.4012 100 38.1642 97.2294 38.1651 93.8223L38.1617 80.1502L46.4936 80.1477L46.497 93.8223C46.497 97.2252 49.2659 99.9992 52.6671 99.9924C56.0776 99.995 58.8423 97.2244 58.8414 93.8173L58.8372 80.146L63.3374 80.1426C66.9699 80.1443 69.9246 77.1997 69.9263 73.5555L69.9187 33.5019L14.7392 33.5111ZM84.6419 38.5082C84.641 35.0986 81.8789 32.3356 78.4692 32.3373C75.0613 32.3339 72.3 35.1011 72.3 38.5108L72.305 64.3566C72.3042 67.7688 75.0655 70.5267 78.476 70.5276C81.8848 70.5267 84.6452 67.7688 84.6461 64.3533L84.6419 38.5082Z'
        fill='#A4C439'
      />
    </SvgIcon>
  );
}
