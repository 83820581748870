/* eslint-disable prettier/prettier */
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function RoundedPlayIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M8 0C3.58065 0 0 3.58065 0 8C0 12.4194 3.58065 16 8 16C12.4194 16 16 12.4194 16 8C16 3.58065 12.4194 0 8 0ZM11.7323 8.77419L6.05484 12.0323C5.54516 12.3161 4.90323 11.9516 4.90323 11.3548V4.64516C4.90323 4.05161 5.54194 3.68387 6.05484 3.96774L11.7323 7.41935C12.2613 7.71613 12.2613 8.48065 11.7323 8.77419Z'
        fill='black'
      />
    </SvgIcon>
  );
}
