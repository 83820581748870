import { createContext } from 'react';
import { LayoutType } from 'types';

export interface IAppSettingsContext {
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  masterVolume: number;
  setMasterVolume(volume: number): void;
  layoutType: string;
  setLayoutType(layoutType: LayoutType): void;
  isGalleryViewActive: boolean;

  open: boolean;
  openSettings(): void;
  closeSettings(): void;
}

export const AppSettingsContext = createContext<IAppSettingsContext | null>(null);
export const AppSettingsConsumer = AppSettingsContext.Consumer;
