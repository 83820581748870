import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useLogin } from 'hooks';

export function ResetPasswordPage() {
  const { onResetPasswordEmailOpen } = useLogin();
  const { enqueueSnackbar } = useSnackbar();

  const search = useLocation().search;
  const navigate = useNavigate();

  useEffect(() => {
    const userId = new URLSearchParams(search).get('userId');
    const token = new URLSearchParams(search).get('token')?.replaceAll(' ', '+');
    const phone = new URLSearchParams(search).get('phone')?.replace('$ ', '+');

    if (userId && token) {
      onResetPasswordEmailOpen(userId, token, phone ?? '');
      navigate('../login');
    } else {
      enqueueSnackbar('Invalid reset password link', { variant: 'error' });
    }
  }, [search]);

  return <></>;
}
