import React, { createRef } from 'react';
import { Button } from '@mui/material';
import classnames from 'classnames';

import styles from './UploadButton.module.scss';

type UploadButtonProps = {
  className?: string;
  acceptFormats?: string;
  disabled?: boolean;
  onInputChange: (e: any) => void;
};

export function UploadButton({ className, acceptFormats, disabled, onInputChange }: UploadButtonProps): JSX.Element {
  const inputRef = createRef<HTMLInputElement>();

  const triggerInputFile = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <Button
      variant='contained'
      disabled={disabled}
      onClick={triggerInputFile}
      className={classnames(className, styles.uploadButton)}
    >
      <input
        value={''}
        onChange={onInputChange}
        aria-hidden='true'
        className={styles.uploadInput}
        type='file'
        accept={acceptFormats}
        ref={inputRef}
      />
      Upload
    </Button>
  );
}
