import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button, Avatar, Stack, Box, Popper, ClickAwayListener, Grid } from '@mui/material';

import { ChevronRightIcon, CrownIcon, GearIcon, HelpIcon, LogOutIcon } from 'assets';
import { Transitions } from 'components';
import { selectUser } from 'store/user';
import { useAppSelector } from 'store';
import { useAzureAsset, useUser } from 'hooks';
import { ApiUser } from 'types';
import { getFullName } from 'utils';
import { IUserContext } from 'context';

import styles from './ProfileSection.module.scss';

export function ProfileSection() {
  const navigate = useNavigate();
  const user: ApiUser | null = useAppSelector(selectUser);
  const { logout }: IUserContext = useUser();

  const [open, setOpen] = useState(false);
  const prevOpen = useRef(open);
  const anchorRef = useRef<HTMLDivElement>(null);
  const avatarUrl = useAzureAsset(user?.linkToProfilePicture);

  function handleSignOut(): void {
    navigate('/');
    logout();
  }

  function handleHelp(): void {
    window.location.replace('https://support.hipaalink.net/');
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSettingsClick = (): void => {
    setOpen(false);
    navigate('../settings');
  };

  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current && anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <div onClick={handleToggle} ref={anchorRef} className={styles.profile}>
        <Grid className={styles.texts}>
          <Typography component='p' variant='body2' className={styles.name}>
            {getFullName(user)}
          </Typography>

          <Typography component='p' variant='body1' color='#979797'>
            {user?.clinicUser.organization}
          </Typography>

          <CrownIcon className={styles.crownIcon} />
        </Grid>

        <Avatar className={styles.profilePicture} src={avatarUrl} />

        <ChevronRightIcon className={styles.arrowIcon} style={{ transform: open ? 'rotate(180deg)' : '' }} />
      </div>
      <Popper
        placement='bottom-end'
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box className={styles.menu}>
                <Stack className={styles.item} alignItems='flex-start'>
                  <Button
                    disableRipple={true}
                    startIcon={<GearIcon color='primary' />}
                    className={styles.menuButton}
                    onClick={handleSettingsClick}
                  >
                    Settings
                  </Button>
                </Stack>

                <Stack className={styles.item} alignItems='flex-start'>
                  <Button
                    disableRipple={true}
                    startIcon={<HelpIcon color='primary' />}
                    className={styles.menuButton}
                    onClick={handleHelp}
                  >
                    Help
                  </Button>
                </Stack>

                <Stack className={styles.item} alignItems='flex-start'>
                  <Button
                    disableRipple={true}
                    startIcon={<LogOutIcon color='primary' />}
                    className={styles.menuButton}
                    onClick={handleSignOut}
                  >
                    Log out
                  </Button>
                </Stack>
              </Box>
            </ClickAwayListener>
          </Transitions>
        )}
      </Popper>
    </>
  );
}
