import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function LogOut2Icon(props: SvgIconProps) {
  return (
    <SvgIcon width='15' height='18' viewBox='0 0 15 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M6.44502 0.610005L13.4535 2.01169C13.7811 2.07722 14.0168 2.36484 14.0168 2.69892V15.3097C14.0168 15.6437 13.7811 15.9314 13.4535 15.997L6.44502 17.3986C6.23912 17.4398 6.02562 17.3864 5.86325 17.2534C5.70086 17.1203 5.60673 16.9214 5.60673 16.7114V1.29723C5.63353 1.06018 5.71903 0.879521 5.86325 0.755247C6.07957 0.568838 6.23912 0.568838 6.44502 0.610005ZM4.20505 1.99808V16.0105H0.700841C0.356787 16.0105 0.0706336 15.7626 0.0112915 15.4357L0 15.3097V2.69892C0 2.35486 0.247925 2.06871 0.574865 2.00937L0.700841 1.99808H4.20505ZM9.11093 8.30342C8.72387 8.30342 8.41009 8.6172 8.41009 9.00426C8.41009 9.39133 8.72387 9.7051 9.11093 9.7051C9.49799 9.7051 9.81177 9.39133 9.81177 9.00426C9.81177 8.6172 9.49799 8.30342 9.11093 8.30342Z'
        fill='#626363'
      />
    </SvgIcon>
  );
}
