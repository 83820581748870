import { useCallback, useState, useEffect, useRef, MutableRefObject } from 'react';
import { Client, Conversation, Paginator, Message, User } from '@twilio/conversations';

import { ChatContext } from './context';
import { useSignalrConnection } from 'hooks';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { selectPatient } from 'store/patient';
import { getFullName, noop } from 'utils';
import { useChatConversationsDeletion, useChatUnreadMessagesCounter } from './hooks';
import { selectPatients } from 'store/patientQueue';
import { HubPatient } from 'types';

const TWILIO_CONVERSATION_NOT_FOUND_ERROR_CODE = 50350;

type ChatProviderProps = {
  children?: JSX.Element;
};

export function ChatProvider({ children }: ChatProviderProps) {
  const { signalrConnectionId, getChatToken } = useSignalrConnection();
  const user = useAppSelector(selectUser);
  const patient = useAppSelector(selectPatient);
  const patients: HubPatient[] = useAppSelector(selectPatients);

  const prevPatients: MutableRefObject<HubPatient[]> = useRef<HubPatient[]>([]);

  const [client, setClient] = useState<Client | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [openedConversation, setOpenedConversation] = useState<Conversation | null>(null);
  const [openedRoomConversation, setOpenedRoomConversation] = useState<Conversation | null>(null);
  const [allConversations, setAllConversations] = useState<Conversation[]>([]);

  useChatConversationsDeletion({ allConversations });
  const { conversationsUnreadMessagesData, lastUpdatedChat } = useChatUnreadMessagesCounter({
    allConversations,
    openedConversation,
    openedRoomConversation,
  });

  useEffect(() => {
    if (signalrConnectionId && (user || patient)) {
      getChatToken(user?.clinicUser.id ?? signalrConnectionId).then((token) => {
        if (!token) {
          return;
        }
        const newClient = new Client(token);
        setClient(newClient);

        const userName = user ? getFullName(user) : patient!.userName;
        newClient.user.updateAttributes(userName);
      });
    }
  }, [signalrConnectionId, user, patient]);

  useEffect(() => {
    if (client) {
      const onInitialized = (): void => {
        setIsConnected(() => true);
        getAllUserConversations(client);
      };
      const onInitFailed = ({ error }: { error?: any }): void => {
        // eslint-disable-next-line no-console
        console.log(`Connection to twilio conversations failed with error: ${error!.message}`);
      };

      client.on('initialized', onInitialized);
      client.on('initFailed', onInitFailed);

      return () => {
        client.off('initialized', onInitialized);
        client.off('initFailed', onInitFailed);
      };
    }
  }, [client]);

  useEffect(() => {
    if (openedConversation) {
      const onMessageAdded = (message: Message) => {
        message.conversation.setAllMessagesRead();
      };

      openedConversation.setAllMessagesRead();

      openedConversation.on('messageAdded', onMessageAdded);

      return () => {
        openedConversation.off('messageAdded', onMessageAdded);
      };
    }
  }, [openedConversation]);

  useEffect(() => {
    if (openedRoomConversation) {
      const onMessageAdded = (message: Message) => {
        message.conversation.setAllMessagesRead();
      };

      openedRoomConversation.setAllMessagesRead();

      openedRoomConversation.on('messageAdded', onMessageAdded);

      return () => {
        openedRoomConversation.off('messageAdded', onMessageAdded);
      };
    }
  }, [openedRoomConversation]);

  useEffect(() => {
    if (isConnected && (getFullName(user) || patient?.userName)) {
      const onConversationAdded = async (newConversation: Conversation) => {
        setAllConversations((prevAllConversations: Conversation[]) => [...prevAllConversations, newConversation]);
      };

      const onConversationRemoved = async (conversationToRemove: Conversation) => {
        setAllConversations((prevAllConversations: Conversation[]) => [
          ...prevAllConversations.filter((conversation: Conversation) => conversation.sid !== conversationToRemove.sid),
        ]);
      };

      client!.on('conversationAdded', onConversationAdded);
      client!.on('conversationLeft', onConversationRemoved);
      client!.on('conversationRemoved', onConversationRemoved);

      return () => {
        client!.off('conversationAdded', onConversationAdded);
        client!.off('conversationLeft', onConversationRemoved);
        client!.off('conversationRemoved', onConversationRemoved);
      };
    }
  }, [isConnected, user, patient]);

  useEffect(() => {
    if (
      openedConversation &&
      !allConversations.find((conversation: Conversation) => conversation.sid === openedConversation.sid)
    ) {
      setOpenedConversation(null);
    }
  }, [allConversations, openedConversation]);

  const getAllUserConversations = async (client: Client): Promise<void> => {
    const conversationsPaginator: Paginator<Conversation> = await client.getSubscribedConversations();
    const conversations: Conversation[] = await getAllItemsFromPaginator(conversationsPaginator);

    setAllConversations(conversations);
  };

  const openChat = useCallback(
    (providerId: string, patientId: string) => {
      if (!isConnected) {
        throw new Error('It is not possible to open chat. The client did not connect');
      }

      client!
        .getConversationByUniqueName(`$chat-${providerId}-${patientId}`)
        .then((currentConversation: Conversation) => {
          setOpenedConversation(currentConversation);
          currentConversation.setAllMessagesRead();
        })
        .catch((error) => {
          // conversation does not exists, so we need to create
          if (error.body.code === TWILIO_CONVERSATION_NOT_FOUND_ERROR_CODE) {
            client!
              .createConversation({ uniqueName: `$chat-${providerId}-${patientId}` })
              .then((conversation: Conversation) => {
                client!.getUser(patientId).then((user: User) => {
                  conversation.add(patientId, user.attributes);
                });
                client!.getUser(providerId).then((user: User) => {
                  conversation.add(providerId, user.attributes).then(() => {
                    setOpenedConversation(conversation);
                    conversation.setAllMessagesRead();
                  });
                });
              })
              .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(`Create conversations failed with error: ${error}`);
              });
          }
        });
    },
    [isConnected, client],
  );

  const openChatByUniqueName = useCallback(
    (uniqueName: string) => {
      if (!isConnected) {
        throw new Error('It is not possible to open chat. The client did not connect');
      }

      client!
        .getConversationByUniqueName(uniqueName)
        .then((currentConversation: Conversation) => {
          setOpenedConversation(currentConversation);
          currentConversation.setAllMessagesRead();
        })
        .catch(noop);
    },
    [isConnected, client],
  );

  const openRoomChat = useCallback(
    (roomName: string) => {
      if (!isConnected) {
        throw new Error('It is not possible to open group chat. The client did not connect');
      }

      client!
        .getConversationByUniqueName(roomName)
        .then((currentConversation: Conversation) => {
          setOpenedRoomConversation(currentConversation);
          currentConversation.setAllMessagesRead();
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(`Get conversations failed with error: ${error}`);
        });
    },
    [isConnected, client, user],
  );

  const addPatientToRoomChat = useCallback(
    (patientId: string, roomName: string) => {
      if (!isConnected) {
        throw new Error('It is not possible to add patient to group chat. The client did not connect');
      }

      if (!user) {
        throw new Error('Only provider has ability to add patient to room chat');
      }

      client!
        .getConversationByUniqueName(roomName)
        .then((currentConversation: Conversation) => {
          client!.getUser(patientId).then((user: User) => {
            currentConversation.add(patientId, user.attributes);
          });
        })
        .catch((error) => {
          // conversation does not exists, so we need to create
          if (error.body.code === TWILIO_CONVERSATION_NOT_FOUND_ERROR_CODE) {
            client!
              .createConversation({ uniqueName: roomName })
              .then((conversation: Conversation) => {
                client!.getUser(client!.user.identity).then((user: User) => {
                  conversation.add(client!.user.identity, user.attributes);
                });
                client!.getUser(patientId).then((user: User) => {
                  conversation.add(patientId, user.attributes);
                });
              })
              .catch((error: any) => {
                // eslint-disable-next-line no-console
                console.log(`Create conversations failed with error: ${error}`);
              });
            // eslint-disable-next-line no-dupe-else-if
          }
        });
    },
    [isConnected, client, user],
  );

  const closeChat = (): void => {
    setOpenedConversation(null);
  };

  const closeRoomChat = (): void => {
    setOpenedRoomConversation(null);
  };

  const sendMessage = useCallback(
    (text: string) => {
      if (!isConnected) {
        throw new Error('It is not possible to open chat. The client did not connect');
      }

      if (!openedConversation) {
        throw new Error('It is not possible to send message. The chat did not open');
      }

      openedConversation.sendMessage(text);
    },
    [isConnected, client, openedConversation],
  );

  const sendMessageToRoomChat = useCallback(
    (text: string) => {
      if (!isConnected) {
        throw new Error('It is not possible to open chat. The client did not connect');
      }

      if (!openedRoomConversation) {
        throw new Error('It is not possible to send message. The chat did not open');
      }

      openedRoomConversation.sendMessage(text);
    },
    [isConnected, client, openedRoomConversation],
  );

  async function getAllItemsFromPaginator<T>(paginator: Paginator<T>): Promise<T[]> {
    let result: T[] = paginator.items;

    while (paginator.hasNextPage) {
      paginator = await paginator.nextPage();
      result = [...result, ...paginator.items];
    }

    return Promise.resolve(result);
  }

  // using for close chat when patient leave from waiting queue
  useEffect(() => {
    if (prevPatients.current !== patients && user) {
      const removedPatientsChatsNames: string[] = [];

      prevPatients.current.forEach((prevPatient: HubPatient) => {
        if (!patients.map((patient: HubPatient) => patient.connectionId).includes(prevPatient.connectionId)) {
          removedPatientsChatsNames.push(`$chat-${user.clinicUser.id}-${prevPatient.connectionId}`);
        }
      });

      if (removedPatientsChatsNames.includes(openedConversation?.uniqueName ?? '')) {
        setOpenedConversation(null);
      }
    }

    prevPatients.current = patients;
  }, [user, patients, openedConversation]);

  return (
    <ChatContext.Provider
      value={{
        conversation: openedConversation,
        roomConversation: openedRoomConversation,
        isConnected,
        identity: client?.user.identity ?? '',
        allConversations,
        conversationsUnreadMessagesData,
        lastUpdatedChat,
        openChat,
        openChatByUniqueName,
        openRoomChat,
        addPatientToRoomChat,
        closeChat,
        closeRoomChat,
        sendMessage,
        sendMessageToRoomChat,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}
