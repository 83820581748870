import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Dialog, Typography, Button, DialogTitle, DialogContent } from '@mui/material';
import { useSnackbar } from 'notistack';
import { IApiRequests, useApiRequests } from 'hooks';
import { PasswordUpdateRequest } from 'types';
import { FormInput, ModalTitle } from 'components';
import { withOutsideClickPreventer } from 'utils';

import styles from './Password.module.scss';

type PasswordPopupProps = {
  open: boolean;
  handleClose: () => void;
};

const schema = Yup.object().shape({
  oldPassword: Yup.string().required(),
  newPassword: Yup.string()
    .min(
      8,
      'password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special character',
    )
    .minLowercase(1, 'password must contain at least 1 lower case letter')
    .minUppercase(1, 'password must contain at least 1 upper case letter')
    .minNumbers(1, 'password must contain at least 1 number')
    .minSymbols(1, 'password must contain at least 1 special character')
    .required(),
  newPasswordConfirmation: Yup.string().oneOf([Yup.ref('newPassword')], 'Passwords must match'),
});

export const PasswordPopup = (props: PasswordPopupProps) => {
  const { updateUserPassword }: IApiRequests = useApiRequests();
  const { reset } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const form = useForm<PasswordUpdateRequest>({
    resolver: yupResolver(schema),
    defaultValues: {},
    mode: 'all',
  });

  const handleChangePassword = (): void => {
    const formValues = form.getValues();

    updateUserPassword(formValues)
      .then(() => {
        enqueueSnackbar('Password changed', { variant: 'success' });
        reset();
        props.handleClose();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  return (
    <Dialog className={styles.dialog} open={props.open} onClose={withOutsideClickPreventer(props.handleClose)}>
      <DialogTitle>
        <ModalTitle title='CHANGE PASSWORD' onClose={props.handleClose} />
      </DialogTitle>

      <DialogContent>
        <Box>
          <Box>
            <Typography variant='body2' className={styles.typography}>
              Current Password <span className={styles.asterisk}>*</span>
            </Typography>
            <FormInput name='oldPassword' control={form.control} type='password' />
          </Box>

          <Box>
            <Typography variant='body2' className={styles.typography}>
              New Password <span className={styles.asterisk}>*</span>
            </Typography>
            <FormInput name='newPassword' control={form.control} type='password' />
          </Box>

          <Box>
            <Typography variant='body2' className={styles.typography}>
              Confirm New Password <span className={styles.asterisk}>*</span>
            </Typography>
            <FormInput name='newPasswordConfirmation' control={form.control} type='password' />
          </Box>

          <Button
            variant='contained'
            className={styles.button}
            onClick={handleChangePassword}
            disabled={!form.formState.isValid}
          >
            Change password
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
