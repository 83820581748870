import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function WindowsIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='100' height='100' viewBox='0 0 100 100' fill='none' {...props}>
      <path d='M100 0L46 8V48L100 47.5V0ZM41 52.5L0 52V86L40.5 91.5L41 52.5ZM0 14V48H40.5V8.5L0 14ZM45.5 52.5V92L100 100V53L45.5 52.5Z' />
    </SvgIcon>
  );
}
