import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function RedoIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.6292 4.67364C15.0401 4.0602 15.0422 3.08537 15.6421 2.4745C16.253 1.85241 17.2526 1.84337 17.8747 2.4543L20.5272 5.05926C20.8295 5.35611 20.9998 5.762 20.9998 6.18566C20.9998 6.60933 20.8295 7.01521 20.5272 7.31206L17.8747 9.91698C17.2526 10.5279 16.253 10.5189 15.6421 9.89676C15.0841 9.32854 15.0433 8.4454 15.5145 7.83114H7.75779C5.74292 7.83114 4.15583 9.43048 4.15583 11.3402C4.15583 13.25 5.7429 14.8493 7.75779 14.8493H16.047C16.9189 14.8493 17.6257 15.5561 17.6257 16.428C17.6257 17.3 16.9189 18.0068 16.047 18.0068H7.75779C4.05317 18.0068 0.998331 15.0474 0.998331 11.3402C0.998331 7.63305 4.05316 4.67364 7.75779 4.67364H15.6292Z'
      />
    </SvgIcon>
  );
}
