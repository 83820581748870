import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';

import WelcomeBakground from '../../../../assets/images/welcome-background.jpg';
import styles from './WelcomeLayout.module.scss';

export const WelcomeLayout = () => {
  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    navigate('/edit');
  };

  return (
    <Box className={styles.welcomeContainer}>
      <Box className={styles.innerBox}>
        <img className={styles.image} src={WelcomeBakground} alt='' />

        <Typography variant='h4' component='h4' className={styles.welcomeText}>
          Create your waiting room to get started with HIPAA<span className={styles.link}>LINK</span>
        </Typography>

        <Button variant='contained' onClick={handleGetStartedClick}>
          Get started
        </Button>
      </Box>
    </Box>
  );
};
