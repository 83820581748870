import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function ClockIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fill='none'
        d='M10.0074 18.9894C14.968 18.9894 18.9894 14.968 18.9894 10.0074C18.9894 5.04677 14.968 1.02539 10.0074 1.02539C5.04677 1.02539 1.02539 5.04677 1.02539 10.0074C1.02539 14.968 5.04677 18.9894 10.0074 18.9894Z'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.0078 4.61816V10.0074L13.6006 11.8038'
        fill='none'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  );
}
