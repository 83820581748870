import { Box } from '@mui/system';
import { Button, Theme, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import styles from './PageNotFound.module.scss';
import image from '../../assets/images/404.png';

export const PageNotFound = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const handleBackToHome = (): void => {
    navigate('/');
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.innerContainer}>
        <img src={image} className={styles.image}></img>

        <Box className={styles.texts}>
          <Typography variant={isMobile ? 'h2' : 'h1'} component={isMobile ? 'h2' : 'h1'}>
            Oops! {isMobile && <br />}We can’t find that page.
          </Typography>

          <Typography variant='body2' component='span'>
            Sorry the page you are looking for doesn’t exist on HIPAA LINK. <br />
            Please check with your provider to make sure it’s the correct link.
          </Typography>

          <Button variant='contained' onClick={handleBackToHome}>
            Go to home page
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
