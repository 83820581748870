/* eslint-disable prettier/prettier */
import { useCallback, useEffect, useRef, useState } from 'react';
import useWindowSize from 'use-window-size-v2';
import { Button, Box, Typography } from '@mui/material';
import classnames from 'classnames';

import {
  MovementInfinityIcon,
  MovementLeftRightIcon,
  MovementTopBottomIcon,
  ObjectShapeCircleIcon,
  ObjectShapeFlowerIcon,
  ObjectShapeHartIcon,
  ObjectShapeMoonIcon,
  ObjectShapeStarIcon,
  PauseIcon,
  PlayIcon,
} from 'assets';
import { IDialogContext, IEMDRContext } from 'context';
import { useDialog, useEMDR, useScreenSize } from 'hooks';
import { DurationTypeEnum, EMDRSettings, MovementEnum, ShapeEnum } from 'types';
import { NumberSlideInput } from 'components';
import { EMDRSaveSettingsDialog } from '../EMDRSaveSettingsDialog';
import { EMDRContainer } from '../EMDRContainer';
import { MainParticipant } from '../MainParticipant';
import { DEFAULT_DURATION_TIME_VALUE, DEFAULT_DURATION_PASSES_VALUE, EMDR_OBJECT_COLORS } from '../../../../constants';

import styles from './EMDRProviderContainer.module.scss';

const DEBOUNCE_TIME = 100;

const backgroundColors: string[] = ['000000', '5F0000', '5F5500', '5F5F5F', 'DCDCDC', 'F1ECE7', 'FFFFFF'];

export function EMDRProviderContainer() {
  const { isTablet } = useScreenSize();
  const { height, width } = useWindowSize();
  const { showDialog }: IDialogContext = useDialog();

  const {
    state,
    settings,
    counterSettings,
    soundNames,
    showDurationCountdown,
    setEmdrCounterSettings,
    toggleShowDurationCountdown,
    startEmdr,
    stopEmdr,
    play,
    pause,
    changeSettings,
    updatePresets,
    switchPreset,
  }: IEMDRContext = useEMDR();
  const [localSettings, setLocalSettings] = useState<Partial<EMDRSettings>>({ ...settings });
  const [selectedPreset, setSelectedPreset] = useState<number>(1);

  const [hideShadow, setHideShadow] = useState<boolean>(false);
  const scrollableBlockRef = useRef<HTMLDivElement | null>(null);
  const fixedBlockRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setLocalSettings({ ...settings });
  }, [settings]);

  useEffect(() => {
    if (!scrollableBlockRef.current || !fixedBlockRef.current) {
      return;
    }

    handleScroll(scrollableBlockRef.current);

    scrollableBlockRef.current.addEventListener('scroll', handleScroll);
    return () => {
      scrollableBlockRef?.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleScroll(scrollableBlockRef.current);
    }, DEBOUNCE_TIME);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [height, width]);

  const updateSettings = useCallback((newSettings: EMDRSettings): void => {
    changeSettings({ ...newSettings });
  }, []);

  const handleScroll = (scrollableBlock: any) => {
    if (scrollableBlock.scrollTop + scrollableBlock.clientHeight >= scrollableBlock.scrollHeight) {
      setHideShadow(true);
    } else {
      setHideShadow(false);
    }
  };

  const handleToggleEmdrPlayPause = (): void => {
    if (state === 'play') {
      pause();
    } else {
      play();
    }
  };

  const handleToggleEmdrOnOff = (): void => {
    if (state === 'off') {
      startEmdr();
    } else {
      stopEmdr();
    }
  };

  const handleSpeedChange = (_: any, value: number | number[]): void => {
    setLocalSettings({ ...localSettings, speed: value as number });
    updateSettings({ ...localSettings, speed: value as number } as EMDRSettings);
  };

  const handleSizeChange = (_: any, value: number | number[]): void => {
    setLocalSettings({ ...localSettings, size: value as number });
    updateSettings({ ...localSettings, size: value as number } as EMDRSettings);
  };

  const handleMovementChange = (movement: MovementEnum): void => {
    setLocalSettings({ ...localSettings, movement });
    updateSettings({ ...localSettings, movement } as EMDRSettings);
  };

  const handleShapeChange = (shape: ShapeEnum): void => {
    setLocalSettings({ ...localSettings, shape });
    updateSettings({ ...localSettings, shape } as EMDRSettings);
  };

  const handleObjectColorChange = (color: string): void => {
    setLocalSettings({ ...localSettings, objectColor: color });
    updateSettings({ ...localSettings, objectColor: color } as EMDRSettings);
  };

  const handleBackgroundColorChange = (color: string): void => {
    setLocalSettings({ ...localSettings, backgroundColor: color });
    updateSettings({ ...localSettings, backgroundColor: color } as EMDRSettings);
  };

  const handleSoundChange = (sound: number | null): void => {
    setLocalSettings({ ...localSettings, sound });
    updateSettings({ ...localSettings, sound } as EMDRSettings);
  };

  const handleSwitchPreset = (number: number): void => {
    switchPreset(number);
    setSelectedPreset(number);
  };

  const handleSavePreset = (): void => {
    const modal = showDialog(EMDRSaveSettingsDialog, {
      defaultValue: selectedPreset,
      onConfirm: (preset: number) => {
        updatePresets(preset);
        setSelectedPreset(preset);
        modal.destroy();
      },
      onClose: () => {
        modal.destroy();
      },
    });
  };

  const handleDurationTypeChange = (durationType: DurationTypeEnum) => () => {
    setEmdrCounterSettings({ ...counterSettings, type: durationType });
  };

  const handleCounterTimeChange = (_: any, value: number): void => {
    setEmdrCounterSettings({ ...counterSettings, time: value, type: DurationTypeEnum.Time });
  };

  const handleCounterPasesChange = (_: any, value: number): void => {
    setEmdrCounterSettings({ ...counterSettings, passes: value, type: DurationTypeEnum.Passes });
  };

  return (
    <Box className={styles.container}>
      <div className={styles.leftSide}>
        <MainParticipant />
        <EMDRContainer className={styles.emdrContainerOuter} />
      </div>

      <div className={styles.rightSide}>
        <Typography className={styles.emdrControlsTitle} variant='body4' component='span'>
          EMDR CONTROLS
        </Typography>

        <div className={styles.emdrSetting} ref={scrollableBlockRef}>
          <div className={styles.settings}>
            <div className={styles.settingsPart}>
              <div className={styles.row}>
                <Typography variant='body5' component='span'>
                  Use Saved Settings
                </Typography>

                <div className={styles.presets}>
                  <div
                    onClick={() => handleSwitchPreset(1)}
                    className={classnames(styles.preset, {
                      [styles.presetActive]: selectedPreset === 1,
                    })}
                  >
                    Preset 1
                  </div>
                  <div
                    onClick={() => handleSwitchPreset(2)}
                    className={classnames(styles.preset, {
                      [styles.presetActive]: selectedPreset === 2,
                    })}
                  >
                    Preset 2
                  </div>
                  <div
                    onClick={() => handleSwitchPreset(3)}
                    className={classnames(styles.preset, {
                      [styles.presetActive]: selectedPreset === 3,
                    })}
                  >
                    Preset 3
                  </div>
                </div>
              </div>

              {isTablet && <div className={styles.devider}></div>}

              <div className={classnames(styles.row, styles.rowWithSlideInput)}>
                <Typography variant='body5' component='span'>
                  Speed
                </Typography>

                <NumberSlideInput
                  key='speed'
                  minValue={1}
                  maxValue={100}
                  value={localSettings.speed}
                  onChange={handleSpeedChange}
                />
              </div>

              <div className={classnames(styles.row, styles.rowWithSlideInput)}>
                <Typography variant='body5' component='span'>
                  Size
                </Typography>

                <NumberSlideInput
                  key='size'
                  minValue={1}
                  maxValue={5}
                  value={localSettings.size}
                  marks
                  onChange={handleSizeChange}
                />
              </div>

              <div className={styles.devider}></div>

              <div className={styles.row}>
                <Typography variant='body5' component='span'>
                  Movement
                </Typography>

                <div className={styles.select5}>
                  <div>
                    <MovementTopBottomIcon
                      onClick={() => handleMovementChange(MovementEnum.TopToBottom)}
                      color={localSettings.movement === MovementEnum.TopToBottom ? 'primary' : 'disabled'}
                    />
                  </div>
                  <div>
                    <MovementLeftRightIcon
                      onClick={() => handleMovementChange(MovementEnum.LeftToRight)}
                      color={localSettings.movement === MovementEnum.LeftToRight ? 'primary' : 'disabled'}
                    />
                  </div>
                  <div>
                    <MovementTopBottomIcon
                      onClick={() => handleMovementChange(MovementEnum.LeftTopToRightBottom)}
                      className={styles.rotateMinus45}
                      color={localSettings.movement === MovementEnum.LeftTopToRightBottom ? 'primary' : 'disabled'}
                    />
                  </div>
                  <div>
                    <MovementTopBottomIcon
                      onClick={() => handleMovementChange(MovementEnum.LeftBottomToRightTop)}
                      className={styles.rotate45}
                      color={localSettings.movement === MovementEnum.LeftBottomToRightTop ? 'primary' : 'disabled'}
                    />
                  </div>
                  <div>
                    <MovementInfinityIcon
                      onClick={() => handleMovementChange(MovementEnum.Infinity)}
                      color={localSettings.movement === MovementEnum.Infinity ? 'primary' : 'disabled'}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.devider}></div>

              <div className={styles.row}>
                <Typography variant='body5' component='span'>
                  Object Shape
                </Typography>

                <div className={styles.select5}>
                  <div>
                    <ObjectShapeCircleIcon
                      onClick={() => handleShapeChange(ShapeEnum.Circle)}
                      color={localSettings.shape === ShapeEnum.Circle ? 'primary' : 'disabled'}
                    />
                  </div>
                  <div>
                    <ObjectShapeStarIcon
                      onClick={() => handleShapeChange(ShapeEnum.Star)}
                      color={localSettings.shape === ShapeEnum.Star ? 'primary' : 'disabled'}
                    />
                  </div>
                  <div>
                    <ObjectShapeHartIcon
                      onClick={() => handleShapeChange(ShapeEnum.Hart)}
                      color={localSettings.shape === ShapeEnum.Hart ? 'primary' : 'disabled'}
                    />
                  </div>
                  <div>
                    <ObjectShapeMoonIcon
                      onClick={() => handleShapeChange(ShapeEnum.Moon)}
                      color={localSettings.shape === ShapeEnum.Moon ? 'primary' : 'disabled'}
                    />
                  </div>
                  <div>
                    <ObjectShapeFlowerIcon
                      onClick={() => handleShapeChange(ShapeEnum.Flower)}
                      color={localSettings.shape === ShapeEnum.Flower ? 'primary' : 'disabled'}
                    />
                  </div>
                </div>
              </div>
            </div>

            {!isTablet && <div className={styles.devider}></div>}

            <div className={styles.settingsPart}>
              <div className={styles.row}>
                <Typography variant='body5' component='span'>
                  Object Color
                </Typography>

                <div className={styles.objectColors}>
                  {EMDR_OBJECT_COLORS.map((color: string, index: number) => (
                    <div
                      key={index}
                      className={classnames(styles.color, {
                        [styles.colorActive]: color === localSettings.objectColor,
                      })}
                      onClick={() => handleObjectColorChange(color)}
                    >
                      <div style={{ background: `#${color}` }}></div>
                    </div>
                  ))}
                </div>
              </div>

              <div className={styles.devider}></div>

              <div className={styles.row}>
                <Typography variant='body5' component='span'>
                  Background Color
                </Typography>

                <div className={styles.backgroundColors}>
                  {backgroundColors.map((color: string, index: number) => (
                    <div
                      key={index}
                      className={classnames(styles.color, {
                        [styles.colorActive]: color === localSettings.backgroundColor,
                      })}
                      onClick={() => handleBackgroundColorChange(color)}
                    >
                      <div style={{ background: `#${color}` }}></div>
                    </div>
                  ))}
                </div>
              </div>

              <div className={styles.devider}></div>

              <div className={styles.row}>
                <div className={styles.soundTitle}>
                  <Typography variant='body5' component='span'>
                    Sound
                  </Typography>

                  {localSettings.sound != null && (
                    <div className={styles.soundName}>{soundNames[localSettings.sound]}</div>
                  )}
                </div>

                <div className={styles.sounds}>
                  <div
                    className={classnames(styles.sound, {
                      [styles.soundActive]: localSettings.sound === null,
                    })}
                    onClick={() => handleSoundChange(null)}
                  >
                    None
                  </div>
                  {soundNames.map((_, index: number) => (
                    <div
                      key={index}
                      className={classnames(styles.sound, {
                        [styles.soundActive]: index === localSettings.sound,
                      })}
                      onClick={() => handleSoundChange(index)}
                    >
                      {index + 1}
                    </div>
                  ))}
                </div>
              </div>

              <div className={styles.devider}></div>

              <div className={styles.row}>
                <div className={styles.durationCountdownSettings}>
                  <Typography variant='body5' component='span'>
                    Duration Type
                  </Typography>
                  <Button variant='text' className={styles.emdrSettingsButton} onClick={toggleShowDurationCountdown}>
                    {showDurationCountdown ? 'Hide' : 'Show'}
                  </Button>
                </div>

                <div className={styles.presets}>
                  <div
                    onClick={handleDurationTypeChange(DurationTypeEnum.Manual)}
                    className={classnames(styles.preset, {
                      [styles.presetActive]: counterSettings.type === DurationTypeEnum.Manual,
                    })}
                  >
                    Manual
                  </div>

                  <div
                    onClick={handleDurationTypeChange(DurationTypeEnum.Time)}
                    className={classnames(styles.preset, {
                      [styles.presetActive]: counterSettings.type === DurationTypeEnum.Time,
                    })}
                  >
                    Time
                  </div>

                  <div
                    onClick={handleDurationTypeChange(DurationTypeEnum.Passes)}
                    className={classnames(styles.preset, {
                      [styles.presetActive]: counterSettings.type === DurationTypeEnum.Passes,
                    })}
                  >
                    Passes
                  </div>
                </div>

                {counterSettings.type === DurationTypeEnum.Time && (
                  <NumberSlideInput
                    key='time'
                    minValue={1}
                    maxValue={100}
                    defaultValue={counterSettings.time ? counterSettings.time : DEFAULT_DURATION_TIME_VALUE}
                    labelPostfix='s'
                    onChange={handleCounterTimeChange}
                  />
                )}

                {counterSettings.type === DurationTypeEnum.Passes && (
                  <NumberSlideInput
                    key='passes'
                    minValue={1}
                    maxValue={50}
                    defaultValue={counterSettings.passes ? counterSettings.passes : DEFAULT_DURATION_PASSES_VALUE}
                    onChange={handleCounterPasesChange}
                  />
                )}
              </div>

              <div className={styles.devider}></div>

              <Button variant='text' className={styles.emdrSettingsButton} onClick={handleSavePreset}>
                Save current settings
              </Button>
            </div>
          </div>
        </div>

        <div className={classnames(styles.fixedButtons, { [styles.noShadow]: hideShadow })} ref={fixedBlockRef}>
          <Button
            variant='contained'
            onClick={handleToggleEmdrPlayPause}
            endIcon={state === 'play' ? <PauseIcon /> : <PlayIcon />}
          >
            {state === 'play' ? 'Pause' : 'Play'}
          </Button>

          <Button variant='contained' color='error' onClick={handleToggleEmdrOnOff}>
            {state === 'off' ? 'Start' : 'Close EMDR'}
          </Button>
        </div>
      </div>
    </Box>
  );
}
