import { Typography } from '@mui/material';
import classnames from 'classnames';
import moment from 'moment';

import styles from './MessageInfo.module.scss';

type MessageInfoProps = {
  dateCreated: Date;
  isMy: boolean;
  author: string;
};

export function MessageInfo({ dateCreated, isMy, author }: MessageInfoProps): JSX.Element {
  const getMessageDateString = (date: Date): string => {
    return moment(date).format('h:mm A');
  };

  return (
    <div className={classnames(styles.messageDate, isMy ? styles.messageDateMy : '')}>
      {author ? (
        <Typography component='p' variant='body6' sx={{ color: '#626363' }}>
          <>
            <b className={styles.author}>{author}</b>
            {getMessageDateString(dateCreated)}
          </>
        </Typography>
      ) : (
        <Typography component='p' variant='body6' sx={{ color: '#626363' }}>
          {getMessageDateString(dateCreated)}
        </Typography>
      )}
    </div>
  );
}
