import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function ArrowBackIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M20 11.5275L15.0553 16.5L20 21.4725L18.4777 23L12 16.5L18.4777 10L20 11.5275Z' fill='#626363' />
    </SvgIcon>
  );
}
