import { BurgerMenu } from 'components';
import { useState } from 'react';

import { BurgerMenuContext } from './context';

type BurgerMenuProviderProps = {
  children?: JSX.Element;
};

export function BurgerMenuProvider({ children }: BurgerMenuProviderProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const showMenu = (): void => {
    setIsOpen(true);
  };

  const hideMenu = (): void => {
    setIsOpen(false);
  };

  return (
    <BurgerMenuContext.Provider
      value={{
        isOpen,
        showMenu,
        hideMenu,
      }}
    >
      <BurgerMenu />
      {children}
    </BurgerMenuContext.Provider>
  );
}
