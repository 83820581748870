import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { Box, Button, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';

import { DEFAULT_WELCOME_MESSAGE, DEFAULT_UNAVAILABLE_MESSAGE } from 'constants/data';
import { FormInput, FormMediaUpload, FormSelector, Info, MyRoomLink, Spinner } from 'components';
import { useAppDispatch, useAppSelector } from 'store';
import { getUser, selectUser } from 'store/user';
import { CheckboxCheckedIcon, CheckboxIcon } from 'assets';
import { ApiUser, UpdateClinicRequest, UpdateClinicUserRequest, UpdateUserRequest } from 'types';
import { IApiRequests, useApiRequests, useAzureAsset } from 'hooks';
import { getIsAdminByRoles, getSuffixesOptionsForUser, getTitlesOptionsForUser } from 'utils';

import styles from './EditWaitingRoom.module.scss';

type WaitingRoomFormType = {
  user: UpdateUserRequest;
  clinicUser: UpdateClinicUserRequest;
  clinic: UpdateClinicRequest;
};

const schema = Yup.object().shape({
  clinicUser: Yup.object().shape({
    welcomeMessage: Yup.string().max(500, 'Max length is 500 character').nullable(),
    unavailableMessage: Yup.string().max(100, 'Max length is 100 character').nullable(),
    preferedDisplayName: Yup.string()
      .max(50, 'Max length is 50 character')
      .matches(/^[a-zA-Z0-9'\-,.\s]{0,50}$/g, 'Preferred Display Name has invalid characters')
      .nullable()
      .label('Preferred Display Name'),
    biography: Yup.string().max(2000, 'Max length is 2000 character').nullable(),
    displayEmail: Yup.boolean(),
    suffix: Yup.string().nullable(),
  }),
  user: Yup.object().shape({
    title: Yup.string().nullable(),
    firstName: Yup.string()
      .required('First name is required')
      .min(2, 'Min length is 2 character')
      .max(50, 'Max length is 50 character'),
    lastName: Yup.string()
      .required('Last name is required')
      .min(2, 'Min length is 2 character')
      .max(50, 'Max length is 50 character'),
    speciality: Yup.string().notRequired().max(25, 'Max length is 25 character'),
  }),
  clinic: Yup.object().shape({
    organization: Yup.string().min(2, 'Min length is 2 character').max(100, 'Max length is 100 character'),
  }),
});

export function EditWaitingRoom() {
  const user: ApiUser = useAppSelector(selectUser)!;
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const ApiRequests: IApiRequests = useApiRequests();

  const [profilePicture, setProfilePicture] = React.useState<File | null>(null);
  const [profilePictureChanged, setProfilePictureChanged] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isDirty, setIsDirty] = useState(false);

  // hidden temporary
  // const [logo, setLogo] = React.useState<File | null>(null);
  //const [deleteLogo, setDeleteLogo] = React.useState<boolean>(false);

  const avatarUrl = useAzureAsset(user?.linkToProfilePicture);
  // hidden temporary
  // const logoUrl = useAzureAsset(user?.ClinicUser?.Clinic?.LogoRoute);

  const isAdmin = useMemo<boolean>(() => {
    return getIsAdminByRoles(user.clinicUser.roles || []);
  }, [user]);

  const formDefaultValues = useMemo(
    () => ({
      clinicUser: {
        welcomeMessage: user.clinicUser.welcomeMessage ?? '',
        unavailableMessage: user.clinicUser.unavailableMessage ?? '',
        preferedDisplayName: user.clinicUser.preferedDisplayName ?? '',
        biography: user.clinicUser.biography ?? '',
        displayEmail: user.clinicUser.displayEmail,
        suffix: user.clinicUser.suffix ?? '',
      },
      user: {
        title: user.title ?? '',
        firstName: user.firstName,
        lastName: user.lastName,
        speciality: user.speciality ?? '',
      },
      clinic: {
        organization: user.clinicUser.organization,
      },
    }),
    [user],
  );

  const form = useForm<WaitingRoomFormType>({
    resolver: yupResolver(schema),
    defaultValues: formDefaultValues,
    mode: 'all',
  });

  const handleDisplayEmailChange = (_: any, checked: boolean): void => {
    form.setValue('clinicUser.displayEmail', checked);
    form.trigger('clinicUser.displayEmail');
    setIsDirty(true);
  };

  async function handleSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();
    setLoading(true);

    if (form.formState.isValid) {
      const formValues = form.getValues();

      const updateProfilePicturePromise = (): Promise<void> => {
        if (!profilePictureChanged) {
          return Promise.resolve();
        }

        return ApiRequests.updateProfilePicture(profilePicture).catch((e) => {
          Promise.reject(`Unable to update profile picture: ${e}`);
        });
      };

      const updateClinicPromise = (): Promise<void> => {
        if (isAdmin && formValues.clinic.organization !== user.clinicUser.organization) {
          return ApiRequests.updateClinic(formValues.clinic).catch((e: any) => {
            Promise.reject(`Update clinic profile failed: ${e}`);
          });
        }

        return Promise.resolve();
      };

      const updateUserPromise = (): Promise<void> => {
        return ApiRequests.updateUser(formValues.user).catch((e) => {
          Promise.reject(`Unable to update user: ${e}`);
        });
      };

      const updateClinicUserPromise = (): Promise<void> => {
        return ApiRequests.updateClinicUser(formValues.clinicUser).catch((e) => {
          Promise.reject(`Unable to update clinic user: ${e}`);
        });
      };

      await updateProfilePicturePromise()
        .then(() => updateClinicPromise())
        .then(() => updateUserPromise())
        .then(() => updateClinicUserPromise())
        .then(() => dispatch(getUser()).unwrap())
        .then(() => setIsDirty(false))
        .catch((e) => enqueueSnackbar(e, { variant: 'error' }))
        .finally(() => setLoading(false));
    }
  }

  const handleLinkChange = (value?: string): void => {
    if (!value) {
      return;
    }

    setLoading(true);

    const ensureRoomNameIsUniquePromise = (): Promise<void> => {
      return ApiRequests.ensureRoomNameIsUnique(value ?? '').catch((error) => {
        return Promise.reject(error.message);
      });
    };

    ensureRoomNameIsUniquePromise()
      .then(() => ApiRequests.updateClinicUser({ roomName: value }))
      .then(() => dispatch(getUser()).unwrap())
      .then(() => enqueueSnackbar('Room Name updated', { variant: 'success' }))
      .catch((e) => {
        enqueueSnackbar(`Unable to update link: ${e}`, { variant: 'error' });
      })
      .finally(() => setLoading(false));
  };

  const handlePictureChanged = (file: File): void => {
    setProfilePicture(file);
    setProfilePictureChanged(true);
  };

  const handlePictureDeleted = (): void => {
    setProfilePicture(null);
    setProfilePictureChanged(true);
  };

  useEffect(() => {
    form.reset(formDefaultValues);
  }, [formDefaultValues]);

  useEffect(() => {
    setIsDirty(form.formState.isDirty);
  }, [form.formState.isDirty]);

  return loading ? (
    <Spinner fixed />
  ) : (
    <>
      <Box component='form' onSubmit={handleSubmit} className={styles.form}>
        <Stack className={styles.content}>
          <Stack className={styles.row}>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Typography component='p' variant='body2'>
                Room Name URL
              </Typography>
              <Info text='Create name for your personalized room link. The link must not contain .~!@#$%^&*()/' />
            </Stack>
            <MyRoomLink type='edit' value={user.clinicUser.roomName || ''} onClick={handleLinkChange} />
          </Stack>

          <Stack className={styles.row}>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Typography component='p' variant='body2'>
                Welcome Message
              </Typography>
              <Info text='Customize a welcome message for your patient!' />
            </Stack>
            <FormInput
              name='clinicUser.welcomeMessage'
              control={form.control}
              multiline
              maxRows={4}
              placeholder={DEFAULT_WELCOME_MESSAGE}
              className={styles.textareas}
              errorHelperClassName={styles.errorHelper}
            />
          </Stack>

          <Stack className={styles.row}>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Typography component='p' variant='body2'>
                Unavailable Message
              </Typography>
              <Info text='Customize your out of office message for your patient!' />
            </Stack>
            <FormInput
              name='clinicUser.unavailableMessage'
              control={form.control}
              multiline
              maxRows={4}
              placeholder={DEFAULT_UNAVAILABLE_MESSAGE}
              className={styles.textareas}
              errorHelperClassName={styles.errorHelper}
            />
          </Stack>

          <div className={styles.maxWidth}>
            <Stack className={styles.row}>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography component='p' variant='body2'>
                  Profile Picture
                </Typography>
                <Info text='Max File Size: 10 MB Acceptable Formats: jpg, png only' />
              </Stack>
              <FormMediaUpload
                onInputChange={handlePictureChanged}
                onRemove={handlePictureDeleted}
                title='Profile Picture'
                src={avatarUrl}
              />
            </Stack>

            <Stack className={styles.row}>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography component='p' variant='body2'>
                  Title
                </Typography>
              </Stack>
              <FormSelector
                variant='outlined'
                name='user.title'
                control={form.control}
                options={getTitlesOptionsForUser()}
              />
            </Stack>

            <Stack className={styles.row}>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography component='p' variant='body2'>
                  First Name <span className={styles.asterisk}>*</span>
                </Typography>
              </Stack>
              <FormInput name='user.firstName' control={form.control} errorHelperClassName={styles.errorHelper} />
            </Stack>

            <Stack className={styles.row}>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography component='p' variant='body2'>
                  Last Name <span className={styles.asterisk}>*</span>
                </Typography>
              </Stack>
              <FormInput name='user.lastName' control={form.control} errorHelperClassName={styles.errorHelper} />
            </Stack>

            <Stack className={styles.row}>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography component='p' variant='body2'>
                  Suffix
                </Typography>
              </Stack>
              <FormSelector
                variant='outlined'
                name='clinicUser.suffix'
                control={form.control}
                options={getSuffixesOptionsForUser()}
              />
            </Stack>

            <Stack className={styles.row}>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography component='p' variant='body2'>
                  Speciality/Specialties
                </Typography>
              </Stack>
              <FormInput name='user.speciality' control={form.control} errorHelperClassName={styles.errorHelper} />
            </Stack>

            <Stack className={styles.row}>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography component='p' variant='body2'>
                  Preferred Display Name
                </Typography>
                <Info
                  text='Enter how your name should appear at check-in and in your waiting room. 
              If you leave this field empty, we will use your title, last name and suffix.'
                />
              </Stack>
              <FormInput
                name='clinicUser.preferedDisplayName'
                control={form.control}
                errorHelperClassName={styles.errorHelper}
              />
            </Stack>

            <Stack className={styles.row}>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography component='p' variant='body2'>
                  Provider/Organization Name <span className={styles.asterisk}>*</span>
                </Typography>
              </Stack>
              <FormInput
                name='clinic.organization'
                disabled={!isAdmin}
                control={form.control}
                errorHelperClassName={styles.errorHelper}
              />
            </Stack>

            <Stack className={styles.row}>
              <FormControlLabel
                control={
                  <Controller
                    name='clinicUser.displayEmail'
                    control={form.control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          handleDisplayEmailChange(e, e.target.checked);
                        }}
                        icon={<CheckboxIcon className={styles.checkboxIcon} />}
                        checkedIcon={<CheckboxCheckedIcon className={styles.checkboxIcon} />}
                      />
                    )}
                  />
                }
                label='Display email in waiting room'
              />
            </Stack>
          </div>

          <Stack className={styles.row}>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Typography component='p' variant='body2'>
                Personal Biography
              </Typography>
            </Stack>
            <FormInput
              name='clinicUser.biography'
              className={styles.bio}
              control={form.control}
              multiline
              maxRows={20}
              placeholder='Personal Biography'
              errorHelperClassName={styles.errorHelper}
            />
          </Stack>

          <Stack className={styles.row} alignItems='flex-end'>
            <Button
              variant='contained'
              onClick={handleSubmit}
              className={styles.formButton}
              type='submit'
              disabled={(!form.formState.isValid || !isDirty) && !profilePictureChanged}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
