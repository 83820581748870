import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Box, ListItem, IconButton, ListItemText, Stack, Tooltip, Button, Badge } from '@mui/material';
import moment from 'moment';

import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { ChatIcon, ErrorOutlineIcon, UserAddIcon, VideoCameraIcon } from 'assets';
import { ApiUser, HubPatient } from 'types';
import { ModalTitle, PatientStatus } from 'components';
import { useChat } from 'hooks';
import { DotMenu } from '../DotMenu';
import { PatientListDialogType } from '../../PatientList';

import styles from './PatientListItem.module.scss';

type PatientListItemProps = {
  patient: HubPatient;
  isCallStarted?: boolean;
  isListPinned?: boolean;
  onStartCall: (id: string) => void;
  onDialogOpen: (type: PatientListDialogType) => void;
};

export function PatientListItem({
  patient,
  isCallStarted = false,
  isListPinned = false,
  onStartCall,
  onDialogOpen,
}: PatientListItemProps) {
  const { identity, conversationsUnreadMessagesData, openChat } = useChat();
  const user: ApiUser | null = useAppSelector(selectUser);

  const [waitingTime, setWaitingTime] = useState(0);
  const [hover, setHover] = useState(false);

  const hasBadState = useMemo(() => {
    let state = true;
    if (patient.callAccesses) {
      if (patient.callAccesses.hasMicroAccess && patient.callAccesses.hasCameraAccess) {
        state = false;
      }
    }
    return state;
  }, [patient]);

  const unreadMessagesCount: number = useMemo(() => {
    return conversationsUnreadMessagesData.get(`$chat-${identity}-${patient.connectionId}`)?.unreadMessagesCount ?? 0;
  }, [conversationsUnreadMessagesData, identity, patient]);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const handleOpenChat = useCallback((): void => {
    openChat(identity, patient.connectionId);
  }, [identity, openChat]);

  useEffect(() => {
    const now = moment();
    const waitingMinutes = moment(patient.joinedOn, 'YYYY-MM-DDTHH:mm:ss.SSSSZ');
    const duration = moment.duration(now.diff(waitingMinutes, 'seconds'), 'seconds');

    setWaitingTime(duration.minutes());
    const timer = setInterval(() => {
      duration.add(1, 'second');
      setWaitingTime(duration.minutes());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <ListItem
      key={patient.connectionId}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      secondaryAction={
        !isListPinned && (
          <Box className={styles.actions}>
            <IconButton edge='end' aria-label='chat' onClick={() => onStartCall(patient.connectionId)}>
              {isCallStarted ? (
                <UserAddIcon sx={{ color: '#979797', fontSize: '22px' }} />
              ) : (
                <VideoCameraIcon className={styles.videoCameraIcon} sx={{ color: '#979797' }} />
              )}
            </IconButton>
            {user?.clinicUser.receiveMessages && (
              <IconButton edge='end' aria-label='chat' onClick={handleOpenChat}>
                <>
                  <Badge badgeContent={unreadMessagesCount} className={styles.chatIconBadge} color='primary'>
                    <ChatIcon className={styles.chatIcon} sx={{ color: '#979797' }} />
                  </Badge>
                </>
              </IconButton>
            )}
            <DotMenu hasBadState={hasBadState} onDialogOpen={onDialogOpen} />
          </Box>
        )
      }
    >
      {isListPinned && hover ? (
        <Button variant='contained' onClick={() => onStartCall(patient.connectionId)} className={styles.addBtn}>
          Add to call
        </Button>
      ) : (
        <ListItemText
          primary={patient.userName}
          className={styles.userName}
          secondary={
            <Stack component='span' spacing={'5px'} direction='row' alignItems='center'>
              <Box component='span'>{`Waiting ${waitingTime} min`}</Box>
              {hasBadState && (
                <Tooltip
                  classes={{ tooltip: styles.tooltip }}
                  title={
                    <>
                      <ModalTitle title='Status' />
                      <PatientStatus patient={patient} />
                    </>
                  }
                  placement='right-start'
                >
                  <Box component='span'>
                    <ErrorOutlineIcon sx={{ fontSize: '0.9rem' }} />
                  </Box>
                </Tooltip>
              )}
            </Stack>
          }
        />
      )}
    </ListItem>
  );
}
