import { useState } from 'react';
import { Theme, Tooltip, Typography, useMediaQuery } from '@mui/material';

import styles from './FirstLoginTooltip.module.scss';
import classnames from 'classnames';

type FirstLoginTooltipProps = {
  children: JSX.Element;
  className?: string;
  arrowClassName?: string;
  open: boolean;
  text: string;
  numberText?: string;
  actionText?: string;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  onClick(): Promise<void>;
};

export function FirstLoginTooltip({
  children,
  text,
  numberText,
  actionText,
  open,
  className,
  arrowClassName,
  placement,
  onClick,
}: FirstLoginTooltipProps) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = () => {
    if (loading) {
      return;
    }

    setLoading(true);
    onClick().finally(() => setLoading(false));
  };

  return (
    <>
      <Tooltip
        title={
          <div className={styles.container}>
            <Typography component='p' variant='body5'>
              {text}
            </Typography>

            <div className={styles.navigation}>
              <Typography component='p' variant={isDesktop ? 'subtitle2' : 'body7'} color='#81CFDD'>
                {numberText}
              </Typography>

              <Typography
                component='p'
                variant={isDesktop ? 'body1' : 'body7'}
                color={loading ? '#979797' : '#81CFDD'}
                className={styles.action}
                onClick={handleClick}
              >
                {actionText ?? 'Next'}
              </Typography>
            </div>
          </div>
        }
        placement={placement ?? 'bottom-end'}
        arrow
        classes={{
          tooltip: classnames(styles.tooltip, className),
          arrow: classnames(styles.arrowTooltip, arrowClassName),
        }}
        open={open}
      >
        {children}
      </Tooltip>

      {open && <div className={styles.bg}></div>}
    </>
  );
}
