import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  DialogContent,
  Popper,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { HangUpButton, Transitions } from 'components';
import { useVideo, useChat, useRoomHost } from 'hooks';

import styles from './EndCall.module.scss';

type EndCallProps = {
  disabled?: boolean;
  withTooltip?: boolean;
  onEndCall?: () => void;
};

export function EndCall(props: EndCallProps) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { closeRoomChat } = useChat();
  const { room } = useVideo();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const prevOpen = useRef(open);
  const { isHost } = useRoomHost();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current && anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleLeaveCall = () => {
    room!.disconnect();
    closeRoomChat();
    props?.onEndCall && props?.onEndCall();
  };

  return (
    <>
      <HangUpButton
        withTooltip={!open && props.withTooltip}
        onClick={handleToggle}
        ref={anchorRef}
        disabled={props.disabled}
      />

      <Popper
        placement='top'
        open={open && !isMobile}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              enabled: true,
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box className={styles.menu} sx={{ backgroundColor: 'rgba(0, 0, 0, 0.5);' }}>
                <Stack direction='column' spacing={'10px'} alignItems='flex-start'>
                  <Typography component='p' variant='body2' color='#FFF'>
                    Are you sure?
                  </Typography>
                  <Button
                    variant='contained'
                    fullWidth
                    sx={{ backgroundColor: '#FF3D3D !important' }}
                    onClick={handleLeaveCall}
                  >
                    {isHost ? 'End call' : 'Leave call'}
                  </Button>
                  <Button
                    variant='outlined'
                    fullWidth
                    sx={{ color: '#FFF', borderColor: '#FFF !important' }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Box>
            </ClickAwayListener>
          </Transitions>
        )}
      </Popper>

      <Dialog open={open && isMobile} className={styles.dialog}>
        <DialogContent>
          <Typography variant='body2'>Are you sure?</Typography>

          <Button variant='contained' fullWidth className={styles.formButton} onClick={handleLeaveCall}>
            {isHost ? 'End call' : 'Leave call'}
          </Button>

          <Button variant='outlined' fullWidth className={styles.formButton} onClick={handleClose}>
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
