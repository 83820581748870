import { useContext } from 'react';
import { ParticipantContext } from 'context';

export function useParticipantsContext() {
  const context = useContext(ParticipantContext);
  if (!context) {
    throw new Error('useParticipantsContext must be used within a ParticipantProvider');
  }
  return context;
}
