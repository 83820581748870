import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

import { IEMDRContext } from 'context';
import { useEMDR } from 'hooks';
import { DurationTypeEnum, EMDRCounterSettings } from 'types';
import { DEFAULT_DURATION_PASSES_VALUE, DEFAULT_DURATION_TIME_VALUE } from '../../../../../constants';

export type CountType = {
  updatePassesCountdown(): void;
};

export function useCount(): CountType {
  const { counterSettings, settings, state, setEmdrCounterSettings, pause }: IEMDRContext = useEMDR();

  const timeoutRef: MutableRefObject<NodeJS.Timeout | null> = useRef<NodeJS.Timeout | null>(null);

  const setupCountdowns = useCallback((): void => {
    const { type } = counterSettings;

    if (type === DurationTypeEnum.Manual) {
      setEmdrCounterSettings({ ...counterSettings, time: 0, passes: 0 });
    } else if (type === DurationTypeEnum.Time) {
      setEmdrCounterSettings((prevCounterSettings: EMDRCounterSettings) => ({
        ...prevCounterSettings,
        time: prevCounterSettings.time ? prevCounterSettings.time : DEFAULT_DURATION_TIME_VALUE,
        passes: 0,
      }));
    } else {
      setEmdrCounterSettings((prevCounterSettings: EMDRCounterSettings) => ({
        ...prevCounterSettings,
        passes: prevCounterSettings.passes ? prevCounterSettings.passes : DEFAULT_DURATION_PASSES_VALUE,
        time: 0,
      }));
    }

    if (timeoutRef.current) {
      clearInterval(timeoutRef.current!);
    }

    timeoutRef.current = setInterval(() => {
      setEmdrCounterSettings((prevCounterSettings: EMDRCounterSettings) => {
        if (prevCounterSettings.time === 0 && prevCounterSettings.type === DurationTypeEnum.Time) {
          pause();
          return { ...prevCounterSettings, time: 0 };
        }

        return {
          ...prevCounterSettings,
          time:
            prevCounterSettings.type === DurationTypeEnum.Time
              ? prevCounterSettings.time - 1
              : prevCounterSettings.time + 1,
        };
      });
    }, 1000);
  }, [counterSettings.type]);

  const stopCountdowns = useCallback((): void => {
    if (timeoutRef.current) {
      clearInterval(timeoutRef.current!);
      setEmdrCounterSettings((prevCounterSettings: EMDRCounterSettings) => ({
        ...prevCounterSettings,
        time: prevCounterSettings.type === DurationTypeEnum.Manual ? 0 : prevCounterSettings.time,
        passes: prevCounterSettings.passes === DurationTypeEnum.Manual ? 0 : prevCounterSettings.passes,
      }));
    }
  }, []);

  const updatePassesCountdown = useCallback((): void => {
    setEmdrCounterSettings((prevCounterSettings: EMDRCounterSettings) => {
      if (prevCounterSettings.passes === 0 && prevCounterSettings.type === DurationTypeEnum.Passes) {
        pause();
        return { ...prevCounterSettings, passes: 0 };
      }

      return {
        ...prevCounterSettings,
        passes:
          prevCounterSettings.type === DurationTypeEnum.Passes
            ? prevCounterSettings.passes - 1
            : prevCounterSettings.passes + 1,
      };
    });
  }, []);

  useEffect(() => {
    if (state === 'pause') {
      setupCountdowns();
      stopCountdowns();
    } else if (state === 'play') {
      setupCountdowns();
    }
  }, [state, setupCountdowns, stopCountdowns]);

  useEffect(() => {
    setEmdrCounterSettings((prevCounterSettings: EMDRCounterSettings) => ({
      ...prevCounterSettings,
      type: settings.counterType ?? DurationTypeEnum.Manual,
    }));
  }, [settings.counterType]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current);
      }
    };
  }, []);

  return {
    updatePassesCountdown,
  };
}
