import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function HelpIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='15' height='16' viewBox='0 0 15 16' fill='none' {...props}>
      <path d='M7.5 0.5C11.6421 0.5 15 3.85736 15 7.99888C15 12.1404 11.6421 15.4978 7.5 15.4978C6.2697 15.4978 5.08137 15.2007 4.01683 14.6416L0.799035 15.4792C0.458325 15.568 0.110175 15.3638 0.021435 15.0231C-0.00600754 14.9178 -0.00601507 14.8072 0.0214124 14.7018L0.858675 11.486C0.2979 10.4206 0 9.2308 0 7.99888C0 3.85736 3.35786 0.5 7.5 0.5V0.5ZM7.5 10.6235C7.08577 10.6235 6.75 10.9592 6.75 11.3734C6.75 11.7875 7.08577 12.1233 7.5 12.1233C7.91422 12.1233 8.25 11.7875 8.25 11.3734C8.25 10.9592 7.91422 10.6235 7.5 10.6235ZM7.5 4.06197C6.3609 4.06197 5.4375 4.98525 5.4375 6.12416C5.4375 6.43477 5.68934 6.68658 6 6.68658C6.28477 6.68658 6.52013 6.475 6.5574 6.20048L6.56737 6.02832C6.61537 5.55565 7.0146 5.1868 7.5 5.1868C8.0178 5.1868 8.4375 5.60647 8.4375 6.12416C8.4375 6.5282 8.33625 6.72805 7.95382 7.12316L7.72582 7.35503C7.16062 7.94339 6.9375 8.36573 6.9375 9.12372C6.9375 9.43432 7.18935 9.68613 7.5 9.68613C7.81065 9.68613 8.0625 9.43432 8.0625 9.12372C8.0625 8.71968 8.16375 8.51983 8.54617 8.12471L8.77417 7.89285C9.33937 7.30449 9.5625 6.88215 9.5625 6.12416C9.5625 4.98525 8.6391 4.06197 7.5 4.06197Z' />
    </SvgIcon>
  );
}
