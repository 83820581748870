import { useMemo } from 'react';

export function useAzureAsset(url?: string | null): string | undefined {
  return useMemo(() => {
    let newUrl = undefined;
    if (url) {
      newUrl = `${process.env['REACT_APP_HIPAA_VIDEO_URI']}${url}`;

      if (/^((http|https):\/\/)/.test(url)) {
        newUrl = url;
      }
    }

    return newUrl;
  }, [url]);
}
