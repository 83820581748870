import { useRef, useState, MouseEvent } from 'react';
import { Controller, Control } from 'react-hook-form';
import { IconButton, InputAdornment, InputProps, OutlinedInput } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { FormErrorHelperText } from 'components';

import styles from './FormInput.module.scss';

type FormInputPropsType = {
  control: Control<any, object>;
  name: string;
  style?: string;
  errorHelperClassName?: string;
  prefix?: string;
  maxLength?: number;
} & InputProps;

export function FormInput({ control, name, errorHelperClassName, prefix, maxLength, ...props }: FormInputPropsType) {
  const ref = useRef<HTMLInputElement>(null);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const formatPhoneNumber = (event: any) => {
    if (props.type !== 'phone') {
      return props.onInput;
    }

    let value = '';

    if (prefix) {
      value = event.target.value.replaceAll(prefix, '').replace(/\D/g, '');
      value = prefix + value;
    } else {
      value = event.target.value.replace(/\D/g, '');
    }

    if (maxLength) {
      value = value.substring(0, maxLength);
    }

    event.target.value = value;
    return props.onInput;
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={props.type === 'phone' && prefix ? prefix : ''}
      render={({ field, fieldState }) => (
        <>
          <OutlinedInput
            {...field}
            {...props}
            onInput={formatPhoneNumber}
            type={props.type === 'password' ? (showPassword ? 'text' : 'password') : props.type}
            fullWidth
            inputRef={ref}
            name={name}
            error={!!fieldState.error?.message}
            onKeyDown={(event: any) => {
              if (new RegExp(/^\s.*/).test(event.key) && ref!.current!.selectionStart === 0) {
                event.preventDefault();
              }
            }}
            endAdornment={
              props.type === 'password' && (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff className={styles.icon} /> : <Visibility className={styles.icon} />}
                  </IconButton>
                </InputAdornment>
              )
            }
          />
          <FormErrorHelperText className={errorHelperClassName}>{fieldState.error?.message}</FormErrorHelperText>
        </>
      )}
    />
  );
}
