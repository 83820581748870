import { Spinner } from 'components';

import styles from './SmallLoader.module.scss';

export function SmallLoader() {
  return (
    <div className={styles.loader}>
      <Spinner small />
    </div>
  );
}
