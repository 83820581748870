export type EMDRSettings = {
  speed: number;
  size: number;
  movement: MovementEnum;
  shape: ShapeEnum;
  objectColor: string;
  backgroundColor: string;
  sound: number | null;
  counterType: DurationTypeEnum | null;
};

export type EMDRCounterSettings = {
  type: DurationTypeEnum;
  time: number;
  passes: number;
};

export enum DurationTypeEnum {
  Manual = 1,
  Time,
  Passes,
}

export enum MovementEnum {
  TopToBottom = 0,
  LeftToRight,
  LeftTopToRightBottom,
  LeftBottomToRightTop,
  Infinity,
}

export enum ShapeEnum {
  Circle = 0,
  Star,
  Hart,
  Moon,
  Flower,
}
