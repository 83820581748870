import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import { Header } from '../components';

import styles from './SimpleLayout.module.scss';

export default function SimpleLayout() {
  return (
    <Box className={styles.main}>
      <Header />

      <div className={styles.container}>
        <Outlet />
      </div>
    </Box>
  );
}
