import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function MobilePhoneIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='67' height='100' viewBox='0 0 67 100' fill='none' {...props}>
      <path
        d='M57.2632 4H8.8421C6.16788 4 4 6.16788 4 8.84211V91.1579C4 93.8321 6.16788 96 8.8421 96H57.2632C59.9374 96 62.1053 93.8321 62.1053 91.1579V8.84211C62.1053 6.16788 59.9374 4 57.2632 4Z'
        stroke='#626363'
        strokeWidth='8'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M33.1298 76.7087L33.0527 76.6316'
        stroke='#626363'
        strokeWidth='10'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}
