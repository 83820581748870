import { Outlet } from 'react-router-dom';
import classnames from 'classnames';
import { Box, Container, Theme, useMediaQuery } from '@mui/material';

import { EMDRContainerFullScreen, WhiteboardContainerFullScreen, VideoRoom } from 'components';
import { Header, ModulesContainer } from '../components';

import styles from './MainLayout.module.scss';

export default function MainLayout() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Box
      className={classnames(styles.main, {
        [styles.mainMobile]: isMobile,
      })}
    >
      <Header />

      <Container className={styles.container}>
        <Outlet />
        <VideoRoom />
        <EMDRContainerFullScreen />
        <WhiteboardContainerFullScreen />
      </Container>

      <ModulesContainer />
    </Box>
  );
}
