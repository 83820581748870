import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function SaveIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.54346 10.6671C8.54195 10.6429 8.54119 10.6185 8.54119 10.594V1.18232C8.54119 0.870305 8.66514 0.571067 8.88577 0.350437C9.1064 0.129808 9.40563 0.00585938 9.71765 0.00585938C10.0297 0.00585938 10.3289 0.129808 10.5495 0.350437C10.7702 0.571067 10.8941 0.870305 10.8941 1.18232V10.594C10.8941 10.5968 10.8941 10.5997 10.8941 10.6025L13.718 8.47622C13.9676 8.28901 14.2814 8.20863 14.5903 8.25276C14.8992 8.29688 15.1779 8.4619 15.3651 8.71152C15.5523 8.96113 15.6327 9.27489 15.5885 9.58377C15.5444 9.89265 15.3794 10.1714 15.1298 10.3586L10.4239 13.888C10.2203 14.0407 9.9726 14.1232 9.71805 14.1232C9.47415 14.1251 9.2357 14.0511 9.0357 13.9115L4.32985 10.5939C4.07622 10.4139 3.90414 10.1409 3.85123 9.83448C3.79832 9.52805 3.86888 9.21313 4.0475 8.95857C4.13666 8.83134 4.25014 8.72302 4.38138 8.63988C4.51263 8.55673 4.65905 8.50041 4.81218 8.47416C4.96531 8.44791 5.12213 8.45225 5.27357 8.48693C5.42502 8.52161 5.5681 8.58595 5.69454 8.67622L8.54346 10.6671ZM0.305908 16.4765V18.8291V18.8294C0.305908 19.4791 0.832629 20.0059 1.48237 20.0059C1.49156 20.0059 1.50072 20.0058 1.50985 20.0055H17.9254C17.9346 20.0058 17.9437 20.0059 17.9529 20.0059C18.6027 20.0059 19.1294 19.4791 19.1294 18.8294V16.4765C19.1294 15.8267 18.6027 15.3 17.9529 15.3C17.3032 15.3 16.7765 15.8267 16.7765 16.4765V17.6526H2.65883V16.4765C2.65883 15.8267 2.13211 15.3 1.48237 15.3C0.832629 15.3 0.305908 15.8267 0.305908 16.4765Z'
      />
    </SvgIcon>
  );
}
