import React from 'react';
import { Button, DialogContent, Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';

import { InfoIcon } from 'assets';

import styles from './CheckYourDeviceDialog.module.scss';

export interface CheckYourDeviceDialogProps extends DialogProps {
  onClose: () => void;
  onConfirm: () => void;
}

export function CheckYourDeviceDialog({ onClose, onConfirm, ...rest }: CheckYourDeviceDialogProps) {
  return (
    <Dialog {...rest} className={styles.dialog}>
      <DialogContent>
        <InfoIcon className={styles.icon} />

        <Typography color='error' variant='body4'>
          Check Your Device
        </Typography>

        <Typography variant='body2'>
          We detected your microphone or speaker may have been disconnected. Please check your Settings to reselect your
          device.
        </Typography>

        <div className={styles.buttons}>
          <Button variant='outlined' onClick={onConfirm}>
            Open Settings
          </Button>

          <Button variant='contained' onClick={onClose}>
            Dismiss
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
