import { forwardRef, useMemo } from 'react';
import { Box, IconButton, styled } from '@mui/material';
import classnames from 'classnames';
import { HangUpIcon } from 'assets';
import { ControlTooltip } from 'components';
import { useRoomHost } from 'hooks';

import styles from './HangUpButton.module.scss';

type HangUpButtonProps = {
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
  withTooltip?: boolean;
  onClick: () => void;
};

const HangUpIconStyled = styled(HangUpIcon)(({ theme }) => ({
  color: '#fff',
}));

export const HangUpButton = forwardRef<HTMLButtonElement, HangUpButtonProps>(
  ({ disabled, className, iconClassName, withTooltip, onClick }: HangUpButtonProps, ref) => {
    const { isHost } = useRoomHost();

    const tooltipMessage = useMemo(() => {
      let tooltipMessage = '';

      if (withTooltip) {
        tooltipMessage = isHost ? 'End call' : 'Leave call';
      }

      return tooltipMessage;
    }, [withTooltip]);

    return (
      <ControlTooltip title={tooltipMessage} isDisabled={disabled}>
        <Box component='span' display='block' className={classnames(styles.deviceButton, className)}>
          <IconButton
            disabled={disabled}
            className={classnames(styles.deviceButton, className)}
            sx={{
              backgroundColor: '#FF3D3D !important',
            }}
            onClick={onClick}
            edge='end'
            aria-label='hangUp'
            ref={ref}
          >
            <HangUpIconStyled className={classnames(styles.icon, iconClassName)} />
          </IconButton>
        </Box>
      </ControlTooltip>
    );
  },
);
