import React, { useCallback, useState } from 'react';
import { Box, Divider, Stack, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { FirstLoginTooltip, MyRoomLink, NotificationSettings } from 'components';
import { AvailableMark } from '../AvailableMark';
import { IFirstLoginTooltipsContext } from 'context';
import { useFirstLoginTooltips } from 'hooks';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { ApiUser } from 'types';
import { getFullName } from 'utils';

import styles from './MyRoom.module.scss';

export function MyRoom() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const user: ApiUser = useAppSelector(selectUser)!;
  const { tooltipsState, accept }: IFirstLoginTooltipsContext = useFirstLoginTooltips();

  const [open, setOpen] = useState(false);

  const onCopyToClipboard = useCallback(() => {
    setOpen(true);
    setTimeout(() => setOpen(false), 1500);
  }, []);

  return (
    <>
      <Box className={styles.content}>
        <Box className={styles.section}>
          {user.clinicUser.roomName && (
            <Stack direction='row' spacing={4} alignItems='center' alignSelf='flex-start' className={styles.greeting}>
              <Typography component='p' variant='h2' align='center'>
                {`Welcome, ${getFullName(user)}!`}
              </Typography>

              {!isMobile && <AvailableMark />}
            </Stack>
          )}

          {!isMobile && (
            <Typography component='p' variant='body2' align='center'>
              Share this link to invite people in your waiting room!
            </Typography>
          )}

          <Tooltip open={open} title='Copied to clipboard!' placement='right'>
            <div>
              <FirstLoginTooltip
                text='Copy this link and share it with your clients!'
                numberText='1 of 3'
                open={tooltipsState === 1}
                onClick={accept}
                arrowClassName={styles.tooltipArrow}
              >
                <Box sx={{ width: '100%' }}>
                  <CopyToClipboard
                    text={`${window.location.origin}/${user.clinicUser.roomName}`}
                    onCopy={onCopyToClipboard}
                  >
                    <MyRoomLink type='copy' value={user.clinicUser.roomName} onClick={onCopyToClipboard} />
                  </CopyToClipboard>
                </Box>
              </FirstLoginTooltip>
            </div>
          </Tooltip>
        </Box>

        {!isMobile && (
          <>
            <Divider />
            <NotificationSettings />
          </>
        )}
      </Box>
    </>
  );
}
