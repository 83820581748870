import { ParticipantTracks } from '../ParticipantTracks';
import { useMainParticipant, useScreenShareParticipant, useSelectedParticipant, useVideo } from 'hooks';
import { MainParticipantInfo } from '../MainParticipantInfo';

type MainParticipantProps = {
  className?: string;
};

export function MainParticipant({ className }: MainParticipantProps) {
  const { room } = useVideo();

  const mainParticipant = useMainParticipant();
  const localParticipant = room!.localParticipant;
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();

  const videoPriority =
    (mainParticipant === selectedParticipant || mainParticipant === screenShareParticipant) &&
    mainParticipant !== localParticipant
      ? 'high'
      : null;

  /* audio is disabled for this participant component because this participant's audio
       is already being rendered in the <ParticipantStrip /> component.  */
  return (
    <MainParticipantInfo className={className} participant={mainParticipant}>
      <ParticipantTracks
        participant={mainParticipant}
        videoOnly
        enableScreenShare={true}
        videoPriority={videoPriority}
        isLocalParticipant={mainParticipant === localParticipant}
      />
    </MainParticipantInfo>
  );
}
