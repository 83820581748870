import { ReactNode } from 'react';
import { MenuItem, Select, Typography } from '@mui/material';
import { Controller, Control } from 'react-hook-form';

import { ChevronRightIcon, UserIcon } from 'assets';
import { ClinicListItemResponse } from 'types';
import { useAzureAsset } from 'hooks';

import styles from './ClinicSelector.module.scss';

type ClinicSelectorPropsType = {
  control: Control<any, object>;
  name: string;
  options: ClinicListItemResponse[];
  displayIcon: boolean;
};

export function ClinicSelector({ control, name, displayIcon, options, ...props }: ClinicSelectorPropsType) {
  function getRenderValue(selected: string): ReactNode {
    const option: ClinicListItemResponse = options.find(
      (option: ClinicListItemResponse) => option.clinicId === selected,
    )!;
    const avatarUrl = useAzureAsset('');

    return (
      <div className={styles.item}>
        {displayIcon && <img src={avatarUrl} className={styles.avatar} />}

        <Typography variant='body2' component='span' className={styles.organization}>
          {option.organization}
        </Typography>

        <div className={styles.additionalInfo}>
          <Typography variant='body1' component='span'>
            {option.isAdmin ? 'Admin' : 'Provider'}
          </Typography>

          <div className={styles.count}>
            <UserIcon />
            <Typography variant='body1' component='span'>
              {option.membersCount}
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Select
          {...field}
          {...props}
          fullWidth
          MenuProps={{ MenuListProps: { className: styles.list } }}
          className={styles.select}
          variant='outlined'
          IconComponent={ChevronRightIcon}
          renderValue={getRenderValue}
        >
          {options.map((option: ClinicListItemResponse) => (
            <MenuItem key={option.clinicId} value={option.clinicId} className={styles.menuItem}>
              {getRenderValue(option.clinicId)}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
}
