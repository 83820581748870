import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IBasicState, Patient } from 'types';

import { AppState } from '../index';

export interface IPatientState extends IBasicState {
  data: Patient | null;
  message: string | null;
  error: string | null;
  isDismissed: boolean;
}

const initialState: IPatientState = {
  data: null,
  loading: false,
  message: null,
  error: null,
  isDismissed: false,
};

export const setPatient = createAsyncThunk<Patient, Patient>(
  'patient/current',
  async (patient): Promise<Patient> => patient,
);

export const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    clearPatientStore: (state) => {
      state.data = null;
      state.loading = false;
    },
    clearPatientMessages: (state) => {
      state.message = null;
      state.error = null;
    },
    dismiss: (state) => {
      if (state.data) {
        state.isDismissed = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setPatient.pending, (state) => {
        state.loading = true;
      })
      .addCase(setPatient.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      });
  },
});

// Actions
export const { clearPatientStore, clearPatientMessages, dismiss } = patientSlice.actions;

// Selectors
export const selectPatient = (state: AppState) => state.patient.data;
export const selectPatientIsDismissed = (state: AppState) => state.patient.isDismissed;

export default patientSlice.reducer;
