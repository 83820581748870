import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function UndoIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.37143 4.6709C5.96062 4.0576 5.95873 3.08284 5.359 2.47185C4.74822 1.84961 3.74866 1.84032 3.12641 2.4511L0.473075 5.05555C0.17063 5.35243 0.000244141 5.75843 0.000244141 6.18223C0.000244141 6.60603 0.170636 7.01204 0.473084 7.30891L3.12642 9.91332C3.74867 10.5241 4.74823 10.5148 5.35901 9.89255C5.91644 9.32465 5.95738 8.44248 5.48726 7.8284H13.2418C15.2568 7.8284 16.8436 9.42753 16.8436 11.3365C16.8436 13.2455 15.2568 14.8446 13.2418 14.8446H4.95293C4.08101 14.8446 3.37418 15.5514 3.37418 16.4233C3.37418 17.2953 4.08101 18.0021 4.95293 18.0021H13.2418C16.9461 18.0021 20.0011 15.0434 20.0011 11.3365C20.0011 7.62964 16.9461 4.6709 13.2418 4.6709H5.37143Z'
      />
    </SvgIcon>
  );
}
