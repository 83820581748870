import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useDialog, useUser } from 'hooks';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { IDialogContext, IUserContext } from 'context';
import { Spinner } from '../Spinner';
import { LogoutRequiredDialog } from 'components';

export function RequireNotAuthWithLogoutOption() {
  const navigate = useNavigate();
  const { showDialog }: IDialogContext = useDialog();

  const user = useAppSelector(selectUser);
  const { isLoading, logout }: IUserContext = useUser();

  useEffect(() => {
    if (user && !isLoading) {
      const modal = showDialog(LogoutRequiredDialog, {
        onClose: () => {
          navigate('../');
          modal.destroy();
        },
        onConfirm: () => {
          logout();
          modal.destroy();
          location.reload();
        },
      });
    }
  }, []);

  if (isLoading) return <Spinner />;
  if (user) return null;
  return <Outlet />;
}
