import { ParticipantList } from 'components';
import { MenuItem } from '../MenuItem';

type ParticipantsMenuProps = {
  className?: string;
};

export function ParticipantsMenu({ className }: ParticipantsMenuProps): JSX.Element {
  return (
    <>
      <MenuItem title='Participants' className={className}>
        <ParticipantList />
      </MenuItem>
    </>
  );
}
