export const EMDR_OBJECT_COLORS: string[] = [
  'FF4F4F',
  'FFAA38',
  'FFE143',
  '00F536',
  '42E2FF',
  'B882FF',
  'E0D6CC',
  'FFFFFF',
  '3A3A3A',
];
