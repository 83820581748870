import React from 'react';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: React.CSSProperties['color'];
    };
  }
  interface ThemeOptions {
    status: {
      danger: React.CSSProperties['color'];
    };
  }
}

export const palette = (theme: any) => ({
  type: theme,
  primary: {
    main: '#81CFDD',
    light: 'rgba(116, 116, 128, 0.18)',
  },
  secondary: {
    main: '#151517',
  },
  info: {
    main: 'rgba(0, 220, 255, 1)',
  },
  background: {
    paper: '#151517',
  },
  divider: '#333333',

  action: {
    active: '#0A84FF',
    disabledBackground: '#103961',
    icon: 'rgba(255, 255, 255, 0.6)',
  },
  text: {
    primary: '#333333',
    secondary: 'rgba(255, 255, 255, 0.6)',
    disabled: 'rgba(255, 255, 255, 0.3)',
    whiteSecondary: 'rgba(235, 235, 245, 0.6)',
    whiteTertiary: 'rgba(235, 235, 245, 0.3)',
  },
  error: {
    main: '#F52F2F',
  },
  status: {
    danger: '#e53e3e',
  },
  common: {
    white: '#FFFFFF',
  },
});
