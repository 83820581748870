import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Button, OutlinedInput, Typography } from '@mui/material';

import {
  CouopnDescription,
  Coupon,
  CouponDiscountBy,
  CouponStatus,
  CouponType,
  GetCouponSubscriptionApiResponse,
} from 'types';
import { IApiRequests, useApiRequests } from 'hooks';
import { getCouponDescriptionModel, isCouponValidForPlan } from 'utils';

import styles from './DiscountInput.module.scss';

type DiscountInputProps = {
  defaultCoupon?: Coupon | null;
  disabled?: boolean;
  plan: string;
  onApply(coupon: Coupon): void;
  onRemove(): void;
};

export function DiscountInput({ defaultCoupon, disabled, plan, onApply, onRemove }: DiscountInputProps) {
  const { getCoupon }: IApiRequests = useApiRequests();

  const [discountCode, setDiscountCode] = useState<string>(defaultCoupon ? defaultCoupon!.code : '');
  const [coupon, setCoupon] = useState<Coupon | null>(defaultCoupon ?? null);
  const [discountCodeError, setDiscountCodeError] = useState<string>('');

  const applyedCouponDescription: string = useMemo(() => {
    if (!coupon) {
      return '';
    }

    const descriptionModel: CouopnDescription = getCouponDescriptionModel(coupon);

    return descriptionModel.DiscountExplanation;
  }, [coupon]);

  useEffect(() => {
    if (disabled) {
      handleRemoveCoupon();
    }
  }, [disabled]);

  const handleChangeDiscountCode = (event: ChangeEvent<HTMLInputElement>): void => {
    setDiscountCode(event.target.value);
  };

  const handleApplyCoupon = (): void => {
    getCoupon(discountCode)
      .then((response: GetCouponSubscriptionApiResponse) => {
        if (!isCouponValidForPlan(response.coupon!, plan)) {
          setDiscountCodeError('Discount not for this plan');
          return;
        }

        if (response.coupon?.status !== CouponStatus.Active) {
          setDiscountCodeError('Coupon is ' + response.coupon?.status);
          return;
        }

        setCoupon(response.coupon);
        setDiscountCodeError('');
        onApply(response.coupon!);
      })
      .catch((error) => {
        setDiscountCodeError(error.message);
      });
  };

  const handleRemoveCoupon = (): void => {
    setCoupon(null);
    setDiscountCodeError('');
    setDiscountCode('');
    onRemove();
  };

  return (
    <div className={styles.discountInput}>
      <div className={styles.discountCode}>
        <OutlinedInput
          name='discountCode'
          placeholder='Enter code here'
          value={discountCode}
          disabled={disabled || !!coupon}
          onChange={handleChangeDiscountCode}
        />

        {coupon && (
          <Button variant='contained' disabled={disabled} onClick={handleRemoveCoupon}>
            Remove
          </Button>
        )}

        {!coupon && (
          <Button variant='contained' disabled={disabled} onClick={handleApplyCoupon}>
            Apply
          </Button>
        )}
      </div>

      {discountCodeError && (
        <Typography variant='subtitle2' component='span' color='red'>
          {discountCodeError}
        </Typography>
      )}

      {applyedCouponDescription && (
        <Typography variant='subtitle2' component='span' color='#81CFDD'>
          {applyedCouponDescription}
        </Typography>
      )}
    </div>
  );
}
