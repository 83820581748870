/* eslint-disable prettier/prettier*/
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Box } from '@mui/system';
import { Typography, Button, Dialog, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';

import { useAppDispatch, useAppSelector } from 'store';
import { getUser, selectUser } from 'store/user';
import { ApiUser } from 'types';
import { IApiRequests, ITimer, useApiRequests, useTimer } from 'hooks';
import { FormInput } from 'components';
import { withOutsideClickPreventer } from 'utils';

import styles from './PhonePopup.module.scss';

type PasswordPopupProps = {
  open: boolean;
  handleClose(): void;
};

type SendNewPhoneRequest = {
  newPhoneNumber: string;
};

// Regular expression for phone numbers from North America, South America, and Europe
// +1 - North American Numbering Plan (USA, Canada, and others)
// +52 - Mexico
// +[2-7] - Most countries in Europe and South America have country codes starting with digits from 2 to 7.
const phoneRegex = /^\+(\d{1}[0-9]{10}|\d{2}[0-9]{6,10}|\d{3}[0-9]{5,9})$/;

const schema = Yup.object().shape({
  newPhoneNumber: Yup.string()
    .required('New Phone Number is a required field')
    .matches(phoneRegex, 'Invalid phone number format'),
});

const RESEND_TIMER_DURATION = 60;

const maskPhoneNumber = (phoneNumber: string | null | undefined) => {
  if (!phoneNumber) return '';

  const match = phoneNumber.match(/^\+(\d{1,3})/);
  if (!match) return `${phoneNumber.slice(0, 2)}...`;

  const countryCode = match[1];

  const visibleEndDigits = 4;
  const maskedPart = '*'.repeat(phoneNumber.length - countryCode.length - visibleEndDigits - 1);

  return `+${countryCode}${maskedPart}${phoneNumber.slice(-visibleEndDigits)}`;
};

// TODO: Refactoring NEEDED
// Move to the context&provider all functionality, separate all dialog components and render dialog components in the portal
export const PhonePopup = ({ open, handleClose }: PasswordPopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { seconds, startTimer }: ITimer = useTimer(0);
  const { sendCodeToPhone, preUpdatePhone, updatePhone, verifyPhoneCode }: IApiRequests = useApiRequests();
  const dispatch = useAppDispatch();
  const user: ApiUser | null = useAppSelector(selectUser);

  const [code, setCode] = useState<string>('');
  const [code2, setCode2] = useState<string>('');
  const [newPhonePopup, setNewPhonePopup] = useState<boolean>(false);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [changeNumberPopup, setChangeNumberPopup] = useState<boolean>(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState<string>('');

  const form = useForm<SendNewPhoneRequest>({
    resolver: yupResolver(schema),
    defaultValues: {},
    mode: 'all',
  });

  useEffect(() => {
    if (!open) {
      setCode('');
      return;
    }

    if (!user?.phoneNumber) {
      handleClose();
      handleClickOpenNewPhone();
      return;
    }

    if (seconds) {
      return;
    }

    sendCodeToPhone('sms')
      .catch((error: Error) => {
        enqueueSnackbar(`Send code error: ${error.message}`, { variant: 'error' });
      });
    startTimer(RESEND_TIMER_DURATION);
  }, [user, open]);

  useEffect(() => {
    if (!newPhonePopup) {
      form.reset({ newPhoneNumber: '+1' });
    }
  }, [newPhoneNumber]);

  const handleClickOpenNewPhone = (): void => {
    setNewPhonePopup(true);
  };

  const handleClickCloseNewPhone = (): void => {
    const formValues = form.getValues();
    setNewPhoneNumber(formValues.newPhoneNumber);
    form.reset({ newPhoneNumber: '' });
    setNewPhonePopup(false);
  };

  const handleClickOpenNewPhoneCode = (): void => {
    setChangeNumberPopup(true);
  };

  const handleClickCloseNewPhoneCode = (): void => {
    setChangeNumberPopup(false);
  };

  const handleSendCodeToNewNumber = (): void => {
    const formValues = form.getValues();
    preUpdatePhone(formValues.newPhoneNumber, 'sms')
      .then(() => {
        handleClickCloseNewPhone();
        handleClickOpenNewPhoneCode();
        form.reset({ newPhoneNumber: '' });
        startTimer(RESEND_TIMER_DURATION);
      })
      .catch((error: { message: string }) => {
        setNewPhoneNumber('');
        enqueueSnackbar(`Verification error: ${error.message}`, { variant: 'error' });
      });
  };

  const handleChange = (newValue: string) => {
    setCode(newValue);
  };

  const handleChangeCode = (newValue: string) => {
    setCode2(newValue);
  };

  const handleOpenSuccessModal = () => {
    setSuccessPopup(true);
  };

  const handleCloseSuccessModal = () => {
    setSuccessPopup(false);
    setNewPhoneNumber('');
  };

  const handleVerifyPhone = () => {
    verifyPhoneCode(code)
      .then((data) => {
        if (data) {
          handleClickOpenNewPhone();
          handleClose();
          startTimer(0);
        } else {
          enqueueSnackbar('Invalid code', { variant: 'error' });
          setCode('');
        }
      })
      .catch((error: any) => {
        let resultError = error.message;

        if (resultError instanceof AxiosError) {
          const { message } = resultError as AxiosError;
          resultError = message;
        }

        enqueueSnackbar(`Verification error: ${resultError}`, { variant: 'error' });
        setCode('');
      });
  };

  const handleResend = () => {
    sendCodeToPhone('sms').then(() => enqueueSnackbar('Code resent', { variant: 'info' }));
    startTimer(RESEND_TIMER_DURATION);
  };

  const handleVerifyPhoneNew = () => {
    updatePhone(newPhoneNumber, code2)
      .then(() => {
        handleClickCloseNewPhoneCode();
        handleOpenSuccessModal();
        dispatch(getUser());
      })
      .catch((error: any) => {
        setCode2('');
        enqueueSnackbar(`Verification error: ${error.message}`, { variant: 'error' });
      });
  };

  const handleResendPhoneNew = () => {
    preUpdatePhone(newPhoneNumber, 'sms').then(() => {
      enqueueSnackbar('Code resent', { variant: 'info' });
    });
    startTimer(RESEND_TIMER_DURATION);
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={withOutsideClickPreventer(handleClose)}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '350px',
              borderRadius: '10px',
            },
          },
        }}
      >
        <Box className={styles.PhoneMainBox}>
          <Box className={styles.changePhone}>
            <Typography variant='body2' fontWeight='bold'>
              CHANGE PHONE NUMBER
            </Typography>
            <CloseIcon onClick={handleClose} className={styles.closeIcon} />
          </Box>
          <Box className={styles.enterPhone}>
            <Typography variant='body2'>Your phone number:</Typography>
            <Typography variant='body2' className={styles.phoneNumber}>
              {maskPhoneNumber(user?.phoneNumber)}
            </Typography>
          </Box>
          <Box>
            <Typography variant='body2' className={styles.desc}>
              To change your phone number, HIPAA LINK needs to verify your identity. HIPAA LINK will send a text message
              with a 6-digit verification code.
            </Typography>
          </Box>
          <Box>
            <MuiOtpInput
              sx={{
                '& .MuiFormControl-root': {
                  borderColor: '#81CFDD',
                  marginTop: '15px',
                  marginBottom: '15px',
                },
                '& .MuiInputBase-root': {
                  height: '45px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid #81CFDD',
                },
                '& input': {
                  padding: '0',
                },
              }}
              autoFocus
              value={code}
              onChange={handleChange}
              length={6}
            />
          </Box>
          <Box>
            <Typography variant='body1' textAlign='center' className={styles.resend}>
              {!seconds ? (
                <>
                  Didn't receive a code? Click <u onClick={handleResend}>here</u> to resend a code
                </>
              ) : (
                <>You will be able to resend code in {seconds} seconds</>
              )}
            </Typography>
          </Box>
          <DialogActions className={styles.cancel}>
            <Button fullWidth onClick={handleClose} variant='outlined'>
              Cancel
            </Button>
            <Button fullWidth onClick={handleVerifyPhone} variant='contained'>
              Verify
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Dialog
        open={newPhonePopup}
        onClose={withOutsideClickPreventer(handleClickCloseNewPhone)}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '350px',
              borderRadius: '10px',
            },
          },
        }}
      >
        <Box className={styles.PhoneMainBox}>
          <Box className={styles.changePhone}>
            <Typography variant='body2' fontWeight='bold'>
              ENTER NEW PHONE NUMBER
            </Typography>
            <CloseIcon onClick={handleClickCloseNewPhone} className={styles.closeIcon} />
          </Box>
          <Box>
            <Typography variant='body2' className={styles.desc}>
              We will send you an SMS with a verification code for your new phone number.
            </Typography>
          </Box>
          <Box>
            <Typography variant='body2' className={styles.typography}>
              New Phone Number <span className={styles.asterisk}>*</span>
            </Typography>
            <FormInput
              name='newPhoneNumber'
              control={form.control}
              type='phone'
              prefix='+1'
              maxLength={13}
            />
          </Box>
          {!!seconds && (
            <Box paddingTop='10px' marginBottom='-10px'>
              <Typography variant='body1' textAlign='center' className={styles.resend}>
                You will be able to resend code in {seconds} seconds
              </Typography>
            </Box>
          )}
          <Box className={styles.flexContainer}>
            <Button variant='outlined' className={styles.button} onClick={handleClickCloseNewPhone}>
              Close
            </Button>
            <Button
              disabled={!!seconds}
              variant='contained'
              className={styles.button}
              onClick={handleSendCodeToNewNumber}
            >
              Send code
            </Button>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        open={changeNumberPopup}
        onClose={withOutsideClickPreventer(handleClickCloseNewPhoneCode)}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '350px',
              borderRadius: '10px',
            },
          },
        }}
      >
        <Box className={styles.PhoneMainBox}>
          <Box className={styles.changePhone}>
            <Typography variant='body2' fontWeight='bold'>
              CHANGE PHONE NUMBER
            </Typography>
            <CloseIcon onClick={handleClickCloseNewPhoneCode} className={styles.closeIcon} />
          </Box>
          <Box className={styles.enterPhone}>
            <Typography variant='body2'>Your new phone number:</Typography>
            <Typography variant='body2' className={styles.phoneNumber}>
              {maskPhoneNumber(newPhoneNumber)}
            </Typography>
          </Box>

          <Box>
            <MuiOtpInput
              sx={{
                '& .MuiFormControl-root': {
                  borderColor: '#81CFDD',
                  marginTop: '15px',
                  marginBottom: '15px',
                },
                '& .MuiInputBase-root': {
                  height: '45px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid #81CFDD',
                },
                '& input': {
                  padding: '0',
                },
              }}
              autoFocus
              value={code2}
              onChange={handleChangeCode}
              length={6}
              TextFieldsProps={{ InputProps: { componentsProps: { input: { pattern: '\\d*' } } } }}
            />
          </Box>
          <Box>
            <Typography variant='body1' textAlign='center' className={styles.resend}>
              {!seconds ? (
                <>
                  Didn't receive a code? Click <u onClick={handleResendPhoneNew}>here</u> to resend a code
                </>
              ) : (
                <>You will be able to resend code in {seconds} seconds</>
              )}
            </Typography>
          </Box>
          <DialogActions className={styles.cancel}>
            <Button fullWidth onClick={handleClickCloseNewPhoneCode} variant='outlined'>
              Cancel
            </Button>
            <Button fullWidth onClick={handleVerifyPhoneNew} variant='contained'>
              Verify
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Dialog
        open={successPopup}
        onClose={withOutsideClickPreventer(handleCloseSuccessModal)}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '350px',
              borderRadius: '10px',
            },
          },
        }}
      >
        <Box className={styles.PhoneMainBox}>
          <Box className={styles.PhoneInnerBox}>
            <CheckCircleIcon className={styles.CheckedIcon} />
            <Typography className={styles.PhoneSuccsessTitle} variant='body2'>
              Phone number changed
            </Typography>
            <Typography variant='body2'>Your phone number was successfully changed</Typography>
            <Button variant='contained' onClick={handleCloseSuccessModal}>
              Ok
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};
