import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function StarIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='15' height='15' viewBox='0 0 15 15' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.08255 13.1088L3.83646 14.8921C3.39799 15.133 2.85567 14.9569 2.62515 14.4987C2.53336 14.3162 2.50168 14.1072 2.53503 13.904L3.15498 10.1268C3.20488 9.8227 3.10842 9.51244 2.89702 9.29711L0.270869 6.62202C-0.0838601 6.26068 -0.0911186 5.66725 0.254657 5.29656C0.392346 5.14894 0.572761 5.05288 0.767971 5.02324L4.39722 4.47214C4.68936 4.42778 4.94191 4.23603 5.07257 3.95939L6.69561 0.522705C6.91485 0.0584923 7.45268 -0.132102 7.8969 0.0970007C8.07379 0.18823 8.21697 0.337853 8.30427 0.522705L9.92732 3.95939C10.058 4.23603 10.3105 4.42778 10.6027 4.47214L14.2319 5.02324C14.7221 5.09768 15.0618 5.57332 14.9906 6.0856C14.9622 6.2896 14.8703 6.47814 14.729 6.62202L12.1029 9.29711C11.8915 9.51244 11.795 9.8227 11.8449 10.1268L12.4649 13.904C12.5486 14.4143 12.2207 14.8988 11.7324 14.9863C11.538 15.0212 11.338 14.9881 11.1634 14.8921L7.91733 13.1088C7.65603 12.9652 7.34386 12.9652 7.08255 13.1088Z'
      />
    </SvgIcon>
  );
}
