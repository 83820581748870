import { useMemo } from 'react';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';

import { PLAN_CODE_YEARLY, PLAN_CODE_MONTHLY } from 'constants/payment';
import { COUPON } from 'constants/index';
import { useLocalStorageState } from 'hooks';
import { CouopnDescription, Coupon, CouponDiscountBy, CouponType } from 'types';
import { getCouponDescriptionModel, isCouponValidForPlan } from 'utils';

import styles from './LeftPromocodeContainer.module.scss';

export function LeftPromocodeContainer() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [coupon] = useLocalStorageState<Coupon | null>(COUPON, null);

  const topDescriptionFlatDurationString: string = useMemo(() => {
    if (!coupon) {
      return '';
    }

    if (coupon.type === CouponType.OneTime) {
      return 'ONE TIME';
    }

    if (coupon.type === CouponType.Forever) {
      return 'FOREVER';
    }

    if (isCouponValidForPlan(coupon, PLAN_CODE_MONTHLY)) {
      return `FOR ${coupon.duration} MONTHS`;
    }

    if (isCouponValidForPlan(coupon, PLAN_CODE_YEARLY)) {
      return `FOR ${coupon.duration} YEARS`;
    }

    return '';
  }, [coupon]);

  const couopnDescription: CouopnDescription = useMemo(() => {
    return coupon ? getCouponDescriptionModel(coupon) : ({} as CouopnDescription);
  }, [coupon]);

  return (
    <Box className={styles.container}>
      <div className={styles.topContainer}>
        <div className={styles.circle1}></div>
        <div className={styles.circle2}></div>

        {coupon?.discountBy === CouponDiscountBy.Percentage && (
          <div className={styles.topDescriptionPercentage}>
            <Typography variant='body1' component='span' className={styles.percentValue}>
              {coupon.discountValue}
            </Typography>

            <Typography variant='body1' component='span' className={styles.percent}>
              %
            </Typography>

            <Typography variant='body1' component='span' className={styles.off}>
              OFF
            </Typography>
          </div>
        )}

        {coupon?.discountBy === CouponDiscountBy.Flat && (
          <div className={styles.topDescriptionFlat}>
            <Typography variant='body1' component='span' className={styles.flatValue}>
              ${coupon.discountValue}
            </Typography>

            <Typography variant='body1' component='span' className={styles.by}>
              /{isCouponValidForPlan(coupon, PLAN_CODE_MONTHLY) ? 'MONTH' : 'YEAR'}
            </Typography>

            <Typography variant='body1' component='span' className={styles.duration}>
              {topDescriptionFlatDurationString}
            </Typography>
          </div>
        )}
      </div>

      <div className={styles.bottomContainer}>
        <Typography variant='h3' component='h3'>
          YOUR PROMO CODE:
        </Typography>

        <div className={styles.promoCodeBorder}>
          <div className={styles.promoCode}>{coupon?.code}</div>
        </div>

        <Typography variant={!isMobile ? 'h3' : 'body4'} component={!isMobile ? 'h3' : 'span'}>
          {couopnDescription.PromoTitle}
        </Typography>

        <Typography variant={!isMobile ? 'body2' : 'body1'} component='span'>
          {couopnDescription.PromoSubTitle}
        </Typography>
      </div>
    </Box>
  );
}
