import { createContext } from 'react';

export type OpenedTab = 'chat' | 'settings' | 'participants' | 'notes' | 'backgroundEffects' | 'waitingQueue' | null;

export interface IRoomRightSidebarContext {
  openedTab: OpenedTab;
  hideSidebar(): void;
  toggleSettings(): void;
  toggleChat(): void;
  toggleParticipants(): void;
  toggleBackgroundEffects(): void;
  toggleNotes(): void;
  toggleWaitingQueue(): void;
}

export const RoomRightSidebarContext = createContext<IRoomRightSidebarContext | null>(null);
export const RoomRightSidebarConsumer = RoomRightSidebarContext.Consumer;
