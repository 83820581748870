import { createContext } from 'react';

export interface IAITranscribingNotes {
  isItPossibleToStartRecording: boolean;
  startTranscribing(): Promise<void>;
  stopTranscribing(): Promise<void>;
}

export const AITranscribingNotesContext = createContext<IAITranscribingNotes | null>(null);
export const AITranscribingNotesConsumer = AITranscribingNotesContext.Consumer;
