import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Dialog, IconButton, Theme, Typography, useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';
import classnames from 'classnames';
import moment from 'moment';

import { CrossIcon } from 'assets';
import { PLAN_CODE_MONTHLY, SUCCESS_OPERATION_ACTION_TYPE_SUBSCRIPTION_CHANGE } from 'constants/payment';
import { DiscountInput, Info, SeatsCountSelector, Spinner } from 'components';
import { UpgradeToAnnualPlanPopup } from '../UpgradeToAnnualPlanPopup/UpgradeToAnnualPlanPopup';
import {
  ApiUser,
  ComputeSubscriptionProrationMode,
  ComputeSubscriptionProrationResponse,
  Coupon,
  SubscriptionProrationApi,
  UpdateSubscriptionWithIncreasingAmountOfLicensesResponse,
} from 'types';
import { useApiRequests, useDialog } from 'hooks';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';

import styles from './ManageSeatsPopup.module.scss';

type ManageSeatsState = 'minLimitReached' | 'seatsDecrease' | 'notChanged' | 'seatsIncrease';

type ManageSeatsPopupProps = {
  open: boolean;
  totalSeats: number;
  availableSeats: number;
  onClose(): void;
};

export function ManageSeatsPopup({ open, totalSeats, availableSeats, onClose }: ManageSeatsPopupProps) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dialog = useDialog();
  const {
    updateSubscriptionWithIncreasingAmountOfLicenses,
    updateSubscriptionWithDecreasingAmountOfLicenses,
    computeSubscriptionProration,
  } = useApiRequests();

  const user: ApiUser = useAppSelector(selectUser)!;

  const [loading, setLoading] = useState<boolean>(true);
  const [seatsCount, setSeatsCount] = useState<number>(totalSeats);
  const [coupon, setCoupon] = useState<Coupon | null>(null);
  const [subscriptionProration, setSubscriptionProration] = useState<SubscriptionProrationApi | null>(null);

  const manageSeatsState: ManageSeatsState = useMemo(() => {
    if (seatsCount < totalSeats - availableSeats) {
      return 'minLimitReached';
    }

    if (seatsCount < totalSeats) {
      return 'seatsDecrease';
    }

    if (seatsCount > totalSeats) {
      return 'seatsIncrease';
    }

    return 'notChanged';
  }, [totalSeats, availableSeats, seatsCount]);

  const pricePerOneSeatPerMonth: string = useMemo(() => {
    if (user.clinicUser.subscriptionInfo?.planCode === PLAN_CODE_MONTHLY) {
      return user.clinicUser.subscriptionInfo.price?.toFixed(2) ?? '';
    }

    return ((user.clinicUser.subscriptionInfo?.price ?? 0) / 12).toFixed(2);
  }, [user]);

  const cardExparationString: string = useMemo(() => {
    return moment()
      .year(user.clinicUser.subscriptionInfo?.cardExpirationYear ?? 2022)
      .month((user.clinicUser.subscriptionInfo?.cardExpirationMonth ?? 1) - 1)
      .format('MMM YYYY');
  }, [user]);

  const currentTotalPrice: string = useMemo(() => {
    return ((user.clinicUser.subscriptionInfo?.price ?? 0) * totalSeats).toFixed(2);
  }, [totalSeats, user]);

  const renewsDate: string = useMemo(() => {
    return 'Renews ' + moment(user.clinicUser.subscriptionInfo?.currentTermEndsAt).format('MMMM D, YYYY');
  }, [user]);

  useEffect(() => {
    setLoading(true);

    computeSubscriptionProration({
      plan: { code: user.clinicUser.subscriptionInfo!.planCode!, quantity: seatsCount },
      mode: ComputeSubscriptionProrationMode.ProrateExistingSubscription,
      couponCode: coupon?.code,
    })
      .then((response: ComputeSubscriptionProrationResponse) => {
        setSubscriptionProration(response.subscriptionProration);
      })
      .catch((error) => {
        enqueueSnackbar('Calculation error: ' + error.message, { variant: 'error' });
        setSubscriptionProration(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [seatsCount, coupon, user]);

  const handleDecreaseSeat = (): void => {
    if (seatsCount > 1) {
      setSeatsCount(seatsCount - 1);
    }
  };

  const handleIncreaseSeat = (): void => {
    setSeatsCount(seatsCount + 1);
  };

  const handleIncrease = (): void => {
    setLoading(true);

    updateSubscriptionWithIncreasingAmountOfLicenses({
      planCode: user.clinicUser.subscriptionInfo!.planCode!,
      quantity: seatsCount,
      redirectUrl: `${process.env.REACT_APP_PUBLIC_URI}/successPayment?type=${SUCCESS_OPERATION_ACTION_TYPE_SUBSCRIPTION_CHANGE}&redirectTo=settings&redirectToHash=subscriptions`,
      couponCode: coupon?.code,
    })
      .then((response: UpdateSubscriptionWithIncreasingAmountOfLicensesResponse) => {
        window.location.href = response.page.url;
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDecrease = (): void => {
    setLoading(true);

    updateSubscriptionWithDecreasingAmountOfLicenses({
      planCode: user.clinicUser.subscriptionInfo!.planCode!,
      quantity: seatsCount,
    })
      .then(() => {
        window.location.href = `${process.env.REACT_APP_PUBLIC_URI}/successPayment?type=${SUCCESS_OPERATION_ACTION_TYPE_SUBSCRIPTION_CHANGE}&redirectTo=settings&redirectToHash=subscriptions`;
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleApplyCoupon = (coupon: Coupon): void => {
    setCoupon(coupon);
  };

  const handleRemoveCoupon = (): void => {
    setCoupon(null);
  };

  const handleManageStaff = (): void => {
    navigate('/settings#staff');
    onClose();
  };

  const handleToAnnual = async (): Promise<void> => {
    const modal = dialog.showDialog(UpgradeToAnnualPlanPopup, {
      totalSeats,
      availableSeats,
      onClose: () => {
        modal.destroy();
      },
    });
  };

  return (
    <Box>
      <Dialog className={styles.dialog} open={open} onClose={onClose}>
        {loading && <Spinner small />}

        <Box className={styles.leftSide}>
          <Typography variant='body4' component='span'>
            Current Quantity
          </Typography>

          <div className={classnames(styles.row, styles.pricePerMonth)}>
            <Typography variant='h4' component='h4'>
              ${pricePerOneSeatPerMonth}/Month/Seat
            </Typography>

            <Info
              text='If your practice includes multiple providers, you can purchase and assign a seat to each practice provider. Use the staff page to assign seats and manage your staff.'
              tooltipClassName={styles.tooltip}
            />

            <Typography variant={isDesktop ? 'body1' : 'body5'} component='span' color='#626363'>
              {user.clinicUser.subscriptionInfo?.planCode === PLAN_CODE_MONTHLY
                ? 'Billed Monthly'
                : `Billed $${(user.clinicUser.subscriptionInfo?.price ?? 0).toFixed(2)}/seat annually`}
            </Typography>
          </div>

          <div className={styles.devider}></div>

          <div className={styles.row}>
            <div className={styles.calculationsRow}>
              <Typography variant={isDesktop ? 'body2' : 'body5'} component='span' color='#393534'>
                Total Seats
              </Typography>

              <Typography variant={isDesktop ? 'body2' : 'body5'} component='span' color='#393534'>
                {totalSeats}
              </Typography>
            </div>

            <div className={styles.calculationsRow}>
              <Typography variant='body4' component='span'>
                Total
              </Typography>

              <Typography variant='body4' component='span'>
                ${currentTotalPrice}
              </Typography>
            </div>
          </div>

          <div className={styles.devider}></div>

          <div className={styles.row}>
            <Typography variant='body2' component='span'>
              Payment Method
            </Typography>

            <div className={styles.card}>
              <Typography variant={isDesktop ? 'body' : 'body3'} component='span' color='#393534'>
                {`Card ending in ${user.clinicUser.subscriptionInfo?.cardLastFourDigits ?? '0000'}`}
              </Typography>

              <Typography variant={isDesktop ? 'body2' : 'body5'} component='span' color='#393534'>
                {`Expires ${cardExparationString}`}
              </Typography>
            </div>
          </div>

          {user.clinicUser.subscriptionInfo?.planCode === PLAN_CODE_MONTHLY && (
            <div className={styles.toAnnual} onClick={handleToAnnual}>
              <Typography variant='body5' component='span'>
                <u>
                  Save <b>20%</b> by upgrading to Annual
                </u>
              </Typography>
            </div>
          )}
        </Box>

        <Box className={styles.rightSide}>
          <div className={styles.title}>
            <Typography component='p' variant='body4'>
              New Quantity
            </Typography>

            <IconButton onClick={onClose} className={styles.cross}>
              <CrossIcon color='primary' />
            </IconButton>
          </div>

          <div className={styles.row}>
            <Typography variant='body2' component='span'>
              Total Seats
            </Typography>

            <SeatsCountSelector
              count={seatsCount}
              decreaseDisabled={manageSeatsState === 'minLimitReached' || seatsCount === 1}
              onDecrease={handleDecreaseSeat}
              onIncrease={handleIncreaseSeat}
            />
          </div>

          <div className={styles.row}>
            <Typography variant='body2' component='span'>
              Discount Code
            </Typography>

            <DiscountInput
              onApply={handleApplyCoupon}
              onRemove={handleRemoveCoupon}
              plan={user.clinicUser.subscriptionInfo!.planCode!}
              disabled={manageSeatsState !== 'seatsIncrease'}
            />
          </div>

          <div className={styles.devider}></div>

          <div className={styles.row}>
            <div className={styles.calculationsRow}>
              <Typography variant={isDesktop ? 'body1' : 'body5'} component='span' color='#393534'>
                Discount
              </Typography>

              <Typography variant={isDesktop ? 'body1' : 'body5'} component='span' color='#393534'>
                -${subscriptionProration?.initialDiscount.toFixed(2)}
              </Typography>
            </div>

            {manageSeatsState !== 'seatsIncrease' && (
              <div className={styles.calculationsRow}>
                <Typography variant='body4' component='span'>
                  {manageSeatsState === 'notChanged' ? 'Current Total' : 'New Total'}
                </Typography>

                <Typography variant='body4' component='span'>
                  ${subscriptionProration?.recurringChargeTotal.toFixed(2)}
                </Typography>
              </div>
            )}

            {manageSeatsState === 'seatsIncrease' && (
              <>
                <div className={styles.calculationsRow}>
                  <Typography variant={isDesktop ? 'body1' : 'body5'} component='span' color='#393534'>
                    New Total
                  </Typography>

                  <Typography variant={isDesktop ? 'body1' : 'body5'} component='span' color='#393534'>
                    ${subscriptionProration?.recurringChargeTotal.toFixed(2)}
                  </Typography>
                </div>

                <div className={styles.calculationsRow}>
                  <Typography variant='body4' component='span'>
                    Prorated Amount Due
                  </Typography>

                  <Typography variant='body4' component='span'>
                    ${subscriptionProration?.initialChargeAmount.toFixed(2)}
                  </Typography>
                </div>
              </>
            )}
          </div>

          {manageSeatsState === 'minLimitReached' && (
            <Typography color='#FF3D3D' variant='body1' component='span'>
              Total number of seats cannot be reduced to less than the number of currently active seats.
              <br />
              Please deactivate some of your staff to continue.
            </Typography>
          )}

          {manageSeatsState === 'seatsDecrease' && (
            <Typography color='#626363' variant='body1' component='span'>
              The prorated amount will be credited to balance in the next billing cycle.
            </Typography>
          )}

          {manageSeatsState === 'seatsIncrease' && (
            <Typography color='#626363' variant='body1' component='span'>
              Prorated amount will be charged immediately, and your new total will take effect on your next billing
              cycle.
            </Typography>
          )}

          <div className={styles.devider}></div>

          <div>
            <Typography variant={isDesktop ? 'body1' : 'body5'} component='span' color='#626363'>
              {renewsDate}
            </Typography>
          </div>

          <div className={styles.buttons}>
            {manageSeatsState === 'minLimitReached' && (
              <Button className={styles.manageStaff} variant='contained' onClick={handleManageStaff}>
                Manage staff
              </Button>
            )}

            <Button variant='outlined' onClick={onClose}>
              Cancel
            </Button>

            {manageSeatsState === 'minLimitReached' && (
              <Button variant='contained' disabled>
                Update
              </Button>
            )}

            {manageSeatsState === 'seatsDecrease' && (
              <Button variant='contained' onClick={handleDecrease}>
                Update
              </Button>
            )}

            {manageSeatsState === 'notChanged' && (
              <Button variant='contained' disabled>
                Pay now
              </Button>
            )}

            {manageSeatsState === 'seatsIncrease' && (
              <Button variant='contained' onClick={handleIncrease}>
                Pay ${subscriptionProration?.initialChargeAmount.toFixed(2)}
              </Button>
            )}
          </div>
        </Box>
      </Dialog>
    </Box>
  );
}
