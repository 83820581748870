import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogContent, DialogTitle, InputAdornment, Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';

import { FormInput, ModalTitle } from 'components';
import { ConfirmResultEnum } from 'context';
import { ApiUser } from 'types';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { getFullName } from 'utils';
import { useRequestPayments } from 'hooks';

import styles from './RequestPaymentDialog.module.scss';

type RequestPaymentFormType = {
  amount: number;
  reasonForPayment: string | null;
};

const schema = Yup.object().shape({
  amount: Yup.number()
    .typeError('Amount must be a number')
    .min(1, 'Amount must be greater than 0')
    .required('Amount is required'),
  reasonForPayment: Yup.string().nullable(),
});

export interface RequestPaymentDialogProps extends DialogProps {
  clientName: string;
  clientId: string;
  onConfirm(confirmResult: ConfirmResultEnum): void;
}

export function RequestPaymentDialog({ onConfirm, title, clientId, clientName, ...rest }: RequestPaymentDialogProps) {
  const user: ApiUser | null = useAppSelector(selectUser);
  const { requestPaymentFromClient } = useRequestPayments();

  const form = useForm<RequestPaymentFormType>({
    resolver: yupResolver(schema),
    defaultValues: { amount: 0, reasonForPayment: '' },
    mode: 'all',
  });

  const handleClose = () => {
    onConfirm(ConfirmResultEnum.Confirm);
  };

  const handleRequestPayment = () => {
    if (form.formState.isValid) {
      const formData: RequestPaymentFormType = form.getValues();
      requestPaymentFromClient({
        clientConnectionId: clientId,
        ammount: +formData.amount,
        description: formData.reasonForPayment,
      });
    }
    onConfirm(ConfirmResultEnum.Confirm);
  };

  return (
    <Dialog {...rest} className={styles.dialog}>
      <DialogTitle>
        <ModalTitle title={'Request Payment'} onClose={handleClose} />
      </DialogTitle>

      <DialogContent>
        <Typography variant='body2' component='span'>
          <b>Client: </b>
          {clientName}
        </Typography>

        <div className={styles.row}>
          <Typography variant='body2' component='span'>
            Provider/Organization Name
          </Typography>

          <FormInput name='organization' control={form.control} value={user?.clinicUser.organization} disabled />
        </div>

        <div className={styles.row}>
          <Typography variant='body2' component='span'>
            Provider
          </Typography>

          <FormInput name='provider' control={form.control} value={getFullName(user)} disabled />
        </div>

        <div className={styles.row}>
          <Typography variant='body2' component='span'>
            {'Amount (USD)'}
          </Typography>

          <FormInput
            name='amount'
            control={form.control}
            type='number'
            startAdornment={
              <InputAdornment position='start' sx={{ lineHeight: '100%', marginRight: '1rem' }}>
                <Typography variant='body2' component='span' sx={{ lineHeight: '100%', opacity: '0.8' }}>
                  $
                </Typography>
              </InputAdornment>
            }
          />
        </div>

        <div className={styles.row}>
          <Typography variant='body2' component='span'>
            Reason for Payment
          </Typography>

          <FormInput name='reasonForPayment' control={form.control} multiline />
        </div>

        <Button
          variant='contained'
          className={styles.formButton}
          onClick={handleRequestPayment}
          disabled={!form.formState.isValid}
        >
          Request payment
        </Button>
      </DialogContent>
    </Dialog>
  );
}
