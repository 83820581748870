import { useMemo, useState } from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Box } from '@mui/system';
import { Typography, Button, Dialog, DialogTitle, DialogContent } from '@mui/material';

import { ModalTitle } from 'components';
import { ITimer, useTimer } from 'hooks';
import { withOutsideClickPreventer } from 'utils';

import styles from './SubmitCodePopup.module.scss';

const RESEND_TIMER_DURATION = 60;

type SubmitCodePopupProps = {
  open: boolean;
  phone?: string;
  onSubmit: (code: string) => Promise<void>;
  onResendCode: () => void;
  onClose: () => void;
};

export const SubmitCodePopup = ({ open, phone, onSubmit, onResendCode, onClose }: SubmitCodePopupProps) => {
  const { seconds, startTimer }: ITimer = useTimer(RESEND_TIMER_DURATION);

  const [code, setCode] = useState<string>('');

  const isCodeValid: boolean = useMemo(() => code.length === 6 && /^\d+$/.test(code), [code]);

  const handleInput = (value: string): void => {
    setCode(value);
  };

  const handleSubmit = () => {
    if (isCodeValid) {
      onSubmit(code);
    }
  };

  const handleResendCode = () => {
    onResendCode();
    startTimer(RESEND_TIMER_DURATION);
  };

  return (
    <Dialog open={open} onClose={withOutsideClickPreventer(onClose)} className={styles.dialog}>
      <DialogTitle>
        <ModalTitle title='SET UP 2FA' onClose={onClose} />
      </DialogTitle>

      <DialogContent className={styles.dialogContent}>
        <Typography variant='body2'>
          A verification code has been sent to the phone number ending in {phone?.slice(-4)}.
        </Typography>

        <MuiOtpInput
          sx={{
            '& input': {
              padding: '0',
            },
          }}
          className={styles.input}
          value={code}
          onChange={handleInput}
          length={6}
          TextFieldsProps={{ InputProps: { componentsProps: { input: { pattern: '\\d*' } } } }}
          inputMode='numeric'
        />

        <Box className={styles.buttonsContaine}>
          <Button disabled={!!seconds} variant='outlined' onClick={handleResendCode}>
            Resend code
          </Button>

          <Button variant='contained' disabled={!isCodeValid} onClick={handleSubmit}>
            Submit
          </Button>
        </Box>

        {!!seconds && <Typography variant='body1'>You will be able to resend code in {seconds} seconds</Typography>}
      </DialogContent>
    </Dialog>
  );
};
