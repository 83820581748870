import { Theme } from '@mui/material';

export const overrides = (theme: Theme): any => {
  return {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'textVariant_32_500',
          h2: 'textVariant_24_500',
          h3: 'textVariant_16_500',
          subtitle1: 'textVariant_16_400',
          subtitle2: 'textVariant_12_400',
          body: 'textVariant_16_400',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
          padding: '0.7rem 1.5rem',
          borderRadius: '0.3rem',
          fontSize: '1.2rem',
          fontWeight: 700,
          lineHeight: '1.6rem',
          height: '3rem',
          textTransform: 'none',
          boxShadow: 'none !important',
          [theme.breakpoints.down('lg')]: {
            ['@media (orientation: landscape)']: {
              fontSize: '1.5rem',
              lineHeight: '2rem',
              height: '3.5rem',
              padding: '0.75rem 1.35rem',
            },
          },
          [theme.breakpoints.down('md')]: {
            fontSize: '1.5rem',
            lineHeight: '2rem',
            height: '3.5rem',
            padding: '0.75rem 1.35rem',
          },
        },
        containedPrimary: {
          backgroundColor: '#81CFDD',
          '&:hover': {
            backgroundColor: '#81CFDD',
            opacity: 0.8,
          },
          '&.Mui-disabled': {
            backgroundColor: '#979797',
            color: '#FFF',
            // opacity: 0.5
          },
        },
        outlined: {
          color: '#81CFDD',
          '&:hover': {
            borderColor: '#81CFDD',
          },
          '&.Mui-disabled': {
            borderColor: '#96969699',
          },
        },
        outlinedPrimary: {
          background: 'none',
          border: '1px solid #81CFDD',
          text: {
            color: '#81CFDD',
          },
        },
        text: {
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '1.2rem',
          lineHeight: '1.6rem',
          '&:hover': {
            color: '#81CFDD !important',
          },
        },
        textPrimary: {
          color: '#81CFDD',
          '&:hover': {
            color: '#FFF',
          },
          '&.Mui-disabled': {
            color: '#FFF',
            opacity: 0.5,
          },
        },
        textSecondary: {
          color: '#626363',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
        rounded: {
          borderRadius: '10px',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: 3,
          padding: '5px 0',
        },
        thumb: {
          width: 11,
          height: 11,
        },
        track: {
          border: 'none',
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          zIndex: 999,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: '#626363',
          [theme.breakpoints.down('md')]: {
            right: '1.2rem',
            width: '1.2rem !important',
          },
        },
        select: {
          minHeight: 'auto',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: 10,

          '@media(min-width: 3840px)': {
            fontSize: 20,
          },
        },
        body: {
          fontSize: '1.4rem',
          backgroundColor: 'transparent',
          // minHeight: '100vh',
        },
        '#root': {
          minHeight: 'inherit',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          paddingTop: '1.2rem',
          paddingBottom: '1.2rem',
          color: '#000',
          fontFamily: 'Open Sans',
          fontSize: '1.7rem',
          fontStyle: 'normal',
          fontWeight: 400,
          '&::placeholder': {
            color: '#D1D1D1',
            opacity: 1,
          },
        },
        root: {
          '&:before': {
            borderBottom: '1px solid #FFF',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-error:before': {
            borderBottom: '1px solid #F52F2F',
          },
          '&::placeholder': {
            color: '#D1D1D1',
          },
        },
        input: {
          color: '#393534',
          fontSize: '1.4rem',
          lineHeight: '1.9rem',
          padding: '0.55rem 1.5rem',
          '&::placeholder': {
            color: '#000',
            fontSize: '1.4rem',
            [theme.breakpoints.down('lg')]: {
              ['@media (orientation: landscape)']: {
                fontSize: '1.5rem',
              },
            },
            [theme.breakpoints.down('md')]: {
              fontSize: '1.5rem',
            },
          },
          '&.Mui-disabled': {
            WebkitTextFillColor: 'rgb(0 0 0)',
            backgroundColor: '#E8E8E8',
          },
          [theme.breakpoints.down('lg')]: {
            ['@media (orientation: landscape)']: {
              fontSize: '1.5rem',
              lineHeight: '2rem',
              padding: '7.5px 15px',
            },
          },
          [theme.breakpoints.down('md')]: {
            fontSize: '1.5rem',
            lineHeight: '2rem',
            padding: '7.5px 15px',
          },
        },
        adornedStart: {
          paddingLeft: '14px',
        },
        disabled: {},
        [theme.breakpoints.down('md')]: {
          root: {
            '&.Mui-error:before': {
              borderBottom: '1px solid #F52F2F',
            },
            '&::placeholder': {
              color: '#D1D1D1',
            },
          },
          input: {
            fontSize: '1.5rem',
            lineHeight: '2rem',
            padding: '7.5px 15px',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
          lineHeight: '1.6rem',
          borderRadius: '3px',

          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D1D1D1',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D1D1D1',
            borderWidth: 1,
          },
        },
        multiline: {
          padding: 0,
          height: 70,
          alignItems: 'flex-start',
        },
        input: {
          height: 'inherit',
          fontWeight: 500,
          padding: '0.55rem 1.5rem',
          borderRadius: '3px',
        },
        inputMultiline: {
          height: 'calc(100% - 2rem) !important',
          fontSize: '1.4rem',
          lineHeight: '1.6rem',
          padding: '1rem 1.5rem',

          '&::placeholder': {
            fontSize: '1.4rem',
          },
        },
        inputAdornedStart: {
          paddingLeft: 0,
          marginLeft: -8,
        },
        notchedOutline: {
          borderRadius: '3px',
          borderColor: '#D1D1D1',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: '#000',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '1.5rem',
          lineHeight: '2rem',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperFullScreen: {
          margin: 0,
          width: '100%!important',
          borderRadius: '0!important',
          backgroundColor: '#FAFAFA!important',
        },
        paper: {
          borderRadius: '1rem !important',
          background: '#FFF',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
        },
      },
    },
    MuiGrid2: {
      defaultProps: {
        disableEqualOverflow: true,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          background: '#F3F3F3',
          padding: '7px 20px',
          minHeight: 46,
        },
        title: {
          fontSize: '1.5rem',
          fontWeight: 700,
          color: '#393534',
          textTransform: 'uppercase',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '5px 20px',

          '&:last-child': {
            paddingBottom: '5px',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          whiteSpace: 'break-spaces',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          whiteSpace: 'break-spaces',
          padding: '10px 0',
          margin: 0,
        },
        gutters: {
          padding: 0,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        dense: {
          padding: 0,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'initial',
          marginRight: '1rem',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '20px 20px 15px 20px',
        },
      },
    },
    MuiListItemSecondaryAction: {
      styleOverrides: {
        root: {
          right: 0,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
        },
        primary: {
          fontWeight: 400,
          fontSize: '1.4rem',
          lineHeight: '1.9rem',
          letterSpacing: '-0.336px',
          color: '#393534',
        },
        secondary: {
          fontWeight: 700,
          fontSize: '1.1rem',
          lineHeight: '1.5rem',
          letterSpacing: '-0.336px',
          color: '#3EB157',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          overflowY: 'none',
          padding: 20,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          overflowY: 'none',
          padding: '0 20px 20px',
          '&>:not(:first-of-type)': {
            marginLeft: 10,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(136, 136, 136, 0.15)',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: '#626363',
          background: '#F3F3F3',
          width: 143,
          padding: '5px 15px',
          borderRadius: 3,
          fontSize: '1rem',
          fontWeight: 400,
          lineHeight: '1.4rem',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: 'rgba(0, 0, 0, 0.3)',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: '28px',
          height: '16px',
          padding: 0,
          display: 'flex',
          '&:active': {
            '& .MuiSwitch-thumb': {
              width: 12,
              [theme.breakpoints.down('md')]: {
                width: '1.6rem',
              },
            },
          },
          [theme.breakpoints.down('md')]: {
            width: '4.1rem',
            height: '2.4rem',
          },
        },
        switchBase: {
          padding: 2,
          '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: '#81CFDD',
            },
            [theme.breakpoints.down('md')]: {
              transform: 'translateX(1.6rem)',
            },
          },
          [theme.breakpoints.down('md')]: {
            padding: '0.3rem',
          },
        },
        thumb: {
          boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
          width: 12,
          height: 12,
          borderRadius: 6,
          [theme.breakpoints.down('md')]: {
            width: '1.8rem',
            height: '1.8rem',
            borderRadius: '50%',
          },
        },
        track: {
          borderRadius: 16 / 2,
          opacity: 1,
          backgroundColor: '#D1D1D1',
          boxSizing: 'border-box',
          [theme.breakpoints.down('md')]: {
            borderRadius: '1.2rem',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorDisabled: {
          color: '#D1D1D1',
        },
      },
    },
  };
};
