/* eslint-disable prettier/prettier */

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Theme, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import classnames from 'classnames';
import { useSnackbar } from 'notistack';

import { SUCCESS_OPERATION_ACTION_TYPE_CARD_CHANGE, Subscriptions } from 'constants/payment';
import { CrownIcon } from 'assets';
import { ManageSeatsPopup } from '../ManageSeatsPopup/ManageSeatsPopup';
import { ConfirmResultEnum } from 'context';
import { useApiRequests, useDialog, useSubscription } from 'hooks';
import { getUser, selectUser } from 'store/user';
import { ApiUser, ClinicSubscriptionInfo, SubscriptionStatusEnum, UpdateCustomerCurrentCardResponse } from 'types';
import { useAppDispatch } from 'store';
import { SubscriptionDetailsPopup } from '../SubscriptionDetailsPopup';

import styles from './Live.module.scss';

const billingPeriodName = {
  ['YEARLY']: 'year',
  ['MONTHLY']: 'month',
};

export const Live = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const dialog = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const apiRequests = useApiRequests();
  const dispatch = useAppDispatch();
  const subscription = useSubscription();
  const user: ApiUser | null = useSelector(selectUser);

  const isLive = useMemo<boolean>(() => {
    return subscription?.subscriptionStatus === SubscriptionStatusEnum.Live;
  }, [user]);

  const isNonRenewing = useMemo<boolean>(() => {
    return subscription?.subscriptionStatus === SubscriptionStatusEnum.NonRenewing;
  }, [user]);

  const isCancelled = useMemo<boolean>(() => {
    return subscription?.subscriptionStatus === SubscriptionStatusEnum.Cancelled;
  }, [user]);

  const subscriptionInfo = useMemo<ClinicSubscriptionInfo | null | undefined>(() => {
    return user?.clinicUser.subscriptionInfo;
  }, [user]);

  const handleKeepMyPlan = async () => {
    const response = await apiRequests.reactivateCurrentSubscription();
    if (response.code === Subscriptions.Responses.StatusCodes.Success) {
      dispatch(getUser()).unwrap();
    }
  };

  const handleUpgradePlan = async () => {
    if (isMobile) {
      navigate('/subscriptionDetails');
      return;
    }
    
    const confirmationResult = await dialog.confirm({ content: SubscriptionDetailsPopup });
    if (confirmationResult === ConfirmResultEnum.Confirm) {
      dispatch(getUser()).unwrap();
    }
  };

  const handleChangePaymentMethod = (): void => {
    apiRequests
      .updateCustomerCurrentCard({
        redirectUrl: `${process.env.REACT_APP_PUBLIC_URI}/successPayment?type=${SUCCESS_OPERATION_ACTION_TYPE_CARD_CHANGE}&redirectTo=settings&redirectToHash=subscriptions`,
      })
      .then((response: UpdateCustomerCurrentCardResponse) => {
        window.location.href = response.page.url;
      })
      .catch((error) => {
        enqueueSnackbar(`Change payment method error: ${error.message}`, { variant: 'error' });
      });
  };

  const handleManageSeats = async () => {
    if (isMobile) {
      navigate('/manageSeats');
      return;
    }

    const confirmationResult = await dialog.confirm({
      content: ManageSeatsPopup,
      props: { totalSeats: subscription?.totalSeats, availableSeats: subscription?.availableSeats },
    });
    if (confirmationResult === ConfirmResultEnum.Confirm) {
      dispatch(getUser()).unwrap();
    }
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.innerContainer}>
        <Box className={styles.seatsContainer}>
          <Typography variant='body2'>
            <span className={styles.seatsCounter}>{subscription?.availableSeats}</span> Available Seats
          </Typography>
          <Typography variant='body2'>
            <span className={styles.seatsCounter}>{subscription?.totalSeats}</span> Total Seats
          </Typography>
        </Box>

        {isLive && (
          <>
            <Typography className={styles.sectionTitle} variant='body2'>
              Subscription for {user?.clinicUser.organization}
            </Typography>
            <Box className={styles.borderContainer}>
              <Box className={styles.subscriptionContainer}>
                <Box className={styles.iconBorderContainer}>
                  <CrownIcon className={styles.icon} />
                </Box>

                <Box className={styles.subscriptionInfo}>
                  <Typography variant='body2'>
                    <span className={styles.seatsCounter}>{subscription?.totalSeats} Seats</span>
                  </Typography>
                  <Typography variant='body1' className={styles.subLabel}>
                    Next bill on {moment(subscriptionInfo?.currentTermEndsAt).format('MMM DD, YYYY')}
                  </Typography>
                  <Typography variant='body1' className={styles.subLabel}>
                    {`$${subscriptionInfo?.price}/${Reflect.get(billingPeriodName, subscriptionInfo?.planName || '')}`}
                  </Typography>
                </Box>
                <Typography variant='body2' className={styles.subscriptionType}>
                  {subscriptionInfo?.planName?.toLowerCase()}
                </Typography>
              </Box>
              <Button variant='contained' className={styles.button} onClick={handleManageSeats}>
                Manage seats
              </Button>
            </Box>
          </>
        )}

        {isNonRenewing && (
          <>
            <Typography className={styles.sectionTitle} variant='body2'>
              Subscription for {user?.clinicUser.organization}
            </Typography>
            <Box className={styles.borderContainer}>
              <Box className={styles.subscriptionContainer}>
                <Box className={styles.iconBorderContainer}>
                  <CrownIcon className={styles.icon} />
                </Box>
                <Box className={styles.subscriptionInfo}>
                  <Typography variant='body2'>
                    <span className={styles.seatsCounter}>{subscription?.totalSeats} Seats</span>
                  </Typography>
                  <Typography variant='body1' className={styles.subLabel}>
                    Your seats will end on {moment(subscriptionInfo?.currentTermEndsAt).format('MMMM D, YYYY')}.
                  </Typography>
                  <Typography variant='body1' className={styles.subLabel}>
                    After that date, you will lose access to your subscription benefits and your card will not be
                    charged.
                  </Typography>
                </Box>
                <Typography variant='body2' className={classnames(styles.subscriptionType, styles.nonRenewing)}>
                  Cancelled
                </Typography>
              </Box>
              <Button
                variant='contained'
                className={classnames(styles.button, styles.nonRenewing)}
                onClick={handleKeepMyPlan}
              >
                Keep my plan
              </Button>
            </Box>
          </>
        )}

        {isCancelled && (
          <>
            <Typography className={styles.sectionTitle} variant='body2'>
              Subscription for {user?.clinicUser.organization}
            </Typography>
            <Box className={styles.borderContainer}>
              <Box className={styles.subscriptionContainer}>
                <Box className={styles.iconBorderContainer}>
                  <CrownIcon className={styles.icon} />
                </Box>
                <Box className={styles.subscriptionInfo}>
                  <Typography variant='body2'>
                    <span className={styles.seatsCounter}>{subscription?.totalSeats} Seats</span>
                  </Typography>
                  <Typography variant='body1' className={styles.subLabel}>
                    Your seats has ended.
                  </Typography>
                  <Typography variant='body1' className={styles.subLabel}>
                    You has lost access to your subscription benefits.
                  </Typography>
                </Box>
                <Typography variant='body2' className={classnames(styles.subscriptionType, styles.nonRenewing)}>
                  Cancelled
                </Typography>
              </Box>
              <Button
                variant='contained'
                className={classnames(styles.button, styles.nonRenewing)}
                onClick={handleUpgradePlan}
              >
                Upgrade my plan
              </Button>
            </Box>
          </>
        )}

        <Typography className={styles.sectionTitle} variant='body2'>
          Payment Method
        </Typography>
        <Box className={styles.borderContainer}>
          <Typography className={styles.cardEnding} variant='body2'>
            Card ending in {subscriptionInfo?.cardLastFourDigits}
          </Typography>
          <Typography className={classnames(styles.cardExpireDate, styles.subLabel)} variant='body1'>
            Expires&nbsp;
            {moment(
              `${subscriptionInfo?.cardExpirationYear}-${
                (subscriptionInfo?.cardExpirationMonth ?? 1) < 10
                  ? '0' + subscriptionInfo?.cardExpirationMonth
                  : subscriptionInfo?.cardExpirationMonth
              }`,
              'YYYY-MM',
            ).format('MMM YYYY')}
          </Typography>
          <Button variant='contained' className={styles.button} onClick={handleChangePaymentMethod}>
            Change payment method
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
