import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CouponExpiredDialog, DuplicateEmailDialog, VerifyEmailDialog } from 'components';
import { useAppDispatch, useAppSelector } from 'store';
import { getUser, selectUser } from 'store/user';
import { IApiRequests, useApiRequests, useDialog, useLocalStorageState, useUser } from 'hooks';
import {
  ApiUser,
  Coupon,
  CreateHostedPageResponse,
  GetCouponSubscriptionApiResponse,
  RegisterRequest,
  UpdateClinicUserRequest,
  UpdateUserRequest,
} from 'types';
import { RegistrationContext } from './context';
import { IUserContext } from 'context';
import { SUCCESS_OPERATION_ACTION_TYPE_SUBSCRIPTION_CHANGE } from 'constants/payment';
import { COUPON } from 'constants/index';

type RegistrationProviderProps = {
  children?: JSX.Element;
};

export type RegistrationStage = {
  stage: number;
};

export type RegistrationModelData = {
  registerRequest: RegisterRequest | null;
  codeHash: string | null;
};

export function RegistrationProvider({ children }: RegistrationProviderProps) {
  const { showDialog } = useDialog();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { logout }: IUserContext = useUser();
  const user: ApiUser | null = useAppSelector(selectUser);

  const {
    register,
    resendConfirmEmail,
    updateProfilePicture,
    updateUser,
    updateClinicUser,
    checkEmailExists,
    skipCreateRoom,
    getCoupon,
    createSubscriptionWithHostedPages,
    ensureRoomNameIsUnique,
  }: IApiRequests = useApiRequests();

  // const [codeHash, setCodeHash] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [stage, setStage] = useState<number>(1);
  const [coupon, _setCoupon] = useLocalStorageState<Coupon | null>(COUPON, null);

  useEffect(() => {
    if (!user) {
      setStage(1);
      return;
    }

    if (user && !user.clinicUser.createRoomSkipped) {
      setStage(2);
      return;
    }

    if (user && user.clinicUser.createRoomSkipped && (coupon || !user.clinicUser.subscriptionInfo)) {
      setStage(3);
      return;
    }

    if (user && user.clinicUser.createRoomSkipped && !coupon && user.clinicUser.subscriptionInfo) {
      navigate('/');
    }
  }, [user, coupon]);

  const setCoupon = (promocode: string): void => {
    getCoupon(promocode)
      .then((response: GetCouponSubscriptionApiResponse) => {
        _setCoupon(response.coupon);
      })
      .catch((error) => {
        _setCoupon(null);
        const modal: any = showDialog(CouponExpiredDialog, {
          text: error.message,
          onConfirm: () => modal.destroy(),
        });
      });
  };

  const onRegisterFormSubmit = async (model: RegisterRequest): Promise<void> => {
    const onModalConfirm = (modal: any): void => {
      modal.destroy();
      navigate('../login');
    };

    setLoading(true);

    let emailExists = false;

    try {
      emailExists = await checkEmailExists(model.email);
    } catch (error: any) {
      setLoading(false);
      return Promise.reject(`Check email error: ${error.message}`);
    }

    if (emailExists) {
      setLoading(false);

      const modal: any = showDialog(DuplicateEmailDialog, {
        onConfirm: () => onModalConfirm(modal),
      });
    } else {
      return register(model)
        .then(() => {
          showDialog(VerifyEmailDialog, {
            email: model.email,
            initialTimerValue: 60,
            onConfirm: () => resendConfirmEmail(model.email),
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onCreateRoomFormSubmit = (
    profilePicture: File | null,
    user: UpdateUserRequest,
    clinicUser: UpdateClinicUserRequest,
  ): Promise<void> => {
    setLoading(true);

    const ensureRoomNameIsUniquePromise = (): Promise<void> => {
      return ensureRoomNameIsUnique(clinicUser.roomName ?? '').catch((error) => {
        return Promise.reject(`Update clinic user failed: ${error.message}`);
      });
    };

    const updateImagePromise = (): Promise<void> => {
      return updateProfilePicture(profilePicture).catch((error) => {
        return Promise.reject(`Unable to update profile picture: ${error.message}`);
      });
    };

    const updateClinicUserPromise = (): Promise<any> => {
      return updateClinicUser(clinicUser).catch((error: any) => {
        return Promise.reject(`Update clinic user failed: ${error.message}`);
      });
    };

    const skipCreateRoomPromise = (): Promise<any> => {
      return skipCreateRoom().catch((error: any) => {
        return Promise.reject(`Update clinic user failed: ${error.message}`);
      });
    };

    const updateUserPromise = (): Promise<any> => {
      return updateUser(user).catch((error: any) => {
        return Promise.reject(`Update user failed: ${error.message}`);
      });
    };

    return ensureRoomNameIsUniquePromise()
      .then(() => updateImagePromise())
      .then(() => updateClinicUserPromise())
      .then(() => skipCreateRoomPromise())
      .then(() => updateUserPromise())
      .then(() => dispatch(getUser()).unwrap())
      .then(() => navigate('../'))
      .finally(() => {
        setLoading(false);
      });
  };

  const onCreateRoomFormSkip = (): Promise<void> => {
    setLoading(true);
    return skipCreateRoom()
      .then(() => dispatch(getUser()).unwrap())
      .then(() => {
        navigate('../');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onCreateRoomFormBack = (): void => {
    logout();
  };

  const onUpdateSubscription = (planCode: string, quantity: number, coupon: string): Promise<void> => {
    setLoading(true);

    return createSubscriptionWithHostedPages({
      planCode,
      quantity,
      couponCode: coupon,
      redirectUrl: `${process.env.REACT_APP_PUBLIC_URI}/successPayment?type=${SUCCESS_OPERATION_ACTION_TYPE_SUBSCRIPTION_CHANGE}&redirectTo=`,
    })
      .then((response: CreateHostedPageResponse) => {
        _setCoupon(null);
        window.location.href = response.page.url;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onLeaveOnTrial = (): void => {
    _setCoupon(null);
  };

  // temporary hidden
  // const onVerifyPhoneFormSendCode = (phone: string, twoFactor: TwoFactor): Promise<void> => {
  //   setLoading(true);

  //   const updatePhonePromise = (phone: string) => {
  //     if (phone === user?.PhoneNumber) {
  //       return Promise.resolve();
  //     }

  //     return updateUser({ ...user, PhoneNumber: phone });
  //   };

  //   return updatePhonePromise(phone)
  //     .then(() => {
  //       return sendCodeToPhone(phone, twoFactor);
  //     })
  //     .then((response: SendCodeToPhoneResponse) => setCodeHash(response.VerificationCodeHash))
  //     .catch((error) => {
  //       return Promise.reject(`Error during code sending: ${error.message}`);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });

  //   return Promise.resolve();
  // };

  // const onVerifyPhoneFormSubmit = (phone: string, code: string): Promise<void> => {
  //   setLoading(true);

  //   return verifyPhoneCode(phone, code, codeHash!)
  //     .then((result: boolean) => {
  //       if (!result) {
  //         return Promise.reject(new Error('Ivalid phone or code'));
  //       }
  //       return dispatch(getUser()).unwrap();
  //     })
  //     .then(() => {
  //       navigate('../');
  //     })
  //     .catch((error) => {
  //       return Promise.reject(`Error during code verification: ${error.message}`);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  return (
    <RegistrationContext.Provider
      value={{
        stage,
        isCoupon: !!coupon,
        loading,
        // codeHash,
        setCoupon,
        onRegisterFormSubmit,
        onCreateRoomFormSubmit,
        onCreateRoomFormSkip,
        onCreateRoomFormBack,
        onUpdateSubscription,
        onLeaveOnTrial,
        // onVerifyPhoneFormSubmit,
        // onVerifyPhoneFormSendCode,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
}
