import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { ModalTitle } from 'components';
import { useAppSelector } from 'store';
import { selectWaitingRoomProfile } from 'store/waitingRoom';
import { getProviderFullName } from 'utils';

import styles from './MakePaymentDialog.module.scss';

export interface MakePaymentDialogProps extends DialogProps {
  clientSecret: string;
  amount: number;
  description: string | null;
  onClose(): void;
}

export function MakePaymentDialog({
  onClose,
  title,
  clientSecret,
  amount,
  description,
  ...rest
}: MakePaymentDialogProps) {
  const waitingRoomProfile = useAppSelector(selectWaitingRoomProfile);

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRequestSent, setIsRequestSent] = useState<boolean>(false);

  const formRef = useRef<HTMLButtonElement>(null);

  const formattedAmount: string = useMemo(() => {
    return '$' + amount.toFixed(2);
  }, [amount]);

  useEffect(() => {
    if (!stripe || !isRequestSent || !clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          // onSuccess();
          console.log('success');
          break;
        case 'processing':
          setIsLoading(true);
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe, clientSecret, isRequestSent]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: '',
      },
      redirect: 'if_required',
    });

    console.log(error);
    console.log(paymentIntent);

    if (paymentIntent?.status === 'succeeded') {
      console.log('success');
    }

    if (error) {
      setMessage(error.message ?? null);
    }

    setIsRequestSent(true);
    setIsLoading(false);
  };

  const handleClose = () => {
    onClose();
  };

  const handlePay = () => {
    formRef.current?.click();
  };

  return (
    <Dialog {...rest} className={styles.dialog}>
      <DialogTitle>
        <ModalTitle title={'Payment'} onClose={handleClose} />
      </DialogTitle>

      <DialogContent>
        {message && (
          <Box className={styles.error}>
            <Typography variant='body1' color='white'>
              {message}
            </Typography>
          </Box>
        )}

        <Stack>
          <Typography variant='body4'>Provider/Organization Name:</Typography>

          <Typography variant='body2'>{getProviderFullName(waitingRoomProfile)}</Typography>

          <Typography variant='body2'>{waitingRoomProfile?.organization}</Typography>
        </Stack>

        <Stack>
          <Typography variant='body4'>Amount:</Typography>

          <Typography variant='body2'>{formattedAmount}</Typography>
        </Stack>

        {description && (
          <Stack>
            <Typography variant='body4'>Reason for Payment:</Typography>

            <Typography variant='body2'>{description}</Typography>
          </Stack>
        )}

        <form onSubmit={handleSubmit}>
          <PaymentElement id='payment-element' options={{ layout: 'tabs' }} />
          <button hidden ref={formRef} type='submit'></button>
        </form>

        <Stack className={styles.formButton}>
          <Button variant='outlined' onClick={handleClose}>
            Decline
          </Button>

          <Button variant='contained' disabled={isLoading || !stripe || !elements} onClick={handlePay}>
            {'Pay ' + formattedAmount}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
