import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';
import ReCAPTCHA from 'react-google-recaptcha';

import { FormInput, Info } from 'components';
import { RegisterRequest, TrackingEventsEnum } from 'types';
import { IRegistrationContext } from 'context';
import { useRegistration, useTrackingAnalytics } from 'hooks';

import styles from './RegisterForm.module.scss';

YupPassword(Yup);

const schema = Yup.object().shape({
  firstName: Yup.string().label('First Name').max(50, 'Max length is 50 character').required(),
  lastName: Yup.string().label('Last Name').max(50, 'Max length is 50 character').required(),
  email: Yup.string().label('Email').email('Email must be a valid').required(),
  organization: Yup.string().label('Organization').min(2).max(100, 'Max length is 100 character').required(),
  password: Yup.string()
    .label('Password')
    .min(
      8,
      'Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special character',
    )
    .minLowercase(1, 'Password must contain at least 1 lower case letter')
    .minUppercase(1, 'Password must contain at least 1 upper case letter')
    .minNumbers(1, 'Password must contain at least 1 number')
    .minSymbols(1, 'Password must contain at least 1 special character')
    .required(),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match'),
  captcha: Yup.string().required(),
});

export function RegisterForm() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const { onRegisterFormSubmit: onSubmit }: IRegistrationContext = useRegistration();

  const trackingAnalyticsContext = useTrackingAnalytics();
  const recaptchaRef = useRef<ReCAPTCHA>();
  const location = useLocation();

  const form = useForm<RegisterRequest>({
    resolver: yupResolver(schema),
    defaultValues: {},
    mode: 'all',
  });

  const handleSubmit = (): void => {
    if (form.formState.isValid) {
      onSubmit(form.getValues())
        .then(() => {
          trackingAnalyticsContext.trackEvent(TrackingEventsEnum.CompleteRegistration, {
            email: form.getValues().email,
          });
        })
        .catch((error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
          recaptchaRef.current?.reset();
          handleCaptchaChange(null);
        });
    }
  };

  const handleCaptchaChange = (token: string | null): void => {
    form.setValue('captcha', token ?? '');
    form.trigger('captcha');
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (location.pathname === '/register' && searchParams.get('step') !== 'createRoom') {
      trackingAnalyticsContext.trackEvent(TrackingEventsEnum.StartSignup);
    }
  }, []);

  return (
    <div className={styles.form}>
      <div className={styles.rowWithTwoInputs}>
        <Stack className={styles.row}>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Typography component='p' variant='body2'>
              First Name <span className={styles.asterisk}>*</span>
            </Typography>
          </Stack>
          <FormInput name='firstName' control={form.control} />
        </Stack>

        <Stack className={styles.row}>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Typography component='p' variant='body2'>
              Last Name <span className={styles.asterisk}>*</span>
            </Typography>
          </Stack>
          <FormInput name='lastName' control={form.control} />
        </Stack>
      </div>

      <Stack className={styles.row}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography component='p' variant='body2'>
            Email <span className={styles.asterisk}>*</span>
          </Typography>
        </Stack>
        <FormInput name='email' control={form.control} />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography component='p' variant='body2'>
            Provider/Organization Name <span className={styles.asterisk}>*</span>
          </Typography>
          <Info
            children={
              <Typography variant='body6'>
                Enter your organization
                <br />
                name. If you are a sole <br />
                practitioner, just enter <br />
                your business name.
              </Typography>
            }
          />
        </Stack>
        <FormInput name='organization' control={form.control} />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography component='p' variant='body2'>
            Password <span className={styles.asterisk}>*</span>
          </Typography>
          <Info text='Password must be at least 8 characters including at least one uppercase and lowercase letter, one symbol and one number' />
        </Stack>
        <FormInput name='password' control={form.control} type='password' />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography component='p' variant='body2'>
            Confirm Password <span className={styles.asterisk}>*</span>
          </Typography>
        </Stack>
        <FormInput name='passwordConfirmation' control={form.control} type='password' />
      </Stack>

      <Stack justifyContent='center' className={styles.captcha}>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? ''}
          size={isMobile ? 'compact' : 'normal'}
          onChange={handleCaptchaChange}
          ref={recaptchaRef as any}
        />
      </Stack>

      <Stack className={styles.row} alignItems='flex-end'>
        <Button
          id='RegisterFormNext'
          variant='contained'
          onClick={handleSubmit}
          disabled={!form.formState.isValid}
          className={styles.formButton}
          type='submit'
        >
          Next
        </Button>
      </Stack>
    </div>
  );
}
