import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function MovementLeftRightIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='20' height='10' viewBox='0 0 20 10' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M15.2633 1.73717L15.2633 3.53635L5.73669 3.53635L5.73669 1.73717C5.73669 0.901941 4.72689 0.483661 4.13627 1.07425L0.774599 4.43592C0.408467 4.80205 0.408467 5.3956 0.774599 5.76173L4.13627 9.1234C4.72685 9.71399 5.73669 9.29571 5.73669 8.46047L5.73669 6.66134L15.2633 6.66134L15.2633 8.46051C15.2633 9.29574 16.2731 9.71402 16.8637 9.12344L20.2254 5.76177C20.5915 5.39564 20.5915 4.80209 20.2254 4.43595L16.8637 1.07428C16.2731 0.483661 15.2633 0.901941 15.2633 1.73717Z' />
    </SvgIcon>
  );
}
