import React from 'react';
import { Button, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { ModalTitle } from 'components/index';

import styles from './TwoOptionsDialog.module.scss';

export interface TwoOptionsDialogProps extends DialogProps {
  title?: string;
  text: string | JSX.Element;
  cancelText?: string;
  confirmText?: string;
  onConfirm: () => void;
  onClose?: () => void;
}

export function TwoOptionsDialog({
  title,
  text,
  cancelText,
  confirmText,
  onConfirm,
  onClose,
  ...rest
}: TwoOptionsDialogProps) {
  return (
    <Dialog {...rest} className={styles.dialog}>
      {title && (
        <DialogTitle>
          <ModalTitle title={title} onClose={onClose} />
        </DialogTitle>
      )}

      <DialogContent>
        {text instanceof String ? (
          <Typography component='p' variant='body2'>
            {text}
          </Typography>
        ) : (
          text
        )}

        <Stack direction='row' spacing={'1rem'} alignItems='center'>
          <Button variant='outlined' fullWidth className={styles.formButton} onClick={onClose}>
            {cancelText ?? 'Cancel'}
          </Button>
          <Button variant='contained' fullWidth className={styles.formButton} onClick={onConfirm}>
            {confirmText ?? 'Ok'}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
