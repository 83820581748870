import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function MoveIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M19.7426 7.89222L1.6958 1.04688L8.54114 19.0937L11.6527 11.0038L19.7426 7.89222Z'
        strokeWidth='1.89966'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  );
}
