import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import patientQueueReducer from './patientQueue/patientQueueSlice';
import userReducer from './user/userSlice';
import inviteReducer from './invite/inviteSlice';
import patientReducer from './patient/patientSlice';
import waitingRoomReducer from './waitingRoom/waitingRoomSlice';
import callReducer from './call/callSlice';

export const reducer = {
  patientQueue: patientQueueReducer,
  user: userReducer,
  invite: inviteReducer,
  patient: patientReducer,
  waitingRoom: waitingRoomReducer,
  call: callReducer,
};

export function makeStore() {
  return configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
}

const store = makeStore();

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
