/* eslint-disable prettier/prettier */
import { FC, ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';

export type AINotesTranscriptionSettingsSelectorWrapperProps = {
  labelSlot: string;
  selectorSlot: ReactNode;
};

export const AINotesTranscriptionSettingsSelectorWrapper: FC<AINotesTranscriptionSettingsSelectorWrapperProps> = ({
  labelSlot,
  selectorSlot,
}) => (
  <Stack
    direction='column'
    alignContent='center'
    justifyContent='space-between'
    gap='7px'>
    <Stack
      direction='row'
      alignItems='center'>
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: 400,
          lineHeight: '19.07px',
          textAlign: 'left',
        }}>
        {labelSlot}
      </Typography>
    </Stack>
    {selectorSlot}
  </Stack>
);
