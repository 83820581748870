import { PatientList } from 'components';
import { MenuItem } from '../MenuItem';

type ClientQueueMenuProps = {
  className?: string;
};

export function ClientQueueMenu({ className }: ClientQueueMenuProps): JSX.Element {
  return (
    <>
      <MenuItem className={className} title='Client Queue'>
        <PatientList isCallStarted />
      </MenuItem>
    </>
  );
}
