import React, { useMemo, useState } from 'react';
import { LocalVideoTrack } from 'twilio-video';
import { MenuItem, Select } from '@mui/material';
import { ChevronRightIcon } from 'assets';
import { useDevices, useLocalVideoToggle, useMediaStreamTrack, useVideo } from 'hooks';

import { SELECTED_VIDEO_INPUT_KEY } from '../../../constants';

export function VideoInputList() {
  const { videoInputDevices } = useDevices();
  const { localTracks } = useVideo();
  const { resetVideo } = useLocalVideoToggle();

  const localVideoTrack = useMemo(
    () => localTracks.find((track) => track.kind === 'video') as LocalVideoTrack | undefined,
    [localTracks],
  );
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY),
  );
  const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;

  function replaceTrack(newDeviceId: string) {
    // Here we store the device ID in the component state. This is so we can re-render this component display
    // to display the name of the selected device when it is changed while the users camera is off.
    setStoredLocalVideoDeviceId(newDeviceId);
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    if (localVideoTrack) {
      resetVideo();
    }
  }

  const isDisabled = videoInputDevices.length <= 1;

  return (
    <>
      <Select
        fullWidth
        variant='outlined'
        disabled={isDisabled}
        IconComponent={ChevronRightIcon}
        value={!isDisabled ? localVideoInputDeviceId || '' : 'default'}
        onChange={(e) => replaceTrack(e.target.value as string)}
        sx={{
          svg: {
            width: '10px',
          },
        }}
      >
        {!isDisabled ? (
          videoInputDevices.map((device) => (
            <MenuItem value={device.deviceId} key={device.deviceId}>
              {device.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem value='default'>{localVideoTrack?.mediaStreamTrack.label || 'No Local Video'}</MenuItem>
        )}
      </Select>
    </>
  );
}
