import { ChevronLeft } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

import styles from './BackButton.module.scss';

type BackButtonProps = {
  text: string;
  onClick(): void;
};

export function BackButton({ text, onClick }: BackButtonProps) {
  return (
    <Stack className={styles.back} onClick={onClick}>
      <ChevronLeft className={styles.chevronIcon} />
      <Typography variant='body2' color='#626363'>
        {text}
      </Typography>
    </Stack>
  );
}
