import React, { useMemo } from 'react';
import classnames from 'classnames';
import { Box } from '@mui/material';
import { useParticipantsContext, useScreenSize, useVideo } from 'hooks';
import { Participant } from '../Participant';

import styles from './GridView.module.scss';

export function GridView(): JSX.Element {
  const { gridViewParticipants } = useParticipantsContext();
  const { room } = useVideo();
  const localParticipant = room?.localParticipant;
  const { isDesktop, isMobile } = useScreenSize();

  const participantsLength = useMemo(() => {
    return localParticipant ? [localParticipant, ...gridViewParticipants].length : gridViewParticipants.length;
  }, [localParticipant, gridViewParticipants]);

  return (
    <Box className={classnames(styles.container)}>
      <Box
        className={classnames(
          styles.participantContainer,
          {
            [styles.participantContainerDesktop]: isDesktop,
            [styles.participantContainerTablet]: !isDesktop && !isMobile,
            [styles.participantContainerMobile]: isMobile,
          },
          styles[`participantContainer-${participantsLength}`],
        )}
        overflow={isMobile ? 'scroll' : 'hidden'}
      >
        <Participant participant={localParticipant as any} isLocalParticipant={true} withBorder />
        {gridViewParticipants.map((participant) => (
          <Participant key={participant.sid} participant={participant} withBorder />
        ))}
      </Box>
    </Box>
  );
}
