import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { useLocalAudioToggle, useSignalrConnection, useVideo } from 'hooks';
import { MutedParticipantsContext } from './context';
import { ISignalrConnectionContext } from 'context/signalrConnection';
import { IVideoContext } from 'context';

type MutedParticipantsProviderProps = {
  children?: JSX.Element;
};

export function MutedParticipantsProvider({ children }: MutedParticipantsProviderProps) {
  const { events, mutePatientInCall }: ISignalrConnectionContext = useSignalrConnection();
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { room }: IVideoContext = useVideo();
  const { enqueueSnackbar } = useSnackbar();

  const [mutedParticipantsId, setMutedParticipantsId] = useState<string[]>([]);
  const [isCurrentParticipantMuted, setIsCurrentParticipantMuted] = useState<boolean>(false);

  useEffect(() => {
    if (!room) {
      setMutedParticipantsId([]);
      setIsCurrentParticipantMuted(false);
    }
  }, [room]);

  useEffect(() => {
    if (isAudioEnabled && isCurrentParticipantMuted) {
      toggleAudioEnabled();
    }
  }, [isAudioEnabled, isCurrentParticipantMuted]);

  const onMuted = (muted: boolean) => {
    setIsCurrentParticipantMuted(muted);
    enqueueSnackbar(`You have been ${muted ? 'muted' : 'unmuted'}`, { variant: 'info' });
  };

  useEffect(() => {
    events.on('muted', onMuted);
  }, [events]);

  const muteParticipant = (participantId: string, mute: boolean): void => {
    mutePatientInCall(participantId, mute);

    const newParticipants: string[] = [
      ...mutedParticipantsId.filter((mutedParticipantId: string) => mutedParticipantId !== participantId),
    ];
    if (mute) {
      newParticipants.push(participantId);
    }

    setMutedParticipantsId(newParticipants);
  };

  return (
    <MutedParticipantsContext.Provider value={{ mutedParticipantsId, isCurrentParticipantMuted, muteParticipant }}>
      {children}
    </MutedParticipantsContext.Provider>
  );
}
