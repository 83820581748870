import React, { useMemo } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import classnames from 'classnames';

import { WhiteboardIcon } from 'assets';
import { ControlTooltip, SimpleInfoDialog } from 'components';
import { IWhiteboardContext } from 'context';
import { useDialog, useVideo, useWhiteboard } from 'hooks';

import styles from './WhiteboardButton.module.scss';

type WhiteboardButtonProps = {
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
  iconColor?: string;
  withTooltip?: boolean;
  withLabel?: boolean;
};

export function WhiteboardButton({
  disabled,
  className,
  iconClassName,
  iconColor,
  withTooltip,
  withLabel,
}: WhiteboardButtonProps) {
  const { state, startWhiteboard }: IWhiteboardContext = useWhiteboard();
  const { isSharingScreen } = useVideo();
  const { showDialog } = useDialog();

  const isDisabled: boolean = useMemo(() => {
    return state !== 'off' || !!disabled;
  }, [state, disabled]);

  const handleClick = (): void => {
    if (isSharingScreen) {
      const modal = showDialog(SimpleInfoDialog, {
        text: 'In order to open whiteboard please exit share screen',
        onConfirm: () => {
          modal.destroy();
        },
      });

      return;
    }

    startWhiteboard();
  };

  return (
    <ControlTooltip title={withTooltip ? 'Whiteboard' : ''} isDisabled={isDisabled}>
      <Box component='span' display='block' className={classnames(styles.deviceButton, className)}>
        <IconButton
          disabled={isDisabled}
          className={classnames(
            { [styles.deviceButton]: !withLabel, [styles.deviceButtonWithLabel]: withLabel },
            className,
          )}
          onClick={handleClick}
        >
          <WhiteboardIcon
            className={classnames(styles.icon, iconClassName)}
            sx={{ color: isDisabled ? '#b7b7b7' : iconColor || '#fff' }}
          />
          {withLabel && (
            <Typography component='p' variant='body2' sx={{ color: '#FFF' }} ml='10px'>
              Whiteboard
            </Typography>
          )}
        </IconButton>
      </Box>
    </ControlTooltip>
  );
}
