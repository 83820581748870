import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function CheckCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M60 30C60 46.5686 46.5686 60 30 60C13.4314 60 0 46.5686 0 30C0 13.4314 13.4314 0 30 0C46.5686 0 60 13.4314 60 30ZM26.5299 45.8848L48.788 23.6267C49.5438 22.8709 49.5438 21.6454 48.788 20.8896L46.0508 18.1524C45.295 17.3965 44.0695 17.3965 43.3136 18.1524L25.1613 36.3046L16.6864 27.8297C15.9306 27.0739 14.7051 27.0739 13.9492 27.8297L11.212 30.5669C10.4562 31.3227 10.4562 32.5482 11.212 33.304L23.7927 45.8846C24.5486 46.6406 25.774 46.6406 26.5299 45.8848Z'
        fill='#81CFDD'
      />
    </SvgIcon>
  );
}
