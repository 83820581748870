import { Avatar, Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { BookInfoIcon, CrossIcon, CrownIcon, HelpIcon, HomeIcon, LogOut2Icon, PencilIcon, SettingsIcon } from 'assets';
import { IBurgerMenuContext, IUserContext } from 'context';
import { useAzureAsset, useBurgerMenu, useUser } from 'hooks';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { ApiUser } from 'types';
import { getFullName } from 'utils';

import styles from './BurgerMenu.module.scss';

export function BurgerMenu() {
  const navigate = useNavigate();

  const { isOpen, hideMenu }: IBurgerMenuContext = useBurgerMenu();
  const user: ApiUser | null = useAppSelector(selectUser);
  const { logout }: IUserContext = useUser();
  const avatarUrl = useAzureAsset(user?.linkToProfilePicture);

  const handleHelpClick = (): void => {
    window.location.replace('https://support.hipaalink.net/');
  };

  const handlePrivacyPolicyClick = (): void => {
    window.location.replace('https://www.hipaalink.net/privacy-policy/');
  };

  const handleHomeClick = (): void => {
    navigate('/');
    hideMenu();
  };

  const handleWaitingRoomClick = (): void => {
    navigate('/edit');
    hideMenu();
  };

  const handleSettingsClick = (): void => {
    navigate('/settings');
    hideMenu();
  };

  const handleLogoutClick = (): void => {
    navigate('/');
    hideMenu();
    logout();
  };

  return (
    <>
      {isOpen && (
        <Box className={styles.background}>
          <Box className={styles.burgerMenu}>
            <Stack className={styles.header}>
              <IconButton onClick={hideMenu}>
                <CrossIcon color='primary' />
              </IconButton>
            </Stack>

            <Stack className={styles.profile}>
              <Avatar className={styles.profilePicture} src={avatarUrl} />

              <Grid className={styles.texts}>
                <Typography component='h4' variant='h4' className={styles.name}>
                  {getFullName(user)}
                </Typography>

                <Typography component='p' variant='body2' color='#979797'>
                  {user?.clinicUser.organization}
                </Typography>

                <CrownIcon className={styles.crownIcon} />
              </Grid>
            </Stack>

            <Stack className={styles.items}>
              <div className={styles.item} onClick={handleHomeClick}>
                <HomeIcon />
                <Typography component='p' variant='body2'>
                  MY ROOM
                </Typography>
                <ChevronRight className={styles.right} />
              </div>

              <div className={styles.item} onClick={handleWaitingRoomClick}>
                <PencilIcon />
                <Typography component='p' variant='body2'>
                  EDIT WAITING ROOM
                </Typography>
                <ChevronRight className={styles.right} />
              </div>

              <div className={styles.item} onClick={handleSettingsClick}>
                <SettingsIcon />
                <Typography component='p' variant='body2'>
                  SETTINGS
                </Typography>
                <ChevronRight className={styles.right} />
              </div>

              <div className={styles.item} onClick={handleLogoutClick}>
                <LogOut2Icon />
                <Typography component='p' variant='body2'>
                  LOG OUT
                </Typography>
                <ChevronRight className={styles.right} />
              </div>
            </Stack>

            <Stack className={styles.footer}>
              <div className={styles.devider}></div>

              <div className={styles.item} onClick={handleHelpClick}>
                <HelpIcon />
                <Typography component='p' variant='body2'>
                  HELP
                </Typography>
              </div>

              <div className={styles.item} onClick={handlePrivacyPolicyClick}>
                <BookInfoIcon />
                <Typography component='p' variant='body2'>
                  PRIVACY POLICY
                </Typography>
              </div>
            </Stack>
          </Box>
        </Box>
      )}
    </>
  );
}
