import { ComponentType, MutableRefObject, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { Slider, Stack, SvgIconProps, Typography } from '@mui/material';

import {
  ArrowUpIcon,
  CircleIcon,
  CrossIcon,
  DialogIcon,
  DotMenuVerticalIcon,
  EraserIcon,
  FilledCircleIcon,
  FlowerIcon,
  HartIcon,
  HexagonIcon,
  MinusIcon,
  MoveIcon,
  PencilIcon,
  PentagonIcon,
  PlusIcon,
  RactangleIcon,
  RedoIcon,
  SaveIcon,
  TextIcon,
  ThreeLinesIcon,
  TrashIcon,
  TriangleIcon,
  UndoIcon,
  WhiteboardStarIcon,
  ZoomIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from 'assets';
import { IWhiteboardContext } from 'context';
import { useScreenSize, useWhiteboard } from 'hooks';
import { useDrawFigure, useMove, useText } from './hooks';
import { usePencil } from './hooks/usePencil';
import {
  ApiUser,
  WhiteboardFigureItemData,
  WhiteboardFigureTypeEnum,
  WhiteboardItem,
  WhiteboardItemTypeEnum,
  WhiteboardLineItemData,
  WhiteboardTextItemData,
} from 'types';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { TextInputWithOptions } from './components';

import styles from './WhiteboardContainer.module.scss';

const HIDE_ZOOM_INFO_TIMER_DURATION = 1000;
const COLORS = ['#000000', '#FF4F4F', '#FF9D43', '#FAFF00', '#00AA25', '#42E2FF', '#1357BE', '#FF93EE', '#B882FF'];
const FIGURES = [
  WhiteboardFigureTypeEnum.Rectangle,
  WhiteboardFigureTypeEnum.Circle,
  WhiteboardFigureTypeEnum.Triangle,
  WhiteboardFigureTypeEnum.Pentagon,
  WhiteboardFigureTypeEnum.Hexagon,
  WhiteboardFigureTypeEnum.Hart,
  WhiteboardFigureTypeEnum.Star,
  WhiteboardFigureTypeEnum.Flower,
  WhiteboardFigureTypeEnum.Message,
  WhiteboardFigureTypeEnum.Arrow,
];

type ItemType = 'move' | 'pencil' | 'eraser' | 'figure' | 'text';
type OpenedAdditionalSettingsType = 'lineSize' | 'color' | 'figure' | 'zoom' | 'additional' | null;
type CursorStylesDictionary = { [key in ItemType]: string };

export function WhiteboardContainer() {
  const user: ApiUser | null = useAppSelector(selectUser);

  const { height: screenHeight, width: screenWidth, isTouchDevice, isMobileVertical } = useScreenSize();
  const {
    items,
    canUndo,
    canRedo,
    isFullScreen,
    toggleFullScreen,
    undo,
    redo,
    clearCnavas,
    stopWhiteboard,
  }: IWhiteboardContext = useWhiteboard();

  const containerRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const canvasRef: RefObject<HTMLCanvasElement> = useRef<HTMLCanvasElement>(null);
  const ctx: MutableRefObject<CanvasRenderingContext2D | null> = useRef<CanvasRenderingContext2D | null>(null);

  const [selectedItemType, setSelectedItemType] = useState<ItemType>('pencil');
  const [openedAdditionalSettings, setOpenedAdditionalSettings] = useState<OpenedAdditionalSettingsType>(null);
  const [selectedFigure, setSelectedFigure] = useState<WhiteboardFigureTypeEnum>(WhiteboardFigureTypeEnum.Rectangle);
  const [selectedLineSize, setSelectedLineSize] = useState<number>(3);
  const [selectedColor, setSelectedColor] = useState<string>('#000000');
  const [showZoomInfo, setShowZoomInfo] = useState<boolean>(true);

  const { zoom, position, applyZoom, moveTo } = useMove({ canvasRef, isMoveSelected: selectedItemType === 'move' });
  const { drawingFigureData, drawFigure, drawTransformingRectangle } = useDrawFigure({
    ctx,
    canvasRef,
    zoom,
    canvasPosition: position,
    selectedFigure,
    selectedLineSize,
    selectedColor,
    isDrawFigureSelected: selectedItemType === 'figure',
  });
  const { drawingLineData, drawLine } = usePencil({
    ctx,
    canvasRef,
    zoom,
    canvasPosition: position,
    selectedLineSize,
    selectedColor,
    isEraserSelected: selectedItemType === 'eraser',
    isPencilSelected: selectedItemType === 'pencil',
  });
  const {
    properties: textProperties,
    drawingText,
    drawText,
    changeTextProperties,
  } = useText({
    ctx,
    canvasRef,
    zoom,
    canvasPosition: position,
    isTextSelected: selectedItemType === 'text',
    moveTo,
  });

  const zoomInPercents: string = useMemo(() => {
    return Math.round(zoom * 100) + '%';
  }, [zoom]);

  const FigureIcon: ComponentType<SvgIconProps> = useMemo(() => getFigureIcon(selectedFigure), [selectedFigure]);

  const cursorStylesDictionary: CursorStylesDictionary = useMemo(() => {
    return {
      move: 'grab',
      // eslint-disable-next-line quotes
      pencil: `url('data:image/svg+xml;utf8,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.3576 3.64293L16.3585 8.6438L5.49934 19.503L1.04068 19.9952C0.443791 20.0612 -0.0605143 19.5565 0.00589308 18.9596L0.501995 14.4978L11.3576 3.64293ZM19.4515 2.89839L17.1034 0.550302C16.371 -0.182132 15.1831 -0.182132 14.4507 0.550302L12.2416 2.75932L17.2425 7.76019L19.4515 5.55117C20.184 4.81834 20.184 3.63082 19.4515 2.89839Z" fill="black"/></svg>') 1 20, auto`,
      // eslint-disable-next-line quotes
      eraser: `url('data:image/svg+xml;utf8,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.7727 18.9127H10.4718L13.8374 15.5032L13.839 15.5018L13.8404 15.5001L19.3209 9.94828C19.7564 9.50605 20.001 8.9069 20.001 8.28224C20.001 7.65759 19.7564 7.05844 19.3209 6.61621L14.9354 2.17347C14.4992 1.73162 13.9076 1.4834 13.2908 1.4834C12.6739 1.4834 12.0823 1.73162 11.6462 2.17347L6.16511 7.72596L6.16412 7.72687L6.16322 7.72788L0.682175 13.2804C0.24601 13.7222 0.000976563 14.3215 0.000976562 14.9464C0.000976563 15.5713 0.24601 16.1706 0.682175 16.6124L4.2764 20.2534C4.42181 20.4007 4.61901 20.4834 4.82463 20.4834H18.7727C18.9783 20.4834 19.1755 20.4007 19.3209 20.2534C19.4663 20.1061 19.548 19.9063 19.548 19.698C19.548 19.4897 19.4663 19.29 19.3209 19.1427C19.1755 18.9954 18.9783 18.9127 18.7727 18.9127ZM12.7426 3.28423C12.8146 3.21128 12.9 3.15342 12.9941 3.11394C13.0881 3.07446 13.189 3.05414 13.2908 3.05414C13.3926 3.05414 13.4934 3.07446 13.5874 3.11394C13.6815 3.15342 13.7669 3.21128 13.8389 3.28423L18.2245 7.72696C18.3696 7.87436 18.4512 8.07408 18.4512 8.28229C18.4512 8.49051 18.3696 8.69023 18.2245 8.83762L13.2908 13.8356L7.80886 8.28229L12.7426 3.28423Z" fill="black"/></svg>') 1 20, auto`,
      figure: drawingFigureData ? 'pointer' : 'crosshair',
      // eslint-disable-next-line quotes
      text: `url('data:image/svg+xml;utf8,<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.574219 0V5H1.82422C1.82422 3.625 2.94922 2.5 4.32422 2.5H8.07422V16.25C8.07422 16.95 7.52422 17.5 6.82422 17.5H5.57422V20H15.5742V17.5H14.3242C13.6242 17.5 13.0742 16.95 13.0742 16.25V2.5H16.8242C18.1992 2.5 19.3242 3.625 19.3242 5H20.5742V0H0.574219Z" fill="black"/></svg>') 1 20, auto`,
    };
  }, [drawingFigureData]);

  function getFigureIcon(figure: WhiteboardFigureTypeEnum): (props: SvgIconProps) => JSX.Element {
    switch (figure) {
      case WhiteboardFigureTypeEnum.Rectangle:
      default:
        return RactangleIcon;
      case WhiteboardFigureTypeEnum.Circle:
        return CircleIcon;
      case WhiteboardFigureTypeEnum.Triangle:
        return TriangleIcon;
      case WhiteboardFigureTypeEnum.Pentagon:
        return PentagonIcon;
      case WhiteboardFigureTypeEnum.Hexagon:
        return HexagonIcon;
      case WhiteboardFigureTypeEnum.Hart:
        return HartIcon;
      case WhiteboardFigureTypeEnum.Star:
        return WhiteboardStarIcon;
      case WhiteboardFigureTypeEnum.Flower:
        return FlowerIcon;
      case WhiteboardFigureTypeEnum.Message:
        return DialogIcon;
      case WhiteboardFigureTypeEnum.Arrow:
        return ArrowUpIcon;
    }
  }

  const clearCnavasAndSetDefaultStyles = useCallback(() => {
    ctx.current!.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);
    ctx.current!.fillStyle = 'rgb(255, 255, 255)';
    ctx.current!.fillRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);
    ctx.current!.lineCap = 'round';
    ctx.current!.lineJoin = 'round';
  }, []);

  const drawAllItems = useCallback(() => {
    items.forEach((item: WhiteboardItem) => {
      if (item.type === WhiteboardItemTypeEnum.Line) {
        drawLine(item.data as WhiteboardLineItemData);
      } else if (item.type === WhiteboardItemTypeEnum.Figure) {
        drawFigure(item.data as WhiteboardFigureItemData);
      } else if (item.type === WhiteboardItemTypeEnum.Text) {
        drawText(item.data as WhiteboardTextItemData);
      }
    });

    if (drawingLineData) {
      drawLine({
        line: drawingLineData,
        color: selectedItemType === 'pencil' ? selectedColor : 'white',
        width: selectedItemType === 'pencil' ? selectedLineSize : 20,
      });
    }

    if (drawingFigureData) {
      drawFigure({
        boundaries: drawingFigureData,
        color: selectedColor,
        figureType: selectedFigure,
        lineWidth: selectedLineSize,
      });

      drawTransformingRectangle();
    }
  }, [
    items,
    drawingLineData,
    drawingFigureData,
    selectedColor,
    selectedLineSize,
    selectedFigure,
    drawLine,
    drawFigure,
    drawText,
    drawTransformingRectangle,
  ]);

  const redrawCanvas = useCallback(() => {
    window.requestAnimationFrame(() => {
      clearCnavasAndSetDefaultStyles();

      drawAllItems();
    });
  }, [drawAllItems]);

  useEffect(() => {
    ctx.current = canvasRef.current!.getContext('2d')!;
  }, []);

  useEffect(() => {
    canvasRef.current!.width = containerRef.current?.clientWidth ?? 0;
    canvasRef.current!.height = containerRef.current?.clientHeight ?? 0;

    redrawCanvas();
  }, [screenWidth, screenHeight]);

  useEffect(() => {
    redrawCanvas();
  }, [redrawCanvas]);

  useEffect(() => {
    setShowZoomInfo(true);

    const newTimerId: NodeJS.Timeout = setTimeout(() => {
      setShowZoomInfo(false);
    }, HIDE_ZOOM_INFO_TIMER_DURATION);

    return () => {
      clearInterval(newTimerId);
    };
  }, [zoom]);

  useEffect(() => {
    canvasRef.current!.style.cursor = cursorStylesDictionary[selectedItemType];
  }, [selectedItemType, cursorStylesDictionary]);

  const handleOpenLineSizeSettings = useCallback((): void => {
    setOpenedAdditionalSettings((prevOpenedAdditionalSettings: OpenedAdditionalSettingsType) =>
      prevOpenedAdditionalSettings === 'lineSize' ? null : 'lineSize',
    );
  }, []);

  const handleOpenColorSettings = useCallback((): void => {
    setOpenedAdditionalSettings((prevOpenedAdditionalSettings: OpenedAdditionalSettingsType) =>
      prevOpenedAdditionalSettings === 'color' ? null : 'color',
    );
  }, []);

  const handlePencilSelect = useCallback((): void => {
    setOpenedAdditionalSettings(null);
    setSelectedItemType('pencil');
  }, []);

  const handleEraserSelect = useCallback((): void => {
    setOpenedAdditionalSettings(null);
    setSelectedItemType('eraser');
  }, []);

  const handleOpenFigureSettings = useCallback((): void => {
    setSelectedItemType('figure');
    setOpenedAdditionalSettings((prevOpenedAdditionalSettings: OpenedAdditionalSettingsType) =>
      prevOpenedAdditionalSettings === 'figure' ? null : 'figure',
    );
  }, []);

  const handleTextSelect = useCallback((): void => {
    setOpenedAdditionalSettings(null);
    setSelectedItemType('text');
  }, []);

  const handleMoveSelect = useCallback((): void => {
    setSelectedItemType('move');
    setOpenedAdditionalSettings(null);
  }, []);

  const handleOpenZoomSettings = useCallback((): void => {
    setOpenedAdditionalSettings((prevOpenedAdditionalSettings: OpenedAdditionalSettingsType) =>
      prevOpenedAdditionalSettings === 'zoom' ? null : 'zoom',
    );
  }, []);

  const handleOpenAdditionalSettings = useCallback((): void => {
    setOpenedAdditionalSettings((prevOpenedAdditionalSettings: OpenedAdditionalSettingsType) =>
      prevOpenedAdditionalSettings === 'additional' ? null : 'additional',
    );
  }, []);

  const handlePlusZoom = useCallback(() => {
    applyZoom(zoom + 0.2);
  }, [zoom, applyZoom]);

  const handleMinusZoom = useCallback(() => {
    applyZoom(zoom - 0.2);
  }, [zoom, applyZoom]);

  const handleZoomByScroll = useCallback(
    (_: any, value: number | number[]) => {
      applyZoom(value as number);
    },
    [zoom, applyZoom],
  );

  const handleSaveImage = useCallback(() => {
    const img: string = canvasRef.current!.toDataURL('image/jpeg');
    const downloadLink: HTMLAnchorElement = document.createElement('a');
    downloadLink.href = img;
    downloadLink.download = 'whiteboard';
    downloadLink.click();
    downloadLink.remove();
  }, []);

  return (
    <>
      <div ref={containerRef} className={styles.container}>
        <canvas width={0} height={0} ref={canvasRef}></canvas>

        <div className={styles.settings}>
          <div className={styles.settingsItem}>
            <ThreeLinesIcon
              sx={{ color: openedAdditionalSettings === 'lineSize' ? '#42E2FF' : '#ffffff' }}
              onClick={handleOpenLineSizeSettings}
            />
          </div>

          <div className={styles.settingsItem}>
            <div
              className={classnames(styles.selectColorIcon, {
                [styles.active]: openedAdditionalSettings === 'color',
              })}
              style={{ background: selectedColor }}
              onClick={handleOpenColorSettings}
            ></div>
          </div>

          <div className={styles.devider}></div>

          <div className={styles.settingsItem}>
            <PencilIcon
              sx={{ color: selectedItemType === 'pencil' ? '#42E2FF' : '#ffffff' }}
              onClick={handlePencilSelect}
            />
          </div>

          <div className={styles.settingsItem}>
            <EraserIcon
              sx={{ color: selectedItemType === 'eraser' ? '#42E2FF' : '#ffffff' }}
              onClick={handleEraserSelect}
            />
          </div>

          <div className={styles.devider}></div>

          <div className={styles.settingsItem}>
            <FigureIcon
              sx={{
                stroke: selectedItemType === 'figure' ? '#42E2FF' : '#ffffff',
              }}
              onClick={handleOpenFigureSettings}
            />
          </div>

          <div className={styles.settingsItem}>
            <TextIcon sx={{ color: selectedItemType === 'text' ? '#42E2FF' : '#ffffff' }} onClick={handleTextSelect} />
          </div>

          <div className={styles.devider}></div>

          {!isTouchDevice && (
            <>
              <div className={styles.settingsItem}>
                <MoveIcon
                  sx={{
                    color: selectedItemType === 'move' ? '#42E2FF' : '#ffffff',
                    stroke: selectedItemType === 'move' ? '#42E2FF' : '#ffffff',
                  }}
                  onClick={handleMoveSelect}
                />
              </div>

              <div className={styles.settingsItem}>
                <ZoomIcon
                  sx={{ color: openedAdditionalSettings === 'zoom' ? '#42E2FF' : '#ffffff' }}
                  onClick={handleOpenZoomSettings}
                />
              </div>
            </>
          )}

          {(!isMobileVertical || (isMobileVertical && !user)) && (
            <>
              <div className={styles.settingsItem}>
                <UndoIcon sx={{ color: canUndo ? '#ffffff' : 'rgba(255, 255, 255, 0.5)' }} onClick={undo} />
              </div>

              <div className={styles.settingsItem}>
                <RedoIcon sx={{ color: canRedo ? '#ffffff' : 'rgba(255, 255, 255, 0.5)' }} onClick={redo} />
              </div>
            </>
          )}

          {user && (
            <>
              {isMobileVertical && (
                <div className={styles.settingsItem}>
                  <DotMenuVerticalIcon
                    sx={{
                      fill: openedAdditionalSettings === 'additional' ? '#42E2FF' : '#ffffff',
                    }}
                    onClick={handleOpenAdditionalSettings}
                  />
                </div>
              )}

              {!isMobileVertical && (
                <>
                  <div className={styles.settingsItem}>
                    <TrashIcon sx={{ color: '#ffffff' }} onClick={clearCnavas} />
                  </div>

                  <div className={styles.devider}></div>

                  <div className={styles.settingsItem}>
                    <SaveIcon sx={{ color: '#ffffff' }} onClick={handleSaveImage} />
                  </div>
                </>
              )}

              <div className={styles.settingsItem}>
                <CrossIcon sx={{ color: '#FF6F65' }} onClick={stopWhiteboard} />
              </div>
            </>
          )}
        </div>

        {openedAdditionalSettings === 'lineSize' && (
          <div
            className={classnames(styles.lineSizeSettings, {
              [styles.isPatient]: !user,
            })}
          >
            <div className={styles.arrow}></div>

            <div className={styles.lineSizeSettingsItem} onClick={() => setSelectedLineSize(3)}>
              <FilledCircleIcon
                sx={{ color: selectedLineSize === 3 ? '#ffffff' : 'rgb(188, 188, 188)', width: 9, height: 10 }}
              />
            </div>

            <div className={styles.lineSizeSettingsItem} onClick={() => setSelectedLineSize(6)}>
              <FilledCircleIcon
                sx={{ color: selectedLineSize === 6 ? '#ffffff' : 'rgb(188, 188, 188)', width: 15, height: 14 }}
              />
            </div>

            <div className={styles.lineSizeSettingsItem} onClick={() => setSelectedLineSize(12)}>
              <FilledCircleIcon
                sx={{ color: selectedLineSize === 12 ? '#ffffff' : 'rgb(188, 188, 188)', width: 20, height: 20 }}
              />
            </div>
          </div>
        )}

        {openedAdditionalSettings === 'color' && (
          <div
            className={classnames(styles.colorSettings, {
              [styles.isPatient]: !user,
            })}
          >
            <div className={styles.arrow}></div>

            {COLORS.map((color: string, index: number) => (
              <div
                key={index}
                className={classnames(styles.colorSettingsItem, {
                  [styles.active]: color === selectedColor,
                })}
                onClick={() => setSelectedColor(color)}
              >
                <div style={{ background: color }}></div>
              </div>
            ))}
          </div>
        )}

        {openedAdditionalSettings === 'figure' && (
          <div
            className={classnames(styles.figureSettings, {
              [styles.isPatient]: !user,
            })}
          >
            <div className={styles.arrow}></div>

            {FIGURES.map((figure: WhiteboardFigureTypeEnum, index: number) => {
              const FigureIcon: (props: SvgIconProps) => JSX.Element = getFigureIcon(figure);

              return (
                <div key={index} className={styles.figureSettingsItem} onClick={() => setSelectedFigure(figure)}>
                  <FigureIcon
                    sx={{
                      stroke: figure === selectedFigure ? '#42E2FF' : '#ffffff',
                    }}
                  />
                </div>
              );
            })}
          </div>
        )}

        {openedAdditionalSettings === 'additional' && isMobileVertical && user && (
          <div className={styles.additionalSettings}>
            <div className={styles.arrow}></div>

            <div className={styles.settingsItem}>
              <UndoIcon sx={{ color: canUndo ? '#ffffff' : 'rgba(255, 255, 255, 0.5)' }} onClick={undo} />
            </div>

            <div className={styles.settingsItem}>
              <RedoIcon sx={{ color: canRedo ? '#ffffff' : 'rgba(255, 255, 255, 0.5)' }} onClick={redo} />
            </div>

            <div className={styles.settingsItem}>
              <TrashIcon sx={{ color: '#ffffff' }} onClick={clearCnavas} />
            </div>

            <div className={styles.devider}></div>

            <div className={styles.settingsItem}>
              <SaveIcon sx={{ color: '#ffffff' }} onClick={handleSaveImage} />
            </div>
          </div>
        )}

        {openedAdditionalSettings === 'zoom' && !isTouchDevice && (
          <div
            className={classnames(styles.zoomSettings, {
              [styles.isPatient]: !user,
            })}
          >
            <div className={styles.arrow}></div>

            <div className={styles.zoomPercent}>
              <Typography variant='subtitle2' component='span' color='white'>
                {zoomInPercents}
              </Typography>
            </div>

            <div className={styles.button} onClick={handleMinusZoom}>
              <MinusIcon sx={{ stroke: '#ffffff' }} />
            </div>

            <Stack className={styles.slider}>
              <Slider
                orientation='horizontal'
                value={zoom}
                min={0.125}
                max={4}
                step={0.125}
                onChange={handleZoomByScroll}
              />
            </Stack>

            <div className={styles.button} onClick={handlePlusZoom}>
              <PlusIcon sx={{ stroke: '#ffffff' }} />
            </div>
          </div>
        )}

        {drawingText && (
          <TextInputWithOptions
            zoom={zoom}
            canvasPosition={position}
            properties={textProperties}
            drawingText={drawingText!}
            onChange={changeTextProperties}
          />
        )}

        {showZoomInfo && (
          <div className={styles.showZoomInfo}>
            <Typography variant='body' component='span' color='white'>
              {zoomInPercents}
            </Typography>
          </div>
        )}

        <div className={styles.toggleFullScreen} onClick={toggleFullScreen}>
          {isFullScreen ? <ZoomOutIcon /> : <ZoomInIcon />}
        </div>
      </div>
    </>
  );
}
