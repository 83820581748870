import { ReactNode } from 'react';
import { Box, Card, CardContent, CardHeader, Stack, Theme, useMediaQuery } from '@mui/material';
import classnames from 'classnames';
import { Chat, PatientList } from 'components';

import styles from './PatientQueue.module.scss';

type PatientQueueProps = {
  className?: string;
  additionalMenuSlot?: ReactNode;
};

export function PatientQueue({ className, additionalMenuSlot }: PatientQueueProps) {
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <Stack
      direction='column'
      alignItems='center'
      justifyContent='flex-start'
      sx={{
        gap: '24px',
        ...(additionalMenuSlot && { paddingBottom: '24px' }),
      }}
    >
      <Card
        className={classnames(styles.patientQueue, className)}
        sx={({ breakpoints }) => ({
          [breakpoints.down(900)]: {
            width: '100% !important',
          },
        })}
      >
        <CardHeader title={'Client Queue'} />
        <CardContent
          className={classnames(styles.cardContent, {
            [styles.tabletCardContent]: isTablet,
            [styles.mobileCardContent]: isMobile,
          })}
        >
          <PatientList />
        </CardContent>

        <Chat className={styles.chat} />
      </Card>
      {additionalMenuSlot && <Box sx={{ width: '100%' }}>{additionalMenuSlot}</Box>}
    </Stack>
  );
}
