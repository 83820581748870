import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function InviteIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='18' viewBox='0 0 16 18' xmlns='http://www.w3.org/2000/svg' fill='none' {...props}>
      <path d='M12 11.8139C11.4933 11.8139 11.04 12.0208 10.6933 12.3449L5.94 9.48283C5.97333 9.32421 6 9.16559 6 9.00007C6 8.83455 5.97333 8.67593 5.94 8.51731L10.64 5.68283C11 6.02765 11.4733 6.24145 12 6.24145C13.1067 6.24145 14 5.31731 14 4.17248C14 3.02765 13.1067 2.10352 12 2.10352C10.8933 2.10352 10 3.02765 10 4.17248C10 4.338 10.0267 4.49662 10.06 4.65524L5.36 7.48972C5 7.1449 4.52667 6.9311 4 6.9311C2.89333 6.9311 2 7.85524 2 9.00007C2 10.1449 2.89333 11.069 4 11.069C4.52667 11.069 5 10.8552 5.36 10.5104L10.1067 13.3794C10.0733 13.5242 10.0533 13.6759 10.0533 13.8277C10.0533 14.938 10.9267 15.8414 12 15.8414C13.0733 15.8414 13.9467 14.938 13.9467 13.8277C13.9467 12.7173 13.0733 11.8139 12 11.8139Z' />
    </SvgIcon>
  );
}
