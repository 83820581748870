import { Box } from '@mui/material';
import { LocalVideoTrack } from 'twilio-video';
import classnames from 'classnames';
import { useVideo } from 'hooks';
import { UserIcon } from 'assets';
import { VideoTrack } from '../VideoTrack';

import styles from './VideoPreview.module.scss';

type VideoPreviewProps = {
  className?: string;
};

export function VideoPreview({ className }: VideoPreviewProps): JSX.Element {
  const { localTracks } = useVideo();

  const localVideoTrack = localTracks.find((track) => track.kind === 'video') as LocalVideoTrack | undefined;

  return (
    <Box
      className={classnames(styles.preview, className)}
      sx={{
        background: '#F3F3F3',
      }}
    >
      {localVideoTrack ? (
        <VideoTrack isLocal track={localVideoTrack} />
      ) : (
        <UserIcon sx={{ color: '#979797', fontSize: '8.8rem' }} />
      )}
    </Box>
  );
}
