import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function SendIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M0.301792 17.2063L8.6289 0.307186C8.76324 0.0345627 9.09314 -0.0775398 9.36577 0.0567966C9.47454 0.110393 9.56256 0.198415 9.61616 0.307186L17.9431 17.206C18.0774 17.4786 17.9653 17.8085 17.6927 17.9428C17.5735 18.0016 17.4371 18.0152 17.3086 17.9812L10.986 16.3069C10.7769 16.2515 10.6196 16.0788 10.5841 15.8654L9.32133 8.29225C9.30587 8.19947 9.24462 8.12276 9.16136 8.08589L9.09516 8.06608C8.96668 8.04466 8.84417 8.116 8.79606 8.23121L8.77852 8.29225L7.5092 15.9082C7.47362 16.1216 7.31626 16.2944 7.10707 16.3497L0.936172 17.9816C0.642359 18.0593 0.341159 17.8841 0.263423 17.5903C0.229443 17.4619 0.243071 17.3255 0.301792 17.2063Z' />
    </SvgIcon>
  );
}
