import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function ChromeIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='101' height='100' viewBox='0 0 101 100' fill='none' {...props}>
      <path
        d='M50.058 27.3662H94.579C98.5397 35.1608 100.387 43.858 99.9359 52.5896C99.485 61.3212 96.7518 69.7818 92.0092 77.127C87.2665 84.4722 80.6803 90.445 72.9079 94.4492C65.1355 98.4535 56.4488 100.349 47.7148 99.947'
        fill='#FFCC44'
      />
      <path
        d='M69.7802 61.3427L47.715 99.947C38.949 99.5479 30.4425 96.8483 23.0505 92.1196C15.6586 87.3909 9.64162 80.7996 5.6045 73.0083C1.56737 65.2171 -0.347677 56.5004 0.0518464 47.7344C0.45137 38.9684 3.15139 30.462 7.8805 23.0703'
        fill='#0F9D58'
      />
      <path
        d='M50.0586 27.3663H94.5795C90.5913 19.5076 84.5965 12.843 77.2026 8.04784C69.8087 3.25267 61.2786 0.497391 52.4767 0.0612618C43.6748 -0.374868 34.9141 1.52365 27.0824 5.56441C19.2506 9.60516 12.6264 15.6445 7.88086 23.0704L30.3366 61.3428'
        fill='#DB4437'
      />
      <path
        d='M50.0577 70.5203C61.3813 70.5203 70.5608 61.3407 70.5608 50.0172C70.5608 38.6937 61.3813 29.5142 50.0577 29.5142C38.7342 29.5142 29.5547 38.6937 29.5547 50.0172C29.5547 61.3407 38.7342 70.5203 50.0577 70.5203Z'
        fill='#4285F4'
        stroke='#F1F1F1'
        strokeWidth='3.38771'
      />
    </SvgIcon>
  );
}
