/* eslint-disable prettier/prettier */

import { useContext } from 'react';
import {
  ICancellationConfirmationPopupContext,
  CancellationConfirmationPopupContext
} from './CancellationConfirmationPopupContext';

export function useCancellationConfirmationPopup(): ICancellationConfirmationPopupContext {
  return useContext(CancellationConfirmationPopupContext)!;
}
