import { NotesForm } from 'components';
import { MenuItem } from '../MenuItem';

type NotesMenuProps = {
  className?: string;
};

export function NotesMenu({ className }: NotesMenuProps): JSX.Element {
  return (
    <>
      <MenuItem className={className} title='Notes'>
        <NotesForm />
      </MenuItem>
    </>
  );
}
