import { useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, FormControlLabel, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSnackbar } from 'notistack';

import { DEFAULT_WELCOME_MESSAGE, DEFAULT_UNAVAILABLE_MESSAGE } from 'constants/data';
import { CheckboxCheckedIcon, CheckboxIcon } from 'assets';
import { FormInput, FormMediaUpload, FormSelector, Info } from 'components';
import { ApiUser, UpdateClinicUserRequest, UpdateUserRequest } from 'types';
import { useRegistration } from 'hooks';
import { IRegistrationContext } from 'context';
import { selectUser } from 'store/user';
import { useAppSelector } from 'store';
import { getSuffixesOptionsForUser, getTitlesOptionsForUser } from 'utils';

import styles from './CreateRoomForm.module.scss';

type CreateWaitingRoomFormType = {
  user: UpdateUserRequest;
  clinicUser: UpdateClinicUserRequest;
};

export function CreateRoomForm() {
  const { enqueueSnackbar } = useSnackbar();
  // const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const {
    onCreateRoomFormBack: onBack,
    onCreateRoomFormSkip: onSkip,
    onCreateRoomFormSubmit: onSubmit,
  }: IRegistrationContext = useRegistration();
  const user: ApiUser | null = useAppSelector(selectUser);

  const schema = Yup.object().shape({
    clinicUser: Yup.object().shape({
      roomName: Yup.string()
        .required('Please set unique URL for your waiting room')
        .matches(/^(([a-z]|\d|_)+([a-z]|\d|-|_)*)$/i, 'This URL contains characters not allowed. Please try again.')
        .max(128, 'Max length is 128 character'),
      welcomeMessage: Yup.string().max(500, 'Max length is 500 character').nullable(),
      unavailableMessage: Yup.string().max(100, 'Max length is 100 character').nullable(),
      preferedDisplayName: Yup.string()
        .max(50, 'Max length is 50 character')
        .matches(/^[a-zA-Z0-9'\-,.\s]{0,50}$/g, 'Preferred Display Name has invalid characters')
        .nullable()
        .label('Preferred Display Name'),
      biography: Yup.string().max(2000, 'Max length is 2000 character').nullable(),
      displayEmail: Yup.boolean(),
      suffix: Yup.string().nullable(),
    }),
    user: Yup.object().shape({
      title: Yup.string().nullable(),
      speciality: Yup.string().max(25, 'Max length is 25 character').nullable(),
    }),
  });

  const form = useForm<CreateWaitingRoomFormType>({
    resolver: yupResolver(schema),
    defaultValues: {},
    mode: 'all',
  });

  const [profilePicture, setProfilePicture] = useState<File | null>(null);

  const handleSubmit = async (): Promise<void> => {
    if (!form.formState.isValid) {
      return;
    }

    const formValues: CreateWaitingRoomFormType = form.getValues();

    await onSubmit(profilePicture, formValues.user, formValues.clinicUser).catch((error) => {
      enqueueSnackbar(error, { variant: 'error' });
    });
  };

  const handleSkip = (): void => {
    onSkip().catch((error) => {
      enqueueSnackbar(error, { variant: 'error' });
    });
  };

  // const handleBack = () => {
  //   onBack();
  // };

  return (
    <div className={styles.form}>
      <Stack className={styles.row}>
        <Typography component='p' variant='body4' color='#81CFDD' onClick={handleSkip} className={styles.skipButton}>
          Skip now, I will create my room later
        </Typography>
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography component='p' variant='body2'>
            Room Name URL <span className={styles.asterisk}>*</span>
          </Typography>
          <Info text='Create name for your personalized room link. The link must not contain .~!@#$%^&*()/' />
        </Stack>
        <Stack direction='row' alignItems='center' className={styles.roomNameContainer}>
          <div className={styles.roomNameLink}>
            <Typography variant='body1' color='#979797'>
              {window.location.origin}/
            </Typography>
          </div>
          <FormInput
            placeholder='AaronSmithTherapy'
            name='clinicUser.roomName'
            control={form.control}
            className={styles.roomName}
            errorHelperClassName={styles.roomNameError}
          />
        </Stack>
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' spacing={1} alignItems='flex-end'>
          <Typography component='p' variant='body2'>
            Welcome Message
          </Typography>
          <Info text='Customize a welcome message for your patient!' />
        </Stack>
        <FormInput
          name='clinicUser.welcomeMessage'
          control={form.control}
          multiline
          maxRows={4}
          placeholder={DEFAULT_WELCOME_MESSAGE}
        />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' spacing={1} alignItems='flex-end'>
          <Typography component='p' variant='body2'>
            Unavailable Message
          </Typography>
          <Info text='Customize your out of office message for your patient!' />
        </Stack>
        <FormInput
          name='clinicUser.unavailableMessage'
          control={form.control}
          multiline
          maxRows={4}
          placeholder={DEFAULT_UNAVAILABLE_MESSAGE}
        />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' alignItems='flex-end'>
          <Typography component='p' variant='body2'>
            Profile Picture
          </Typography>
        </Stack>
        <FormMediaUpload
          onInputChange={(file) => setProfilePicture(file)}
          onRemove={() => setProfilePicture(null)}
          title='Profile Picture'
          src={user?.linkToProfilePicture ?? ''}
        />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' alignItems='center'>
          <Typography component='p' variant='body2'>
            Title
          </Typography>
        </Stack>
        <FormSelector variant='outlined' name='user.title' control={form.control} options={getTitlesOptionsForUser()} />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' alignItems='center'>
          <Typography component='p' variant='body2'>
            Suffix
          </Typography>
        </Stack>
        <FormSelector
          variant='outlined'
          name='clinicUser.suffix'
          control={form.control}
          options={getSuffixesOptionsForUser()}
        />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' spacing={1} alignItems='flex-end'>
          <Typography component='p' variant='body2'>
            Preferred Display Name
          </Typography>
          <Info text='Enter how your name should appear at check-in and in your waiting room. If you leave this field empty, we will use your title, last name and suffix.' />
        </Stack>
        <FormInput
          name='clinicUser.preferedDisplayName'
          control={form.control}
          errorHelperClassName={styles.errorHelper}
        />
      </Stack>

      <Stack className={styles.row}>
        <FormControlLabel
          control={
            <Checkbox
              className={styles.checkbox}
              {...form.register('clinicUser.displayEmail')}
              icon={<CheckboxIcon className={styles.checkboxIcon} />}
              checkedIcon={<CheckboxCheckedIcon className={styles.checkboxIcon} />}
            />
          }
          label='Display email'
        />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography component='p' variant='body2'>
            Personal Biography
          </Typography>
        </Stack>
        <FormInput name='clinicUser.biography' className={styles.bio} control={form.control} multiline maxRows={20} />
      </Stack>

      <Stack className={styles.row} direction='row' justifyContent='flex-end'>
        {/* 
          hidden temporary
          <Stack direction='row' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleBack}>
            <ArrowBackIosIcon sx={{ width: '1rem' }} />
            <Typography variant='body2' color='#626363'>
              {isMobile ? 'Back' : 'Back to Personal Profile'}
            </Typography>
          </Stack> */}

        <Stack direction='row'>
          <Button variant='text' color='primary' onClick={handleSkip} className={styles.skipButtonBottom}>
            Skip
          </Button>

          <Button
            id='CreateRoomFormNext'
            variant='contained'
            onClick={handleSubmit}
            disabled={!form.formState.isValid}
            className={styles.formButton}
            type='submit'
          >
            Next
          </Button>
        </Stack>
      </Stack>
    </div>
  );
}
