import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApiCallHistory, IBasicState } from 'types';
import { IApiRequests, useApiRequests } from 'hooks';

import { AppState } from '../index';

const ApiRequests: IApiRequests = useApiRequests();

export interface ICallState extends IBasicState {
  data: ApiCallHistory | null;
  message: string | null;
  error: string | null;
}

const initialState: ICallState = {
  data: null,
  loading: false,
  message: null,
  error: null,
};

export const updateCallHistory = createAsyncThunk<
  Partial<ApiCallHistory>,
  Partial<ApiCallHistory>,
  { state: AppState }
>('call/update', async (data: Partial<ApiCallHistory>, { getState }): Promise<Partial<ApiCallHistory>> => {
  const state = getState();
  const room = state.call.data!.room;
  await ApiRequests.updateCallHistory({ room, note: data.meetingNotes! });
  return data;
});

export const createRoom = createAsyncThunk<Partial<ApiCallHistory>>(
  'call/create',
  async (): Promise<Partial<ApiCallHistory>> => {
    const room = await ApiRequests.createRoom();
    return { room };
  },
);

export const getRoomHistory = createAsyncThunk<ApiCallHistory | null, void, { state: AppState }>(
  'call/getRoomHistory',
  async (_, { getState }): Promise<ApiCallHistory> => {
    const state = getState();
    const room = state.call.data!.room;
    return await ApiRequests.getRoomHistory(room);
  },
);

export const callSlice = createSlice({
  name: 'call',
  initialState,
  reducers: {
    clearCallStore: (state) => {
      state.data = null;
    },
    clearCallMessages: (state) => {
      state.message = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateCallHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCallHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.data = { ...state.data, ...action.payload } as ApiCallHistory;
      })
      .addCase(updateCallHistory.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createRoom.pending, (state) => {
        state.loading = true;
      })
      .addCase(createRoom.fulfilled, (state, action) => {
        state.loading = false;
        state.data = { ...state.data, ...action.payload } as ApiCallHistory;
      })
      .addCase(createRoom.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getRoomHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRoomHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.data = { ...state.data, ...action.payload } as ApiCallHistory;
      })
      .addCase(getRoomHistory.rejected, (state) => {
        state.loading = false;
      });
  },
});

// Actions
export const { clearCallStore, clearCallMessages } = callSlice.actions;

// Selectors
export const selectCall = (state: AppState) => state.call.data;
export const selectCallLoading = (state: AppState) => state.call.loading;

export default callSlice.reducer;
