import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function ObjectShapeHartIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M10 17.5C9.86841 17.5007 9.73797 17.4755 9.61615 17.4258C9.49432 17.376 9.38352 17.3027 9.29009 17.21L1.52113 9.42977C0.546625 8.44492 0 7.11527 0 5.72967C0 4.34406 0.546625 3.01442 1.52113 2.02957C2.50326 1.05005 3.83366 0.5 5.22064 0.5C6.60762 0.5 7.93801 1.05005 8.92014 2.02957L10 3.1096L11.0799 2.02957C12.062 1.05005 13.3924 0.5 14.7794 0.5C16.1663 0.5 17.4967 1.05005 18.4789 2.02957C19.4534 3.01442 20 4.34406 20 5.72967C20 7.11527 19.4534 8.44492 18.4789 9.42977L10.7099 17.21C10.6165 17.3027 10.5057 17.376 10.3839 17.4258C10.262 17.4755 10.1316 17.5007 10 17.5Z' />
    </SvgIcon>
  );
}
