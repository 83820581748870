import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function ObjectShapeStarIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M16.0569 19.9948C15.8966 19.9955 15.7385 19.9558 15.5959 19.8791L10.485 17.0699L5.37405 19.8791C5.20808 19.9707 5.02097 20.0116 4.83397 19.9972C4.64697 19.9827 4.46759 19.9134 4.31621 19.7972C4.16484 19.6811 4.04755 19.5226 3.97768 19.3399C3.9078 19.1573 3.88814 18.9577 3.92094 18.7638L4.92308 12.8403L0.794259 8.63181C0.665441 8.49685 0.57406 8.32761 0.529921 8.14225C0.485783 7.95689 0.490555 7.7624 0.543723 7.57968C0.601807 7.39269 0.708652 7.22653 0.852133 7.10007C0.995613 6.97361 1.16998 6.8919 1.35546 6.86423L7.06767 5.99096L9.58304 0.593518C9.6651 0.415633 9.79323 0.265613 9.95274 0.160643C10.1123 0.0556738 10.2967 0 10.485 0C10.6732 0 10.8577 0.0556738 11.0172 0.160643C11.1767 0.265613 11.3048 0.415633 11.3869 0.593518L13.9323 5.98043L19.6445 6.85371C19.83 6.88138 20.0044 6.96309 20.1479 7.08955C20.2913 7.21601 20.3982 7.38216 20.4563 7.56916C20.5094 7.75188 20.5142 7.94636 20.4701 8.13172C20.4259 8.31708 20.3346 8.48633 20.2057 8.62129L16.0769 12.8298L17.0791 18.7533C17.1148 18.9506 17.0961 19.1545 17.0251 19.3409C16.954 19.5273 16.8337 19.6885 16.6782 19.8055C16.4967 19.939 16.2782 20.0056 16.0569 19.9948Z' />
    </SvgIcon>
  );
}
