import { useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Typography, Button } from '@mui/material';

import { IApiRequests, useApiRequests, useDialog } from 'hooks';
import { AcceptPaymentStatusEnum, ApiUser, SelectedAcceptPaymentEnum } from 'types';
import { Spinner, TwoOptionsDialog } from 'components';
import { getUser, selectUser } from 'store/user';
import { useAppDispatch, useAppSelector } from 'store';
import { getIsAdminByRoles } from 'utils';

import styles from './AcceptPaymentsPannel.module.scss';
import StripeLogo from 'assets/images/StripeLogo.png';

const stripeTextListItems = [
  'Accept all major credit and debit cards',
  'Receive funds in 2-3 business days',
  'Fee of 2.9% + 30 cents per online transaction',
];
const payPalTextListItems = [
  'Accept payments from millions of PayPal users Fast and secure checkout for customers Transaction fee of 2.9% + 30¢ per online transaction',
  'No monthly or setup fees',
];

export const AcceptPaymentsPanel = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { showDialog } = useDialog();
  const dispatch = useAppDispatch();

  const user: ApiUser | null = useAppSelector(selectUser);
  const { connectStripe, disconnectStripe }: IApiRequests = useApiRequests();

  const [loading, setLoading] = useState<boolean>(false);
  const [connectedPaymentMethod, setConnectedPaymentMethod] = useState<SelectedAcceptPaymentEnum | null>(null);

  const isAdmin = useMemo<boolean>(() => {
    return getIsAdminByRoles(user?.clinicUser.roles);
  }, [user]);

  const stripeButtonText: string = useMemo(() => {
    if (user?.selectedAcceptPayment !== SelectedAcceptPaymentEnum.Stripe) {
      return 'Connect';
    }

    if (
      user?.selectedAcceptPayment === SelectedAcceptPaymentEnum.Stripe &&
      user.acceptPaymentStatus === AcceptPaymentStatusEnum.Pending
    ) {
      return 'Reconnect';
    }

    return 'Disconnect';
  }, [user]);

  const stripeUnderButtonText: string = useMemo(() => {
    if (user?.selectedAcceptPayment !== SelectedAcceptPaymentEnum.Stripe) {
      return '';
    }

    if (
      user?.selectedAcceptPayment === SelectedAcceptPaymentEnum.Stripe &&
      user.acceptPaymentStatus === AcceptPaymentStatusEnum.Pending
    ) {
      return 'Pending';
    }

    return 'Connected';
  }, [user]);

  const stripeButtonColor: 'primary' | 'error' = useMemo(() => {
    if (user?.selectedAcceptPayment !== SelectedAcceptPaymentEnum.Stripe) {
      return 'primary';
    }

    if (
      user?.selectedAcceptPayment === SelectedAcceptPaymentEnum.Stripe &&
      user.acceptPaymentStatus === AcceptPaymentStatusEnum.Pending
    ) {
      return 'primary';
    }

    return 'error';
  }, [user]);

  const disconnectPayment = (): void => {
    setLoading(true);

    disconnectStripe()
      .then(() => dispatch(getUser()).unwrap())
      .catch((e) => enqueueSnackbar(`Disconnect payment method error: ${e}`, { variant: 'error' }))
      .finally(() => {
        setLoading(false);
      });
  };

  const showDialogToDisconnectPayment = (): void => {
    const modal = showDialog(TwoOptionsDialog, {
      title: 'Disconnect',
      text: 'Do you want to disconnect this payment method?',
      confirmText: 'Yes',
      cancelText: 'Cancel',
      onConfirm: () => {
        modal.destroy();
        disconnectPayment();
      },
      onClose: () => {
        modal.destroy();
      },
    });
  };

  useEffect(() => {
    setConnectedPaymentMethod(user?.selectedAcceptPayment ?? null);
  }, [user]);

  const handleStripeClick = (): void => {
    if (
      connectedPaymentMethod === SelectedAcceptPaymentEnum.Stripe &&
      user?.acceptPaymentStatus === AcceptPaymentStatusEnum.Connected
    ) {
      showDialogToDisconnectPayment();
    } else {
      connectStripe().then((url: string) => {
        window.open(url, '_self');
      });
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <Box className={styles.container}>
      <div className={styles.texts}>
        <Typography variant='body4'>Payment Methods</Typography>

        <Typography variant='body2'>
          Connect your existing payment process service to start accepting payments from your clients.
        </Typography>
      </div>

      <div className={styles.paymentMethod}>
        <div>
          <div className={styles.image}>
            <img src={StripeLogo} />
          </div>

          <div className={styles.paymentMethodInfo}>
            <Typography variant='body7'>Stripe</Typography>

            <ul>
              {stripeTextListItems.map((item: string, index: number) => (
                <li key={index}>
                  <Typography variant='body1'>{item}</Typography>
                </li>
              ))}
            </ul>
          </div>

          <div className={styles.buttons}>
            {stripeUnderButtonText && (
              <Typography variant='body1'>
                <b>{stripeUnderButtonText}</b>
              </Typography>
            )}

            {isAdmin && (
              <Button variant='contained' color={stripeButtonColor} onClick={handleStripeClick}>
                {stripeButtonText}
              </Button>
            )}
          </div>
        </div>
      </div>

      {/* hidden temporary */}
      {/* <div className={styles.paymentMethod}>
        <div>
          <div className={styles.image}>
            <img src={NMILogo} />
          </div>

          <div className={styles.paymentMethodInfo}>
            <Typography variant='body7'>PayPal</Typography>

            <ul>
              {payPalTextListItems.map((item: string, index: number) => (
                <li key={index}>
                  <Typography variant='body1'>{item}</Typography>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <Button variant='contained'>Connect</Button>
      </div> */}
    </Box>
  );
};
