import { useEffect, useState } from 'react';
import { MenuItem, Select, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { CheckIcon, ChevronRightIcon, GridIcon, SpeakerViewIcon } from 'assets';
import { LayoutType } from 'types';
import { useAppSettings, useParticipants, useWhiteboard } from 'hooks';
import { GRID_VIEW_THRESHOLD } from '../../../constants';
import { IWhiteboardContext } from 'context';

import styles from './LayoutList.module.scss';

export function LayoutList() {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { layoutType, setLayoutType } = useAppSettings();
  const participants = useParticipants();
  const { state }: IWhiteboardContext = useWhiteboard();

  const [isMultipleParticipantsViewAllowed, setIsMultipleParticipantsViewAllowed] = useState(
    participants.length >= GRID_VIEW_THRESHOLD,
  );

  useEffect(() => {
    if (participants.length >= GRID_VIEW_THRESHOLD && state === 'off') {
      setIsMultipleParticipantsViewAllowed(true);
    } else {
      setIsMultipleParticipantsViewAllowed(false);
    }
  }, [participants, state]);

  return (
    <>
      <Select
        variant='outlined'
        IconComponent={ChevronRightIcon}
        value={layoutType}
        onChange={(e) => setLayoutType(e.target.value as LayoutType)}
        className={styles.layoutSelect}
        classes={{
          icon: styles.chevron,
        }}
        disabled={!isMultipleParticipantsViewAllowed}
      >
        <MenuItem value={LayoutType.GRID}>
          <Stack
            direction='row'
            spacing={'15px'}
            justifyContent='space-between'
            alignItems='center'
            sx={{
              width: '100%',
            }}
          >
            <GridIcon
              color='primary'
              sx={{
                fontSize: '1.5rem',
              }}
            />
            <Typography
              component='p'
              variant={isDesktop ? 'body5' : 'body2'}
              textTransform='capitalize'
              sx={{
                width: '100%',
              }}
            >
              {LayoutType.GRID}
            </Typography>
            {layoutType === LayoutType.GRID && (
              <CheckIcon
                className='check-icon'
                sx={{
                  fontSize: '0.9rem',
                }}
              />
            )}
          </Stack>
        </MenuItem>
        <MenuItem value={LayoutType.SPEAKER}>
          <Stack
            direction='row'
            spacing={'15px'}
            justifyContent='space-between'
            alignItems='center'
            sx={{
              width: '100%',
            }}
          >
            <SpeakerViewIcon
              color='primary'
              sx={{
                fontSize: '1.5rem',
              }}
            />
            <Typography
              component='p'
              variant={isDesktop ? 'body5' : 'body2'}
              textTransform='capitalize'
              sx={{
                width: '100%',
              }}
            >
              {LayoutType.SPEAKER}
            </Typography>
            {layoutType === LayoutType.SPEAKER && (
              <CheckIcon
                className='check-icon'
                sx={{
                  fontSize: '0.9rem',
                }}
              />
            )}
          </Stack>
        </MenuItem>
      </Select>
    </>
  );
}
