import { RefObject, useState } from 'react';
import { Box, Button, IconButton, Popover, Typography } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

import { CrownIcon, PaymentIcon } from 'assets';
import { InvitationStatus, StaffItem } from 'types';

import styles from './MoreOptions.module.scss';

type MoreOptionsProps = {
  className?: string;
  item: StaffItem;
  ref?: RefObject<HTMLButtonElement>;
  onResend(invitationId: string): void;
  onActivate(item: StaffItem): void;
  onDeactivate(item: StaffItem): void;
  onAllowPayments(item: StaffItem): void;
  onDisablePayments(item: StaffItem): void;
  onRemoveStaff(item: StaffItem): void;
};

export function MoreOptions({
  className,
  item,
  ref,
  onResend,
  onActivate,
  onDeactivate,
  onAllowPayments,
  onDisablePayments,
  onRemoveStaff,
}: MoreOptionsProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInvitationResend = (invitationId: string): void => {
    onResend(invitationId);
  };

  const handleDeactivate = (item: StaffItem): void => {
    onDeactivate(item);
  };

  const handleActivate = (item: StaffItem): void => {
    onActivate(item);
  };

  const handleAllowPayments = (item: StaffItem): void => {
    onAllowPayments(item);
  };

  const handleDisablePayments = (item: StaffItem): void => {
    onDisablePayments(item);
  };

  const handleRemoveStaff = (item: StaffItem): void => {
    onRemoveStaff(item);
  };

  return (
    <>
      <IconButton ref={ref} className={className} onClick={handleOpen}>
        <MoreVert color='secondary' />
      </IconButton>
      <Popover
        sx={{
          '.MuiBackdrop-root': {
            opacity: '0 !important',
          },
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Box className={styles.popoverBox} onClick={handleClose}>
          {item.status !== InvitationStatus.Active && (
            <Button onClick={() => handleInvitationResend(item?.invitationId)}>
              <SendIcon /> <Typography variant='body5'>Resend Invitation</Typography>
            </Button>
          )}

          {item.hasLicense && (
            <Button onClick={() => handleDeactivate(item)}>
              <CancelIcon /> <Typography variant='body5'>Deactivate</Typography>
            </Button>
          )}

          {!item.hasLicense && (
            <Button onClick={() => handleActivate(item)}>
              <CrownIcon /> <Typography variant='body5'>Activate</Typography>
            </Button>
          )}

          {item.status === InvitationStatus.Active && item.hasLicense && !item.paymentsAllowed && (
            <Button onClick={() => handleAllowPayments(item)}>
              <PaymentIcon /> <Typography variant='body5'>Allow Payments</Typography>
            </Button>
          )}

          {item.status === InvitationStatus.Active && item.hasLicense && item.paymentsAllowed && (
            <Button onClick={() => handleDisablePayments(item)}>
              <PaymentIcon color='error' /> <Typography variant='body5'>Disable Payments</Typography>
            </Button>
          )}

          <Button onClick={() => handleRemoveStaff(item)}>
            <PersonRemoveIcon /> <Typography variant='body5'>Remove Staff</Typography>
          </Button>
        </Box>
      </Popover>
    </>
  );
}
