import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function CrossIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='13' height='13' viewBox='0 0 13 13' fill='none' {...props}>
      <path d='M13 1.85705L11.143 0L6.5 4.64295L1.85705 0L0 1.85705L4.64295 6.5L0 11.143L1.85705 13L6.5 8.35705L11.143 13L13 11.143L8.35705 6.5L13 1.85705Z' />
    </SvgIcon>
  );
}
