import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function BackgroundEffectsIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M4.20705 2L0 6.20705V4.79286L2.79286 2H4.20705Z' fill='#81CFDD' />
      <path
        d='M11.2073 2L9.74322 3.46415C9.42923 3.28354 9.08473 3.15021 8.71924 3.0739L9.79312 2H11.2073Z'
        fill='#81CFDD'
      />
      <path
        d='M10.1188 5.17348C9.85264 4.74895 9.46235 4.41011 8.99885 4.20775C8.69276 4.07412 8.35476 4 7.99947 4C7.92659 4 7.85444 4.00312 7.78315 4.00923C6.57503 4.11278 5.61229 5.07552 5.50874 6.28364C5.50263 6.35493 5.49951 6.42708 5.49951 6.49996C5.49951 6.85526 5.57363 7.19326 5.70726 7.49931C5.90962 7.96282 6.24846 8.35313 6.67298 8.61932C6.98341 8.81402 7.33965 8.94242 7.72187 8.98472C7.81301 8.99472 7.90564 8.99992 7.99947 8.99992C9.38015 8.99992 10.4994 7.88065 10.4994 6.49996C10.4994 6.40613 10.4942 6.3135 10.4842 6.22235C10.4419 5.84015 10.3135 5.48391 10.1188 5.17348Z'
        fill='#81CFDD'
      />
      <path
        d='M10.8389 4.45334C11.043 4.736 11.2059 5.05033 11.3188 5.38757L14.6939 2.01251C14.6301 2.00426 14.5652 2 14.4992 2H13.2922L10.8389 4.45334Z'
        fill='#81CFDD'
      />
      <path
        d='M5.49949 10C5.4235 10 5.34882 10.0057 5.27586 10.0166C4.62647 10.1136 4.11314 10.627 4.01607 11.2764C4.00516 11.3493 3.99951 11.424 3.99951 11.5V13.9999H11.9994V11.5C11.9994 11.1605 11.8866 10.8473 11.6964 10.5959C11.4226 10.2339 10.9883 10 10.4994 10H5.49949Z'
        fill='#81CFDD'
      />
      <path
        d='M10.5002 8.99966C10.717 8.99966 10.9273 9.02726 11.1279 9.07916L16.0002 4.20683V3.49974C16.0002 3.29118 15.9576 3.09257 15.8807 2.91211L11.3798 7.41299C11.215 8.02427 10.8888 8.56937 10.4497 8.99966H10.5002Z'
        fill='#81CFDD'
      />
      <path
        d='M12.4082 9.8848C12.6328 10.1498 12.8032 10.4623 12.9021 10.8051L16 7.70716V6.29297L12.4082 9.8848Z'
        fill='#81CFDD'
      />
      <path d='M13 14V12.793L16 9.79297V11.2071L13.2072 14H13Z' fill='#81CFDD' />
      <path
        d='M2.99973 13.7091V12.2949L1.30518 13.9895C1.36886 13.9977 1.43381 14.002 1.49975 14.002H2.70686L2.99973 13.7091Z'
        fill='#81CFDD'
      />
      <path
        d='M4.57384 7.2168C4.65016 7.58228 4.78348 7.92683 4.96409 8.24079L0.119478 13.0853C0.0425592 12.9049 0 12.7063 0 12.4977V11.7906L4.57384 7.2168Z'
        fill='#81CFDD'
      />
      <path d='M7.70699 2L0 9.70697V8.2928L6.29281 2H7.70699Z' fill='#81CFDD' />
    </SvgIcon>
  );
}
