import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function PauseIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M13.875 1.75V14.25C13.8746 14.5814 13.7428 14.8991 13.5085 15.1335C13.2741 15.3678 12.9564 15.4996 12.625 15.5H9.8125C9.48109 15.4996 9.16337 15.3678 8.92903 15.1335C8.6947 14.8991 8.56288 14.5814 8.5625 14.25V1.75C8.56288 1.4186 8.6947 1.10087 8.92903 0.866535C9.16337 0.632197 9.48109 0.500379 9.8125 0.5H12.625C12.9564 0.500379 13.2741 0.632197 13.5085 0.866535C13.7428 1.10087 13.8746 1.4186 13.875 1.75ZM4.1875 0.5H1.375C1.0436 0.500379 0.725873 0.632197 0.491535 0.866535C0.257197 1.10087 0.125379 1.4186 0.125 1.75V14.25C0.125379 14.5814 0.257197 14.8991 0.491535 15.1335C0.725873 15.3678 1.0436 15.4996 1.375 15.5H4.1875C4.5189 15.4996 4.83663 15.3678 5.07096 15.1335C5.3053 14.8991 5.43712 14.5814 5.4375 14.25V1.75C5.43712 1.4186 5.3053 1.10087 5.07096 0.866535C4.83663 0.632197 4.5189 0.500379 4.1875 0.5Z'
        fill='white'
      />
    </SvgIcon>
  );
}
