import { useMemo, useState } from 'react';
import { Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

import { MyProfileForm } from '../MyProfileForm';
import { RoomSettingsForm } from '../RoomSettingsForm';
import { FormHeader, HeaderItem } from '../FormHeader';
import { BackButton } from 'components';

import styles from './ProfileSettings.module.scss';

type Tab = 'myProfile' | 'roomSettings';

type ProfileSettingsProps = {
  onBackToSettingsClick(): void;
};

export function ProfileSettings({ onBackToSettingsClick }: ProfileSettingsProps) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [tab, setTab] = useState<Tab | null>(isMobile ? null : 'myProfile');

  const headerItems: HeaderItem[] = useMemo(
    () => [
      {
        text: 'MY PROFILE',
        isSelected: tab === 'myProfile',
        onClick: () => setTab('myProfile'),
      },
      {
        text: 'ROOM SETTINGS',
        isSelected: tab === 'roomSettings',
        onClick: () => setTab('roomSettings'),
      },
    ],
    [tab],
  );

  const getMobileHeaderName = (): string => {
    switch (tab) {
      case 'myProfile':
        return 'MY PROFILE';
      case 'roomSettings':
        return 'ROOM SETTINGS';
      default:
        return 'PROFILE';
    }
  };

  const handleBackToProfile = (): void => {
    setTab(null);
  };

  const handleMyProfileClick = (): void => {
    setTab('myProfile');
  };

  const handleRoomSettingsClick = (): void => {
    setTab('roomSettings');
  };

  return (
    <>
      {!tab && isMobile && <BackButton text='Back to Settings' onClick={onBackToSettingsClick} />}
      {!!tab && isMobile && <BackButton text='Back to Profile' onClick={handleBackToProfile} />}

      <div className={styles.container}>
        {isMobile && (
          <>
            <FormHeader items={[{ text: getMobileHeaderName() }]} />

            {!tab && (
              <>
                <Stack className={styles.navigationItem} onClick={handleMyProfileClick}>
                  <Typography variant='body2'>My Profile</Typography>
                  <ChevronRight />
                </Stack>
                <Stack className={styles.navigationItem} onClick={handleRoomSettingsClick}>
                  <Typography variant='body2'>Room Settings</Typography>
                  <ChevronRight />
                </Stack>
              </>
            )}

            {tab && (
              <div className={styles.main}>
                {tab === 'myProfile' && <MyProfileForm />}
                {tab === 'roomSettings' && <RoomSettingsForm />}
              </div>
            )}
          </>
        )}

        {!isMobile && (
          <>
            <FormHeader items={headerItems} />
            <div className={styles.main}>
              {tab === 'myProfile' && <MyProfileForm />}
              {tab === 'roomSettings' && <RoomSettingsForm />}
            </div>
          </>
        )}
      </div>
    </>
  );
}
