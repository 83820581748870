import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { FormInput, FormMediaUpload, FormSelector, Info, Spinner } from 'components';
import { ApiUser, UpdateClinicUserRequest, UpdateUserRequest } from 'types';
import { IApiRequests, useApiRequests } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store';
import { getUser, selectUser } from 'store/user';
import { getSuffixesOptionsForUser, getTitlesOptionsForUser } from 'utils';

import styles from './MyProfileForm.module.scss';

type MyProfileFormType = {
  clinicUser: UpdateClinicUserRequest;
  user: UpdateUserRequest;
};

export function MyProfileForm() {
  const { enqueueSnackbar } = useSnackbar();
  const { updateProfilePicture, updateUser, updateClinicUser }: IApiRequests = useApiRequests();
  const user: ApiUser = useAppSelector(selectUser)!;
  const dispatch = useAppDispatch();

  const schema = Yup.object().shape({
    clinicUser: Yup.object().shape({
      suffix: Yup.string().nullable(),
    }),
    user: Yup.object().shape({
      firstName: Yup.string().max(50, 'Max length is 50 character').required('First Name is required'),
      lastName: Yup.string().max(50, 'Max length is 50 character').required('Last Name is required'),
      title: Yup.string().nullable(),
      speciality: Yup.string().max(25, 'Max length is 25 character').nullable(),
    }),
  });

  const formDefaultValues: MyProfileFormType = useMemo(
    () => ({
      user: {
        firstName: user.firstName,
        lastName: user.lastName,
        speciality: user.speciality ?? '',
        title: user.title,
      },
      clinicUser: {
        suffix: user.clinicUser.suffix,
      },
    }),
    [user],
  );

  const form = useForm<MyProfileFormType>({
    resolver: yupResolver(schema),
    defaultValues: formDefaultValues,
    mode: 'all',
  });

  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [profilePictureChanged, setProfilePictureChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    form.reset(formDefaultValues);
  }, [formDefaultValues]);

  const onSubmit = (): Promise<void> => {
    setLoading(true);

    const formValues: MyProfileFormType = form.getValues();

    const updateImagePromise = (): Promise<void> => {
      if (!profilePictureChanged) {
        return Promise.resolve();
      }

      return updateProfilePicture(profilePicture).catch((error) =>
        Promise.reject(`Unable to update profile picture: ${error}`),
      );
    };

    const updateClinicUserPromise = (): Promise<any> => {
      return updateClinicUser(formValues.clinicUser).catch((error: any) => {
        return Promise.reject(`Update clinic user failed: ${error.message}`);
      });
    };

    const updateUserPromise = (): Promise<any> => {
      return updateUser(formValues.user).catch((error: any) => {
        return Promise.reject(`Update user failed: ${error.message}`);
      });
    };

    return updateImagePromise()
      .then(() => updateClinicUserPromise())
      .then(() => updateUserPromise())
      .then(() => dispatch(getUser()).unwrap())
      .then(() => Promise.resolve())
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = async (): Promise<void> => {
    if (!form.formState.isValid) {
      return;
    }

    await onSubmit()
      .then(() => {
        form.reset();
        setProfilePictureChanged(false);
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: 'error' });
      });
  };

  const handleProfilePictureChange = (picture: File | null) => {
    setProfilePicture(picture);
    setProfilePictureChanged(true);
  };

  return loading ? (
    <Spinner small />
  ) : (
    <div className={styles.form}>
      <Stack className={styles.row}>
        <Stack direction='row' alignItems='flex-end'>
          <Typography component='p' variant='body2'>
            Profile Picture
          </Typography>
        </Stack>
        <FormMediaUpload
          onInputChange={handleProfilePictureChange}
          onRemove={() => handleProfilePictureChange(null)}
          title='Profile Picture'
          src={user?.linkToProfilePicture ?? ''}
        />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' alignItems='center'>
          <Typography component='p' variant='body2'>
            Title
          </Typography>
        </Stack>
        <FormSelector variant='outlined' name='user.title' control={form.control} options={getTitlesOptionsForUser()} />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' alignItems='flex-end'>
          <Typography component='p' variant='body2'>
            First Name <span className={styles.asterisk}>*</span>
          </Typography>
        </Stack>
        <FormInput name='user.firstName' control={form.control} />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' alignItems='flex-end'>
          <Typography component='p' variant='body2'>
            Last Name <span className={styles.asterisk}>*</span>
          </Typography>
        </Stack>
        <FormInput name='user.lastName' control={form.control} />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' alignItems='center'>
          <Typography component='p' variant='body2'>
            Suffix
          </Typography>
        </Stack>
        <FormSelector
          variant='outlined'
          name='clinicUser.suffix'
          control={form.control}
          options={getSuffixesOptionsForUser()}
        />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' alignItems='center' className={styles.row}>
          <Typography component='p' variant='body2'>
            Email
          </Typography>
          <Info
            children={
              <div>
                Contact us&nbsp;
                <a
                  className={styles.emailTooltipHref}
                  href='mailto:support@hipaalink.net?subject=Change%20email%20address%20request'
                >
                  here
                </a>
                &nbsp;to change your email
              </div>
            }
          />
        </Stack>
        <FormInput name='email' control={form.control} value={user.email} disabled className={styles.email} />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' alignItems='flex-end'>
          <Typography component='p' variant='body2'>
            Specialty/Specialties
          </Typography>
        </Stack>
        <FormInput name='user.speciality' control={form.control} />
      </Stack>

      <Stack className={styles.row} alignItems='flex-end'>
        <Button
          variant='contained'
          onClick={handleSubmit}
          disabled={(!form.formState.isValid || !form.formState.isDirty) && !profilePictureChanged}
          className={styles.nextButton}
          type='submit'
        >
          Save
        </Button>
      </Stack>
    </div>
  );
}
