import { WhiteboardItem } from 'types';

export const deserializeItem = (item: string): WhiteboardItem | null => {
  try {
    return JSON.parse(item) as WhiteboardItem;
  } catch {
    return null;
  }
};

export const serializeItem = (item: WhiteboardItem): string => {
  return JSON.stringify(item);
};
