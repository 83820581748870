import { Box, Container, IconButton, Theme, useMediaQuery } from '@mui/material';
import classnames from 'classnames';

import { Logo, MenuIcon } from 'assets';
import { IBurgerMenuContext, IFirstLoginTooltipsContext } from 'context';
import { useBurgerMenu, useFirstLoginTooltips } from 'hooks';
import { SubscriptionStatusBanner } from 'components/SubscriptionStatusBanner';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { ProfileSection } from './components/ProfileSection';
import { FirstLoginTooltip } from 'components';
import { ApiUserTooltipStagePassedEnum } from 'types';

import styles from './Header.module.scss';

export function Header() {
  const user = useAppSelector(selectUser);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { showMenu }: IBurgerMenuContext = useBurgerMenu();

  const { tooltipsState, tooltipStagePassed, accept }: IFirstLoginTooltipsContext = useFirstLoginTooltips();

  return (
    <Box component='div' className={styles.outerWrapper}>
      <SubscriptionStatusBanner />
      <Box component='header' className={styles.headerWrapper}>
        <Container>
          <FirstLoginTooltip
            text={
              tooltipsState === 2
                ? `For greater security, 
            HIPAA LINK encourages you to set up Two-Factor Authentication. Click here to open settings and secure your account with Two-Factor Authentication!`
                : `Don't forget to download your Business Associate Agreement (BAA)!`
            }
            numberText={tooltipsState === 2 ? '2 of 3' : '3 of 3'}
            open={
              (tooltipsState === 2 || tooltipsState === 3) &&
              tooltipStagePassed === ApiUserTooltipStagePassedEnum.None &&
              isDesktop
            }
            onClick={accept}
            actionText={tooltipsState === 2 ? 'Next' : 'Done'}
            arrowClassName={styles.tooltipArrow}
          >
            <Box
              className={classnames(styles.headerPrimary, {
                [styles.headerPrimaryTablet]: isTablet,
                [styles.headerPrimaryMobile]: isMobile,
              })}
            >
              {user && !isDesktop && (
                <FirstLoginTooltip
                  text={
                    tooltipsState === 2
                      ? `For greater security, 
                          HIPAA LINK encourages you to set up Two-Factor Authentication. Click here to open settings and secure your account with Two-Factor Authentication!`
                      : `Don't forget to download your Business Associate Agreement (BAA)!`
                  }
                  numberText={tooltipsState === 2 ? '2 of 3' : '3 of 3'}
                  open={
                    (tooltipsState === 2 || tooltipsState === 3) &&
                    tooltipStagePassed === ApiUserTooltipStagePassedEnum.None
                  }
                  onClick={accept}
                  actionText={tooltipsState === 2 ? 'Next' : 'Done'}
                >
                  <IconButton onClick={showMenu}>
                    <MenuIcon color='primary' className={styles.icon} />
                  </IconButton>
                </FirstLoginTooltip>
              )}

              <a href='/'>
                <Logo className={styles.logo} />
              </a>

              {/* {user && !isDesktop && (
              <IconButton>
                <BellIcon color='primary' className={styles.icon} />
              </IconButton>
            )} */}

              {user && isDesktop && (
                <Box className={styles.profile}>
                  <ProfileSection />
                </Box>
              )}
            </Box>
          </FirstLoginTooltip>
        </Container>
      </Box>
    </Box>
  );
}
