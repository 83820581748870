import { forwardRef, ReactElement, Ref, useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { RemoteParticipant } from 'twilio-video';
import { useSnackbar } from 'notistack';

import { Transitions } from '../Transitions';
import { useSignalrConnection, useVideo } from 'hooks';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { parseParticipantIdentity, withOutsideClickPreventer } from 'utils';
import { Room } from './components';
import { ISignalrConnectionContext, RoomRightSidebarProvider } from 'context';

import styles from './VideoRoom.module.scss';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>,
) {
  return <Transitions className={styles.transition} direction='up' type='slide' ref={ref} {...props} />;
});

export function VideoRoom(): JSX.Element {
  const user = useAppSelector(selectUser);
  const { enqueueSnackbar } = useSnackbar();
  const { callEnded }: ISignalrConnectionContext = useSignalrConnection();
  const [open, setOpen] = useState(false);
  const { room } = useVideo();

  useEffect(() => {
    if (room) {
      setOpen(true);
      const onDisconnected = () => {
        setOpen(false);
        callEnded();
      };

      const participantDisconnected = (participant: RemoteParticipant) => {
        const [id, identity] = parseParticipantIdentity(participant);
        if (!identity || !id) return;
        const [hostId] = (room?.name || '').split('__');
        if (id === hostId) {
          room!.disconnect();
        } else {
          enqueueSnackbar(`${identity} has left the call`, { variant: 'info' });
        }
      };

      const participantConnected = (participant: RemoteParticipant) => {
        const [, identity] = parseParticipantIdentity(participant);
        enqueueSnackbar(`${identity} has joined the call`, { variant: 'info' });
      };

      room.on('participantConnected', participantConnected);
      room.on('disconnected', onDisconnected);
      room.on('participantDisconnected', participantDisconnected);
      return () => {
        room.off('participantConnected', participantConnected);
        room.off('disconnected', onDisconnected);
        room.off('participantDisconnected', participantDisconnected);
      };
    }
  }, [user, room]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <RoomRightSidebarProvider>
      <Dialog fullScreen open={open} onClose={withOutsideClickPreventer(handleClose)} TransitionComponent={Transition}>
        {room && <Room />}
      </Dialog>
    </RoomRightSidebarProvider>
  );
}
