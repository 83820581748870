import { useEffect, useRef, useState } from 'react';

export interface ITimer {
  seconds: number;
  startTimer(duration: number): void;
}

export function useTimer(initialDuration: number) {
  const timerRef = useRef<NodeJS.Timer | null>(null);
  const [seconds, setSeconds] = useState<number>(initialDuration);

  useEffect(() => {
    if (initialDuration) {
      startTimer(initialDuration);
    }
  }, []);

  const getDeadline = (duration: number): Date => {
    const deadline: Date = new Date();

    deadline.setSeconds(deadline.getSeconds() + duration);
    return deadline;
  };

  const getTimeDif = (deadline: Date): number => {
    const total = +deadline - +new Date();
    return Math.ceil((total / 1000) % 60);
  };

  const startTimer = (duration: number): void => {
    const deadline: Date = getDeadline(duration);
    setSeconds(duration);

    if (timerRef.current) clearInterval(timerRef.current as unknown as number);

    const id = setInterval(() => {
      const timeDif: number = getTimeDif(deadline);
      if (timeDif >= 0) {
        setSeconds(timeDif);
      }
    }, 1000);
    timerRef!.current = id;
  };

  return { seconds, startTimer } as ITimer;
}
