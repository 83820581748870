import { createContext } from 'react';
import TypedEmitter from 'typed-emitter';
import { CallAccessAttributes, HubPatient, PaymentRequestCreatedMessage } from 'types';

export type EventType =
  | 'emdrStarted'
  | 'whiteboardStarted'
  | 'callEnded'
  | 'removedFromCall'
  | 'disconnected'
  | 'reconnected'
  | 'paymentRequestCancelled'
  | 'paymentRequestSucceeded';
export type EventsMap = { [key in EventType]: (() => void | null)[] };

export type SignalrConnectionEvents = {
  joinCall: (roomName: string, connectionId: string) => void;
  isProviderInQueueSent: (isProviderInQueue: boolean) => void;
  updatedStatusDataSent: () => void;
  updatedUnavailableProviderStatus: (unavailable: boolean) => void;
  muted: (muted: boolean) => void;
  userJoined: (data: HubPatient) => void;
  paymentRequestCreated: (data: PaymentRequestCreatedMessage) => void;
  paymentRequestCancelled: () => void;
  paymentRequestSucceeded: () => void;
};

export interface ISignalrConnectionContext {
  getPatientsList(): void;
  getIsProviderConnectedToQueue(): void;
  getToken(data: { identity: string; roomId: string }): Promise<string>;
  getChatToken(connectionId: string): Promise<string>;
  invitePatient(data: { connectionId: string; roomId: string }): void;
  removeParticipantFromCall(connectionId: string): void;
  mutePatientInCall(connectionId: string, muted: boolean): void;
  updateCallData(data: { callAccesses: CallAccessAttributes }): void;
  updateUnavailableProviderStatus(unavailable: boolean): void;
  removePatientFromQueue(connectionId: string): void;
  startEmdr(): void;
  startWhiteboard(): void;
  cancelRequestPaymentByProvider(): void;
  cancelRequestPaymentByClient(): void;
  setSuccessRequestPayment(): void;
  disconnect(): void;
  isFetching: boolean;
  events: TypedEmitter<SignalrConnectionEvents>;
  signalrConnectionId: string;
  callEnded(): void;
  addEventListener(eventType: EventType, callback: () => void): void;
  removeEventListener(eventType: EventType, callback: () => void): void;
  removeAllEventListeners(): void;
}

export const SignalrConnectionContext = createContext<ISignalrConnectionContext | null>(null);
export const SignalrConnectionConsumer = SignalrConnectionContext.Consumer;
