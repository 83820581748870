import { Box, Stack, Theme, useMediaQuery } from '@mui/material';
import classnames from 'classnames';

import { IAppSettingsContext } from 'context';
import { ToggleAudioButton, ToggleSettingsButton, ToggleVideoButton } from '../Buttons';
import { SettingsMenu } from '../SettingsMenu';
import { VideoPreview } from '../VideoPreview';
import { useAppSettings, useSignalrConnection, useVideo, useVideoBackgroundEffect } from 'hooks';

import styles from './VideoPreviewWithControls.module.scss';

type VideoPreviewWithControlsProps = {
  className?: string;
  controlsClassName?: string;
  buttonsClassName?: string;
  previewClassName?: string;
};

export function VideoPreviewWithControls({
  controlsClassName,
  previewClassName,
}: VideoPreviewWithControlsProps): JSX.Element {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { isAcquiringLocalTracks, isConnecting, room } = useVideo();
  const { isFetching } = useSignalrConnection();
  const { open, openSettings, closeSettings }: IAppSettingsContext = useAppSettings();

  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  useVideoBackgroundEffect();

  return (
    <div className={styles.container}>
      <Box position='relative' className={styles.preview}>
        <VideoPreview className={classnames(styles.videoPreview, previewClassName)} />
        <Stack className={classnames(styles.controls, controlsClassName)} direction='column'>
          <ToggleAudioButton disabled={disableButtons} withLabel={isMobile} />
          <ToggleVideoButton disabled={disableButtons} withLabel={isMobile} />
          <ToggleSettingsButton disabled={disableButtons} onClick={openSettings} withLabel={isMobile} />
        </Stack>
      </Box>

      {isDesktop && open && !room && (
        <div className={styles.settingsContainer}>
          <SettingsMenu className={styles.settings} onClose={closeSettings} withBackground />
        </div>
      )}
    </div>
  );
}
