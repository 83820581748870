import { Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import RegistrationImage from '../../../../assets/images/RegistrationLeftImage.png';
import styles from './RegisterLayout.module.scss';

export const RegisterLayout = () => {
  return (
    <Box className={styles.layoutContainer}>
      <img className={styles.registerImage} src={RegistrationImage} />
      <Typography variant='h1' className={styles.welcomeText}>
        Welcome!
      </Typography>
      <Typography variant='h1' className={styles.welcomeText}>
        Benefits HIPAA<span className={styles.link}>LINK</span>
      </Typography>
      <Box className={styles.layoutItem}>
        <CheckIcon />
        <Typography variant='body2'>Host all your visits from one custom link </Typography>
      </Box>
      <Box className={styles.layoutItem}>
        <CheckIcon />
        <Typography variant='body2'>Easy Check-in for your Clients in your real-time Virtual Waiting Room </Typography>
      </Box>
      <Box className={styles.layoutItem}>
        <CheckIcon />
        <Typography variant='body2'>One-to-One or Group Sessions up to 15 people</Typography>
      </Box>
      <Box className={styles.layoutItem}>
        <CheckIcon />
        <Typography variant='body2'>Take Notes during any visit and export them after the call</Typography>
      </Box>
      <Box className={styles.layoutItem}>
        <CheckIcon />
        <Typography variant='body2'>Screen Sharing, Chat, File Share, Spotlight and more</Typography>
      </Box>
      <Box className={styles.layoutItem}>
        <CheckIcon />
        <Typography variant='body2'>
          Perfect for practices of all sizes, from Sole Practitioner to Organizations
        </Typography>
      </Box>
      <Box className={styles.layoutItem}>
        <CheckIcon />
        <Typography variant='body2'>No Dropped Calls. The most reliable platform on the market!</Typography>
      </Box>
      <Box className={styles.layoutItem}>
        <CheckIcon />
        <Typography variant='body2'>No download or installation required</Typography>
      </Box>
    </Box>
  );
};
