import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function WhiteboardIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M10.8915 1.49995L8.4191 3.98157C8.09403 4.30789 7.85118 4.70717 7.71055 5.14629L7.08857 7.08865C6.97488 7.44367 6.97109 7.825 7.07767 8.18223C7.3695 9.15944 8.39701 9.71551 9.37306 9.42092L11.3322 8.82965C11.7903 8.69144 12.207 8.44132 12.5452 8.10192L15.9992 4.6338L16 10.4995C16 12.1563 14.6569 13.4994 13.0001 13.4994H3.00063C1.34385 13.4994 0.000771843 12.1563 0.000771843 10.4995L0 7.52066L0.0592786 7.47738L0.536431 7.0624C2.78373 5.14247 4.17322 4.46167 4.56967 4.78407L4.60713 4.82099C4.90586 5.17758 4.82178 5.46381 4.19171 6.25169L3.87377 6.64366C3.52273 7.08361 3.34764 7.35728 3.23021 7.69574C2.786 8.97595 3.18953 9.96423 4.20565 10.2341C4.74918 10.3785 5.40685 10.2793 6.18811 9.963C6.44406 9.85938 6.56754 9.56789 6.46392 9.31194C6.36029 9.05599 6.0688 8.93251 5.81286 9.03613C5.21405 9.27856 4.76073 9.34693 4.46235 9.26768C4.05766 9.16019 3.90783 8.79324 4.17491 8.02353C4.23707 7.84438 4.34905 7.66154 4.57018 7.37592L4.78784 7.10361L4.97106 6.87786C5.89594 5.71699 6.10465 5.05145 5.37366 4.17885C4.48275 3.11535 2.74104 3.87635 0.000862199 6.20427L0.000771843 4.49981C0.000771843 2.84303 1.34385 1.49995 3.00063 1.49995H10.8915ZM15.3314 0.961159L15.4411 1.06326L15.5425 1.1735C16.1849 1.92816 16.1507 3.06498 15.4402 3.77896L11.8369 7.39609C11.6155 7.61826 11.3429 7.78195 11.0433 7.87234L9.08413 8.46362C8.63885 8.59801 8.16958 8.34402 8.03588 7.89634C7.9869 7.73215 7.98864 7.55676 8.04088 7.3936L8.66285 5.45128C8.75515 5.1631 8.91444 4.90119 9.12753 4.68728L12.7392 1.06171C13.4502 0.347848 14.5812 0.31449 15.3314 0.961159Z' />
    </SvgIcon>
  );
}
