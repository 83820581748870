import { forwardRef, useMemo } from 'react';
import { Badge, Box, IconButton, styled, Typography } from '@mui/material';
import classnames from 'classnames';
import { Participant } from 'twilio-video';

import { PeopleTeamIcon } from 'assets';
import { ControlTooltip } from 'components';
import { useChat, useParticipants, useRoomHost } from 'hooks';
import { useAppSelector } from 'store';
import { ApiWaitingRoomLinkProfile } from 'types';
import { parseIdentity } from 'utils';
import { selectWaitingRoomProfile } from 'store/waitingRoom';

import styles from './ToggleParticipantsButton.module.scss';

type ToggleParticipantsButtonProps = {
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
  iconColor?: string;
  withTooltip?: boolean;
  onClick: () => void;
  withLabel?: boolean;
};

const PeopleTeamIconStyled = styled(PeopleTeamIcon)(({ theme }) => ({
  color: '#fff',
}));

export const ToggleParticipantsButton = forwardRef<HTMLButtonElement, ToggleParticipantsButtonProps>(
  (
    { disabled, className, iconClassName, iconColor, withTooltip, onClick, withLabel }: ToggleParticipantsButtonProps,
    ref,
  ) => {
    const { identity: chatIdentity, conversationsUnreadMessagesData } = useChat();
    const waitingRoomProfile: ApiWaitingRoomLinkProfile | null = useAppSelector(selectWaitingRoomProfile);
    const participants = useParticipants();
    const { isHost } = useRoomHost();

    const unreadMessagesCount: number = useMemo(() => {
      const ureadMessagesCount: number[] = [];

      if (isHost) {
        participants
          .map((participant: Participant) => {
            return (
              conversationsUnreadMessagesData.get(`$chat-${chatIdentity}-${parseIdentity(participant.identity)[0]}`)
                ?.unreadMessagesCount ?? 0
            );
          })
          .forEach((unreadMessagesCount: number) => {
            ureadMessagesCount.push(unreadMessagesCount);
          });
      } else {
        const chatWithProviderUnreadMessagesCount: number =
          conversationsUnreadMessagesData.get(`$chat-${waitingRoomProfile!.providerId}-${chatIdentity}`)
            ?.unreadMessagesCount ?? 0;

        ureadMessagesCount.push(chatWithProviderUnreadMessagesCount);
      }

      return ureadMessagesCount.length
        ? ureadMessagesCount.reduce((prevCount: number, currentCount: number) => prevCount + currentCount)
        : 0;
    }, [isHost, participants, chatIdentity, waitingRoomProfile, conversationsUnreadMessagesData]);

    const tooltipMessage = useMemo(() => {
      let tooltipMessage = '';

      if (withTooltip) {
        tooltipMessage = 'Participants';
      }

      return tooltipMessage;
    }, [withTooltip]);

    const sx = useMemo(
      () => ({
        backgroundColor: withLabel ? 'transparent !important' : 'rgb(0 0 0 / 50%) !important',
      }),
      [withLabel],
    );

    return (
      <ControlTooltip title={tooltipMessage} isDisabled={disabled}>
        <Box component='span' display='block' className={classnames(styles.deviceButton, className)}>
          <IconButton
            disabled={disabled}
            className={classnames(
              { [styles.deviceButton]: !withLabel, [styles.deviceButtonWithLabel]: withLabel },
              className,
            )}
            sx={sx}
            onClick={onClick}
            edge='end'
            aria-label='participants'
            ref={ref}
          >
            <Badge badgeContent={unreadMessagesCount} className={styles.badge} color='primary'>
              <PeopleTeamIconStyled
                className={classnames(styles.icon, iconClassName)}
                sx={{ color: iconColor || '#fff' }}
              />
            </Badge>

            {withLabel && (
              <Typography component='p' variant='body2' sx={{ color: 'FFF' }} ml='10px'>
                Participants
              </Typography>
            )}
          </IconButton>
        </Box>
      </ControlTooltip>
    );
  },
);
