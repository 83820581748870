import { useRef, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField, Box, Typography, Button, Stack, useMediaQuery, Theme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { selectUser } from 'store/user';
import { ApiUser } from 'types';
import { FormInput, Info, Spinner } from 'components';
import { IApiRequests, useApiRequests } from 'hooks';

import styles from './BAAPannel.module.scss';

type BAAPannelFormType = {
  organization: string;
  signatoryTitle: string;
};

export const BAAPannel = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const user: ApiUser = useSelector(selectUser)!;
  const { createBaa }: IApiRequests = useApiRequests();
  const aRef = useRef<HTMLAnchorElement>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const schema = Yup.object().shape({
    organization: Yup.string().max(100, 'Max length is 100 character').min(2, 'Min length is 2 character').required(),
    signatoryTitle: Yup.string().max(50, 'Max length is 50 character').min(2, 'Min length is 2 character').required(),
  });

  const form = useForm<BAAPannelFormType>({
    resolver: yupResolver(schema),
    defaultValues: {},
    mode: 'onBlur',
  });

  const handleCreate = (): void => {
    if (!form.formState.isValid) {
      return;
    }

    setLoading(true);

    createBaa({
      signatoryName: `${user.firstName} ${user.lastName}`,
      organization: form.getValues().organization,
      signatoryTitle: form.getValues().signatoryTitle,
    })
      .then((response: any) => {
        console.log(response);

        const blob = new Blob([response], {
          type: 'application/pdf',
        });

        aRef.current!.href = URL.createObjectURL(blob);
        aRef.current?.click();
      })
      .catch((error) => {
        enqueueSnackbar(`Create BAA error: ${error.message}`);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Box className={styles.container}>
      {loading && <Spinner />}

      <a ref={aRef} download='BAA.pdf' target='_blank' hidden />

      <div className={styles.innerContainer}>
        <Stack className={styles.row}>
          <Typography variant='body7' component='span'>
            Business Associate Agreement (BAA)
          </Typography>

          <Typography variant={isMobile ? 'body5' : 'body1'} component='span'>
            HIPAA-covered entities must have a BAA in place with each of their partners to maintain PHI security and
            overall HIPAA compliance.
          </Typography>
        </Stack>

        <Stack className={styles.row}>
          <Stack spacing={1} direction='row' alignItems='center'>
            <Typography variant='body2'>Full Name</Typography>

            <Info text='Name of signatory on the BAA.' />
          </Stack>

          <TextField size='small' value={`${user.firstName} ${user.lastName}`} disabled />
        </Stack>

        <Stack className={styles.row}>
          <Stack spacing={1} direction='row' alignItems='center'>
            <Typography variant='body2'>Relationship to Covered Entity</Typography>

            <Info text='Director, COO, etc.' />
          </Stack>

          <FormInput name='signatoryTitle' control={form.control} />
        </Stack>

        <Stack className={styles.row}>
          <Stack spacing={1} direction='row' alignItems='center'>
            <Typography variant='body2'>Covered Entity</Typography>

            <Info text='Your name or organization.' />
          </Stack>

          <FormInput name='organization' control={form.control} />
        </Stack>

        <Button className={styles.button} variant='contained' onClick={handleCreate} disabled={!form.formState.isValid}>
          Create BAA
        </Button>
      </div>

      <Typography variant='body1' component='span' className={styles.link}>
        For more information about BAA requirements, visit the&nbsp;
        <a
          target='_blank'
          href='https://www.hhs.gov/hipaa/for-professionals/faq/business-associates/index.html#business-associate-contracts'
        >
          U.S. Department of Health & Human Service
        </a>
        &nbsp;website.
      </Typography>
    </Box>
  );
};
