import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function PencilIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M11.6889 6.60958L4.74056 13.5595C4.36775 13.9323 3.90064 14.1968 3.38915 14.3247L0.723418 14.9911C0.29733 15.0976 -0.0886218 14.7117 0.0179001 14.2856L0.684334 11.6199C0.812205 11.1084 1.07668 10.6413 1.44949 10.2684L8.39779 3.3185L11.6889 6.60958ZM13.3352 1.67379C14.244 2.58259 14.244 4.05606 13.3352 4.96486L12.5116 5.78681L9.22055 2.49574L10.0441 1.67379C10.9529 0.764986 12.4264 0.764986 13.3352 1.67379Z' />
    </SvgIcon>
  );
}
