import { Stack, Typography } from '@mui/material';

import { CircleCrossIcon, HeavyCheckIcon } from 'assets';
import { ApiUser } from 'types';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';

type AvailableMarkProps = {
  className?: string;
};

export function AvailableMark({ className }: AvailableMarkProps) {
  const user: ApiUser = useAppSelector(selectUser)!;

  return (
    <Stack direction='row' spacing={1} alignItems='center' className={className}>
      {user.clinicUser.unavailable ? (
        <>
          <CircleCrossIcon sx={{ color: '#626363', fontSize: '1.6rem' }} />
          <Typography component='p' variant='body2'>
            Unavailable
          </Typography>
        </>
      ) : (
        <>
          <HeavyCheckIcon />
          <Typography component='p' variant='body2'>
            Available
          </Typography>
        </>
      )}
    </Stack>
  );
}
