import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function PlayIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M12.8706 6.78885L2.55851 0.692412C1.72065 0.197315 0.4375 0.677765 0.4375 1.90232V14.0923C0.4375 15.1908 1.62983 15.8529 2.55851 15.3022L12.8706 9.20867C13.7905 8.6667 13.7934 7.33082 12.8706 6.78885Z'
        fill='white'
      />
    </SvgIcon>
  );
}
