import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function MinusIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M2 9.89941L19 9.89941' strokeWidth='2.80602' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIcon>
  );
}
