import { useState, useEffect } from 'react';
import { Slider } from '@mui/material';
import styles from './NumberSlideInput.module.scss';

type NumberSlideInputProps = {
  minValue: number;
  maxValue: number;
  value?: number;
  defaultValue?: number;
  marks?: boolean;
  labelPostfix?: string;
  step?: number | null;
  onChange(event: Event, value: number | number[], activeThumb: number): void;
};

export function NumberSlideInput({
  minValue,
  maxValue,
  value,
  defaultValue,
  marks,
  labelPostfix = '',
  step,
  onChange,
}: NumberSlideInputProps) {
  const [localValue, setLocalValue] = useState<number | undefined>(defaultValue);

  useEffect(() => {
    if (value !== undefined) {
      setLocalValue(value);
    }
  }, [value]);

  const handleChange = (event: Event, newValue: number | number[], activeThumb: number) => {
    if (value === undefined) {
      setLocalValue(newValue as number);
    }
    onChange(event, newValue, activeThumb);
  };

  return (
    <div className={styles.input}>
      <Slider
        value={value !== undefined ? value : localValue}
        defaultValue={defaultValue}
        min={minValue}
        max={maxValue}
        marks={marks}
        step={step ?? 1}
        classes={{ mark: styles.mark }}
        onChange={handleChange}
        aria-labelledby='input-slider'
      />
      <div className={styles.value}>{`${value ?? localValue}${labelPostfix}`}</div>
    </div>
  );
}
