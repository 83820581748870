import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function PaymentIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M2.14286 2.75C0.959389 2.75 0 3.69018 0 4.84996V5.89993H15V4.84996C15 3.69018 14.0406 2.75 12.8571 2.75H2.14286Z' />
      <path d='M0 11.1511V6.95117H15V11.1511C15 12.3109 14.0406 13.251 12.8571 13.251H2.14286C0.959389 13.251 0 12.3109 0 11.1511ZM10.1786 10.1011C9.88275 10.1011 9.64286 10.3362 9.64286 10.6261C9.64286 10.916 9.88275 11.1511 10.1786 11.1511H12.3214C12.6172 11.1511 12.8571 10.916 12.8571 10.6261C12.8571 10.3362 12.6172 10.1011 12.3214 10.1011H10.1786Z' />
    </SvgIcon>
  );
}
