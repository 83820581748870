/* eslint-disable prettier/prettier */
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { ISubscriptionContext, SubscriptionContext } from './context';
import { useLocation } from 'react-router-dom';

import { IUserContext } from 'context/user';
import { LicenseUsageInfoResponse, PaymentResponseStatusEnum, SubscriptionStatusEnum } from 'types';
import { useAppSelector } from 'store';
import { IApiRequests, useApiRequests, useUser } from 'hooks';
import { selectUser } from 'store/user';

interface SubscriptionProviderProps extends PropsWithChildren { }

export function SubscriptionProvider({ children }: SubscriptionProviderProps) {
  const location = useLocation();
  const user = useAppSelector(selectUser);
  const useApiRequestsContext: IApiRequests = useApiRequests();
  const userContext: IUserContext = useUser();

  const [isLessThanThreeDaysLeft, setIsLessThanThreeDaysLeft] = useState<boolean>(false);
  const [trialDaysLeft, setTrialDaysLeft] = useState<number>(0);
  const [daysUntilBillingPeriodEnd, setDaysUntilBillingPeriodEnd] = useState<number>(0);
  const [totalSeats, setTotalSeats] = useState<number>(0);
  const [availableSeats, setAvailableSeats] = useState<number>(0);
  const [trialEndsAt, setTrialEndsAt] = useState<string>();

  const hasSubscription = useMemo<boolean>(() => {
    return !!user?.clinicUser.subscriptionInfo
  }, [location, user]);

  const isSubscriptionActive = useMemo<boolean>(() => {
    // this status which includes all active subscription statuses but for now leave this block
    return user?.clinicUser?.subscriptionInfo?.status === SubscriptionStatusEnum.Live ||
      user?.clinicUser?.subscriptionInfo?.status === SubscriptionStatusEnum.Trial;
  }, [location, user]);

  const subscriptionStatus = useMemo<SubscriptionStatusEnum>(() => {
    return (user?.clinicUser?.subscriptionInfo?.status
      ? user?.clinicUser?.subscriptionInfo?.status
      : SubscriptionStatusEnum.Unknown) as SubscriptionStatusEnum;
  }, [location, user]);

  const fetchSubscriptionInfo = async () => {
    if (user?.clinicId) {
      try {
        const { code, message, licenseUsageInfo }: LicenseUsageInfoResponse = await useApiRequestsContext.getLicenseUsageInfo(user!.clinicId);
        if (code !== PaymentResponseStatusEnum.Success) {
          console.error(message);
          return;
        }

        const now = new Date(licenseUsageInfo.serverTime);

        if (licenseUsageInfo.trialEndsAt) {
          const trialEndDate = new Date(licenseUsageInfo.trialEndsAt);
          const trialDaysLeft: number = Math.ceil((trialEndDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
          setIsLessThanThreeDaysLeft(trialDaysLeft <= 3);
          setTrialDaysLeft(trialDaysLeft > 0 ? trialDaysLeft : 0);
          setTrialEndsAt(licenseUsageInfo.trialEndsAt);
        }

        if (licenseUsageInfo.currentTermEndsAt) {
          const termEndDate = new Date(licenseUsageInfo.currentTermEndsAt);
          const timeDifference = termEndDate.getTime() - now.getTime();
          const daysUntilBillingPeriodEnd = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
          setDaysUntilBillingPeriodEnd(daysUntilBillingPeriodEnd);
        }

        setAvailableSeats(licenseUsageInfo.available);
        setTotalSeats(licenseUsageInfo.total);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchSubscriptionInfo();
  }, [user, userContext.tokens]);

  return (
    <SubscriptionContext.Provider
      value={{
        isLessThanThreeDaysLeft,
        trialDaysLeft,
        hasSubscription,
        isSubscriptionActive,
        subscriptionStatus,
        daysUntilBillingPeriodEnd,
        totalSeats,
        availableSeats,
        trialEndsAt,
        fetchSubscriptionInfo,
      } as ISubscriptionContext}
    >
      {children}
    </SubscriptionContext.Provider>
  );
}
