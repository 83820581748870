import { createSlice } from '@reduxjs/toolkit';
import { AppState } from 'store';

export type InviteData = {
  email: string;
  firstName: string;
  lastName: string;
  organizationName: string;
  userId: string | null;
  invitationId: string;
};

export interface IInviteState {
  data: InviteData | null;
}

const initialInviteData: InviteData = {
  email: '',
  firstName: '',
  lastName: '',
  organizationName: '',
  userId: null,
  invitationId: '',
};

const initialState: IInviteState = {
  data: initialInviteData,
};

export const inviteSlice = createSlice({
  name: 'invite',
  initialState,
  reducers: {
    updateInvite: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    clearInvite: (state) => {
      state.data = null;
    },
  },
});

// Actions
export const { updateInvite, clearInvite } = inviteSlice.actions;

// Selectors
export const selectInvite = (state: AppState) => state.invite.data;

export default inviteSlice.reducer;
