import { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import classNames from 'classnames';
import Cropper, { Area, Point } from 'react-easy-crop';

import { FormErrorHelperText, ModalTitle, UploadButton } from 'components';
import { getCroppedImg } from 'utils';

import styles from './FormMediaUpload.module.scss';

type FormMediaUploadProps = {
  title: string;
  src?: string;
  disabled?: boolean;
  onlyView?: boolean;
  onInputChange: (file: File) => void;
  onRemove: () => void;
};

const acceptFormats: string[] = ['.jpg', '.jpeg', '.png'];

export function FormMediaUpload({
  onInputChange,
  onRemove,
  src,
  title,
  disabled,
  onlyView,
}: FormMediaUploadProps): JSX.Element {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const [imageSrc, setImageSrc] = useState<string | undefined>(src);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [openCropImageDialog, setOpenCropImageDialog] = useState<boolean>(false);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [area, setArea] = useState<Area | null>(null);
  const [zoom, setZoom] = useState<number>(1);

  const validate = (file: File): boolean => {
    let isValid = false;
    const fileSize = file.size / 1024 / 1024; // in MB
    if (fileSize > 10) {
      setError('The file is too large and cannot be uploaded. (Max: 10 MB)');
    } else {
      setError('');
      isValid = true;
    }
    return isValid;
  };

  const imageSrcToShowOnForm = useMemo(() => {
    return openCropImageDialog ? src : imageSrc;
  }, [src, imageSrc, openCropImageDialog]);

  const handleInputChange = useCallback((e: any) => {
    if (!e.target.files.length) {
      return;
    }
    const file = e.target.files[0] as File;

    if (validate(file)) {
      setImageSrc(URL.createObjectURL(file));
      setOpenCropImageDialog(true);
    }
  }, []);

  const handleAcceptImage = useCallback(async () => {
    if (!imageSrc || !area) {
      return;
    }

    setLoading(true);
    const resultImgBlob = await getCroppedImg(imageSrc, area);
    if (!resultImgBlob) {
      setLoading(false);
      throw Error('Error handling image crop');
    }

    const file = new File([resultImgBlob], 'avatar.png');
    onInputChange(file);
    setImageSrc(URL.createObjectURL(file));
    setOpenCropImageDialog(false);
    setLoading(false);
  }, [imageSrc, area, onInputChange]);

  const handleRemove = () => {
    setImageSrc('');
    setError('');
    onRemove();
  };

  const handleClose = () => {
    setImageSrc(src);
    setError('');
    setOpenCropImageDialog(false);
  };

  return (
    <>
      <Stack className={styles.container}>
        <Box className={styles.imageContainer}>
          {imageSrcToShowOnForm ? (
            <img className={styles.image} src={imageSrcToShowOnForm} alt={title} loading='lazy' />
          ) : (
            <Typography component='p' variant='body' align='center'>
              {title}
            </Typography>
          )}
        </Box>

        {!onlyView && (
          <>
            <Stack direction='column' justifyContent='space-between'>
              <UploadButton
                onInputChange={handleInputChange}
                acceptFormats={acceptFormats.join(', ')}
                disabled={disabled}
                className={styles.upload}
              />
              <Stack textAlign='left'>
                <Typography variant={isDesktop ? 'body6' : 'body1'} color='#626363'>
                  Max File Size: 10 MB
                </Typography>
                <Typography variant={isDesktop ? 'body6' : 'body1'} color='#626363'>
                  Acceptable Formats: jpg, png only
                </Typography>
              </Stack>
            </Stack>

            <Typography
              variant={isDesktop ? 'body6' : 'body2'}
              color='#979797'
              component='u'
              className={classNames(styles.remove, imageSrc ? '' : styles.removeHidden)}
              onClick={handleRemove}
            >
              Remove
            </Typography>
            <FormErrorHelperText className={styles.errorHelper}>{error}</FormErrorHelperText>
          </>
        )}
      </Stack>

      <Dialog open={openCropImageDialog}>
        <DialogTitle>
          <ModalTitle title={'Crop'} onClose={handleClose} />
        </DialogTitle>

        <DialogContent sx={{ width: 350, display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Cropper
            image={imageSrc}
            crop={crop}
            minZoom={0.1}
            objectFit='contain'
            restrictPosition={false}
            zoomSpeed={0.05}
            style={{
              containerStyle: {
                width: '100%',
                height: '300px',
                position: 'relative',
              },
            }}
            zoom={zoom}
            aspect={1}
            onCropChange={(crop) => {
              setCrop(crop);
            }}
            onCropComplete={(_, croppedAreaPixels: Area) => {
              setArea(croppedAreaPixels);
            }}
            onZoomChange={setZoom}
          />

          <Stack sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '16px' }}>
            <Button variant='outlined' disabled={loading} onClick={handleClose}>
              Decline
            </Button>

            <Button variant='contained' disabled={loading} onClick={handleAcceptImage}>
              Accept
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
