import { useCallback, useEffect } from 'react';

import { PLAYED_MESSAGES_MAP } from 'constants/';
import { ConversationUnreadMessagesData } from 'context/chat/hooks';
import { useChat, useLocalStorageState, useVideo } from 'hooks';
import { ApiUser } from 'types';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { IVideoContext } from 'context';

import vibelongNote from 'assets/audio/chat/music_vibelong_note.wav';

const CONVERSATION_LIFETIME_IN_MILLISECONDS = 86400000; // 1 day
// const CONVERSATION_LIFETIME_IN_MILLISECONDS = 60000; // 1 minute

export function useChatNewMessageSound() {
  const { conversationsUnreadMessagesData } = useChat();
  const user: ApiUser | null = useAppSelector(selectUser);
  const { room }: IVideoContext = useVideo();

  const [playedMessagesMap, setPlayedMessagesMap] = useLocalStorageState<{ [key: string]: Date }>(
    PLAYED_MESSAGES_MAP,
    {},
  );

  const playIncomingMessageSoundIfNeed = useCallback(() => {
    if (user && !user.clinicUser.notificationsSound) {
      return;
    }

    if (user && !user.clinicUser.receiveMessages) {
      return;
    }

    if (user && room && !user.clinicUser.receiveMessagesInMeeting) {
      return;
    }

    const sound = new Howl({
      src: [vibelongNote],
      onload: () => {
        sound.play();
      },
    });
  }, [user, room]);

  useEffect(() => {
    const conversationUniqueNamesToPlay: string[] = [];
    conversationsUnreadMessagesData.forEach((unreadItem: ConversationUnreadMessagesData, key: string) => {
      if (unreadItem.unreadMessagesCount > 0 && !playedMessagesMap[key]) {
        conversationUniqueNamesToPlay.push(key);
      }
    });

    const currentDateTime: Date = new Date(user?.clinicUser?.subscriptionInfo?.serverTime ?? new Date());
    const conversationUniqueNamesToDelete: string[] = [];
    Object.keys(playedMessagesMap).forEach((key: string) => {
      if (
        new Date(playedMessagesMap[key]).getTime() + CONVERSATION_LIFETIME_IN_MILLISECONDS <
        currentDateTime.getTime()
      ) {
        conversationUniqueNamesToDelete.push(key);
      }
    });

    if (conversationUniqueNamesToPlay.length || conversationUniqueNamesToDelete.length) {
      const newPlayedMessagesMap: { [key: string]: Date } = {};

      Object.keys(playedMessagesMap).forEach((key: string) => {
        if (!conversationUniqueNamesToDelete.includes(key)) {
          newPlayedMessagesMap[key] = playedMessagesMap[key];
        }
      });

      conversationUniqueNamesToPlay.forEach((key: string) => {
        newPlayedMessagesMap[key] = new Date();
        playIncomingMessageSoundIfNeed();
      });

      setPlayedMessagesMap(newPlayedMessagesMap);
    }
  }, [user, conversationsUnreadMessagesData, playedMessagesMap, playIncomingMessageSoundIfNeed]);

  return {};
}
