import { useNavigate } from 'react-router-dom';
import { Box, Stack, Typography, styled } from '@mui/material';
import { SettingsIcon } from 'assets';

import StripeLabel from 'assets/images/StripeLabel.png';
import AI from 'assets/images/AI.png';
import { FC } from 'react';

const StyledStack = styled(Stack)(() => ({
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: 80,
  width: 80,
  borderRadius: '10px',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: '#81CFDD',
  background: '#fff',
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'none',
    backgroundColor: 'rgba(129, 207, 221, 0.04)',
  },
}));

const StyledTypography = styled(Typography)(() => ({
  width: '100%',
  textAlign: 'center',
  color: '#393534',
  fontFamily: 'Open Sans',
  fontSize: '10px',
  fontWeight: 700,
  lineHeight: '13.62px',
}));

export type AINotesMenuProps = {
  aiCreditsCount?: number;
};

export const AINotesMenu: FC<AINotesMenuProps> = ({ aiCreditsCount }) => {
  const navigate = useNavigate();

  const handleNavigateToSettings = () => {
    navigate('/settings#accountSettings');
  };

  const handleNavigateToAINotes = () => {
    navigate('/settings#aiNotes');
  };

  const handleNavigateToAcceptPayments = () => {
    navigate('/settings#acceptPayments');
  };

  return (
    <Stack
      direction='row'
      alignItems='start'
      justifyContent='space-between'
      sx={({ breakpoints }) => ({
        gridColumnStart: 1,
        gridColumnEnd: 2,
        gridRowStart: 2,
        gridRowEnd: 3,
        [breakpoints.down(900)]: {
          justifyContent: 'center',
          gap: '19px',
        },
      })}
    >
      <StyledStack gap='4px' onClick={handleNavigateToAINotes}>
        <Box component='img' src={AI} sx={{ width: 24, height: 24 }} />
        <Stack direction='column' alignItems='center' justifyContent='center'>
          <StyledTypography>AI Notes</StyledTypography>
          <StyledTypography sx={{ fontWeight: 400 }}>{`${aiCreditsCount ?? 0}`} Credits</StyledTypography>
        </Stack>
      </StyledStack>
      <StyledStack gap='4px' onClick={handleNavigateToAcceptPayments}>
        <Box component='img' src={StripeLabel} sx={{ width: 50, height: 23 }} />
        <StyledTypography>Accept Payments</StyledTypography>
      </StyledStack>
      <StyledStack gap='8px' onClick={handleNavigateToSettings}>
        <SettingsIcon
          sx={{
            width: 24,
            height: 24,
            '&.MuiSvgIcon-root path': { fill: '#81CFDD' },
          }}
        />
        <StyledTypography>Account Settings</StyledTypography>
      </StyledStack>
    </Stack>
  );
};
