import React, { forwardRef, useMemo } from 'react';
import { Badge, Box, IconButton, styled } from '@mui/material';
import classnames from 'classnames';

import { GroupChatIcon } from 'assets';
import { ControlTooltip } from 'components';
import { useChat, useVideo } from 'hooks';
import { IChatContext, IVideoContext } from 'context';

import styles from './ToggleChatButton.module.scss';

type ToggleChatButtonProps = {
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
  withTooltip?: boolean;
  onClick: () => void;
};

const GroupChatIconStyled = styled(GroupChatIcon)(({ theme }) => ({
  color: '#fff',
}));

export const ToggleChatButton = forwardRef<HTMLButtonElement, ToggleChatButtonProps>(
  ({ disabled, className, iconClassName, withTooltip, onClick }: ToggleChatButtonProps, ref) => {
    const { conversationsUnreadMessagesData }: IChatContext = useChat();
    const { room }: IVideoContext = useVideo();

    const tooltipMessage = useMemo(() => {
      let tooltipMessage = '';

      if (withTooltip) {
        tooltipMessage = 'Chat';
      }

      return tooltipMessage;
    }, [withTooltip]);

    const unreadMessagesCount: number = useMemo(() => {
      return conversationsUnreadMessagesData.get(room?.name ?? '')?.unreadMessagesCount ?? 0;
    }, [conversationsUnreadMessagesData, room]);

    return (
      <ControlTooltip title={tooltipMessage} isDisabled={disabled}>
        <Box component='span' display='block' className={classnames(styles.deviceButton, className)}>
          <IconButton
            disabled={disabled}
            className={classnames(styles.deviceButton, className)}
            sx={{
              backgroundColor: 'rgb(0 0 0 / 50%) !important',
            }}
            onClick={onClick}
            edge='end'
            aria-label='chat'
            ref={ref}
          >
            <Badge badgeContent={unreadMessagesCount} className={styles.chatIconBadge} color='primary'>
              <GroupChatIconStyled className={classnames(styles.icon, iconClassName)} />
            </Badge>
          </IconButton>
        </Box>
      </ControlTooltip>
    );
  },
);
