import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function ErrorOutlineIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='11' height='11' viewBox='0 0 11 11' fill='none' {...props}>
      <path d='M5.5 3.70001V5.50001' stroke='#FF3D3D' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5.5 7.30944V7.29999' stroke='#FF3D3D' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M5.5 10C7.98528 10 10 7.98528 10 5.5C10 3.01472 7.98528 1 5.5 1C3.01472 1 1 3.01472 1 5.5C1 7.98528 3.01472 10 5.5 10Z'
        stroke='#FF3D3D'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}
