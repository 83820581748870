import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HubPatient, IBasicState } from 'types';

import { AppState } from '../index';

export interface IPatientQueueState extends IBasicState {
  data: HubPatient[];
  message: string | null;
  error: string | null;
}

const initialState: IPatientQueueState = {
  data: [],
  loading: false,
  message: null,
  error: null,
};

export const setPatients = createAsyncThunk<HubPatient[], HubPatient[], { state: AppState }>(
  'patientQueue/setPatients',
  async (patients, { getState }): Promise<HubPatient[]> => {
    // TODO: need change BL to store patients in store if they in call
    const state = getState();
    if (state.patientQueue.data.length > 0) {
      const newState = patients.filter((updatedPatient) => {
        return state.patientQueue.data.some((patient) => patient.connectionId === updatedPatient.connectionId);
      });
      return newState;
    } else {
      const userId = state.user?.data?.clinicUser.id;
      const newState = patients.filter((patient) => patient.userName !== userId);
      return newState;
    }
  },
);

export const addPatient = createAsyncThunk<HubPatient[], HubPatient, { state: AppState }>(
  'patientQueue/addPatient',
  async (patient, { getState }): Promise<HubPatient[]> => {
    const state = getState();
    const userId = state.user?.data?.clinicUser.id;
    const newState = [...state.patientQueue.data];
    if (patient.userName !== userId) {
      newState.push(patient);
    }
    return newState;
  },
);

export const patientQueueSlice = createSlice({
  name: 'patientQueue',
  initialState,
  reducers: {
    clearPatientQueueStore: (state) => {
      state.data = [];
    },
    clearPatientQueueMessages: (state) => {
      state.message = null;
      state.error = null;
    },
    removePatient: (state, action) => {
      state.data = state.data.filter((patient) => patient.connectionId !== action.payload.connectionId);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setPatients.fulfilled, (state, action) => {
        state.data = [...action.payload];
      })
      .addCase(addPatient.fulfilled, (state, action) => {
        state.data = [...action.payload];
      });
  },
});

// Actions
export const { clearPatientQueueStore, clearPatientQueueMessages, removePatient } = patientQueueSlice.actions;

// Selectors
export const selectPatients = (state: AppState) => state.patientQueue.data;

export default patientQueueSlice.reducer;
