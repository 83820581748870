import { MutableRefObject, useEffect, useRef } from 'react';

import { useChat, useVideo } from 'hooks';

import { ApiUser } from 'types';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { IVideoContext } from 'context';

export function useOpenChatOnNewMessage() {
  const { conversation, lastUpdatedChat, conversationsUnreadMessagesData, openChatByUniqueName } = useChat();
  const user: ApiUser | null = useAppSelector(selectUser);
  const { room }: IVideoContext = useVideo();

  const lastMessagesCountInUpdatedChat: MutableRefObject<number> = useRef<number>(0);

  useEffect(() => {
    const updatedConversationUnreadMessagesCount: number =
      conversationsUnreadMessagesData.get(lastUpdatedChat?.uniqueName ?? '')?.unreadMessagesCount ?? 0;

    const messagesCountInLastUpdatedChatChanged: boolean =
      updatedConversationUnreadMessagesCount !== lastMessagesCountInUpdatedChat.current;

    lastMessagesCountInUpdatedChat.current = updatedConversationUnreadMessagesCount;

    if (!messagesCountInLastUpdatedChatChanged) {
      return;
    }

    if (conversation) {
      return;
    }

    if (user && !user.clinicUser.receiveMessages) {
      return;
    }

    if (user && room && !user.clinicUser.receiveMessagesInMeeting) {
      return;
    }

    if (!lastUpdatedChat?.uniqueName?.startsWith('$chat')) {
      return;
    }

    openChatByUniqueName(lastUpdatedChat?.uniqueName ?? '');
  }, [conversationsUnreadMessagesData, lastUpdatedChat, user, room, conversation, openChatByUniqueName]);

  return {};
}
