import { MenuItem, Checkbox, SelectProps, Select } from '@mui/material';
import { Controller, Control } from 'react-hook-form';
import { ChevronRightIcon } from 'assets';

import styles from './FormSelector.module.scss';

type BasicSelectOption = {
  id: string;
  value: string;
};

type FormSelectorPropsType = {
  control: Control<any, object>;
  name: string;
  options?: BasicSelectOption[];
  withoutNone?: boolean;
} & SelectProps<string | string[]>;

export function FormSelector({ control, name, multiple, options, withoutNone, ...props }: FormSelectorPropsType) {
  const isMultipleSelector = Boolean(multiple);

  function getRenderValue(selected: string | string[]): string | undefined {
    return Array.isArray(selected)
      ? selected.map((_selected) => options?.find((option) => option.id === _selected)?.value).join(', ')
      : options?.find((option) => option.id === selected)?.value;
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Select
          {...field}
          {...props}
          fullWidth
          className={styles.select}
          multiple={isMultipleSelector}
          variant='outlined'
          IconComponent={ChevronRightIcon}
          renderValue={getRenderValue}
        >
          {!withoutNone && <MenuItem value={''}>None</MenuItem>}
          {options?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {isMultipleSelector && <Checkbox checked={field.value?.indexOf(option.id) > -1} />}
              {option.value}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
}
