import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function PentagonIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M9.6113 1.62483C9.843 1.45839 10.157 1.45839 10.3887 1.62483L18.7274 7.61865C18.9591 7.78542 19.056 8.08036 18.9677 8.34981L15.7825 18.0481C15.6939 18.3175 15.4401 18.5 15.1538 18.5H4.84616C4.55993 18.5 4.30608 18.3175 4.2175 18.0481L1.03227 8.34981C0.944024 8.08036 1.04087 7.78542 1.27256 7.61865L9.6113 1.62483Z'
        fill='none'
        strokeWidth='2'
      />
    </SvgIcon>
  );
}
