import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Dialog, Typography, Button, Stack, DialogTitle, DialogContent } from '@mui/material';
import { useSnackbar } from 'notistack';

import { InviteRequest } from 'types';
import { FormInput, ModalTitle, Spinner } from 'components';
import { withOutsideClickPreventer } from 'utils';

import styles from './InvitePopup.module.scss';

type InvitePopupProps = {
  open: boolean;
  onInvite(model: InviteRequest): Promise<void>;
  onClose(): void;
};

const schema = Yup.object().shape({
  firstName: Yup.string().label('First Name').required(),
  lastName: Yup.string().label('Last Name').required(),
  email: Yup.string().label('Email').required().email(),
  isLicenceActive: Yup.boolean(),
});

export const InvitePopup = ({ open, onInvite, onClose }: InvitePopupProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);

  const form = useForm<InviteRequest>({
    resolver: yupResolver(schema),
    defaultValues: {},
    mode: 'all',
  });

  const handleInvite = (): void => {
    setLoading(true);
    if (!form.formState.isValid) {
      return;
    }

    const formValues: InviteRequest = form.getValues();

    onInvite(formValues)
      .then(() => {
        form.reset({});
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box>
      <Dialog className={styles.dialog} open={open} onClose={withOutsideClickPreventer(onClose)}>
        {loading && <Spinner small />}
        <DialogTitle className={styles.dialogTitle}>
          <ModalTitle title='INVITE STAFF' onClose={onClose} />
        </DialogTitle>

        <DialogContent className={styles.content}>
          <Stack className={styles.row}>
            <Typography variant='body2'>
              First Name <span className={styles.asterisk}>*</span>
            </Typography>
            <FormInput name='firstName' control={form.control} />
          </Stack>

          <Stack className={styles.row}>
            <Typography variant='body2'>
              Last Name <span className={styles.asterisk}>*</span>
            </Typography>
            <FormInput name='lastName' control={form.control} />
          </Stack>

          <Stack className={styles.row}>
            <Typography variant='body2'>
              Email <span className={styles.asterisk}>*</span>
            </Typography>
            <FormInput name='email' control={form.control} />
          </Stack>

          {/* temporary hidden */}
          {/* <Stack className={styles.assignSeat}>
          <FormControlLabel
            control={
              <Checkbox
                className={styles.checkbox}
                {...form.register('isLicenceActive')}
                icon={<CheckboxIcon className={styles.checkboxIcon} />}
                checkedIcon={<CheckboxCheckedIcon className={styles.checkboxIcon} />}
              />
            }
            label='Assign seat'
          />
          <Info text='Manage your seats in subscriptions' />
        </Stack> */}

          <Button variant='contained' disabled={!form.formState.isValid || loading} onClick={handleInvite}>
            Send
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
