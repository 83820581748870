import React, { useMemo } from 'react';
import { Box, Card, CardContent, CardHeader, Hidden, Tab, Theme, useMediaQuery } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';

import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { ApiUser } from 'types';
import { VideoPreviewWithControls } from 'components';
import { MyRoom } from '../MyRoom';
import { WelcomeLayout } from '../WelcomeLayout';

import styles from './MyWaitingRoom.module.scss';
import { AvailableMark } from '../AvailableMark';

type MyWaitingRoomProps = {
  className?: string;
};

export function MyWaitingRoom({ className }: MyWaitingRoomProps) {
  const user: ApiUser | null = useAppSelector(selectUser);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };

  const cardHeaderTitle = useMemo(() => {
    let title: React.ReactNode;
    switch (true) {
      case isDesktop: {
        title = (
          <TabList onChange={handleChange} aria-label='Waiting room tabs'>
            <Tab label='My room' value='/' />
            <Tab label='Edit Waiting Room' value='/edit' />
          </TabList>
        );
        break;
      }
      default: {
        title = 'My room';
        break;
      }
    }
    return title;
  }, [isDesktop]);

  return (
    <>
      <TabContext value={'/'}>
        <Card className={classnames(styles.main, className)}>
          {!user?.clinicUser.roomName ? (
            <WelcomeLayout />
          ) : (
            <Box>
              {isMobile && <AvailableMark className={styles.availableMark} />}

              <CardHeader title={cardHeaderTitle} sx={{ padding: isDesktop ? 0 : '0 20px' }} />

              <CardContent className={styles.content}>
                <Box className={styles.contentWrapper} maxWidth={isDesktop ? 510 : '100%'}>
                  <MyRoom />
                </Box>
                <Hidden only={['sm', 'xs']}>
                  <Box className={styles.preview}>
                    <VideoPreviewWithControls />
                  </Box>
                </Hidden>
              </CardContent>
            </Box>
          )}
        </Card>
      </TabContext>
    </>
  );
}
