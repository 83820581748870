import { createContext } from 'react';
import { Participant } from 'twilio-video';
import { ParticipantDotMenuDialogType } from './provider';

export interface IParticipantDotMenuContext {
  onDialogOpen(type: ParticipantDotMenuDialogType, participant: Participant): void;
}

export const ParticipantDotMenuContext = createContext<IParticipantDotMenuContext | null>(null);
export const ParticipantDotMenuConsumer = ParticipantDotMenuContext.Consumer;
