import React, { useMemo } from 'react';
import classnames from 'classnames';
import { Box, Typography } from '@mui/material';
import { LocalVideoTrack, Participant, RemoteAudioTrack, RemoteParticipant, RemoteVideoTrack } from 'twilio-video';
import {
  // useIsRecording,
  useIsTrackEnabled,
  useIsTrackSwitchedOff,
  useMutedParticipants,
  useParticipantIsReconnecting,
  useParticipantNQ,
  usePublications,
  useRoomHost,
  useRoomState,
  useScreenShareParticipant,
  useSelectedParticipant,
  useTrack,
  useVideo,
} from 'hooks';
import { getPreferredFullName, parseParticipantIdentity } from 'utils';
import { MicOffIcon, NQIcon, PinIcon, StarIcon, UserIcon } from 'assets';
import { ParticipantDotMenu, ReconnectingAnimation } from 'components';
import { IMutedParticipantsContext } from 'context';
import { ApiUser, ApiWaitingRoomLinkProfile } from 'types';
import { useAppSelector } from 'store';
import { selectWaitingRoomProfile } from 'store/waitingRoom';
import { selectUser } from 'store/user';

import styles from './MainParticipantInfo.module.scss';

interface MainParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  className?: string;
}

export function MainParticipantInfo({ participant, children, className = '' }: MainParticipantInfoProps) {
  const { room, isSharingScreen } = useVideo();
  const { isHost } = useRoomHost();
  const participantDisplayingSharedScreen = useScreenShareParticipant();
  const localParticipant = room!.localParticipant;
  const isLocal = localParticipant === participant;
  const [id, identity] = useMemo(() => {
    return parseParticipantIdentity(participant);
  }, [participant]);
  const [identityDisplayingSharedScreenId, identityDisplayingSharedScreen] = useMemo(() => {
    return participantDisplayingSharedScreen ? parseParticipantIdentity(participantDisplayingSharedScreen) : [];
  }, [participantDisplayingSharedScreen]);
  const { mutedParticipantsId }: IMutedParticipantsContext = useMutedParticipants();

  const publications = usePublications(participant);
  const [selectedParticipant] = useSelectedParticipant();
  const videoPublication = publications.find((p) => !p.trackName.includes('screen') && p.kind === 'video');
  const screenSharePublication = publications.find((p) => p.trackName.includes('screen'));

  const audioPublication = publications.find((p) => p.kind === 'audio');
  const audioTrack = useTrack(audioPublication) as RemoteAudioTrack | undefined;
  const isAudioEnabled = useIsTrackEnabled(audioTrack as RemoteAudioTrack);

  const videoTrack = useTrack(screenSharePublication || videoPublication);
  const isVideoEnabled = Boolean(videoTrack);

  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);
  const nq: number = useParticipantNQ(participant);

  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';

  // const isRecording = useIsRecording();

  const user: ApiUser | null = useAppSelector(selectUser);
  const waitingRoomProfile: ApiWaitingRoomLinkProfile | null = useAppSelector(selectWaitingRoomProfile);

  const { clinicUser } = user || {};
  const { preferedDisplayName: clinicUserPreferredDisplayName } = clinicUser || {};
  const { preferedDisplayName: waitingRoomProfilePreferredDisplayName, providerId } = waitingRoomProfile || {};

  const participantMuted: boolean = useMemo(() => {
    const isMuted: boolean = mutedParticipantsId.some((mutedParticipantId: string) => mutedParticipantId === id);
    return isMuted;
  }, [id, mutedParticipantsId]);

  const showStar: boolean = useMemo(() => {
    return (isHost && id === user?.clinicUser.id) || (!isHost && id === waitingRoomProfile?.providerId);
  }, [isHost, id, waitingRoomProfile, user]);

  const preferredFullName = useMemo(() => {
    const clinicUserFullName = getPreferredFullName(clinicUserPreferredDisplayName, null);
    const waitingRoomFullName = getPreferredFullName(waitingRoomProfilePreferredDisplayName, identity);

    return showStar ? clinicUserFullName ?? waitingRoomFullName : identity;
  }, [isHost, id, waitingRoomProfilePreferredDisplayName, clinicUserPreferredDisplayName]);

  return (
    <Box className={classnames(styles.container, className)} flex={1} sx={{ border: '1px solid #979797' }}>
      {identityDisplayingSharedScreenId && (
        <Box className={classnames(styles.screenSharingInfoContainer)}>
          <Box component='span' className={classnames(styles.screenSharingInfo)}>
            {isSharingScreen ? <>You're presenting to everyone</> : `${identityDisplayingSharedScreen} is presenting`}
          </Box>
        </Box>
      )}
      <Box className={styles.infoContainer}>
        <Box className={styles.infoRowBottom}>
          {!screenSharePublication && !isLocal && (
            <Box component='span' className={styles.identity}>
              {selectedParticipant === participant && <PinIcon className={styles.star} />}
              {(!isAudioEnabled || participantMuted) && <MicOffIcon className={styles.micOff} />}
              {showStar && <StarIcon className={styles.star} />}
              <Typography variant='body1' component='span' sx={{ color: '#FFF' }}>
                {preferredFullName}
              </Typography>
            </Box>
          )}
          <Box component='span' className={styles.addInfo}>
            <div className={styles.nq}>
              <NQIcon nq={nq} />
            </div>

            {!screenSharePublication && isHost && (
              <ParticipantDotMenu
                withMute
                withRemove
                participant={participant as RemoteParticipant}
                toggleClassName={styles.dotMenuToggle}
                iconClassName={styles.dotMenuIcon}
                iconColor={'#FFF'}
                menuBackgroundColor={'rgba(0, 0, 0, 0.5)'}
                listItemTextColor={'#FFF'}
                tooltipPlacement='right-end'
              />
            )}
          </Box>
        </Box>
        {/* {isRecording && (
          <Tooltip
            title="All participants' audio and video is currently being recorded. Visit the app settings to stop recording."
            placement='top'
          >
            <Box className={styles.recordingIndicator} sx={{ backgroundColor: 'rgb(0 0 0 / 50%)' }}>
              <Box className={styles.circle}></Box>
              <Typography variant='body1' sx={{ color: '#FFF' }} data-cy-recording-indicator>
                Recording
              </Typography>
            </Box>
          </Tooltip>
        )} */}
      </Box>
      <Box className={styles.innerContainer}>
        {(!isVideoEnabled || isVideoSwitchedOff) && <UserIcon sx={{ color: '#979797', fontSize: '8.8rem' }} />}
        {(isParticipantReconnecting || isReconnecting) && <ReconnectingAnimation />}
        {children}
      </Box>
    </Box>
  );
}
