/* eslint-disable prettier/prettier */

import { PropsWithChildren, useEffect, useMemo, useRef } from 'react';

import TagManager from 'react-gtm-module';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';

import { TrackingAnalyticsContext, ITrackingAnalyticsContext } from './context';
import { googleTagManagerInitializeArgs, googleEventIdMap, facebookPixelId } from './config';
import { TrackingEventsEnum } from 'types/trackingAnalytics';

// Facebook Pixel Code
function initializeFacebookPixel(f: any, b: any, e: string, v: string): Promise<void> {
  return new Promise((resolve, reject) => {
    if (f.fbq) resolve();
    const n: any = (f.fbq = function (...args: any[]) {
      n.callMethod
        ? //eslint-disable-next-line
          n.callMethod.apply(n, args)
        : n.queue.push(args);
    });
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = true;
    n.version = '2.0';
    n.queue = [];
    const t = b.createElement(e);
    t.async = true;
    t.src = v;
    const s = b.getElementsByTagName(e)[0];
    s.parentNode!.insertBefore(t, s);
    t.onload = resolve;
    t.onerror = reject;
  });
}

function initializeTrackdesk(): Promise<void> {
  return new Promise((resolve) => {
    if ((window as any).trackdesk) {
      resolve();
    }

    const script: HTMLScriptElement = document.createElement('script');
    script.src = '//cdn.trackdesk.com/tracking.js';
    script.async = true;
    const firstScript: HTMLScriptElement = document.getElementsByTagName('script')[0];
    firstScript.parentNode!.insertBefore(script, firstScript);

    script.onload = () => {
      (function (t: any, d: any, k: any) {
        (t[k] = t[k] || []).push(d);
        t[d] =
          t[d] ||
          t[k].f ||
          function () {
            // eslint-disable-next-line prefer-rest-params
            (t[d].q = t[d].q || []).push(arguments);
          };
      })(window, 'trackdesk', 'TrackdeskObject');
      resolve();
    };
  });
}

interface TrackingAnalyticsProviderProps extends PropsWithChildren {}

export function TrackingAnalyticsProvider({ children }: TrackingAnalyticsProviderProps) {
  const navigate: NavigateFunction = useNavigate();
  const { search } = useLocation();

  const eventQueueRef = useRef<Array<() => void>>([]);
  const fbPixelPromise = useRef<Promise<void> | null>(null);

  const trackTrackdescClick = (): void => {
    const linkId: string =
      new URLSearchParams(search).get('linkId')?.replaceAll(' ', '+') ??
      process.env.REACT_APP_TRACKDESK_DEFAULT_LINK_ID!;

    const sourceId: string =
      new URLSearchParams(search).get('sourceId')?.replaceAll(' ', '+') ??
      process.env.REACT_APP_TRACKDESK_DEFAULT_SOURCE_ID!;

    const tenantId: string =
      new URLSearchParams(search).get('tenantId')?.replaceAll(' ', '+') ?? process.env.REACT_APP_TRACKDESK_TENANT_ID!;

    navigate(`?linkId=${linkId}&sourceId=${sourceId}&tenantId=${tenantId}`);

    (window as any).trackdesk(process.env.REACT_APP_TRACKDESK_TENANT_ID, 'click');

    navigate('/register');
  };

  const trackTrackdescRegister = (email: string): void => {
    (window as any).trackdesk(process.env.REACT_APP_TRACKDESK_TENANT_ID, 'externalCid', {
      externalCid: email,
      revenueOriginId: process.env.REACT_APP_TRACKDESK_REVENUE_ORIGIN_ID,
    });
  };

  const trackTrackdescSale = (email: string, value: string): void => {
    (window as any).trackdesk(process.env.REACT_APP_TRACKDESK_TENANT_ID, 'conversion', {
      conversionType: 'sale',
      externalCid: email,
      amount: { value },
      revenueOriginId: process.env.REACT_APP_TRACKDESK_REVENUE_ORIGIN_ID,
    });
  };

  const enqueueEvent = (event: () => void) => {
    eventQueueRef.current = [...eventQueueRef.current, event];
  };

  const eventHandlers: { [event: string]: (data: any | null) => void | null } = useMemo(
    () => ({
      [TrackingEventsEnum.StartSignup]: () => {
        (window as any).fbq('track', 'Lead');
        TagManager.dataLayer({
          dataLayer: { event: 'conversion', send_to: googleEventIdMap[TrackingEventsEnum.StartSignup] },
        });
        trackTrackdescClick();
      },
      [TrackingEventsEnum.CompleteRegistration]: (data: any) => {
        (window as any).fbq('track', 'CompleteRegistration');
        TagManager.dataLayer({
          dataLayer: { event: 'conversion', send_to: googleEventIdMap[TrackingEventsEnum.CompleteRegistration] },
        });
        trackTrackdescRegister(data.email);
      },
      [TrackingEventsEnum.InitiateCheckout]: () => {
        (window as any).fbq('track', 'InitiateCheckout');
        TagManager.dataLayer({
          dataLayer: { event: 'conversion', send_to: googleEventIdMap[TrackingEventsEnum.InitiateCheckout] },
        });
      },
      [TrackingEventsEnum.CompleteCheckout]: (data: any) => {
        (window as any).fbq('track', 'Purchase', data);
        TagManager.dataLayer({
          dataLayer: { event: 'conversion', send_to: googleEventIdMap[TrackingEventsEnum.CompleteCheckout], ...data },
        });
        trackTrackdescSale(data.email, data.value + '');
      },
      default: () => {
        //eslint-disable-next-line
        console.error('Unhandled event name');
      },
    }),
    [],
  );

  const trackEvent = (event: TrackingEventsEnum, details?: { [propName: string]: string | number }): void => {
    const trackingEvent = () => (eventHandlers[event] ?? eventHandlers.default)(details);
    if (fbPixelPromise.current && (window as any).trackdesk) {
      trackingEvent();
    } else {
      enqueueEvent(trackingEvent);
    }
  };

  useEffect(() => {
    fbPixelPromise.current = initializeFacebookPixel(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js',
    ).then(() => {
      (window as any).fbq('init', facebookPixelId);
    });

    Promise.allSettled([fbPixelPromise.current, initializeTrackdesk()]).then(() => {
      // Execute queued events after the Facebook Pixel script and Trackdesk script has loaded
      eventQueueRef.current.forEach((event) => event());
      eventQueueRef.current = [];
    });

    // Google Tag Manager
    TagManager.initialize(googleTagManagerInitializeArgs);
  }, []);

  return (
    <TrackingAnalyticsContext.Provider value={{ trackEvent } as ITrackingAnalyticsContext}>
      {children}
    </TrackingAnalyticsContext.Provider>
  );
}
