import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function LogOutIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='15' height='12' viewBox='0 0 15 12' fill='none' {...props}>
      <path d='M0 1.5C0 1.10218 0.158037 0.720644 0.439344 0.43934C0.720652 0.158035 1.10219 0 1.50002 0H8.25009C8.64792 0 9.02945 0.158035 9.31076 0.43934C9.59207 0.720644 9.7501 1.10218 9.7501 1.5V3C9.7501 3.19891 9.67109 3.38968 9.53043 3.53033C9.38978 3.67098 9.19901 3.75 9.0001 3.75C8.80118 3.75 8.61041 3.67098 8.46976 3.53033C8.32911 3.38968 8.25009 3.19891 8.25009 3V1.5H1.50002V10.5H8.25009V9C8.25009 8.80109 8.32911 8.61032 8.46976 8.46967C8.61041 8.32902 8.80118 8.25 9.0001 8.25C9.19901 8.25 9.38978 8.32902 9.53043 8.46967C9.67109 8.61032 9.7501 8.80109 9.7501 9V10.5C9.7501 10.8978 9.59207 11.2794 9.31076 11.5607C9.02945 11.842 8.64792 12 8.25009 12H1.50002C1.10219 12 0.720652 11.842 0.439344 11.5607C0.158037 11.2794 0 10.8978 0 10.5V1.5ZM11.4699 3.21975C11.6105 3.07915 11.8013 3.00016 12.0001 3.00016C12.199 3.00016 12.3897 3.07915 12.5304 3.21975L14.7804 5.46975C14.921 5.6104 15 5.80113 15 6C15 6.19887 14.921 6.3896 14.7804 6.53025L12.5304 8.78025C12.3889 8.91687 12.1995 8.99246 12.0028 8.99076C11.8062 8.98905 11.6181 8.91017 11.479 8.77111C11.34 8.63206 11.2611 8.44395 11.2594 8.2473C11.2577 8.05065 11.3333 7.8612 11.4699 7.71975L12.4396 6.75H5.25006C5.05114 6.75 4.86037 6.67098 4.71972 6.53033C4.57907 6.38968 4.50005 6.19891 4.50005 6C4.50005 5.80109 4.57907 5.61032 4.71972 5.46967C4.86037 5.32902 5.05114 5.25 5.25006 5.25H12.4396L11.4699 4.28025C11.3293 4.1396 11.2503 3.94887 11.2503 3.75C11.2503 3.55113 11.3293 3.3604 11.4699 3.21975Z' />
    </SvgIcon>
  );
}
