import { useMemo, useState } from 'react';
import { Button, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useSnackbar } from 'notistack';

import { ILoginContext } from 'context';
import { ITimer, useLogin, useTimer } from 'hooks';

import styles from './Login2Fa.module.scss';

const RESEND_TIMER_DURATION = 60;

export function Login2Fa() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();

  const { loginModelData, on2FaLogin, on2faLoginResendCode }: ILoginContext = useLogin();
  const { seconds, startTimer }: ITimer = useTimer(RESEND_TIMER_DURATION);

  const [code, setCode] = useState<string>('');

  const isCodeValid: boolean = useMemo(() => code.length === 6 && /^\d+$/.test(code), [code]);

  const handleLogin = (): void => {
    if (!isCodeValid) {
      return;
    }

    on2FaLogin(code).catch((error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
      setCode('');
    });
  };

  const handleChange = (value: string): void => {
    setCode(value);
  };

  const handleResendCode = (): void => {
    on2faLoginResendCode()
      .then(() => {
        enqueueSnackbar('Code resend', { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });

    startTimer(RESEND_TIMER_DURATION);
  };

  return (
    <div className={styles.form}>
      <Typography variant={isMobile ? 'h2' : 'h1'} component={isMobile ? 'h2' : 'h1'}>
        Login with 2FA
      </Typography>

      <Typography variant='body2'>
        Your account has been configured to use two-factor authentication. Type your 6-digit code below to verify this
        action.
      </Typography>

      <Typography variant='body2'>
        A verification code has been sent to the phone number ending in {loginModelData.phone!.slice(-4)}.
      </Typography>

      <MuiOtpInput
        autoFocus
        className={styles.input}
        value={code}
        onChange={handleChange}
        length={6}
        TextFieldsProps={{ InputProps: { componentsProps: { input: { pattern: '\\d*' } } } }}
      />

      <Stack className={styles.buttonsContainer}>
        <Button variant='outlined' disabled={!!seconds} onClick={handleResendCode}>
          Resend code
        </Button>
        <Button variant='contained' disabled={!isCodeValid} onClick={handleLogin} type='submit'>
          Continue
        </Button>
      </Stack>

      {!!seconds && <Typography variant='body1'>You will be able to resend code in {seconds} seconds</Typography>}
    </div>
  );
}
