import { EMDRState } from 'context/emdr';
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { EMDR_OBJECT_COLORS } from 'constants/emdr';
import { ShapeEnum } from 'types';

import circleSVG from 'assets/images/emdr/circle.svg';
import flowerSVG from 'assets/images/emdr/flower.svg';
import hartSVG from 'assets/images/emdr/hart.svg';
import moonSVG from 'assets/images/emdr/moon.svg';
import starSVG from 'assets/images/emdr/star.svg';

import left1 from 'assets/audio/emdr/1left.wav';
import right1 from 'assets/audio/emdr/1right.wav';
import left2 from 'assets/audio/emdr/2left.wav';
import right2 from 'assets/audio/emdr/2right.wav';
import left3 from 'assets/audio/emdr/3left.wav';
import right3 from 'assets/audio/emdr/3right.wav';
import left4 from 'assets/audio/emdr/4left.wav';
import right4 from 'assets/audio/emdr/4right.wav';
import left5 from 'assets/audio/emdr/5left.wav';
import right5 from 'assets/audio/emdr/5right.wav';
import left6 from 'assets/audio/emdr/6left.wav';
import right6 from 'assets/audio/emdr/6right.wav';
import left7 from 'assets/audio/emdr/7left.wav';
import right7 from 'assets/audio/emdr/7right.wav';
import stereo1 from 'assets/audio/emdr/1stereo.wav';
import stereo2 from 'assets/audio/emdr/2stereo.wav';
import stereo3 from 'assets/audio/emdr/3stereo.wav';
import stereo4 from 'assets/audio/emdr/4stereo.wav';
import stereo5 from 'assets/audio/emdr/5stereo.wav';
import stereo6 from 'assets/audio/emdr/6stereo.wav';
import stereo7 from 'assets/audio/emdr/7stereo.wav';

export type EMDRImagesAndAudioPreloadType = {
  imagesRef: MutableRefObject<{ [key: string]: HTMLImageElement }>;
  audiosRef: MutableRefObject<{ [key: string]: Howl }>;
  isImgPresetsLoaded: boolean;
};

export type EMDRImagesAndAudioPreloadProps = {
  state: EMDRState;
};

export function useEMDRImagesAndAudioPreload({ state }: EMDRImagesAndAudioPreloadProps): EMDRImagesAndAudioPreloadType {
  // First of all wu should load only stereo sound and change chanel depend
  // on ball position and do not use separate left-right audio tracks.
  // We should find solution for stereo EMDR sound issue for mobile.
  // Possible fix is to update microphone audio tracks to disable echo cancellation
  // for time, when EMDR started. But this fix can occure some microphone bugs

  const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  const isMobile = regex.test(navigator.userAgent);

  const imagesRef: MutableRefObject<{ [key: string]: HTMLImageElement }> = useRef<{ [key: string]: HTMLImageElement }>(
    {},
  );
  const audiosRef: MutableRefObject<{ [key: string]: Howl }> = useRef<{ [key: string]: Howl }>({});
  const isPresetsStartLodingOnceRef: MutableRefObject<boolean> = useRef<boolean>(false);

  const [isImgPresetsLoaded, setIsImgPresetsLoaded] = useState<boolean>(false);

  const shapes = [ShapeEnum.Circle, ShapeEnum.Flower, ShapeEnum.Hart, ShapeEnum.Moon, ShapeEnum.Star];

  const LRSoundFiles = {
    left: [left1, left2, left3, left4, left5, left6, left7],
    right: [right1, right2, right3, right4, right5, right6, right7],
  };

  const stereoSoundFiles = {
    left: [stereo1, stereo2, stereo3, stereo4, stereo5, stereo6, stereo7],
    right: [stereo1, stereo2, stereo3, stereo4, stereo5, stereo6, stereo7],
  };

  function getSF() {
    if (!isMobile) return LRSoundFiles;
    else return stereoSoundFiles;
  }
  const soundFiles = getSF();

  const getObjectSVGUrlByObjectSettings = useCallback((shape: ShapeEnum): string => {
    switch (shape) {
      default:
      case ShapeEnum.Circle:
        return circleSVG;
      case ShapeEnum.Star:
        return starSVG;
      case ShapeEnum.Hart:
        return hartSVG;
      case ShapeEnum.Moon:
        return moonSVG;
      case ShapeEnum.Flower:
        return flowerSVG;
    }
  }, []);

  const loadImageWithColors = useCallback(
    (shape: ShapeEnum) => {
      const url: string = getObjectSVGUrlByObjectSettings(shape);

      return fetch(url)
        .then((response) => response.text())
        .then((svgData) => {
          EMDR_OBJECT_COLORS.forEach((color: string) => {
            const svgAsString: string = svgData.replaceAll('fill="none"', `fill="#${color}"`);
            const key = `${shape.toString()}#${color}`;

            imagesRef.current[key] = new Image();
            imagesRef.current[key].src = 'data:image/svg+xml,' + encodeURIComponent(svgAsString);
          });
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Failed to load SVG:', error);
        });
    },
    [getObjectSVGUrlByObjectSettings],
  );

  const loadAudio = useCallback((url: string, key: string) => {
    const sound = new Howl({
      src: [url],
      onload: () => {
        sound.rate(1);
        audiosRef.current[key] = sound;
      },
    });
  }, []);

  useEffect(() => {
    if (!isPresetsStartLodingOnceRef.current && state !== 'off') {
      isPresetsStartLodingOnceRef.current = true;
      Promise.allSettled(shapes.map((shape: ShapeEnum) => loadImageWithColors(shape))).then(() => {
        setIsImgPresetsLoaded(true);
        Promise.allSettled(soundFiles.left.map((file, index) => loadAudio(file, 'left' + index)));
        Promise.allSettled(soundFiles.right.map((file, index) => loadAudio(file, 'right' + index)));
      });
    }
  }, [state, loadImageWithColors, loadAudio]);

  return { imagesRef, audiosRef, isImgPresetsLoaded };
}
