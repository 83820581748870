import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, TextareaAutosize, Typography } from '@mui/material';
import { CheckCircle, Sync } from '@mui/icons-material';
import moment from 'moment';
import _ from 'lodash';

import { useAppDispatch, useAppSelector } from 'store';
import { selectCall, updateCallHistory } from 'store/call';

import styles from './NotesForm.module.scss';

export function NotesForm(): JSX.Element {
  const dispatch = useAppDispatch();
  const call = useAppSelector(selectCall);

  const [notes, setNotes] = useState(call?.meetingNotes || '');
  const [startTyping, setStartTyping] = useState(false);
  const anchorRef = useRef<HTMLTextAreaElement>(null);

  const callDate = useMemo(() => {
    let date = moment();
    if (call?.startDate) {
      date = moment(call?.startDate || moment(), 'YYYY-MM-DDTHH:mm:ss.SSSSZ');
    }
    return date.format('MMMM DD, YYYY [at] hh:mm A');
  }, [call?.startDate]);

  const updateNotes = useCallback(
    _.debounce((notes: string) => {
      setNotes(notes);

      dispatch(
        updateCallHistory({
          meetingNotes: notes,
        }),
      )
        .unwrap()
        .then(() => {
          setStartTyping(false);
        });
    }, 500),
    [],
  );

  useEffect(() => {
    if (anchorRef.current) {
      anchorRef.current && anchorRef.current.focus();
    }
  }, []);

  const handleNotesChange = (event: ChangeEvent<HTMLTextAreaElement>): any => {
    setStartTyping(true);
    updateNotes(event.target.value);
  };

  return (
    <Box className={styles.container}>
      <div className={styles.header}>
        <Typography component='p' variant='body3'>
          {callDate}
        </Typography>

        {startTyping ? <Sync className={styles.syncIcon} /> : <CheckCircle />}
      </div>

      <Box className={styles.form}>
        <Box className={styles.innerForm}>
          <TextareaAutosize
            ref={anchorRef}
            placeholder={''}
            defaultValue={notes}
            onChange={handleNotesChange}
            className={styles.textField}
          />
        </Box>
      </Box>
    </Box>
  );
}
