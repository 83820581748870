import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function ChatIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='22' height='20' viewBox='0 0 22 20' fill='none' {...props}>
      <path d='M22 1.67676V15.0909C21.9994 15.5354 21.8209 15.9616 21.5037 16.2759C21.1864 16.5902 20.7563 16.7671 20.3077 16.7676L6.13959 16.8069L2.78142 19.6046C2.47866 19.8593 2.09451 19.9994 1.69729 20C1.44884 19.9996 1.20347 19.9454 0.978391 19.8412C0.684974 19.7079 0.436602 19.4935 0.263065 19.2238C0.089529 18.9542 -0.00180214 18.6406 2.69443e-05 18.3208V1.67676C0.000610449 1.23224 0.179094 0.806081 0.496336 0.491752C0.813579 0.177423 1.24368 0.000578146 1.69233 0H20.3077C20.7563 0.00057787 21.1864 0.177422 21.5037 0.491751C21.8209 0.80608 21.9994 1.23224 22 1.67676Z' />
    </SvgIcon>
  );
}
