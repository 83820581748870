/* eslint-disable */
import { useEffect, useRef, useState } from 'react';
import { ClickAwayListener, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popper } from '@mui/material';
import { CircleCrossIcon, LocalPhoneIcon, OnlineIcon, PaymentIcon, RenameIcon } from 'assets';
import { PatientListDialogType, ToggleDotMenuButton, Transitions } from 'components';
import { useAppFeatureFlags } from 'hooks';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { AcceptPaymentStatusEnum, ApiUser } from 'types';

import styles from './DotMenu.module.scss';

type DotMenuProps = {
  hasBadState?: boolean;
  onDialogOpen: (type: PatientListDialogType) => void;
};

export function DotMenu({ hasBadState, onDialogOpen }: DotMenuProps) {
  const { participantRenameFeature } = useAppFeatureFlags();
  const user: ApiUser | null = useAppSelector(selectUser);

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current && anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDialogOpen = (type: PatientListDialogType): void => {
    onDialogOpen(type);
    setOpen(false);
  };

  return (
    <>
      <ToggleDotMenuButton className={styles.menuIcon} onClick={handleToggle} ref={anchorRef} />

      <Popper
        placement='right-start'
        className={styles.dotMenu}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <ClickAwayListener onClickAway={handleClose}>
              <List disablePadding sx={{ backgroundColor: '#FFF', boxShadow: '0 2px 8px rgb(0 0 0 / 5%)' }}>
                <ListItem>
                  <ListItemButton role={undefined} onClick={() => handleDialogOpen(PatientListDialogType.STATUS)} dense>
                    <ListItemIcon>
                      <OnlineIcon sx={{ color: hasBadState ? '#FF3D3D' : '#3EB157' }} />
                    </ListItemIcon>
                    <ListItemText primary='Status' />
                  </ListItemButton>
                </ListItem>

                {participantRenameFeature && (
                  <ListItem>
                    <ListItemButton
                      role={undefined}
                      onClick={() => handleDialogOpen(PatientListDialogType.RENAME)}
                      dense
                    >
                      <ListItemIcon>
                        <RenameIcon color='primary' />
                      </ListItemIcon>
                      <ListItemText primary='Rename' />
                    </ListItemButton>
                  </ListItem>
                )}

                <ListItem>
                  <ListItemButton
                    role={undefined}
                    onClick={() => handleDialogOpen(PatientListDialogType.CONTACT)}
                    dense
                  >
                    <ListItemIcon>
                      <LocalPhoneIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText primary='Contact' />
                  </ListItemButton>
                </ListItem>

                {user?.acceptPaymentStatus === AcceptPaymentStatusEnum.Connected &&
                  user?.clinicUser.paymentsAllowed && (
                    <ListItem>
                      <ListItemButton
                        role={undefined}
                        onClick={() => handleDialogOpen(PatientListDialogType.PAYMENT)}
                        dense
                      >
                        <ListItemIcon>
                          <PaymentIcon color='primary' />
                        </ListItemIcon>
                        <ListItemText primary='Payment' />
                      </ListItemButton>
                    </ListItem>
                  )}

                <ListItem>
                  <ListItemButton role={undefined} onClick={() => handleDialogOpen(PatientListDialogType.REMOVE)} dense>
                    <ListItemIcon>
                      <CircleCrossIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText primary='Remove' />
                  </ListItemButton>
                </ListItem>
              </List>
            </ClickAwayListener>
          </Transitions>
        )}
      </Popper>
    </>
  );
}
// online 3EB157
// offline FF3D3D
