import { createContext } from 'react';

import { LoginRequest } from 'types';
import { LoginModelData, LoginPageState } from './provider';

export interface ILoginContext {
  loading: boolean;
  loginModelData: LoginModelData;
  loginPageState: LoginPageState;
  onLogin(model: LoginRequest): Promise<void>;
  on2FaLogin(code: string): Promise<void>;
  on2faLoginResendCode(): Promise<void>;
  onSelectClinic(clinicId: string): Promise<void>;
  onForgotPassword(): void;
  onSendResetPassword(email: string): Promise<void>;
  onResetPasswordEmailOpen(userId: string, token: string, phone: string): void;
  // onResetPasswordFormSendVarificationCode(twoFactor: TwoFactor): Promise<void>;
  // onResetPasswordFormSubmitCode(code: string, token: string): Promise<boolean>;
  onResetPassword(password: string, passwordConfirmed: string): Promise<void>;
  onResetPasswordCancel(): void;
}

export const LoginContext = createContext<ILoginContext | null>(null);
export const LoginConsumer = LoginContext.Consumer;
