import { forwardRef, useEffect, useMemo, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import classnames from 'classnames';
import moment from 'moment';

import { TimerIcon } from 'assets';
import { selectCall } from 'store/call';
import { useAppSelector } from 'store';

import styles from './SessionTimerButton.module.scss';

type SessionTimerButtonProps = {
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
  iconColor?: string;
  withTooltip?: boolean;
  withLabel?: boolean;
};

export const SessionTimerButton = forwardRef<HTMLButtonElement, SessionTimerButtonProps>(
  ({ disabled, className, iconClassName, iconColor, withLabel }: SessionTimerButtonProps, ref) => {
    const [open, setOpen] = useState<boolean>(true);
    const [seconds, setSeconds] = useState<number>(0);
    const call = useAppSelector(selectCall);

    const sx = useMemo(
      () => ({
        backgroundColor: withLabel ? 'transparent !important' : 'rgb(0 0 0 / 50%) !important',
      }),
      [withLabel],
    );

    const timeString: string = useMemo(() => {
      const hours: number = Math.floor(seconds / 3600);
      const minutes: number = Math.floor((seconds % 3600) / 60);
      const _seconds: number = Math.floor(seconds % 60);

      // eslint-disable-next-line
      return `${hours > 0 ? (hours < 10 ? '0' + hours : hours) + ':' : ''}${minutes < 10 ? '0' + minutes : minutes}:${_seconds < 10 ? '0' + _seconds : _seconds}`;
    }, [seconds]);

    useEffect(() => {
      if (call?.startDate) {
        const duration = moment.duration(moment.utc().diff(call?.startDate));
        const newTimeDiff = duration.asSeconds();
        setSeconds(newTimeDiff + 1);

        const interval = setInterval(() => {
          setSeconds((prevTime: number) => {
            return prevTime + 1;
          });
        }, 1000);

        return () => clearInterval(interval);
      }
    }, [call?.startDate]);

    const handleClick = (): void => {
      if (!withLabel) {
        setOpen(!open);
      }
    };

    return (
      <Box component='span' display='block' className={classnames(styles.deviceButton, className)}>
        <IconButton
          disabled={disabled}
          className={classnames(
            {
              [styles.deviceButton]: !withLabel,
              [styles.deviceButtonOpen]: open && !withLabel,
              [styles.deviceButtonWithLabel]: withLabel,
            },
            className,
          )}
          sx={sx}
          onClick={handleClick}
          edge='end'
          aria-label='notes'
          ref={ref}
        >
          {open && !withLabel && (
            <>
              <TimerIcon className={styles.icon} sx={{ color: iconColor || '#fff' }} />
              <Typography component='p' variant='body7' color='white'>
                {timeString}
              </Typography>
            </>
          )}
          {(!open || withLabel) && (
            <TimerIcon className={classnames(styles.icon, iconClassName)} sx={{ color: iconColor || '#fff' }} />
          )}
          {withLabel && (
            <Typography component='p' variant='body2' sx={{ color: 'FFF' }} ml='10px'>
              {timeString}
            </Typography>
          )}
        </IconButton>
      </Box>
    );
  },
);
