import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function ZipFileIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='64' height='73' viewBox='0 0 64 73' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.77563 0C2.58503 0 0 2.58617 0 5.77563V67.0617C0 70.2523 2.58503 72.8374 5.77563 72.8374H57.8781C61.0664 72.8374 63.6537 70.2523 63.6537 67.0617V23.1185L42.1391 0H5.77563V0Z'
        fill='#81CFDD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M63.6728 23.1959V24.3341H49.104C49.104 24.3341 41.9192 22.8999 42.1275 16.6997C42.1275 16.6997 42.3643 23.1959 48.9617 23.1959H63.6728Z'
        fill='#5B95A0'
      />
      <path
        opacity='0.5'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M42.1562 0.0296021V16.6041C42.1562 18.4891 43.4129 23.1959 49.1043 23.1959H63.6731L42.1562 0.0296021Z'
        fill='white'
      />
      <path
        d='M20.9429 61.3897H12.3136C11.8834 61.3897 11.4531 61.0004 11.4531 60.4472C11.4531 60.2423 11.5146 60.0374 11.6375 59.8735L19.2412 49.2363H12.1702C11.7604 49.2363 11.4326 48.9084 11.4326 48.4577C11.4326 48.0479 11.7604 47.7201 12.1702 47.7201H20.655C21.0852 47.7201 21.5155 48.1094 21.5155 48.6637C21.5155 48.8686 21.454 49.0735 21.3311 49.2374L13.7274 59.8746H20.9418C21.3516 59.8746 21.6794 60.2025 21.6794 60.6122C21.6805 61.0619 21.3527 61.3897 20.9429 61.3897ZM25.5097 61.5126C25.0385 61.5126 24.6697 61.1438 24.6697 60.6726V48.4167C24.6697 47.9659 25.0385 47.596 25.5507 47.596C26.0015 47.596 26.3703 47.9648 26.3703 48.4167V60.6726C26.3703 61.1427 26.0015 61.5126 25.5097 61.5126ZM35.571 55.9589H31.7794V60.6726C31.7794 61.1438 31.4106 61.5126 30.9188 61.5126C30.4476 61.5126 30.0788 61.1438 30.0788 60.6726V48.9494C30.0788 48.2733 30.632 47.7189 31.3093 47.7189H35.5721C38.3188 47.7189 39.8555 49.6051 39.8555 51.8384C39.8555 54.0717 38.2767 55.9589 35.571 55.9589ZM35.3661 49.2363H31.7794V54.4416H35.3661C36.9847 54.4416 38.0923 53.3762 38.0923 51.8384C38.0911 50.3017 36.9847 49.2363 35.3661 49.2363Z'
        fill='white'
      />
    </SvgIcon>
  );
}
