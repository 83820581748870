/* eslint-disable prettier/prettier */
import { createContext } from 'react';
import { SubscriptionStatusEnum } from 'types';

export interface ISubscriptionContext {
  subscriptionStatus: SubscriptionStatusEnum;
  isSubscriptionActive: boolean | null;
  hasSubscription: boolean;
  
  trialEndsAt: string | null;
  isLessThanThreeDaysLeft: boolean;
  trialDaysLeft: number;
  daysUntilBillingPeriodEnd: number;

  totalSeats: number;
  availableSeats: number;

  fetchSubscriptionInfo(): Promise<void>;
}

export const SubscriptionContext = createContext<ISubscriptionContext | null>(null);
export const SubscriptionConsumer = SubscriptionContext.Consumer;
