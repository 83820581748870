import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function MinusButtonIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect width='20' height='20' rx='5' fill={props.color} />
      <rect x='3.19995' y='8.80078' width='13.6' height='2.4' rx='1.2' fill='white' />
    </SvgIcon>
  );
}
