import { MutableRefObject, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import uuid from 'react-uuid';

import { IWhiteboardContext } from 'context';
import { useScreenSize, useWhiteboard } from 'hooks';
import { WhiteboardItemTypeEnum, WhiteboardLineItemData, XY } from 'types';

const MAX_COORDS_COUNT_IN_LINE = 25;

export type PencilProps = {
  ctx: MutableRefObject<CanvasRenderingContext2D | null>;
  canvasRef: RefObject<HTMLCanvasElement>;
  isPencilSelected: boolean;
  isEraserSelected: boolean;
  zoom: number;
  canvasPosition: number[];
  selectedLineSize: number;
  selectedColor: string;
};

export type PencilType = {
  drawingLineData: XY[] | null;
  drawLine(lineData: WhiteboardLineItemData): void;
};

export function usePencil({
  ctx,
  canvasRef,
  isPencilSelected,
  isEraserSelected,
  zoom,
  canvasPosition,
  selectedLineSize,
  selectedColor,
}: PencilProps): PencilType {
  const { isTouchDevice } = useScreenSize();
  const { addItem }: IWhiteboardContext = useWhiteboard();

  const [drawingLineData, setDrawingLineData] = useState<XY[] | null>(null);

  const isDrawing = useRef<boolean>(false);
  // currentLineId used for group part of lines in one line
  const currentLineId = useRef<string | null>(null);
  const lineData = useRef<XY[]>([]);

  const drawLine = useCallback(
    (lineData: WhiteboardLineItemData) => {
      if (!lineData.line.length) {
        return;
      }

      const x: number = (lineData.line[0].x - canvasPosition[0]) * zoom;
      const y: number = (lineData.line[0].y - canvasPosition[1]) * zoom;

      ctx.current!.beginPath();
      ctx.current!.moveTo(x, y);
      ctx.current!.strokeStyle = lineData.color;
      ctx.current!.lineWidth = lineData.width * zoom;

      lineData.line.slice(1, lineData.line.length).map((coords: XY) => {
        const x: number = (coords.x - canvasPosition[0]) * zoom;
        const y: number = (coords.y - canvasPosition[1]) * zoom;

        ctx.current!.lineTo(x, y);
        ctx.current!.stroke();
      });
    },
    [zoom, canvasPosition],
  );

  const startDrawingLine = useCallback(
    (clientX: number, clientY: number) => {
      const canvasRect: DOMRect = canvasRef.current!.getBoundingClientRect();
      const x: number = Math.round((clientX - canvasRect.left) / zoom + canvasPosition[0]);
      const y: number = Math.round((clientY - canvasRect.top) / zoom + canvasPosition[1]);
      isDrawing.current = true;
      currentLineId.current = uuid();

      lineData.current.push({ x, y });
      setDrawingLineData([...lineData.current]);
    },
    [zoom, canvasPosition],
  );

  const startDrawingLineMouse = useCallback(
    (event: MouseEvent) => {
      startDrawingLine(event.clientX, event.clientY);
    },
    [startDrawingLine],
  );

  const startDrawingLineTouch = useCallback(
    (event: TouchEvent) => {
      if (event.targetTouches.length > 1) {
        isDrawing.current = false;
        lineData.current = [];
        setDrawingLineData(null);

        return;
      }

      startDrawingLine(event.targetTouches.item(0)!.clientX, event.targetTouches.item(0)!.clientY);
    },
    [startDrawingLine],
  );

  const drawPartOfLine = useCallback(
    (clientX: number, clientY: number) => {
      if (isDrawing.current) {
        const canvasRect: DOMRect = canvasRef.current!.getBoundingClientRect();
        const x: number = Math.round((clientX - canvasRect.left) / zoom + canvasPosition[0]);
        const y: number = Math.round((clientY - canvasRect.top) / zoom + canvasPosition[1]);

        lineData.current.push({ x, y });
        if (lineData.current.length >= MAX_COORDS_COUNT_IN_LINE) {
          addItem({
            id: currentLineId.current!,
            type: WhiteboardItemTypeEnum.Line,
            data: {
              line: lineData.current,
              color: isPencilSelected ? selectedColor : '#fff',
              width: isPencilSelected ? selectedLineSize : 20,
            },
          });
          lineData.current = [{ x, y }];
        }

        setDrawingLineData([...lineData.current]);
      }
    },
    [zoom, canvasPosition, selectedColor, selectedLineSize, isPencilSelected, addItem],
  );

  const drawPartOfLineMouse = useCallback(
    (event: MouseEvent) => {
      drawPartOfLine(event.clientX, event.clientY);
    },
    [drawPartOfLine],
  );

  const drawPartOfLineTouch = useCallback(
    (event: TouchEvent) => {
      drawPartOfLine(event.targetTouches.item(0)!.clientX, event.targetTouches.item(0)!.clientY);
    },
    [drawPartOfLine],
  );

  const stopDrawingLine = useCallback(() => {
    isDrawing.current = false;

    addItem({
      id: currentLineId.current!,
      type: WhiteboardItemTypeEnum.Line,
      data: {
        line: lineData.current,
        color: isPencilSelected ? selectedColor : '#fff',
        width: isPencilSelected ? selectedLineSize : 20,
      },
    });
    lineData.current = [];
    setDrawingLineData(null);
  }, [selectedColor, selectedLineSize, isPencilSelected, addItem]);

  useEffect(() => {
    if (isPencilSelected || isEraserSelected) {
      if (isTouchDevice) {
        canvasRef.current!.addEventListener('touchstart', startDrawingLineTouch);
        canvasRef.current!.addEventListener('touchmove', drawPartOfLineTouch);
        canvasRef.current!.addEventListener('touchend', stopDrawingLine);
        canvasRef.current!.addEventListener('mousedown', startDrawingLineMouse);
        canvasRef.current!.addEventListener('mousemove', drawPartOfLineMouse);
        canvasRef.current!.addEventListener('mouseup', stopDrawingLine);

        return () => {
          canvasRef.current?.removeEventListener('touchstart', startDrawingLineTouch);
          canvasRef.current?.removeEventListener('touchmove', drawPartOfLineTouch);
          canvasRef.current?.removeEventListener('touchend', stopDrawingLine);
          canvasRef.current?.removeEventListener('mousedown', startDrawingLineMouse);
          canvasRef.current?.removeEventListener('mousemove', drawPartOfLineMouse);
          canvasRef.current?.removeEventListener('mouseup', stopDrawingLine);
        };
      } else {
        canvasRef.current!.addEventListener('mousedown', startDrawingLineMouse);
        canvasRef.current!.addEventListener('mousemove', drawPartOfLineMouse);
        canvasRef.current!.addEventListener('mouseup', stopDrawingLine);

        return () => {
          canvasRef.current?.removeEventListener('mousedown', startDrawingLineMouse);
          canvasRef.current?.removeEventListener('mousemove', drawPartOfLineMouse);
          canvasRef.current?.removeEventListener('mouseup', stopDrawingLine);
        };
      }
    }
  }, [
    isPencilSelected,
    isEraserSelected,
    isTouchDevice,
    startDrawingLineMouse,
    startDrawingLineTouch,
    drawPartOfLineMouse,
    drawPartOfLineTouch,
    stopDrawingLine,
  ]);

  return { drawingLineData, drawLine };
}
