import { Dialog } from '@mui/material';

import { IWhiteboardContext } from 'context';
import { useWhiteboard } from 'hooks';
import { WhiteboardContainer } from 'components';

import styles from './WhiteboardContainerFullScreen.module.scss';

export function WhiteboardContainerFullScreen() {
  const { state, isFullScreen }: IWhiteboardContext = useWhiteboard();

  return (
    <Dialog className={styles.dialog} fullScreen open={state === 'on' && isFullScreen}>
      <WhiteboardContainer />
    </Dialog>
  );
}
