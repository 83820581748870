/* eslint-disable prettier/prettier */

import { AxiosError } from 'axios';
import { Api } from 'api';
import {
  ApiCallHistory,
  ApiUser,
  ApiWaitingRoomLinkProfile,
  ClinicListResponse,
  GetStaffRequest,
  StaffResponse,
  InvitationInfoResponse,
  InviteRequest,
  LoginRequest,
  LoginResponse,
  PasswordUpdateRequest,
  RefreshTokenRequest,
  RegisterByInvitationRequest,
  RegisterRequest,
  ResetPasswordRequest,
  TokenResponse,
  TwoFactor,
  UpdateClinicRequest,
  UpdateClinicUserRequest,
  UpdateUserRequest,
  GetPlanResponse,
  LicenseUsageInfoResponse,
  SubscriptionsApiResponse,
  CreateHostedPageRequest,
  CreateHostedPageResponse,
  ReactivateCurrentSubscriptionApiResponse,
  UpdateCustomerCurrentCardRequest,
  UpdateCustomerCurrentCardResponse,
  UpdateSubscriptionWithIncreasingAmountOfLicensesRequest,
  UpdateSubscriptionWithDecreasingAmountOfLicensesRequest,
  UpdateSubscriptionWithIncreasingAmountOfLicensesResponse,
  ManageLicenseRequest,
  LoginWithVerificationCodeRequest,
  GetCouponSubscriptionApiResponse,
  ComputeSubscriptionProrationRequest,
  ComputeSubscriptionProrationResponse,
  CreateBaaRequest,
  PostCheckoutSubscriptionsApiResponse,
  InviteToCallBySmsRequest,
  InviteToCallByEmailRequest,
  AllowDisablePaymentsRequest,
  CreatePaymentRequest,
  GetAllAINotesPurchaseItemsResponse,
  MakePurchaseResponse,
  AINotesPreselectsResponse,
  GetAINotesResponse,
} from 'types';

export interface IApiRequests {
  register(registerRequest: RegisterRequest): Promise<void>;
  login(loginRequest: LoginRequest): Promise<LoginResponse>;
  loginWithVerificationCode(payload: LoginWithVerificationCodeRequest): Promise<LoginResponse>;
  refreshToken(refreshTokenRequest: RefreshTokenRequest): Promise<TokenResponse>;
  logout(): Promise<void>;
  registerSecondAccount(organization: string): Promise<TokenResponse>;
  confirmEmail(userId: string, code: string): Promise<TokenResponse>;
  resendConfirmEmail(email: string): Promise<void>;
  sendResetPassword(email: string): Promise<void>;
  resetPassword(resetPasswordRequest: ResetPasswordRequest): Promise<void>;
  updateUserPassword(data: PasswordUpdateRequest): Promise<void>;
  registerUserByInvitation(metadata: RegisterByInvitationRequest): Promise<TokenResponse>;

  updateCallHistory({ room, note }: { room: string; note: string }): Promise<void>;
  createRoom(): Promise<string>;
  getRoomHistory(room: string): Promise<ApiCallHistory>;

  updateClinic(updateClinicRequest: UpdateClinicRequest): Promise<void>;
  updateClinicPicture(file: File | null): Promise<void>;
  getClinicsList(): Promise<ClinicListResponse>;
  changeClinic(clinicId: string): Promise<TokenResponse>;

  updateClinicUser(updateClinicUserRequest: UpdateClinicUserRequest): Promise<void>;
  getProfileByLink(roomName: string): Promise<ApiWaitingRoomLinkProfile>;
  skipCreateRoom(): Promise<void>;

  ensureRoomNameIsUnique(roomName: string): Promise<void>;

  invite(inviteRequest: InviteRequest): Promise<string>;
  invitationResend(invitationId: string): Promise<void>;
  invitationDecline(invitationId: string): Promise<void>;
  invitationAccept(invitationId: string): Promise<void>;
  invitationInfo(invitationId: string): Promise<InvitationInfoResponse>;
  getStaff(getStaffRequest: GetStaffRequest): Promise<StaffResponse>;
  removeInvitation(invitationId: string): Promise<void>;
  removeStaff(clinicUserId: string): Promise<void>;
  inviteToCallBySms(inviteToCallBySmsRequest: InviteToCallBySmsRequest): Promise<void>;
  inviteToCallByEmail(inviteToCallByEmailRequest: InviteToCallByEmailRequest): Promise<void>;

  getPlan(planCode: string): Promise<GetPlanResponse>;
  postCheckoutSubscriptionUpdate(hostedPageId: string): Promise<PostCheckoutSubscriptionsApiResponse>;
  updateSubscriptionWithIncreasingAmountOfLicenses(
    payload: UpdateSubscriptionWithIncreasingAmountOfLicensesRequest,
  ): Promise<UpdateSubscriptionWithIncreasingAmountOfLicensesResponse>;
  updateSubscriptionWithDecreasingAmountOfLicenses(
    payload: UpdateSubscriptionWithDecreasingAmountOfLicensesRequest,
  ): Promise<SubscriptionsApiResponse>;
  getLicenseUsageInfo(clinicId: string): Promise<LicenseUsageInfoResponse>;
  cancelCurrentSubscription(): Promise<SubscriptionsApiResponse>;
  createSubscriptionWithHostedPages(request: CreateHostedPageRequest): Promise<CreateHostedPageResponse>;
  updateCustomerCurrentCard(payload: UpdateCustomerCurrentCardRequest): Promise<UpdateCustomerCurrentCardResponse>;
  reactivateCurrentSubscription(): Promise<ReactivateCurrentSubscriptionApiResponse>;
  getCoupon(promocode: string): Promise<GetCouponSubscriptionApiResponse>;
  manageLicense(payload: ManageLicenseRequest): Promise<SubscriptionsApiResponse>;
  computeSubscriptionProration(
    payload: ComputeSubscriptionProrationRequest,
  ): Promise<ComputeSubscriptionProrationResponse>;

  updateUser(updateUserRequest: UpdateUserRequest): Promise<void>;
  updateProfilePicture(file: File | null): Promise<void>;
  sendCodeToPhone(twoFactorMode: TwoFactor): Promise<void>;
  verifyPhoneCode(code: string): Promise<boolean>;
  checkEmailExists(email: string): Promise<boolean>;
  getUser(): Promise<ApiUser>;
  preUpdatePhone(newPhone: string, twoFactorMode: string): Promise<void>;
  updatePhone(newPhone: string, code: string): Promise<void>;
  turnOnTwoFactor(): Promise<void>;
  turnOffTwoFactor(code: string): Promise<void>;
  changeTwoFactor(twoFactor: TwoFactor): Promise<void>;
  createBaa(payload: CreateBaaRequest): Promise<any>;

  connectStripe(): Promise<string>;
  disconnectStripe(): Promise<void>;
  allowDisablePayments(payload: AllowDisablePaymentsRequest): Promise<void>;
  createPayment(payload: CreatePaymentRequest): Promise<void>;
  cancellPaymentByClient(clientConnectionId: string): Promise<void>;
  cancellPaymentByProvider(): Promise<void>;

  confirmTermsAndConditions(): Promise<void>;
  getAllAINotesItems(): Promise<GetAllAINotesPurchaseItemsResponse>;
  makePurchase(itemId: string): Promise<MakePurchaseResponse>;
  getAINotesPreselects(): Promise<AINotesPreselectsResponse>;
  startRecording(startRecordData: {
    typeOfVisit: string;
    writtenAs: string;
    generateNote: string;
    includeReductedTranscript: boolean;
  }): Promise<void>;
  stopRecording(): Promise<void>;
  getAINotes(page: number, count: number): Promise<GetAINotesResponse>;
  downloadNote(aiNote: string, isPdf: boolean): Promise<any>;
  processTooShortRecording(aiNote: string): Promise<void>;
  processStoppedNotByUserRecording(aiNote: string): Promise<void>;
}

export function useApiRequests(): IApiRequests {
  ////////////////////////////////////////////////////////////
  /// Auth
  ////////////////////////////////////////////////////////////

  async function register(registerRequest: RegisterRequest): Promise<void> {
    return await Api.request<void>({
      url: '/api/auth/register',
      method: 'post',
      payload: registerRequest,
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function login(payload: LoginRequest): Promise<LoginResponse> {
    return await Api.unauthRequest<LoginResponse>({
      url: '/api/auth/login',
      method: 'post',
      payload,
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function loginWithVerificationCode(payload: LoginWithVerificationCodeRequest): Promise<LoginResponse> {
    return await Api.unauthRequest<LoginResponse>({
      url: '/api/auth/loginWithVerificationCode',
      method: 'post',
      payload,
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function refreshToken(refreshTokenRequest: RefreshTokenRequest): Promise<TokenResponse> {
    return await Api.request<TokenResponse>({
      url: '/api/auth/refreshToken',
      method: 'post',
      payload: refreshTokenRequest,
    });
  }

  async function logout(): Promise<void> {
    return await Api.request<void>({
      url: '/api/auth/logout',
      method: 'post',
      payload: {},
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function registerSecondAccount(organization: string): Promise<TokenResponse> {
    return await Api.request<TokenResponse>({
      url: '/api/auth/registerSecondAccount',
      method: 'post',
      payload: { organization },
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function confirmEmail(userId: string, token: string): Promise<TokenResponse> {
    return await Api.request<TokenResponse>({
      url: '/api/auth/confirmEmail',
      method: 'post',
      payload: { userId, token },
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function resendConfirmEmail(email: string): Promise<void> {
    return await Api.request<void>({
      url: '/api/auth/resendConfirmEmail',
      method: 'put',
      params: { email },
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function sendResetPassword(email: string): Promise<void> {
    return await Api.request<void>({
      url: '/api/auth/sendResetPassword',
      method: 'post',
      payload: { email },
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function resetPassword(resetPasswordRequest: ResetPasswordRequest): Promise<void> {
    return await Api.request<void>({
      url: '/api/auth/resetPassword',
      method: 'post',
      payload: resetPasswordRequest,
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function updateUserPassword(data: PasswordUpdateRequest): Promise<void> {
    return await Api.request<void>({
      url: '/api/auth/changePassword',
      method: 'post',
      payload: data,
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function registerUserByInvitation(model: RegisterByInvitationRequest): Promise<TokenResponse> {
    return await Api.request<TokenResponse>({
      url: '/api/auth/registerByInvitation',
      method: 'post',
      payload: model,
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  ////////////////////////////////////////////////////////////
  /// Call
  ////////////////////////////////////////////////////////////

  async function updateCallHistory({ room, note }: { room: string; note: string }): Promise<void> {
    return await Api.request<void>({
      url: '/api/call/updateNote',
      method: 'post',
      payload: { room, note },
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function createRoom(): Promise<string> {
    return await Api.request<string>({
      url: '/api/call/createRoom',
      method: 'put',
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function getRoomHistory(room: string): Promise<ApiCallHistory> {
    return await Api.request<ApiCallHistory>({
      url: '/api/call/getCallHistory',
      method: 'get',
      params: { room },
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  ////////////////////////////////////////////////////////////
  /// Clinic
  ////////////////////////////////////////////////////////////

  async function updateClinic(updateClinicRequest: UpdateClinicRequest): Promise<void> {
    return await Api.request<void>({
      url: '/api/clinic/updateClinic',
      method: 'put',
      payload: updateClinicRequest,
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function updateClinicPicture(file: File | null): Promise<void> {
    return await Api.request<void>({
      url: '/api/clinic/updateClinicPicture',
      method: 'put',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      payload: { file },
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function getClinicsList(): Promise<ClinicListResponse> {
    return await Api.request<ClinicListResponse>({
      url: '/api/clinic/userClinics',
      method: 'get',
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function changeClinic(clinicId: string): Promise<TokenResponse> {
    return await Api.request<TokenResponse>({
      url: '/api/clinic/changeClinic',
      method: 'put',
      payload: { clinicId },
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  ////////////////////////////////////////////////////////////
  /// Clinic User
  ////////////////////////////////////////////////////////////

  async function updateClinicUser(updateClinicUserRequest: UpdateClinicUserRequest): Promise<void> {
    return await Api.request<void>({
      url: '/api/clinicUser/updateClinicUser',
      method: 'put',
      payload: updateClinicUserRequest,
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function getProfileByLink(roomName: string): Promise<ApiWaitingRoomLinkProfile> {
    return await Api.request<ApiWaitingRoomLinkProfile>({
      url: '/api/clinicUser/getByRoomName',
      method: 'get',
      params: { roomName },
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function skipCreateRoom(): Promise<void> {
    return await Api.request<void>({
      url: '/api/clinicUser/skipCreateRoom',
      method: 'put',
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function ensureRoomNameIsUnique(roomName: string): Promise<void> {
    return await Api.request<void>({
      url: '/api/clinicUser/ensureRoomNameIsUnique',
      method: 'get',
      params: { roomName },
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  ////////////////////////////////////////////////////////////
  /// Invitation
  ////////////////////////////////////////////////////////////

  async function invite(inviteRequest: InviteRequest): Promise<string> {
    return await Api.request<string>({
      url: '/api/invitation/invite',
      method: 'put',
      payload: inviteRequest,
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function invitationResend(invitationId: string): Promise<void> {
    await Api.request<string>({
      url: '/api/invitation/resendInvite',
      method: 'put',
      params: { invitationId },
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function invitationDecline(invitationId: string): Promise<void> {
    await Api.request<string>({
      url: '/api/invitation/declineInvitation',
      method: 'put',
      params: { invitationId },
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function invitationAccept(invitationId: string): Promise<void> {
    return await Api.request<void>({
      url: '/api/invitation/acceptInvitation',
      method: 'put',
      params: { invitationId },
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function invitationInfo(invitationId: string): Promise<InvitationInfoResponse> {
    return await Api.request<InvitationInfoResponse>({
      url: '/api/invitation/invitationInfo',
      method: 'get',
      params: { invitationId },
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function getStaff(getStaffRequest: GetStaffRequest): Promise<StaffResponse> {
    return await Api.request<StaffResponse>({
      url: '/api/invitation/staff',
      method: 'post',
      payload: getStaffRequest,
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function removeInvitation(invitationId: string): Promise<void> {
    return await Api.request<void>({
      url: '/api/invitation/removeInvitation',
      method: 'delete',
      params: { invitationId },
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function removeStaff(clinicUserId: string): Promise<void> {
    return await Api.request<void>({
      url: '/api/invitation/removeStaff',
      method: 'delete',
      params: { clinicUserId },
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function inviteToCallBySms(inviteToCallBySmsRequest: InviteToCallBySmsRequest): Promise<void> {
    return await Api.request<void>({
      url: '/api/invitation/inviteToCallBySms',
      method: 'put',
      payload: inviteToCallBySmsRequest,
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function inviteToCallByEmail(inviteToCallByEmailRequest: InviteToCallByEmailRequest): Promise<void> {
    return await Api.request<void>({
      url: '/api/invitation/inviteToCallByEmail',
      method: 'put',
      payload: inviteToCallByEmailRequest,
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  ////////////////////////////////////////////////////////////
  /// Subscriptions
  ////////////////////////////////////////////////////////////

  async function getPlan(planCode: string): Promise<GetPlanResponse> {
    return await Api.request<GetPlanResponse>({
      url: '/api/subscriptions/plan',
      method: 'get',
      params: { planCode },
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function getLicenseUsageInfo(clinicId: string): Promise<LicenseUsageInfoResponse> {
    return await Api.request<LicenseUsageInfoResponse>({
      url: '/api/subscriptions/licenseUsageInfo',
      method: 'get',
      params: { clinicId },
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function postCheckoutSubscriptionUpdate(hostedPageId: string): Promise<PostCheckoutSubscriptionsApiResponse> {
    return await Api.request<PostCheckoutSubscriptionsApiResponse>({
      url: '/api/subscriptions/postCheckoutSubscriptionUpdate',
      method: 'post',
      payload: { hostedPageId },
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function updateSubscriptionWithIncreasingAmountOfLicenses(
    payload: UpdateSubscriptionWithIncreasingAmountOfLicensesRequest,
  ): Promise<UpdateSubscriptionWithIncreasingAmountOfLicensesResponse> {
    return await Api.request<UpdateSubscriptionWithIncreasingAmountOfLicensesResponse>({
      url: '/api/subscriptions/updateSubscriptionWithIncreasingAmountOfLicenses',
      method: 'post',
      payload,
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function updateSubscriptionWithDecreasingAmountOfLicenses(
    payload: UpdateSubscriptionWithDecreasingAmountOfLicensesRequest,
  ): Promise<SubscriptionsApiResponse> {
    return await Api.request<SubscriptionsApiResponse>({
      url: '/api/subscriptions/updateSubscriptionWithDecreasingAmountOfLicenses',
      method: 'post',
      payload,
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function cancelCurrentSubscription(): Promise<SubscriptionsApiResponse> {
    return await Api.request<SubscriptionsApiResponse>({
      url: '/api/subscriptions/cancelCurrentSubscription',
      method: 'post',
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function createSubscriptionWithHostedPages(
    payload: CreateHostedPageRequest,
  ): Promise<CreateHostedPageResponse> {
    return await Api.request<CreateHostedPageResponse>({
      url: '/api/subscriptions/createSubscriptionWithHostedPages',
      method: 'post',
      payload,
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function updateCustomerCurrentCard(
    payload: UpdateCustomerCurrentCardRequest,
  ): Promise<UpdateCustomerCurrentCardResponse> {
    return await Api.request<UpdateCustomerCurrentCardResponse>({
      url: '/api/subscriptions/updateCustomerCurrentCard',
      method: 'post',
      payload: { ...payload, autoCollect: true },
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function reactivateCurrentSubscription(): Promise<ReactivateCurrentSubscriptionApiResponse> {
    return await Api.request<ReactivateCurrentSubscriptionApiResponse>({
      url: '/api/subscriptions/reactivateCurrentSubscription',
      method: 'post',
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function getCoupon(promocode: string): Promise<GetCouponSubscriptionApiResponse> {
    return await Api.request<GetCouponSubscriptionApiResponse>({
      url: '/api/subscriptions/coupon',
      method: 'get',
      params: { couponCode: promocode },
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function manageLicense(payload: ManageLicenseRequest): Promise<SubscriptionsApiResponse> {
    return await Api.request<SubscriptionsApiResponse>({
      url: '/api/subscriptions/manageLicense',
      method: 'post',
      payload,
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function computeSubscriptionProration(
    payload: ComputeSubscriptionProrationRequest,
  ): Promise<ComputeSubscriptionProrationResponse> {
    return await Api.request<ComputeSubscriptionProrationResponse>({
      url: '/api/subscriptions/computeSubscriptionProration',
      method: 'post',
      payload,
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  ////////////////////////////////////////////////////////////
  /// User
  ////////////////////////////////////////////////////////////

  async function updateUser(updateUserRequest: UpdateUserRequest): Promise<void> {
    return await Api.request<void>({
      url: '/api/user/updateUser',
      method: 'put',
      payload: updateUserRequest,
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function updateProfilePicture(file: File | null): Promise<void> {
    return await Api.request<void>({
      url: '/api/user/updateProfilePicture',
      method: 'put',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      payload: { file },
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function sendCodeToPhone(twoFactorMode: TwoFactor): Promise<void> {
    return await Api.request<void>({
      url: '/api/user/sendCodeToPhone',
      method: 'put',
      params: { twoFactorMode },
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function verifyPhoneCode(code: string): Promise<boolean> {
    return await Api.request<boolean>({
      url: '/api/user/verifyPhoneCode',
      method: 'put',
      params: { code },
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function checkEmailExists(email: string): Promise<boolean> {
    return await Api.request<boolean>({
      url: '/api/user/checkEmailExists',
      method: 'get',
      params: { email },
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function getUser(): Promise<ApiUser> {
    return await Api.request<ApiUser>({
      url: '/api/user/getCurrentUser',
      method: 'get',
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function preUpdatePhone(newPhone: string, twoFactorMode: string): Promise<void> {
    return await Api.request<void>({
      url: '/api/user/preUpdatePhone',
      method: 'put',
      payload: { newPhone, twoFactorMode },
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function updatePhone(newPhone: string, code: string): Promise<void> {
    return await Api.request<void>({
      url: '/api/user/updatePhone',
      method: 'put',
      payload: { newPhone, code },
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function turnOnTwoFactor(): Promise<void> {
    await Api.request<void>({
      url: '/api/user/enableTwoFactor',
      method: 'post',
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function turnOffTwoFactor(code: string): Promise<void> {
    await Api.request<string>({
      url: '/api/user/disableTwoFactor',
      method: 'post',
      params: { code },
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function changeTwoFactor(twoFactor: TwoFactor): Promise<void> {
    await Api.request<string>({
      url: '/api/user/changeTwoFactor',
      method: 'put',
      params: { twoFactor },
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function createBaa(payload: CreateBaaRequest): Promise<any> {
    return await Api.request<any>({
      url: '/api/user/createBaa',
      method: 'post',
      payload,
      responseType: 'blob',
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function connectStripe(): Promise<string> {
    return await Api.request<string>({
      url: '/api/acceptPayments/connectStripe',
      method: 'post',
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function disconnectStripe(): Promise<void> {
    return await Api.request<void>({
      url: '/api/acceptPayments/disconnectStripe',
      method: 'post',
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function allowDisablePayments(payload: AllowDisablePaymentsRequest): Promise<void> {
    return await Api.request<void>({
      url: '/api/acceptPayments/allowDisablePayments',
      method: 'post',
      payload,
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function createPayment(payload: CreatePaymentRequest): Promise<void> {
    return await Api.request<void>({
      url: '/api/acceptPayments/createPayment',
      method: 'post',
      payload,
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function cancellPaymentByClient(clientConnectionId: string): Promise<void> {
    return await Api.request<void>({
      url: '/api/acceptPayments/cancellPaymentByClient',
      method: 'post',
      payload: { clientConnectionId },
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function cancellPaymentByProvider(): Promise<void> {
    return await Api.request<void>({
      url: '/api/acceptPayments/cancellPaymentByProvider',
      method: 'post',
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  ////////////////////////////////////////////////////////////
  /// AI Notes
  ////////////////////////////////////////////////////////////

  async function confirmTermsAndConditions(): Promise<void> {
    return await Api.request<void>({
      url: '/api/aiNotes/confirmTermsAndConditions',
      method: 'patch',
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function getAllAINotesItems(): Promise<GetAllAINotesPurchaseItemsResponse> {
    return await Api.request<GetAllAINotesPurchaseItemsResponse>({
      url: '/api/aiNotes/getAllItems',
      method: 'get',
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function makePurchase(itemId: string): Promise<MakePurchaseResponse> {
    return await Api.request<MakePurchaseResponse>({
      url: '/api/aiNotes/makePurchase',
      method: 'post',
      payload: { itemId },
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function getAINotesPreselects(): Promise<AINotesPreselectsResponse> {
    return await Api.request<AINotesPreselectsResponse>({
      url: '/api/aiNotes/getAINotesPreselects',
      method: 'get',
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function startRecording(startRecordData: {
    typeOfVisit: string;
    writtenAs: string;
    generateNote: string;
    includeReductedTranscript: boolean;
  }): Promise<void> {
    return await Api.request<void>({
      url: '/api/aiNotes/StartRecording',
      method: 'post',
      payload: startRecordData,
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function stopRecording(): Promise<void> {
    return await Api.request<void>({
      url: '/api/aiNotes/StopRecording',
      method: 'post',
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function getAINotes(page: number, count: number): Promise<GetAINotesResponse> {
    return await Api.request<GetAINotesResponse>({
      url: `/api/aiNotes/AINotes?page=${page}&count=${count}`,
      method: 'get',
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function downloadNote(aiNote: string, isPdf: boolean): Promise<any> {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return await Api.request<any>({
      url: '/api/aiNotes/DownloadAINote',
      method: 'get',
      params: { aiNote, timezone, isPdf: isPdf + '' },
      responseType: 'blob',
    }).catch((e: any) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function processTooShortRecording(aiNote: string): Promise<void> {
    return await Api.request<void>({
      url: `/api/aiNotes/processTooShortRecording`,
      method: 'post',
      params: { aiNote },
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  async function processStoppedNotByUserRecording(aiNote: string): Promise<void> {
    return await Api.request<void>({
      url: `/api/aiNotes/processStoppedNotByUserRecording`,
      method: 'post',
      params: { aiNote },
    }).catch((e: AxiosError) => {
      throw new Error(e.response!.data as string);
    });
  }

  return {
    register,
    login,
    loginWithVerificationCode,
    refreshToken,
    logout,
    registerSecondAccount,
    confirmEmail,
    resendConfirmEmail,
    sendResetPassword,
    resetPassword,
    updateUserPassword,
    registerUserByInvitation,

    updateCallHistory,
    createRoom,
    getRoomHistory,

    updateClinic,
    updateClinicPicture,
    getClinicsList,
    changeClinic,

    updateClinicUser,
    getProfileByLink,
    skipCreateRoom,

    ensureRoomNameIsUnique,

    invite,
    invitationResend,
    invitationDecline,
    invitationAccept,
    invitationInfo,
    getStaff,
    removeStaff,
    removeInvitation,
    inviteToCallBySms,
    inviteToCallByEmail,

    getPlan,
    updateSubscriptionWithIncreasingAmountOfLicenses,
    updateSubscriptionWithDecreasingAmountOfLicenses,
    postCheckoutSubscriptionUpdate,
    getLicenseUsageInfo,
    cancelCurrentSubscription,
    createSubscriptionWithHostedPages,
    updateCustomerCurrentCard,
    reactivateCurrentSubscription,
    getCoupon,
    manageLicense,
    computeSubscriptionProration,

    updateUser,
    updateProfilePicture,
    sendCodeToPhone,
    verifyPhoneCode,
    checkEmailExists,
    getUser,
    preUpdatePhone,
    updatePhone,
    turnOnTwoFactor,
    turnOffTwoFactor,
    changeTwoFactor,
    createBaa,

    connectStripe,
    disconnectStripe,
    allowDisablePayments,
    createPayment,
    cancellPaymentByClient,
    cancellPaymentByProvider,

    confirmTermsAndConditions,
    getAllAINotesItems,
    makePurchase,
    getAINotesPreselects,
    startRecording,
    stopRecording,
    getAINotes,
    downloadNote,
    processTooShortRecording,
    processStoppedNotByUserRecording,
  };
}
