import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function HomeIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M7.73364 0.792699C7.28781 0.371184 6.5904 0.371184 6.14457 0.792699L0.542962 6.08879C0.196377 6.41647 0 6.8724 0 7.34936V13.7903C0 14.7483 0.776683 15.5251 1.73478 15.5251H3.46955C4.42765 15.5251 5.20433 14.7483 5.20433 13.7903V10.899C5.20433 10.5797 5.46323 10.3208 5.78259 10.3208H8.09562C8.41498 10.3208 8.67388 10.5797 8.67388 10.899V13.7903C8.67388 14.7483 9.4506 15.5251 10.4087 15.5251H12.1434C13.1015 15.5251 13.8782 14.7483 13.8782 13.7903V7.34936C13.8782 6.8724 13.6818 6.41647 13.3352 6.08879L7.73364 0.792699Z'
        fill='#626363'
      />
    </SvgIcon>
  );
}
