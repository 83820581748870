import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function MovementTopBottomIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='10' height='20' viewBox='0 0 10 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M8.2627 14.7633H6.46353V5.23669H8.2627C9.09794 5.23669 9.51622 4.22689 8.92563 3.63627L5.56396 0.274599C5.19783 -0.0915329 4.60428 -0.0915329 4.23815 0.274599L0.876476 3.63627C0.285892 4.22685 0.704172 5.23669 1.5394 5.23669H3.33854V14.7633H1.53936C0.704133 14.7633 0.285853 15.7731 0.876437 16.3637L4.23811 19.7254C4.60424 20.0915 5.19779 20.0915 5.56392 19.7254L8.92559 16.3637C9.51622 15.7731 9.09794 14.7633 8.2627 14.7633Z' />
    </SvgIcon>
  );
}
