import { useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';

import { DEFAULT_WELCOME_MESSAGE, DEFAULT_UNAVAILABLE_MESSAGE } from 'constants/data';
import { CheckboxCheckedIcon, CheckboxIcon } from 'assets';
import { FormInput, FormMediaUpload, FormSelector, Info } from 'components';
import { ApiUser, UpdateClinicUserRequest, UpdateUserRequest } from 'types';
import { getSuffixesOptionsForUser, getTitlesOptionsForUser } from 'utils';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';

import styles from './CreateRoomForm.module.scss';

type CreateWaitingRoomFormType = {
  user: UpdateUserRequest;
  clinicUser: UpdateClinicUserRequest;
  organization: string;
};

type CreateRoomFormProps = {
  onSubmit(
    profilePicture: File | null,
    profilePictureChanged: boolean,
    user: UpdateUserRequest,
    clinicUser: UpdateClinicUserRequest,
    organization: string,
  ): Promise<void>;
};

export function CreateRoomForm({ onSubmit }: CreateRoomFormProps) {
  const user: ApiUser = useAppSelector(selectUser)!;

  const schema = Yup.object().shape({
    clinicUser: Yup.object().shape({
      roomName: Yup.string()
        .required('Please set unique URL for your waiting room')
        .matches(/^(([a-z]|\d|_)+([a-z]|\d|-|_)*)$/i, 'This URL contains characters not allowed. Please try again.')
        .max(128, 'Max length is 128 character'),
      welcomeMessage: Yup.string().max(500, 'Max length is 500 character').nullable(),
      unavailableMessage: Yup.string().max(100, 'Max length is 100 character').nullable(),
      preferedDisplayName: Yup.string()
        .max(50, 'Max length is 50 character')
        .matches(/^[a-zA-Z0-9'\-,.\s]{0,50}$/g, 'Preferred Display Name has invalid characters')
        .nullable()
        .label('Preferred Display Name'),
      biography: Yup.string().max(2000, 'Max length is 2000 character').nullable(),
      displayEmail: Yup.boolean(),
      suffix: Yup.string().nullable(),
    }),
    user: Yup.object().shape({
      title: Yup.string().nullable(),
      speciality: Yup.string().max(25, 'Max length is 25 character').nullable(),
    }),
    organization: Yup.string().max(100, 'Max length is 100 character').required(),
  });

  const form = useForm<CreateWaitingRoomFormType>({
    resolver: yupResolver(schema),
    defaultValues: {
      user: {
        title: user.title,
        speciality: user.speciality,
      },
    },
    mode: 'all',
  });

  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [profilePictureChanged, setProfilePictureChanged] = useState<boolean>(false);

  const handleSubmit = async (): Promise<void> => {
    if (!form.formState.isValid) {
      return;
    }

    const formValues: CreateWaitingRoomFormType = form.getValues();

    await onSubmit(
      profilePicture,
      profilePictureChanged,
      formValues.user,
      formValues.clinicUser,
      formValues.organization,
    );
  };

  const handlePictureChanged = (file: File): void => {
    setProfilePicture(file);
    setProfilePictureChanged(true);
  };

  const handlePictureDeleted = (): void => {
    setProfilePicture(null);
    setProfilePictureChanged(true);
  };

  return (
    <div className={styles.form}>
      <Stack className={styles.row}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography component='p' variant='body2'>
            Provider/Organization Name <span className={styles.asterisk}>*</span>
          </Typography>
          <Info
            children={
              <Typography variant='body6'>
                Enter your organization
                <br />
                name. If you are a sole <br />
                practitioner, just enter <br />
                your business name.
              </Typography>
            }
          />
        </Stack>
        <FormInput name='organization' control={form.control} />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography component='p' variant='body2'>
            Room Name URL <span className={styles.asterisk}>*</span>
          </Typography>
          <Info text='Create name for your personalized room link. The link must not contain .~!@#$%^&*()/ ' />
        </Stack>
        <Stack direction='row' alignItems='center' className={styles.roomNameContainer}>
          <div className={styles.roomNameLink}>
            <Typography variant='body1' color='#979797'>
              {window.location.origin}/
            </Typography>
          </div>
          <FormInput
            placeholder='AaronSmithTherapy'
            name='clinicUser.roomName'
            control={form.control}
            className={styles.roomName}
            errorHelperClassName={styles.roomNameError}
          />
        </Stack>
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' spacing={1} alignItems='flex-end'>
          <Typography component='p' variant='body2'>
            Welcome Message
          </Typography>
          <Info text='Customize a welcome message for your patient!' />
        </Stack>
        <FormInput
          name='clinicUser.welcomeMessage'
          control={form.control}
          multiline
          maxRows={4}
          placeholder={DEFAULT_WELCOME_MESSAGE}
        />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' spacing={1} alignItems='flex-end'>
          <Typography component='p' variant='body2'>
            Unavailable Message
          </Typography>
          <Info text='Customize your out of office message for your patient!' />
        </Stack>
        <FormInput
          name='clinicUser.unavailableMessage'
          control={form.control}
          multiline
          maxRows={4}
          placeholder={DEFAULT_UNAVAILABLE_MESSAGE}
        />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' alignItems='flex-end'>
          <Typography component='p' variant='body2'>
            Profile Picture
          </Typography>
        </Stack>
        <FormMediaUpload
          onInputChange={handlePictureChanged}
          onRemove={handlePictureDeleted}
          title='Profile Picture'
          src={user.linkToProfilePicture}
        />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' alignItems='center'>
          <Typography component='p' variant='body2'>
            Title
          </Typography>
        </Stack>
        <FormSelector variant='outlined' name='user.title' control={form.control} options={getTitlesOptionsForUser()} />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' alignItems='center'>
          <Typography component='p' variant='body2'>
            Suffix
          </Typography>
        </Stack>
        <FormSelector
          variant='outlined'
          name='clinicUser.suffix'
          control={form.control}
          options={getSuffixesOptionsForUser()}
        />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' spacing={1} alignItems='flex-end'>
          <Typography component='p' variant='body2'>
            Preferred Display Name
          </Typography>
          <Info text='Enter how your name should appear at check-in and in your waiting room. If you leave this field empty, we will use your title, last name and suffix.' />
        </Stack>
        <FormInput
          name='clinicUser.preferedDisplayName'
          control={form.control}
          errorHelperClassName={styles.errorHelper}
        />
      </Stack>

      <Stack className={styles.row}>
        <FormControlLabel
          control={
            <Checkbox
              className={styles.checkbox}
              {...form.register('clinicUser.displayEmail')}
              icon={<CheckboxIcon className={styles.checkboxIcon} />}
              checkedIcon={<CheckboxCheckedIcon className={styles.checkboxIcon} />}
            />
          }
          label='Display email'
        />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography component='p' variant='body2'>
            Personal Biography
          </Typography>
        </Stack>
        <FormInput name='clinicUser.biography' className={styles.bio} control={form.control} multiline maxRows={20} />
      </Stack>

      <Stack className={styles.row} alignItems='flex-end'>
        <Button
          id='CreateRoomFormNext'
          variant='contained'
          onClick={handleSubmit}
          disabled={!form.formState.isValid}
          className={styles.nextButton}
          type='submit'
        >
          Next
        </Button>
      </Stack>
    </div>
  );
}
