import { DeviceSelectionContainer } from 'components';
import { MenuItem } from '../MenuItem';

type SettingsMenuProps = {
  className?: string;
  withLayoutSelection?: boolean;
  withBackground?: boolean;
};

export function SettingsMenu({ className, withBackground, withLayoutSelection }: SettingsMenuProps) {
  return (
    <>
      <MenuItem title='Settings' className={className}>
        <DeviceSelectionContainer
          withMasterVolume
          withBackground={withBackground}
          withLayoutSelection={withLayoutSelection}
          withAudioDiagnostic
        />
      </MenuItem>
    </>
  );
}
