import React from 'react';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    textVariant_16_500: React.CSSProperties;
    textVariant_24_500: React.CSSProperties;
    textVariant_32_500: React.CSSProperties;
    textVariant_12_400: React.CSSProperties;
    textVariant_16_400: React.CSSProperties;
    subtitle2: React.CSSProperties;
    body: React.CSSProperties;
    body1: React.CSSProperties;
    body2: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    body5: React.CSSProperties;
    body6: React.CSSProperties;
    body7: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    textVariant_16_500?: React.CSSProperties;
    textVariant_24_500?: React.CSSProperties;
    textVariant_32_500?: React.CSSProperties;
    textVariant_12_400?: React.CSSProperties;
    textVariant_16_400?: React.CSSProperties;
    subtitle2?: React.CSSProperties;
    body?: React.CSSProperties;
    body1?: React.CSSProperties;
    body2?: React.CSSProperties;
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    body5?: React.CSSProperties;
    body6?: React.CSSProperties;
    body7?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    textVariant_16_500: true;
    textVariant_24_500: true;
    textVariant_32_500: true;
    textVariant_12_400: true;
    textVariant_16_400: true;
    subtitle2: true;
    body: true;
    body1: true;
    body2: true;
    body3: true;
    body4: true;
    body5: true;
    body6: true;
    body7: true;
  }
}

export const typography = {
  fontFamily: 'Open Sans',
  h1: {
    fontSize: '3.2rem',
    fontWeight: 600,
    lineHeight: '3.901rem',
  },
  h2: {
    fontSize: '2.4rem',
    fontWeight: 600,
    lineHeight: '3.3rem',
  },
  h3: {
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: '2.7rem',
  },
  h4: {
    fontSize: '2rem',
    fontWeight: 600,
    lineHeight: '2.7rem',
  },
  caption: {
    fontSize: '1.8rem',
    fontWeight: 400,
    lineHeight: '2.2rem',
  },
  subtitle1: {
    fontSize: '1.2rem',
    fontWeight: 400,
  },
  subtitle2: {
    fontSize: '1.2rem',
    fontWeight: 600,
  },
  body: {
    fontSize: '1.6rem',
    fontWeight: 600,
    lineHeight: '2rem',
  },
  body1: {
    fontSize: '1.2rem',
    fontWeight: 400,
    lineHeight: '1.6rem',
  },
  body2: {
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: '2rem',
  },
  body3: {
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: '1.9rem',
  },
  body4: {
    fontSize: '1.5rem',
    fontWeight: 700,
    lineHeight: '2rem',
  },
  body5: {
    fontSize: '1.4rem',
    fontWeight: 400,
    lineHeight: '1.9rem',
  },
  body6: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.4rem',
  },
  body7: {
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '2rem',
  },
};
