import React, { useCallback } from 'react';
import { Box, Stack, Switch, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { Info } from 'components';
import { IApiRequests, useApiRequests, useSignalrConnection } from 'hooks';
import { ApiClinicUser, ApiUser } from 'types';
import { useAppDispatch, useAppSelector } from 'store';
import { getUser, selectUser } from 'store/user';

import styles from './NotificationSettings.module.scss';

export function NotificationSettings() {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();
  const user: ApiUser = useAppSelector(selectUser)!;
  const { updateClinicUser }: IApiRequests = useApiRequests();
  const { updateUnavailableProviderStatus } = useSignalrConnection();

  const onChange = useCallback(
    (data: Partial<ApiClinicUser>) => {
      updateClinicUser({ ...data })
        .then(() => {
          if (Reflect.has(data, 'unavailable')) {
            updateUnavailableProviderStatus(data.unavailable ?? false);
          }

          return dispatch(getUser()).unwrap();
        })
        .catch((e) => enqueueSnackbar(`Update clinic user error: ${e}`, { variant: 'error' }));
    },
    [user],
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeRevers = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Box className={styles.container}>
      <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography component='p' variant='body5' alignItems='center'>
            Receive waiting room messages
          </Typography>
          <Info text='Receive messages from your queue during meetings.' />
        </Stack>
        <Switch
          checked={user.clinicUser.receiveMessages}
          name='receiveMessages'
          inputProps={{ 'aria-label': 'ReceiveMessages' }}
          onChange={handleChange}
        />
      </Stack>

      <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography component='p' variant='body5'>
            Do not disturb while in meetings
          </Typography>
          <Info text='Turn off notifications only during meetings.' />
        </Stack>
        <Switch
          checked={!user.clinicUser.receiveMessagesInMeeting}
          name='receiveMessagesInMeeting'
          inputProps={{ 'aria-label': 'ReceiveMessagesInMeeting' }}
          onChange={handleChangeRevers}
        />
      </Stack>

      <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography component='p' variant='body5'>
            Prompt AI Notes at start of every call
          </Typography>
          <Info text='Start every call asking if you want AI to transcribe and generate a progress note.' />
        </Stack>
        <Switch
          checked={user.clinicUser.autoStartPromptAINotes}
          name='autoStartPromptAINotes'
          inputProps={{ 'aria-label': 'AutoStartPromptAINotes' }}
          onChange={handleChangeRevers}
        />
      </Stack>

      <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography component='p' variant='body5'>
            Notifications
          </Typography>
          <Info text='Enable/Disable In-App Notifications' />
        </Stack>
        <Switch
          checked={user.clinicUser.notifications}
          name='notifications'
          inputProps={{ 'aria-label': 'Notifications' }}
          onChange={handleChange}
        />
      </Stack>

      <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography component='p' variant='body5'>
            Unavailable
          </Typography>
          <Info text='Changes your online status to “Unavailable” and displays your Unavailable Message in your Waiting Room.' />
        </Stack>
        <Switch
          checked={user.clinicUser.unavailable}
          name='unavailable'
          inputProps={{ 'aria-label': 'Unavailable' }}
          onChange={handleChange}
        />
      </Stack>
    </Box>
  );
}

export default React.memo(NotificationSettings);
