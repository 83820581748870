import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function ScreenShareIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='24' height='18' viewBox='0 0 24 18' fill='none' {...props}>
      <path d='M3.34558 0.214478C1.75594 0.214478 0.467285 1.50243 0.467285 3.0912V14.5981C0.467285 16.1869 1.75594 17.4748 3.34558 17.4748H20.6153C22.205 17.4748 23.4936 16.1869 23.4936 14.5981V3.0912C23.4936 1.50243 22.205 0.214478 20.6153 0.214478H3.34558ZM11.9805 14.5981C11.5831 14.5981 11.2609 14.276 11.2609 13.8789V5.54663L8.89141 7.91482C8.6104 8.19568 8.15479 8.19568 7.87379 7.91482C7.59276 7.63396 7.59276 7.17861 7.87379 6.89775L11.4717 3.30185C11.7527 3.02098 12.2083 3.02098 12.4893 3.30185L16.0872 6.89775C16.3681 7.17861 16.3681 7.63396 16.0872 7.91482C15.8062 8.19568 15.3505 8.19568 15.0695 7.91482L12.7 5.54664V13.8789C12.7 14.276 12.3778 14.5981 11.9805 14.5981Z' />
    </SvgIcon>
  );
}
