import React from 'react';
import { Button, DialogContent, Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';

import styles from './CouponExpiredDialog.module.scss';
import { SadFaceIcon } from 'assets';

export interface CouponExpiredDialogProps extends DialogProps {
  text: string;
  onConfirm: () => void;
}

export function CouponExpiredDialog({ text, onConfirm, ...rest }: CouponExpiredDialogProps) {
  return (
    <Dialog {...rest} className={styles.dialog}>
      <DialogContent>
        <SadFaceIcon className={styles.icon} color='primary' />

        <Typography component='p' variant='body5'>
          {text}
        </Typography>

        <Button variant='contained' onClick={onConfirm} className={styles.formButton}>
          Ok
        </Button>
      </DialogContent>
    </Dialog>
  );
}
