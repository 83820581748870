import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

type NQIconProps = SvgIconProps & {
  nq: number;
};

export function NQIcon(props: NQIconProps) {
  const color: string = props.nq > 2 ? '#54E1FB' : '#FF3D3D';

  return (
    <SvgIcon width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M2.73922 12.1482H1.31963C1.05819 12.1482 0.846436 12.3599 0.846436 12.6214V15.4605C0.846436 15.722 1.05819 15.9337 1.31963 15.9337H2.73922C3.00066 15.9337 3.21241 15.722 3.21241 15.4605V12.6214C3.21241 12.3599 3.00066 12.1482 2.73922 12.1482Z'
        fill={props.nq >= 1 ? color : '#abadae'}
      />
      <path
        d='M6.52478 9.30901H5.10519C4.84375 9.30901 4.632 9.52077 4.632 9.78221V15.4605C4.632 15.722 4.84375 15.9337 5.10519 15.9337H6.52478C6.78622 15.9337 6.99797 15.722 6.99797 15.4605V9.78221C6.99797 9.52077 6.78622 9.30901 6.52478 9.30901Z'
        fill={props.nq >= 2 ? color : '#abadae'}
      />
      <path
        d='M10.3103 6.46984H8.89075C8.62931 6.46984 8.41756 6.6816 8.41756 6.94304V15.4605C8.41756 15.722 8.62931 15.9337 8.89075 15.9337H10.3103C10.5718 15.9337 10.7835 15.722 10.7835 15.4605V6.94304C10.7835 6.6816 10.5718 6.46984 10.3103 6.46984Z'
        fill={props.nq >= 3 ? color : '#abadae'}
      />
      <path
        d='M14.0959 3.63067H12.6763C12.4149 3.63067 12.2031 3.84243 12.2031 4.10387V15.4605C12.2031 15.722 12.4149 15.9337 12.6763 15.9337H14.0959C14.3573 15.9337 14.5691 15.722 14.5691 15.4605V4.10387C14.5691 3.84243 14.3573 3.63067 14.0959 3.63067Z'
        fill={props.nq >= 4 ? color : '#abadae'}
      />
      <path
        d='M17.8815 0.791504H16.4619C16.2004 0.791504 15.9887 1.00326 15.9887 1.2647V15.4605C15.9887 15.722 16.2004 15.9337 16.4619 15.9337H17.8815C18.1429 15.9337 18.3547 15.722 18.3547 15.4605V1.2647C18.3547 1.00326 18.1429 0.791504 17.8815 0.791504Z'
        fill={props.nq >= 5 ? color : '#abadae'}
      />
    </SvgIcon>
  );
}
