import {
  useDominantSpeaker,
  useParticipants,
  useScreenShareParticipant,
  useSelectedParticipant,
  useVideo,
} from 'hooks';
import { parseParticipantIdentity } from 'utils';

export function useMainParticipant() {
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const dominantSpeaker = useDominantSpeaker();
  const participants = useParticipants();
  const { room } = useVideo();
  const [hostId] = (room?.name || '').split('__');
  const localParticipant = room?.localParticipant;
  const hostParticipant = participants.find((participant) => {
    const [id] = parseParticipantIdentity(participant);
    return id === hostId;
  });
  const remoteScreenShareParticipant = screenShareParticipant;

  // The participant that is returned is displayed in the main video area. Changing the order of the following
  // variables will change the how the main speaker is determined.
  return (
    selectedParticipant ||
    remoteScreenShareParticipant ||
    dominantSpeaker ||
    hostParticipant ||
    participants[0] ||
    localParticipant
  );
}
