/* eslint-disable prettier/prettier */

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Theme, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import classnames from 'classnames';

import { useDialog, useSubscription } from 'hooks';
import { getUser, selectUser } from 'store/user';
import { ApiUser, SubscriptionStatusEnum } from 'types';
import { SubscriptionDetailsPopup } from '../SubscriptionDetailsPopup';
import { useAppDispatch } from 'store';
import { ConfirmResultEnum } from 'context';

import styles from './FreeTrial.module.scss';

export const FreeTrial = () => {
  const dialog = useDialog();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const subscription = useSubscription();
  const user: ApiUser | null = useSelector(selectUser);

  const isExpired = useMemo<boolean>(() => {
    return subscription?.subscriptionStatus === SubscriptionStatusEnum.Expired;
  }, [user]);

  const handleUpgradePlan = async () => {
    if (isMobile) {
      navigate('/subscriptionDetails');
      return;
    }

    const confirmationResult = await dialog.confirm({ content: SubscriptionDetailsPopup });
    if (confirmationResult === ConfirmResultEnum.Confirm) {
      dispatch(getUser()).unwrap();
    }
  };

  return (
    <Box className={styles.freeTrialContainer}>
      <Box className={styles.freeTrialInnerContainer}>
        <Box className={styles.availableContainer}>
          <Typography variant='body2'>
            <span className={styles.availableNumber}>{subscription?.availableSeats}</span> Available Seats
          </Typography>
          <Typography variant='body2'>
            <span className={styles.availableNumber}>{subscription?.totalSeats}</span> Total Seats
          </Typography>
        </Box>
        <Box className={styles.borderContainer}>
          <Typography variant='body1'>
            In order to use HIPAA LINK after your trial, you must purchase a seat. Your current seat will expire at the
            end of your trial. If you have a practice that includes multiple providers, you can purchase and assign a
            seat to each practice Provider.
          </Typography>
          {!isExpired && (
            <Typography className={styles.warning}>
              You have <span>{subscription?.trialDaysLeft} days remaining</span> in your free trial.
            </Typography>
          )}
        </Box>
        <Box>
          <Typography className={styles.subscriptionFor} variant='body2'>
            Subscription for {user?.clinicUser.organization}
          </Typography>
        </Box>
        <Box className={styles.borderContainer}>
          <Typography className={styles.daysLeft} variant='body1' style={{ display: isExpired ? 'none' : 'block' }}>
            {subscription?.trialDaysLeft} days left
          </Typography>
          <Box className={classnames(styles.titleAndExpired, { [styles.indent]: isExpired })}>
            <Typography className={styles.hipaaLinkTitle} variant='body2'>
              HIPAA LINK Free Trial
            </Typography>
            {isExpired && (
              <Typography className={styles.expired} variant='body1'>
                Expired
              </Typography>
            )}
          </Box>
          <Typography className={styles.expireDate} variant='body1' style={{ display: isExpired ? 'none' : 'block' }}>
            Trial expires on {moment(subscription?.trialEndsAt).format('MMM DD, YYYY')}
          </Typography>
          <Button
            variant='contained'
            className={classnames(styles.button, {
              [styles.buttonWarning]: isExpired || subscription?.isLessThanThreeDaysLeft,
            })}
            onClick={handleUpgradePlan}
          >
            Upgrade now to keep plan
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
