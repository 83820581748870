import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function HospitalIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='22' height='24' viewBox='0 0 22 24' fill='none' {...props}>
      <path
        d='M2 9.01131V21C2 21.5523 2.44772 22 3 22H7.5C8.05228 22 8.5 21.5523 8.5 21V16.5H13.5V21C13.5 21.5523 13.9477 22 14.5 22H19C19.5523 22 20 21.5523 20 21V9.01131C20 8.69021 19.8458 8.38864 19.5855 8.20063L11 2L2.41451 8.20063C2.1542 8.38864 2 8.69021 2 9.01131Z'
        stroke='#81CFDD'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M8.5 10H13.5M11 7.5V12.5V7.5Z'
        stroke='#81CFDD'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  );
}
