import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, OutlinedInput, Theme, Typography, useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import classnames from 'classnames';

import {
  PLAN_CODE_MONTHLY,
  PLAN_CODE_TRIAL,
  SUCCESS_OPERATION_ACTION_TYPE_SUBSCRIPTION_CHANGE,
} from 'constants/payment';
import { ISubscriptionContext } from 'context';
import { BackButton, DiscountInput, Info, SeatsCountSelector, Spinner } from 'components';
import {
  ApiUser,
  ComputeSubscriptionProrationMode,
  ComputeSubscriptionProrationResponse,
  Coupon,
  SubscriptionProrationApi,
  UpdateSubscriptionWithIncreasingAmountOfLicensesResponse,
} from 'types';
import { IApiRequests, useApiRequests, useSubscription } from 'hooks';
import { selectUser } from 'store/user';
import { useAppSelector } from 'store';
import { getIsAdminByRoles } from 'utils';

import styles from './ManageSeatsPage.module.scss';

type ManageSeatsState = 'minLimitReached' | 'seatsDecrease' | 'notChanged' | 'seatsIncrease';

export function ManageSeatsPage() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    updateSubscriptionWithDecreasingAmountOfLicenses,
    updateSubscriptionWithIncreasingAmountOfLicenses,
    computeSubscriptionProration,
  }: IApiRequests = useApiRequests();
  const subscription: ISubscriptionContext | null = useSubscription();

  const user: ApiUser = useAppSelector(selectUser)!;

  const [loading, setLoading] = useState<boolean>(false);
  const [seatsCount, setSeatsCount] = useState<number>(subscription?.totalSeats ?? 0);
  const [coupone, setCoupone] = useState<Coupon | null>(null);
  const [subscriptionProration, setSubscriptionProration] = useState<SubscriptionProrationApi | null>(null);

  const manageSeatsState: ManageSeatsState = useMemo(() => {
    if (!subscription) {
      return 'notChanged';
    }
    if (seatsCount < subscription.totalSeats - subscription.availableSeats) {
      return 'minLimitReached';
    }

    if (seatsCount < subscription.totalSeats) {
      return 'seatsDecrease';
    }

    if (seatsCount > subscription.totalSeats) {
      return 'seatsIncrease';
    }

    return 'notChanged';
  }, [subscription, seatsCount]);

  const pricePerOneSeatPerMonth: string = useMemo(() => {
    if (user.clinicUser.subscriptionInfo?.planCode === PLAN_CODE_MONTHLY) {
      return user.clinicUser.subscriptionInfo!.price?.toFixed(2) ?? '';
    }

    return ((user.clinicUser.subscriptionInfo?.price ?? 0) / 12).toFixed(2);
  }, [user]);

  const cardExparationString: string = useMemo(() => {
    return moment()
      .year(user.clinicUser.subscriptionInfo?.cardExpirationYear ?? 2022)
      .month((user.clinicUser.subscriptionInfo?.cardExpirationMonth ?? 1) - 1)
      .format('MMM YYYY');
  }, [user]);

  const currentTotalPrice: string = useMemo(() => {
    return ((user.clinicUser.subscriptionInfo?.price ?? 0) * (subscription?.totalSeats ?? 0)).toFixed(2);
  }, [subscription, user]);

  const renewsDate: string = useMemo(() => {
    return 'Renews ' + moment(user.clinicUser.subscriptionInfo?.currentTermEndsAt).format('MMMM D, YYYY');
  }, [user]);

  useEffect(() => {
    if (subscription) {
      setSeatsCount(subscription.totalSeats);
    }
  }, [subscription]);

  useEffect(() => {
    if (
      !isMobile ||
      user.clinicUser?.subscriptionInfo?.planCode === PLAN_CODE_TRIAL ||
      !getIsAdminByRoles(user.clinicUser.roles)
    ) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (seatsCount) {
      setLoading(true);

      computeSubscriptionProration({
        plan: { code: user.clinicUser.subscriptionInfo!.planCode!, quantity: seatsCount },
        mode: ComputeSubscriptionProrationMode.ProrateExistingSubscription,
        couponCode: coupone?.code,
      })
        .then((response: ComputeSubscriptionProrationResponse) => {
          setSubscriptionProration(response.subscriptionProration);
        })
        .catch((error) => {
          enqueueSnackbar('Calculation error: ' + error.message, { variant: 'error' });
          setSubscriptionProration(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [seatsCount, coupone, user]);

  const handleBackToSubscriptionsClick = (): void => {
    navigate('/settings#subscriptions');
  };

  const handleDecreaseSeat = (): void => {
    if (seatsCount > 1) {
      setSeatsCount(seatsCount - 1);
    }
  };

  const handleIncreaseSeat = (): void => {
    setSeatsCount(seatsCount + 1);
  };

  const handleIncrease = (): void => {
    setLoading(true);

    updateSubscriptionWithIncreasingAmountOfLicenses({
      planCode: user.clinicUser.subscriptionInfo!.planCode!,
      quantity: seatsCount,
      redirectUrl: `${process.env.REACT_APP_PUBLIC_URI}/successPayment?type=${SUCCESS_OPERATION_ACTION_TYPE_SUBSCRIPTION_CHANGE}&redirectTo=settings&redirectToHash=subscriptions`,
      couponCode: coupone?.code,
    })
      .then((response: UpdateSubscriptionWithIncreasingAmountOfLicensesResponse) => {
        window.location.href = response.page.url;
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDecrease = (): void => {
    setLoading(true);

    updateSubscriptionWithDecreasingAmountOfLicenses({
      planCode: user.clinicUser.subscriptionInfo!.planCode!,
      quantity: seatsCount,
    })
      .then(() => {
        window.location.href = `${process.env.REACT_APP_PUBLIC_URI}/successPayment?type=${SUCCESS_OPERATION_ACTION_TYPE_SUBSCRIPTION_CHANGE}&redirectTo=settings&redirectToHash=subscriptions`;
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleApplyCoupon = (coupon: Coupon): void => {
    setCoupone(coupon);
  };

  const handleRemoveCoupon = (): void => {
    setCoupone(null);
  };

  const handleManageStaff = (): void => {
    navigate('/settings#staff');
  };

  const handleToAnnual = async (): Promise<void> => {
    navigate('/upgradeToAnnualPlan');
  };

  const handleBack = (): void => {
    navigate('/settings#subscriptions');
  };

  return (
    <>
      {loading && <Spinner />}
      <div className={styles.page}>
        <BackButton text='Back to Subscription' onClick={handleBackToSubscriptionsClick} />

        <div className={styles.container}>
          <Box className={styles.containerTop}>
            <Typography variant='body4' component='span'>
              Current Quantity
            </Typography>

            <div className={classnames(styles.row, styles.pricePerMonth)}>
              <Typography variant='h4' component='h4'>
                ${pricePerOneSeatPerMonth}/Month/Seat
              </Typography>

              <Info
                text='If your practice includes multiple providers, you can purchase and assign a seat to each practice provider. Use the staff page to assign seats and manage your staff.'
                tooltipClassName={styles.tooltip}
              />

              <Typography variant='body5' component='span' color='#626363'>
                {user.clinicUser.subscriptionInfo?.planCode === PLAN_CODE_MONTHLY
                  ? 'Billed Monthly'
                  : `Billed $${(user.clinicUser.subscriptionInfo?.price ?? 0).toFixed(2)}/seat annually`}
              </Typography>
            </div>

            <div className={styles.devider}></div>

            <div className={styles.row}>
              <div className={styles.calculationsRow}>
                <Typography variant='body5' component='span' color='#393534'>
                  Total Seats
                </Typography>

                <Typography variant='body5' component='span' color='#393534'>
                  {subscription?.totalSeats}
                </Typography>
              </div>

              <div className={styles.calculationsRow}>
                <Typography variant='body4' component='span'>
                  Total
                </Typography>

                <Typography variant='body4' component='span'>
                  ${currentTotalPrice}
                </Typography>
              </div>
            </div>

            <div className={styles.devider}></div>

            <div className={styles.row}>
              <Typography variant='body2' component='span'>
                Payment Method
              </Typography>

              <div className={styles.card}>
                <Typography variant='body3' component='span' color='#393534'>
                  {`Card ending in ${user.clinicUser.subscriptionInfo?.cardLastFourDigits ?? '0000'}`}
                </Typography>

                <Typography variant='body5' component='span' color='#393534'>
                  {`Expires ${cardExparationString}`}
                </Typography>
              </div>
            </div>

            {user.clinicUser.subscriptionInfo?.planCode === PLAN_CODE_MONTHLY && (
              <div className={styles.toAnnual} onClick={handleToAnnual}>
                <Typography variant='body5' component='span'>
                  <u>
                    Save <b>20%</b> by upgrading to Annual
                  </u>
                </Typography>
              </div>
            )}
          </Box>

          <Box className={styles.containerBottom}>
            <Typography component='p' variant='body4'>
              New Quantity
            </Typography>

            <div className={styles.row}>
              <Typography variant='body2' component='span'>
                Total Seats
              </Typography>

              <SeatsCountSelector
                count={seatsCount}
                decreaseDisabled={manageSeatsState === 'minLimitReached' || seatsCount === 1}
                onDecrease={handleDecreaseSeat}
                onIncrease={handleIncreaseSeat}
              />
            </div>

            <div className={styles.row}>
              <Typography variant='body2' component='span'>
                Discount Code
              </Typography>

              <DiscountInput
                onApply={handleApplyCoupon}
                onRemove={handleRemoveCoupon}
                plan={user.clinicUser.subscriptionInfo!.planCode!}
                disabled={manageSeatsState !== 'seatsIncrease'}
              />
            </div>

            <div className={styles.devider}></div>

            <div className={styles.row}>
              <div className={styles.calculationsRow}>
                <Typography variant='body5' component='span' color='#393534'>
                  Discount
                </Typography>

                <Typography variant='body5' component='span' color='#393534'>
                  -${subscriptionProration?.initialDiscount.toFixed(2)}
                </Typography>
              </div>

              {manageSeatsState !== 'seatsIncrease' && (
                <div className={styles.calculationsRow}>
                  <Typography variant='body4' component='span'>
                    {manageSeatsState === 'notChanged' ? 'Current Total' : 'New Total'}
                  </Typography>

                  <Typography variant='body4' component='span'>
                    ${subscriptionProration?.recurringChargeTotal.toFixed(2)}
                  </Typography>
                </div>
              )}

              {manageSeatsState === 'seatsIncrease' && (
                <>
                  <div className={styles.calculationsRow}>
                    <Typography variant='body5' component='span' color='#393534'>
                      New Total
                    </Typography>

                    <Typography variant='body5' component='span' color='#393534'>
                      ${subscriptionProration?.recurringChargeTotal.toFixed(2)}
                    </Typography>
                  </div>

                  <div className={styles.calculationsRow}>
                    <Typography variant='body4' component='span'>
                      Prorated Amount Due
                    </Typography>

                    <Typography variant='body4' component='span'>
                      ${subscriptionProration?.initialChargeAmount.toFixed(2)}
                    </Typography>
                  </div>
                </>
              )}
            </div>

            {manageSeatsState === 'minLimitReached' && (
              <Typography color='#FF3D3D' variant='body1' component='span'>
                Total number of seats cannot be reduced to less than the number of currently active seats.
                <br />
                Please deactivate some of your staff to continue.
              </Typography>
            )}

            {manageSeatsState === 'seatsDecrease' && (
              <Typography color='#626363' variant='body1' component='span'>
                The prorated amount will be credited to balance in the next billing cycle.
              </Typography>
            )}

            {manageSeatsState === 'seatsIncrease' && (
              <Typography color='#626363' variant='body1' component='span'>
                Prorated amount will be charged immediately, and your new total will take effect on your next billing
                cycle.
              </Typography>
            )}

            <div className={styles.devider}></div>

            <div>
              <Typography variant='body5' component='span' color='#626363'>
                {renewsDate}
              </Typography>
            </div>

            <div className={styles.buttons}>
              {manageSeatsState === 'minLimitReached' && (
                <Button className={styles.manageStaff} variant='contained' onClick={handleManageStaff}>
                  Manage staff
                </Button>
              )}

              <Button variant='outlined' onClick={handleBack}>
                Back
              </Button>

              {manageSeatsState === 'minLimitReached' && (
                <Button variant='contained' disabled>
                  Update
                </Button>
              )}

              {manageSeatsState === 'seatsDecrease' && (
                <Button variant='contained' onClick={handleDecrease}>
                  Update
                </Button>
              )}

              {manageSeatsState === 'notChanged' && (
                <Button variant='contained' disabled>
                  Pay now
                </Button>
              )}

              {manageSeatsState === 'seatsIncrease' && (
                <Button variant='contained' onClick={handleIncrease}>
                  Pay ${subscriptionProration?.initialChargeAmount.toFixed(2)}
                </Button>
              )}
            </div>
          </Box>
        </div>
      </div>
    </>
  );
}
