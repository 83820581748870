import { useEffect, useRef, useState } from 'react';
import { LocalVideoTrack } from 'twilio-video';
import {
  GaussianBlurBackgroundProcessor,
  Pipeline,
  VirtualBackgroundProcessor,
  ImageFit,
} from '@twilio/video-processors';

import { useLocalStorageState, useVideo } from 'hooks';
import { VIDEO_BACKGROUND_EFFECT } from 'constants/index';

import img2 from 'assets/images/videoBackgrounds/2.jpg';
import img3 from 'assets/images/videoBackgrounds/3.jpg';
import img4 from 'assets/images/videoBackgrounds/4.jpg';
import img5 from 'assets/images/videoBackgrounds/5.jpg';
import img6 from 'assets/images/videoBackgrounds/6.jpg';
import img7 from 'assets/images/videoBackgrounds/7.jpg';
import img8 from 'assets/images/videoBackgrounds/8.jpg';
import img9 from 'assets/images/videoBackgrounds/9.jpg';
import img10 from 'assets/images/videoBackgrounds/10.jpg';
import img11 from 'assets/images/videoBackgrounds/11.jpg';
import img12 from 'assets/images/videoBackgrounds/12.jpg';
import img13 from 'assets/images/videoBackgrounds/13.jpg';
import img14 from 'assets/images/videoBackgrounds/14.jpg';
import img15 from 'assets/images/videoBackgrounds/15.jpg';
import img16 from 'assets/images/videoBackgrounds/16.jpg';
import img17 from 'assets/images/videoBackgrounds/17.jpg';
import img18 from 'assets/images/videoBackgrounds/18.jpg';
import img19 from 'assets/images/videoBackgrounds/19.jpg';
import img20 from 'assets/images/videoBackgrounds/20.jpg';
import img21 from 'assets/images/videoBackgrounds/21.jpg';
import img22 from 'assets/images/videoBackgrounds/22.jpg';
import img23 from 'assets/images/videoBackgrounds/23.jpg';
import img24 from 'assets/images/videoBackgrounds/24.jpg';
import img25 from 'assets/images/videoBackgrounds/25.jpg';
import img26 from 'assets/images/videoBackgrounds/26.jpg';
import img27 from 'assets/images/videoBackgrounds/27.jpg';
import img28 from 'assets/images/videoBackgrounds/28.jpg';
import img29 from 'assets/images/videoBackgrounds/29.jpg';
import img30 from 'assets/images/videoBackgrounds/30.jpg';
import img31 from 'assets/images/videoBackgrounds/31.jpg';
import img32 from 'assets/images/videoBackgrounds/32.jpg';
import img33 from 'assets/images/videoBackgrounds/33.jpg';
import img34 from 'assets/images/videoBackgrounds/34.jpg';
import img35 from 'assets/images/videoBackgrounds/35.jpg';
import img36 from 'assets/images/videoBackgrounds/36.jpg';

type VideoBackgroundEffect = null | 'blur' | number;

export function useVideoBackgroundEffect() {
  const { localTracks } = useVideo();

  const [videoBackgroundEffect, setVideoBackgroundEffect] = useLocalStorageState<VideoBackgroundEffect>(
    VIDEO_BACKGROUND_EFFECT,
    null,
  );
  const [loading, setLoading] = useState<boolean>(true);
  const blurProcessor = useRef<GaussianBlurBackgroundProcessor | null>(null);

  const imgs: string[] = [
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
    img27,
    img28,
    img29,
    img30,
    img31,
    img32,
    img33,
    img34,
    img35,
    img36,
  ];

  useEffect(() => {
    const bg = new GaussianBlurBackgroundProcessor({
      assetsPath: '/',
      maskBlurRadius: 20,
      blurFilterRadius: 25,
      pipeline: Pipeline.Canvas2D,
    });
    bg.loadModel().finally(() => {
      setLoading(false);
    });

    blurProcessor.current = bg;
  }, []);

  useEffect(() => {
    if (!blurProcessor.current) {
      return;
    }

    const localVideoTrack = localTracks.find((track) => track.kind === 'video') as LocalVideoTrack | undefined;

    if (localVideoTrack) {
      if (videoBackgroundEffect === null) {
        onClearEffect(localVideoTrack);
      } else if (videoBackgroundEffect === 'blur') {
        onBlur(localVideoTrack);
      } else {
        onSetImage(localVideoTrack, videoBackgroundEffect);
      }
    }
  }, [localTracks, videoBackgroundEffect, blurProcessor]);

  const onClearEffect = (localVideoTrack: LocalVideoTrack): void => {
    if (localVideoTrack.processor) {
      localVideoTrack.removeProcessor(localVideoTrack.processor!);
    }
  };

  const onBlur = (localVideoTrack: LocalVideoTrack): void => {
    onClearEffect(localVideoTrack);

    localVideoTrack!.addProcessor(blurProcessor.current!);
  };

  const onSetImage = (localVideoTrack: LocalVideoTrack, imgIndex: number): void => {
    setLoading(true);

    const img: HTMLImageElement = new Image();
    img.src = imgs[imgIndex];
    img.onload = async () => {
      const bg: VirtualBackgroundProcessor = new VirtualBackgroundProcessor({
        assetsPath: '/',
        backgroundImage: img,
        maskBlurRadius: 5,
        pipeline: Pipeline.Canvas2D,
        fitType: ImageFit.Cover,
      });
      await bg.loadModel();

      onClearEffect(localVideoTrack);
      setLoading(false);
      localVideoTrack.addProcessor(bg);
    };
  };

  const clearEffect = (): void => {
    setVideoBackgroundEffect(null);
  };

  const blur = (): void => {
    setVideoBackgroundEffect('blur');
  };

  const setImage = (imgIndex: number): void => {
    setVideoBackgroundEffect(imgIndex);
  };

  return {
    loading,
    videoBackgroundEffect,
    clearEffect,
    blur,
    setImage,
  };
}
