import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function DotMenuIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
      <circle cx='10.0001' cy='2.20288' r='1.89856' />
      <circle cx='10.0001' cy='10' r='1.89856' />
      <circle cx='10.0001' cy='17.7971' r='1.89856' />
    </SvgIcon>
  );
}
