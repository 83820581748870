import { FormEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import classnames from 'classnames';
import { Avatar, Box, Button, DialogContent, DialogTitle, Theme, Typography, useMediaQuery } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Patient } from 'types';
import { FormInput } from 'components';

import styles from './WelcomeDialog.module.scss';

export interface WelcomeDialogProps extends DialogProps {
  onConfirm: (patient: Patient) => void;
  avatarUrl?: string;
  profileFullName: string;
  organizationName: string;
}

const schema = Yup.object().shape({
  userName: Yup.string()
    .required('Name is a required field')
    .min(2, 'Min length is 2 character')
    .max(40, 'Max length is 40 character'),
  phoneNumber: Yup.string().nullable(),
});

export function WelcomeDialog({
  onConfirm,
  avatarUrl,
  profileFullName,
  organizationName,
  onClose,
  ...rest
}: WelcomeDialogProps) {
  const form = useForm<Patient>({
    resolver: yupResolver(schema),
    defaultValues: {
      userName: '',
      phoneNumber: undefined,
    },
    mode: 'all',
  });
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  async function handleSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();

    if (form.formState.isValid) {
      const formValues = form.getValues();
      formValues.id = uuidv4();
      onConfirm(formValues);
    }
  }

  return (
    <Dialog {...rest} className={classnames(styles.dialog, { [styles.dialogMobile]: isMobile })}>
      <DialogTitle>
        <Avatar
          className={classnames(styles.avatar, {
            [styles.avatarTablet]: isTablet,
            [styles.avatarMobile]: isMobile,
          })}
          variant='square'
          src={avatarUrl}
        >
          {organizationName}
        </Avatar>
      </DialogTitle>
      <DialogContent>
        <Box
          component='form'
          onSubmit={handleSubmit}
          className={classnames(styles.form, { [styles.formMobile]: isMobile })}
        >
          <Typography component='p' variant='h3' className={styles.welcome}>
            Welcome to
            <br />
            {organizationName}
          </Typography>

          <Typography component='p' variant={isTablet ? 'body2' : 'body5'}>
            {profileFullName}
          </Typography>

          <Box position='relative'>
            <FormInput
              name='userName'
              control={form.control}
              placeholder='Name'
              classes={{ input: styles.nameInput }}
              errorHelperClassName={styles.errorHelper}
            />
          </Box>
          <Box position='relative'>
            <FormInput
              name='phoneNumber'
              control={form.control}
              placeholder='Call Back Number'
              errorHelperClassName={styles.errorHelper}
            />
          </Box>

          <Button
            variant='contained'
            onClick={handleSubmit}
            disabled={!form.formState.isValid}
            className={classnames(styles.formButton, {
              [styles.formButtonTablet]: isTablet,
            })}
            type='submit'
          >
            Enter waiting room
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
