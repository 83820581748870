/* eslint-disable prettier/prettier */
import { SyntheticEvent } from 'react';

type CallbackHandler = (() => void) | undefined | null;
type CloseReason = 'escapeKeyDown' | 'backdropClick';

export function withOutsideClickPreventer(callbackHandler?: CallbackHandler): (event: SyntheticEvent, reason: CloseReason) => void {
  return (event: SyntheticEvent, reason: CloseReason) => {
    event?.stopPropagation();
    event?.preventDefault();

    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      callbackHandler?.();
    }
  }
}