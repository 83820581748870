////////////////////////////////////////////////////////////
/// Common
////////////////////////////////////////////////////////////

import { ClinicSubscriptionInfo, PostCheckoutSubscriptionInfo } from './subscription';

export type ApiUser = {
  id: string;
  title?: string;
  firstName: string;
  lastName: string;
  twoFactorMode: TwoFactor;
  twoFactorEnabled: boolean;
  speciality?: string;
  phoneNumber?: string;
  linkToProfilePicture?: string;
  email: string;
  clinicId: string;
  // TODO: what to do if ClinicUser is null
  clinicUser: ApiClinicUser;
  emdrSettings: string;
  tooltipStagePassed: ApiUserTooltipStagePassedEnum;
  acceptPaymentStatus: AcceptPaymentStatusEnum;
  selectedAcceptPayment?: SelectedAcceptPaymentEnum;
};

export type ApiClinicUser = {
  id: string;
  roomName?: string;
  welcomeMessage?: string;
  unavailableMessage?: string;
  suffix?: string;
  preferedDisplayName?: string;
  displayEmail: boolean;
  biography?: string;
  roles: string[];
  organization: string;
  description?: string;
  linkToClinicPicture?: string;

  createRoomSkipped: boolean;
  receiveMessages: boolean;
  receiveMessagesInMeeting: boolean;
  notifications: boolean;
  notificationsSound: boolean;
  unavailable: boolean;

  hasLicense: boolean;
  paymentsAllowed: boolean;
  aiCreditsCount: number;
  subscriptionInfo: ClinicSubscriptionInfo | null;
  requestPayment: RequestPayment | null;

  aiNotesTAndCConfirmed: boolean;
  autoStartPromptAINotes: boolean;
};

export type RequestPayment = {
  clientConnectionId: string;
  ammount: number;
  description: string | null;
};

export enum ApiUserTooltipStagePassedEnum {
  None = 0,
  WelcomePage,
  InviteStaff,
  StaffManagement,
}

export enum ApiUserTitle {
  'Dr.',
  'Mr.',
  'Mrs.',
  'Miss',
  'Ms.',
  'M.',
}

export enum ApiLinkProfileSuffix {
  'LCSW',
  'PHD',
  'MSW',
  'APC',
  'LPC',
  'LAPC',
  'LAMFT',
  'LMSW',
  'RPT',
  'RPT/S',
  'NCC',
  'CPCS',
  'MMFC',
}

export enum AcceptPaymentStatusEnum {
  Connected = 'Connected',
  Pending = 'Pending',
  NotConnected = 'NotConnected',
}

export enum SelectedAcceptPaymentEnum {
  Stripe = 'Stripe',
  PayPal = 'PayPal',
}

export type TwoFactor = 'sms' | 'call';

export type ApiWaitingRoomLinkProfile = {
  email: string;
  firstName: string;
  lastName: string;
  title?: string;
  linkToProfilePicture?: string;
  providerId: string;
  biography?: string;
  unavailable: boolean;
  isProviderInQueue?: boolean;
  receiveMessages: boolean;
  displayEmail: boolean;
  suffix?: string;
  welcomeMessage?: string;
  unavailableMessage?: string;
  organization: string;
  preferedDisplayName?: string;
  speciality?: string;
};

export type ApiCallHistory = {
  startDate: Date;
  endDate: Date;
  participants: string[];
  meetingNotes: string;
  providerId: string;
  room: string;
};

export type ApiPlan = {
  code?: string;
  recurringPrice?: number;
};

export type ApiLicenseUsageInfo = {
  available: number;
  total: number;
  trialEndsAt: string | null;
  trialStartsAt: string | null;
  currentTermEndsAt: string | null;
  serverTime: string;
};

////////////////////////////////////////////////////////////
/// Requests
////////////////////////////////////////////////////////////

export type LoginRequest = {
  email: string;
  password: string;
  twoFactorCode?: string;
};

export type LoginWithVerificationCodeRequest = {
  email: string;
  verificationCode: string;
  clinicId: string;
};

export type RefreshTokenRequest = {
  token: string;
  refreshToken: string;
};

export type RegisterRequest = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  organization: string;
  captcha: string;
};

export type UpdateUserRequest = {
  firstName?: string;
  lastName?: string;
  title?: string;
  speciality?: string;
  emdrSettings?: string;
  tooltipStagePassed?: ApiUserTooltipStagePassedEnum;
};

export type UpdateClinicUserRequest = {
  roomName?: string;
  welcomeMessage?: string;
  unavailableMessage?: string;
  suffix?: string;
  preferedDisplayName?: string;
  displayEmail?: boolean;
  biography?: string;

  receiveMessages?: boolean;
  receiveMessagesInMeeting?: boolean;
  notifications?: boolean;
  notificationsSound?: boolean;
  unavailable?: boolean;
};

export type UpdateClinicRequest = {
  organization?: string;
  description?: string;
};

export type PasswordUpdateRequest = {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

export type GetStaffRequest = {
  count: number;
  offset: number;
};

export type InviteToCallBySmsRequest = {
  phoneNumber: string;
};

export type InviteToCallByEmailRequest = {
  email: string;
};

export type InviteRequest = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

export type RegisterByInvitationRequest = {
  invitationId: string;
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirmation: string;
};

export type ResetPasswordRequest = {
  userId: string;
  emailToken: string;
  password: string;
  passwordConfirmation: string;
};

export type CreateHostedPageRequest = {
  planCode: string;
  quantity: number;
  redirectUrl: string;
  couponCode?: string;
};

export type UpdateSubscriptionWithIncreasingAmountOfLicensesRequest = {
  planCode: string;
  quantity: number;
  redirectUrl: string;
  couponCode?: string;
};

export type UpdateSubscriptionWithDecreasingAmountOfLicensesRequest = {
  planCode: string;
  quantity: number;
};

export type UpdateCustomerCurrentCardRequest = {
  redirectUrl: string;
};

export type ManageLicenseRequest = {
  id: string;
  isInvite: boolean;
  hasLicense: boolean;
};

export type ComputeSubscriptionProrationRequest = {
  couponCode?: string;
  plan: {
    code: string;
    quantity: number;
  };
  mode: ComputeSubscriptionProrationMode;
};

export enum ComputeSubscriptionProrationMode {
  ProrateNewSubscription = 0,
  ProrateExistingSubscription,
}

export type CreateBaaRequest = {
  organization: string;
  signatoryName: string;
  signatoryTitle: string;
};

export type AllowDisablePaymentsRequest = {
  clinicUserId: string;
  allow: boolean;
};

export type CreatePaymentRequest = {
  clientConnectionId: string;
  ammount: number;
  description: string | null;
};

////////////////////////////////////////////////////////////
/// Responses
////////////////////////////////////////////////////////////

export type TokenResponse = {
  token: string;
  refreshToken: string;
  validTo: Date;
};

export type LoginResponse = TokenResponse & {
  code: number;
  phoneNumber?: string;
  clinics: ClinicListItemResponse[] | null;
  verificationCode?: string;
};

export type ClinicListResponse = {
  clinics: ClinicListItemResponse[];
};

export type ClinicListItemResponse = {
  clinicId: string;
  organization: string;
  isAdmin: boolean;
  membersCount: number;
};

export enum InvitationStatus {
  Active = 0,
  Inactive,
  Pending,
}

export type StaffItem = {
  invitationId: string;
  clinicUserId: string;
  name: string;
  email: string;
  status: InvitationStatus;
  roomName: string;
  hasLicense: boolean;
  paymentsAllowed: boolean;
};

export type StaffResponse = {
  items: StaffItem[];
  offset: number;
  totalCount: number;
};

export type InvitationInfoResponse = {
  isValid: boolean;
  organization?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
};

export type SubscriptionsApiResponse = {
  code?: number;
  message?: string;
};

export type GetPlanResponse = SubscriptionsApiResponse & {
  plan: ApiPlan;
};

export type LicenseUsageInfoResponse = SubscriptionsApiResponse & {
  licenseUsageInfo: ApiLicenseUsageInfo;
};

export type CreateHostedPageResponse = SubscriptionsApiResponse & {
  page: HostedPage;
};

export type UpdateCustomerCurrentCardResponse = SubscriptionsApiResponse & {
  page: HostedPage;
};

export type UpdateSubscriptionWithIncreasingAmountOfLicensesResponse = SubscriptionsApiResponse & {
  page: HostedPage;
};

export type HostedPage = {
  id: string;
  status: string;
  url: string;
  action: string;
};

export type ReactivateCurrentSubscriptionApiResponse = SubscriptionsApiResponse & {
  subscription: ClinicSubscriptionInfo | null;
};

export type PostCheckoutSubscriptionsApiResponse = SubscriptionsApiResponse & {
  subscription: PostCheckoutSubscriptionInfo | null;
};

export type GetCouponSubscriptionApiResponse = SubscriptionsApiResponse & {
  coupon: Coupon | null;
};

export type Coupon = {
  code: string;
  name: string;
  description: string;
  duration: number;
  status: CouponStatus;
  type: CouponType;
  discountBy: CouponDiscountBy;
  discountValue: number;
  plans: ApiPlan[];
  applyToPlans: CouponApplyToPlans;
};

export type CouopnDescription = {
  PromoTitle: string;
  PromoSubTitle: string;
  DiscountExplanation: string;
};

export enum CouponStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Expired = 'Expired',
  MaxedOut = 'MaxedOut',
}

export enum CouponType {
  OneTime = 'OneTime',
  Duration = 'Duration',
  Forever = 'Forever',
}

export enum CouponDiscountBy {
  Percentage = 'Percentage',
  Flat = 'Flat',
}

export enum CouponApplyToPlans {
  All = 0,
  None,
  Select,
}

export type ComputeSubscriptionProrationResponse = SubscriptionsApiResponse & {
  subscriptionProration: SubscriptionProrationApi;
};

export type SubscriptionProrationApi = {
  initialChargeAmount: number;
  initialChargeTotal: number;
  recurringChargeTotal: number;
  initialDiscount: number;
};

export type AINotesPurchaseItem = {
  itemId: string;
  name: string;
  description: string;
  rate: number;
  unit: string;
};

export type GetAllAINotesPurchaseItemsResponse = {
  items: AINotesPurchaseItem[];
};

export enum MakePurchaseStatusCodes {
  Success = 1,
  NoCard = 2,
}

export type MakePurchaseResponse = {
  code: MakePurchaseStatusCodes;
};

export enum AINotesTypeOfVisitEnum {
  FollowUp = 'FollowUp',
  Consultation = 'Consultation',
  Intake = 'Intake',
  Discharge = 'Discharge',
  Termination = 'Termination',
  GroupTherapy = 'GroupTherapy',
  CouplesCounseling = 'CouplesCounseling',
  FamilyTherapy = 'FamilyTherapy',
  ChildTherapy = 'ChildTherapy',
}

export enum AINotesWrittenAsEnum {
  AcademicCounselor = 'AcademicCounselor',
  AddictionCounselor = 'AddictionCounselor',
  AdlerianTherapist = 'AdlerianTherapist',
  AdolescentTherapist = 'AdolescentTherapist',
  AdultTherapist = 'AdultTherapist',
  AnimalAssistedTherapist = 'AnimalAssistedTherapist',
  AppliedPsychologist = 'AppliedPsychologist',
  ArtTherapist = 'ArtTherapist',
  AviationPsychologist = 'AviationPsychologist',
  BehavioralAnalyst = 'BehavioralAnalyst',
  BehavioralNeuroscientist = 'BehavioralNeuroscientist',
  BehavioralTherapist = 'BehavioralTherapist',
  BiofeedbackTherapist = 'BiofeedbackTherapist',
  BodyPsychotherapist = 'BodyPsychotherapist',
  CareerCounselor = 'CareerCounselor',
  CertifiedAlcoholAndDrugCounselor = 'CertifiedAlcoholAndDrugCounselor',
  CertifiedEatingDisorderSpecialist = 'CertifiedEatingDisorderSpecialist',
  ChildAndAdolescentPsychiatrist = 'ChildAndAdolescentPsychiatrist',
  ChildAndFamilySocialWorker = 'ChildAndFamilySocialWorker',
  ChildTherapist = 'ChildTherapist',
  ClinicalHypnotherapist = 'ClinicalHypnotherapist',
  ClinicalNeuropsychologist = 'ClinicalNeuropsychologist',
  ClinicalPsychologist = 'ClinicalPsychologist',
  ClinicalSocialWorker = 'ClinicalSocialWorker',
  CognitiveBehavioralTherapist = 'CognitiveBehavioralTherapist',
  CommunityPsychiatricNurse = 'CommunityPsychiatricNurse',
  CommunityPsychologist = 'CommunityPsychologist',
  ComparativePsychologist = 'ComparativePsychologist',
  CompassionFocusedTherapist = 'CompassionFocusedTherapist',
  ConsultingPsychologist = 'ConsultingPsychologist',
  ConsumerPsychologist = 'ConsumerPsychologist',
  CorrectionalPsychologist = 'CorrectionalPsychologist',
  CounselingPsychologist = 'CounselingPsychologist',
  CoupleTherapist = 'CoupleTherapist',
  CriminalPsychologist = 'CriminalPsychologist',
  CrisisCounselor = 'CrisisCounselor',
  DanceMovementPsychotherapist = 'DanceMovementPsychotherapist',
  DanceMovementTherapist = 'DanceMovementTherapist',
  DepthPsychotherapist = 'DepthPsychotherapist',
  DevelopmentalPsychologist = 'DevelopmentalPsychologist',
  DialecticalBehaviorTherapist = 'DialecticalBehaviorTherapist',
  DisabilityCounselor = 'DisabilityCounselor',
  DomesticViolenceCounselor = 'DomesticViolenceCounselor',
  EatingDisorderCounselor = 'EatingDisorderCounselor',
  EducationalPsychologist = 'EducationalPsychologist',
  EmotionallyFocusedTherapist = 'EmotionallyFocusedTherapist',
  EngineeringPsychologist = 'EngineeringPsychologist',
  EnvironmentalPsychologist = 'EnvironmentalPsychologist',
  EquineAssistedPsychotherapist = 'EquineAssistedPsychotherapist',
  Ergonomist = 'Ergonomist',
  EvolutionaryPsychologist = 'EvolutionaryPsychologist',
  ExercisePsychologist = 'ExercisePsychologist',
  ExistentialTherapist = 'ExistentialTherapist',
  ExperimentalPsychologist = 'ExperimentalPsychologist',
  ExpressiveArtsTherapist = 'ExpressiveArtsTherapist',
  ExpressiveTherapist = 'ExpressiveTherapist',
  EMDRTherapist = 'EMDRTherapist',
  FamilySystemsTherapist = 'FamilySystemsTherapist',
  FamilyTherapist = 'FamilyTherapist',
  FeministTherapist = 'FeministTherapist',
  ForensicCounselor = 'ForensicCounselor',
  ForensicPsychologist = 'ForensicPsychologist',
  ForensicSocialWorker = 'ForensicSocialWorker',
  GeneticCounselor = 'GeneticCounselor',
  GeriatricCounselor = 'GeriatricCounselor',
  GeriatricPsychiatrist = 'GeriatricPsychiatrist',
  GeriatricSocialWorker = 'GeriatricSocialWorker',
  Geropsychologist = 'Geropsychologist',
  GestaltTherapist = 'GestaltTherapist',
  GlobalPsychologist = 'GlobalPsychologist',
  GriefCounselor = 'GriefCounselor',
  GroupTherapist = 'GroupTherapist',
  HealthPsychologist = 'HealthPsychologist',
  HospiceAndPalliativeCareSocialWorker = 'HospiceAndPalliativeCareSocialWorker',
  HumanFactorsPsychologist = 'HumanFactorsPsychologist',
  HumanisticTherapist = 'HumanisticTherapist',
  Hypnotherapist = 'Hypnotherapist',
  ImagoRelationshipTherapist = 'ImagoRelationshipTherapist',
  IndustrialOrganizationalPsychologist = 'IndustrialOrganizationalPsychologist',
  IntegrativeBodyPsychotherapist = 'IntegrativeBodyPsychotherapist',
  IntegrativeHealthCoach = 'IntegrativeHealthCoach',
  IntegrativeTherapist = 'IntegrativeTherapist',
  InternalFamilySystemsTherapist = 'InternalFamilySystemsTherapist',
  InternationalPsychologist = 'InternationalPsychologist',
  InvestigativePsychologist = 'InvestigativePsychologist',
  JungianAnalyst = 'JungianAnalyst',
  JungianTherapist = 'JungianTherapist',
  LegalPsychologist = 'LegalPsychologist',
  LGBTQAffirmativeTherapist = 'LGBTQAffirmativeTherapist',
  LGBTQCounselor = 'LGBTQCounselor',
  LicensedProfessionalCounselor = 'LicensedProfessionalCounselor',
  MarriageAndFamilyTherapist = 'MarriageAndFamilyTherapist',
  MentalHealthAdministrator = 'MentalHealthAdministrator',
  MentalHealthAdvocate = 'MentalHealthAdvocate',
  MentalHealthConsultant = 'MentalHealthConsultant',
  MentalHealthCounselor = 'MentalHealthCounselor',
  MentalHealthEducator = 'MentalHealthEducator',
  MentalHealthNurse = 'MentalHealthNurse',
  MentalHealthResearcher = 'MentalHealthResearcher',
  MentalHealthSocialWorker = 'MentalHealthSocialWorker',
  MentalHealthSpeaker = 'MentalHealthSpeaker',
  MentalHealthTechnician = 'MentalHealthTechnician',
  MentalHealthTrainer = 'MentalHealthTrainer',
  MentalHealthWriter = 'MentalHealthWriter',
  MilitaryPsychologist = 'MilitaryPsychologist',
  MindfulnessTherapist = 'MindfulnessTherapist',
  MulticulturalTherapist = 'MulticulturalTherapist',
  MusicTherapist = 'MusicTherapist',
  NarrativeTherapist = 'NarrativeTherapist',
  NeurofeedbackTherapist = 'NeurofeedbackTherapist',
  Neuropsychologist = 'Neuropsychologist',
  OccupationalHealthPsychologist = 'OccupationalHealthPsychologist',
  OccupationalTherapist = 'OccupationalTherapist',
  PediatricPsychologist = 'PediatricPsychologist',
  PeerSupportSpecialist = 'PeerSupportSpecialist',
  PerformancePsychologist = 'PerformancePsychologist',
  PersonCenteredTherapist = 'PersonCenteredTherapist',
  PlayTherapist = 'PlayTherapist',
  PoliticalPsychologist = 'PoliticalPsychologist',
  PositivePsychologist = 'PositivePsychologist',
  PositivePsychologyPractitioner = 'PositivePsychologyPractitioner',
  PsychiatricMentalHealthNursePractitioner = 'PsychiatricMentalHealthNursePractitioner',
  PsychiatricNurse = 'PsychiatricNurse',
  PsychiatricNursePractitioner = 'PsychiatricNursePractitioner',
  PsychiatricTechnician = 'PsychiatricTechnician',
  Psychiatrist = 'Psychiatrist',
  Psychoanalyst = 'Psychoanalyst',
  Psychodramatist = 'Psychodramatist',
  PsychodynamicTherapist = 'PsychodynamicTherapist',
  Psychologist = 'Psychologist',
  RationalEmotiveAndCognitiveBehaviorTherapist = 'RationalEmotiveAndCognitiveBehaviorTherapist',
  RationalEmotiveBehaviorTherapist = 'RationalEmotiveBehaviorTherapist',
  RehabilitationCounselor = 'RehabilitationCounselor',
  RehabilitationPsychologist = 'RehabilitationPsychologist',
  RelationalTherapist = 'RelationalTherapist',
  ResiliencePsychologist = 'ResiliencePsychologist',
  SchemaTherapist = 'SchemaTherapist',
  SchoolCounselor = 'SchoolCounselor',
  SchoolPsychologist = 'SchoolPsychologist',
  SchoolSocialWorker = 'SchoolSocialWorker',
  SeniorTherapist = 'SeniorTherapist',
  SensorimotorPsychotherapist = 'SensorimotorPsychotherapist',
  SexTherapist = 'SexTherapist',
  SocialPsychologist = 'SocialPsychologist',
  SolutionFocusedBriefTherapist = 'SolutionFocusedBriefTherapist',
  SomaticExperiencingPractitioner = 'SomaticExperiencingPractitioner',
  SpacePsychologist = 'SpacePsychologist',
  SportsPsychologist = 'SportsPsychologist',
  SubstanceAbuseCounselor = 'SubstanceAbuseCounselor',
  SubstanceAbuseSocialWorker = 'SubstanceAbuseSocialWorker',
  SuicidePreventionCounselor = 'SuicidePreventionCounselor',
  SystemicTherapist = 'SystemicTherapist',
  TrafficPsychologist = 'TrafficPsychologist',
  TranspersonalPsychologist = 'TranspersonalPsychologist',
  TranspersonalTherapist = 'TranspersonalTherapist',
  TraumaCounselor = 'TraumaCounselor',
  TraumaTherapist = 'TraumaTherapist',
  VocationalCounselor = 'VocationalCounselor',
}

export enum AINotesGenerateNoteEnum {
  SOAP = 'SOAP',
  DAP = 'DAP',
  BIRP = 'BIRP',
  GIRP = 'GIRP',
  PIRP = 'PIRP',
  SIRP = 'SIRP',
  PIE = 'PIE',
  RIFT = 'RIFT',
  CARE = 'CARE',
  STOP = 'STOP',
  MINT = 'MINT',
  FORT = 'FORT',
  Intake = 'Intake',
  Group = 'Group',
  Couples = 'Couples',
  Psychotherapy = 'Psychotherapy',
}

export type AINotesPreselectsResponse = {
  aiNotesPreselectedTypeOfVisit: AINotesTypeOfVisitEnum;
  aiNotesPreselectedWrittenAs: AINotesWrittenAsEnum;
  aiNotesPreselectedGenerateNote: AINotesGenerateNoteEnum;
  aiNotesPreselectedIncludeReductedTranscript: boolean;
  aiCreditsCount: number;
};

export enum AINoteItemStatusEnum {
  NotStarted,
  Processing,
  Error,
  Compleated,
  TooShortRecordingError,
  RecordingStoppedNotByUserError,
}

export type AINoteItem = {
  id: string;
  startedAt: string;
  endedAt: string;
  participantsCount: number;
  room: string;
  status: AINoteItemStatusEnum;
  errorMessage: string;
};

export type GetAINotesResponse = {
  page: number;
  pagesCount: number;
  count: number;
  items: AINoteItem[];
};

////////////////////////////////////////////////////////////
/// SignalR Messages
////////////////////////////////////////////////////////////

export type MutePatientMessage = {
  muted: boolean;
};

export type UpdatedUnavailableProviderStatusMessage = {
  unavailable: boolean;
};

export type IsProviderInQueueMessage = {
  isProviderInQueue: boolean;
};

export type ItemAddedMessage = {
  itemInJson: string;
  connectionId: string;
};

export type ItemsAddedMessage = {
  itemsInJson: string[];
  connectionId: string;
};

export type ItemUndoedMessage = {
  itemId: string;
  connectionId: string;
};

export type AllItemsMessage = {
  itemsInJson: string[];
};
