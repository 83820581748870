import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function MedicalNotesIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='21' height='28' viewBox='0 0 21 28' fill='none' {...props}>
      <path d='M18 4.08192H13.8333C13.8333 2.24416 12.3385 0.75 10.5 0.75C8.66146 0.75 7.16667 2.24416 7.16667 4.08192H3C1.61979 4.08192 0.5 5.20123 0.5 6.58085V24.9064C0.5 26.286 1.61979 27.4053 3 27.4053H18C19.3802 27.4053 20.5 26.286 20.5 24.9064V6.58085C20.5 5.20123 19.3802 4.08192 18 4.08192ZM10.5 2.83245C11.1927 2.83245 11.75 3.3895 11.75 4.08192C11.75 4.77433 11.1927 5.33138 10.5 5.33138C9.80729 5.33138 9.25 4.77433 9.25 4.08192C9.25 3.3895 9.80729 2.83245 10.5 2.83245ZM15.5 18.659C15.5 18.8881 15.3125 19.0755 15.0833 19.0755H12.1667V21.991C12.1667 22.22 11.9792 22.4074 11.75 22.4074H9.25C9.02083 22.4074 8.83333 22.22 8.83333 21.991V19.0755H5.91667C5.6875 19.0755 5.5 18.8881 5.5 18.659V16.1601C5.5 15.931 5.6875 15.7436 5.91667 15.7436H8.83333V12.8282C8.83333 12.5991 9.02083 12.4117 9.25 12.4117H11.75C11.9792 12.4117 12.1667 12.5991 12.1667 12.8282V15.7436H15.0833C15.3125 15.7436 15.5 15.931 15.5 16.1601V18.659ZM15.5 8.6633C15.5 8.89237 15.3125 9.07979 15.0833 9.07979H5.91667C5.6875 9.07979 5.5 8.89237 5.5 8.6633V7.83032C5.5 7.60125 5.6875 7.41383 5.91667 7.41383H15.0833C15.3125 7.41383 15.5 7.60125 15.5 7.83032V8.6633Z' />
    </SvgIcon>
  );
}
