import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function ZoomOutIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.5 4.88174e-08C13.1904 4.88174e-08 13.75 0.559644 13.75 1.25V4.48223L17.8661 0.366117C18.3543 -0.122039 19.1457 -0.122039 19.6339 0.366117C20.122 0.854272 20.122 1.64573 19.6339 2.13388L15.5178 6.25H18.75C19.4404 6.25 20 6.80964 20 7.5C20 8.19036 19.4404 8.75 18.75 8.75H12.5C11.8096 8.75 11.25 8.19036 11.25 7.5V1.25C11.25 0.559644 11.8096 4.88174e-08 12.5 4.88174e-08ZM1.25 13.75C0.559644 13.75 4.88174e-08 13.1904 4.88174e-08 12.5C4.88174e-08 11.8096 0.559644 11.25 1.25 11.25H7.5C8.19036 11.25 8.75 11.8096 8.75 12.5V18.75C8.75 19.4404 8.19036 20 7.5 20C6.80964 20 6.25 19.4404 6.25 18.75V15.5178L2.13388 19.6339C1.64573 20.122 0.854272 20.122 0.366117 19.6339C-0.122039 19.1457 -0.122039 18.3543 0.366117 17.8661L4.48223 13.75H1.25Z'
        fill='#393534'
      />
    </SvgIcon>
  );
}
