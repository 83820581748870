import React from 'react';
import { Box, Divider, Stack, Theme, Typography, useMediaQuery } from '@mui/material';

import { AudioDiagnostic } from '../AudioDiagnostic';
import { VideoPreview } from '../VideoPreview';
import { MasterVolume } from './MasterVolume';
import { VideoInputList } from './VideoInputList';
import { AudioInputList } from './AudioInputList';
import { AudioOutputList } from './AudioOutputList';
import { LayoutList } from './LayoutList';
import { BackgroundEffectsList } from 'components';

import styles from './DeviceSelectionContainer.module.scss';

type DeviceSelectionContainerProps = {
  withVideoPreview?: boolean;
  withMasterVolume?: boolean;
  withBackground?: boolean;
  withLayoutSelection?: boolean;
  withAudioDiagnostic?: boolean;
};

export function DeviceSelectionContainer({
  withVideoPreview,
  withMasterVolume,
  withBackground,
  withLayoutSelection,
  withAudioDiagnostic,
}: DeviceSelectionContainerProps) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Box className={styles.container}>
      {withMasterVolume && (
        <>
          <Stack className={styles.row}>
            <Stack direction='row' alignItems='center'>
              <Typography component='p' variant={isDesktop ? 'body5' : 'body2'}>
                Meeting Audio Volume
              </Typography>
            </Stack>

            <MasterVolume />
          </Stack>
          <Divider />
        </>
      )}

      {withVideoPreview && <VideoPreview className={styles.preview} />}

      {withAudioDiagnostic && <AudioDiagnostic />}

      <Stack className={styles.row}>
        <Stack direction='row' alignItems='center'>
          <Typography component='p' variant={isDesktop ? 'body5' : 'body2'}>
            Camera
          </Typography>
        </Stack>
        <VideoInputList />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' alignItems='center'>
          <Typography component='p' variant={isDesktop ? 'body5' : 'body2'}>
            Microphone
          </Typography>
        </Stack>
        <AudioInputList />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' alignItems='center'>
          <Typography component='p' variant={isDesktop ? 'body5' : 'body2'}>
            Speaker
          </Typography>
        </Stack>
        <AudioOutputList />
      </Stack>

      {withLayoutSelection && (
        <>
          <Divider />

          <Stack className={styles.row}>
            <Stack direction='row' alignItems='center'>
              <Typography component='p' variant={isDesktop ? 'body5' : 'body2'}>
                View
              </Typography>
            </Stack>
            <LayoutList />
          </Stack>
        </>
      )}

      {withBackground && (
        <>
          <Divider />

          <Stack className={styles.row}>
            <Stack direction='row' alignItems='center'>
              <Typography component='p' variant={isDesktop ? 'body5' : 'body2'}>
                Choose Virtual Background
              </Typography>
            </Stack>
            <BackgroundEffectsList itemClassName={styles.backgroundEffectsListItem} />
          </Stack>
        </>
      )}
    </Box>
  );
}
