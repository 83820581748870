import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function InfoIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='17' viewBox='0 0 16 17' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 2.1C6.30261 2.1 4.67475 2.77428 3.47452 3.97452C2.27428 5.17475 1.6 6.80261 1.6 8.5C1.6 10.1974 2.27428 11.8253 3.47452 13.0255C4.67475 14.2257 6.30261 14.9 8 14.9C9.69739 14.9 11.3253 14.2257 12.5255 13.0255C13.7257 11.8253 14.4 10.1974 14.4 8.5C14.4 6.80261 13.7257 5.17475 12.5255 3.97452C11.3253 2.77428 9.69739 2.1 8 2.1ZM0 8.5C0 4.0816 3.5816 0.5 8 0.5C12.4184 0.5 16 4.0816 16 8.5C16 12.9184 12.4184 16.5 8 16.5C3.5816 16.5 0 12.9184 0 8.5ZM8 12.5C8.21217 12.5 8.41566 12.4157 8.56569 12.2657C8.71571 12.1157 8.8 11.9122 8.8 11.7V8.5C8.8 8.28783 8.71571 8.08434 8.56569 7.93431C8.41566 7.78429 8.21217 7.7 8 7.7C7.78783 7.7 7.58434 7.78429 7.43431 7.93431C7.28429 8.08434 7.2 8.28783 7.2 8.5V11.7C7.2 11.9122 7.28429 12.1157 7.43431 12.2657C7.58434 12.4157 7.78783 12.5 8 12.5ZM8 4.5C7.78783 4.5 7.58434 4.58429 7.43431 4.73431C7.28429 4.88434 7.2 5.08783 7.2 5.3C7.2 5.51217 7.28429 5.71566 7.43431 5.86569C7.58434 6.01571 7.78783 6.1 8 6.1H8.008C8.22017 6.1 8.42366 6.01571 8.57369 5.86569C8.72371 5.71566 8.808 5.51217 8.808 5.3C8.808 5.08783 8.72371 4.88434 8.57369 4.73431C8.42366 4.58429 8.22017 4.5 8.008 4.5H8Z'
      />
    </SvgIcon>
  );
}
