import React, { useMemo } from 'react';
import { Box, IconButton, styled, Typography } from '@mui/material';
import classnames from 'classnames';
import { useDialog, useEMDR, useVideo, useWhiteboard } from 'hooks';
import { ScreenShareIcon } from 'assets';
import { ControlTooltip, SimpleInfoDialog } from 'components';
import { IEMDRContext, IWhiteboardContext } from 'context';

import styles from './ToggleScreenShareButton.module.scss';

type ToggleScreenShareButtonProps = {
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
  iconColor?: string;
  withTooltip?: boolean;
  withLabel?: boolean;
};

const ShareIcon = styled(ScreenShareIcon)(({ theme }) => ({
  color: '#fff',
}));

export function ToggleScreenShareButton({
  disabled,
  className,
  iconClassName,
  iconColor,
  withTooltip,
  withLabel,
}: ToggleScreenShareButtonProps) {
  const { toggleScreenShare, isSharingScreen } = useVideo();
  const { state: whiteboardState }: IWhiteboardContext = useWhiteboard();
  const { state: emdrState }: IEMDRContext = useEMDR();
  const { showDialog } = useDialog();

  const tooltipMessage = useMemo(() => {
    let tooltipMessage = '';

    if (withTooltip) {
      tooltipMessage = isSharingScreen ? 'Stop Sharing' : 'Share Screen';
    }

    return tooltipMessage;
  }, [withTooltip, isSharingScreen]);

  const sx = useMemo(
    () => ({
      backgroundColor: isSharingScreen ? '#81CFDD !important' : 'rgb(0 0 0 / 50%)!important',
    }),
    [isSharingScreen],
  );

  const handleClick = (): void => {
    if (whiteboardState !== 'off' || emdrState !== 'off') {
      const modal = showDialog(SimpleInfoDialog, {
        text:
          whiteboardState !== 'off'
            ? 'In order to share screen please exit whiteboard'
            : 'In order to start share screen please exit EMDR',
        onConfirm: () => {
          modal.destroy();
        },
      });

      return;
    }

    toggleScreenShare();
  };

  return (
    <ControlTooltip title={tooltipMessage} isDisabled={disabled}>
      <Box component='span' display='block' className={classnames(styles.deviceButton, className)}>
        <IconButton
          disabled={disabled}
          className={classnames(
            { [styles.deviceButton]: !withLabel, [styles.deviceButtonWithLabel]: withLabel },
            className,
          )}
          sx={sx}
          onClick={handleClick}
        >
          <ShareIcon className={classnames(styles.icon, iconClassName)} sx={{ color: iconColor || '#fff' }} />
          {withLabel && (
            <Typography component='p' variant='body2' sx={{ color: '#FFF' }} ml='10px'>
              {isSharingScreen ? 'Stop Sharing' : 'Share Screen'}
            </Typography>
          )}
        </IconButton>
      </Box>
    </ControlTooltip>
  );
}
