/* eslint-disable prettier/prettier */
import { useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import SwiperCore, { Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box } from '@mui/material';
import {
  useEMDR,
  useMainParticipant,
  useParticipantsContext,
  useScreenShareParticipant,
  useScreenSize,
  useVideo,
  useWhiteboard,
} from 'hooks';
import { ChevronRightIcon } from 'assets';
import { Participant } from '../Participant';
import { IEMDRContext, IWhiteboardContext } from 'context';

import styles from './ParticipantList.module.scss';

SwiperCore.use([Mousewheel, Navigation]);

type ParticipantListProps = {
  className?: string;
};

export function ParticipantList({ className = '' }: ParticipantListProps) {
  const { room } = useVideo();
  const { speakerViewParticipants } = useParticipantsContext();
  const screenShareParticipant = useScreenShareParticipant();
  const mainParticipant = useMainParticipant();
  const { isDesktop, isMobileHorizontal, isBetweenMdAndLg, isTablet } = useScreenSize();
  const localParticipant = room!.localParticipant;
  const { state: emdrState }: IEMDRContext = useEMDR();
  const { state: whiteboardState }: IWhiteboardContext = useWhiteboard();

  const navigationPrevRef = useRef<HTMLElement>(null);
  const navigationNextRef = useRef(null);
  const [showHideClass, setShowHideClass] = useState<boolean>(false);

  const speakerViewParticipantsToShow = useMemo(() => {
    // show all participants in this cases lsit:
    // 1. if someone share screen
    // 2. or if whiteboard
    // show all participants except main participant in all other cases
    return speakerViewParticipants.filter(
      (participant) =>
        screenShareParticipant ||
        whiteboardState !== 'off' ||
        (!screenShareParticipant && participant !== mainParticipant),
    );
  }, [speakerViewParticipants, screenShareParticipant, whiteboardState, mainParticipant]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (navigationPrevRef.current) {
        setShowHideClass(true);
        clearInterval(interval);
      }
    }, 100);
  }, []);

  return speakerViewParticipants.length ? (
    <Box
      className={classnames(styles.container, className, {
        [styles.containerHideNav]: showHideClass,
        [styles.hideParticipantList]: (isBetweenMdAndLg || isTablet) && emdrState !== 'off',
      })}
    >
      <Swiper
        direction={isDesktop || isMobileHorizontal ? 'vertical' : 'horizontal'}
        navigation={{
          enabled: true,
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
          hideOnClick: false,
          disabledClass: styles.navigationDisabled,
          hiddenClass: styles.navigationHidden,
          lockClass: styles.navigationLock,
          navigationDisabledClass: styles.navigationDisabledClass,
        }}
        allowTouchMove={!isDesktop}
        mousewheel={true}
        slidesPerView='auto'
        centeredSlides={false}
        spaceBetween={10}
        className={styles.swiper}
      >
        <SwiperSlide className={styles.slide}>
          <Participant participant={localParticipant} isLocalParticipant={true} withBorder />
        </SwiperSlide>

        {speakerViewParticipantsToShow.map((participant) => (
          <SwiperSlide className={styles.slide} key={participant.sid}>
            <Participant participant={participant} withBorder />
          </SwiperSlide>
        ))}
      </Swiper>

      <Box
        slot='container-start'
        ref={navigationPrevRef}
        className={classnames(styles.navigationPrevEl, styles.navigationEl)}
      >
        <ChevronRightIcon sx={{ color: '#626363', fontSize: '2rem', rotate: '90deg' }} />
      </Box>
      <Box
        slot='container-end'
        ref={navigationNextRef}
        className={classnames(styles.navigationNextEl, styles.navigationEl)}
      >
        <ChevronRightIcon sx={{ color: '#626363', fontSize: '2rem', rotate: '-90deg' }} />
      </Box>
    </Box>
  ) : null;
}
