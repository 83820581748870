/* eslint-disable prettier/prettier */
import { PropsWithChildren, useState } from 'react';

import { useApiRequests, useDialog } from 'hooks';
import { CancellationConfirmationPopupContext } from './CancellationConfirmationPopupContext';
import { CancellationConfirmationPopup } from './CancellationConfirmationPopup';
import { ConfirmResultEnum } from 'context';
import { ApiUser } from 'types';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/user';
import { Subscriptions } from 'constants/payment';
import { PostCancellationPopup } from './PostCancellationPopup';

export function CancellationConfirmationPopupProvider({ children }: PropsWithChildren) {
    const dialog = useDialog();
    const apiRequests = useApiRequests();
    const user: ApiUser | null = useSelector(selectUser);
    const [loading, setLoading] = useState<boolean>(false);

    const handleCancelCurrentSubscription = async (): Promise<void> => {
        const subscriptionInfo = user?.clinicUser.subscriptionInfo;

        const cancellationConfirmationPopupOptions = {
            content: CancellationConfirmationPopup,
            props: {
                planName: subscriptionInfo?.planName,
                roomName: user?.clinicUser.roomName,
                loading
            }
        };

        const confirmationResult = await dialog.confirm(cancellationConfirmationPopupOptions);
        if (confirmationResult !== ConfirmResultEnum.Confirm) {
            return;
        }

        setLoading(true);
        const response = await apiRequests.cancelCurrentSubscription();
        if (response.code === Subscriptions.Responses.StatusCodes.Success) {
            setLoading(false);
            await dialog.confirm({ content: PostCancellationPopup });
        }
    };

    return (
        <CancellationConfirmationPopupContext.Provider
            value={{
                cancelCurrentSubscription: handleCancelCurrentSubscription,
            }}
        >
            {children}
        </CancellationConfirmationPopupContext.Provider>
    );
}
