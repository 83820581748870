import { useCallback, useState } from 'react';
import { LocalVideoTrack } from 'twilio-video';
import { useVideo } from '../useVideo';

export function useLocalVideoToggle() {
  const { room, localTracks, getLocalVideoTrack, removeLocalVideoTrack, onError } = useVideo();
  const localParticipant = room?.localParticipant;
  const videoTrack = localTracks.find(
    (track) => !track.name.includes('screen') && track.kind === 'video',
  ) as LocalVideoTrack;
  const [isPublishing, setIspublishing] = useState(false);

  const toggleVideoEnabled = useCallback(() => {
    if (!isPublishing) {
      if (videoTrack) {
        const localTrackPublication = localParticipant?.unpublishTrack(videoTrack);
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        localParticipant?.emit('trackUnpublished', localTrackPublication);
        removeLocalVideoTrack();
      } else {
        setIspublishing(true);
        getLocalVideoTrack()
          .then((track: LocalVideoTrack) => localParticipant?.publishTrack(track, { priority: 'low' }))
          .catch(onError)
          .finally(() => {
            setIspublishing(false);
          });
      }
    }
  }, [videoTrack, localParticipant, getLocalVideoTrack, isPublishing, onError, removeLocalVideoTrack]);

  const resetVideo = useCallback(() => {
    if (videoTrack) {
      setIspublishing(true);

      const localTrackPublication = localParticipant?.unpublishTrack(videoTrack);
      localParticipant?.emit('trackUnpublished', localTrackPublication);
      removeLocalVideoTrack();

      getLocalVideoTrack()
        .then((track: LocalVideoTrack) => localParticipant?.publishTrack(track, { priority: 'low' }))
        .catch(onError)
        .finally(() => {
          setIspublishing(false);
        });
    }
  }, [videoTrack, localParticipant, getLocalVideoTrack, onError, removeLocalVideoTrack]);

  return { videoEnabled: !!videoTrack, toggleVideoEnabled, resetVideo };
}
