export const PLAN_CODE_MONTHLY = process.env['REACT_APP_ZOHO_MONTHLY_PLAN_CODE'] ?? '';
export const PLAN_CODE_YEARLY = process.env['REACT_APP_ZOHO_YEARLY_PLAN_CODE'] ?? '';
export const PLAN_CODE_TRIAL = process.env['REACT_APP_ZOHO_TRIAL_PLAN_CODE'] ?? '';

export const SUCCESS_OPERATION_ACTION_TYPE_CARD_CHANGE = 'cardChange';
export const SUCCESS_OPERATION_ACTION_TYPE_SUBSCRIPTION_CHANGE = 'subscriptionChange';

export const Subscriptions = {
  Responses: {
    StatusCodes: {
      Success: 0,
    },
  },
};
