/* eslint-disable prettier/prettier */
import { useCallback, useEffect } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store';
import {
  useChatNewMessageSound,
  useDialog,
  useOpenChatOnNewMessage,
  useSignalrConnection,
  useVideo,
} from 'hooks';
import { ISignalrConnectionContext } from 'context';
import { AINotesMenu, NotesDialog, PatientQueue } from 'components';
import { getRoomHistory } from 'store/call';
import { selectUser } from 'store/user';
import { ApiUser } from 'types';
import { EditWaitingRoomContainer } from './components';
import styles from './EditRoomPage.module.scss';

export function EditRoomPage() {
  // will be moved to Chat component
  useChatNewMessageSound();
  useOpenChatOnNewMessage();
  const dispatch = useAppDispatch();
  const {
    signalrConnectionId,
    events,
    getPatientsList,
    addEventListener,
    removeEventListener,
  }: ISignalrConnectionContext = useSignalrConnection();
  const { showDialog } = useDialog();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { room } = useVideo();
  const user: ApiUser | null = useAppSelector(selectUser);

  useEffect(() => {
    if (room) {
      return () => {
        room?.disconnect();
      };
    }
  }, [room]);

  useEffect(() => {
    if (signalrConnectionId) {
      getPatientsList();
    }
  }, [signalrConnectionId]);

  useEffect(() => {
    events.on('updatedStatusDataSent', getPatientsList);
  }, [events]);

  const onCallEnded = useCallback(() => {
    dispatch(getRoomHistory()).then(() => {
      const modal = showDialog(NotesDialog, {
        onConfirm: () => {
          modal.destroy();
        },
      });
    });
  }, []);

  useEffect(() => {
    addEventListener('callEnded', onCallEnded);

    return () => {
      removeEventListener('callEnded', onCallEnded);
    };
  }, [onCallEnded]);

  return (
    <div className={styles.container}>
      {isDesktop && (
        <PatientQueue
          className={styles.patientQueue}
          additionalMenuSlot={
            <AINotesMenu aiCreditsCount={user?.clinicUser.aiCreditsCount} />
          }
        />
      )}
      <EditWaitingRoomContainer />
    </div>
  );
}
