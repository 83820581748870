import React, { useMemo } from 'react';
import { Box, IconButton, styled, Typography } from '@mui/material';
import classnames from 'classnames';

import { useLocalAudioToggle, useMutedParticipants, useVideo } from 'hooks';
import { MicIcon, MicOffIcon } from 'assets';
import { ControlTooltip } from 'components';
import { IMutedParticipantsContext } from 'context';

import styles from './ToggleAudioButton.module.scss';

type ToggleAudioButtonProps = {
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
  withTooltip?: boolean;
  withLabel?: boolean;
};

const MicIconStyled = styled(MicIcon)(({ theme }) => ({
  color: '#fff',
}));

const MicIconOffStyled = styled(MicOffIcon)(({ theme }) => ({
  color: '#fff',
}));

export function ToggleAudioButton({
  disabled,
  className,
  iconClassName,
  withTooltip,
  withLabel,
}: ToggleAudioButtonProps) {
  const { isCurrentParticipantMuted }: IMutedParticipantsContext = useMutedParticipants();
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideo();
  const hasAudioTrack = localTracks.some((track) => track.kind === 'audio');

  const tooltipMessage = useMemo(() => {
    let tooltipMessage = '';

    if (withTooltip) {
      tooltipMessage = isCurrentParticipantMuted ? 'You have been muted' : isAudioEnabled ? 'Mute' : 'Unmute';
    }

    return tooltipMessage;
  }, [withTooltip, isAudioEnabled, isCurrentParticipantMuted]);

  const sx = useMemo(
    () => ({
      backgroundColor: `${isAudioEnabled ? 'rgb(0 0 0 / 50%)' : '#FF3D3D'} !important`,
      '&:hover': {
        backgroundColor: isAudioEnabled ? 'rgb(0 0 0 / 50%)' : '#FF3D3D',
      },
    }),
    [isAudioEnabled],
  );

  return (
    <ControlTooltip title={tooltipMessage} isDisabled={!isAudioEnabled}>
      <Box component='span' display='block'>
        <IconButton
          disabled={!hasAudioTrack || disabled || isCurrentParticipantMuted}
          className={classnames(
            styles.button,
            {
              [styles.deviceButton]: !withLabel,
              [styles.deviceButtonWithLabel]: withLabel,
              [styles.disabled]: !hasAudioTrack || disabled || isCurrentParticipantMuted,
            },
            className,
          )}
          sx={!withLabel ? sx : {}}
          onClick={toggleAudioEnabled}
          aria-label='notification'
        >
          {isAudioEnabled ? (
            <MicIconStyled
              className={classnames(styles.icon, iconClassName)}
              sx={withLabel ? { color: '#3EB157' } : {}}
            />
          ) : (
            <MicIconOffStyled
              className={classnames(styles.icon, iconClassName)}
              sx={withLabel ? { color: '#FF3D3D' } : {}}
            />
          )}
          {withLabel && (
            <Typography component='p' variant='body2' sx={{ color: '#393534' }} ml='10px'>
              Microphone
            </Typography>
          )}
        </IconButton>
      </Box>
    </ControlTooltip>
  );
}
