import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function FlowerIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <mask id='path-1-inside-1_18149_51614'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          strokeWidth='1'
          stroke='black'
          fill='white'
          d='M6.20502 19.4216C7.88005 19.4216 9.3252 18.4372 9.99359 17.0153C10.662 18.4372 12.1071 19.4216 13.7822 19.4216C16.093 19.4216 17.9662 17.5482 17.9662 15.2372C17.9662 14.3637 17.6986 13.5528 17.2409 12.8818C18.8504 12.2991 20.0004 10.757 20.0004 8.9462C20.0004 6.63517 18.1272 4.76172 15.8164 4.76172C15.2376 4.76172 14.6862 4.87926 14.1849 5.09177C14.1933 4.98316 14.1976 4.87339 14.1976 4.76261C14.1976 2.45158 12.3244 0.578125 10.0136 0.578125C7.70277 0.578125 5.82959 2.45158 5.82959 4.76261C5.82959 4.87751 5.83422 4.99134 5.84331 5.1039C5.33444 4.88374 4.77323 4.76172 4.18354 4.76172C1.87269 4.76172 -0.000488281 6.63517 -0.000488281 8.9462C-0.000488281 10.7532 1.14467 12.2926 2.74881 12.8781C2.28958 13.5498 2.021 14.3621 2.021 15.2372C2.021 17.5482 3.89418 19.4216 6.20502 19.4216Z'
        />
      </mask>
      <path
        d='M6.20502 19.4216C7.88005 19.4216 9.3252 18.4372 9.99359 17.0153C10.662 18.4372 12.1071 19.4216 13.7822 19.4216C16.093 19.4216 17.9662 17.5482 17.9662 15.2372C17.9662 14.3637 17.6986 13.5528 17.2409 12.8818C18.8504 12.2991 20.0004 10.757 20.0004 8.9462C20.0004 6.63517 18.1272 4.76172 15.8164 4.76172C15.2376 4.76172 14.6862 4.87926 14.1849 5.09177C14.1933 4.98316 14.1976 4.87339 14.1976 4.76261C14.1976 2.45158 12.3244 0.578125 10.0136 0.578125C7.70277 0.578125 5.82959 2.45158 5.82959 4.76261C5.82959 4.87751 5.83422 4.99134 5.84331 5.1039C5.33444 4.88374 4.77323 4.76172 4.18354 4.76172C1.87269 4.76172 -0.000488281 6.63517 -0.000488281 8.9462C-0.000488281 10.7532 1.14467 12.2926 2.74881 12.8781C2.28958 13.5498 2.021 14.3621 2.021 15.2372C2.021 17.5482 3.89418 19.4216 6.20502 19.4216Z'
        mask='url(#path-1-inside-1_18149_51614)'
        strokeWidth='4'
        fill='none'
      />
    </SvgIcon>
  );
}
