import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function DialogIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M7.25 16.25V15.25H6.25H2.5C1.67338 15.25 1 14.5766 1 13.75V2.5C1 1.67338 1.67338 1 2.5 1H17.5C18.3266 1 19 1.67338 19 2.5V13.75C19 14.5766 18.3266 15.25 17.5 15.25H11.875H11.5416L11.2749 15.4501L7.25 18.4696V16.25Z'
        strokeWidth='2'
        fill='none'
      />
    </SvgIcon>
  );
}
