/* eslint-disable prettier/prettier */
import { styled } from '@mui/material';
import { Box, Dialog, DialogContent, DialogProps, Typography } from '@mui/material';
import phoneRotation from 'assets/images/phone-rotate.png';

const StyledDialogContent = styled(DialogContent)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '36px',
  width: '100%',
  height: '100%',
  background: '#393534 !important',
}));

const ImageContainer = styled(Box)(() => ({
  width: '109px',
  height: '109px',

  '& img': {
    width: '100%',
    height: '100%',
  },
}));

const TextMessage = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: 'normal',
  textTransform: 'capitalize',
  color: '#fff',
}));

export type ForcedRotatePhoneDialogProps = DialogProps;

export const ForcedRotatePhoneDialog = ({ ...props }: ForcedRotatePhoneDialogProps) => {
  return (
    <Dialog {...props} open={true}>
      <StyledDialogContent>
        <ImageContainer>
          <img src={phoneRotation} alt='Phone rotation' />
        </ImageContainer>
        <TextMessage>Rotate your phone</TextMessage>
      </StyledDialogContent>
    </Dialog>
  );
};
