import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function UserAddIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='22' height='22' viewBox='0 0 22 22' fill='none' {...props}>
      <path d='M16.1765 10.3529C19.3927 10.3529 22 12.9602 22 16.1765C22 19.3927 19.3927 22 16.1765 22C12.9602 22 10.3529 19.3927 10.3529 16.1765C10.3529 12.9602 12.9602 10.3529 16.1765 10.3529ZM10.6858 11.6471C9.66941 12.8776 9.05882 14.4558 9.05882 16.1765C9.05882 17.8628 9.64527 19.4122 10.6254 20.6317C10.1142 20.6814 9.59051 20.7059 9.05882 20.7059C6.65857 20.7059 4.42196 20.2064 2.76293 19.1485C1.07775 18.074 0 16.4239 0 14.2353C0 12.8603 1.06494 11.752 2.40614 11.6541L2.59953 11.6471H10.6858ZM16.1765 12.9412C15.8589 12.9412 15.5946 13.1701 15.5398 13.4719L15.5294 13.5882V15.5294H13.5882C13.2309 15.5294 12.9412 15.8192 12.9412 16.1765C12.9412 16.4941 13.1701 16.7583 13.4719 16.8131L13.5882 16.8235H15.5294V18.7647C15.5294 19.122 15.8192 19.4118 16.1765 19.4118C16.4941 19.4118 16.7583 19.1828 16.8131 18.881L16.8235 18.7647V16.8235H18.7647C19.122 16.8235 19.4118 16.5338 19.4118 16.1765C19.4118 15.8589 19.1828 15.5946 18.881 15.5398L18.7647 15.5294H16.8235V13.5882C16.8235 13.2309 16.5338 12.9412 16.1765 12.9412ZM9.05882 0C11.9177 0 14.2353 2.31758 14.2353 5.17647C14.2353 8.03536 11.9177 10.3529 9.05882 10.3529C6.19994 10.3529 3.88235 8.03536 3.88235 5.17647C3.88235 2.31758 6.19994 0 9.05882 0Z' />
    </SvgIcon>
  );
}
