export * from './roles';
export * from './data';
export * from './payment';
export * from './texts';
export * from './emdr';

export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'selectedAudioInputDevice';
export const SELECTED_AUDIO_OUTPUT_KEY = 'selectedAudioOutputDevice';
export const SELECTED_VIDEO_INPUT_KEY = 'selectedVideoInput';
export const SELECTED_MASTER_VOLUME_KEY = 'selectedMasterVolume';
export const SELECTED_LAYOUT_TYPE_KEY = 'selectedLayoutType';

// These are used to store user settings and other flags
export const PLAYED_MESSAGES_MAP = 'playedMessagesMap';
export const SHOW_MOBILE_LANDSCAPE_WARNING = 'showMobileLandscapeWarning';
export const COUPON = 'coupon';
export const TOKENS = 'tokens';
export const VIDEO_BACKGROUND_EFFECT = 'videoBackgroundEffect';

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'selectedBackgroundSettings';

export const GALLERY_VIEW_ASPECT_RATIO = 9 / 16; // 16:9
export const GALLERY_VIEW_MARGIN = 3;
export const GRID_VIEW_THRESHOLD = 2;

export const IDENTITY_DIVIDER = '|!##!|';

export const LOGIN_SUCCESS_CODE = 1;
export const LOGIN_SELECT_CLINIC_CODE = 2;
export const LOGIN_VERIFY_PHONE_CODE = 3;
export const LOGIN_EMAIL_NOT_CONFIRMED_CODE = 4;
export const LOGIN_LICENSE_REQUIRED_CODE = 5;

export const DEFAULT_DURATION_TIME_VALUE = 30;
export const DEFAULT_DURATION_PASSES_VALUE = 25;

export const STRIPE_ACCOUNT_ONBORDING_COMPLEATED = 'accountOnbordingCompleated';
export const STRIPE_ACCOUNT_ONBORDING_REJECTED = 'accountOnbordingRejected';
