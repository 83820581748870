import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

import { noop } from 'utils';

import styles from './EMDRSaveSettingsDialog.module.scss';

export interface EMDRSaveSettingsDialogProps extends DialogProps {
  defaultValue: number;
  onConfirm: (presetIndex: number) => void;
  onClose: () => void;
}

export function EMDRSaveSettingsDialog({ defaultValue, onConfirm, onClose, ...rest }: EMDRSaveSettingsDialogProps) {
  const [value, setValue] = useState<number>(defaultValue);

  const handleChange = (e: any, value: string): void => {
    setValue(+value);
  };

  const handleConfirm = (): void => {
    onConfirm(value);
  };

  return (
    <Dialog {...rest} onClose={noop} className={styles.dialog}>
      <DialogContent>
        <Typography component='p' variant='body2'>
          Save settings as
        </Typography>

        <RadioGroup name='selectPreset' value={value} className={styles.radioGroup} onChange={handleChange}>
          <FormControlLabel value={'1'} control={<Radio className={styles.check} />} label='Preset 1' />
          <FormControlLabel value={'2'} control={<Radio className={styles.check} />} label='Preset 2' />
          <FormControlLabel value={'3'} control={<Radio className={styles.check} />} label='Preset 3' />
        </RadioGroup>

        <div className={styles.buttons}>
          <Button variant='outlined' onClick={onClose}>
            Cancel
          </Button>

          <Button variant='contained' onClick={handleConfirm}>
            Save
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
