import { TextField, Box, Typography, FormControl, OutlinedInput, Button, Stack } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectUser } from 'store/user';
import { ApiUser } from 'types';
import { PasswordPopup } from './PasswordPopup';
import { Info } from 'components';

import styles from './LoginPannel.module.scss';

export const LoginPannel = () => {
  const user: ApiUser = useSelector(selectUser)!;

  const [open, setOpen] = useState<boolean>(false);

  const handleClickOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <Box className={styles.login_main_container}>
      <Stack className={styles.row}>
        <Stack spacing={1} direction='row' alignItems='center'>
          <Typography variant='body2' className={styles.email}>
            Email
          </Typography>

          <Info
            children={
              <div>
                Contact us&nbsp;
                <a
                  className={styles.emailTooltipHref}
                  href='mailto:support@hipaalink.net?subject=Change%20email%20address%20request'
                >
                  here
                </a>
                &nbsp;to change your email
              </div>
            }
          />
        </Stack>
        <TextField size='small' className={styles.email_textField} value={user.email} disabled />
      </Stack>

      <Typography variant='body2' className={styles.inputField}>
        Password
      </Typography>

      <Box className={styles.flex}>
        <FormControl className={styles.fullWidth} variant='outlined'>
          <OutlinedInput type='password' value='' className={styles.font12} disabled />
        </FormControl>
        <Button className={styles.changeButton} variant='contained' onClick={handleClickOpen}>
          Change
        </Button>
      </Box>

      <PasswordPopup open={open} handleClose={handleClose} />
    </Box>
  );
};
