import { useCallback } from 'react';
import { Button, DialogContent, DialogTitle, Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { ModalTitle } from 'components';
import { HubPatient } from 'types';

import styles from './ContactDialog.module.scss';

export interface ContactDialogProps extends DialogProps {
  patient: HubPatient;
  onConfirm: () => void;
}

export function ContactDialog({ onConfirm, patient, ...rest }: ContactDialogProps) {
  const onCopyToClipboard = useCallback(() => {
    onConfirm();
  }, []);
  return (
    <Dialog {...rest} className={styles.dialog}>
      <DialogTitle>
        <ModalTitle title='Contact' onClose={onConfirm} />
      </DialogTitle>
      <DialogContent>
        <Typography component='p' variant='body5'>
          {patient.userName}
        </Typography>
        {patient.phoneNumber && (
          <Typography component='p' variant='body5'>
            {patient.phoneNumber}
          </Typography>
        )}

        {patient.phoneNumber && (
          <CopyToClipboard text={`${patient.phoneNumber}`} onCopy={onCopyToClipboard}>
            <Button variant='contained' fullWidth className={styles.formButton} onClick={onCopyToClipboard}>
              Copy
            </Button>
          </CopyToClipboard>
        )}
      </DialogContent>
    </Dialog>
  );
}
