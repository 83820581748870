import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function Mp4FileIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='64' height='73' viewBox='0 0 64 73' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.77387 0.00683594C2.58526 0.00683594 0 2.59324 0 5.78298V67.0746C0 70.2655 2.58526 72.8507 5.77387 72.8507H57.8833C61.0719 72.8507 63.6594 70.2655 63.6594 67.0746V23.1274L42.1406 0.00683594H5.77387V0.00683594Z'
        fill='#81CFDD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M63.6728 23.1683V24.3067H49.1027C49.1027 24.3067 41.9172 22.8724 42.1255 16.6716C42.1255 16.6716 42.3623 23.1683 48.9604 23.1683H63.6728Z'
        fill='#5C96A0'
      />
      <path
        opacity='0.5'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M42.1548 0V16.576C42.1548 18.4611 43.4116 23.1683 49.1035 23.1683H63.6736L42.1548 0Z'
        fill='white'
      />
      <path
        d='M24.0036 61.3349C23.5323 61.3349 23.1635 60.966 23.1635 60.4947V49.9602L18.5724 61.0696C18.5109 61.2335 18.347 61.3349 18.1421 61.3349C17.9782 61.3349 17.8143 61.2324 17.7528 61.0696L13.1822 49.9602V60.4947C13.1822 60.966 12.8134 61.3349 12.3216 61.3349C11.8503 61.3349 11.4814 60.966 11.4814 60.4947V48.8115C11.4814 48.0738 12.1167 47.4386 12.8965 47.4386C13.4497 47.4386 14.0235 47.7665 14.2488 48.2993L18.1637 57.8298L22.0991 48.2993C22.3245 47.7665 22.8778 47.4386 23.4515 47.4386C24.2302 47.4386 24.8665 48.0738 24.8665 48.8115V60.4947C24.8643 60.966 24.4954 61.3349 24.0036 61.3349ZM34.0624 55.7807H30.2704V60.4947C30.2704 60.966 29.9016 61.3349 29.4098 61.3349C28.9385 61.3349 28.5697 60.966 28.5697 60.4947V48.7705C28.5697 48.0943 29.1229 47.54 29.8003 47.54H34.0635C36.8104 47.54 38.3472 49.4262 38.3472 51.6598C38.3461 53.8955 36.7683 55.7807 34.0624 55.7807ZM33.8575 49.0574H30.2704V54.2632H33.8575C35.4762 54.2632 36.5839 53.1977 36.5839 51.6598C36.5839 50.1241 35.4762 49.0574 33.8575 49.0574ZM49.698 57.749H48.3661V60.4959C48.3661 60.9672 47.9973 61.336 47.5044 61.336C47.0331 61.336 46.6643 60.9672 46.6643 60.4959V57.749H40.8847C40.4544 57.749 40.0446 57.3801 40.0446 56.8269C40.0446 56.6424 40.1049 56.4375 40.2085 56.2736L45.6807 48.0545C45.9471 47.6652 46.4184 47.4193 46.9113 47.4193C47.6899 47.4193 48.3661 48.0955 48.3661 48.8536V56.2531H49.698C50.0874 56.2531 50.4152 56.581 50.4152 57.0124C50.4152 57.42 50.0862 57.749 49.698 57.749ZM46.6643 49.1189L41.8683 56.252H46.6643V49.1189Z'
        fill='white'
      />
    </SvgIcon>
  );
}
