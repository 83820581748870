import { IconButton, Theme, Tooltip, useMediaQuery } from '@mui/material';
import classnames from 'classnames';

import { InfoIcon } from 'assets';
import { SimpleInfoDialog } from 'components';
import { IDialogContext } from 'context';
import { useDialog } from 'hooks';

import styles from './Info.module.scss';

type InfoProps = {
  text?: string;
  children?: JSX.Element;
  tooltipClassName?: string;
};

export function Info({ text, children, tooltipClassName }: InfoProps) {
  const { showDialog }: IDialogContext = useDialog();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const handleClick = (): void => {
    if (!isDesktop) {
      const modal = showDialog(SimpleInfoDialog, {
        text,
        children,
        onConfirm: () => {
          modal.destroy();
        },
        classes: { paper: styles.dialog },
      });
    }
  };

  return isDesktop ? (
    <Tooltip
      classes={{ tooltip: classnames(styles.tooltip, tooltipClassName) }}
      title={text ?? children}
      placement='right'
    >
      <IconButton className={styles.icon} onClick={handleClick}>
        <InfoIcon color='primary' />
      </IconButton>
    </Tooltip>
  ) : (
    <IconButton className={styles.icon} onClick={handleClick}>
      <InfoIcon color='primary' />
    </IconButton>
  );
}
