import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { HubConnectionBuilder, HubConnection } from '@microsoft/signalr';
import { AxiosError } from 'axios';

import { Spinner, SubscriptionRequiredDialog } from 'components';
import { useAppDispatch, useAppSelector } from 'store';
import { getUser, clearUserStore, selectUser } from 'store/user';
import { ApiUser, RegisterByInvitationRequest, TokenResponse } from 'types';
import { IApiRequests, useApiRequests, useDialog, useLocalStorageState } from 'hooks';
import { UserContext } from './context';
import { TOKENS } from 'constants/index';

type UserProviderProps = {
  children?: JSX.Element;
};

const REFRESH_TOKEN_TIME_IN_MINUTES = 5;

export function UserProvider({ children }: UserProviderProps) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showDialog } = useDialog();
  const {
    refreshToken,
    changeClinic: changeClinicApi,
    logout: logoutApi,
    registerUserByInvitation: registerUserByInvitationApi,
    registerSecondAccount: registerSecondAccountApi,
  }: IApiRequests = useApiRequests();

  const user: ApiUser | null = useAppSelector(selectUser);
  const signalrConnection = useRef<HubConnection | null>(null);
  const refreshTimeout = useRef<NodeJS.Timeout | null>(null);
  // TODO: add refresh token life validation

  const [tokens, setTokens] = useLocalStorageState<TokenResponse | null>(TOKENS, null);
  const [isLoading, setIsLoading] = useState<boolean>(!!tokens);

  const refreshTokens = (): Promise<void> => {
    return refreshToken({
      token: tokens!.token,
      refreshToken: tokens!.refreshToken,
    })
      .then((tokenResponse: TokenResponse) => {
        setTokens(tokenResponse);
      })
      .catch((error: AxiosError) => {
        // eslint-disable-next-line no-console
        console.log(`Refresh token error: ${error}`);
        setTokens(null);
        dispatch(clearUserStore());
        window.open(process.env.REACT_APP_HOME_PAGE_FOR_NON_AUTH_URL, '_self');
      });
  };

  const login = (tokenResponse: TokenResponse): Promise<ApiUser> => {
    setTokens(tokenResponse);
    return dispatch(getUser()).unwrap();
  };

  const changeClinic = (clinicId: string): Promise<ApiUser> => {
    return changeClinicApi(clinicId).then((tokenResponse: TokenResponse) => {
      setTokens(tokenResponse);
      return dispatch(getUser()).unwrap();
    });
  };

  const registerByInvitation = (registerRequest: RegisterByInvitationRequest): Promise<void> => {
    return registerUserByInvitationApi(registerRequest).then((tokenResponse: TokenResponse) => {
      setTokens(tokenResponse);
      return dispatch(getUser())
        .unwrap()
        .then(() => Promise.resolve());
    });
  };

  const registerSecondAccount = (organization: string): Promise<void> => {
    return registerSecondAccountApi(organization).then((tokenResponse: TokenResponse) => {
      setTokens(tokenResponse);
    });
  };

  useEffect(() => {
    if (tokens) {
      refreshTokens()
        .then(() => {
          return dispatch(getUser()).unwrap();
        })
        .finally(() => setIsLoading(false));
    }
  }, []);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (tokens && !refreshTimeout.current) {
      refreshTimeout.current = setTimeout(() => {
        refreshTokens();
        refreshTimeout.current = null;
      }, REFRESH_TOKEN_TIME_IN_MINUTES * 60 * 1000);
    } else if (!tokens && refreshTimeout.current) {
      clearTimeout(refreshTimeout.current);
      refreshTimeout.current = null;
    }
  }, [tokens, isLoading]);

  const logout = (): void => {
    logoutApi();
    setTokens(null);
    dispatch(clearUserStore());
    disconnect();
  };

  useEffect(() => {
    return () => disconnect();
  }, []);

  useEffect(() => {
    if (user && !signalrConnection.current) {
      const queryString = {
        clinicUserId: user!.clinicUser.id,
      };
      const urlParams: string = new URLSearchParams(queryString).toString();

      const hubConnection: HubConnection = new HubConnectionBuilder()
        .withUrl(`${process.env['REACT_APP_HIPAA_LINK_API_URI']}user?${urlParams}`)
        .build();

      listenUserHub(hubConnection);

      hubConnection
        .start()
        .then(() => {
          signalrConnection.current = hubConnection;
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e);
        });

      hubConnection.onclose(() => {
        disconnect();
      });
    }
  }, [user]);

  const listenUserHub = (hubConnection: HubConnection) => {
    if (hubConnection) {
      hubConnection.on('removedFromClinic', function () {
        enqueueSnackbar('Removed From Clinic');

        setTimeout(() => {
          logout();
        }, 3000);
      });

      hubConnection.on('licenseRemoved', function () {
        const modal: any = showDialog(SubscriptionRequiredDialog, {
          organization: user?.clinicUser.organization,
          isProvider: true,
          onConfirm: () => {
            modal.destroy();
          },
        });

        logout();
      });
    }
  };

  const disconnect = useCallback((): void => {
    signalrConnection.current?.stop();
    signalrConnection.current = null;
  }, [signalrConnection]);

  return (
    <UserContext.Provider
      value={{
        isLoading,
        tokens,
        logout,
        login,
        changeClinic,
        registerSecondAccount,
        registerByInvitation,
        disconnect,
      }}
    >
      {isLoading && <Spinner />}
      {!isLoading && children}
    </UserContext.Provider>
  );
}
