import { Button, DialogContent, DialogTitle, Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';

import { InfoIcon } from 'assets';

import styles from './InfoDialog.module.scss';

export interface InfoDialogProps extends DialogProps {
  title: string;
  onConfirm: () => void;
}

export function InfoDialog({ onConfirm, title, onClose, ...rest }: InfoDialogProps) {
  return (
    <Dialog {...rest} className={styles.dialog}>
      <DialogTitle textAlign='center' height={96}>
        <InfoIcon color='primary' sx={{ fontSize: '6.1rem' }} />
      </DialogTitle>
      <DialogContent>
        <Typography component='p' variant='body5'>
          {title}
        </Typography>

        <Button variant='contained' onClick={onConfirm} className={styles.formButton}>
          Ok
        </Button>
      </DialogContent>
    </Dialog>
  );
}
