/* eslint-disable prettier/prettier */
import { useMemo } from 'react';
import classnames from 'classnames';
import { IconButton, Badge } from '@mui/material';

import { MessageCircleIcon, MessageCircleDisabledIcon, CrossCircleIcon } from 'assets';
import { Chat } from 'components';
import { useChat } from 'hooks';
import { ApiWaitingRoomLinkProfile } from 'types';

import styles from './ChatButton.module.scss';

type ChatButtonProps = {
  provider: ApiWaitingRoomLinkProfile;
  className?: string;
};

export function ChatButton({ provider, className }: ChatButtonProps) {
  const { identity, conversation, conversationsUnreadMessagesData, openChat, closeChat } = useChat();

  const unreadMessagesCount: number = useMemo(() => {
    return conversationsUnreadMessagesData.get(`$chat-${provider.providerId}-${identity}`)?.unreadMessagesCount ?? 0;
  }, [conversationsUnreadMessagesData, identity, provider]);

  const handleClick = (): void => {
    if (conversation) {
      closeChat();
    } else if (!conversation && !provider.unavailable && provider.isProviderInQueue) {
      openChat(provider.providerId!, identity);
    }
  };

  return (
    <div className={classnames(styles.chatButton, className)}>
      <Chat className={styles.chat} />

      <IconButton disabled={provider.unavailable || !provider.isProviderInQueue} onClick={handleClick}>
        {provider.unavailable || !provider.isProviderInQueue ? (
          <MessageCircleDisabledIcon color='disabled' />
        ) : (
          <Badge badgeContent={unreadMessagesCount} className={styles.chatIconBadge} color='primary' overlap='circular'>
            {conversation ? <CrossCircleIcon color='primary' /> : <MessageCircleIcon color='primary' />}
          </Badge>
        )}
      </IconButton>
    </div>
  );
}
