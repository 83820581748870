import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function LaptopIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='100' height='82' viewBox='0 0 100 82' fill='none' {...props}>
      <path
        d='M86.7996 4H13.1996C10.6591 4 8.59961 6.05949 8.59961 8.6V54.6C8.59961 57.1405 10.6591 59.2 13.1996 59.2H86.7996C89.3401 59.2 91.3996 57.1405 91.3996 54.6V8.6C91.3996 6.05949 89.3401 4 86.7996 4Z'
        stroke='#626363'
        strokeWidth='8'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M4 77.5999H96'
        stroke='#626363'
        strokeWidth='8'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}
