/* eslint-disable prettier/prettier */
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { getUser, selectUser } from 'store/user';
import { IApiRequests, useApiRequests, useDialog, useVideo } from 'hooks';
import {
  AINotesTermsAndConditionsConfirmationDialog,
  AINotesTranscriptionSettingsDialog,
  AINotesTranscriptionStopDialog,
} from 'components';
import { IAITranscribingNotes, AITranscribingNotesContext } from './context';
import { AINotesGenerateNoteEnum, AINotesTypeOfVisitEnum, AINotesWrittenAsEnum } from 'types';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';

export interface AITranscribingNotesProviderProps {
  children?: JSX.Element;
}

export function AITranscribingNotesProvider({ children }: AITranscribingNotesProviderProps) {
  const dispatch = useAppDispatch();
  const { room } = useVideo();
  const { showDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const { confirmTermsAndConditions, getAINotesPreselects, startRecording, stopRecording }: IApiRequests =
    useApiRequests();

  const user = useAppSelector(selectUser);

  const [lastRoomSid, setLastRoomSid] = useState<string | null>(null);

  const isItPossibleToStartRecording = useMemo(() => {
    return lastRoomSid !== room?.sid;
  }, [lastRoomSid, room]);

  const showAINotesTermsAndConditionsConfirmationDialog = (): Promise<'Confirmed' | 'Canceled'> => {
    return new Promise((resolve) => {
      const modal = showDialog(AINotesTermsAndConditionsConfirmationDialog, {
        async onConfirm() {
          resolve('Confirmed');
          modal.destroy();
        },
        onCancel() {
          resolve('Canceled');
          modal.destroy();
        },
      });
    });
  };

  const showAINotesTranscriptionSettingsDialog = (props: {
    aiNotesTypeOfVisit: AINotesTypeOfVisitEnum;
    aiNotesWrittenAs: AINotesWrittenAsEnum;
    aiNotesGenerateNote: AINotesGenerateNoteEnum;
    aiNotesIncludeReductedTranscript: boolean;
    aiCreditsCount: number;
  }): Promise<{
    from?: {
      aiNotesTypeOfVisit: AINotesTypeOfVisitEnum;
      aiNotesWrittenAs: AINotesWrittenAsEnum;
      aiNotesGenerateNote: AINotesGenerateNoteEnum;
      aiNotesIncludeReductedTranscript: boolean;
    };
    dialogResult: 'Confirmed' | 'Canceled';
  }> => {
    return new Promise((resolve) => {
      const modal = showDialog(AINotesTranscriptionSettingsDialog, {
        onAINotesTranscriptionConfirmResult(result) {
          resolve(result);
          modal.destroy();
        },
        disableStartAINotesTranscription: props.aiCreditsCount <= 0,
        ...props,
      });
    });
  };

  const startTranscribing = async (): Promise<void> => {
    try {
      if (!user?.clinicUser.aiNotesTAndCConfirmed) {
        const termsAndConditionsConfirmationDialogResult = await showAINotesTermsAndConditionsConfirmationDialog();
        if (termsAndConditionsConfirmationDialogResult === 'Confirmed') {
          await confirmTermsAndConditions();
        }
      }

      const {
        aiCreditsCount,
        aiNotesPreselectedGenerateNote: aiNotesGenerateNote,
        aiNotesPreselectedIncludeReductedTranscript: aiNotesIncludeReductedTranscript,
        aiNotesPreselectedTypeOfVisit: aiNotesTypeOfVisit,
        aiNotesPreselectedWrittenAs: aiNotesWrittenAs,
      } = await getAINotesPreselects();
      const { dialogResult: aiNotesTranscriptionSettingsDialogResult, from } =
        await showAINotesTranscriptionSettingsDialog({
          aiCreditsCount,
          aiNotesGenerateNote,
          aiNotesIncludeReductedTranscript,
          aiNotesTypeOfVisit,
          aiNotesWrittenAs,
        });
      if (aiNotesTranscriptionSettingsDialogResult === 'Confirmed') {
        await startRecording({
          writtenAs: from?.aiNotesWrittenAs ?? '',
          generateNote: from?.aiNotesGenerateNote ?? '',
          typeOfVisit: from?.aiNotesTypeOfVisit ?? '',
          includeReductedTranscript: Boolean(from?.aiNotesIncludeReductedTranscript),
        });
        await dispatch(getUser()).unwrap();
        setLastRoomSid(room?.sid ?? null);
        enqueueSnackbar('This session is now being transcribed!', { variant: 'info' });
      }
    } catch (axiosError) {
      const error = axiosError as unknown as AxiosError;
      const message = (error.message as string).toUpperCase().includes('NOT ENOUGHT CREDITS')
        ? 'You don’t have enough credits'
        : error.message ?? 'Something went wrong';
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const showAINotesTranscriptionStopDialog = (): Promise<'Confirmed' | 'Canceled'> => {
    return new Promise((resolve) => {
      const modal = showDialog(AINotesTranscriptionStopDialog, {
        onAINotesTranscriptionStopConfirmResult(result) {
          resolve(result);
          modal.destroy();
        },
      });
    });
  };

  const stopTranscribing = async (): Promise<void> => {
    try {
      const dialogResult = await showAINotesTranscriptionStopDialog();
      if (dialogResult === 'Confirmed') {
        await stopRecording();
      }
    } catch (axiosError) {
      const error = axiosError as unknown as AxiosError;
      enqueueSnackbar(error.message ?? 'Something went wrong', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (user?.clinicUser.autoStartPromptAINotes && room) startTranscribing();
  }, [user?.clinicUser.autoStartPromptAINotes, room]);

  return (
    <AITranscribingNotesContext.Provider
      value={{ isItPossibleToStartRecording, startTranscribing, stopTranscribing } as IAITranscribingNotes}
    >
      {children}
    </AITranscribingNotesContext.Provider>
  );
}
