import { useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Button, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { IApiRequests, useApiRequests, useAzureAsset, useSubscription, useUser } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store';
import { getUser, selectUser } from 'store/user';
import { ApiUser, ClinicListItemResponse, ClinicListResponse } from 'types';
import { OrganizationInfo } from '../OrganizationInfo';
import { ProfileSelector } from '../ProfileSelector';
import { MenuState } from 'pages/SettingPage/SettingsPage';
import { getIsAdminByRoles } from 'utils';
import { Spinner, BackButton } from 'components';
import { IUserContext } from 'context';

import styles from './LeftMenu.module.scss';

type LeftMenuProps = {
  menuState: MenuState | null;
  onMenuStateChange(state: MenuState | null): void;
};

export function LeftMenu({ menuState, onMenuStateChange }: LeftMenuProps) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const subscription = useSubscription();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch: any = useAppDispatch();
  const user: ApiUser = useAppSelector(selectUser)!;
  const { getClinicsList }: IApiRequests = useApiRequests();
  const { changeClinic }: IUserContext = useUser();
  // const organizationAvatarUrl = useAzureAsset(user?.ClinicUser?.Clinic.LogoRoute);
  const organizationAvatarUrl = useAzureAsset(user?.linkToProfilePicture);
  const [loading, setLoading] = useState<boolean>(true);
  const [clinicsList, setClinicsList] = useState<ClinicListItemResponse[]>([]);

  const isAdmin = useMemo<boolean>(() => {
    return getIsAdminByRoles(user?.clinicUser.roles || []);
  }, [user]);

  useEffect(() => {
    if (user) {
      getClinicsList().then(({ clinics }: ClinicListResponse) => {
        setClinicsList(clinics);
        setLoading(false);
      });
    }
  }, [user]);

  const handleBackClick = (): void => {
    navigate('../');
  };

  const handleClinicChange = (clinicId: string): Promise<void> => {
    if (clinicId === user?.clinicId) {
      return Promise.resolve();
    }

    setLoading(true);

    return changeClinic(clinicId)
      .then(() => Promise.resolve())
      .catch((error: any) => {
        enqueueSnackbar(`Change clinic response: ${error}`, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleProfileClick = (): void => {
    onMenuStateChange('profile');
  };

  const handleAccountSettingsClick = (): void => {
    onMenuStateChange('accountSettings');
  };

  const handleYourOrganizationClick = (): void => {
    onMenuStateChange('yourOrganization');
  };

  const handleStaffClick = (): void => {
    onMenuStateChange('staff');
  };

  const handleAINotesClick = (): void => {
    onMenuStateChange('aiNotes');
  };

  const handleSubscriptionsClick = (): void => {
    onMenuStateChange('subscriptions');
  };

  const handleCreateSeccondAccount = (): void => {
    navigate('../registerSecondAccount');
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className={styles.leftMenu}>
      {(!isAdmin || (isAdmin && subscription?.hasSubscription && subscription.isSubscriptionActive)) && (
        <BackButton text='Back to My Room/Lobby' onClick={handleBackClick} />
      )}

      <Stack className={styles.main}>
        <Stack className={styles.profileMenu}>
          <ProfileSelector clinicsList={clinicsList} onChangeClinic={handleClinicChange} />

          <Stack
            className={classnames(styles.item, {
              [styles.itemSelected]: menuState === 'profile',
            })}
            onClick={handleProfileClick}
          >
            <Typography variant='body2'>Profile</Typography>
          </Stack>

          <Stack
            className={classnames(styles.item, {
              [styles.itemSelected]: menuState === 'accountSettings',
            })}
            onClick={handleAccountSettingsClick}
          >
            <Typography variant='body2'>Account Settings</Typography>
          </Stack>
        </Stack>

        <Stack className={styles.organizationMenu}>
          <OrganizationInfo
            avatar={organizationAvatarUrl}
            organization={user?.clinicUser.organization ?? ''}
            membersCount={
              clinicsList?.find((clinic: ClinicListItemResponse) => clinic.clinicId === user?.clinicId)?.membersCount ??
              0
            }
            isAdmin={isAdmin}
          />

          <Stack
            className={classnames(styles.item, {
              [styles.itemSelected]: menuState === 'yourOrganization' || menuState === 'acceptPayments',
            })}
            onClick={handleYourOrganizationClick}
          >
            <Typography variant='body2'>Your Organization</Typography>
          </Stack>

          <Stack
            className={classnames(styles.item, {
              [styles.itemSelected]: menuState === 'staff',
            })}
            onClick={handleStaffClick}
          >
            <Typography variant='body2'>Staff</Typography>
          </Stack>

          <Stack
            className={classnames(styles.item, {
              [styles.itemSelected]: menuState === 'aiNotes',
            })}
            onClick={handleAINotesClick}
          >
            <Typography variant='body2'>AI Progress Notes</Typography>
          </Stack>

          <Stack
            className={classnames(styles.item, {
              [styles.itemSelected]: menuState === 'subscriptions',
            })}
            onClick={handleSubscriptionsClick}
          >
            <Typography variant='body2'>Subscriptions</Typography>
          </Stack>
        </Stack>

        <Stack className={styles.createAccountButton}>
          <Button variant='contained' onClick={handleCreateSeccondAccount}>
            Create additional business profile
          </Button>
        </Stack>
      </Stack>
    </div>
  );
}
