import { useMemo, useState } from 'react';
import { useActiveSinkId, useLocalStorageState, useMasterVolume } from 'hooks';
import { LayoutType } from 'types';

import { AppSettingsContext } from './context';
import { SELECTED_LAYOUT_TYPE_KEY } from '../../constants';

interface AppSettingsProviderProps {
  children?: JSX.Element;
}

export function AppSettingsProvider({ children }: AppSettingsProviderProps) {
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [masterVolume, setMasterVolume] = useMasterVolume();
  const [layoutType, setLayoutType] = useLocalStorageState(SELECTED_LAYOUT_TYPE_KEY, LayoutType.SPEAKER);
  const [open, setOpen] = useState<boolean>(false);

  const isGalleryViewActive = useMemo(() => layoutType === LayoutType.GRID, [layoutType]);

  const openSettings = (): void => {
    setOpen(true);
  };

  const closeSettings = (): void => {
    setOpen(false);
  };

  return (
    <AppSettingsContext.Provider
      value={{
        activeSinkId,
        setActiveSinkId,
        masterVolume,
        setMasterVolume,
        layoutType,
        setLayoutType,
        isGalleryViewActive,
        open,
        openSettings,
        closeSettings,
      }}
    >
      {children}
    </AppSettingsContext.Provider>
  );
}
