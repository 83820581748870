import React from 'react';
import { Stack } from '@mui/material';
import { SessionTimerButton, ToggleNotesButton, ToggleParticipantsButton, ToggleSettingsButton } from 'components';
import { useRoomState, useRoomRightSidebar, useRoomHost } from 'hooks';

import styles from './SidebarControls.module.scss';

export function SidebarControls(): JSX.Element {
  const roomState = useRoomState();
  const { isHost } = useRoomHost();
  const { toggleSettings, toggleNotes, toggleParticipants } = useRoomRightSidebar();
  const isReconnecting = roomState === 'reconnecting';

  return (
    <Stack className={styles.container} direction='row' spacing='20px'>
      {isHost && <SessionTimerButton />}

      {isHost && <ToggleNotesButton onClick={toggleNotes} withTooltip />}

      <ToggleParticipantsButton onClick={toggleParticipants} withTooltip />

      <ToggleSettingsButton
        disabled={isReconnecting}
        className={styles.deviceButton}
        iconClassName={styles.gearIcon}
        onClick={toggleSettings}
        withTooltip
      />
    </Stack>
  );
}
