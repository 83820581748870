import { Box } from '@mui/material';
import classnames from 'classnames';

import { ModalTitle } from '../ModalTitle';
import { DeviceSelectionContainer } from '../DeviceSelectionContainer';

import styles from './SettingsMenu.module.scss';

type SettingsMenuProps = {
  onClose(): void;
  className?: string;
  withLayoutSelection?: boolean;
  withBackground?: boolean;
};

export function SettingsMenu({ className, withBackground, withLayoutSelection, onClose }: SettingsMenuProps) {
  return (
    <>
      <Box
        className={classnames(styles.menu, className)}
        sx={{ backgroundColor: '#FFF', boxShadow: '0 2px 8px rgb(0 0 0 / 5%)' }}
      >
        <ModalTitle title='Settings' onClose={onClose} />
        <DeviceSelectionContainer
          withMasterVolume
          withBackground={withBackground}
          withLayoutSelection={withLayoutSelection}
          withAudioDiagnostic
        />
      </Box>
    </>
  );
}
