import { Theme, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import { ILoginContext } from 'context';
import { useLogin } from 'hooks';
import { Spinner } from 'components';
import { LoginForm, SelectClinicForm, ForgotPasswordForm, ResetPasswordForm, Login2Fa } from './components';

import styles from './LoginPage.module.scss';
import image from '../../assets/images/LoginLeftImage.png';

export function LoginPage() {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { loading, loginModelData, loginPageState }: ILoginContext = useLogin();

  const handleSignupClick = (): void => {
    navigate('../register', { replace: true });
  };

  return (
    <div className={styles.main}>
      {(!isMobile || (!loginModelData.clinics && !loginModelData.isForgotPassword)) && (
        <div className={styles.leftSide}>
          <img src={image} />

          <div className={styles.content}>
            <Typography variant={isDesktop ? 'h1' : 'h2'} component={isDesktop ? 'h1' : 'h2'} color='#ffffff'>
              {'New to HIPAA'}
              <i>LINK?</i>
            </Typography>

            <Typography variant='body2' component='span' color='#ffffff'>
              Sign up today and discover how HIPAA LINK is the easiest to use, mostly reliable telehealth platform for
              providers and their clients.
            </Typography>

            <Button onClick={handleSignupClick}>Sign up</Button>

            <Typography variant='body2' component='span' color='#ffffff'>
              Want to know more about HIPAA LINK?{' '}
              <a target='_blank' href='https://www.hipaalink.net/'>
                Click here
              </a>
            </Typography>
          </div>

          <div className={styles.backgroundCircle1}></div>
          <div className={styles.backgroundCircle2}></div>
          <div className={styles.backgroundCircle3}></div>
        </div>
      )}

      <div className={styles.rightSide}>
        <div className={styles.loginContainer}>
          {loading && <Spinner small />}
          {loginPageState === 'login' && <LoginForm />}
          {loginPageState === '2fa' && <Login2Fa />}
          {loginPageState === 'selectClinic' && <SelectClinicForm />}
          {loginPageState === 'forgotPassword' && <ForgotPasswordForm />}
          {loginPageState === 'resetPassword' && <ResetPasswordForm />}
        </div>

        <Typography variant='body2' component='span'>
          This is a HIPAA compliant secure video login page.{' '}
          <a target='_blank' href='https://www.hipaalink.net/'>
            more details
          </a>
        </Typography>
      </div>
    </div>
  );
}
