import { BackgroundEffectsList } from 'components';
import { MenuItem } from '../MenuItem';

type BackgroundEffectsMenuProps = {
  className?: string;
};

export function BackgroundEffectsMenu({ className }: BackgroundEffectsMenuProps): JSX.Element {
  return (
    <>
      <MenuItem title='Background' className={className}>
        <BackgroundEffectsList />
      </MenuItem>
    </>
  );
}
