import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useApiRequests, useUser } from 'hooks';
import { IUserContext } from 'context';
import { TokenResponse } from 'types';

export function ConfirmEmailPage() {
  const { enqueueSnackbar } = useSnackbar();
  const { confirmEmail } = useApiRequests();
  const { login }: IUserContext = useUser();

  const search = useLocation().search;
  const navigate = useNavigate();

  const [operationFinished, setOperationFinished] = useState<boolean>(false);

  useEffect(() => {
    const userId = new URLSearchParams(search).get('userId');
    const code = new URLSearchParams(search).get('code')?.replaceAll(' ', '+');

    if (userId && code) {
      confirmEmail(userId, code)
        .then((tokenResponse: TokenResponse) => {
          login(tokenResponse);
          enqueueSnackbar('Email confirmed!', { variant: 'info' });
        })
        .catch((error) => {
          enqueueSnackbar(`Email confirmed error: ${error.message}`, { variant: 'error' });
        })
        .finally(() => {
          setOperationFinished(true);
        });
    }
  }, [search]);

  useEffect(() => {
    if (operationFinished) {
      setTimeout(() => {
        navigate('/register?step=createRoom');
      }, 2000);
    }
  }, [operationFinished]);

  return <></>;
}
