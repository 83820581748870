import { useCallback, useEffect, useRef, useState } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { EventEmitter } from 'events';
import TypedEmitter from 'typed-emitter';
import {
  CallAccessAttributes,
  HubPatient,
  HubPatientQueueResponse,
  IsProviderInQueueMessage,
  MutePatientMessage,
  PaymentRequestCreatedMessage,
  UpdatedUnavailableProviderStatusMessage,
} from 'types';
import { addPatient, removePatient, setPatients } from 'store/patientQueue';
import { useUserAgentInfo } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { selectPatient } from 'store/patient';
import { selectWaitingRoomProfile } from 'store/waitingRoom';

import { EventType, EventsMap, SignalrConnectionContext, SignalrConnectionEvents } from './context';

interface SignalrConnectionProviderProps {
  children?: JSX.Element;
}

export function SignalrConnectionProvider({ children }: SignalrConnectionProviderProps) {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  const patient = useAppSelector(selectPatient);
  const waitingRoomProfile = useAppSelector(selectWaitingRoomProfile);
  const signalrConnection = useRef<HubConnection | null>(null);
  const prevClinicUserId = useRef<string | null>(null);

  const [isFetching, setIsFetching] = useState(false);
  const [signalrConnectionId, setSignalrConnectionId] = useState('');
  const { type, os, browser } = useUserAgentInfo();
  const events = new EventEmitter() as TypedEmitter<SignalrConnectionEvents>;
  const signalrEventRefs = useRef<EventsMap>({} as unknown as EventsMap);

  const addEventListener = (eventType: EventType, callback: () => void): void => {
    const targetEventListeners = signalrEventRefs.current[eventType];

    signalrEventRefs.current = targetEventListeners
      ? { ...signalrEventRefs.current, [eventType]: [...targetEventListeners, callback] }
      : { ...signalrEventRefs.current, [eventType]: [callback] };
  };

  const removeEventListener = (eventType: EventType, callback: () => void): void => {
    const targetEventListeners = signalrEventRefs.current[eventType];

    if (targetEventListeners) {
      signalrEventRefs.current = {
        ...signalrEventRefs.current,
        [eventType]: targetEventListeners.filter((eventListener) => eventListener !== callback),
      };
    }
  };

  const removeAllEventListeners = (): void => {
    signalrEventRefs.current = {} as unknown as EventsMap;
  };

  const internalEmitEvent = (eventType: EventType): void => {
    const targetEventListeners = signalrEventRefs.current[eventType];

    if (targetEventListeners) {
      targetEventListeners.forEach((eventListener) => {
        eventListener();
        console.log('Event emit: ' + eventType);
      });
    }
  };

  useEffect(() => {
    return () => {
      events.removeAllListeners();
      removeAllEventListeners();
    };
  }, []);

  useEffect(() => {
    if (!user && !patient && signalrConnection.current) {
      disconnect();
    }

    if (user || patient) {
      if ((!user || user?.clinicId === prevClinicUserId.current) && signalrConnection.current) {
        return;
      }

      if (user && user?.clinicId !== prevClinicUserId.current && signalrConnection.current) {
        disconnect();
      }

      prevClinicUserId.current = user?.clinicId ?? null;

      const providerId = user?.clinicUser.id ?? waitingRoomProfile!.providerId;
      const userName = user?.clinicUser.id ?? patient!.userName;
      const phoneNumber = user?.phoneNumber ?? patient?.phoneNumber ?? '';

      const queryString = {
        providerId,
        userName,
        phoneNumber,
        typeOfDevice: type,
        os: os,
        browser: browser,
      };
      const urlParams: string = new URLSearchParams(queryString).toString();

      const hubConnection: HubConnection = new HubConnectionBuilder()
        .withUrl(`${process.env['REACT_APP_HIPAA_LINK_API_URI']}patientsQueue?${urlParams}`)
        .withAutomaticReconnect()
        .build();

      listenQueueHub(hubConnection);

      hubConnection
        .start()
        .then(function () {
          signalrConnection.current = hubConnection;
          setSignalrConnectionId(hubConnection.connectionId!);
        })
        .catch(function () {
          // eslint-disable-next-line no-console
          console.log('Connectin failed');
        });

      hubConnection.onreconnected(() => {
        internalEmitEvent('reconnected');
      });

      hubConnection.onclose(() => {
        disconnect();
        internalEmitEvent('disconnected');

        // eslint-disable-next-line no-console
        console.log('disconnected v1');
      });
    }
  }, [user, prevClinicUserId, patient, waitingRoomProfile?.providerId]);

  useEffect(() => {
    return () => {
      disconnect();

      // eslint-disable-next-line no-console
      console.log('disconnected v2');
    };
  }, []);

  const listenQueueHub = (hubConnection: HubConnection) => {
    if (hubConnection) {
      hubConnection.on('tokenSent', function (token: string) {
        // eslint-disable-next-line no-console
        console.log('tokenSent', token);
      });
      hubConnection.on('joinCall', function (roomName: string) {
        events.emit('joinCall', roomName, hubConnection.connectionId!);
      });
      hubConnection.on('disconnected', function () {
        internalEmitEvent('disconnected');
      });
      hubConnection.on('removedFromCall', function () {
        internalEmitEvent('removedFromCall');
      });
      hubConnection.on('isProviderInQueueSent', function (message: IsProviderInQueueMessage) {
        events.emit('isProviderInQueueSent', message.isProviderInQueue);
      });
      hubConnection.on('updatedStatusDataSent', function () {
        events.emit('updatedStatusDataSent');
      });
      hubConnection.on('muted', function (message: MutePatientMessage) {
        events.emit('muted', message.muted);
      });
      hubConnection.on('emdrStarted', function () {
        internalEmitEvent('emdrStarted');
      });
      hubConnection.on('whiteboardStarted', function () {
        internalEmitEvent('whiteboardStarted');
      });
      hubConnection.on('PaymentRequestCancelled', function () {
        internalEmitEvent('paymentRequestCancelled');
      });
      hubConnection.on('PaymentRequestSucceeded', function () {
        internalEmitEvent('paymentRequestSucceeded');
      });
      // TODO: use internalEmitEvent instead events
      hubConnection.on('PaymentRequestCreated', function (message: PaymentRequestCreatedMessage) {
        events.emit('paymentRequestCreated', message);
      });
      hubConnection.on('updatedUnavailableProviderStatus', function (message: UpdatedUnavailableProviderStatusMessage) {
        events.emit('updatedUnavailableProviderStatus', message.unavailable);
      });

      if (user) {
        hubConnection.on('patientsListSent', function (data: HubPatientQueueResponse) {
          dispatch(setPatients(data.patients));
        });
        hubConnection.on('userJoined', function (data: HubPatient) {
          if (user?.clinicUser.id !== data.userName) {
            events.emit('userJoined', data);
            dispatch(addPatient(data));
          }
        });
        hubConnection.on('userLeft', function (data: HubPatient) {
          dispatch(removePatient(data));
        });
      }
    }
  };

  const getPatientsList = useCallback((): void => {
    signalrConnection.current!.invoke('GetPatientsList');
  }, [signalrConnection]);

  const getIsProviderConnectedToQueue = useCallback((): void => {
    signalrConnection.current!.invoke('GetIsProviderConnectedToQueue');
  }, [signalrConnection]);

  const callEnded = useCallback((): void => {
    internalEmitEvent('callEnded');
  }, []);

  const getToken = useCallback(
    async ({ identity, roomId }: { identity: string; roomId: string }): Promise<string> => {
      setIsFetching(true);
      return signalrConnection
        .current!.invoke('GetToken', identity, roomId)
        .then((token: string) => {
          setIsFetching(false);
          return token;
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log('getToken failed: ', e);
          setIsFetching(false);
          return '';
        });
    },
    [signalrConnection],
  );

  const getChatToken = useCallback(
    async (connectionId: string): Promise<string> => {
      return signalrConnection.current
        ?.invoke('GetChatToken', connectionId)
        .then((token) => {
          return token;
        })
        .catch((error: any) => {
          // eslint-disable-next-line no-console
          console.log('getChatToken failed: ', error);
        });
    },
    [signalrConnection],
  );

  const invitePatient = useCallback(
    ({ connectionId, roomId }: { connectionId: string; roomId: string }): void => {
      signalrConnection.current?.invoke('InvitePatient', { connectionId, roomId });
    },
    [signalrConnection],
  );

  const removeParticipantFromCall = useCallback(
    (connectionId: string): void => {
      signalrConnection.current?.invoke('RemoveParticipantFromCall', connectionId);
    },
    [signalrConnection],
  );

  const mutePatientInCall = useCallback(
    (connectionId: string, muted: boolean): void => {
      signalrConnection.current?.invoke('MutePatient', { patientConnectionId: connectionId, muted });
    },
    [signalrConnection],
  );

  const updateCallData = useCallback(
    ({ callAccesses }: { callAccesses: CallAccessAttributes }): void => {
      signalrConnection.current?.invoke('UpdateCallData', {
        callAccess: callAccesses,
        deviceConfigs: {
          type,
          operationSystem: os,
          browser,
        },
      });
    },
    [signalrConnection],
  );

  const updateUnavailableProviderStatus = useCallback(
    (unavailable: boolean): void => {
      signalrConnection.current?.invoke('UpdateUnavailableProviderStatus', unavailable);
    },
    [signalrConnection],
  );

  const removePatientFromQueue = useCallback(
    (connectionId: string): void => {
      signalrConnection.current?.invoke('RemovePatientFromQueue', connectionId);
    },
    [signalrConnection],
  );

  const startEmdr = useCallback((): void => {
    signalrConnection.current?.invoke('EmdrStart');
  }, [signalrConnection]);

  const startWhiteboard = useCallback((): void => {
    signalrConnection.current?.invoke('WhiteboardStart');
  }, [signalrConnection]);

  const cancelRequestPaymentByProvider = useCallback((): void => {
    signalrConnection.current?.invoke('CancelRequestPayment');
  }, []);

  const cancelRequestPaymentByClient = useCallback((): void => {
    signalrConnection.current?.invoke('CancelRequestPaymentByClient');
  }, []);

  const setSuccessRequestPayment = useCallback((): void => {
    signalrConnection.current?.invoke('SetSuccessPayment');
  }, []);

  const disconnect = useCallback((): void => {
    signalrConnection.current?.stop();
    signalrConnection.current = null;
    setSignalrConnectionId('');
  }, []);

  return (
    <SignalrConnectionContext.Provider
      value={{
        getPatientsList,
        getIsProviderConnectedToQueue,
        getToken,
        getChatToken,
        invitePatient,
        removeParticipantFromCall,
        mutePatientInCall,
        updateCallData,
        updateUnavailableProviderStatus,
        removePatientFromQueue,
        startEmdr,
        startWhiteboard,
        cancelRequestPaymentByClient,
        cancelRequestPaymentByProvider,
        setSuccessRequestPayment,
        disconnect,
        isFetching,
        events,
        signalrConnectionId,
        callEnded,
        addEventListener,
        removeEventListener,
        removeAllEventListeners,
      }}
    >
      {children}
    </SignalrConnectionContext.Provider>
  );
}
