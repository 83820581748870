/* eslint-disable prettier/prettier */
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Theme,
  Typography,
  Box,
  useMediaQuery,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import {
  ArrowBackIcon,
  ArrowForwardIcon,
  ChevronRightIcon,
  FirstPageIcon,
  LastPageIcon,
  RoundedPlayIcon,
} from 'assets';
import { AINoteItem, AINoteItemStatusEnum, ApiUser, GetAINotesResponse } from 'types';
import { useApiRequests, useDialog } from 'hooks';
import { getUser, selectUser } from 'store/user';
import { useAppDispatch, useAppSelector } from 'store';
import { ADMIN, CLINIC_ADMIN } from 'constants/roles';
import { Spinner, BackButton, SuccessInfoDialog, TwoOptionsDialog } from 'components';
import { FormHeader } from '../FormHeader';
import { PurchaseCreditsPopup } from './components';

const rowsPerPageOptions: number[] = [10, 15, 20];

type AINotesSettings = {
  onBackToSettingsClick(): void;
};

export function AINotesSettings({ onBackToSettingsClick }: AINotesSettings) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { showDialog } = useDialog();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { getAINotes, downloadNote, processTooShortRecording, processStoppedNotByUserRecording } = useApiRequests();
  const user: ApiUser = useAppSelector(selectUser)!;

  const [loading, setLoading] = useState<boolean>(true);
  const [purchaseCreditsPopupOpen, setPurchaseCreditsPopupOpen] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [page, setPage] = useState<number>(1);
  const [totalPagesCount, setTotalPagesCount] = useState<number>(1);
  const [items, setItems] = useState<AINoteItem[]>([]);

  const aRef = useRef<HTMLAnchorElement>(null);

  const isAdmin: boolean = useMemo(() => {
    return user?.clinicUser.roles.some((role: string) => role === ADMIN || role === CLINIC_ADMIN) ?? false;
  }, [user]);

  const paginationText: string = useMemo(() => {
    return `${page} of ${totalPagesCount}`;
  }, [page, totalPagesCount]);

  const getFormatedStartDate = (date: string): string => {
    return moment(date).format('MMM D, YYYY');
  };

  const getFormatedTime = (
    date: string,
    options: { useTwelveHourFormat: boolean } = { useTwelveHourFormat: false },
  ): string => {
    const format = options.useTwelveHourFormat ? 'hh:mma' : 'HH:mma';
    return moment(date).format(format);
  };

  const getFormatedTimeDiff = (startDate: string, endDate: string): string => {
    const hours = moment(endDate).diff(moment(startDate), 'hours');
    const minutes = moment(endDate).diff(moment(startDate), 'minutes') % 60;
    const seconds = moment(endDate).diff(moment(startDate), 'seconds') % 60;

    const hoursString = hours < 10 ? '0' + hours : hours.toString();
    const minutesString = minutes < 10 ? '0' + minutes : minutes.toString();
    const secondsString = seconds < 10 ? '0' + seconds : seconds.toString();

    return `${hours ? hoursString + ':' : ''}${minutesString}:${secondsString}`;
  };

  const fetchItems = (page: number, rowsPerPage: number) => {
    setLoading(true);

    getAINotes(page, rowsPerPage)
      .then((response: GetAINotesResponse) => {
        setPage(response.page);
        setRowsPerPage(response.count);
        setTotalPagesCount(response.pagesCount);
        setItems(response.items);
      })
      .catch((e) => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchItems(1, 20);
  }, []);

  const handleRowsPerPageChange = (event: SelectChangeEvent<string>): void => {
    const newRowsPerPage: number = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    fetchItems(1, newRowsPerPage);
  };

  const handleFirstPageClick = (): void => {
    if (page === 1) {
      return;
    }

    fetchItems(1, rowsPerPage);
  };

  const handleLastPageClick = (): void => {
    if (page === totalPagesCount) {
      return;
    }

    fetchItems(totalPagesCount, rowsPerPage);
  };

  const handlePreviousPageClick = (): void => {
    if (page === 1) {
      return;
    }

    fetchItems(page - 1, rowsPerPage);
  };

  const handleNextPageClick = (): void => {
    if (page === totalPagesCount) {
      return;
    }

    fetchItems(page + 1, rowsPerPage);
  };

  const handleAddCreditsClick = (): void => {
    setPurchaseCreditsPopupOpen(true);
  };

  const handleClose = (): void => {
    setPurchaseCreditsPopupOpen(false);
  };

  const handlePurchased = (itemsCreditsCount: number): void => {
    setPurchaseCreditsPopupOpen(false);
    const modal = showDialog(SuccessInfoDialog, {
      buttonText: 'Close',
      title: 'Payment successful!',
      body: (
        <Typography variant='body2'>
          <b>{itemsCreditsCount}</b> AI credits have been added to your organization and are ready for use.
        </Typography>
      ),
      onConfirm: () => {
        modal.destroy();
      },
    });

    dispatch(getUser()).unwrap();
  };

  const handleNoCard = (): void => {
    setPurchaseCreditsPopupOpen(false);
    setLoading(true);
    dispatch(getUser())
      .unwrap()
      .finally(() => setLoading(false));
    enqueueSnackbar('No card', { variant: 'error' });
  };

  const handleViewError = (item: AINoteItem) => () => {
    if (item.status === AINoteItemStatusEnum.Error) {
      enqueueSnackbar(item.errorMessage, { variant: 'error' });
      return;
    }

    if (item.status === AINoteItemStatusEnum.TooShortRecordingError) {
      const modal = showDialog(TwoOptionsDialog, {
        title: 'Audio Recording Too Short',
        text: 'Would you like to start processing this call?',
        confirmText: 'Yes',
        sx: { '.MuiPaper-root': { maxWidth: '300px !important' } },
        onConfirm: () => {
          processTooShortRecording(item.id)
            .then(() => {
              fetchItems(page, rowsPerPage);
            })
            .catch((e) => enqueueSnackbar(e.message, { variant: 'error' }));
          modal.destroy();
        },
      });
    }

    if (item.status === AINoteItemStatusEnum.RecordingStoppedNotByUserError) {
      const modal = showDialog(TwoOptionsDialog, {
        title: 'Something Went Wrong',
        text: 'We noticed your call may have ended abruptly. Do you want us to attempt to reprocess this call?',
        confirmText: 'Yes, try again',
        sx: { '.MuiPaper-root': { maxWidth: '300px !important' } },
        onConfirm: () => {
          processStoppedNotByUserRecording(item.id)
            .then(() => {
              fetchItems(page, rowsPerPage);
            })
            .catch((e) => enqueueSnackbar(e.message, { variant: 'error' }));
          modal.destroy();
        },
      });
    }
  };

  const handleDownload = (item: AINoteItem, isPdf: boolean) => () => {
    setLoading(true);

    downloadNote(item.id, isPdf)
      .then((response: any) => {
        const blob = new Blob([response], {
          type: isPdf ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        });
        const date = moment().format('YYYY_MM_DD_hh_mm_A');
        const fileName = `Progress_Note_${date}.${isPdf ? 'pdf' : 'docx'}`;

        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(downloadUrl);
      })
      .catch((error) => {
        enqueueSnackbar(`Download report error: ${error.message}`);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <a ref={aRef} download='AINote.docx' target='_blank' hidden />

      {isMobile && <BackButton text='Back to Settings' onClick={onBackToSettingsClick} />}

      <Stack
        sx={{
          height: '100%',
          borderRadius: '1rem',
          background: '#ffffff',
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
        }}
      >
        <FormHeader
          items={[{ text: 'AI PROGRESS NOTES' }]}
          rightSlot={
            <Button
              href='https://www.youtube.com/watch?v=lw5w7k-jwos&feature=youtu.be'
              target='_blank'
              sx={{
                marginRight: '16px',
                color: '#393534',
                fontSize: 12,
                fontWeight: 600,
                lineHeight: '16.34px',
                '&:hover': {
                  color: '#393534 !important',
                },
              }}
            >
              <RoundedPlayIcon
                sx={{
                  fontSize: 16,
                  marginRight: '7px',
                }}
              />
              How It Works
            </Button>
          }
        />

        {loading ? (
          <Spinner small />
        ) : (
          <Stack
            sx={{
              height: '100%',
              padding: 3,
              display: 'grid',
              gap: '2rem',
              gridTemplateRows: 'auto 1fr auto',
            }}
          >
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Typography variant='body2'>
                <b>{user.clinicUser.aiCreditsCount}</b> Total Available Credits
              </Typography>

              {isAdmin && (
                <Button variant='contained' disabled={loading} onClick={handleAddCreditsClick}>
                  Add Credits
                </Button>
              )}
            </Stack>

            <Stack sx={{ overflowX: 'scroll' }}>
              <Stack sx={{ width: 'auto', minWidth: '700px' }}>
                <Stack
                  sx={{
                    paddingBlock: '8px',
                    display: 'grid',
                    gridTemplateColumns: '9fr 10fr 7fr 7fr 8fr 9fr 11.5rem',
                    borderTop: '1px solid #D1D1D1',
                    borderBottom: '1px solid #D1D1D1',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant='subtitle2' component='span'>
                    Date
                  </Typography>

                  <Typography variant='subtitle2' component='span'>
                    Room
                  </Typography>

                  <Typography variant='subtitle2' component='span'>
                    Start Time
                  </Typography>

                  <Typography variant='subtitle2' component='span'>
                    End Time
                  </Typography>

                  <Typography variant='subtitle2' component='span' sx={{ textAlign: 'center' }}>
                    Duration
                  </Typography>

                  <Typography variant='subtitle2' component='span' sx={{ textAlign: 'center' }}>
                    # Participants
                  </Typography>

                  <Typography variant='subtitle2' component='span' sx={{ textAlign: 'center' }}>
                    Status
                  </Typography>
                </Stack>

                {!!items.length &&
                  items.map((item: AINoteItem, index: number) => (
                    <Stack
                      key={index}
                      sx={(theme) => ({
                        height: 'auto',
                        paddingBlock: '8px',
                        display: 'grid',
                        gridTemplateColumns: '9fr 10fr 7fr 7fr 8fr 9fr 11.5rem',
                        borderBottom: '1px solid #D1D1D1',
                        alignItems: 'center',
                        [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
                          height: '5.3rem',
                        },
                      })}
                    >
                      <Typography variant='body1' component='span'>
                        {getFormatedStartDate(item.startedAt)}
                      </Typography>

                      <Typography variant='body1' component='span'>
                        /{item.room}
                      </Typography>

                      <Typography variant='body1' component='span'>
                        {getFormatedTime(item.startedAt, { useTwelveHourFormat: true })}
                      </Typography>

                      <Typography variant='body1' component='span'>
                        {getFormatedTime(item.endedAt, { useTwelveHourFormat: true })}
                      </Typography>

                      <Typography variant='body1' component='span' sx={{ textAlign: 'center' }}>
                        {getFormatedTimeDiff(item.startedAt, item.endedAt)}
                      </Typography>

                      <Typography variant='body1' component='span' sx={{ textAlign: 'center' }}>
                        {item.participantsCount}
                      </Typography>

                      {item.status === AINoteItemStatusEnum.NotStarted && (
                        <Typography variant='body1' component='span' sx={{ textAlign: 'center' }}>
                          Not started...
                        </Typography>
                      )}

                      {item.status === AINoteItemStatusEnum.Processing && (
                        <Typography variant='body1' component='span' sx={{ textAlign: 'center' }}>
                          Processing...
                        </Typography>
                      )}

                      {(item.status === AINoteItemStatusEnum.Error ||
                        item.status === AINoteItemStatusEnum.TooShortRecordingError ||
                        item.status === AINoteItemStatusEnum.RecordingStoppedNotByUserError) && (
                        <Stack sx={{ width: '100%' }}>
                          <Button
                            variant='contained'
                            sx={{
                              width: '100%',
                              height: '2.4rem',
                              backgroundColor: '#FF3D3D',
                              ':hover': {
                                backgroundColor: '#e41919',
                              },
                            }}
                            onClick={handleViewError(item)}
                          >
                            View Error
                          </Button>
                        </Stack>
                      )}

                      {item.status === AINoteItemStatusEnum.Compleated && (
                        <Stack sx={{ width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 1 }}>
                          <Button
                            variant='outlined'
                            sx={{
                              height: '2.4rem',
                              minWidth: '1rem',
                              padding: 0,
                              fontSize: 10,
                            }}
                            onClick={handleDownload(item, true)}
                          >
                            .PDF
                          </Button>

                          <Button
                            variant='contained'
                            sx={{
                              height: '2.4rem',
                              minWidth: '1rem',
                              padding: 0,
                              fontSize: 10,
                            }}
                            onClick={handleDownload(item, false)}
                          >
                            .DOC
                          </Button>
                        </Stack>
                      )}
                    </Stack>
                  ))}

                {!items.length && (
                  <>
                    <Box
                      sx={{
                        maxWidth: 510,
                        marginBlock: '3.2rem',
                        padding: '12px',
                        border: '1px solid #D1D1D1',
                        borderRadius: '3px',
                      }}
                    >
                      <Typography variant='body1'>You have no reports to view.</Typography>
                    </Box>

                    <Stack
                      direction='column'
                      alignItems='flex-start'
                      justifyContent='space-between'
                      sx={{
                        gap: '14px',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 15,
                          fontWeight: 700,
                          lineHeight: '20.43px',
                          textAlign: 'left',
                        }}
                      >
                        See How It Works!
                      </Typography>
                      <iframe
                        width='508'
                        height='287'
                        src='https://www.youtube.com/embed/lw5w7k-jwos?si=gFXDdubfotBqY0HU'
                        title='YouTube video player'
                        frameBorder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                        referrerPolicy='strict-origin-when-cross-origin'
                      ></iframe>
                    </Stack>
                  </>
                )}
              </Stack>
            </Stack>

            <Stack
              sx={{
                display: 'grid',
                gridTemplateColumns: 'auto auto auto',
                gap: '3rem',
                margin: 'auto',
                alignItems: 'center',
              }}
            >
              <Stack direction='row' alignItems='center' gap='1rem'>
                <Typography variant='body1'>Rows per page</Typography>

                <Select
                  variant='outlined'
                  value={rowsPerPage.toString()}
                  onChange={handleRowsPerPageChange}
                  IconComponent={ChevronRightIcon}
                  sx={{
                    height: '3rem',
                    '.MuiSelect-select': {
                      fontSize: '1.2rem',
                    },
                    svg: {
                      width: '1rem',
                    },
                  }}
                >
                  {rowsPerPageOptions.map((option: number) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>

              <Typography variant='body1'>{paginationText}</Typography>

              <Stack
                direction='row'
                sx={{
                  '> button': {
                    height: '3.2rem',
                    width: '3.2rem',
                    padding: '0',
                  },
                  svg: {
                    fontSize: '3.2rem',
                  },
                }}
              >
                <IconButton onClick={handleFirstPageClick}>
                  <FirstPageIcon />
                </IconButton>
                <IconButton onClick={handlePreviousPageClick}>
                  <ArrowBackIcon />
                </IconButton>
                <IconButton onClick={handleNextPageClick}>
                  <ArrowForwardIcon />
                </IconButton>
                <IconButton onClick={handleLastPageClick}>
                  <LastPageIcon />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>

      {isAdmin && (
        <PurchaseCreditsPopup
          open={purchaseCreditsPopupOpen}
          onClose={handleClose}
          onPurchased={handlePurchased}
          onNoCard={handleNoCard}
        />
      )}
    </>
  );
}
