import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { ChevronRightIcon } from 'assets';
import { useAppSettings, useDevices } from 'hooks';

export function AudioOutputList() {
  const { audioOutputDevices } = useDevices();
  const { activeSinkId, setActiveSinkId } = useAppSettings();
  const activeOutputLabel = audioOutputDevices.find((device) => device.deviceId === activeSinkId)?.label;

  const isDisabled = audioOutputDevices.length <= 1;

  return (
    <>
      <Select
        fullWidth
        variant='outlined'
        disabled={isDisabled}
        IconComponent={ChevronRightIcon}
        onChange={(e) => setActiveSinkId(e.target.value as string)}
        value={!isDisabled ? activeSinkId : 'default'}
        sx={{
          svg: {
            width: '10px',
          },
        }}
      >
        {audioOutputDevices.length > 1 ? (
          audioOutputDevices.map((device) => (
            <MenuItem value={device.deviceId} key={device.deviceId}>
              {device.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem value='default'>{activeOutputLabel || 'System Default Audio Output'}</MenuItem>
        )}
      </Select>
    </>
  );
}
