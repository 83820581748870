import { Button, DialogContent, Stack, Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';

import styles from './DuplicateEmailDialog.module.scss';

export interface DuplicateEmailDialogProps extends DialogProps {
  onConfirm: () => void;
  onClose?: () => void;
}

export function DuplicateEmailDialog({ onClose, onConfirm, ...rest }: DuplicateEmailDialogProps) {
  return (
    <Dialog {...rest} className={styles.dialog}>
      <DialogContent>
        <Typography component='p' variant='body5'>
          There is already an account for this email address.
          <br />
          Would you like to set up a new organization under the same account?
        </Typography>

        <Stack direction='row' spacing={'10px'} alignItems='center'>
          <Button variant='outlined' fullWidth className={styles.formButton} onClick={onClose}>
            Cancel
          </Button>
          <Button variant='contained' fullWidth className={styles.formButton} onClick={onConfirm}>
            Yes
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
