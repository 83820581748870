import { Box } from '@mui/material';
import classnames from 'classnames';

import { ModalTitle } from 'components';
import { useRoomRightSidebar } from 'hooks';

import styles from './MenuItem.module.scss';

type MenuItemProps = {
  children: JSX.Element;
  className?: string;
  title: string;
};

export function MenuItem({ className, title, children }: MenuItemProps): JSX.Element {
  const { hideSidebar } = useRoomRightSidebar();

  return (
    <>
      <Box className={classnames(styles.menu, className)}>
        <ModalTitle title={title} onClose={hideSidebar} />
        <div className={styles.scrolableContainer}>{children}</div>
      </Box>
    </>
  );
}
