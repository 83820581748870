import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function SettingsIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M0.0209656 5.74901C0.339964 4.76669 0.865448 3.86498 1.55882 3.10081C1.67606 2.97159 1.86001 2.92552 2.0247 2.98416L3.68171 3.57409C4.13094 3.73394 4.62526 3.50091 4.78579 3.0536C4.80158 3.00961 4.81374 2.96443 4.82214 2.91855L5.13782 1.19323C5.16921 1.02169 5.30152 0.885993 5.47281 0.849684C5.97398 0.743473 6.48782 0.689453 7.00841 0.689453C7.52867 0.689453 8.04221 0.743412 8.54309 0.849489C8.71433 0.885762 8.84663 1.02137 8.87809 1.19285L9.19466 2.91848C9.28025 3.38578 9.73003 3.69551 10.1994 3.6103C10.2456 3.60191 10.2909 3.58981 10.3351 3.57411L11.9921 2.98416C12.1568 2.92552 12.3407 2.97159 12.4579 3.10081C13.1514 3.86498 13.6768 4.76669 13.9959 5.74901C14.0496 5.91473 13.9977 6.09641 13.8644 6.20913L12.5213 7.34451C12.1577 7.65197 12.1133 8.19474 12.422 8.55682C12.4523 8.59242 12.4856 8.6255 12.5213 8.65572L13.8644 9.79112C13.9977 9.90384 14.0496 10.0855 13.9959 10.2512C13.6768 11.2336 13.1514 12.1353 12.4579 12.8994C12.3407 13.0286 12.1568 13.0747 11.9921 13.0161L10.3351 12.4261C9.88592 12.2664 9.39156 12.4994 9.23102 12.9466C9.21524 12.9906 9.20307 13.0358 9.19464 13.0819L8.87809 14.8074C8.84663 14.9789 8.71433 15.1144 8.54309 15.1508C8.04221 15.2568 7.52867 15.3108 7.00841 15.3108C6.48782 15.3108 5.97398 15.2568 5.47281 15.1505C5.30152 15.1142 5.16921 14.9785 5.13782 14.8071L4.82215 13.0818C4.73656 12.6145 4.28673 12.3047 3.81741 12.3899C3.77125 12.3984 3.72588 12.4104 3.68178 12.4261L2.0247 13.0161C1.86001 13.0747 1.67606 13.0286 1.55882 12.8994C0.865448 12.1353 0.339964 11.2336 0.0209656 10.2512C-0.0328468 10.0855 0.0190396 9.90384 0.152394 9.79112L1.49545 8.65573C1.85909 8.34828 1.90357 7.80551 1.5948 7.44342C1.56444 7.40782 1.53121 7.37474 1.49546 7.34453L0.152394 6.20913C0.0190396 6.09641 -0.0328468 5.91473 0.0209656 5.74901ZM4.87527 8.00005C4.87527 9.17807 5.83024 10.133 7.00826 10.133C8.18628 10.133 9.14125 9.17807 9.14125 8.00005C9.14125 6.82203 8.18628 5.86706 7.00826 5.86706C5.83024 5.86706 4.87527 6.82203 4.87527 8.00005Z'
        fill='#626363'
      />
    </SvgIcon>
  );
}
