/* eslint-disable prettier/prettier */
import classnames from 'classnames';
import { Card, CardContent, CardHeader, IconButton } from '@mui/material';

import { useChatState, useChat } from 'hooks';
import { CrossIcon } from 'assets';
import { MessagesList } from './MessagesList';
import { ChatInput } from './ChatInput';

import { ApiUser, ApiWaitingRoomLinkProfile } from 'types';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { useMemo } from 'react';
import { selectWaitingRoomProfile } from 'store/waitingRoom';
import styles from './Chat.module.scss';

type ChatProperties = {
  className?: string;
  shouldIgnoreReceiveMessages?: boolean;
};

export function Chat({ className, shouldIgnoreReceiveMessages = true }: ChatProperties): JSX.Element {
  const { chatName } = useChatState();
  const { conversation, closeChat } = useChat();
  const user: ApiUser | null = useAppSelector(selectUser);
  const waitingRoomProfile: ApiWaitingRoomLinkProfile | null = useAppSelector(selectWaitingRoomProfile);

  const { preferedDisplayName } = waitingRoomProfile || {};

  const preferredChatName = useMemo(() => {
    return preferedDisplayName && preferedDisplayName !== '' ? preferedDisplayName : chatName;
  }, [chatName, preferedDisplayName]);

  const shouldDisplayChat: boolean = useMemo(() => {
    const hasConversation = !!conversation;
    const canReceiveMessages = !user || (user?.clinicUser.receiveMessages || !shouldIgnoreReceiveMessages);

    return hasConversation && canReceiveMessages;
  }, [conversation, user, shouldIgnoreReceiveMessages]);

  const handleTyping = (): void => {
    conversation?.typing();
  };

  const handleSendMessage = (message: string | FormData): Promise<number> => {
    return conversation!.sendMessage(message);
  };

  return (
    <>
      {shouldDisplayChat && (
        <>
          <Card className={classnames(styles.chat, className)}>
            <CardHeader
              title={preferredChatName}
              action={
                <IconButton edge='end' aria-label='close' onClick={closeChat}>
                  <CrossIcon className={styles.close} color='primary' />
                </IconButton>
              }
            />
            <CardContent>
              <MessagesList isGroup={false} />
              <ChatInput isGroup={false} onTyping={handleTyping} sendMessage={handleSendMessage} />
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
}
