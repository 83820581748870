import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function TabletIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='82' height='100' viewBox='0 0 82 100' fill='none' {...props}>
      <path
        d='M68.4 4H13.2C8.11898 4 4 8.11898 4 13.2V86.8C4 91.881 8.11898 96 13.2 96H68.4C73.481 96 77.6 91.881 77.6 86.8V13.2C77.6 8.11898 73.481 4 68.4 4Z'
        stroke='#626363'
        strokeWidth='8'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M36.2002 82.2H45.4002'
        stroke='#626363'
        strokeWidth='8'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}
