import { MutableRefObject, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import uuid from 'react-uuid';

import { IWhiteboardContext } from 'context';
import { useScreenSize, useWhiteboard } from 'hooks';
import { WhiteboardFigureItemData, WhiteboardFigureTypeEnum, WhiteboardItemTypeEnum } from 'types';

const MIN_FIGURE_SIZE = 5;
const TRANSFORM_RECTANGLE_RESIZE_ELEMENT_SIZE = 8;
const TRANSFORM_RECTANGLE_RESIZE_ELEMENT_CLICKABLE_PADDING = 10;

type FigureTransformType = 'resize' | 'move' | null;
type FigureTransformResizeItem = 'X1Y1' | 'X1Y2' | 'X2Y1' | 'X2Y2';

export type DrawFigureProps = {
  ctx: MutableRefObject<CanvasRenderingContext2D | null>;
  canvasRef: RefObject<HTMLCanvasElement>;
  zoom: number;
  canvasPosition: number[];
  selectedFigure: WhiteboardFigureTypeEnum;
  selectedLineSize: number;
  selectedColor: string;
  isDrawFigureSelected: boolean;
};

export type DrawFigureType = {
  drawingFigureData: number[] | null;
  drawFigure(figureData: WhiteboardFigureItemData): void;
  drawTransformingRectangle(): void;
};

export function useDrawFigure({
  ctx,
  canvasRef,
  zoom,
  canvasPosition,
  selectedFigure,
  selectedLineSize,
  selectedColor,
  isDrawFigureSelected,
}: DrawFigureProps): DrawFigureType {
  const { isTouchDevice } = useScreenSize();
  const { addItem }: IWhiteboardContext = useWhiteboard();

  const [drawingFigureData, setDrawingFigureData] = useState<number[] | null>(null);
  const [isFigureTransform, setIsFigureTransform] = useState<boolean>(false);

  // figure data is coords of top-left figure edge (x1, y1)
  // and bottom-right figure edge (x2, y2) in array format: [x1, y1, x2, y2]
  const isDrawing = useRef<boolean>(false);
  const currentFigureId = useRef<string | null>(null);
  const figureData = useRef<number[]>([]);
  const figureTransformData = useRef<number[]>([]);
  const figureTransformType = useRef<FigureTransformType>(null);
  const figureTransformResizeItem = useRef<FigureTransformResizeItem | null>(null);

  const getIsClickInsideTransformRectangle = useCallback(
    (x: number, y: number, x1: number, y1: number, x2: number, y2: number): boolean => {
      return (x1 > x2 ? x >= x2 && x <= x1 : x >= x1 && x <= x2) && (y1 > y2 ? y >= y2 && y <= y1 : y >= y1 && y <= y2);
    },
    [],
  );

  const getIsClickInsideTransformResizeItems = useCallback(
    (
      x: number,
      y: number,
      x1: number,
      y1: number,
      x2: number,
      y2: number,
      figureTransformResizeItem?: FigureTransformResizeItem,
    ): boolean => {
      const insideX1Y1Item: boolean =
        x >=
          x1 -
            (TRANSFORM_RECTANGLE_RESIZE_ELEMENT_SIZE + TRANSFORM_RECTANGLE_RESIZE_ELEMENT_CLICKABLE_PADDING) / zoom &&
        x <= x1 + TRANSFORM_RECTANGLE_RESIZE_ELEMENT_CLICKABLE_PADDING / zoom &&
        y >=
          y1 -
            (TRANSFORM_RECTANGLE_RESIZE_ELEMENT_SIZE + TRANSFORM_RECTANGLE_RESIZE_ELEMENT_CLICKABLE_PADDING) / zoom &&
        y <= y1 + TRANSFORM_RECTANGLE_RESIZE_ELEMENT_CLICKABLE_PADDING / zoom;

      if (figureTransformResizeItem === 'X1Y1') {
        return insideX1Y1Item;
      }

      const insideX1Y2Item: boolean =
        x >=
          x1 -
            (TRANSFORM_RECTANGLE_RESIZE_ELEMENT_SIZE + TRANSFORM_RECTANGLE_RESIZE_ELEMENT_CLICKABLE_PADDING) / zoom &&
        x <= x1 + TRANSFORM_RECTANGLE_RESIZE_ELEMENT_CLICKABLE_PADDING / zoom &&
        y >= y2 - TRANSFORM_RECTANGLE_RESIZE_ELEMENT_CLICKABLE_PADDING / zoom &&
        y <=
          y2 + (TRANSFORM_RECTANGLE_RESIZE_ELEMENT_SIZE + TRANSFORM_RECTANGLE_RESIZE_ELEMENT_CLICKABLE_PADDING) / zoom;

      if (figureTransformResizeItem === 'X1Y2') {
        return insideX1Y2Item;
      }

      const insideX2Y1Item: boolean =
        x >= x2 - TRANSFORM_RECTANGLE_RESIZE_ELEMENT_CLICKABLE_PADDING / zoom &&
        x <=
          x2 +
            (TRANSFORM_RECTANGLE_RESIZE_ELEMENT_SIZE + TRANSFORM_RECTANGLE_RESIZE_ELEMENT_CLICKABLE_PADDING) / zoom &&
        y >=
          y1 -
            (TRANSFORM_RECTANGLE_RESIZE_ELEMENT_SIZE + TRANSFORM_RECTANGLE_RESIZE_ELEMENT_CLICKABLE_PADDING) / zoom &&
        y <= y1 + TRANSFORM_RECTANGLE_RESIZE_ELEMENT_CLICKABLE_PADDING / zoom;

      if (figureTransformResizeItem === 'X2Y1') {
        return insideX2Y1Item;
      }

      const insideX2Y2Item: boolean =
        x >= x2 - TRANSFORM_RECTANGLE_RESIZE_ELEMENT_CLICKABLE_PADDING / zoom &&
        x <=
          x2 +
            (TRANSFORM_RECTANGLE_RESIZE_ELEMENT_SIZE + TRANSFORM_RECTANGLE_RESIZE_ELEMENT_CLICKABLE_PADDING) / zoom &&
        y >= y2 - TRANSFORM_RECTANGLE_RESIZE_ELEMENT_CLICKABLE_PADDING / zoom &&
        y <=
          y2 + (TRANSFORM_RECTANGLE_RESIZE_ELEMENT_SIZE + TRANSFORM_RECTANGLE_RESIZE_ELEMENT_CLICKABLE_PADDING) / zoom;

      if (figureTransformResizeItem === 'X2Y2') {
        return insideX2Y2Item;
      }

      return insideX1Y1Item || insideX1Y2Item || insideX2Y1Item || insideX2Y2Item;
    },
    [zoom],
  );

  const getClickedTransformResizeItem = useCallback(
    (x: number, y: number, x1: number, y1: number, x2: number, y2: number): FigureTransformResizeItem => {
      if (getIsClickInsideTransformResizeItems(x, y, x1, y1, x2, y2, 'X1Y1')) {
        return 'X1Y1';
      }

      if (getIsClickInsideTransformResizeItems(x, y, x1, y1, x2, y2, 'X1Y2')) {
        return 'X1Y2';
      }

      if (getIsClickInsideTransformResizeItems(x, y, x1, y1, x2, y2, 'X2Y1')) {
        return 'X2Y1';
      }

      if (getIsClickInsideTransformResizeItems(x, y, x1, y1, x2, y2, 'X2Y2')) {
        return 'X2Y2';
      }

      return 'X1Y1';
    },
    [getIsClickInsideTransformResizeItems],
  );

  const drawRectangle = useCallback((x1: number, y1: number, x2: number, y2: number): void => {
    const rectangle = new Path2D();
    rectangle.rect(x1, y1, x2 - x1, y2 - y1);
    ctx.current!.stroke(rectangle);
  }, []);

  const drawCircle = useCallback((x1: number, y1: number, x2: number, y2: number): void => {
    const centerX = (x1 + x2) / 2;
    const centerY = (y1 + y2) / 2;
    const radiusX = Math.abs(x1 - x2) / 2;
    const radiusY = Math.abs(y1 - y2) / 2;
    const ellipse = new Path2D();
    ellipse.ellipse(centerX, centerY, radiusX, radiusY, 0, 0, Math.PI * 2);
    ctx.current!.stroke(ellipse);
  }, []);

  const drawTriangle = useCallback((x1: number, y1: number, x2: number, y2: number): void => {
    const _x1 = x1 < x2 ? x1 : x2;
    const _y1 = y1 < y2 ? y1 : y2;
    const _x2 = x1 < x2 ? x2 : x1;
    const _y2 = y1 < y2 ? y2 : y1;

    const triangle = new Path2D();
    triangle.moveTo(_x2, _y2);
    triangle.lineTo(_x1, _y2);
    triangle.lineTo((_x2 + _x1) / 2, _y1);
    triangle.lineTo(_x2, _y2);

    ctx.current!.stroke(triangle);
  }, []);

  const drawPentagon = useCallback((x1: number, y1: number, x2: number, y2: number): void => {
    const _x1 = x1 < x2 ? x1 : x2;
    const _y1 = y1 < y2 ? y1 : y2;
    const _x2 = x1 < x2 ? x2 : x1;
    const _y2 = y1 < y2 ? y2 : y1;

    const pentagon = new Path2D();
    pentagon.moveTo(_x2 - (_x2 - _x1) * 0.2, _y2);
    pentagon.lineTo(_x1 + (_x2 - _x1) * 0.2, _y2);
    pentagon.lineTo(_x1, _y1 + (_y2 - _y1) * 0.4);
    pentagon.lineTo((_x2 + _x1) / 2, _y1);
    pentagon.lineTo(_x2, _y1 + (_y2 - _y1) * 0.4);
    pentagon.lineTo(_x2 - (_x2 - _x1) * 0.2, _y2);

    ctx.current!.stroke(pentagon);
  }, []);

  const drawHexagon = useCallback((x1: number, y1: number, x2: number, y2: number): void => {
    const _x1 = x1 < x2 ? x1 : x2;
    const _y1 = y1 < y2 ? y1 : y2;
    const _x2 = x1 < x2 ? x2 : x1;
    const _y2 = y1 < y2 ? y2 : y1;

    const hexagon = new Path2D();
    hexagon.moveTo((_x2 + _x1) / 2, _y1);
    hexagon.lineTo(_x2, _y1 + (_y2 - _y1) * 0.25);
    hexagon.lineTo(_x2, _y1 + (_y2 - _y1) * 0.75);
    hexagon.lineTo((_x2 + _x1) / 2, _y2);
    hexagon.lineTo(_x1, _y1 + (_y2 - _y1) * 0.75);
    hexagon.lineTo(_x1, _y1 + (_y2 - _y1) * 0.25);
    hexagon.lineTo((_x2 + _x1) / 2, _y1);

    ctx.current!.stroke(hexagon);
  }, []);

  const drawHart = useCallback((x1: number, y1: number, x2: number, y2: number): void => {
    const _x1 = x1 < x2 ? x1 : x2;
    const _y1 = y1 < y2 ? y1 : y2;
    const _x2 = x1 < x2 ? x2 : x1;
    const _y2 = y1 < y2 ? y2 : y1;

    const width = _x2 - _x1;
    const height = _y2 - _y1;

    const hart = new Path2D();
    hart.moveTo((_x2 + _x1) / 2, _y2);
    hart.bezierCurveTo(
      _x1 + width * 0.24,
      _y2 - height * 0.24,
      _x1 + width * 0.2,
      _y2 - height * 0.24,
      _x1 + (_x2 - _x1) * 0.05,
      _y1 + (_y2 - _y1) * 0.5,
    );
    hart.bezierCurveTo(
      _x1 - width * 0.12,
      _y1 + height * 0.1,
      _x1 + width * 0.24,
      _y1 - height * 0.22,
      (_x2 + _x1) / 2,
      _y1 + (_y2 - _y1) * 0.2,
    );
    hart.bezierCurveTo(
      _x2 - width * 0.24,
      _y1 - height * 0.22,
      _x2 + width * 0.12,
      _y1 + height * 0.1,
      _x1 + (_x2 - _x1) * 0.95,
      _y1 + (_y2 - _y1) * 0.5,
    );
    hart.bezierCurveTo(
      _x2 - width * 0.2,
      _y2 - height * 0.24,
      _x2 - width * 0.24,
      _y2 - height * 0.24,
      (_x2 + _x1) / 2,
      _y2,
    );
    ctx.current!.stroke(hart);
  }, []);

  const drawStar = useCallback((x1: number, y1: number, x2: number, y2: number): void => {
    const _x1 = x1 < x2 ? x1 : x2;
    const _y1 = y1 < y2 ? y1 : y2;
    const _x2 = x1 < x2 ? x2 : x1;
    const _y2 = y1 < y2 ? y2 : y1;

    const width: number = _x2 - _x1;
    const height: number = _y2 - _y1;

    const star = new Path2D();
    star.moveTo(_x2 - width * 0.2, _y2);
    star.lineTo(_x1 + width * 0.5, _y2 - height * 0.2);
    star.lineTo(_x1 + width * 0.2, _y2);
    star.lineTo(_x1 + width * 0.3, _y2 - height * 0.4);
    star.lineTo(_x1, _y1 + height * 0.35);
    star.lineTo(_x1 + width * 0.35, _y1 + height * 0.3);
    star.lineTo(_x1 + width * 0.5, _y1);
    star.lineTo(_x2 - width * 0.35, _y1 + height * 0.3);
    star.lineTo(_x2, _y1 + height * 0.35);
    star.lineTo(_x2 - width * 0.3, _y2 - height * 0.4);
    star.lineTo(_x2 - width * 0.2, _y2);

    ctx.current!.stroke(star);
  }, []);

  const drawFlower = useCallback((x1: number, y1: number, x2: number, y2: number): void => {
    const _x1 = x1 < x2 ? x1 : x2;
    const _y1 = y1 < y2 ? y1 : y2;
    const _x2 = x1 < x2 ? x2 : x1;
    const _y2 = y1 < y2 ? y2 : y1;

    const width = _x2 - _x1;
    const height = _y2 - _y1;

    const flower = new Path2D();
    flower.moveTo(_x1 + width * 0.33, _y1 + height * 0.33);
    flower.bezierCurveTo(
      _x1 + width * 0.2,
      _y1 - height * 0.1,
      _x2 - width * 0.2,
      _y1 - height * 0.1,
      _x2 - width * 0.33,
      _y1 + height * 0.33,
    );

    flower.bezierCurveTo(
      _x2 - width * 0,
      _y1 - height * 0,
      _x2 + width * 0.15,
      _y2 - height * 0.3,
      _x2 - width * 0.2,
      _y2 - height * 0.37,
    );

    flower.bezierCurveTo(
      _x2 + width * 0.1,
      _y2 + height * 0,
      _x1 + width * 0.45,
      _y2 + height * 0.15,
      _x2 - width * 0.5,
      _y2 - height * 0.26,
    );

    flower.bezierCurveTo(
      _x2 - width * 0.45,
      _y2 + height * 0.15,
      _x1 - width * 0.1,
      _y2 + height * 0,
      _x1 + width * 0.2,
      _y2 - height * 0.37,
    );

    flower.bezierCurveTo(
      _x1 - width * 0.15,
      _y2 - height * 0.3,
      _x1 + width * 0,
      _y1 - height * 0,
      _x1 + width * 0.33,
      _y1 + height * 0.33,
    );

    ctx.current!.stroke(flower);
  }, []);

  const drawMessage = useCallback((x1: number, y1: number, x2: number, y2: number): void => {
    const _x1 = x1 < x2 ? x1 : x2;
    const _y1 = y1 < y2 ? y1 : y2;
    const _x2 = x1 < x2 ? x2 : x1;
    const _y2 = y1 < y2 ? y2 : y1;

    const width: number = _x2 - _x1;
    const height: number = _y2 - _y1;

    const message = new Path2D();
    message.moveTo(_x1 + width * 0.1, _y1);
    message.lineTo(_x2 - width * 0.1, _y1);
    message.bezierCurveTo(
      _x2 - width * 0.02,
      _y1 + height * 0.02,
      _x2 - width * 0.02,
      _y1 + height * 0.02,
      _x2,
      _y1 + height * 0.1,
    );
    message.lineTo(_x2, _y2 - height * 0.3);
    message.bezierCurveTo(
      _x2 - width * 0.02,
      _y2 - height * 0.22,
      _x2 - width * 0.02,
      _y2 - height * 0.22,
      _x2 - width * 0.1,
      _y2 - height * 0.2,
    );
    message.lineTo(_x2 - width * 0.45, _y2 - height * 0.2);
    message.lineTo(_x1 + width * 0.35, _y2);
    message.lineTo(_x1 + width * 0.35, _y2 - height * 0.2);
    message.lineTo(_x1 + width * 0.1, _y2 - height * 0.2);
    message.bezierCurveTo(
      _x1 + width * 0.02,
      _y2 - height * 0.22,
      _x1 + width * 0.02,
      _y2 - height * 0.22,
      _x1,
      _y2 - height * 0.3,
    );
    message.lineTo(_x1, _y1 + height * 0.1);
    message.bezierCurveTo(
      _x1 + width * 0.02,
      _y1 + height * 0.02,
      _x1 + width * 0.02,
      _y1 + height * 0.02,
      _x1 + width * 0.1,
      _y1,
    );

    ctx.current!.stroke(message);
  }, []);

  const drawArrow = useCallback((x1: number, y1: number, x2: number, y2: number): void => {
    const _x1 = x1 < x2 ? x1 : x2;
    const _y1 = y1 < y2 ? y1 : y2;
    const _x2 = x1 < x2 ? x2 : x1;
    const _y2 = y1 < y2 ? y2 : y1;

    const width: number = _x2 - _x1;
    const height: number = _y2 - _y1;

    const arrow = new Path2D();
    arrow.moveTo(_x2 - width * 0.5, _y1);
    arrow.lineTo(_x2, _y1 + height * 0.5);
    arrow.lineTo(_x2 - width * 0.25, _y1 + height * 0.5);
    arrow.lineTo(_x2 - width * 0.25, _y2);
    arrow.lineTo(_x1 + width * 0.25, _y2);
    arrow.lineTo(_x1 + width * 0.25, _y1 + height * 0.5);
    arrow.lineTo(_x1, _y1 + height * 0.5);
    arrow.lineTo(_x2 - width * 0.5, _y1);

    ctx.current!.stroke(arrow);
  }, []);

  const drawFigure = useCallback(
    (figureData: WhiteboardFigureItemData) => {
      ctx.current!.strokeStyle = figureData.color;
      ctx.current!.lineWidth = figureData.lineWidth * zoom;

      const x1 = (figureData.boundaries[0] - canvasPosition[0]) * zoom;
      const y1 = (figureData.boundaries[1] - canvasPosition[1]) * zoom;
      const x2 = (figureData.boundaries[2] - canvasPosition[0]) * zoom;
      const y2 = (figureData.boundaries[3] - canvasPosition[1]) * zoom;

      switch (figureData.figureType) {
        case WhiteboardFigureTypeEnum.Rectangle:
          drawRectangle(x1, y1, x2, y2);
          break;
        case WhiteboardFigureTypeEnum.Circle:
          drawCircle(x1, y1, x2, y2);
          break;
        case WhiteboardFigureTypeEnum.Triangle:
          drawTriangle(x1, y1, x2, y2);
          break;
        case WhiteboardFigureTypeEnum.Pentagon:
          drawPentagon(x1, y1, x2, y2);
          break;
        case WhiteboardFigureTypeEnum.Hexagon:
          drawHexagon(x1, y1, x2, y2);
          break;
        case WhiteboardFigureTypeEnum.Hart:
          drawHart(x1, y1, x2, y2);
          break;
        case WhiteboardFigureTypeEnum.Star:
          drawStar(x1, y1, x2, y2);
          break;
        case WhiteboardFigureTypeEnum.Flower:
          drawFlower(x1, y1, x2, y2);
          break;
        case WhiteboardFigureTypeEnum.Message:
          drawMessage(x1, y1, x2, y2);
          break;
        case WhiteboardFigureTypeEnum.Arrow:
          drawArrow(x1, y1, x2, y2);
          break;
      }
    },
    [zoom, canvasPosition],
  );

  const drawTransformingRectangle = useCallback(() => {
    if (isFigureTransform) {
      const x1 = (figureData.current[0] - canvasPosition[0]) * zoom;
      const y1 = (figureData.current[1] - canvasPosition[1]) * zoom;
      const x2 = (figureData.current[2] - canvasPosition[0]) * zoom;
      const y2 = (figureData.current[3] - canvasPosition[1]) * zoom;

      ctx.current!.strokeStyle = '#000000';
      ctx.current!.lineWidth = 2;
      ctx.current!.setLineDash([5, 5]);

      const rectangle = new Path2D();
      rectangle.rect(x1, y1, x2 - x1, y2 - y1);
      ctx.current!.stroke(rectangle);
      ctx.current!.setLineDash([]);

      const rectangleResizeItem = new Path2D();

      rectangleResizeItem.rect(
        (x1 < x2 ? x1 : x2) - TRANSFORM_RECTANGLE_RESIZE_ELEMENT_SIZE,
        (y1 < y2 ? y1 : y2) - TRANSFORM_RECTANGLE_RESIZE_ELEMENT_SIZE,
        TRANSFORM_RECTANGLE_RESIZE_ELEMENT_SIZE,
        TRANSFORM_RECTANGLE_RESIZE_ELEMENT_SIZE,
      );
      ctx.current!.stroke(rectangleResizeItem);

      rectangleResizeItem.rect(
        x1 < x2 ? x2 : x1,
        (y1 < y2 ? y1 : y2) - TRANSFORM_RECTANGLE_RESIZE_ELEMENT_SIZE,
        TRANSFORM_RECTANGLE_RESIZE_ELEMENT_SIZE,
        TRANSFORM_RECTANGLE_RESIZE_ELEMENT_SIZE,
      );
      ctx.current!.stroke(rectangleResizeItem);

      rectangleResizeItem.rect(
        (x1 < x2 ? x1 : x2) - TRANSFORM_RECTANGLE_RESIZE_ELEMENT_SIZE,
        y1 < y2 ? y2 : y1,
        TRANSFORM_RECTANGLE_RESIZE_ELEMENT_SIZE,
        TRANSFORM_RECTANGLE_RESIZE_ELEMENT_SIZE,
      );
      ctx.current!.stroke(rectangleResizeItem);

      rectangleResizeItem.rect(
        x1 < x2 ? x2 : x1,
        y1 < y2 ? y2 : y1,
        TRANSFORM_RECTANGLE_RESIZE_ELEMENT_SIZE,
        TRANSFORM_RECTANGLE_RESIZE_ELEMENT_SIZE,
      );
      ctx.current!.stroke(rectangleResizeItem);
    }
  }, [isFigureTransform, zoom, canvasPosition]);

  //////////////////////////////////////////////////////
  /// EVENT HANDLERS ON DRAW FIGURE
  //////////////////////////////////////////////////////

  const startDrawingFigure = useCallback(
    (clientX: number, clientY: number) => {
      const canvasRect: DOMRect = canvasRef.current!.getBoundingClientRect();
      const x: number = Math.round((clientX - canvasRect.left) / zoom + canvasPosition[0]);
      const y: number = Math.round((clientY - canvasRect.top) / zoom + canvasPosition[1]);

      isDrawing.current = true;
      currentFigureId.current = uuid();

      figureData.current = [x, y, x, y];
      setDrawingFigureData(figureData.current);
    },
    [canvasPosition, zoom],
  );

  const startDrawingFigureMouse = useCallback(
    (event: MouseEvent) => {
      startDrawingFigure(event.clientX, event.clientY);
    },
    [startDrawingFigure],
  );

  const startDrawingFigureTouch = useCallback(
    (event: TouchEvent) => {
      startDrawingFigure(event.targetTouches.item(0)!.clientX, event.targetTouches.item(0)!.clientY);
    },
    [startDrawingFigure],
  );

  const drawingFigure = useCallback(
    (clientX: number, clientY: number) => {
      if (isDrawing.current) {
        const canvasRect: DOMRect = canvasRef.current!.getBoundingClientRect();
        const x2: number = Math.round((clientX - canvasRect.left) / zoom + canvasPosition[0]);
        const y2: number = Math.round((clientY - canvasRect.top) / zoom + canvasPosition[1]);

        const x1 = figureData.current[0];
        const y1 = figureData.current[1];
        figureData.current = [x1, y1, x2, y2];
        setDrawingFigureData(figureData.current);
      }
    },
    [canvasPosition, zoom],
  );

  const drawingFigureMouse = useCallback(
    (event: MouseEvent) => {
      drawingFigure(event.clientX, event.clientY);
    },
    [drawingFigure],
  );

  const drawingFigureTouch = useCallback(
    (event: TouchEvent) => {
      drawingFigure(event.targetTouches.item(0)!.clientX, event.targetTouches.item(0)!.clientY);
    },
    [drawingFigure],
  );

  const stopDrawingFigure = useCallback(() => {
    isDrawing.current = false;
    const x1 = figureData.current[0];
    const y1 = figureData.current[1];
    const x2 = figureData.current[2];
    const y2 = figureData.current[3];

    if (Math.abs(x1 - x2) < MIN_FIGURE_SIZE && Math.abs(y1 - y2) < MIN_FIGURE_SIZE) {
      setDrawingFigureData(null);
      return;
    }

    setIsFigureTransform(true);
  }, []);

  //////////////////////////////////////////////////////
  /// EVENT HANDLERS ON TRANSFORM FIGURE
  //////////////////////////////////////////////////////

  const startFigureTransform = useCallback(
    (clientX: number, clientY: number) => {
      const canvasRect: DOMRect = canvasRef.current!.getBoundingClientRect();
      const x: number = Math.round((clientX - canvasRect.left) / zoom + canvasPosition[0]);
      const y: number = Math.round((clientY - canvasRect.top) / zoom + canvasPosition[1]);

      // rearrange for x2 always > x1 and y2 alwys > y1
      if (figureData.current[0] > figureData.current[2]) {
        figureData.current = [
          figureData.current[2],
          figureData.current[1],
          figureData.current[0],
          figureData.current[3],
        ];
      }

      if (figureData.current[1] > figureData.current[3]) {
        figureData.current = [
          figureData.current[0],
          figureData.current[3],
          figureData.current[2],
          figureData.current[1],
        ];
      }

      const x1 = figureData.current[0];
      const y1 = figureData.current[1];
      const x2 = figureData.current[2];
      const y2 = figureData.current[3];

      const clickInsideTransformRectangle: boolean = getIsClickInsideTransformRectangle(x, y, x1, y1, x2, y2);
      const clickInsideTransformResizeItems: boolean = getIsClickInsideTransformResizeItems(x, y, x1, y1, x2, y2);

      if (!clickInsideTransformRectangle && !clickInsideTransformResizeItems) {
        return;
      }

      if (clickInsideTransformResizeItems) {
        figureTransformType.current = 'resize';
        figureTransformResizeItem.current = getClickedTransformResizeItem(x, y, x1, y1, x2, y2);
      } else {
        figureTransformData.current = [x, y];
        figureTransformType.current = 'move';
      }
    },
    [zoom, canvasPosition, getClickedTransformResizeItem],
  );

  const startFigureTransformMouse = useCallback(
    (event: MouseEvent) => {
      startFigureTransform(event.clientX, event.clientY);
    },
    [startFigureTransform],
  );

  const startFigureTransformTouch = useCallback(
    (event: TouchEvent) => {
      startFigureTransform(event.targetTouches.item(0)!.clientX, event.targetTouches.item(0)!.clientY);
    },
    [startFigureTransform],
  );

  const figureTransform = useCallback(
    (clientX: number, clientY: number) => {
      if (!figureTransformType.current) {
        return;
      }

      if (figureTransformType.current === 'resize') {
        const canvasRect: DOMRect = canvasRef.current!.getBoundingClientRect();
        const x: number = Math.round((clientX - canvasRect.left) / zoom + canvasPosition[0]);
        const y: number = Math.round((clientY - canvasRect.top) / zoom + canvasPosition[1]);

        const x1 = figureData.current[0];
        const y1 = figureData.current[1];
        const x2 = figureData.current[2];
        const y2 = figureData.current[3];

        if (figureTransformResizeItem.current === 'X1Y1') {
          figureData.current = [x, y, x2, y2];
        }

        if (figureTransformResizeItem.current === 'X1Y2') {
          figureData.current = [x, y1, x2, y];
        }

        if (figureTransformResizeItem.current === 'X2Y1') {
          figureData.current = [x1, y, x, y2];
        }

        if (figureTransformResizeItem.current === 'X2Y2') {
          figureData.current = [x1, y1, x, y];
        }
      }

      if (figureTransformType.current === 'move') {
        const canvasRect: DOMRect = canvasRef.current!.getBoundingClientRect();
        const x: number = Math.round((clientX - canvasRect.left) / zoom + canvasPosition[0]);
        const y: number = Math.round((clientY - canvasRect.top) / zoom + canvasPosition[1]);

        const prevX = figureTransformData.current[0];
        const prevY = figureTransformData.current[1];

        const xOffset = x - prevX;
        const yOffset = y - prevY;

        const x1 = figureData.current[0];
        const y1 = figureData.current[1];
        const x2 = figureData.current[2];
        const y2 = figureData.current[3];

        figureData.current = [x1 + xOffset, y1 + yOffset, x2 + xOffset, y2 + yOffset];
        figureTransformData.current = [x, y];
      }

      setDrawingFigureData(figureData.current);
    },
    [zoom, canvasPosition],
  );

  const figureTransformMouse = useCallback(
    (event: MouseEvent) => {
      figureTransform(event.clientX, event.clientY);
    },
    [figureTransform],
  );

  const figureTransformTouch = useCallback(
    (event: TouchEvent) => {
      figureTransform(event.targetTouches.item(0)!.clientX, event.targetTouches.item(0)!.clientY);
    },
    [figureTransform],
  );

  const stopFigureTransform = useCallback(
    (clientX: number, clientY: number) => {
      const canvasRect: DOMRect = canvasRef.current!.getBoundingClientRect();
      const x: number = Math.round((clientX - canvasRect.left) / zoom + canvasPosition[0]);
      const y: number = Math.round((clientY - canvasRect.top) / zoom + canvasPosition[1]);

      const x1 = figureData.current[0];
      const y1 = figureData.current[1];
      const x2 = figureData.current[2];
      const y2 = figureData.current[3];

      const clickInsideTransformRectangle: boolean = getIsClickInsideTransformRectangle(x, y, x1, y1, x2, y2);
      const clickInsideTransformResizeItems: boolean = getIsClickInsideTransformResizeItems(x, y, x1, y1, x2, y2);

      if (!clickInsideTransformRectangle && !clickInsideTransformResizeItems) {
        setIsFigureTransform(false);
        setDrawingFigureData(null);
        addItem({
          id: currentFigureId.current!,
          type: WhiteboardItemTypeEnum.Figure,
          data: {
            boundaries: figureData.current,
            color: selectedColor,
            lineWidth: selectedLineSize,
            figureType: selectedFigure,
          },
        });

        figureTransformData.current = [0, 0];
        figureTransformType.current = null;
        figureTransformResizeItem.current = null;

        return;
      }

      // rearrange for x2 always > x1 and y2 alwys > y1
      if (clickInsideTransformResizeItems) {
        if (x1 > x2) {
          figureData.current = [
            figureData.current[2],
            figureData.current[1],
            figureData.current[0],
            figureData.current[3],
          ];
        }

        if (y1 > y2) {
          figureData.current = [
            figureData.current[0],
            figureData.current[3],
            figureData.current[2],
            figureData.current[1],
          ];
        }
      }

      figureTransformData.current = [0, 0];
      figureTransformType.current = null;
      figureTransformResizeItem.current = null;
    },
    [zoom, canvasPosition, selectedColor, selectedLineSize, selectedFigure, addItem],
  );

  const stopFigureTransformMouse = useCallback(
    (event: MouseEvent) => {
      stopFigureTransform(event.clientX, event.clientY);
    },
    [stopFigureTransform],
  );

  const stopFigureTransformTouch = useCallback(
    (event: TouchEvent) => {
      stopFigureTransform(event.changedTouches.item(0)!.clientX, event.changedTouches.item(0)!.clientY);
    },
    [stopFigureTransform],
  );

  useEffect(() => {
    if (isDrawFigureSelected && !isFigureTransform) {
      if (isTouchDevice) {
        canvasRef.current!.addEventListener('touchstart', startDrawingFigureTouch);
        canvasRef.current!.addEventListener('touchmove', drawingFigureTouch);
        canvasRef.current!.addEventListener('touchend', stopDrawingFigure);
        canvasRef.current!.addEventListener('mousedown', startDrawingFigureMouse);
        canvasRef.current!.addEventListener('mousemove', drawingFigureMouse);
        canvasRef.current!.addEventListener('mouseup', stopDrawingFigure);

        return () => {
          canvasRef.current?.removeEventListener('touchstart', startDrawingFigureTouch);
          canvasRef.current?.removeEventListener('touchmove', drawingFigureTouch);
          canvasRef.current?.removeEventListener('touchend', stopDrawingFigure);
          canvasRef.current?.removeEventListener('mousedown', startDrawingFigureMouse);
          canvasRef.current?.removeEventListener('mousemove', drawingFigureMouse);
          canvasRef.current?.removeEventListener('mouseup', stopDrawingFigure);
        };
      } else {
        canvasRef.current!.addEventListener('mousedown', startDrawingFigureMouse);
        canvasRef.current!.addEventListener('mousemove', drawingFigureMouse);
        canvasRef.current!.addEventListener('mouseup', stopDrawingFigure);

        return () => {
          canvasRef.current?.removeEventListener('mousedown', startDrawingFigureMouse);
          canvasRef.current?.removeEventListener('mousemove', drawingFigureMouse);
          canvasRef.current?.removeEventListener('mouseup', stopDrawingFigure);
        };
      }
    }

    if (isDrawFigureSelected && isFigureTransform) {
      if (isTouchDevice) {
        canvasRef.current!.addEventListener('touchstart', startFigureTransformTouch);
        canvasRef.current!.addEventListener('touchmove', figureTransformTouch);
        canvasRef.current!.addEventListener('touchend', stopFigureTransformTouch);
        canvasRef.current!.addEventListener('mousedown', startFigureTransformMouse);
        canvasRef.current!.addEventListener('mousemove', figureTransformMouse);
        canvasRef.current!.addEventListener('mouseup', stopFigureTransformMouse);

        return () => {
          canvasRef.current?.removeEventListener('touchstart', startFigureTransformTouch);
          canvasRef.current?.removeEventListener('touchmove', figureTransformTouch);
          canvasRef.current?.removeEventListener('touchend', stopFigureTransformTouch);
          canvasRef.current?.removeEventListener('mousedown', startFigureTransformMouse);
          canvasRef.current?.removeEventListener('mousemove', figureTransformMouse);
          canvasRef.current?.removeEventListener('mouseup', stopFigureTransformMouse);
        };
      } else {
        canvasRef.current!.addEventListener('mousedown', startFigureTransformMouse);
        canvasRef.current!.addEventListener('mousemove', figureTransformMouse);
        canvasRef.current!.addEventListener('mouseup', stopFigureTransformMouse);

        return () => {
          canvasRef.current?.removeEventListener('mousedown', startFigureTransformMouse);
          canvasRef.current?.removeEventListener('mousemove', figureTransformMouse);
          canvasRef.current?.removeEventListener('mouseup', stopFigureTransformMouse);
        };
      }
    }
  }, [
    isDrawFigureSelected,
    isFigureTransform,
    isTouchDevice,
    startDrawingFigureMouse,
    startDrawingFigureTouch,
    drawingFigureMouse,
    drawingFigureTouch,
    stopDrawingFigure,
    startFigureTransformMouse,
    startFigureTransformTouch,
    figureTransformMouse,
    figureTransformTouch,
    stopFigureTransformMouse,
    stopFigureTransformTouch,
  ]);

  useEffect(() => {
    if (isFigureTransform && !isDrawFigureSelected) {
      setIsFigureTransform(false);
      setDrawingFigureData(null);
      addItem({
        id: currentFigureId.current!,
        type: WhiteboardItemTypeEnum.Figure,
        data: {
          boundaries: figureData.current,
          color: selectedColor,
          lineWidth: selectedLineSize,
          figureType: selectedFigure,
        },
      });

      figureTransformData.current = [0, 0];
      figureTransformType.current = null;
      figureTransformResizeItem.current = null;
    }
  }, [isDrawFigureSelected, isFigureTransform, addItem]);

  return {
    drawingFigureData,
    drawFigure,
    drawTransformingRectangle,
  };
}
