import React from 'react';
import { Button, DialogContent, Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';

import styles from './EndCallDialog.module.scss';

export interface EndCallDialogProps extends DialogProps {
  onConfirm: () => void;
}

export function EndCallDialog({ onConfirm, ...rest }: EndCallDialogProps) {
  return (
    <Dialog {...rest} className={styles.dialog}>
      <DialogContent>
        <Typography component='p' variant='h3'>
          Thank you!
        </Typography>

        <Typography component='p' variant='body2'>
          Your call has ended.
        </Typography>

        <Button variant='contained' onClick={onConfirm} className={styles.formButton}>
          Ok
        </Button>
      </DialogContent>
    </Dialog>
  );
}
