import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';

import { CheckCircleIcon } from 'assets';
import { FormInput } from 'components';
import { ILoginContext } from 'context';
import { useLogin } from 'hooks';

import styles from './ResetPasswordForm.module.scss';

// temporary hidden
// const RESEND_TIMER_DURATION = 60;

// const verifyPhoneSchema = Yup.object().shape({
//   code: Yup.string().required(),
// });

YupPassword(Yup);

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(
      8,
      'password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special character',
    )
    .minLowercase(1, 'password must contain at least 1 lower case letter')
    .minUppercase(1, 'password must contain at least 1 upper case letter')
    .minNumbers(1, 'password must contain at least 1 number')
    .minSymbols(1, 'password must contain at least 1 special character')
    .required(),
  passwordConfirmed: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match'),
});

export function ResetPasswordForm() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { onResetPassword, onResetPasswordCancel }: ILoginContext = useLogin();

  const [successConfirmation, setSuccessConfirmation] = useState<boolean>(false);

  // temporary hidden
  // const { seconds, startTimer }: ITimer = useTimer(0);

  // const [isPhoneVerified, setIsPhoneVerified] = useState<boolean>(false);
  // const [twoFactor, setTwoFactor] = useState<TwoFactor>('sms');
  // const [verificationCodeHash, setVerificationCodeHash] = useState<string | null>(null);

  // const verifyPhoneForm = useForm<{ code: string }>({
  //   resolver: yupResolver(verifyPhoneSchema),
  //   defaultValues: {},
  //   mode: 'all',
  // });

  const resetPasswordForm = useForm<{ password: string; passwordConfirmed: string }>({
    resolver: yupResolver(resetPasswordSchema),
    defaultValues: {},
    mode: 'all',
  });

  // temporary hidden
  // useEffect(() => {
  //   handleResendCode();
  // }, []);

  // const handleResendCode = (): void => {
  //   if (!seconds) {
  //     onResetPasswordFormSendVarificationCode(twoFactor)
  //       // .then((response: SendCodeToPhoneResponse) => {
  //       //   setVerificationCodeHash(response.VerificationCodeHash);
  //       //   startTimer(RESEND_TIMER_DURATION);
  //       // })
  //       .catch((error) => {
  //         enqueueSnackbar(error.message, { variant: 'error' });
  //       });
  //   }
  // };

  // temporary hidden
  // const handleSubmitCode = (): void => {
  //   if (verifyPhoneForm.formState.isValid) {
  //     onResetPasswordFormSubmitCode(verifyPhoneForm.getValues().code, verificationCodeHash!)
  //       .then((response: boolean) => {
  //         if (response) {
  //           setIsPhoneVerified(true);
  //         } else {
  //           enqueueSnackbar('Phone varification failed', { variant: 'error' });
  //         }
  //       })
  //       .catch((error) => {
  //         enqueueSnackbar(error.message, { variant: 'error' });
  //       });
  //   }
  // };

  // const handleSwitchTwoFactor = (): void => {
  //   setTwoFactor((prevTwoFactor: TwoFactor) => {
  //     return prevTwoFactor === 'sms' ? 'call' : 'sms';
  //   });
  // };

  const handleResetPassword = (): void => {
    if (resetPasswordForm.formState.isValid) {
      const formValues: { password: string; passwordConfirmed: string } = resetPasswordForm.getValues();
      onResetPassword(formValues.password, formValues.passwordConfirmed)
        .then(() => {
          enqueueSnackbar('Success', { variant: 'success' });
          setSuccessConfirmation(true);
        })
        .catch((error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        });
    }
  };

  const handleResetPasswordCancel = (): void => {
    onResetPasswordCancel();
  };

  const handleRedirectToLogIn = (): void => {
    navigate('/');
  };

  return (
    <div className={styles.form}>
      {!successConfirmation && (
        <>
          <Typography variant={isMobile ? 'h2' : 'h1'} component={isMobile ? 'h2' : 'h1'}>
            Reset password
          </Typography>

          {/* temporary hidden */}
          {/* {!isPhoneVerified && (
        <>
          <Typography variant='body2' component='span'>
            To reset your password, two-factor authorization is required.
          </Typography>

          <Stack className={styles.row}>
            <FormInput
              name='code'
              control={verifyPhoneForm.control}
              placeholder={twoFactor === 'sms' ? 'Enter code from SMS' : 'Enter code from phone call'}
            />
          </Stack>

          <Stack direction='row' className={styles.buttons}>
            <Button variant='outlined' onClick={handleResendCode} disabled={!!seconds} className={styles.formButton}>
              Resend code
            </Button>

            <Button
              variant='contained'
              onClick={handleSubmitCode}
              disabled={!verifyPhoneForm.formState.isValid}
              className={styles.formButton}
            >
              Submit
            </Button>

            <Typography variant='body1' component='span'>
              You will be able to resend code in {seconds} seconds
            </Typography>
          </Stack>

          <Typography variant='body2' component='span'>
            Change verification method to a
            <b onClick={handleSwitchTwoFactor}>{twoFactor === 'sms' ? ' Phone Call' : ' SMS'}</b>
          </Typography>
        </>
      )} */}

          <>
            <Typography variant='body1' component='span'>
              Password must include a symbol or number and have at least 8 characters, upper and lowercase letters.
            </Typography>

            <Stack className={styles.row}>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography component='p' variant='body2'>
                  Password <span className={styles.asterisk}>*</span>
                </Typography>
              </Stack>
              <FormInput name='password' control={resetPasswordForm.control} type='password' />
            </Stack>

            <Stack className={styles.row}>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography component='p' variant='body2'>
                  Confirm Password <span className={styles.asterisk}>*</span>
                </Typography>
              </Stack>
              <FormInput name='passwordConfirmed' control={resetPasswordForm.control} type='password' />
            </Stack>

            <Stack direction='column' className={styles.buttons}>
              <Button variant='outlined' onClick={handleResetPasswordCancel} className={styles.formButton}>
                Cancel
              </Button>

              <Button
                variant='contained'
                onClick={handleResetPassword}
                disabled={!resetPasswordForm.formState.isValid}
                className={styles.formButton}
              >
                Confirm
              </Button>
            </Stack>
          </>
        </>
      )}

      {successConfirmation && (
        <>
          <CheckCircleIcon className={styles.icon} color='primary' />

          <Typography variant='h1' component='h1'>
            Awesome!
          </Typography>

          <Typography variant='body2' component='span'>
            Your password has been reset.
            <br />
            Please log in with the new password.
          </Typography>

          <Button variant='contained' onClick={handleRedirectToLogIn}>
            Log in
          </Button>
        </>
      )}
    </div>
  );
}
