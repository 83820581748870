/* eslint-disable prettier/prettier */

import React, { useState } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { SimpleInfoDialog, SimpleInfoDialogProps } from '../SimpleInfoDialog';
import { CheckboxCheckedIcon, CheckboxIcon } from 'assets';
import styles from './PersistentInfoDialog.module.scss';

export interface PersistentInfoDialogProps extends Omit<SimpleInfoDialogProps, 'afterOkButtonChildren'> {
    onCheckboxChange?: (checked: boolean) => void;
    persistLabel?: string;
}

export function PersistentInfoDialog({ onCheckboxChange, persistLabel, ...props }: PersistentInfoDialogProps) {
    const [isChecked, setIsChecked] = useState<boolean>(false);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
        onCheckboxChange && onCheckboxChange(event.target.checked);
    };

    return (
        <SimpleInfoDialog {...props} afterOkButtonChildren={(
            <FormControlLabel
                className={styles.checkboxContainer}
                control={
                    <Checkbox
                        className={styles.checkbox}
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        icon={<CheckboxIcon className={styles.checkboxIcon} />}
                        checkedIcon={<CheckboxCheckedIcon className={styles.checkboxIcon} />}
                    />
                }
                label={
                    <Typography component='p' variant='body5' sx={{ color: '#979797' }}>
                        {persistLabel ? persistLabel : `Don't show again`}
                    </Typography>
                }
            />
        )}>
            {props.children}
        </SimpleInfoDialog>
    );
}
