import { useState } from 'react';
import { Box, Typography, Switch, Grid, OutlinedInput, Button, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { useSnackbar } from 'notistack';

import { IApiRequests, useApiRequests, useDialog } from 'hooks';
import { ApiUser, TwoFactor } from 'types';
import { useAppDispatch } from 'store';
import { getUser, selectUser } from 'store/user';
import { SimpleInfoDialog, SuccessInfoDialog, Spinner } from 'components';
import { TwoFactorPopupOff, SendCodePopup, SubmitCodePopup, PhonePopup } from './components';

import styles from './SecurityPannel.module.scss';

type DisplayedPopup = 'turnOff2fa' | 'sendCode' | 'submitCode' | null;

type TwoFactorData = {
  phone?: string;
  twoFactor?: TwoFactor;
};

export const SecurityPannel = () => {
  const user: ApiUser = useSelector(selectUser)!;
  const { showDialog } = useDialog();
  const {
    turnOnTwoFactor,
    turnOffTwoFactor,
    changeTwoFactor,
    preUpdatePhone,
    updatePhone,
    sendCodeToPhone,
  }: IApiRequests = useApiRequests();

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [displayedPopup, setDisplayedPopup] = useState<DisplayedPopup>(null);
  const [twoFactorData, setTwoFactorData] = useState<TwoFactorData | null>(null);
  const [openPhonePopup, setOpenPhonePopup] = useState<boolean>(false);

  const onToggle2faType = (twoFactor: TwoFactor): void => {
    if (!user.twoFactorEnabled) {
      return;
    }

    if (user.twoFactorMode === twoFactor) {
      const modal = showDialog(SimpleInfoDialog, {
        text: 'You must turn off Two-Factor Authentication in order to complete this action.',
        onConfirm: () => {
          modal.destroy();
        },
      });

      return;
    }

    setLoading(true);

    changeTwoFactor(twoFactor)
      .then(() => dispatch(getUser()))
      .catch((error: any) => {
        enqueueSnackbar(error, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleToggle2fa = async (): Promise<void> => {
    setLoading(true);
    try {
      if (user.twoFactorEnabled) {
        await sendCodeToPhone('sms');
        enqueueSnackbar('Code sent', { variant: 'success' });

        setDisplayedPopup('turnOff2fa');
      } else {
        if (user.phoneNumber) {
          await turnOnTwoFactor();
          await dispatch(getUser());
        } else {
          setDisplayedPopup('sendCode');
          await dispatch(getUser());
        }
      }
    } catch (error: any) {
      enqueueSnackbar(error, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleClosePopup = (): void => {
    setDisplayedPopup(null);
  };

  const handleResendCode = (): void => {
    sendCodeToPhone('sms')
      .then(() => {
        enqueueSnackbar('Code resend', { variant: 'success' });
      })
      .catch((error: any) => {
        enqueueSnackbar(error, { variant: 'error' });
      });
  };

  const handleTurnOff2fa = (code: string): Promise<void> => {
    setLoading(true);

    return turnOffTwoFactor(code)
      .then(() => dispatch(getUser()).unwrap())
      .then(() => {
        setTwoFactorData(null);
        setDisplayedPopup(null);
      })
      .catch((error: any) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleToggleSms = (): void => {
    onToggle2faType('sms');
  };

  const handleToggleCall = (): void => {
    onToggle2faType('call');
  };

  const handleSendCode = (newPhone: string, twoFactor: TwoFactor): void => {
    setLoading(true);

    preUpdatePhone(newPhone, twoFactor)
      .then(() => {
        setTwoFactorData({ phone: newPhone, twoFactor });
        enqueueSnackbar('Code sent', { variant: 'success' });

        setDisplayedPopup('submitCode');
      })
      .catch((error: any) => {
        enqueueSnackbar(error, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleResendCodeForNewPhone = (): void => {
    preUpdatePhone(twoFactorData!.phone!, twoFactorData!.twoFactor!)
      .then(() => {
        setTwoFactorData((prevData: TwoFactorData | null) => ({
          ...prevData,
        }));
        enqueueSnackbar('Code resend', { variant: 'success' });
      })
      .catch((error: any) => {
        enqueueSnackbar(error, { variant: 'error' });
      });
  };

  const handleUpdatePhoneAndTurn2faOn = async (code: string): Promise<void> => {
    setLoading(true);

    try {
      await updatePhone(twoFactorData!.phone!, code);
      await turnOnTwoFactor();
      await dispatch(getUser());

      const modal = showDialog(SuccessInfoDialog, {
        buttonText: 'Done',
        title: 'Success!',
        text: 'Your two-factor authentication was setup successfully. Your account now requires two-factor authentication to log in. ',
        onConfirm: () => {
          modal.destroy();
        },
      });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setLoading(false);
      setDisplayedPopup(null);
    }
  };

  const handleVerifyPhoneOpen = (): void => {
    setOpenPhonePopup(true);
  };

  const handleVerifyPhoneClose = (): void => {
    setOpenPhonePopup(false);
  };

  if (loading) return <Spinner />;

  return (
    <>
      <Box className={styles.container}>
        <Stack className={styles.row}>
          <Typography variant='body2'>Phone Number</Typography>

          <Box className={styles.phone}>
            <OutlinedInput sx={{ fontSize: '12px' }} type={'text'} value={user.phoneNumber ?? ''} disabled />
            <Button className={styles.changeButton} variant='contained' onClick={handleVerifyPhoneOpen}>
              Change
            </Button>
          </Box>
        </Stack>

        <div className={styles.devider}></div>

        <Grid className={classnames(styles.field, styles.headerField)}>
          <Typography variant='body4' className={styles.text}>
            Two-Factor Authentication
          </Typography>

          <Box className={styles.switcher}>
            <Switch checked={user.twoFactorEnabled} onChange={handleToggle2fa} />
          </Box>

          <Typography variant='body1' className={styles.description}>
            You'll be asked for an authentication code when signing in to your account.
          </Typography>
        </Grid>

        <Box
          className={classnames(styles.mainSettings, {
            [styles.mainSettingsDisabled]: !user.twoFactorEnabled,
          })}
        >
          <Grid className={styles.field}>
            <Typography variant='body4' className={styles.text}>
              SMS Text Message
            </Typography>

            <Box className={styles.switcher}>
              <Switch checked={user.twoFactorEnabled && user.twoFactorMode === 'sms'} onChange={handleToggleSms} />
            </Box>

            <Typography variant='body1' className={styles.description}>
              Receive a text message to your mobile device when signing in.
            </Typography>
          </Grid>

          <Grid className={styles.field}>
            <Typography variant='body4' className={styles.text}>
              Phone Call
            </Typography>

            <Box className={styles.switcher}>
              <Switch checked={user.twoFactorEnabled && user.twoFactorMode === 'call'} onChange={handleToggleCall} />
            </Box>

            <Typography variant='body1' className={styles.description}>
              Receive a phone call with your authentication code when signing in.
            </Typography>
          </Grid>
        </Box>
      </Box>

      <TwoFactorPopupOff
        open={displayedPopup === 'turnOff2fa'}
        onClose={handleClosePopup}
        onTurnOffTwoFactor={handleTurnOff2fa}
        onResendCode={handleResendCode}
      />

      <SendCodePopup open={displayedPopup === 'sendCode'} onClose={handleClosePopup} onSubmit={handleSendCode} />

      <SubmitCodePopup
        open={displayedPopup === 'submitCode'}
        phone={twoFactorData?.phone}
        onClose={handleClosePopup}
        onSubmit={handleUpdatePhoneAndTurn2faOn}
        onResendCode={handleResendCodeForNewPhone}
      />

      <PhonePopup open={openPhonePopup} handleClose={handleVerifyPhoneClose} />
    </>
  );
};
