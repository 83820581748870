import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApiUser, IBasicState } from 'types';
import { IApiRequests, useApiRequests } from 'hooks';

import { AppState } from '../index';

const ApiRequests: IApiRequests = useApiRequests();

export interface IUserState extends IBasicState {
  data: ApiUser | null;
  message: string | null;
  error: string | null;
}

const initialState: IUserState = {
  data: null,
  loading: false,
  message: null,
  error: null,
};

export const getUser = createAsyncThunk<ApiUser>('user/current', async (): Promise<ApiUser> => ApiRequests.getUser());

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUserStore: (state) => {
      state.data = null;
    },
    clearUserMessages: (state) => {
      state.message = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      });
  },
});

// Actions
export const { clearUserStore, clearUserMessages } = userSlice.actions;

// Selectors
export const selectUser = (state: AppState) => state.user.data;

export default userSlice.reducer;
