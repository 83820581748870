import { useMemo, PropsWithChildren } from 'react';
import { AppFeatureFlagsContext, IAppFeatureFlagsContext } from './context';

const featureFlags: IAppFeatureFlagsContext = {
  participantRenameFeature: false,
  displayIconInOrganizationSelectionDropdownFeature: false,
  displayDescriptionInOrganizationDetailsFormFeature: false,
  displayOrganizationLogoInputInOrganizationDetailsFormFeature: false,
  shouldShowMicSpeakerWarning: false,
};

type AppFeatureFlagsProviderProps = PropsWithChildren;

export function AppFeatureFlagsProvider({ children }: AppFeatureFlagsProviderProps) {
  const appFeatureFlags = useMemo(() => featureFlags, []);

  return <AppFeatureFlagsContext.Provider value={appFeatureFlags}>{children}</AppFeatureFlagsContext.Provider>;
}
