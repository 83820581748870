import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function FirstPageIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M22 11.5275L17.0553 16.5L22 21.4725L20.4777 23L14 16.5L20.4777 10L22 11.5275Z' fill='#626363' />
      <path fillRule='evenodd' clipRule='evenodd' d='M10 23V10H12V23H10Z' fill='#626363' />
    </SvgIcon>
  );
}
