import { createContext } from 'react';

export interface IMutedParticipantsContext {
  mutedParticipantsId: string[];
  isCurrentParticipantMuted: boolean;
  muteParticipant(participantId: string, mute: boolean): void;
}

export const MutedParticipantsContext = createContext<IMutedParticipantsContext | null>(null);
export const MutedParticipantsConsumer = MutedParticipantsContext.Consumer;
