/* eslint-disable prettier/prettier */
import { useState, useEffect, useMemo } from 'react';
import { Paginator, Message, Participant, Conversation } from '@twilio/conversations';

import { useChat } from 'hooks';

export function useChatState(isGroup = false) {
  const { identity, conversation, roomConversation } = useChat();

  const [messages, setMessages] = useState<Message[]>([]);
  const [participants, setParticipants] = useState<Map<string, string>>(new Map());
  const [typingParticipants, setTypingParticipants] = useState<string[]>([]);

  useEffect(() => {
    const currentConversation: Conversation | null = isGroup ? roomConversation : conversation;

    if (currentConversation) {
      currentConversation!
        .getMessages(999)
        .then((paginator: Paginator<Message>) => {
          setMessages(paginator.items);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(`Fetch messages failed with error: ${error}`);
        });

      currentConversation!.getParticipants().then((participants: Participant[]) => {
        const participantsMap: Map<string, string> = new Map();
        participants.forEach((participant: Participant) => {
          participantsMap.set(participant.identity ?? '', participant.attributes?.toString() ?? '');
        });

        setParticipants(participantsMap);
      });

      currentConversation.on('participantJoined', onParticipantJoined);
      // conversation.conversation.on('participantUpdated', onParticipantUpdated);
      currentConversation.on('messageAdded', onMessageAdded);
      currentConversation.on('typingStarted', onTypingStarted);
      currentConversation.on('typingEnded', onTypingEnded);

      return () => {
        currentConversation.off('participantJoined', onParticipantJoined);
        // conversation.conversation.off('participantUpdated', onParticipantUpdated);
        currentConversation.off('messageAdded', onMessageAdded);
        currentConversation.off('typingStarted', onTypingStarted);
        currentConversation.off('typingEnded', onTypingEnded);
      };
    }
  }, [conversation, roomConversation]);

  const onParticipantJoined = (newParticipant: Participant) => {
    setParticipants((prevParticipas: Map<string, string>) => {
      const newMap: Map<string, string> = new Map(prevParticipas);
      newMap.set(newParticipant.identity!, newParticipant.attributes?.toString() ?? '');
      return newMap;
    });
  };

  const onMessageAdded = (newMessage: Message) => {
    setMessages((messages: Message[]) => [...messages, newMessage]);
  };

  const onTypingStarted = (newParticipant: Participant) => {
    setTypingParticipants((prevParticipants: string[]) => [...prevParticipants, newParticipant.identity!]);
  };

  const onTypingEnded = (participantToRemove: Participant) => {
    setTypingParticipants((prevParticipants: string[]) => [
      ...prevParticipants.filter((participant: string) => participant !== participantToRemove.identity),
    ]);
  };

  const typingUserName = useMemo((): string => {
    const typingParticipantIdentity: string | undefined = typingParticipants.find(
      (participant: string) => participant !== identity,
    );

    return typingParticipantIdentity ? participants.get(typingParticipantIdentity) ?? '' : '';
  }, [typingParticipants, participants]);

  const chatName = useMemo((): string => {
    if (isGroup && participants.size !== 2) {
      return 'Chat';
    }

    const chatName: string | undefined = participants.get(
      [...participants.keys()].find((key: any) => key !== identity) ?? '',
    );
    return chatName ?? 'Dialogue';
  }, [conversation, participants]);

  return { chatName, messages, participants, typingUserName };
}
