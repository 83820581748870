import { useCallback, useState } from 'react';

import { IApiRequests, useApiRequests } from 'hooks';
import { FirstLoginTooltipsContext } from './context';
import { useAppDispatch, useAppSelector } from 'store';
import { getUser, selectUser } from 'store/user';
import { ApiUserTooltipStagePassedEnum } from 'types';

type FirstLoginTooltipsProviderProps = {
  children?: JSX.Element;
};

export function FirstLoginTooltipsProvider({ children }: FirstLoginTooltipsProviderProps) {
  const user = useAppSelector(selectUser);
  const { updateUser }: IApiRequests = useApiRequests();
  const dispatch = useAppDispatch();

  const [tooltipsState, setTooltipsState] = useState<number>(0);

  const accept = useCallback(async (): Promise<void> => {
    if (!user) {
      return;
    }

    if (
      (user.tooltipStagePassed === ApiUserTooltipStagePassedEnum.None && tooltipsState !== 3) ||
      (user.tooltipStagePassed === ApiUserTooltipStagePassedEnum.InviteStaff && tooltipsState !== 2)
    ) {
      setTooltipsState((prevState: number) => ++prevState);
      return;
    }

    let nextStage: ApiUserTooltipStagePassedEnum = ApiUserTooltipStagePassedEnum.None;
    switch (user.tooltipStagePassed) {
      case ApiUserTooltipStagePassedEnum.None:
        nextStage = ApiUserTooltipStagePassedEnum.WelcomePage;
        break;
      case ApiUserTooltipStagePassedEnum.WelcomePage:
        nextStage = ApiUserTooltipStagePassedEnum.InviteStaff;
        break;
      default:
      case ApiUserTooltipStagePassedEnum.InviteStaff:
        nextStage = ApiUserTooltipStagePassedEnum.StaffManagement;
        break;
    }

    return updateUser({ tooltipStagePassed: nextStage })
      .then(() => {
        dispatch(getUser()).unwrap();
        setTooltipsState(0);
      })
      .catch((error: any) => {
        return Promise.reject(`Update user failed: ${error.message}`);
      });
  }, [user?.tooltipStagePassed, tooltipsState]);

  return (
    <FirstLoginTooltipsContext.Provider
      value={{ tooltipStagePassed: user?.tooltipStagePassed ?? 0, tooltipsState, accept }}
    >
      {children}
    </FirstLoginTooltipsContext.Provider>
  );
}
