export const HIPAA_BENEFITS: string[] = [
  'Host all your visits from one custom link',
  'Easy Check-in for your Clients in your real-time Virtual Waiting Room',
  'One-to-One or Group Sessions up to 15 people',
  'Take Notes during any visit and export them after the call',
  'Screen Sharing, Chat, File Share, Spotlight and more',
  'Perfect for practices of all sizes, from Sole Practitioner to Organizations',
  'No Dropped Calls. The most reliable platform on the market!',
  'No download or installation required',
];
