import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function PeopleTeamIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='29' height='24' viewBox='0 0 29 24' fill='none' {...props}>
      <path d='M14.4999 0.25C12.3323 0.25 10.5752 2.00715 10.5752 4.1747C10.5752 6.34223 12.3323 8.09937 14.4999 8.09937C16.6674 8.09937 18.4246 6.34223 18.4246 4.1747C18.4246 2.00715 16.6674 0.25 14.4999 0.25Z' />
      <path d='M23.339 2.21021C21.7118 2.21021 20.3926 3.52937 20.3926 5.15666C20.3926 6.78395 21.7118 8.10311 23.339 8.10311C24.9663 8.10311 26.2855 6.78395 26.2855 5.15666C26.2855 3.52937 24.9663 2.21021 23.339 2.21021Z' />
      <path d='M5.66032 2.21021C4.03303 2.21021 2.71387 3.52937 2.71387 5.15666C2.71387 6.78395 4.03303 8.10311 5.66032 8.10311C7.28761 8.10311 8.60678 6.78395 8.60678 5.15666C8.60678 3.52937 7.28761 2.21021 5.66032 2.21021Z' />
      <path d='M8.60746 12.0188C8.6143 10.9397 9.4911 10.0671 10.5717 10.0671H18.4289C19.5138 10.0671 20.3932 10.9466 20.3932 12.0315V17.925C20.3932 18.1283 20.383 18.3294 20.3628 18.5274C20.3202 18.948 20.2331 19.3554 20.1068 19.7451C19.34 22.1089 17.1197 23.8179 14.5003 23.8179C11.856 23.8179 9.61845 22.0761 8.87235 19.6773C8.70018 19.1238 8.60742 18.5353 8.60742 17.925V12.0314C8.60742 12.0272 8.60744 12.023 8.60746 12.0188Z' />
      <path d='M6.64291 12.0314C6.64291 11.3159 6.83421 10.645 7.16848 10.0671H2.7143C1.62946 10.0671 0.75 10.9466 0.75 12.0314V16.9428C0.75 19.6549 2.94862 21.8536 5.66076 21.8536C6.31118 21.8536 6.93207 21.7271 7.50019 21.4974C6.95209 20.4257 6.64291 19.2114 6.64291 17.925V12.0314Z' />
      <path d='M22.3572 12.0315V17.925C22.3572 19.2114 22.048 20.4257 21.5 21.4974C22.0681 21.7271 22.689 21.8536 23.3394 21.8536C26.0515 21.8536 28.2501 19.6549 28.2501 16.9428V12.0314C28.2501 10.9466 27.3707 10.0671 26.2858 10.0671H21.8316C22.1659 10.645 22.3572 11.3159 22.3572 12.0315Z' />
    </SvgIcon>
  );
}
