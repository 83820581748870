/* eslint-disable prettier/prettier */
import { useCallback, useMemo } from 'react';
import { Box, ListItem, IconButton, ListItemText, Badge } from '@mui/material';
import { Participant, RemoteAudioTrack } from 'twilio-video';
import classnames from 'classnames';

import { ChatIcon, MicOffIcon } from 'assets';
import {
  useChat,
  useIsTrackEnabled,
  useParticipantDotMenu,
  usePublications,
  useRoomHost,
  useSignalrConnection,
  useTrack,
} from 'hooks';
import { ParticipantDotMenu } from 'components/ParticipantDotMenu';
import { IParticipantDotMenuContext, ISignalrConnectionContext, ParticipantDotMenuDialogType } from 'context';
import { ApiUser, ApiWaitingRoomLinkProfile } from 'types';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { selectWaitingRoomProfile } from 'store/waitingRoom';
import { getPreferredFullName, parseIdentity, parseParticipantIdentity } from 'utils';

import styles from './ParticipantListItem.module.scss';

type ParticipantListItemProps = {
  participant: Participant;
};

export function ParticipantListItem({ participant }: ParticipantListItemProps) {
  const { signalrConnectionId }: ISignalrConnectionContext = useSignalrConnection();
  const publications = usePublications(participant);
  const audioPublication = publications.find((p) => p.kind === 'audio');
  const audioTrack = useTrack(audioPublication) as RemoteAudioTrack | undefined;
  const isTrackEnabled = useIsTrackEnabled(audioTrack as RemoteAudioTrack);
  const { onDialogOpen }: IParticipantDotMenuContext = useParticipantDotMenu();
  const { identity: chatIdentity, conversationsUnreadMessagesData, openChat } = useChat();
  const user: ApiUser | null = useAppSelector(selectUser);
  const waitingRoomProfile: ApiWaitingRoomLinkProfile | null = useAppSelector(selectWaitingRoomProfile);
  const { isHost } = useRoomHost();

  const [id, identity] = useMemo(() => {
    return parseParticipantIdentity(participant);
  }, [participant]);

  const { clinicUser } = user || {};
  const { preferedDisplayName: clinicUserPreferredDisplayName } = clinicUser || {};
  const { preferedDisplayName: waitingRoomProfilePreferredDisplayName, providerId } = waitingRoomProfile || {};

  const showPreferredFullName = (isHost && id === clinicUser?.id) || (!isHost && id === providerId);

  const preferredFullName = useMemo(() => {
    const clinicUserFullName = getPreferredFullName(clinicUserPreferredDisplayName, null);
    const waitingRoomFullName = getPreferredFullName(waitingRoomProfilePreferredDisplayName, identity);

    return showPreferredFullName ? clinicUserFullName ?? waitingRoomFullName : identity;
  }, [isHost, id, waitingRoomProfilePreferredDisplayName, clinicUserPreferredDisplayName]);

  const showChat: boolean = useMemo(() => {
    return (isHost && id !== user?.clinicUser.id) || (!isHost && id === waitingRoomProfile?.providerId);
  }, [isHost, id, waitingRoomProfile, user]);

  const showThreeDots: boolean = useMemo(() => {
    return (isHost && id !== user?.clinicUser.id) || (!isHost && id !== signalrConnectionId);
  }, [isHost, id, signalrConnectionId, user]);

  const unreadMessagesCount: number = useMemo(() => {
    const chatUniqueName: string = isHost
      ? `$chat-${chatIdentity}-${parseIdentity(participant.identity)[0]}`
      : `$chat-${waitingRoomProfile!.providerId}-${chatIdentity}`;
    return conversationsUnreadMessagesData.get(chatUniqueName)?.unreadMessagesCount ?? 0;
  }, [conversationsUnreadMessagesData, waitingRoomProfile, participant, chatIdentity]);

  const onOpenChat = useCallback((): void => {
    if (isHost) {
      openChat(chatIdentity, parseIdentity(participant.identity)[0]);
    } else {
      openChat(waitingRoomProfile!.providerId!, chatIdentity);
    }
  }, [chatIdentity, participant, waitingRoomProfile, isHost, openChat]);

  const handleMuteClick = (): void => {
    if (isHost && id !== user?.clinicUser.id) {
      onDialogOpen(ParticipantDotMenuDialogType.MUTE, participant);
    }
  };

  return (
    <ListItem
      secondaryAction={
        <Box
          className={classnames(styles.actions, {
            [styles.actionsWithChat]: showChat,
          })}
        >
          <IconButton edge='end' aria-label='chat' onClick={handleMuteClick}>
            <MicOffIcon sx={{ color: isTrackEnabled ? '#979797' : '#FF3D3D', fontSize: '20px' }} />
          </IconButton>

          {showChat && (
            <IconButton edge='end' aria-label='chat' onClick={onOpenChat}>
              <>
                <Badge badgeContent={unreadMessagesCount} className={styles.chatIconBadge} color='primary'>
                  <ChatIcon className={styles.chatIcon} sx={{ color: '#979797' }} />
                </Badge>
              </>
            </IconButton>
          )}

          {showThreeDots && (
            <ParticipantDotMenu participant={participant} toggleClassName={styles.dotMenu} withRemove={isHost} />
          )}
        </Box>
      }
    >
      <ListItemText primary={preferredFullName} className={styles.text} />
    </ListItem>
  );
}
