import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { Conversation, ConversationUpdateReason } from '@twilio/conversations';

export type ConversationUnreadMessagesData = {
  conversationUniqueName: string;
  unreadMessagesCount: number;
};

type ChatUnreadMessagesCounterProps = {
  allConversations: Conversation[];
  openedConversation: Conversation | null;
  openedRoomConversation: Conversation | null;
};

type ChatUnreadMessagesCounterType = {
  conversationsUnreadMessagesData: Map<string, ConversationUnreadMessagesData>;
  lastUpdatedChat: Conversation | null;
};

export function useChatUnreadMessagesCounter({
  allConversations,
  openedConversation,
  openedRoomConversation,
}: ChatUnreadMessagesCounterProps): ChatUnreadMessagesCounterType {
  const unreadMessagesMap: MutableRefObject<Map<string, ConversationUnreadMessagesData>> = useRef<
    Map<string, ConversationUnreadMessagesData>
  >(new Map<string, ConversationUnreadMessagesData>());

  const [conversationsUnreadMessagesData, setConversationsUnreadMessagesData] = useState<
    Map<string, ConversationUnreadMessagesData>
  >(new Map<string, ConversationUnreadMessagesData>());
  const [lastUpdatedChat, setLastUpdatedChat] = useState<Conversation | null>(null);

  const setUnreadConversations = useCallback(() => {
    const newMap: Map<string, ConversationUnreadMessagesData> = new Map<string, ConversationUnreadMessagesData>();
    unreadMessagesMap.current.forEach((value: ConversationUnreadMessagesData, key: string) => {
      newMap.set(key, value);
    });

    setConversationsUnreadMessagesData(newMap);
  }, []);

  useEffect(() => {
    const itemsToRemoveFromMap: string[] = [];
    unreadMessagesMap.current.forEach((value: ConversationUnreadMessagesData, key: string) => {
      if (!allConversations.map((conversation: Conversation) => conversation.uniqueName).includes(key)) {
        itemsToRemoveFromMap.push(key);
      }
    });

    itemsToRemoveFromMap.forEach((key: string) => {
      unreadMessagesMap.current.delete(key);
    });

    const onChatUpdated = (data: { conversation: Conversation; updateReasons: ConversationUpdateReason[] }) => {
      if (data.updateReasons.includes('lastMessage') || data.updateReasons.includes('lastReadMessageIndex')) {
        const isConversationOpened: boolean =
          data.conversation.sid === openedConversation?.sid || data.conversation.sid === openedRoomConversation?.sid;

        unreadMessagesMap.current.set(data.conversation.uniqueName ?? '', {
          conversationUniqueName: data.conversation.uniqueName ?? '',
          unreadMessagesCount: isConversationOpened
            ? 0
            : (data.conversation.lastMessage?.index ?? -1) - (data.conversation.lastReadMessageIndex ?? -1),
        });
        setLastUpdatedChat(data.conversation);
        setUnreadConversations();
      }
    };

    allConversations.forEach((conversation: Conversation) => {
      const isConversationOpened: boolean =
        conversation.sid === openedConversation?.sid || conversation.sid === openedRoomConversation?.sid;

      unreadMessagesMap.current.set(conversation.uniqueName ?? '', {
        conversationUniqueName: conversation.uniqueName ?? '',
        unreadMessagesCount: isConversationOpened
          ? 0
          : (conversation.lastMessage?.index ?? -1) - (conversation.lastReadMessageIndex ?? -1),
      });
      conversation.on('updated', onChatUpdated);
    });

    setUnreadConversations();

    return () => {
      allConversations.forEach((conversation: Conversation) => {
        conversation.off('updated', onChatUpdated);
      });
    };
  }, [allConversations, openedConversation, openedRoomConversation]);

  return { conversationsUnreadMessagesData, lastUpdatedChat };
}
