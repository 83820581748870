/* eslint-disable */
import { useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { Participant } from 'twilio-video';
import { ClickAwayListener, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popper } from '@mui/material';
import { CircleCrossIcon, MicOffIcon, PaymentIcon, RenameIcon, StarIcon } from 'assets';
import { RequestPaymentDialog, ToggleDotMenuButton, Transitions } from 'components';
import { IMutedParticipantsContext, ParticipantDotMenuDialogType } from 'context';
import {
  useMutedParticipants,
  useParticipantDotMenu,
  useSelectedParticipant,
  useAppFeatureFlags,
  useDialog,
} from 'hooks';
import { parseIdentity, parseParticipantIdentity } from 'utils';
import { AcceptPaymentStatusEnum, ApiUser } from 'types';
import { selectUser } from 'store/user';
import { useAppSelector } from 'store';

import styles from './ParticipantDotMenu.module.scss';

type ParticipantDotMenu = {
  participant: Participant;
  toggleClassName?: string;
  iconClassName?: string;
  withMute?: boolean;
  withRemove?: boolean;
  isTrackEnabled?: boolean;
  iconColor?: string;
  menuBackgroundColor?: string;
  listItemTextColor?: string;
  tooltipPlacement?: 'right-start' | 'right-end';
};

export function ParticipantDotMenu({
  participant,
  toggleClassName,
  iconClassName,
  withMute,
  withRemove,
  iconColor,
  menuBackgroundColor,
  listItemTextColor,
  tooltipPlacement,
}: ParticipantDotMenu) {
  const user: ApiUser | null = useAppSelector(selectUser);
  const { participantRenameFeature } = useAppFeatureFlags();

  const [selectedParticipant] = useSelectedParticipant();
  const { showDialog } = useDialog();
  const { onDialogOpen } = useParticipantDotMenu();
  const { mutedParticipantsId }: IMutedParticipantsContext = useMutedParticipants();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const prevOpen = useRef(open);

  const [id] = parseParticipantIdentity(participant);

  const isMuted: boolean = useMemo(() => {
    return mutedParticipantsId.some((mutedParticipantId: string) => mutedParticipantId === id);
  }, [mutedParticipantsId, id]);

  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current && anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMuteClick = (): void => {
    onDialogOpen(ParticipantDotMenuDialogType.MUTE, participant);
    setOpen(false);
  };

  const handleRemoveClick = (): void => {
    onDialogOpen(ParticipantDotMenuDialogType.REMOVE, participant);
    setOpen(false);
  };

  const handleSpotlightClick = (): void => {
    onDialogOpen(ParticipantDotMenuDialogType.SPOTLIGHT, participant);
    setOpen(false);
  };

  const handleParticipantRename = (): void => {
    onDialogOpen(ParticipantDotMenuDialogType.RENAME, participant);
    setOpen(false);
  };

  const handlePaymentClick = (): void => {
    const [id, clientName] = parseIdentity(participant.identity);
    const modal = showDialog(RequestPaymentDialog, {
      clientId: id,
      clientName: clientName,
      onConfirm: () => {
        modal.destroy();
      },
    });
    setOpen(false);
  };

  return (
    <>
      <ToggleDotMenuButton
        className={classnames(styles.menuIcon, toggleClassName)}
        iconClassName={iconClassName}
        iconColor={iconColor}
        onClick={handleToggle}
        ref={anchorRef}
      />

      <Popper
        placement={tooltipPlacement ?? 'right-start'}
        className={styles.dotMenu}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <ClickAwayListener onClickAway={handleClose}>
              <List
                disablePadding
                sx={{
                  backgroundColor: menuBackgroundColor || '#FFF',
                  borderRadius: '5px',
                  boxShadow: '0 2px 8px rgb(0 0 0 / 5%)',
                }}
              >
                {participantRenameFeature && (
                  <ListItem>
                    <ListItemButton role={undefined} onClick={handleParticipantRename} dense>
                      <ListItemIcon>
                        <RenameIcon sx={{ color: iconColor || '#81CFDD' }} />
                      </ListItemIcon>
                      <ListItemText
                        primary='Rename'
                        sx={{ '.MuiListItemText-primary': { color: listItemTextColor || '#393534' } }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}

                {user?.acceptPaymentStatus === AcceptPaymentStatusEnum.Connected &&
                  !!user?.clinicUser?.paymentsAllowed && (
                    <ListItem>
                      <ListItemButton role={undefined} onClick={handlePaymentClick} dense>
                        <ListItemIcon>
                          <PaymentIcon sx={{ color: iconColor || '#FF3D3D' }} />
                        </ListItemIcon>
                        <ListItemText
                          primary='Payment'
                          sx={{ '.MuiListItemText-primary': { color: listItemTextColor || '#393534' } }}
                        />
                      </ListItemButton>
                    </ListItem>
                  )}

                {withMute && (
                  <ListItem>
                    <ListItemButton role={undefined} onClick={handleMuteClick} dense>
                      <ListItemIcon>
                        <MicOffIcon sx={{ color: iconColor || '#FF3D3D' }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={isMuted ? 'Unmute' : 'Mute'}
                        sx={{ '.MuiListItemText-primary': { color: listItemTextColor || '#393534' } }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}

                <ListItem>
                  <ListItemButton role={undefined} onClick={handleSpotlightClick} dense>
                    <ListItemIcon>
                      <StarIcon sx={{ color: iconColor || '#81CFDD' }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={selectedParticipant === participant ? 'Unspotlight' : 'Spotlight'}
                      sx={{ '.MuiListItemText-primary': { color: listItemTextColor || '#393534' } }}
                    />
                  </ListItemButton>
                </ListItem>

                {withRemove && (
                  <ListItem>
                    <ListItemButton role={undefined} onClick={handleRemoveClick} dense>
                      <ListItemIcon>
                        <CircleCrossIcon sx={{ color: iconColor || '#81CFDD' }} />
                      </ListItemIcon>
                      <ListItemText
                        primary='Remove'
                        sx={{ '.MuiListItemText-primary': { color: listItemTextColor || '#393534' } }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
              </List>
            </ClickAwayListener>
          </Transitions>
        )}
      </Popper>
    </>
  );
}
