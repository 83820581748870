import { MutableRefObject, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useScreenSize } from 'hooks';

const DEFAULT_ZOOM_CALCULATION_VALUE = 1000;
const MAX_ZOOM_COEFFICIENT = 4;
const MIN_ZOOM_COEFFICIENT = 8;
const TOUCH_ZOOMING_COEFFICIENT = 4;
const DEFAULT_WIDTH_FOR_100_PERCENT_ZOOM = 1150;

export type MoveProps = {
  canvasRef: RefObject<HTMLCanvasElement>;
  isMoveSelected: boolean;
};

export type MoveType = {
  zoom: number;
  position: number[];
  applyZoom(newZoom: number): void;
  moveTo(x: number, y: number): void;
};

export function useMove({ canvasRef, isMoveSelected }: MoveProps): MoveType {
  const { isTouchDevice } = useScreenSize();

  const [zoom, setZoom] = useState<number>(1);
  const [position, setPosition] = useState<number[]>([0, 0]);

  const isMoving: MutableRefObject<boolean> = useRef<boolean>(false);
  const lastPosition: MutableRefObject<number[]> = useRef<number[]>([0, 0]);
  const lastCursorOrTouchPosition: MutableRefObject<number[]> = useRef<number[]>([0, 0]);
  const zoomValue: MutableRefObject<number> = useRef<number>(1);
  const lastDistanceBetweenTouches: MutableRefObject<number> = useRef<number>(0);

  const maxZoomValue: number = MAX_ZOOM_COEFFICIENT;
  const minZoomValue: number = 1 / MIN_ZOOM_COEFFICIENT;

  //////////////////////////////////////////////
  /// ZOOM
  //////////////////////////////////////////////

  const applyZoom = useCallback((newZoom: number) => {
    let _newZoom: number = newZoom;

    if (_newZoom < minZoomValue) {
      _newZoom = minZoomValue;
    }

    if (_newZoom > maxZoomValue) {
      _newZoom = maxZoomValue;
    }

    zoomValue.current = _newZoom;
    setZoom(zoomValue.current);
  }, []);

  const onZoom = useCallback((delta: number, zoomPosition: number[]) => {
    let newZoom: number = zoomValue.current + delta / DEFAULT_ZOOM_CALCULATION_VALUE;

    if (newZoom < minZoomValue) {
      newZoom = minZoomValue;
    }

    if (newZoom > maxZoomValue) {
      newZoom = maxZoomValue;
    }

    // const newZoom: number = newZoomCalculationValue / DEFAULT_ZOOM_CALCULATION_VALUE;
    const prevZoom: number = zoomValue.current;

    // position changed during zoom depend on zoom position
    // zoom position - is a point with x,y coords where the zoom was applied
    const canvasRect: DOMRect = canvasRef.current!.getBoundingClientRect();
    const zoomPositionX: number = zoomPosition[0] - canvasRect.left;
    const zoomPositionY: number = zoomPosition[1] - canvasRect.top;
    const positionDeltaX: number = (zoomPositionX / prevZoom) * (1 - prevZoom / newZoom);
    const positionDeltaY: number = (zoomPositionY / prevZoom) * (1 - prevZoom / newZoom);

    lastPosition.current = [lastPosition.current[0] + positionDeltaX, lastPosition.current[1] + positionDeltaY];

    setPosition(lastPosition.current);
    setZoom(newZoom);

    zoomValue.current = newZoom;
  }, []);

  const onZoomMouse = useCallback((ev: WheelEvent) => {
    ev.preventDefault();
    onZoom(-ev.deltaY, [ev.clientX, ev.clientY]);
  }, []);

  const onStartZoomTouch = useCallback((ev: TouchEvent) => {
    ev.preventDefault();

    if (ev.touches.length === 2) {
      // is zoom when distance between touches changed
      // distance calculated by Pythagorean theorem
      const distance: number = Math.pow(
        Math.pow(Math.abs(ev.touches[0].clientX - ev.touches[1].clientX), 2) +
          Math.pow(Math.abs(ev.touches[0].clientY - ev.touches[1].clientY), 2),
        0.5,
      );

      lastDistanceBetweenTouches.current = distance;
    }
  }, []);

  const onZoomTouch = useCallback((ev: TouchEvent) => {
    ev.preventDefault();

    if (ev.touches.length === 2) {
      // is zoom when distance between touches changed
      // distance calculated by Pythagorean theorem
      const distance: number = Math.pow(
        Math.pow(Math.abs(ev.touches[0].clientX - ev.touches[1].clientX), 2) +
          Math.pow(Math.abs(ev.touches[0].clientY - ev.touches[1].clientY), 2),
        0.5,
      );

      const delta: number = (distance - lastDistanceBetweenTouches.current) * TOUCH_ZOOMING_COEFFICIENT;
      lastDistanceBetweenTouches.current = distance;

      // center between touches is zoom position
      onZoom(delta, [
        (ev.touches[0].clientX + ev.touches[1].clientX) / 2,
        (ev.touches[0].clientY + ev.touches[1].clientY) / 2,
      ]);
    }
  }, []);

  //////////////////////////////////////////////
  /// MOVE
  //////////////////////////////////////////////

  const onStartMove = useCallback((coursorOrTouchPosition: number[]) => {
    isMoving.current = true;
    lastCursorOrTouchPosition.current = coursorOrTouchPosition;
  }, []);

  const onMove = useCallback((coursorOrTouchPosition: number[]) => {
    const zoom: number = zoomValue.current;

    const deltaX: number = (lastCursorOrTouchPosition.current[0] - coursorOrTouchPosition[0]) / zoom;
    const deltaY: number = (lastCursorOrTouchPosition.current[1] - coursorOrTouchPosition[1]) / zoom;

    lastPosition.current = [lastPosition.current[0] + deltaX, lastPosition.current[1] + deltaY];
    setPosition(lastPosition.current);

    lastCursorOrTouchPosition.current = coursorOrTouchPosition;
  }, []);

  const onStopMove = useCallback(() => {
    isMoving.current = false;
  }, []);

  const onStartMoveMouse = useCallback((ev: MouseEvent) => {
    onStartMove([ev.clientX, ev.clientY]);
  }, []);

  const onMoveMouse = useCallback((ev: MouseEvent) => {
    if (!isMoving.current) {
      return;
    }

    onMove([ev.clientX, ev.clientY]);
  }, []);

  const onStartMoveTouch = useCallback((ev: TouchEvent) => {
    if (ev.touches.length === 2) {
      onStartMove([
        (ev.touches[0].clientX + ev.touches[1].clientX) / 2,
        (ev.touches[0].clientY + ev.touches[1].clientY) / 2,
      ]);
    }
  }, []);

  const onMoveTouch = useCallback((ev: TouchEvent) => {
    if (ev.touches.length === 2) {
      ev.preventDefault();

      // center between touches is move position
      onMove([
        (ev.touches[0].clientX + ev.touches[1].clientX) / 2,
        (ev.touches[0].clientY + ev.touches[1].clientY) / 2,
      ]);
    }
  }, []);

  useEffect(() => {
    if (isTouchDevice) {
      canvasRef.current!.addEventListener('touchstart', onStartMoveTouch);
      canvasRef.current!.addEventListener('touchmove', onMoveTouch);
      canvasRef.current!.addEventListener('touchend', onStopMove);

      return () => {
        canvasRef.current?.removeEventListener('touchstart', onStartMoveTouch);
        canvasRef.current?.removeEventListener('touchmove', onMoveTouch);
        canvasRef.current?.removeEventListener('touchend', onStopMove);
      };
    } else if (isMoveSelected) {
      canvasRef.current!.addEventListener('mousedown', onStartMoveMouse);
      canvasRef.current!.addEventListener('mousemove', onMoveMouse);
      canvasRef.current!.addEventListener('mouseup', onStopMove);

      return () => {
        canvasRef.current?.removeEventListener('mousedown', onStartMoveMouse);
        canvasRef.current?.removeEventListener('mousemove', onMoveMouse);
        canvasRef.current?.removeEventListener('mouseup', onStopMove);
      };
    }
  }, [isTouchDevice, isMoveSelected]);

  useEffect(() => {
    if (isTouchDevice) {
      canvasRef.current!.addEventListener('touchstart', onStartZoomTouch);
      canvasRef.current!.addEventListener('touchmove', onZoomTouch);

      return () => {
        canvasRef.current?.removeEventListener('touchstart', onStartZoomTouch);
        canvasRef.current?.removeEventListener('touchmove', onZoomTouch);
      };
    } else {
      canvasRef.current!.addEventListener('wheel', onZoomMouse);

      return () => {
        canvasRef.current?.removeEventListener('wheel', onZoomMouse);
      };
    }
  }, [isTouchDevice]);

  useEffect(() => {
    setTimeout(() => {
      applyZoom(canvasRef.current!.width / DEFAULT_WIDTH_FOR_100_PERCENT_ZOOM);
    }, 100);
  }, []);

  const moveTo = useCallback((x: number, y: number) => {
    if (isMoving.current) {
      return;
    }

    lastPosition.current = [x, y];
    setPosition(lastPosition.current);
  }, []);

  return {
    zoom,
    position,
    applyZoom,
    moveTo,
  };
}
