import { useEffect, useState } from 'react';
import { Participant } from 'twilio-video';
import { useAppSettings, useVideo } from 'hooks';

import { SelectedParticipantContext } from './context';

type SelectedParticipantProviderProps = {
  children?: JSX.Element;
};

export function SelectedParticipantProvider({ children }: SelectedParticipantProviderProps) {
  const { room } = useVideo();
  const { isGalleryViewActive } = useAppSettings();
  const [selectedParticipant, _setSelectedParticipant] = useState<Participant | null>(null);
  const setSelectedParticipant = (participant: Participant | null) =>
    _setSelectedParticipant((prevParticipant) => (prevParticipant === participant ? null : participant));

  useEffect(() => {
    if (isGalleryViewActive) {
      _setSelectedParticipant(null);
    }
  }, [isGalleryViewActive]);

  useEffect(() => {
    if (room) {
      const onDisconnect = () => _setSelectedParticipant(null);
      const handleParticipantDisconnected = (participant: Participant) =>
        _setSelectedParticipant((prevParticipant) => (prevParticipant === participant ? null : prevParticipant));

      room.on('disconnected', onDisconnect);
      room.on('participantDisconnected', handleParticipantDisconnected);
      return () => {
        room.off('disconnected', onDisconnect);
        room.off('participantDisconnected', handleParticipantDisconnected);
      };
    }
  }, [room]);

  return (
    <SelectedParticipantContext.Provider value={[selectedParticipant, setSelectedParticipant]}>
      {children}
    </SelectedParticipantContext.Provider>
  );
}
