import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { FormInput, FormMediaUpload, Spinner } from 'components';
import { getUser, selectUser } from 'store/user';
import { useAppDispatch, useAppSelector } from 'store';
import { ApiUser } from 'types';
import { ADMIN, CLINIC_ADMIN } from 'constants/roles';
import { IApiRequests, useApiRequests, useAppFeatureFlags } from 'hooks';

import styles from './OrganizationPannel.module.scss';

type OrganizationForm = {
  organization: string;
  description?: string;
};

const schema = Yup.object().shape({
  organization: Yup.string().min(2).max(100, 'Max length is 100 character').required(),
  description: Yup.string().max(100, 'Max length is 1000 character').nullable(),
});

export const OrganizationPanel = () => {
  const {
    displayDescriptionInOrganizationDetailsFormFeature,
    displayOrganizationLogoInputInOrganizationDetailsFormFeature,
  } = useAppFeatureFlags();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const user: ApiUser = useAppSelector(selectUser)!;
  const { updateClinic, updateClinicPicture }: IApiRequests = useApiRequests();

  const [loading, setLoading] = useState<boolean>(false);
  const [clinicPicture, setClinicPicture] = useState<File | null>(null);
  const [clinicPictureChanged, setClinicPictureChanged] = useState<boolean>(false);

  const isAdmin: boolean = useMemo(() => {
    return user?.clinicUser.roles.some((role: string) => role === ADMIN || role === CLINIC_ADMIN) ?? false;
  }, [user]);

  const formDefaultValues: OrganizationForm = useMemo(
    () => ({
      organization: user.clinicUser.organization,
      description: user.clinicUser.description ?? '',
    }),
    [user],
  );

  const form = useForm<OrganizationForm>({
    resolver: yupResolver(schema),
    defaultValues: formDefaultValues,
    mode: 'all',
  });

  useEffect(() => {
    form.reset(formDefaultValues);
  }, [formDefaultValues]);

  const handleSubmit = (): void => {
    if (!form.formState.isValid) {
      return;
    }

    setLoading(true);

    const updateImagePromise = (): Promise<void> => {
      if (!clinicPictureChanged) {
        return Promise.resolve();
      }

      return updateClinicPicture(clinicPicture).catch((error) => {
        throw new Error(`Unable to update clinic picture: ${error}`);
      });
    };

    const updateClinicPromsie = (): Promise<void> => {
      return updateClinic(formModel).catch((error) => {
        throw new Error(`Update clinic failed: ${error.message}`);
      });
    };

    const formModel: OrganizationForm = form.getValues();

    updateImagePromise()
      .then(() => updateClinicPromsie())
      .then(() => dispatch(getUser()).unwrap())
      .then(() => {
        enqueueSnackbar('Clinic updated', { variant: 'success' });
      })
      .catch((error: any) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangePicture = (picture: File | null) => {
    setClinicPicture(picture);
    setClinicPictureChanged(true);
  };

  if (loading) return <Spinner />;

  return (
    <Box className={styles.container}>
      {displayOrganizationLogoInputInOrganizationDetailsFormFeature && (
        <Stack className={styles.input}>
          <Stack direction='row' alignItems='flex-end'>
            <Typography component='p' variant='body2'>
              Organization Logo
            </Typography>
          </Stack>
          <FormMediaUpload
            onInputChange={handleChangePicture}
            onRemove={() => handleChangePicture(null)}
            title='Logo'
            src={user.clinicUser.linkToClinicPicture ?? ''}
            onlyView={!isAdmin}
          />
        </Stack>
      )}

      <Stack className={styles.input}>
        <Typography variant='body2'>
          Provider/Organization Name <span className={styles.asterisk}>*</span>
        </Typography>
        <FormInput name='organization' control={form.control} disabled={!isAdmin} type='text' />
      </Stack>

      {displayDescriptionInOrganizationDetailsFormFeature && (
        <Stack className={styles.input}>
          <Typography variant='body2'>Description</Typography>
          <FormInput
            name='description'
            multiline
            control={form.control}
            className={styles.textArea}
            disabled={!isAdmin}
          />
        </Stack>
      )}

      {isAdmin && (
        <Button
          onClick={handleSubmit}
          variant='contained'
          disabled={!form.formState.isValid || !form.formState.isDirty}
          className={styles.saveButton}
        >
          Save
        </Button>
      )}
    </Box>
  );
};
