import { CSSProperties, useMemo, useRef } from 'react';
import { Button, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import moment from 'moment';
import { jsPDF } from 'jspdf';
import './fonts/OpenSans-Regular-normal';
import './fonts/OpenSans-SemiBold-normal';

import { useAppDispatch, useAppSelector } from 'store';
import { clearCallStore, selectCall } from 'store/call';
import { parseIdentity, setMinLengthForNumber } from 'utils';

import styles from './NotesDialog.module.scss';

export interface NotesDialogProps extends DialogProps {
  onConfirm: () => void;
  onClose?: () => void;
}

const pdfStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingRight: '15px',
    paddingBottom: 0,
    marginRight: '5px',
    marginBottom: '15px',
    gap: '15px',
    overflow: 'hidden',
  } as CSSProperties,
  text: {
    margin: 0,
    fontSize: '1.4rem',
    fontWeight: 400,
    lineHeight: '1.9rem',
    fontFamily: 'Open Sans, OpenSans-Regular',
    wordBreak: 'break-word',
    whiteSpace: 'break-spaces',
  } as CSSProperties,
  boldText: {
    display: 'block',
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: '1.9rem',
    fontFamily: 'Open Sans, OpenSans-SemiBold',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 600,
    lineHeight: '2.4rem',
    fontFamily: 'Open Sans, OpenSans-SemiBold',
  } as CSSProperties,
};

export function NotesDialog({ onConfirm, onClose, ...rest }: NotesDialogProps) {
  const dispatch = useAppDispatch();
  const call = useAppSelector(selectCall);
  const sessionNotesRef = useRef<any>(null);

  const generatePDF = () => {
    const doc = new jsPDF('p', 'pt', 'a4');
    doc.setFont('OpenSans-Regular', 'normal');
    doc.html(sessionNotesRef.current, {
      async callback(doc) {
        doc.save('notes.pdf');

        dispatch(clearCallStore());
        onConfirm();
      },
      margin: [20, 0, 20, 60],
    });
  };

  const handleClose = () => {
    dispatch(clearCallStore());
    onClose && onClose();
  };

  const [startDate, startDateString, startTimeString] = useMemo(() => {
    let date = moment().utc();
    if (call?.startDate) {
      date = moment.utc(call?.startDate, 'YYYY-MM-DDTHH:mm:ss.SSSSZ');
    }

    return [date.local(), date.local().format('MM/DD/YYYY'), date.local().format('hh:mm A')];
  }, [call?.startDate]);

  const [endDate, endTimeString] = useMemo(() => {
    let date = moment.utc();
    if (call?.endDate && call?.endDate >= call?.startDate) {
      date = moment.utc(call?.endDate, 'YYYY-MM-DDTHH:mm:ss.SSSSZ');
    }
    return [date.local(), date.local().format('hh:mm A')];
  }, [call?.endDate]);

  const duration = useMemo(() => {
    const duration = moment.duration(endDate.diff(startDate, 'seconds'), 'seconds');

    const hours = setMinLengthForNumber(duration.hours(), 2);
    const minutes = setMinLengthForNumber(duration.minutes(), 2);
    const seconds = setMinLengthForNumber(duration.seconds(), 2);

    return `${hours}:${minutes}:${seconds}`;
  }, [startDate, endDate]);

  return (
    <Dialog {...rest} className={styles.dialog}>
      <DialogTitle textAlign='center'>
        <Typography component='p' variant='h2' textTransform='uppercase'>
          Session Notes
        </Typography>
      </DialogTitle>
      <DialogContent ref={sessionNotesRef} style={pdfStyles.container}>
        <Typography component='p' style={pdfStyles.title} variant='h2' className={styles.dialogContentTitle}>
          Session Notes
        </Typography>
        <Typography component='p' style={pdfStyles.text}>
          <Typography component='span' style={pdfStyles.boldText} className={styles.boldText}>
            Date:&nbsp;
          </Typography>
          {startDateString}
        </Typography>
        <Typography component='p' style={pdfStyles.text}>
          <Typography component='span' style={pdfStyles.boldText} className={styles.boldText}>
            Start Time:&nbsp;
          </Typography>
          {startTimeString}
        </Typography>
        <Typography component='p' style={pdfStyles.text}>
          <Typography component='span' style={pdfStyles.boldText} className={styles.boldText}>
            End Time:&nbsp;
          </Typography>
          {endTimeString}
        </Typography>
        <Typography component='p' style={pdfStyles.text}>
          <Typography component='span' style={pdfStyles.boldText} className={styles.boldText}>
            Duration:&nbsp;
          </Typography>
          {duration}
        </Typography>
        <Stack direction='column' alignItems='flex-start'>
          <Typography component='span' style={pdfStyles.boldText} className={styles.boldText}>
            Participant(s):
          </Typography>
          {call?.participants?.map((participant: string, index: number) => (
            <Typography key={index} component='p' style={pdfStyles.text}>
              {parseIdentity(participant)[1]}
            </Typography>
          ))}
        </Stack>
        <Stack direction='column' alignItems='flex-start'>
          <Typography component='span' style={pdfStyles.boldText} className={styles.boldText}>
            Notes:
          </Typography>
          <Typography component='pre' className={styles.notes} style={pdfStyles.text}>
            {call?.meetingNotes}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={handleClose}>
          Back to waiting room
        </Button>
        <Button variant='contained' onClick={generatePDF}>
          Export notes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
