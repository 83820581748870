import React, { ReactElement } from 'react';
import { Button, DialogContent, Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';

import { CheckCircleIcon } from 'assets';

import styles from './SuccessInfoDialog.module.scss';

export interface SuccessInfoDialogProps extends DialogProps {
  title: string;
  text?: string;
  body?: ReactElement;
  buttonText?: string;
  hideIcon?: boolean;
  onConfirm: () => void;
}

export function SuccessInfoDialog({
  text,
  title,
  body,
  buttonText,
  hideIcon,
  onConfirm,
  ...rest
}: SuccessInfoDialogProps) {
  return (
    <Dialog {...rest} className={styles.dialog}>
      <DialogContent>
        {!hideIcon && <CheckCircleIcon className={styles.icon} color='primary' />}

        <Typography component='p' variant='body4'>
          {title}
        </Typography>

        {body}

        {text && (
          <Typography component='p' variant='body5'>
            {text}
          </Typography>
        )}

        <Button variant='contained' onClick={onConfirm} className={styles.formButton}>
          {buttonText ?? 'Ok'}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
