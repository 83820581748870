import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function EMDRIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M5.77778 0.888672C4.94722 0.888672 4.25833 1.45812 4.06111 2.22756C4.03889 2.22756 4.02222 2.22201 4 2.22201C3.01944 2.22201 2.22222 3.01923 2.22222 3.99978C2.22222 4.13312 2.23889 4.26367 2.26944 4.38867C1.45833 4.72201 0.888889 5.51645 0.888889 6.44423C0.888889 6.79423 0.977778 7.11923 1.11944 7.41367C0.452778 7.79701 0 8.50812 0 9.33312C0 10.2581 0.566667 11.0526 1.37222 11.3859C1.34722 11.5137 1.33333 11.6442 1.33333 11.7776C1.33333 12.8831 2.22778 13.7776 3.33333 13.7776C3.44722 13.7776 3.55833 13.7637 3.66667 13.7442C3.93333 14.5359 4.67222 15.1109 5.55556 15.1109C6.66111 15.1109 7.55556 14.2164 7.55556 13.1109V2.66645C7.55556 1.68589 6.75833 0.888672 5.77778 0.888672ZM16 9.33312C16 8.50812 15.5472 7.79701 14.8806 7.41367C15.025 7.11923 15.1111 6.79423 15.1111 6.44423C15.1111 5.51645 14.5417 4.72201 13.7306 4.38867C13.7583 4.26367 13.7778 4.13312 13.7778 3.99978C13.7778 3.01923 12.9806 2.22201 12 2.22201C11.9778 2.22201 11.9583 2.22756 11.9389 2.22756C11.7417 1.45812 11.0528 0.888672 10.2222 0.888672C9.24167 0.888672 8.44444 1.68312 8.44444 2.66645V13.1109C8.44444 14.2164 9.33889 15.1109 10.4444 15.1109C11.3278 15.1109 12.0667 14.5359 12.3333 13.7442C12.4417 13.7637 12.5528 13.7776 12.6667 13.7776C13.7722 13.7776 14.6667 12.8831 14.6667 11.7776C14.6667 11.6442 14.6528 11.5137 14.6278 11.3859C15.4333 11.0526 16 10.2581 16 9.33312Z' />
    </SvgIcon>
  );
}
