/* eslint-disable prettier/prettier */
import { useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useDialog, useSubscription, useUser } from 'hooks';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { getIsAdminByRoles, getIsProviderRoles } from 'utils';
import { SubscriptionRequiredDialog } from 'components';
import { SubscriptionStatusEnum } from 'types';

export function SubscriptionCheckRoute() {
  const navigate = useNavigate();
  const { showDialog } = useDialog();
  const { logout } = useUser();
  const user = useAppSelector(selectUser);
  const subscription = useSubscription();
  const [isAllow, setIsAllow] = useState<boolean>(false);

  const isAdmin = useMemo<boolean>(() => {
    return getIsAdminByRoles(user?.clinicUser.roles);
  }, [user]);

  const isProvider = useMemo<boolean>(() => {
    return getIsProviderRoles(user?.clinicUser.roles);
  }, [user]);

  const isSubscriptionExpiredOrCancelledOrEnding = useMemo<boolean>(() => {
    const daysUntilEnd = subscription?.daysUntilBillingPeriodEnd;
    return (
      (daysUntilEnd && (daysUntilEnd === 0 || daysUntilEnd < 0)) ||
      subscription?.subscriptionStatus === SubscriptionStatusEnum.Expired ||
      subscription?.subscriptionStatus === SubscriptionStatusEnum.Cancelled
    );
  }, [subscription]);

  const showAdminSubscriptionExpiryModal = () => {
    const modal = showDialog(SubscriptionRequiredDialog, {
      isProvider: false,
      isCancelled: subscription?.subscriptionStatus === SubscriptionStatusEnum.Cancelled,
      onConfirm: () => {
        modal.destroy();
      },
    });

    navigate('/settings#subscriptions');
    setIsAllow(true);
  };

  const showProviderNoLicenseModal = () => {
    const modal = showDialog(SubscriptionRequiredDialog, {
      organization: user?.clinicUser.organization,
      isProvider: true,
      isCancelled: subscription?.subscriptionStatus === SubscriptionStatusEnum.Cancelled,
      onConfirm: () => {
        modal.destroy();
        logout();
      },
    });

    setIsAllow(true);
  };

  useEffect(() => {
    if (isAdmin && isSubscriptionExpiredOrCancelledOrEnding) {
      showAdminSubscriptionExpiryModal();
      return;
    }

    if (isProvider && !user?.clinicUser.hasLicense) {
      showProviderNoLicenseModal();
      return;
    }

    setIsAllow(true);
  }, [navigate, user]);

  return isAllow ? <Outlet /> : null;
}
