import { PLAN_CODE_MONTHLY } from 'constants/payment';
import { ApiPlan, CouopnDescription, Coupon, CouponApplyToPlans } from 'types';

export const isCouponValidForPlan = (coupon: Coupon, planCode: string): boolean => {
  if (coupon.applyToPlans === CouponApplyToPlans.All) {
    return true;
  }

  if (coupon.applyToPlans === CouponApplyToPlans.None) {
    return false;
  }

  return coupon.plans?.some((plan: ApiPlan) => plan.code === planCode);
};

export const isCouponForMonthlyPlan = (coupon: Coupon): boolean => {
  return coupon.plans[0]?.code === PLAN_CODE_MONTHLY;
};

export const getCouponDescriptionModel = (coupon: Coupon): CouopnDescription => {
  try {
    return JSON.parse(coupon.description) as CouopnDescription;
  } catch {
    return {} as CouopnDescription;
  }
};
