/* eslint-disable prettier/prettier */

import Dialog, { DialogProps } from '@mui/material/Dialog';
import { Box, Button, DialogContent, List, ListItem, Typography } from '@mui/material';
import { Spinner } from 'components';
import { ConfirmResultEnum } from 'context';
import { CheckIcon, SadFaceIcon } from 'assets';

import styles from './CancellationConfirmationPopup.module.scss';

export interface CancellationConfirmationPopupProps extends DialogProps {
  planName: string;
  roomName: string;
  loading: boolean;
  onConfirm(confirmResult: ConfirmResultEnum): void;
};

export const CancellationConfirmationPopup = ({ planName, roomName, loading, onConfirm, ...rest }: CancellationConfirmationPopupProps) => {
  const handleCancel = () => {
    onConfirm(ConfirmResultEnum.Cancel);
  };
  const handleConfirm = () => {
    onConfirm(ConfirmResultEnum.Confirm);
  };
  return loading ? <Spinner /> : (
    <Dialog {...rest} className={styles.dialog}>
      <DialogContent>
        {loading && <Spinner small />}

        <SadFaceIcon className={styles.icon} color='primary' />

        <Typography component='p' variant='body4'>
          {`Cancel ${planName} Plan?`}
        </Typography>

        <Typography component='p' variant='body3'>
          Find out what benefits you will lose if you cancel your subscription:
        </Typography>

        <List>
          <ListItem >
            <CheckIcon />
            <Typography variant='body2' component='span'>
              Your URL <strong>/{roomName}</strong> will become inactive.
            </Typography>
          </ListItem>
          <ListItem >
            <CheckIcon />
            <Typography variant='body2' component='span'>
              All your waiting room content will be lost.
            </Typography>
          </ListItem>
          <ListItem >
            <CheckIcon />
            <Typography variant='body2' component='span'>
              Safe and reliable video visits will be disabled.
            </Typography>
          </ListItem>
          <ListItem >
            <CheckIcon />
            <Typography variant='body2' component='span'>
              All Staff will lose access to their Room URL, Queue, and Settings.
            </Typography>
          </ListItem>
        </List>

        <Box component='div' className={styles.actions}>
          <Button variant='outlined' onClick={handleCancel} className={styles.formButton}>
            Cancel
          </Button>
          <Button variant='contained' onClick={handleConfirm} className={styles.formButton}>
            Yes
          </Button>
        </Box>

        <Typography variant='body1' component='span'>
          {`${planName} Plan will last until the end of the billing period.`}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};