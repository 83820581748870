import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function TextIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M0.282227 0V5H1.53223C1.53223 3.625 2.65723 2.5 4.03223 2.5H7.78223V16.25C7.78223 16.95 7.23223 17.5 6.53223 17.5H5.28223V20H15.2822V17.5H14.0322C13.3322 17.5 12.7822 16.95 12.7822 16.25V2.5H16.5322C17.9072 2.5 19.0322 3.625 19.0322 5H20.2822V0H0.282227Z' />
    </SvgIcon>
  );
}
