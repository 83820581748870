import React, { useEffect, useState } from 'react';

export function useLocalStorageState<T = undefined>(
  key: string,
  initialState?: T | undefined,
): [T, React.Dispatch<React.SetStateAction<T | undefined>>];
export function useLocalStorageState<T>(key: string, initialState: T) {
  const [value, setValue] = useState<T | null>(
    window.localStorage.getItem(key) ? (JSON.parse(window.localStorage.getItem(key)!) as T) : initialState,
  );

  const setStorageValue = (value: T | null): void => {
    window.localStorage.setItem(key, JSON.stringify(value));
    window.dispatchEvent(new Event('localStorage'));
  };

  useEffect(() => {
    const storedValue = window.localStorage.getItem(key);

    if (storedValue || initialState) {
      setStorageValue(storedValue ? (JSON.parse(storedValue!) as T) : initialState);
    }

    const storageHandler = (): void => {
      const item = window.localStorage.getItem(key);
      if (item !== null) {
        setValue(JSON.parse(item) as T);
      } else {
        setValue(initialState);
      }
    };

    window.addEventListener('localStorage', storageHandler);

    return () => window.removeEventListener('localStorage', storageHandler);
  }, []);

  return [value, setStorageValue] as const;
}
