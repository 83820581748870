import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function TrashIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='18' height='21' viewBox='0 0 18 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M17.125 1.25587H12.4375L12.0703 0.525397C11.9925 0.369231 11.8727 0.237867 11.7243 0.146084C11.576 0.0543015 11.4049 0.0057411 11.2305 0.00586622H6.76562C6.59155 0.00519706 6.42081 0.0535764 6.27297 0.145461C6.12512 0.237346 6.00615 0.36902 5.92969 0.525397L5.5625 1.25587H0.875C0.70924 1.25587 0.550268 1.32171 0.433058 1.43892C0.315848 1.55613 0.25 1.71511 0.25 1.88087L0.25 3.13087C0.25 3.29663 0.315848 3.4556 0.433058 3.57281C0.550268 3.69002 0.70924 3.75586 0.875 3.75586H17.125C17.2908 3.75586 17.4497 3.69002 17.5669 3.57281C17.6841 3.4556 17.75 3.29663 17.75 3.13087V1.88087C17.75 1.71511 17.6841 1.55613 17.5669 1.43892C17.4497 1.32171 17.2908 1.25587 17.125 1.25587ZM2.32812 18.248C2.35793 18.7241 2.56803 19.1708 2.91565 19.4974C3.26326 19.824 3.72226 20.0058 4.19922 20.0059H13.8008C14.2777 20.0058 14.7367 19.824 15.0843 19.4974C15.432 19.1708 15.6421 18.7241 15.6719 18.248L16.5 5.00586H1.5L2.32812 18.248Z' />
    </SvgIcon>
  );
}
