/* eslint-disable prettier/prettier */
import { ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';

type FormHeaderProps = {
  items: HeaderItem[];
  rightSlot?: ReactNode;
};

export type HeaderItem = {
  isSelected?: boolean;
  text: string;
  onClick?(): void;
};

export function FormHeader({ items, rightSlot }: FormHeaderProps) {
  return (
    <Stack
      sx={{
        backgroundColor: '#f3f3f3',
        borderRadius: '.5rem .5rem 0 0',
        position: 'relative',
        flexDirection: 'row'
      }}>
      {items.length > 1 &&
        items.map((item: HeaderItem) => (
          <Stack
            key={item.text}
            sx={{
              borderRadius: '.5rem .5rem 0 0',
              padding: '1.3rem 2rem',
              cursor: 'pointer',
              ...(item.isSelected && ({
                color: '#81cfdd',
                backgroundColor: '#ffffff',
              }))
            }}
            onClick={item.onClick}>
            <Typography
              variant='body2'
              sx={{ fontWeight: 700 }}>
              {item.text}
            </Typography>
          </Stack>
        ))}

      {items.length === 1 && (
        <Stack
          key={items[0].text}
          sx={{
            padding: '1.3rem 2rem'
          }}>
          <Typography
            variant='body2'
            sx={{ fontWeight: 700 }}>
            {items[0].text}
          </Typography>
        </Stack>
      )}
      {rightSlot && (
        <Stack
          direction='column'
          alignItems='center'
          justifyContent='center'
          sx={{
            height: '100%',
            position: 'absolute',
            right: 0,
            top: 0,
            bottom: 0,
          }}>
          {rightSlot}
        </Stack>
      )}
    </Stack>
  );
}
