import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function ThreeLinesIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.282227 3.15942C0.282227 2.51909 0.801317 2 1.44165 2H19.1228C19.7631 2 20.2822 2.51909 20.2822 3.15942C20.2822 3.79975 19.7631 4.31884 19.1228 4.31884H1.44165C0.801317 4.31884 0.282227 3.79975 0.282227 3.15942ZM0.282227 8.81108C0.282227 7.93063 0.995976 7.21688 1.87643 7.21688H18.688C19.5685 7.21688 20.2822 7.93063 20.2822 8.81108C20.2822 9.69154 19.5685 10.4053 18.688 10.4053H1.87643C0.995976 10.4053 0.282227 9.69154 0.282227 8.81108ZM2.45614 13.3047C1.25552 13.3047 0.282227 14.278 0.282227 15.4786C0.282227 16.6792 1.25552 17.6525 2.45614 17.6525H18.1083C19.3089 17.6525 20.2822 16.6792 20.2822 15.4786C20.2822 14.278 19.3089 13.3047 18.1083 13.3047H2.45614Z'
      />
    </SvgIcon>
  );
}
