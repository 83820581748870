import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function HeavyCheckIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
      <path
        d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
        fill='#09CC34'
      />
      <path
        d='M11.7332 3.20004L6.13324 8.96004L4.26657 7.04004L2.3999 8.96004L6.13324 12.8L13.5999 5.12004L11.7332 3.20004Z'
        fill='white'
      />
    </SvgIcon>
  );
}
