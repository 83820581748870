import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function BoldIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='17' height='20' viewBox='0 0 17 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M14.0844 9.19643C14.7642 8.36599 15.1835 7.35322 15.2897 6.28527C15.6192 2.87902 12.9464 0 9.61696 0H0.714286C0.524845 0 0.343164 0.0752549 0.209209 0.209209C0.0752549 0.343164 0 0.524845 0 0.714286V2.85714C0 3.04658 0.0752549 3.22826 0.209209 3.36222C0.343164 3.49617 0.524845 3.57143 0.714286 3.57143H2.13705V16.4286H0.714286C0.524845 16.4286 0.343164 16.5038 0.209209 16.6378C0.0752549 16.7717 0 16.9534 0 17.1429V19.2857C0 19.4752 0.0752549 19.6568 0.209209 19.7908C0.343164 19.9247 0.524845 20 0.714286 20H10.0589C13.2196 20 16.0473 17.6897 16.3536 14.5357C16.5652 12.3728 15.6219 10.4259 14.0844 9.19643ZM5.69911 3.57143H9.61696C10.1853 3.57143 10.7303 3.79719 11.1322 4.19906C11.5341 4.60092 11.7598 5.14596 11.7598 5.71429C11.7598 6.28261 11.5341 6.82765 11.1322 7.22952C10.7303 7.63138 10.1853 7.85714 9.61696 7.85714H5.69911V3.57143ZM9.61696 16.4286H5.69911V11.4286H9.61696C10.28 11.4286 10.9159 11.692 11.3847 12.1608C11.8536 12.6296 12.117 13.2655 12.117 13.9286C12.117 14.5916 11.8536 15.2275 11.3847 15.6963C10.9159 16.1652 10.28 16.4286 9.61696 16.4286Z' />
    </SvgIcon>
  );
}
