import { useEffect, useMemo } from 'react';
import { Card, CardContent, CardHeader, IconButton } from '@mui/material';
import classnames from 'classnames';

import { CrossIcon } from 'assets';
import { ApiWaitingRoomLinkProfile } from 'types';
import { useAppSelector } from 'store';
import { selectWaitingRoomProfile } from 'store/waitingRoom';
import { MessagesList, ChatInput } from 'components';
import { useChat, useChatState, useRoomRightSidebar } from 'hooks';

import styles from './GroupChat.module.scss';

type GroupChatProps = {
  className?: string;
};

export function GroupChat({ className }: GroupChatProps): JSX.Element {
  const { chatName } = useChatState(true);
  const { roomConversation, closeRoomChat } = useChat();
  const { hideSidebar } = useRoomRightSidebar();
  const waitingRoomProfile: ApiWaitingRoomLinkProfile | null = useAppSelector(selectWaitingRoomProfile);

  const { preferedDisplayName } = waitingRoomProfile || {};

  const preferredChatName = useMemo(() => {
    return preferedDisplayName && preferedDisplayName !== '' ? preferedDisplayName : chatName;
  }, [chatName, preferedDisplayName]);

  useEffect(() => {
    return () => {
      closeRoomChat();
    };
  }, []);

  const handleTyping = (): void => {
    roomConversation?.typing();
  };

  const handleSendMessage = (message: string | FormData): Promise<number> => {
    return roomConversation!.sendMessage(message);
  };

  const handleClose = (): void => {
    hideSidebar();
    closeRoomChat();
  };

  return (
    <>
      {roomConversation && (
        <>
          <Card className={classnames(styles.groupChat, className)}>
            <CardHeader
              title={preferredChatName}
              action={
                <IconButton edge='end' aria-label='close' onClick={handleClose}>
                  <CrossIcon className={styles.close} color='primary' />
                </IconButton>
              }
            />
            <CardContent>
              <MessagesList className={styles.messagesList} isGroup={true} />
              <ChatInput isGroup={true} onTyping={handleTyping} sendMessage={handleSendMessage} />
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
}
