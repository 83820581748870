import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Theme,
  Typography,
  Box,
  useMediaQuery,
  Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import {
  ArrowBackIcon,
  ArrowForwardIcon,
  ChevronRightIcon,
  CircleCrossIcon,
  FirstPageIcon,
  HeavyCheckIcon,
  InfoIcon,
  LastPageIcon,
} from 'assets';
import { ADMIN, CLINIC_ADMIN } from 'constants/roles';

import { IFirstLoginTooltipsContext } from 'context';
import { IApiRequests, useApiRequests, useDialog, useFirstLoginTooltips, useSubscription } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store';
import { getUser, selectUser } from 'store/user';
import {
  ApiUser,
  StaffItem,
  StaffResponse,
  InvitationStatus,
  InviteRequest,
  ApiUserTooltipStagePassedEnum,
} from 'types';
import { Spinner, TwoOptionsDialog, BackButton, Info, FirstLoginTooltip } from 'components';
import { InvitePopup } from './components';
import { MoreOptions } from './components/MoreOptions/MoreOptions';
import { FormHeader } from '../FormHeader';

import styles from './StaffSettings.module.scss';

const rowsPerPageOptions: number[] = [10, 20, 30];

type StaffSettingsProps = {
  onBackToSettingsClick(): void;
};

export function StaffSettings({ onBackToSettingsClick }: StaffSettingsProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { showDialog } = useDialog();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const user: ApiUser = useAppSelector(selectUser)!;
  const dispatch = useAppDispatch();
  const subscription = useSubscription();

  const {
    getStaff,
    invitationResend,
    invite,
    removeStaff,
    removeInvitation,
    manageLicense,
    updateUser,
    allowDisablePayments,
  }: IApiRequests = useApiRequests();

  const [loading, setLoading] = useState<boolean>(true);
  const [staff, setStaff] = useState<StaffResponse | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [isInvitePopupOpen, setIsInvitePopupOpen] = useState<boolean>(false);
  const { tooltipsState, accept }: IFirstLoginTooltipsContext = useFirstLoginTooltips();

  const tableRef = useRef<HTMLDivElement>(null);

  const isAdmin: boolean = useMemo(() => {
    return user?.clinicUser.roles.some((role: string) => role === ADMIN || role === CLINIC_ADMIN) ?? false;
  }, [user]);

  useEffect(() => {
    if (!loading && user.tooltipStagePassed === ApiUserTooltipStagePassedEnum.InviteStaff && tableRef.current) {
      tableRef.current!.scrollLeft = tableRef.current!.scrollWidth;
    }
  }, [user, loading]);

  useEffect(() => {
    if (!isAdmin) {
      setLoading(false);
      return;
    }

    getStaff({ count: rowsPerPage - 1, offset: 0 })
      .then((response: StaffResponse) => {
        setStaff(response);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user]);

  const paginationText: string = useMemo(() => {
    if (!staff) {
      return '';
    }

    if (staff!.offset === 0) {
      return `1-${staff!.items.length + 1} of ${staff!.totalCount + 1}`;
    }

    return `${staff!.offset + 2}-${staff!.offset + 1 + staff!.items.length} of ${staff!.totalCount + 1}`;
  }, [staff]);

  const getInvitationStatusText = (status: InvitationStatus): string => {
    switch (status) {
      case InvitationStatus.Inactive:
        return 'Inactive';
      case InvitationStatus.Pending:
        return 'Pending';
      case InvitationStatus.Active:
      default:
        return 'Active';
    }
  };

  const showNotEnoughtSeatsPopupIfNeed = (): boolean => {
    if ((subscription?.availableSeats ?? 0) < 1) {
      const modal = showDialog(TwoOptionsDialog, {
        text: (
          <>
            <InfoIcon color='primary' sx={{ fontSize: '6.1rem', margin: 'auto' }} />

            <Typography variant='body2' component='p'>
              You do not have enough seats to do this.
              <br />
              You can either remove a seat from another provider or add more seats.
            </Typography>
          </>
        ),
        confirmText: 'Add seat',
        onConfirm: () => {
          modal.destroy();
          navigate('/settings#subscriptions');
        },
      });

      return true;
    }

    return false;
  };

  const allowOrDisablePayments = (clinicUserId: string, allow: boolean): Promise<any> => {
    return allowDisablePayments({
      clinicUserId: clinicUserId,
      allow,
    })
      .then(() => {
        return getStaff({ count: rowsPerPage - 1, offset: staff!.offset }).then((newStaff: StaffResponse) => {
          setStaff(newStaff);
        });
      })
      .then(() => {
        return dispatch(getUser());
      });
  };

  const handleRowsPerPageChange = (event: SelectChangeEvent<string>): void => {
    setLoading(true);

    const newRowsPerPage: number = +event.target.value;

    getStaff({ count: newRowsPerPage - 1, offset: 0 })
      .then((newStaff: StaffResponse) => {
        setStaff(newStaff);
        setRowsPerPage(newRowsPerPage);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInvitationResend = (invitationId: string): void => {
    setLoading(true);

    invitationResend(invitationId)
      .then(() => {
        return getStaff({ count: rowsPerPage - 1, offset: staff!.offset }).then((newStaff: StaffResponse) => {
          setStaff(newStaff);
        });
      })
      .then(() => subscription?.fetchSubscriptionInfo())
      .then(() => {
        enqueueSnackbar('Resend Successfully', { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeactivate = (item: StaffItem): void => {
    const manageLicensePromise = (): Promise<void> => {
      setLoading(true);

      return manageLicense({
        id: item.clinicUserId ?? item.invitationId,
        isInvite: !item.clinicUserId,
        hasLicense: false,
      })
        .then(() => {
          return getStaff({ count: rowsPerPage - 1, offset: staff!.offset }).then((newStaff: StaffResponse) => {
            setStaff(newStaff);
          });
        })
        .then(() => {
          return dispatch(getUser());
        })
        .then(() => {
          enqueueSnackbar('Deactivate Successful', { variant: 'success' });
        })
        .catch((error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const modal = showDialog(TwoOptionsDialog, {
      title: 'DEACTIVATE',
      text: (
        <Typography variant='body2' component='p'>
          Deactivate and unseat <b>{item.name}</b>?
        </Typography>
      ),
      confirmText: 'Deactvate',
      onConfirm: () => {
        modal.destroy();
        manageLicensePromise();
      },
    });
  };

  const handleActivate = (item: StaffItem): void => {
    const isNotEnought: boolean = showNotEnoughtSeatsPopupIfNeed();
    if (isNotEnought) {
      return;
    }

    const manageLicensePromise = (): Promise<void> => {
      setLoading(true);

      return manageLicense({
        id: item.clinicUserId ?? item.invitationId,
        isInvite: !item.clinicUserId,
        hasLicense: true,
      })
        .then(() => {
          return getStaff({ count: rowsPerPage - 1, offset: staff!.offset }).then((newStaff: StaffResponse) => {
            setStaff(newStaff);
          });
        })
        .then(() => {
          return dispatch(getUser());
        })
        .then(() => {
          enqueueSnackbar('Activate Successful', { variant: 'success' });
        })
        .catch((error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const modal = showDialog(TwoOptionsDialog, {
      title: 'ACTIVATE',
      text: (
        <Typography variant='body2' component='p'>
          Activate and assign seat to <b>{item.name}</b>?
        </Typography>
      ),
      confirmText: 'Actvate',
      onConfirm: () => {
        modal.destroy();
        manageLicensePromise();
      },
    });
  };

  const handleAllowPayments = (item: StaffItem): void => {
    setLoading(true);

    allowOrDisablePayments(item.clinicUserId, true)
      .then(() => {
        enqueueSnackbar('Allow Successful', { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDisablePayments = (item: StaffItem): void => {
    setLoading(true);

    allowOrDisablePayments(item.clinicUserId, false)
      .then(() => {
        enqueueSnackbar('Disable Successful', { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePreviousPageClick = (): void => {
    if (staff!.offset === 0) {
      return;
    }

    setLoading(true);

    getStaff({
      count: staff!.offset <= rowsPerPage ? rowsPerPage - 1 : rowsPerPage,
      offset: staff!.offset <= rowsPerPage ? 0 : staff!.offset - rowsPerPage,
    })
      .then((newStaff: StaffResponse) => {
        setStaff(newStaff);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNextPageClick = (): void => {
    if (rowsPerPage + (staff!.offset === 0 ? -1 : staff!.offset) >= staff!.totalCount) {
      return;
    }

    setLoading(true);

    getStaff({
      count: rowsPerPage,
      offset: staff!.offset === 0 ? rowsPerPage - 1 : staff!.offset + rowsPerPage,
    })
      .then((newStaff: StaffResponse) => {
        setStaff(newStaff);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFirstPageClick = (): void => {
    if (staff!.offset === 0) {
      return;
    }

    setLoading(true);

    getStaff({ count: rowsPerPage - 1, offset: 0 })
      .then((newStaff: StaffResponse) => {
        setStaff(newStaff);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleLastPageClick = (): void => {
    if (rowsPerPage + (staff!.offset === 0 ? -1 : staff!.offset) >= staff!.totalCount) {
      return;
    }

    setLoading(true);

    const totalPages: number = (staff!.totalCount + 1) / rowsPerPage;
    const lastPage: number = Math.ceil(totalPages) === totalPages ? totalPages - 1 : Math.ceil(totalPages) - 1;
    const newOffset: number = lastPage * rowsPerPage - 1;

    getStaff({ count: rowsPerPage, offset: newOffset })
      .then((newStaff: StaffResponse) => {
        setStaff(newStaff);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOpenIvitePopup = (): void => {
    const isNotEnought: boolean = showNotEnoughtSeatsPopupIfNeed();
    if (isNotEnought) {
      return;
    }

    setIsInvitePopupOpen(true);
  };

  const handleCloseIvitePopup = (): void => {
    setIsInvitePopupOpen(false);
  };

  const handleInviteUser = (model: InviteRequest): Promise<void> => {
    return invite(model).then(() => {
      return getStaff({ count: rowsPerPage - 1, offset: 0 })
        .then((response: StaffResponse) => {
          setStaff(response);
          setIsInvitePopupOpen(false);
        })
        .then(() => dispatch(getUser()).unwrap())
        .then(() => Promise.resolve());
    });
  };

  const handleRemoveStaff = (item: StaffItem): void => {
    const removeStaffCallback: () => Promise<void> = item.clinicUserId
      ? () => removeStaff(item.clinicUserId)
      : () => removeInvitation(item.invitationId);

    const removeStaffPromise = (): Promise<void> => {
      setLoading(true);

      return removeStaffCallback()
        .then(() => {
          return getStaff({ count: rowsPerPage - 1, offset: 0 }).then((response: StaffResponse) => {
            setStaff(response);
          });
        })
        .then(() => dispatch(getUser()).unwrap())
        .then(() => Promise.resolve())
        .catch((error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        })
        .finally(() => setLoading(false));
    };

    const modal = showDialog(TwoOptionsDialog, {
      title: 'REMOVE STAFF',
      text: (
        <Typography variant='body2' component='p'>
          Remove <b>{item.name}</b> from staff ?
        </Typography>
      ),
      confirmText: 'Remove',
      onConfirm: () => {
        modal.destroy();
        removeStaffPromise();
      },
    });
  };

  return (
    <>
      {isMobile && <BackButton text='Back to Settings' onClick={onBackToSettingsClick} />}

      <div className={styles.container}>
        <FormHeader items={[{ text: 'STAFF' }]} />
        {loading ? (
          <Spinner small />
        ) : (
          <div className={styles.main}>
            {isAdmin ? (
              <>
                <Stack className={styles.header}>
                  <Stack className={styles.headerSeats} direction='row'>
                    <Stack>
                      <Typography variant='body2'>
                        <b>{subscription?.availableSeats ?? 0}</b> Available Seats
                      </Typography>
                    </Stack>

                    <Stack direction='row' spacing='1rem' alignItems='center'>
                      <Typography variant='body2'>
                        <b>{subscription?.totalSeats ?? 0}</b> Total Seats
                      </Typography>

                      <Info text='Manage your seats in subscriptions' />
                    </Stack>
                  </Stack>

                  <FirstLoginTooltip
                    text='Click here to invite other providers to join your organization.'
                    open={user.tooltipStagePassed === ApiUserTooltipStagePassedEnum.WelcomePage}
                    onClick={accept}
                    actionText='Done'
                  >
                    <Button variant='contained' className={styles.inviteButton} onClick={handleOpenIvitePopup}>
                      Invite
                    </Button>
                  </FirstLoginTooltip>
                </Stack>

                <div className={styles.table} ref={tableRef}>
                  <Grid className={styles.tableHeader}>
                    <Typography variant='subtitle2' component='span'>
                      Name
                    </Typography>

                    <Typography variant='subtitle2' component='span'>
                      Email
                    </Typography>

                    <Typography variant='subtitle2' component='span'>
                      Room Link
                    </Typography>

                    <Typography variant='subtitle2' component='span'>
                      Clinic Role
                    </Typography>

                    <FirstLoginTooltip
                      text='Seat indicates whether this staff member is currently occupying a seat or not. A seat represents an active user account within the system.'
                      open={
                        user.tooltipStagePassed === ApiUserTooltipStagePassedEnum.InviteStaff &&
                        tooltipsState === 0 &&
                        (staff?.items?.length ?? 0) > 0
                      }
                      onClick={accept}
                      numberText='1 of 3'
                    >
                      <Typography variant='subtitle2' component='span'>
                        Seat
                      </Typography>
                    </FirstLoginTooltip>

                    <FirstLoginTooltip
                      text='Status indicates active, inactive or pending seats. Pending means an invite has been sent out but not accepted, the seat will be taken until they are removed. Inactive profiles will not occupy a seat.'
                      open={
                        user.tooltipStagePassed === ApiUserTooltipStagePassedEnum.InviteStaff &&
                        tooltipsState === 1 &&
                        (staff?.items?.length ?? 0) > 0
                      }
                      onClick={accept}
                      numberText='2 of 3'
                    >
                      <Typography variant='subtitle2' component='span'>
                        Status
                      </Typography>
                    </FirstLoginTooltip>
                  </Grid>

                  {staff?.offset === 0 && (
                    <Grid className={styles.tableItem}>
                      <Tooltip
                        classes={{ tooltip: styles.tooltip }}
                        title={user?.firstName + ' ' + user?.lastName}
                        placement='bottom'
                      >
                        <Typography variant='body1'>
                          {user?.firstName} {user?.lastName}
                        </Typography>
                      </Tooltip>

                      <Tooltip classes={{ tooltip: styles.tooltip }} title={user?.email} placement='bottom'>
                        <Typography variant='body1'>{user?.email}</Typography>
                      </Tooltip>

                      <Tooltip
                        classes={{ tooltip: styles.tooltip }}
                        title={user?.clinicUser.roomName}
                        placement='bottom'
                      >
                        <Typography variant='body1'>{user.clinicUser.roomName}</Typography>
                      </Tooltip>

                      <Typography variant='body1'>Admin</Typography>

                      <HeavyCheckIcon />

                      <Typography variant='body1'>Active</Typography>
                    </Grid>
                  )}

                  {staff?.items?.map((item: StaffItem, key: number) => (
                    <Grid key={item.invitationId} className={styles.tableItem}>
                      <Tooltip classes={{ tooltip: styles.tooltip }} title={item.name} placement='bottom'>
                        <Typography variant='body1'>{item.name}</Typography>
                      </Tooltip>

                      <Tooltip classes={{ tooltip: styles.tooltip }} title={item.email} placement='bottom'>
                        <Typography variant='body1'>{item.email}</Typography>
                      </Tooltip>

                      <Tooltip classes={{ tooltip: styles.tooltip }} title={item.roomName} placement='bottom'>
                        <Typography variant='body1'>{item.roomName}</Typography>
                      </Tooltip>

                      <Typography variant='body1'>Provider</Typography>

                      {item.hasLicense ? (
                        <HeavyCheckIcon className={styles.seatStatusIcon} />
                      ) : (
                        <CircleCrossIcon className={styles.seatStatusIcon} />
                      )}

                      <Typography variant='body1'>{getInvitationStatusText(item.status)}</Typography>

                      {key === 0 ? (
                        <FirstLoginTooltip
                          text='Click here to manage your staff member’s status.'
                          open={
                            user.tooltipStagePassed === ApiUserTooltipStagePassedEnum.InviteStaff &&
                            tooltipsState === 2 &&
                            (staff?.items?.length ?? 0) > 0
                          }
                          onClick={accept}
                          numberText='3 of 3'
                          actionText='Done'
                        >
                          <div>
                            <MoreOptions
                              className={styles.moreOptionsButton}
                              item={item}
                              onActivate={handleActivate}
                              onDeactivate={handleDeactivate}
                              onRemoveStaff={handleRemoveStaff}
                              onAllowPayments={handleAllowPayments}
                              onDisablePayments={handleDisablePayments}
                              onResend={handleInvitationResend}
                            />
                          </div>
                        </FirstLoginTooltip>
                      ) : (
                        <MoreOptions
                          className={styles.moreOptionsButton}
                          item={item}
                          onActivate={handleActivate}
                          onDeactivate={handleDeactivate}
                          onRemoveStaff={handleRemoveStaff}
                          onAllowPayments={handleAllowPayments}
                          onDisablePayments={handleDisablePayments}
                          onResend={handleInvitationResend}
                        />
                      )}
                    </Grid>
                  ))}
                </div>

                <div className={styles.pagination}>
                  <div className={styles.rowsPerPage}>
                    <Typography variant='body1'>Rows per page</Typography>

                    <Select
                      className={styles.select}
                      variant='outlined'
                      value={rowsPerPage.toString()}
                      onChange={handleRowsPerPageChange}
                      IconComponent={ChevronRightIcon}
                    >
                      {rowsPerPageOptions.map((option: number) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>

                  <Typography variant='body1'>{paginationText}</Typography>

                  <Stack direction='row' className={styles.navigationButtons}>
                    <IconButton onClick={handleFirstPageClick}>
                      <FirstPageIcon />
                    </IconButton>
                    <IconButton onClick={handlePreviousPageClick}>
                      <ArrowBackIcon />
                    </IconButton>
                    <IconButton onClick={handleNextPageClick}>
                      <ArrowForwardIcon />
                    </IconButton>
                    <IconButton onClick={handleLastPageClick}>
                      <LastPageIcon />
                    </IconButton>
                  </Stack>
                </div>
              </>
            ) : (
              <Box className={styles.notAvailable}>
                <Typography variant='body1'>For information about staff, contact your administrator.</Typography>
              </Box>
            )}
          </div>
        )}
      </div>

      <InvitePopup open={isInvitePopupOpen} onClose={handleCloseIvitePopup} onInvite={handleInviteUser} />
    </>
  );
}
