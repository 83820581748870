import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { ConnectOptions } from 'twilio-video';

import {
  useHandleRoomDisconnection,
  useHandleTrackPublicationFailed,
  useLocalTracks,
  useRestartAudioTrackOnDeviceChange,
  useRoom,
  useScreenShareToggle,
  useSignalrConnection,
} from 'hooks';
import { ApiUser, ErrorCallback, HubPatient } from 'types';
import { ISignalrConnectionContext } from 'context';
import { VideoContext } from './context';
import { useSnackbar } from 'notistack';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';

type VideoProviderProps = {
  options?: ConnectOptions;
  children?: JSX.Element;
};

export function VideoProvider({ children, options }: VideoProviderProps) {
  const onErrorCallback: ErrorCallback = useCallback((error) => {
    // eslint-disable-next-line no-console
    console.log(`ERROR: ${error.message}`, error);
  }, []);

  const {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  } = useLocalTracks();
  const { enqueueSnackbar } = useSnackbar();
  const { room, isConnecting, connect } = useRoom(localTracks, onErrorCallback, options);
  const { events, signalrConnectionId }: ISignalrConnectionContext = useSignalrConnection();
  const user: ApiUser | null = useAppSelector(selectUser);

  const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(room, onErrorCallback);
  const [userJoined, setUserJoined] = useState<HubPatient | null>(null);

  const prevSignalrConnectionId: MutableRefObject<string> = useRef<string>(signalrConnectionId);

  useEffect(() => {
    if (userJoined) {
      // eslint-disable-next-line no-console
      console.log('onUserJoined');
      if (user?.clinicUser?.receiveMessagesInMeeting || !room) {
        enqueueSnackbar(`${userJoined.userName} has joined your queue`, { variant: 'info' });
      }

      setUserJoined(null);
    }
  }, [userJoined, user, room]);

  useEffect(() => {
    events.on('userJoined', (data: HubPatient) => setUserJoined(data));
  }, [events]);

  useEffect(() => {
    return () => {
      if (!signalrConnectionId && prevSignalrConnectionId.current) {
        removeLocalVideoTrack();
        removeLocalAudioTrack();
      }

      prevSignalrConnectionId.current = signalrConnectionId;
    };
  }, [signalrConnectionId, removeLocalVideoTrack, removeLocalAudioTrack]);

  // Register callback functions to be called on room disconnect.
  useHandleRoomDisconnection(
    room,
    onErrorCallback,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isSharingScreen,
    toggleScreenShare,
  );
  useHandleTrackPublicationFailed(room, onErrorCallback);
  useRestartAudioTrackOnDeviceChange(localTracks);

  return (
    <VideoContext.Provider
      value={{
        room,
        localTracks,
        isConnecting,
        connect,
        getLocalVideoTrack,
        getLocalAudioTrack,
        isAcquiringLocalTracks,
        removeLocalVideoTrack,
        removeLocalAudioTrack,
        isSharingScreen,
        toggleScreenShare,
        getAudioAndVideoTracks,
        onError: onErrorCallback,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
}
