import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function VideoCameraIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='28' height='20' viewBox='0 0 28 20' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.3158 0C3.20731 0 2.14422 0.451529 1.3604 1.25526C0.576577 2.05898 0.13623 3.14907 0.13623 4.28571V15.7143C0.13623 16.8509 0.576577 17.941 1.3604 18.7447C2.14422 19.5485 3.20731 20 4.3158 20H16.8545C17.963 20 19.0261 19.5485 19.8099 18.7447C20.5938 17.941 21.0341 16.8509 21.0341 15.7143V13.4486L24.4335 16.9343C25.7487 18.2843 28.0001 17.3271 28.0001 15.4186V4.58143C28.0001 2.67286 25.7487 1.71571 24.4335 3.06714L21.0341 6.55V4.28571C21.0341 3.14907 20.5938 2.05898 19.8099 1.25526C19.0261 0.451529 17.963 0 16.8545 0H4.3158Z'
      />
    </SvgIcon>
  );
}
