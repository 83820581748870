import * as Yup from 'yup';
import YupPassword from 'yup-password';
import { useAppSelector } from 'store';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, Typography } from '@mui/material';

import { FormInput, Info } from 'components';
import { RegisterByInvitationRequest, RegisterRequest } from 'types';
import { InviteData, selectInvite } from 'store/invite';

import styles from './RegisterProfileInfoStep.module.scss';

YupPassword(Yup);

const schema = Yup.object().shape({
  firstName: Yup.string().max(50, 'Max length is 50 character').required(),
  lastName: Yup.string().max(50, 'Max length is 50 character').required(),
  email: Yup.string().email().required(),
  organization: Yup.string(),
  password: Yup.string()
    .min(
      8,
      'password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special character',
    )
    .minLowercase(1, 'password must contain at least 1 lower case letter')
    .minUppercase(1, 'password must contain at least 1 upper case letter')
    .minNumbers(1, 'password must contain at least 1 number')
    .minSymbols(1, 'password must contain at least 1 special character')
    .required(),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match'),
});

type RegisterProfileInfoStepProps = {
  onRegister(model: RegisterByInvitationRequest): Promise<void>;
};

export function RegisterProfileInfoStep({ onRegister }: RegisterProfileInfoStepProps) {
  const invite: InviteData = useAppSelector(selectInvite)!;

  const form = useForm<RegisterRequest>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: invite.firstName,
      lastName: invite.lastName,
      organization: invite.organizationName,
      email: invite.email,
    },
    mode: 'all',
  });

  const handleSubmit = (): void => {
    const model: RegisterByInvitationRequest = {
      invitationId: invite.invitationId,
      firstName: form.getValues().firstName,
      lastName: form.getValues().lastName,
      password: form.getValues().password,
      passwordConfirmation: form.getValues().passwordConfirmation,
    };

    onRegister(model);
  };

  return (
    <div className={styles.form}>
      <div className={styles.rowWithTwoInputs}>
        <Stack className={styles.row}>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Typography component='p' variant='body2'>
              First Name <span className={styles.asterisk}>*</span>
            </Typography>
          </Stack>
          <FormInput name='firstName' control={form.control} />
        </Stack>

        <Stack className={styles.row}>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Typography component='p' variant='body2'>
              Last Name <span className={styles.asterisk}>*</span>
            </Typography>
          </Stack>
          <FormInput name='lastName' control={form.control} />
        </Stack>
      </div>

      <Stack className={styles.row}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography component='p' variant='body2'>
            Email <span className={styles.asterisk}>*</span>
          </Typography>
        </Stack>
        <FormInput name='email' control={form.control} disabled />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography component='p' variant='body2'>
            Provider/Organization Name <span className={styles.asterisk}>*</span>
          </Typography>
          <Info
            children={
              <Typography variant='body6'>
                Enter your organization
                <br />
                name. If you are a sole <br />
                practitioner, just enter <br />
                your business name.
              </Typography>
            }
          />
        </Stack>
        <FormInput name='organization' control={form.control} disabled />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography component='p' variant='body2'>
            Password <span className={styles.asterisk}>*</span>
          </Typography>
          <Info text='Password must be at least 8 characters including at least one uppercase and lowercase letter, one symbol and one number' />
        </Stack>
        <FormInput name='password' control={form.control} type='password' />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography component='p' variant='body2'>
            Confirm Password <span className={styles.asterisk}>*</span>
          </Typography>
        </Stack>
        <FormInput name='passwordConfirmation' control={form.control} type='password' />
      </Stack>

      <Stack className={styles.row} alignItems='flex-end'>
        <Button
          id='RegisterProfileInfoStepNext'
          variant='contained'
          onClick={handleSubmit}
          disabled={!form.formState.isValid}
          className={styles.formButton}
          type='submit'
        >
          Next
        </Button>
      </Stack>
    </div>
  );
}
