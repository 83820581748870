import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function EraserIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M20.3537 18.3466H11.502L15.0909 14.7577L15.0926 14.7562L15.0941 14.7545L20.9383 8.9104C21.4027 8.44489 21.6635 7.81421 21.6635 7.15668C21.6635 6.49915 21.4027 5.86847 20.9383 5.40296L16.2617 0.726395C15.7966 0.261291 15.1658 0 14.508 0C13.8502 0 13.2194 0.261291 12.7543 0.726395L6.90956 6.57111L6.9085 6.57207L6.90754 6.57313L1.06282 12.4179C0.597717 12.883 0.336426 13.5138 0.336426 14.1716C0.336426 14.8293 0.597717 15.4602 1.06282 15.9253L4.89552 19.7579C5.05059 19.9129 5.26087 20 5.48013 20H20.3537C20.5729 20 20.7832 19.9129 20.9383 19.7579C21.0933 19.6028 21.1804 19.3925 21.1804 19.1733C21.1804 18.954 21.0933 18.7438 20.9383 18.5887C20.7832 18.4337 20.5729 18.3466 20.3537 18.3466ZM13.9235 1.89561C14.0002 1.81883 14.0914 1.75792 14.1917 1.71636C14.2919 1.6748 14.3994 1.65341 14.508 1.65341C14.6166 1.65341 14.7241 1.6748 14.8244 1.71636C14.9246 1.75792 15.0158 1.81883 15.0925 1.89561L19.7691 6.57217C19.9239 6.72733 20.0108 6.93756 20.0108 7.15673C20.0108 7.37591 19.9239 7.58613 19.7691 7.74129L14.508 13.0024L8.66238 7.15673L13.9235 1.89561Z' />
    </SvgIcon>
  );
}
