import React, { useMemo } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import classnames from 'classnames';
import { BackgroundEffectsIcon } from 'assets';
import { ControlTooltip } from 'components';

import styles from './ToggleBackgroundEffectsButton.module.scss';

type ToggleBackgroundEffectsButtonProps = {
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
  iconColor?: string;
  withTooltip?: boolean;
  withLabel?: boolean;
  onClick: () => void;
};

export function ToggleBackgroundEffectsButton({
  disabled,
  className,
  iconClassName,
  iconColor,
  withTooltip,
  withLabel,
  onClick,
}: ToggleBackgroundEffectsButtonProps) {
  const tooltipMessage = useMemo(() => {
    let tooltipMessage = '';

    if (withTooltip) {
      tooltipMessage = 'Blur/Background';
    }

    return tooltipMessage;
  }, [withTooltip]);

  return (
    <ControlTooltip title={tooltipMessage} isDisabled={disabled}>
      <Box component='span' display='block' className={classnames(styles.deviceButton, className)}>
        <IconButton
          disabled={disabled}
          className={classnames(
            { [styles.deviceButton]: !withLabel, [styles.deviceButtonWithLabel]: withLabel },
            className,
          )}
          onClick={onClick}
        >
          <BackgroundEffectsIcon
            className={classnames(styles.icon, iconClassName)}
            sx={{ color: iconColor || '#fff' }}
          />
          {withLabel && (
            <Typography component='p' variant='body2' sx={{ color: '#FFF' }} ml='10px'>
              Blur/Background
            </Typography>
          )}
        </IconButton>
      </Box>
    </ControlTooltip>
  );
}
