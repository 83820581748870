import React from 'react';
import classnames from 'classnames';
import { Button, DialogContent, Theme, Typography, useMediaQuery } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';

import { noop } from 'utils';

import styles from './CheckedInDialog.module.scss';

export interface CheckedInDialogProps extends DialogProps {
  onConfirm: () => void;
}

export function CheckedInDialog({ onConfirm, ...rest }: CheckedInDialogProps) {
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Dialog
      {...rest}
      className={classnames(styles.dialog, {
        [styles.dialogTablet]: isTablet,
      })}
      onClose={noop}
    >
      <DialogContent>
        <Typography component='p' variant='h4'>
          Checked In
        </Typography>
        <Typography component='p' variant='body5'>
          Your provider has been notified that you are here.
          <br />
          Thank you for your patience.
        </Typography>

        <Button variant='contained' onClick={onConfirm} className={styles.formButton}>
          Ok
        </Button>

        <Typography component='p' variant='body6'>
          If this is a medical or psychiatric emergency call 911
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
