import { Dialog, Theme, useMediaQuery } from '@mui/material';

import { SettingsMenu } from 'components';
import { IAppSettingsContext } from 'context';
import { useAppSettings } from 'hooks';

import styles from './ModulesContainer.module.scss';
import { withOutsideClickPreventer } from 'utils';

export function ModulesContainer() {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const { open, closeSettings }: IAppSettingsContext = useAppSettings();

  if (isDesktop || !open) return null;

  return (
    <Dialog className={styles.dialog} open={open} onClose={withOutsideClickPreventer(closeSettings)}>
      <div className={styles.container}>
        <SettingsMenu className={styles.settings} withBackground onClose={closeSettings} />
      </div>
    </Dialog>
  );
}
