import { useEffect, useRef, MutableRefObject } from 'react';
import { Stack } from '@mui/material';
import { Message } from '@twilio/conversations';
import moment from 'moment';
import classnames from 'classnames';

import { useChatState, useChat } from 'hooks';

import { MessageInfo } from '../MessageInfo';
import { ChatMessage } from '../ChatMessage';

import styles from './MessagesList.module.scss';

type MessagesListProps = {
  isGroup: boolean;
  className?: string;
};

export function MessagesList({ isGroup, className }: MessagesListProps): JSX.Element {
  const { messages, participants } = useChatState(isGroup);
  const { identity } = useChat();

  const scrollRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);

  const getFormatedTime = (date: Date | undefined | null): string => {
    return date ? moment(date).format('YY-M-D-h:mm A') : '';
  };

  useEffect(() => {
    if (messages) {
      scrollRef.current!.scrollTop = scrollRef.current!.scrollHeight;
    }
  }, [messages]);

  return (
    <Stack
      className={classnames(styles.messages, className)}
      direction='column'
      alignItems='flex-start'
      spacing='10px'
      ref={scrollRef}
    >
      {messages.map((message: Message, index: number) => {
        const time: string = getFormatedTime(message.dateCreated!);
        const previousTime: string = getFormatedTime(messages[index - 1]?.dateCreated);

        const shouldDisplayMessageInfo: boolean =
          time !== previousTime || message.author !== messages[index - 1]?.author;

        const isMyMessage: boolean = message.author === identity;
        const author: string | undefined | null =
          isGroup && participants.size > 2 ? (isMyMessage ? 'You' : participants.get(message.author ?? '')) : null;

        return (
          <div key={index} className={isMyMessage ? styles.messageContainerMy : ''}>
            {shouldDisplayMessageInfo && (
              <MessageInfo dateCreated={message.dateCreated!} isMy={isMyMessage} author={author ?? ''} />
            )}
            <ChatMessage message={message} isMy={isMyMessage} />
          </div>
        );
      })}
    </Stack>
  );
}
