/* eslint-disable prettier/prettier */
import { useMemo, ReactNode } from 'react';
import classnames from 'classnames';
import { LocalVideoTrack, Participant, RemoteAudioTrack, RemoteParticipant, RemoteVideoTrack } from 'twilio-video';
import { Box, Typography } from '@mui/material';
import {
  useIsTrackEnabled,
  useIsTrackSwitchedOff,
  useMutedParticipants,
  useParticipantIsReconnecting,
  useParticipantNQ,
  usePublications,
  useRoomHost,
  useRoomState,
  useSelectedParticipant,
  useTrack,
} from 'hooks';
import { getPreferredFullName, parseParticipantIdentity } from 'utils';
import { StarIcon, UserIcon, MicOffIcon, NQIcon, PinIcon } from 'assets';
import { ParticipantDotMenu, ReconnectingAnimation } from 'components';
import { IMutedParticipantsContext } from 'context';
import { ApiUser, ApiWaitingRoomLinkProfile } from 'types';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { selectWaitingRoomProfile } from 'store/waitingRoom';

import styles from './ParticipantInfo.module.scss';

interface ParticipantInfoProps {
  participant: Participant;
  children: ReactNode;
  hideParticipant?: boolean;
  withIdentity?: boolean;
  withBorder?: boolean;
  isLocalParticipant?: boolean;
  dotMenuPosition?: 'top' | 'bottom';
}

export default function ParticipantInfo({
  participant,
  children,
  hideParticipant,
  withIdentity,
  withBorder,
  isLocalParticipant,
  dotMenuPosition = 'top',
}: ParticipantInfoProps) {
  const publications = usePublications(participant);
  const { isHost } = useRoomHost();
  const [selectedParticipant] = useSelectedParticipant();
  const { mutedParticipantsId }: IMutedParticipantsContext = useMutedParticipants();

  const [id, identity] = useMemo(() => {
    return parseParticipantIdentity(participant);
  }, [participant]);

  const videoPublication = publications.find((p) => !p.trackName.includes('screen') && p.kind === 'video');
  const isVideoEnabled = Boolean(videoPublication);
  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);

  const audioPublication = publications.find((p) => p.kind === 'audio');
  const audioTrack = useTrack(audioPublication) as RemoteAudioTrack | undefined;
  const isAudioEnabled = useIsTrackEnabled(audioTrack as RemoteAudioTrack);

  const isParticipantReconnecting = useParticipantIsReconnecting(participant);
  const nq: number = useParticipantNQ(participant);
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';

  const user: ApiUser | null = useAppSelector(selectUser);
  const waitingRoomProfile: ApiWaitingRoomLinkProfile | null = useAppSelector(selectWaitingRoomProfile);

  const { clinicUser } = user || {};
  const { preferedDisplayName: clinicUserPreferredDisplayName } = clinicUser || {};
  const { preferedDisplayName: waitingRoomProfilePreferredDisplayName, providerId } = waitingRoomProfile || {};
  const showPreferredFullName = (isHost && id === clinicUser?.id) || (!isHost && id === providerId);

  const participantMuted: boolean = useMemo(() => {
    const isMuted: boolean = mutedParticipantsId.some((mutedParticipantId: string) => mutedParticipantId === id);
    return isMuted;
  }, [id, mutedParticipantsId]);

  const showStar: boolean = useMemo(() => {
    return (isHost && id === user?.clinicUser.id) || (!isHost && id === waitingRoomProfile?.providerId);
  }, [isHost, id, waitingRoomProfile, user]);

  const preferredFullName = useMemo(() => {
    const clinicUserFullName = getPreferredFullName(clinicUserPreferredDisplayName, null);
    const waitingRoomFullName = getPreferredFullName(waitingRoomProfilePreferredDisplayName, identity);

    return showPreferredFullName ? clinicUserFullName ?? waitingRoomFullName : identity;
  }, [isHost, id, waitingRoomProfilePreferredDisplayName, clinicUserPreferredDisplayName]);

  return (
    <Box
      className={classnames(styles.container, {
        [styles.hideParticipant]: hideParticipant,
      })}
      sx={{ backgroundColor: '#D1D1D1', border: withBorder ? '1px solid #979797' : 'none' }}
    >
      <Box className={styles.infoContainer}>
        <Box className={classnames(styles.dotMenu, styles[`dotMenu-${dotMenuPosition}`])}>
          <div className={styles.nq}>
            <NQIcon nq={nq} />
          </div>

          {!isLocalParticipant && isHost && (
            <ParticipantDotMenu
              withMute
              withRemove
              participant={participant as RemoteParticipant}
              toggleClassName={styles.dotMenuToggle}
              iconClassName={styles.dotMenuIcon}
              iconColor={'#FFF'}
              menuBackgroundColor={'rgba(0, 0, 0, 0.5)'}
              listItemTextColor={'#FFF'}
              tooltipPlacement={dotMenuPosition === 'bottom' ? 'right-end' : 'right-start'}
            />
          )}
        </Box>
        <Box className={styles.infoRowBottom}>
          {withIdentity && (
            <Box component='span' className={styles.identity}>
              {selectedParticipant === participant && <PinIcon className={styles.star} />}
              {(!isAudioEnabled || participantMuted) && <MicOffIcon className={styles.micOff} />}
              {showStar && <StarIcon className={styles.star} />}
              <Typography variant='body1' component='span' sx={{ color: '#FFF' }}>
                {preferredFullName}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box className={styles.innerContainer}>
        {(!isVideoEnabled || isVideoSwitchedOff) && <UserIcon sx={{ color: '#979797', fontSize: '8.8rem' }} />}
        {(isParticipantReconnecting || isReconnecting) && <ReconnectingAnimation />}
        {children}
      </Box>
    </Box>
  );
}
