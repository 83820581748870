import { useCallback, useEffect, useMemo, useState } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useSnackbar } from 'notistack';

import { useApiRequests, useDialog, useSignalrConnection } from 'hooks';
import { ISignalrConnectionContext } from 'context/signalrConnection';
import { ApiUser, CreatePaymentRequest, PaymentRequestCreatedMessage } from 'types';
import { useAppDispatch, useAppSelector } from 'store';
import { getUser, selectUser } from 'store/user';
import {
  MakePaymentDialog,
  PaymentCompleteDialog,
  PaymentDeclinedDialog,
  WaitForClientPaymentDialog,
} from 'components';
import { noop } from 'utils';
import { RequestPaymentsContext } from './context';

type RequestPaymentsProviderProps = {
  children?: JSX.Element;
};

export function RequestPaymentsProvider({ children }: RequestPaymentsProviderProps) {
  const dispatch = useAppDispatch();
  const { showDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const { createPayment, cancellPaymentByProvider, cancellPaymentByClient } = useApiRequests();
  const user: ApiUser | null = useAppSelector(selectUser);

  const { events, signalrConnectionId, addEventListener, removeEventListener }: ISignalrConnectionContext =
    useSignalrConnection();

  const [paymentRequestCreatedMessage, setPaymentRequestCreatedMessage] = useState<PaymentRequestCreatedMessage | null>(
    null,
  );
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);

  const showWaitForAcceptByClientDialog: boolean = useMemo(() => !!user?.clinicUser.requestPayment, [user]);

  const showInputCardDataDialog: boolean = useMemo(
    () => !user && !!paymentRequestCreatedMessage?.clientSecret,
    [user, paymentRequestCreatedMessage],
  );

  const requestPaymentFromClient = useCallback((payload: CreatePaymentRequest): Promise<void> => {
    return createPayment(payload)
      .then(() => {
        dispatch(getUser()).unwrap();
      })
      .catch((e) => enqueueSnackbar(`Request payment error: ${e}`, { variant: 'error' }))
      .then(noop);
  }, []);

  const onPaymentRequestCreated = useCallback((messagse: PaymentRequestCreatedMessage) => {
    console.log(messagse);

    setPaymentRequestCreatedMessage(messagse);
  }, []);

  const onPaymentRequestCancelled = useCallback(() => {
    if (user && user.clinicUser.requestPayment) {
      dispatch(getUser()).unwrap();

      const modal = showDialog(PaymentDeclinedDialog, {
        text: 'Payment declined by client',
        onConfirm: () => {
          modal.destroy();
        },
      });
    } else if (!user && paymentRequestCreatedMessage) {
      setPaymentRequestCreatedMessage(null);

      const modal = showDialog(PaymentDeclinedDialog, {
        text: 'Payment declined by provider',
        onConfirm: () => {
          modal.destroy();
        },
      });
    }
  }, [user, paymentRequestCreatedMessage]);

  const onPaymentRequestSucceeded = useCallback(() => {
    if (user && user.clinicUser.requestPayment) {
      dispatch(getUser()).unwrap();

      const modal = showDialog(PaymentCompleteDialog, {
        onConfirm: () => {
          modal.destroy();
        },
      });
    } else if (!user && paymentRequestCreatedMessage) {
      setPaymentRequestCreatedMessage(null);

      const modal = showDialog(PaymentCompleteDialog, {
        onConfirm: () => {
          modal.destroy();
        },
      });
    }
  }, [user, paymentRequestCreatedMessage]);

  useEffect(() => {
    events.on('paymentRequestCreated', onPaymentRequestCreated);
  }, [events]);

  useEffect(() => {
    addEventListener('paymentRequestCancelled', onPaymentRequestCancelled);
    addEventListener('paymentRequestSucceeded', onPaymentRequestSucceeded);

    return () => {
      removeEventListener('paymentRequestCancelled', onPaymentRequestCancelled);
      removeEventListener('paymentRequestSucceeded', onPaymentRequestSucceeded);
    };
  }, [onPaymentRequestCancelled, onPaymentRequestSucceeded]);

  useEffect(() => {
    if (!stripePromise && paymentRequestCreatedMessage?.accountId) {
      const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? '', {
        stripeAccount: paymentRequestCreatedMessage?.accountId,
      });

      setStripePromise(stripePromise);

      return () => {
        setStripePromise(null);
      };
    }
  }, [stripePromise, paymentRequestCreatedMessage?.accountId]);

  const handleCancelRequestByProvider = (): void => {
    cancellPaymentByProvider()
      .then(() => dispatch(getUser()).unwrap())
      .catch((e) => enqueueSnackbar(`Cancel request error: ${e}`, { variant: 'error' }));
  };

  const handleCancelRequestByClient = (): void => {
    cancellPaymentByClient(signalrConnectionId)
      .then(() => setPaymentRequestCreatedMessage(null))
      .catch((e) => enqueueSnackbar(`Cancel request error: ${e}`, { variant: 'error' }));
  };

  return (
    <>
      <RequestPaymentsContext.Provider value={{ requestPaymentFromClient }}>{children}</RequestPaymentsContext.Provider>

      {showWaitForAcceptByClientDialog && (
        <WaitForClientPaymentDialog
          open={showWaitForAcceptByClientDialog}
          clientId={user!.clinicUser.requestPayment!.clientConnectionId}
          onConfirm={handleCancelRequestByProvider}
        />
      )}

      {showInputCardDataDialog && (
        <Elements options={{ clientSecret: paymentRequestCreatedMessage?.clientSecret }} stripe={stripePromise}>
          <MakePaymentDialog
            open={showInputCardDataDialog}
            clientSecret={paymentRequestCreatedMessage!.clientSecret}
            amount={paymentRequestCreatedMessage!.amount}
            description={paymentRequestCreatedMessage!.description ?? null}
            onClose={handleCancelRequestByClient}
          ></MakePaymentDialog>
        </Elements>
      )}
    </>
  );
}
