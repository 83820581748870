import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function GroupChatIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='29' height='20' viewBox='0 0 29 20' fill='none' {...props}>
      <path d='M0.75 7.41899C0.75 3.59772 3.84944 0.5 7.67277 0.5H13.0774C16.9007 0.5 20.0002 3.59772 20.0002 7.41899C20.0002 11.2403 16.9007 14.338 13.0774 14.338H9.00007V17.6782C9.00007 17.6782 0.75 16.0081 0.75 7.41899Z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.8281 15.5255C15.8234 16.4805 17.1748 17.0675 18.6634 17.0675H20.6877V19.7397C20.6877 19.7397 28.2502 18.4036 28.2502 11.5323C28.2502 8.47527 25.7707 5.99707 22.712 5.99707H21.1929C21.3116 6.44796 21.3749 6.92133 21.3749 7.40953C21.3749 11.3912 18.5705 14.7183 14.8281 15.5255Z'
      />
    </SvgIcon>
  );
}
