import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function GridIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='15' height='15' viewBox='0 0 15 15' fill='none' {...props}>
      <rect width='4.41177' height='4.41177' rx='2' />
      <rect x='5.2937' width='4.41177' height='4.41177' rx='2' />
      <rect x='10.5883' width='4.41177' height='4.41177' rx='2' />
      <rect y='5.29395' width='4.41177' height='4.41177' rx='2' />
      <rect x='5.2937' y='5.29395' width='4.41177' height='4.41177' rx='2' />
      <rect x='10.5883' y='5.29395' width='4.41177' height='4.41177' rx='2' />
      <rect y='10.5884' width='4.41177' height='4.41177' rx='2' />
      <rect x='5.2937' y='10.5884' width='4.41177' height='4.41177' rx='2' />
      <rect x='10.5883' y='10.5884' width='4.41177' height='4.41177' rx='2' />
    </SvgIcon>
  );
}
