import { memo } from 'react';
import ParticipantInfo from '../ParticipantInfo/ParticipantInfo';
import { ParticipantTracks } from '../ParticipantTracks';
import { Participant as IParticipant } from 'twilio-video';

interface ParticipantProps {
  participant: IParticipant;
  videoOnly?: boolean;
  enableScreenShare?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
  withIdentity?: boolean;
  withBorder?: boolean;
  dotMenuPosition?: 'top' | 'bottom';
}

export function Participant({
  participant,
  videoOnly,
  enableScreenShare,
  isLocalParticipant,
  hideParticipant,
  withIdentity = true,
  withBorder,
  dotMenuPosition = 'top',
}: ParticipantProps) {
  return (
    <ParticipantInfo
      participant={participant}
      hideParticipant={hideParticipant}
      withIdentity={withIdentity}
      withBorder={withBorder}
      isLocalParticipant={isLocalParticipant}
      dotMenuPosition={dotMenuPosition}
    >
      <ParticipantTracks
        participant={participant}
        videoOnly={videoOnly}
        enableScreenShare={enableScreenShare}
        isLocalParticipant={isLocalParticipant}
      />
    </ParticipantInfo>
  );
}

export default memo(Participant);
