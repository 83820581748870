import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { IDialogContext } from 'context';
import { useScreenSize, useDialog } from 'hooks';
import { ForcedRotatePhoneDialog } from 'components/Dialogs';

type DialogType = {
  destroy: () => void;
};

const isMobileDevice = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const useShowForcedRotatePhoneDialog = () => {
  const { isMobileVertical } = useScreenSize();
  const { showDialog }: IDialogContext = useDialog();

  const [shouldShowedDialog, setShouldShowedDialog] = useState<boolean>(false);
  const dialogRef: MutableRefObject<DialogType | null> = useRef(null);

  useEffect(() => {
    if (isMobileVertical && shouldShowedDialog) {
      showForcedRotatePhoneDialog();
    } else {
      hideForcedRotatePhoneDialog();
    }
  }, [isMobileVertical, shouldShowedDialog]);

  const showForcedRotatePhoneDialog = (): void => {
    dialogRef.current = showDialog(ForcedRotatePhoneDialog, { fullScreen: true });
  };

  const hideForcedRotatePhoneDialog = (): void => {
    dialogRef?.current?.destroy();
  };

  return (showDialog: boolean): void => {
    setShouldShowedDialog(showDialog);
  };
};
