/* eslint-disable prettier/prettier */
import { MutableRefObject, RefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { Typography } from '@mui/material';

import { IEMDRContext } from 'context';
import { useEMDR, useScreenSize } from 'hooks';
import { DurationTypeEnum } from 'types';
import { secondsToStringWithHours } from 'utils';
import { AudioType, CountType, MoveType, useAudio, useCount, useMove } from './hooks';

import styles from './EMDRContainer.module.scss';

type EMDRContainerProps = {
  className?: string;
  fullScreen?: boolean;
};

export function EMDRContainer({ className, fullScreen = false }: EMDRContainerProps) {
  const { height: screenHeight, width: screenWidth } = useScreenSize();
  const { settings, counterSettings, state, showDurationCountdown }: IEMDRContext = useEMDR();

  const containerRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const canvasRef: RefObject<HTMLCanvasElement> = useRef<HTMLCanvasElement>(null);
  const ctx: MutableRefObject<CanvasRenderingContext2D | null> = useRef<CanvasRenderingContext2D | null>(null);
  const direction = useRef<number>(0);

  const { moveObject, stopMoveObject }: MoveType = useMove({ ctx, canvasRef, containerRef });
  const { updatePassesCountdown }: CountType = useCount();
  const { playSound, stopSound }: AudioType = useAudio();

  const duration: number = useMemo(() => {
    return state === 'play' ? 3500 - settings.speed * 30 : 0;
  }, [settings, state]);

  useEffect(() => {
    ctx.current = canvasRef.current!.getContext('2d')!;
  }, []);

  useEffect(() => {
    canvasRef.current!.width = containerRef.current?.clientWidth ?? 0;
    canvasRef.current!.height = containerRef.current?.clientHeight ?? 0;
  }, [screenWidth, screenHeight]);

  const move = useCallback(() => {
    playSound(duration, direction.current);
    moveObject(duration, direction.current);
  }, [moveObject, playSound, duration]);

  useEffect(() => {
    if (state === 'play') {
      direction.current = 0;
      move();

      const moveObjectInterval = setInterval(() => {
        direction.current = direction.current === 0 ? 1 : 0;

        move();
        updatePassesCountdown();
      }, duration);

      return () => clearInterval(moveObjectInterval);
    } else if (state === 'pause') {
      stopMoveObject();
      stopSound();
    }
  }, [state, duration, move, stopMoveObject, stopSound, updatePassesCountdown]);

  return fullScreen ? (
    <div ref={containerRef} className={styles.container}>
      <canvas width={0} height={0} ref={canvasRef}></canvas>
    </div>
  ) : (
    <div className={className}>
      <Typography variant='body4' component='span' className={styles.whatYourClientSeesLabel}>
        WHAT YOUR CLIENT SEES
      </Typography>

      <div ref={containerRef} className={styles.container}>
        <canvas width={0} height={0} ref={canvasRef}></canvas>
      </div>

      {showDurationCountdown && (
        <div className={styles.durationCountdown}>
          {counterSettings.type === DurationTypeEnum.Time && (
            <Typography variant='body4' component='p'>
              TIME: {secondsToStringWithHours(counterSettings.time)}
            </Typography>
          )}

          {counterSettings.type === DurationTypeEnum.Passes && (
            <Typography variant='body4' component='p'>
              PASSES: {counterSettings.passes}
            </Typography>
          )}

          {counterSettings.type === DurationTypeEnum.Manual && (
            <>
              <Typography variant='body4' component='p'>
                TIME: {secondsToStringWithHours(counterSettings.time)}
              </Typography>
              <Typography variant='body4' component='p'>
                PASSES: {counterSettings.passes}
              </Typography>
            </>
          )}
        </div>
      )}
    </div>
  );
}
