import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function TimerIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 12.5C0 5.59625 5.59625 0 12.5 0C19.4037 0 25 5.59625 25 12.5C25 19.4037 19.4037 25 12.5 25C5.59625 25 0 19.4037 0 12.5ZM13.75 6.25C13.75 5.91848 13.6183 5.60054 13.3839 5.36612C13.1495 5.1317 12.8315 5 12.5 5C12.1685 5 11.8505 5.1317 11.6161 5.36612C11.3817 5.60054 11.25 5.91848 11.25 6.25V10.955C11.25 11.6513 11.4439 12.3339 11.81 12.9262C12.176 13.5185 12.6997 13.9973 13.3225 14.3087L16.9412 16.1175C17.0886 16.197 17.2504 16.246 17.4171 16.2617C17.5837 16.2775 17.7519 16.2596 17.9115 16.2091C18.0711 16.1586 18.2189 16.0766 18.3462 15.9679C18.4735 15.8592 18.5777 15.726 18.6526 15.5763C18.7274 15.4266 18.7715 15.2633 18.7821 15.0962C18.7926 14.9292 18.7695 14.7617 18.7142 14.6037C18.6588 14.4457 18.5722 14.3005 18.4596 14.1766C18.347 14.0527 18.2107 13.9527 18.0588 13.8825L14.4413 12.0725C14.2336 11.9688 14.059 11.8093 13.9369 11.6119C13.8149 11.4145 13.7501 11.1871 13.75 10.955V6.25Z'
      />
    </SvgIcon>
  );
}
