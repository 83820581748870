import { useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';

import { FormInput } from 'components';
import { ILoginContext } from 'context';
import { useLogin } from 'hooks';

import styles from './ForgotPasswordForm.module.scss';

const schema = Yup.object().shape({
  email: Yup.string().email().required(),
});

export function ForgotPasswordForm() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const { onSendResetPassword, onResetPasswordCancel }: ILoginContext = useLogin();

  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

  const form = useForm<{ email: string }>({
    resolver: yupResolver(schema),
    defaultValues: {},
    mode: 'all',
  });

  const handleResetPassword = (): void => {
    if (form.formState.isValid) {
      onSendResetPassword(form.getValues().email)
        .then(() => {
          setIsEmailSent(true);
        })
        .catch((error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        });
    }
  };

  return (
    <div className={styles.form}>
      <Typography variant={isMobile ? 'h2' : 'h1'} component={isMobile ? 'h2' : 'h1'}>
        Forgot Password?
      </Typography>

      {!isEmailSent && (
        <>
          <Typography variant='body2' component='span'>
            Don’t worry, just enter your email address and we’ll set you up with a new password.
          </Typography>

          <Stack className={styles.row}>
            <Stack direction='row' alignItems='center'>
              <Typography component='p' variant='body2'>
                Email <span className={styles.asterisk}>*</span>
              </Typography>
            </Stack>
            <FormInput name='email' control={form.control} errorHelperClassName={styles.errorMessage} />
          </Stack>

          <Stack className={styles.row}>
            <Button
              variant='contained'
              onClick={handleResetPassword}
              disabled={!form.formState.isValid}
              className={styles.formButton}
              type='submit'
            >
              Reset password
            </Button>
          </Stack>
        </>
      )}

      {isEmailSent && (
        <Typography className={styles.isEmailSentText} variant='body2' component='span'>
          If this email exists in our database, we’ve sent you instructions on how to reset your password. If you didn’t
          receive a text or email, please contact us at <b>support@hipaalink.net.</b>
        </Typography>
      )}

      <Button variant='text' onClick={onResetPasswordCancel}>
        Back to login
      </Button>
    </div>
  );
}
