import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import classnames from 'classnames';
import { Participant } from 'twilio-video';

import { useRoomHost, useRoomState, useRoomRightSidebar, useScreenShareParticipant, useScreenSize } from 'hooks';
import {
  EMDRButton,
  RecordingButton,
  SessionTimerButton,
  ToggleAudioButton,
  ToggleBackgroundEffectsButton,
  ToggleChatButton,
  ToggleNotesButton,
  ToggleParticipantsButton,
  ToggleScreenShareButton,
  ToggleSettingsButton,
  ToggleVideoButton,
  WhiteboardButton,
} from 'components';
import { DotMenu } from '../DotMenu';
import { EndCall } from '../EndCall';
import { parseParticipantIdentity } from 'utils';

import styles from './MainControls.module.scss';

export function MainControls(): JSX.Element {
  const { isMobile } = useScreenSize();
  const roomState = useRoomState();
  const { isHost, hostId } = useRoomHost();
  const { hideSidebar, toggleSettings, toggleChat, toggleParticipants, toggleNotes, toggleBackgroundEffects } =
    useRoomRightSidebar();
  const isReconnecting = roomState === 'reconnecting';
  const participantDisplayingSharedScreen: Participant | undefined = useScreenShareParticipant();
  const sharingDisabled: boolean = useMemo(() => {
    const [identityDisplayingSharedScreenId] = participantDisplayingSharedScreen
      ? parseParticipantIdentity(participantDisplayingSharedScreen)
      : [];

    return !isHost && identityDisplayingSharedScreenId === hostId;
  }, [participantDisplayingSharedScreen, isHost]);

  const isScreenShareSupported: boolean = navigator.mediaDevices && !!navigator.mediaDevices.getDisplayMedia;

  const handleEndCall = (): void => {
    hideSidebar();
  };

  return (
    <Stack className={classnames(styles.container, { [styles.containerMobile]: isMobile })} direction='row'>
      <ToggleAudioButton
        className={styles.deviceButton}
        iconClassName={styles.audioIcon}
        disabled={isReconnecting}
        withTooltip
      />
      <ToggleVideoButton
        className={styles.deviceButton}
        iconClassName={styles.videoIcon}
        disabled={isReconnecting}
        withTooltip
      />
      <ToggleChatButton onClick={toggleChat} withTooltip />

      <DotMenu
        className={styles.deviceButton}
        iconClassName={styles.moreIcon}
        contentBgColor={isMobile ? '#FFF' : undefined}
        popperClassName={isMobile ? styles.popperMobile : undefined}
        withTooltip
      >
        <Stack direction='column' spacing={isMobile ? '20px' : '10px'} alignItems='flex-start'>
          {!isMobile && (
            <Stack direction='column' spacing='10px' alignItems='center' width='100%'>
              {isScreenShareSupported && (
                <ToggleScreenShareButton
                  className={styles.dotMenuDeviceButton}
                  iconClassName={styles.dotMenuShareIcon}
                  iconColor='#81CFDD'
                  disabled={isReconnecting || sharingDisabled}
                  withLabel
                />
              )}

              {isHost && (
                <EMDRButton
                  className={styles.dotMenuDeviceButton}
                  iconClassName={styles.dotMenuShareIcon}
                  iconColor='#81CFDD'
                  disabled={isReconnecting}
                  withLabel
                />
              )}

              <ToggleBackgroundEffectsButton
                className={styles.dotMenuDeviceButton}
                iconClassName={styles.dotMenuShareIcon}
                iconColor='#81CFDD'
                disabled={isReconnecting}
                onClick={toggleBackgroundEffects}
                withLabel
              />

              {isHost && (
                <WhiteboardButton
                  className={styles.dotMenuDeviceButton}
                  iconClassName={styles.dotMenuShareIcon}
                  iconColor='#81CFDD'
                  disabled={isReconnecting}
                  withLabel
                />
              )}

              {isHost && (
                <RecordingButton
                  className={styles.dotMenuDeviceButton}
                  iconClassName={styles.dotMenuShareIcon}
                  iconColor='#81CFDD'
                  disabled={isReconnecting}
                  withLabel
                />
              )}
            </Stack>
          )}

          {isMobile && (
            <>
              {isHost && (
                <>
                  <SessionTimerButton
                    disabled={isReconnecting}
                    className={styles.dotMenuDeviceButton}
                    iconColor='#81CFDD'
                    iconClassName={styles.dotMenuShareIcon}
                    withLabel
                  />

                  <ToggleNotesButton
                    disabled={isReconnecting}
                    className={styles.dotMenuDeviceButton}
                    iconColor='#81CFDD'
                    iconClassName={styles.dotMenuShareIcon}
                    onClick={toggleNotes}
                    withLabel
                  />
                </>
              )}

              <ToggleParticipantsButton
                disabled={isReconnecting}
                className={styles.dotMenuDeviceButton}
                iconColor='#81CFDD'
                iconClassName={styles.dotMenuShareIcon}
                onClick={toggleParticipants}
                withLabel
              />

              <ToggleBackgroundEffectsButton
                className={styles.dotMenuDeviceButton}
                iconClassName={styles.dotMenuShareIcon}
                iconColor='#81CFDD'
                disabled={isReconnecting}
                onClick={toggleBackgroundEffects}
                withLabel
              />

              {isHost && (
                <WhiteboardButton
                  className={styles.dotMenuDeviceButton}
                  iconClassName={styles.dotMenuShareIcon}
                  iconColor='#81CFDD'
                  disabled={isReconnecting}
                  withLabel
                />
              )}

              {isHost && (
                <RecordingButton
                  className={styles.dotMenuDeviceButton}
                  iconClassName={styles.dotMenuShareIcon}
                  iconColor='#81CFDD'
                  disabled={isReconnecting}
                  withLabel
                />
              )}

              <ToggleSettingsButton
                disabled={isReconnecting}
                className={styles.dotMenuDeviceButton}
                iconColor='#81CFDD'
                iconClassName={styles.dotMenuShareIcon}
                onClick={toggleSettings}
                withLabel
              />
            </>
          )}
        </Stack>
      </DotMenu>

      <EndCall withTooltip disabled={isReconnecting} onEndCall={handleEndCall} />
    </Stack>
  );
}
