import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function RecordIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
      <path d='M8.00014 12.5713C10.5248 12.5713 12.5716 10.5246 12.5716 7.9999C12.5716 5.47516 10.5248 3.42847 8.00014 3.42847C5.47541 3.42847 3.42871 5.47516 3.42871 7.9999C3.42871 10.5246 5.47541 12.5713 8.00014 12.5713Z' />
      <path d='M8 0C3.58173 0 0 3.58173 0 8C0 12.4183 3.58173 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58173 12.4183 0 8 0ZM1.14286 8C1.14286 4.2129 4.2129 1.14286 8 1.14286C11.7871 1.14286 14.8571 4.2129 14.8571 8C14.8571 11.7871 11.7871 14.8571 8 14.8571C4.2129 14.8571 1.14286 11.7871 1.14286 8Z' />
    </SvgIcon>
  );
}
