import React from 'react';
import { Slider, Stack } from '@mui/material';
import { SpeakerIcon } from 'assets';
import { useAppSettings } from 'hooks';

import styles from './MasterVolume.module.scss';

export function MasterVolume() {
  const { masterVolume, setMasterVolume } = useAppSettings();
  const handleChange = (event: Event, newValue: number | number[]) => {
    setMasterVolume(newValue as number);
  };

  return (
    <>
      <Stack spacing={'12px'} direction='row' alignItems='center'>
        <SpeakerIcon color='primary' sx={{ fontSize: '1.6rem' }} />
        <Slider aria-label='Volume' className={styles.slider} value={masterVolume} onChange={handleChange} />
      </Stack>
    </>
  );
}
