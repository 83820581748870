/* eslint-disable prettier/prettier */
import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, Theme, useMediaQuery } from '@mui/material';
import classnames from 'classnames';

import { useApiRequests, useDialog, useSubscription } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store';
import { getUser, selectUser } from 'store/user';
import { getIsAdminByRoles } from 'utils';
import { ApiUser, SubscriptionStatusEnum } from 'types';
import { SubscriptionDetailsPopup } from 'pages/SettingPage/components/SubscriptionSettings/components';
import { ConfirmResultEnum } from 'context';
import { Subscriptions } from 'constants/payment';

import styles from './SubscriptionStatusBanner.module.scss';

export function SubscriptionStatusBanner() {
  const dialog = useDialog();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const apiRequests = useApiRequests();
  const dispatch = useAppDispatch();
  const subscription = useSubscription();
  const user: ApiUser | null = useAppSelector(selectUser);

  const isAdmin = useMemo<boolean>(() => {
    return getIsAdminByRoles(user?.clinicUser.roles || []);
  }, [user]);

  const isTrial = useMemo<boolean>(() => {
    return subscription?.subscriptionStatus === SubscriptionStatusEnum.Trial;
  }, [user]);

  const isExpiredOrCancelled = useMemo<boolean>(() => {
    return (
      subscription?.subscriptionStatus === SubscriptionStatusEnum.Expired ||
      subscription?.subscriptionStatus === SubscriptionStatusEnum.Cancelled
    );
  }, [user]);

  const isNonRenewing = useMemo<boolean>(() => {
    return subscription?.subscriptionStatus === SubscriptionStatusEnum.NonRenewing;
  }, [user]);

  const handleUpgradePlan = async () => {
    if (isMobile) {
      navigate('/subscriptionDetails');
      return;
    }

    const confirmationResult = await dialog.confirm({ content: SubscriptionDetailsPopup });
    if (confirmationResult === ConfirmResultEnum.Confirm) {
      dispatch(getUser()).unwrap();
    }
  };

  const handleKeepMyPlan = async () => {
    const response = await apiRequests.reactivateCurrentSubscription();
    if (response.code === Subscriptions.Responses.StatusCodes.Success) {
      dispatch(getUser()).unwrap();
    }
  };

  return (
    <>
      {isAdmin && (
        <>
          {isExpiredOrCancelled && (
            <Box component='div' className={classnames(styles.container, styles.alert)}>
              <Box component='div'>
                <Button LinkComponent={Link}>Upgrade now to keep your plan</Button>
              </Box>
            </Box>
          )}

          {isNonRenewing && (
            <Box component='div' className={classnames(styles.container, styles.alert)}>
              <Box component='div' className={styles.contentWrapper}>
                <Box component='div' className={styles.textWrapper}>
                  You have&nbsp;<span>{subscription?.daysUntilBillingPeriodEnd} days remaining.</span>&nbsp;&nbsp;
                </Box>
                <Box component='div' className={styles.buttonWrapper}>
                  <Button LinkComponent={Link} onClick={handleKeepMyPlan}>
                    Keep your plan
                  </Button>
                </Box>
              </Box>
            </Box>
          )}

          {isTrial && (
            <Box
              component='div'
              className={classnames(styles.container, { [styles.alert]: subscription?.isLessThanThreeDaysLeft })}
            >
              <Box component='div' className={styles.contentWrapper}>
                <Box component='div' className={styles.textWrapper}>
                  You have&nbsp;<span>{subscription?.trialDaysLeft} days remaining.</span>&nbsp;&nbsp;
                </Box>
                <Box component='div' className={styles.buttonWrapper}>
                  <Button LinkComponent={Link} onClick={handleUpgradePlan}>
                    Upgrade now to keep your plan
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
}
