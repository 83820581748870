import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function RenameIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='15' height='15' viewBox='0 0 15 15' fill='none' {...props}>
      <path d='M5.62451 0C5.27934 0 4.99951 0.279825 4.99951 0.625C4.99951 0.970175 5.27934 1.25 5.62451 1.25H6.87451V13.75H5.62451C5.27934 13.75 4.99951 14.0299 4.99951 14.375C4.99951 14.7201 5.27934 15 5.62451 15H9.37451C9.71969 15 9.99951 14.7201 9.99951 14.375C9.99951 14.0299 9.71969 13.75 9.37451 13.75H8.12451V1.25H9.37451C9.71969 1.25 9.99951 0.970175 9.99951 0.625C9.99951 0.279825 9.71969 0 9.37451 0H5.62451Z' />
      <path d='M2.5 2.49988H5.625V12.4999H2.5C1.11929 12.4999 0 11.3806 0 9.99988V4.99988C0 3.61917 1.11929 2.49988 2.5 2.49988Z' />
      <path d='M12.4995 12.4999H9.37451V2.49988H12.4995C13.8803 2.49988 14.9995 3.61917 14.9995 4.99988V9.99988C14.9995 11.3806 13.8803 12.4999 12.4995 12.4999Z' />
    </SvgIcon>
  );
}
