/* eslint-disable prettier/prettier */

export type ClinicSubscriptionInfo = {
    lastBillingAt: string | null;
    nextBillingAt: string | null;
    status: string;
    trialEndsAt: string | null;
    trialStartsAt: string | null;
    currentTermEndsAt: string | null;
    currentTermStartsAt: string | null;
    serverTime: string;
    planId: string | null;
    planCode: string | null;
    planName: string | null;
    price: number | null;

    cardExpirationMonth: number | null;
    cardExpirationYear: number | null;
    cardLastFourDigits: string | null;
}

export type PostCheckoutSubscriptionInfo = {
    amount: number;
    currentTermEndsAt: string | null;
    currentTermStartsAt: string | null;
    interval: number;
    intervalUnit: string | null;
    lastBillingAt: string | null;
    nextBillingAt: string | null;
    planName: string | null;
    status: SubscriptionStatusEnum
}

export enum SubscriptionStatusEnum {
    Trial = 'Trial',
    Live = 'Live',
    Dunning = 'Dunning',
    Unpaid = 'Unpaid',
    Future = 'Future',
    NonRenewing = 'NonRenewing',
    Cancelled = 'Cancelled',
    Expired = 'Expired',
    Unknown = 'Unknown',
}

export enum PaymentResponseStatusEnum {
    Success = 0
} 