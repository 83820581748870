import { useContext } from 'react';

import { RoomRightSidebarContext } from 'context';

export function useRoomRightSidebar() {
  const context = useContext(RoomRightSidebarContext);
  if (!context) {
    throw new Error('useRoomRightSidebar must be used within a RightSidebarProvider');
  }
  return context;
}
