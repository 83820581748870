import { createContext } from 'react';
import { WhiteboardItem } from 'types';

export type WhiteboardState = 'off' | 'on' | 'loading';

export interface IWhiteboardContext {
  state: WhiteboardState;
  isFullScreen: boolean;
  items: WhiteboardItem[];
  canUndo: boolean;
  canRedo: boolean;
  startWhiteboard(): void;
  stopWhiteboard(): void;
  toggleFullScreen(): void;
  addItem(item: WhiteboardItem): void;
  undo(): void;
  redo(): void;
  clearCnavas(): void;
}

export const WhiteboardContext = createContext<IWhiteboardContext | null>(null);
export const WhiteboardConsumer = WhiteboardContext.Consumer;
