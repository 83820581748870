import { ChangeEvent, MutableRefObject, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { FormatAlignLeftRounded, FormatAlignRightRounded, FormatAlignCenterRounded } from '@mui/icons-material';
import classnames from 'classnames';

import { BoldIcon, ItalicIcon } from 'assets';
import { TextProperties } from '../../hooks';
import { useScreenSize } from 'hooks';
import { WhiteboardTextItemData } from 'types';
import { SelectOption, Selector } from '../Selector';

import styles from './TextInputWithOptions.module.scss';

const LINE_HEIGHT_COEFFICIENT = 1.5;
// textarea outer container padding + border in px
const RECTANGLE_OFFSET = 3.5;
// coefficient for putting text top offset appropriate to textarea text top offset
const FONT_TOP_OFFSET_COEFFICIENT = 0.35;

const FONTS: SelectOption[] = [
  { id: 'Arial, Helvetica, sans-serif', value: 'Arial' },
  { id: '"Times New Roman", Times, serif', value: 'Times New Roman' },
  { id: 'Calibri, sans-serif', value: 'Calibri' },
  { id: 'Verdana,sans-serif', value: 'Verdana' },
  { id: 'Rockwell,Courier Bold,Courier,Georgia,Times,Times New Roman,serif', value: 'Rockwell' },
  { id: '"Franklin Gothic", sans-serif', value: 'Franklin Gothic' },
  { id: '"Univers", sans-serif', value: 'Univers' },
  { id: '"Frutiger", sans-serif', value: 'Frutiger' },
  { id: '"Avenir", sans-serif', value: 'Avenir' },
];
const FONT_SIZES: number[] = [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72];
const COLORS = ['#000000', '#FF4F4F', '#FF9D43', '#FAFF00', '#00AA25', '#42E2FF', '#1357BE', '#FF93EE', '#B882FF'];

export type TextInputWithOptionsProps = {
  properties: TextProperties;
  zoom: number;
  canvasPosition: number[];
  drawingText: WhiteboardTextItemData;
  onChange(properties: TextProperties): void;
};

export function TextInputWithOptions({
  properties,
  zoom,
  canvasPosition,
  drawingText,
  onChange,
}: TextInputWithOptionsProps) {
  const { isTouchDevice } = useScreenSize();

  const containerRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const textareaRef: RefObject<HTMLTextAreaElement> = useRef<HTMLTextAreaElement>(null);
  const resizeRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const resizePrevPositionX: MutableRefObject<number | null> = useRef<number | null>(null);
  const prevTextsRef: MutableRefObject<string[]> = useRef<string[]>([]);

  const [openedColorSelect, setOpenedColorSelect] = useState<boolean>(false);

  const onStartResize = useCallback((clientX: number): void => {
    resizePrevPositionX.current = clientX;
  }, []);

  const onStartResizeMouse = useCallback(
    (ev: MouseEvent): void => {
      onStartResize(ev.clientX);
    },
    [onStartResize],
  );

  const onStartResizeTouch = useCallback(
    (ev: TouchEvent): void => {
      ev.preventDefault();
      onStartResize(ev.targetTouches.item(0)!.clientX);
    },
    [onStartResize],
  );

  const onResize = useCallback(
    (clientX: number): void => {
      if (resizePrevPositionX.current === null) {
        return;
      }

      const resizeRect = resizeRef.current!.getBoundingClientRect();
      const xOffset: number =
        resizeRect.left > clientX && textareaRef.current!.offsetWidth < 50 ? 0 : clientX - resizePrevPositionX.current;
      resizePrevPositionX.current = clientX;
      textareaRef.current!.style.width = textareaRef.current!.offsetWidth + xOffset + 'px';
      onChange({ ...properties, width: textareaRef.current!.offsetWidth / zoom });
    },
    [properties, zoom, onChange],
  );

  const onResizeMouse = useCallback(
    (ev: MouseEvent): void => {
      ev.preventDefault();
      onResize(ev.clientX);
    },
    [onResize],
  );

  const onResizeTouch = useCallback(
    (ev: TouchEvent): void => {
      ev.preventDefault();
      onResize(ev.targetTouches.item(0)!.clientX);
    },
    [onResize],
  );

  const onStopResize = useCallback((): void => {
    resizePrevPositionX.current = null;
  }, []);

  useEffect(() => {
    textareaRef.current!.focus();
  }, []);

  useEffect(() => {
    const x: number = (drawingText.x - canvasPosition[0]) * zoom - RECTANGLE_OFFSET;
    const y: number =
      (drawingText.y - canvasPosition[1] - properties.size * FONT_TOP_OFFSET_COEFFICIENT) * zoom - RECTANGLE_OFFSET;
    const width: number = properties.width * zoom;
    const fontSize: number = properties.size * zoom;
    const lineHeight: number = fontSize * LINE_HEIGHT_COEFFICIENT;

    containerRef.current!.style.left = x + 'px';
    containerRef.current!.style.top = y + 'px';

    if (properties.texts.length) {
      textareaRef.current!.style.height = '1px';

      if (prevTextsRef.current !== properties.texts) {
        textareaRef.current!.style.height = textareaRef.current!.scrollHeight + 'px';
        prevTextsRef.current = properties.texts;
      } else {
        setTimeout(() => {
          textareaRef.current!.style.height = textareaRef.current!.scrollHeight + 'px';
        }, 0);
      }
    } else {
      textareaRef.current!.style.height = lineHeight + 'px';
    }

    textareaRef.current!.style.width = width + 'px';
    textareaRef.current!.style.font = `${properties.isBold ? 'bold ' : ''}${
      properties.isItalic ? 'italic ' : ''
    }${fontSize}px ${properties.font}`;
    textareaRef.current!.style.fontFamily = properties.font;
    textareaRef.current!.style.lineHeight = lineHeight + 'px';
    textareaRef.current!.style.color = properties.color;
    textareaRef.current!.style.textAlign = properties.aligment;
  }, [zoom, canvasPosition, drawingText, properties]);

  useEffect(() => {
    if (isTouchDevice) {
      resizeRef.current?.addEventListener('touchstart', onStartResizeTouch);
      window.addEventListener('touchmove', onResizeTouch, { passive: false });
      window.addEventListener('touchend', onStopResize);

      return () => {
        textareaRef.current?.removeEventListener('touchstart', onStartResizeTouch);
        window.removeEventListener('touchmove', onResizeTouch);
        window.removeEventListener('touchend', onStopResize);
      };
    } else {
      resizeRef.current?.addEventListener('mousedown', onStartResizeMouse);
      window.addEventListener('mousemove', onResizeMouse);
      window.addEventListener('mouseup', onStopResize);

      return () => {
        textareaRef.current?.removeEventListener('mousedown', onStartResizeMouse);
        window.removeEventListener('mousemove', onResizeMouse);
        window.removeEventListener('mouseup', onStopResize);
      };
    }
  }, [isTouchDevice, onStartResizeMouse, onStartResizeTouch, onResizeMouse, onResizeTouch, onStopResize]);

  const handleFontChange = useCallback(
    (font: string): void => {
      onChange({ ...properties, font });
    },
    [properties],
  );

  const handleFontSizeChange = useCallback(
    (size: string): void => {
      onChange({ ...properties, size: +size });
    },
    [properties],
  );

  const handleColorChange = useCallback(
    (color: string): void => {
      onChange({ ...properties, color });
    },
    [properties],
  );

  const handleBoldChange = useCallback((): void => {
    onChange({ ...properties, isBold: !properties.isBold });
  }, [properties]);

  const handleItalicChange = useCallback((): void => {
    onChange({ ...properties, isItalic: !properties.isItalic });
  }, [properties]);

  const handleTextAlignChange = useCallback(
    (prevAlign: 'left' | 'center' | 'right'): void => {
      let newAlign: 'left' | 'center' | 'right' = 'left';
      if (prevAlign === 'left') {
        newAlign = 'center';
      } else if (prevAlign === 'center') {
        newAlign = 'right';
      } else if (prevAlign === 'right') {
        newAlign = 'left';
      }
      onChange({ ...properties, aligment: newAlign });
    },
    [properties],
  );

  const handleTextChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>): void => {
      const texts: string[] = event.target.value.split('\n');
      onChange({ ...properties, texts });
    },
    [properties],
  );

  const handleOpenColorSettings = useCallback((): void => {
    setOpenedColorSelect((prevOpenedColorSelect: boolean) => !prevOpenedColorSelect);
  }, []);

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.relative}>
        <div className={styles.mainContainer}>
          <Selector
            className={styles.fontSelector}
            value={properties.font}
            options={FONTS}
            onChange={handleFontChange}
          />

          <Selector
            className={styles.fontSizeSelector}
            value={properties.size.toString()}
            options={FONT_SIZES.map((size: number) => ({ id: size.toString(), value: size.toString() }))}
            onChange={handleFontSizeChange}
          />

          <div className={styles.settingsItem}>
            <div
              className={classnames(styles.selectColorIcon, {
                [styles.active]: openedColorSelect,
              })}
              style={{ background: properties.color }}
              onClick={handleOpenColorSettings}
            ></div>
          </div>

          <div className={styles.settingsItem}>
            <BoldIcon sx={{ color: properties.isBold ? '#42E2FF' : '#ffffff' }} onClick={handleBoldChange} />
          </div>

          <div className={styles.settingsItem}>
            <ItalicIcon sx={{ color: properties.isItalic ? '#42E2FF' : '#ffffff' }} onClick={handleItalicChange} />
          </div>

          <div className={styles.settingsItem}>
            {properties.aligment === 'left' && (
              <FormatAlignLeftRounded sx={{ color: '#ffffff' }} onClick={() => handleTextAlignChange('left')} />
            )}
            {properties.aligment === 'center' && (
              <FormatAlignCenterRounded sx={{ color: '#ffffff' }} onClick={() => handleTextAlignChange('center')} />
            )}
            {properties.aligment === 'right' && (
              <FormatAlignRightRounded sx={{ color: '#ffffff' }} onClick={() => handleTextAlignChange('right')} />
            )}
          </div>
        </div>

        <div className={styles.input}>
          <textarea ref={textareaRef} defaultValue={''} onChange={handleTextChange} />
          <div ref={resizeRef} className={styles.resize}>
            <div></div>
          </div>
        </div>

        {openedColorSelect && (
          <div className={styles.colorSettings}>
            <div className={styles.arrow}></div>

            {COLORS.map((color: string, index: number) => (
              <div
                key={index}
                className={classnames(styles.colorSettingsItem, {
                  [styles.active]: color === properties.color,
                })}
                onClick={() => handleColorChange(color)}
              >
                <div style={{ background: color }}></div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
