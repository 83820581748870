import { useMemo } from 'react';
import { useVideo } from '../useVideo';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';

export function useRoomHost() {
  const { room } = useVideo();
  const user = useAppSelector(selectUser);

  const hostId: string = useMemo(() => {
    const [hostId] = (room?.name || '').split('__');
    return hostId;
  }, [room]);

  const isHost: boolean = useMemo(() => {
    return hostId === user?.clinicUser.id;
  }, [hostId, user]);

  return { isHost, hostId };
}
