import React, { forwardRef, useMemo } from 'react';
import { Box, IconButton, styled, Typography } from '@mui/material';
import classnames from 'classnames';
import { MedicalNotesIcon } from 'assets';
import { ControlTooltip } from 'components';

import styles from './ToggleNotesButton.module.scss';

type ToggleNotesButtonProps = {
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
  iconColor?: string;
  withTooltip?: boolean;
  onClick: () => void;
  withLabel?: boolean;
};

const MedicalNotesIconStyled = styled(MedicalNotesIcon)(({ theme }) => ({
  color: '#fff',
}));

export const ToggleNotesButton = forwardRef<HTMLButtonElement, ToggleNotesButtonProps>(
  ({ disabled, className, iconClassName, iconColor, withTooltip, onClick, withLabel }: ToggleNotesButtonProps, ref) => {
    const tooltipMessage = useMemo(() => {
      let tooltipMessage = '';

      if (withTooltip) {
        tooltipMessage = 'Notes';
      }

      return tooltipMessage;
    }, [withTooltip]);

    const sx = useMemo(
      () => ({
        backgroundColor: withLabel ? 'transparent !important' : 'rgb(0 0 0 / 50%) !important',
      }),
      [withLabel],
    );

    return (
      <ControlTooltip title={tooltipMessage} isDisabled={disabled}>
        <Box component='span' display='block' className={classnames(styles.deviceButton, className)}>
          <IconButton
            disabled={disabled}
            className={classnames(
              { [styles.deviceButton]: !withLabel, [styles.deviceButtonWithLabel]: withLabel },
              className,
            )}
            sx={sx}
            onClick={onClick}
            edge='end'
            aria-label='notes'
            ref={ref}
          >
            <MedicalNotesIconStyled
              className={classnames(styles.icon, iconClassName)}
              sx={{ color: iconColor || '#fff' }}
            />
            {withLabel && (
              <Typography component='p' variant='body2' sx={{ color: 'FFF' }} ml='10px'>
                Notes
              </Typography>
            )}
          </IconButton>
        </Box>
      </ControlTooltip>
    );
  },
);
