/* eslint-disable prettier/prettier */
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MediaContainer, RequireAuth, RequireNotAuth, RequireNotAuthWithLogoutOption } from 'components';
import { MainLayout, SimpleLayout } from 'layouts';
import {
  ConfirmEmailPage,
  EditRoomPage,
  LinkPage,
  LoginPage,
  ProviderRoomPage,
  RegisterPage,
  RegisterSecondAccountPage,
  ResetPasswordPage,
  SettingsPage,
  InvitationPage,
  PageNotFound,
  RegisterByInvitationPage,
  SuccessPaymentPage,
  SubscriptionDetailsPage,
  UpgradeToAnnualPlanPage,
  ManageSeatsPage,
  StripeReturnPage,
} from 'pages';

import {
  DialogProvider,
  ParticipantProvider,
  SignalrConnectionProvider,
  VideoProvider,
  ChatProvider,
  AppSettingsProvider,
  SelectedParticipantProvider,
  ParticipantDotMenuProvider,
  RegistrationProvider,
  LoginProvider,
  UserProvider,
  MutedParticipantsProvider,
  BurgerMenuProvider,
  SubscriptionProvider,
  TrackingAnalyticsProvider,
  EMDRProvider,
  WhiteboardProvider,
  AppFeatureFlagsProvider,
  RequestPaymentsProvider,
  FirstLoginTooltipsProvider,
  AITranscribingNotesProvider,
} from 'context';
import { SubscriptionCheckRoute } from 'components/SubscriptionCheckRoute';

export default function RoutesComponent() {
  return (
    <BrowserRouter>
      <AppFeatureFlagsProvider>
        <FirstLoginTooltipsProvider>
          <DialogProvider>
            <UserProvider>
              <TrackingAnalyticsProvider>
                <BurgerMenuProvider>
                  <AppSettingsProvider>
                    <SignalrConnectionProvider>
                      <Routes>
                        <Route
                          element={
                            <VideoProvider>
                              <RequestPaymentsProvider>
                                <ChatProvider>
                                  <EMDRProvider>
                                    <WhiteboardProvider>
                                      <DialogProvider>
                                        <SelectedParticipantProvider>
                                          <MutedParticipantsProvider>
                                            <ParticipantDotMenuProvider>
                                              <ParticipantProvider>
                                                <SubscriptionProvider>
                                                  <AITranscribingNotesProvider>
                                                    <MainLayout />
                                                  </AITranscribingNotesProvider>
                                                </SubscriptionProvider>
                                              </ParticipantProvider>
                                            </ParticipantDotMenuProvider>
                                          </MutedParticipantsProvider>
                                        </SelectedParticipantProvider>
                                      </DialogProvider>
                                    </WhiteboardProvider>
                                  </EMDRProvider>
                                </ChatProvider>
                              </RequestPaymentsProvider>
                            </VideoProvider>
                          }
                        >
                          <Route
                            element={
                              <RequireAuth>
                                <SubscriptionCheckRoute />
                              </RequireAuth>
                            }
                          >
                            <Route
                              index
                              element={
                                <MediaContainer>
                                  <ProviderRoomPage />
                                </MediaContainer>
                              }
                            />
                            <Route path='/edit' element={<EditRoomPage />} />
                            <Route path='/settings' element={<SettingsPage />} />
                            <Route path='/subscriptionDetails' element={<SubscriptionDetailsPage />} />
                            <Route path='/manageSeats' element={<ManageSeatsPage />} />
                            <Route path='/upgradeToAnnualPlan' element={<UpgradeToAnnualPlanPage />} />
                          </Route>
                          <Route element={<RequireNotAuthWithLogoutOption />}>
                            <Route path='/:link' element={<LinkPage />} />
                          </Route>
                        </Route>

                        <Route
                          element={
                            <LoginProvider>
                              <SimpleLayout />
                            </LoginProvider>
                          }
                        >
                          <Route element={<RequireNotAuth />}>
                            <Route path='/login' element={<LoginPage />} />
                          </Route>
                          <Route path='/registerByInvitation' element={<RegisterByInvitationPage />} />
                          <Route path='/invite' element={<InvitationPage />} />
                          <Route path='/resetPassword' element={<ResetPasswordPage />} />
                          <Route
                            path='/register'
                            element={
                              <RegistrationProvider>
                                <RegisterPage />
                              </RegistrationProvider>
                            }
                          />
                          <Route path='/confirmEmail' element={<ConfirmEmailPage />} />
                          <Route path='/successPayment' element={<SuccessPaymentPage />} />
                          <Route path='/stripeReturn' element={<StripeReturnPage />} />
                          <Route path='/registerSecondAccount' element={<RegisterSecondAccountPage />} />
                          <Route path='notFound' element={<PageNotFound />} />
                          <Route path='*' element={<PageNotFound />} />
                        </Route>
                      </Routes>
                    </SignalrConnectionProvider>
                  </AppSettingsProvider>
                </BurgerMenuProvider>
              </TrackingAnalyticsProvider>
            </UserProvider>
          </DialogProvider>
        </FirstLoginTooltipsProvider>
      </AppFeatureFlagsProvider>
    </BrowserRouter>
  );
}
