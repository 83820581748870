import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Switch, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { FormErrorHelperText, Info, MyRoomLink, Spinner } from 'components';
import { useAppDispatch, useAppSelector } from 'store';
import { ApiClinicUser, ApiUser } from 'types';
import { IApiRequests, useApiRequests } from 'hooks';
import { getUser, selectUser } from 'store/user';

import styles from './RoomSettingsForm.module.scss';

const schema = Yup.string().max(80, 'Max length is 80 character').nullable();

export function RoomSettingsForm() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const user: ApiUser = useAppSelector(selectUser)!;
  const dispatch = useAppDispatch();
  const { updateClinicUser, ensureRoomNameIsUnique }: IApiRequests = useApiRequests();

  const [loading, setLoading] = useState<boolean>(false);
  const [displayName, setDisplayName] = useState<string>(user.clinicUser.preferedDisplayName ?? '');
  const [displayNameError, setDisplayNameError] = useState('');
  const inputRef: React.RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    setDisplayName(user.clinicUser.preferedDisplayName ?? '');
  }, [user]);

  const displayNameBtnText: string = useMemo(() => {
    return user.clinicUser.preferedDisplayName === displayName ? 'Edit' : 'Save';
  }, [user, displayName]);

  const handleChangeDisplayName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    validate(value);
    setDisplayName(value);
  };

  const handleClickDisplayNameButton = useCallback(() => {
    if (user.clinicUser.preferedDisplayName !== displayName && validate(displayName)) {
      onChange({ preferedDisplayName: displayName });
      return;
    }

    if (user.clinicUser.preferedDisplayName === displayName) {
      inputRef.current!.focus();
    }
  }, [user, displayName]);

  const validate = (value?: string): boolean => {
    let isValid = false;
    try {
      schema.validateSync(value);
      setDisplayNameError('');
      isValid = true;
    } catch (e: any) {
      setDisplayNameError(e.message);
    }
    return isValid;
  };

  const onChange = useCallback(
    (data: Partial<ApiClinicUser>) => {
      updateClinicUser({ ...data })
        .then(() => dispatch(getUser()).unwrap())
        .then(() => enqueueSnackbar('Clinic user updated', { variant: 'success' }))
        .catch((e) => enqueueSnackbar(`Update clinic user error: ${e}`, { variant: 'error' }));
    },
    [user],
  );

  const hadleEditRoom = (value?: string): void => {
    if (!value) {
      return;
    }

    const ensureRoomNameIsUniquePromise = (): Promise<void> => {
      return ensureRoomNameIsUnique(value ?? '').catch((error) => {
        return Promise.reject(error.message);
      });
    };

    const updateClinicUserPromise = (): Promise<void> => {
      return updateClinicUser({ roomName: value }).catch((error) => {
        return Promise.reject(error.message);
      });
    };

    ensureRoomNameIsUniquePromise()
      .then(() => updateClinicUserPromise())
      .then(() => dispatch(getUser()).unwrap())
      .then(() => enqueueSnackbar('Link updated', { variant: 'success' }))
      .catch((e) => enqueueSnackbar(`Update clinic user link: ${e}`, { variant: 'error' }));
  };

  const handleWaitingRoomClick = (): void => {
    navigate('../edit');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      [event.target.name]: event.target.checked,
    });
  };

  return loading ? (
    <Spinner small />
  ) : (
    <div className={styles.form}>
      <Stack className={styles.row}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography component='p' variant='body2'>
            Room Name URL
          </Typography>
          <Info text='Create name for your personalized room link. The link must not contain .~!@#$%^&*()/' />
        </Stack>
        <MyRoomLink type='edit' value={user.clinicUser.roomName} onClick={hadleEditRoom} className={styles.link} />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography component='p' variant='body2'>
            Preferred Display Name
          </Typography>
          <Info text='Enter how your name should appear at check-in and in your waiting room. If you leave this field empty, we will use your title, last name and suffix.' />
        </Stack>
        <Stack className={styles.displayName}>
          <TextField inputRef={inputRef} value={displayName} onChange={handleChangeDisplayName} />
          <Button
            variant='contained'
            onClick={handleClickDisplayNameButton}
            disabled={displayNameError !== ''}
            className={styles.control}
          >
            {displayNameBtnText}
          </Button>
        </Stack>
        <FormErrorHelperText className={styles.errorHelper}>{displayNameError}</FormErrorHelperText>
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography component='p' variant='body2'>
            Waiting Room
          </Typography>
        </Stack>
        <Button variant='contained' onClick={handleWaitingRoomClick}>
          Edit waiting room
        </Button>
      </Stack>

      <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={0} alignItems='center'>
          <Typography component='p' variant='body5'>
            Receive waiting room messages
          </Typography>
        </Stack>
        <Switch
          checked={user.clinicUser.receiveMessages}
          name='receiveMessages'
          inputProps={{ 'aria-label': 'ReceiveMessages' }}
          onChange={handleChange}
        />
      </Stack>

      <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={0} alignItems='center'>
          <Typography component='p' variant='body5'>
            Notification banners
          </Typography>
        </Stack>
        <Switch
          checked={user.clinicUser.notifications}
          name='notifications'
          inputProps={{ 'aria-label': 'Notifications' }}
          onChange={handleChange}
        />
      </Stack>

      <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={0} alignItems='center'>
          <Typography component='p' variant='body5'>
            Notification sound
          </Typography>
        </Stack>
        <Switch
          checked={user.clinicUser.notificationsSound}
          name='notificationsSound'
          inputProps={{ 'aria-label': 'NotificationsSound' }}
          onChange={handleChange}
        />
      </Stack>

      <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography component='p' variant='body5'>
            Unavailable
          </Typography>
          <Info text='Unavailable' />
        </Stack>
        <Switch
          checked={user.clinicUser.unavailable}
          name='unavailable'
          inputProps={{ 'aria-label': 'Unavailable' }}
          onChange={handleChange}
        />
      </Stack>
    </div>
  );
}
