import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function ArrowUpIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M6.37029 9.81299H3.82703L9.97861 1.23868L16.1703 9.81325H13.6292H12.6292V10.8132V19.0007L7.37004 19.001L7.37029 10.813L7.37032 9.81299H6.37029Z'
        strokeWidth='2'
        fill='none'
      />
    </SvgIcon>
  );
}
