import { Button, DialogContent, Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { ErrorOutlineIcon } from 'assets';

import styles from './PaymentDeclinedDialog.module.scss';

export interface PaymentDeclinedDialogProps extends DialogProps {
  text: string;
  onConfirm: () => void;
}

export function PaymentDeclinedDialog({ text, onConfirm, ...rest }: PaymentDeclinedDialogProps) {
  return (
    <Dialog {...rest} className={styles.dialog}>
      <DialogContent>
        <ErrorOutlineIcon className={styles.icon} />

        <Typography component='p' variant='body4' color='#FF3D3D'>
          {text}
        </Typography>

        <Button variant='contained' onClick={onConfirm} className={styles.formButton}>
          Done
        </Button>
      </DialogContent>
    </Dialog>
  );
}
