import React, { useMemo } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import classnames from 'classnames';

import { RecordIcon } from 'assets';
import { ControlTooltip } from 'components';
import { IAITranscribingNotes } from 'context';
import { useAITranscribingNotes, useIsRecording } from 'hooks';

import styles from './RecordingButton.module.scss';

type RecordingButtonProps = {
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
  iconColor?: string;
  withTooltip?: boolean;
  withLabel?: boolean;
};

export function RecordingButton({
  disabled,
  className,
  iconClassName,
  iconColor,
  withTooltip,
  withLabel,
}: RecordingButtonProps) {
  const { isItPossibleToStartRecording, startTranscribing, stopTranscribing }: IAITranscribingNotes =
    useAITranscribingNotes();
  const isRecording = useIsRecording();

  const isDisabled: boolean = useMemo(() => {
    return !!disabled || (!isItPossibleToStartRecording && !isRecording);
  }, [disabled, isItPossibleToStartRecording, isRecording]);

  const handleClick = (): void => {
    if (isRecording) {
      stopTranscribing();
    } else {
      startTranscribing();
    }
  };

  return (
    <ControlTooltip title={withTooltip ? 'Start Recording' : ''} isDisabled={isDisabled}>
      <Box component='span' display='block' className={classnames(styles.deviceButton, className)}>
        <IconButton
          disabled={isDisabled}
          className={classnames(
            { [styles.deviceButton]: !withLabel, [styles.deviceButtonWithLabel]: withLabel },
            className,
          )}
          onClick={handleClick}
        >
          <RecordIcon
            className={classnames(styles.icon, iconClassName)}
            sx={{ color: isDisabled ? '#b7b7b7' : iconColor || '#fff' }}
          />

          {withLabel && (
            <Typography component='p' variant='body2' sx={{ color: '#FFF' }} ml='10px'>
              {isRecording ? 'Stop AI Notes' : 'Start AI Notes'}
            </Typography>
          )}
        </IconButton>
      </Box>
    </ControlTooltip>
  );
}
