import { useGalleryViewParticipants, useGridViewParticipants, useSpeakerViewParticipants } from 'hooks';

import { ParticipantContext } from './context';

type ParticipantProviderProps = {
  children?: JSX.Element;
};

export function ParticipantProvider({ children }: ParticipantProviderProps) {
  const galleryViewParticipants = useGalleryViewParticipants();
  const speakerViewParticipants = useSpeakerViewParticipants();
  const gridViewParticipants = useGridViewParticipants();

  return (
    <ParticipantContext.Provider
      value={{
        galleryViewParticipants,
        speakerViewParticipants,
        gridViewParticipants,
      }}
    >
      {children}
    </ParticipantContext.Provider>
  );
}
