import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function ImageFileIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='64' height='73' viewBox='0 0 64 73' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.7748 0.047821C2.58568 0.047821 0 2.63578 0 5.8249V67.1263C0 70.3177 2.58568 72.9023 5.7748 72.9023H57.8926C61.0817 72.9023 63.6697 70.3166 63.6697 67.1263V23.1732L42.1462 0.047821H5.7748V0.047821Z'
        fill='#81CFDD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M63.6734 23.1721V24.3106H49.1009C49.1009 24.3106 41.9143 22.876 42.1226 16.6743C42.1226 16.6743 42.3594 23.1721 48.9586 23.1721H63.6734Z'
        fill='#5C96A0'
      />
      <path
        opacity='0.5'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M42.1514 0V16.5786C42.1514 18.4641 43.4083 23.1721 49.1012 23.1721H63.6737L42.1514 0Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.4614 61.1853V37.3848H35.2643V61.1853H11.4614ZM32.8653 39.7313H13.8615V54.0773H32.8653V39.7313ZM21.9544 49.2771L26.4051 43.2928L27.7793 45.7099L29.3676 45.2158L30.488 51.6271H15.5933L19.5737 47.847L21.9544 49.2771ZM17.5641 45.1327C16.528 45.1327 15.6866 44.3494 15.6866 43.3816C15.6866 42.4149 16.528 41.6305 17.5641 41.6305C18.6025 41.6305 19.4428 42.4149 19.4428 43.3816C19.4428 44.3494 18.6025 45.1327 17.5641 45.1327Z'
        fill='white'
      />
    </SvgIcon>
  );
}
