import { CheckYourDeviceDialog } from 'components';
import { IDialogContext, IRoomRightSidebarContext } from 'context';
import { useDevices, useDialog, useRoomRightSidebar } from 'hooks';
import { MutableRefObject, useEffect, useRef } from 'react';

export function useAudioDeviceDisconectedHandler() {
  const { showDialog }: IDialogContext = useDialog();
  const { openedTab, toggleSettings }: IRoomRightSidebarContext = useRoomRightSidebar();

  const { audioInputDevices, audioOutputDevices } = useDevices();

  const prevAudioInputDevices: MutableRefObject<MediaDeviceInfo[]> = useRef<MediaDeviceInfo[]>(audioInputDevices);
  const prevAudioOutputDevices: MutableRefObject<MediaDeviceInfo[]> = useRef<MediaDeviceInfo[]>(audioOutputDevices);

  useEffect(() => {
    let isAudioInputDeviceRemoved = false;
    prevAudioInputDevices.current.forEach((device: MediaDeviceInfo) => {
      if (!audioInputDevices.map((newDevice: MediaDeviceInfo) => newDevice.deviceId).includes(device.deviceId)) {
        isAudioInputDeviceRemoved = true;
      }
    });

    let isAudioOutputDeviceRemoved = false;
    prevAudioOutputDevices.current.forEach((device: MediaDeviceInfo) => {
      if (!audioOutputDevices.map((newDevice: MediaDeviceInfo) => newDevice.deviceId).includes(device.deviceId)) {
        isAudioOutputDeviceRemoved = true;
      }
    });

    prevAudioInputDevices.current = audioInputDevices;
    prevAudioOutputDevices.current = audioOutputDevices;

    if (isAudioInputDeviceRemoved || isAudioOutputDeviceRemoved) {
      const modal = showDialog(CheckYourDeviceDialog, {
        onClose: () => {
          modal.destroy();
        },
        onConfirm: () => {
          if (openedTab !== 'settings') {
            toggleSettings();
          }
          modal.destroy();
        },
      });
    }
  }, [audioInputDevices, audioOutputDevices]);

  return {};
}
