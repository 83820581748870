import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useLocalStorageState, useUser } from 'hooks';
import { Spinner } from '../Spinner';
import { IUserContext } from 'context';
import { ApiUser, Coupon } from 'types';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { COUPON } from 'constants/index';

export function RequireAuth({ children }: { children: JSX.Element }) {
  const navigate = useNavigate();
  const user: ApiUser | null = useAppSelector(selectUser);
  const { isLoading }: IUserContext = useUser();
  const [coupon] = useLocalStorageState<Coupon | null>(COUPON, null);

  const [allowChildren, setAllowChildren] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoading) {
      if (!user) {
        window.open(process.env.REACT_APP_HOME_PAGE_FOR_NON_AUTH_URL, '_self');
        return;
      }

      if (!user!.clinicUser.createRoomSkipped || coupon || !user.clinicUser.subscriptionInfo) {
        navigate('register');
        return;
      }

      setAllowChildren(true);
    }
  }, [user, isLoading, coupon]);

  if (!allowChildren) return <Spinner />;
  return children ?? <Outlet />;
}
