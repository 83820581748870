import { Typography, Avatar, Grid } from '@mui/material';

import { UserIcon } from 'assets';
import classnames from 'classnames';

import styles from './OrganizationInfo.module.scss';

type OrganizationInfoProps = {
  avatar?: string;
  organization: string;
  isAdmin: boolean;
  membersCount: number;
  className?: string;
};

export function OrganizationInfo({ avatar, organization, isAdmin, membersCount, className }: OrganizationInfoProps) {
  return (
    <>
      <div
        className={classnames(styles.organizationInfo, className, {
          [styles.organizationInfoWithAvatar]: avatar,
        })}
      >
        {!!avatar && <Avatar className={styles.organizationPicture} src={avatar} />}

        <Grid className={styles.texts}>
          <Typography component='p' variant='body2' className={styles.name}>
            {organization ?? 'NO NEAME'}
          </Typography>

          <Typography component='p' variant='body1' color='#979797'>
            {isAdmin ? 'Admin' : 'Provider'}
          </Typography>

          <div className={styles.membersCount}>
            <UserIcon color='secondary' className={styles.userIcon} />
            <Typography component='p' variant='body1' color='#979797'>
              {membersCount}
            </Typography>
          </div>
        </Grid>
      </div>
    </>
  );
}
