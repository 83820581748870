import { Box, BoxProps } from '@mui/material';
import classnames from 'classnames';

import styles from './FormErrorHelperText.module.scss';

type FormErrorHelperTextProps = BoxProps;

export function FormErrorHelperText({ children, className, ...props }: FormErrorHelperTextProps) {
  return children ? (
    <Box className={classnames(styles.container, className)} {...props}>
      {children}
    </Box>
  ) : (
    <></>
  );
}
