import { useMemo } from 'react';
import { Typography } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';
import { RemoteParticipant } from 'twilio-video';

import { SimpleInfoDialog, SmallLoader } from 'components';
import { HubPatient } from 'types';
import { useAppSelector } from 'store';
import { selectPatients } from 'store/patientQueue';
import { useParticipants } from 'hooks';
import { parseIdentity } from 'utils';

export interface WaitForClientPaymentDialogProps extends DialogProps {
  clientId: string;
  onConfirm(): void;
}

export function WaitForClientPaymentDialog({ onConfirm, clientId, ...rest }: WaitForClientPaymentDialogProps) {
  const patients = useAppSelector(selectPatients);
  const participants = useParticipants();

  const patientName: string = useMemo(() => {
    const patientName: string =
      patients.find((patient: HubPatient) => patient.connectionId === clientId)?.userName ?? '';

    let participantName = '';
    participants.forEach((participant: RemoteParticipant) => {
      const [id, clientName] = parseIdentity(participant.identity);
      if (id === clientId) {
        participantName = clientName;
        return;
      }
    });

    return patientName ? patientName : participantName;
  }, [patients]);

  return (
    <SimpleInfoDialog {...rest} onConfirm={onConfirm} buttonText='Cancel'>
      <>
        <SmallLoader />

        <Typography variant='body4'>Request sent!</Typography>

        <Typography variant='body2'>Waiting for {patientName} to complete the payment.</Typography>

        <Typography variant='body2'>Do not close this window.</Typography>
      </>
    </SimpleInfoDialog>
  );
}
