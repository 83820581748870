import React from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import { StackProps } from '@mui/material/Stack/Stack';
import { CrossIcon } from 'assets';

import styles from './ModalTitle.module.scss';

export interface ModalTitleProps extends StackProps {
  title: string;
  notTransformToUpper?: boolean;
  onClose?: () => void;
}

export function ModalTitle({ title, onClose, notTransformToUpper, ...rest }: ModalTitleProps) {
  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between' {...rest}>
      <Typography
        component='p'
        variant='body2'
        align='center'
        textTransform={notTransformToUpper ? 'none' : 'uppercase'}
        fontWeight={700}
      >
        {title}
      </Typography>

      {onClose && (
        <IconButton onClick={onClose} sx={{ paddingTop: 0, paddingBottom: 0 }} edge='end'>
          <CrossIcon color='primary' className={styles.cross} sx={{ fontSize: '1.3rem' }} />
        </IconButton>
      )}
    </Stack>
  );
}
