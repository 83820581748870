import axios from 'axios';
import fileDownload from 'js-file-download';
import { Typography } from '@mui/material';
import { Message, Media } from '@twilio/conversations';
import classnames from 'classnames';
import { getFileIconByType, replaceURLsInString } from 'utils';

import styles from './ChatMessage.module.scss';

type ChatMessageProps = {
  message: Message;
  isMy: boolean;
};

export function ChatMessage({ message, isMy }: ChatMessageProps): JSX.Element {
  const getKBString = (bytes: number): string => {
    const kbs: number = Math.round(bytes / 1024);
    return kbs + ' KB';
  };

  const handleFileClick = (media: Media) => {
    media.getContentTemporaryUrl().then((url: string | null) => {
      axios
        .get(url!, {
          responseType: 'blob',
        })
        .then((res) => {
          fileDownload(res.data, media.filename!);
        });
    });
  };

  return message.type === 'text' ? (
    <div className={classnames(styles.message, isMy ? styles.messageMy : '')}>
      <Typography component='p' variant='body5'>
        {replaceURLsInString(message.body ?? '')}
      </Typography>
    </div>
  ) : (
    <div
      className={classnames(styles.message, styles.fileMessage, isMy ? styles.messageMy : '')}
      onClick={() => handleFileClick(message.attachedMedia![0])}
    >
      <div className={styles.fileIcon}>{getFileIconByType(message.attachedMedia![0].contentType)}</div>
      <div>
        <div>
          <Typography component='p' variant='body1'>
            {message.attachedMedia![0].filename}
          </Typography>
        </div>
        <div>
          <Typography component='p' variant='body6' sx={{ color: '#979797' }}>
            {getKBString(message.attachedMedia![0].size)}
          </Typography>
        </div>
      </div>
    </div>
  );
}
