import { createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { breakpoints } from './breakpoints';
import { typography } from './typography';
import { palette } from './palette';
import { overrides } from './overrides';
import { Theme } from '@mui/material';

export const getTheme = (themeName: string) => {
  const theme: Theme = createTheme({
    status: {
      danger: orange[500],
    },
    spacing: [0, 4, 15, 30, 30, 30],
    breakpoints: breakpoints,
    typography: typography,
  });

  return createTheme({ ...theme, palette: palette(theme), components: overrides(theme) });
};
