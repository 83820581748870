import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Dialog, IconButton, Theme, Typography, useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';
import classnames from 'classnames';
import moment from 'moment';

import { CrossIcon } from 'assets';
import { PLAN_CODE_YEARLY, SUCCESS_OPERATION_ACTION_TYPE_SUBSCRIPTION_CHANGE } from 'constants/payment';
import { DiscountInput, Info, SeatsCountSelector, Spinner } from 'components';
import { IApiRequests, useApiRequests } from 'hooks';
import {
  ApiPlan,
  ApiUser,
  ComputeSubscriptionProrationMode,
  ComputeSubscriptionProrationResponse,
  Coupon,
  GetPlanResponse,
  SubscriptionProrationApi,
  UpdateSubscriptionWithIncreasingAmountOfLicensesResponse,
} from 'types';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';

import styles from './UpgradeToAnnualPlanPopup.module.scss';

type UpgradeToAnnualPlanPopupProps = {
  open: boolean;
  totalSeats: number;
  availableSeats: number;
  onClose(): void;
};

export function UpgradeToAnnualPlanPopup({ open, totalSeats, availableSeats, onClose }: UpgradeToAnnualPlanPopupProps) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { enqueueSnackbar } = useSnackbar();
  const { getPlan, updateSubscriptionWithIncreasingAmountOfLicenses, computeSubscriptionProration }: IApiRequests =
    useApiRequests();

  const user: ApiUser = useAppSelector(selectUser)!;

  const [loading, setLoading] = useState<boolean>(true);
  const [seatsCount, setSeatsCount] = useState<number>(totalSeats);
  const [annualyPlan, setAnnualyPlan] = useState<ApiPlan | null>(null);
  const [subscriptionProration, setSubscriptionProration] = useState<SubscriptionProrationApi | null>(null);
  const [coupone, setCoupone] = useState<Coupon | null>(null);

  useEffect(() => {
    getPlan(PLAN_CODE_YEARLY)
      .then((response: GetPlanResponse) => {
        setAnnualyPlan(response.plan);
      })
      .catch(() => {
        enqueueSnackbar('Plans fetching error', { variant: 'error' });
        onClose();
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (annualyPlan) {
      setLoading(true);

      computeSubscriptionProration({
        plan: { code: PLAN_CODE_YEARLY, quantity: seatsCount },
        mode: ComputeSubscriptionProrationMode.ProrateExistingSubscription,
        couponCode: coupone?.code,
      })
        .then((response: ComputeSubscriptionProrationResponse) => {
          setSubscriptionProration(response.subscriptionProration);
        })
        .catch((error) => {
          enqueueSnackbar('Calculation error: ' + error.message, { variant: 'error' });
          setSubscriptionProration(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [annualyPlan, seatsCount, coupone]);

  const cardExparationString: string = useMemo(() => {
    return moment()
      .year(user.clinicUser.subscriptionInfo?.cardExpirationYear ?? 2022)
      .month((user.clinicUser.subscriptionInfo?.cardExpirationMonth ?? 1) - 1)
      .format('MMM YYYY');
  }, [user]);

  const pricePerOneSeatPerMonth: string = useMemo(() => {
    return ((annualyPlan?.recurringPrice ?? 0) / 12).toFixed(2);
  }, [annualyPlan]);

  const subtotalString: string = useMemo(() => {
    return '$' + ((annualyPlan?.recurringPrice ?? 0) * seatsCount).toFixed(2);
  }, [annualyPlan, seatsCount]);

  const renewsDate: string = useMemo(() => {
    return 'Renews ' + moment(user.clinicUser.subscriptionInfo?.serverTime).add(1, 'year').format('MMMM D, YYYY');
  }, [user]);

  const handleDecreaseSeat = (): void => {
    if (seatsCount > 1) {
      setSeatsCount(seatsCount - 1);
    }
  };

  const handleIncreaseSeat = (): void => {
    setSeatsCount(seatsCount + 1);
  };

  const handleApplyCoupon = (coupon: Coupon): void => {
    setCoupone(coupon);
  };

  const handleRemoveCoupon = (): void => {
    setCoupone(null);
  };

  const handleUpgrade = (): void => {
    setLoading(true);

    updateSubscriptionWithIncreasingAmountOfLicenses({
      planCode: PLAN_CODE_YEARLY,
      quantity: seatsCount,
      couponCode: coupone?.code,
      redirectUrl: `${process.env.REACT_APP_PUBLIC_URI}/successPayment?type=${SUCCESS_OPERATION_ACTION_TYPE_SUBSCRIPTION_CHANGE}&redirectTo=settings&redirectToHash=subscriptions`,
    })
      .then((response: UpdateSubscriptionWithIncreasingAmountOfLicensesResponse) => {
        window.location.href = response.page.url;
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box>
      <Dialog className={styles.dialog} open={open} onClose={onClose}>
        {loading && <Spinner small />}

        <Box className={styles.leftSide}>
          <Typography variant='body4' component='span'>
            Upgrade to Annual Plan
          </Typography>

          <Typography variant='body2' component='span'>
            You will receive a credit for the unused period of your existing monthly plan.
          </Typography>

          <Typography variant='body2' component='span'>
            The credit will be applied to your annual plan which you will be charged for and starts today,&nbsp;
            {moment(user.clinicUser.subscriptionInfo?.serverTime).format('MM/DD/YY')}.
          </Typography>

          <div className={styles.devider}></div>

          <div className={styles.row}>
            <Typography variant='body2' component='span'>
              Payment Method
            </Typography>

            <div className={styles.card}>
              <Typography variant={isDesktop ? 'body' : 'body3'} component='span' color='#393534'>
                {`Card ending in ${user.clinicUser.subscriptionInfo?.cardLastFourDigits ?? '0000'}`}
              </Typography>

              <Typography variant={isDesktop ? 'body2' : 'body5'} component='span' color='#393534'>
                {`Expires ${cardExparationString}`}
              </Typography>
            </div>
          </div>
        </Box>

        <Box className={styles.rightSide}>
          <div className={classnames(styles.row, styles.pricePerMonth)}>
            <Typography variant='h4' component='h4'>
              ${pricePerOneSeatPerMonth}/Month/Seat
            </Typography>

            <Info
              text='If your practice includes multiple providers, you can purchase and assign a seat to each practice provider. Use the staff page to assign seats and manage your staff.'
              tooltipClassName={styles.tooltip}
            />

            <IconButton onClick={onClose} className={styles.cross}>
              <CrossIcon color='primary' />
            </IconButton>

            <Typography
              variant={isDesktop ? 'body1' : 'body5'}
              className={styles.billed}
              component='span'
              color='#626363'
            >
              Billed ${(annualyPlan?.recurringPrice ?? 0).toFixed(2)}/seat annually
            </Typography>
          </div>

          <div className={styles.row}>
            <Typography variant='body2' component='span'>
              Total Seats
            </Typography>

            <SeatsCountSelector
              count={seatsCount}
              decreaseDisabled={seatsCount <= totalSeats - availableSeats}
              onDecrease={handleDecreaseSeat}
              onIncrease={handleIncreaseSeat}
            />
          </div>

          <div className={styles.row}>
            <Typography variant='body2' component='span'>
              Discount Code
            </Typography>

            <DiscountInput onApply={handleApplyCoupon} onRemove={handleRemoveCoupon} plan={PLAN_CODE_YEARLY} />
          </div>

          <div className={styles.devider}></div>

          <div className={styles.row}>
            <div className={styles.calculationsRow}>
              <Typography variant={isDesktop ? 'body1' : 'body5'} component='span' color='#393534'>
                Subtotal
              </Typography>

              <Typography variant={isDesktop ? 'body1' : 'body5'} component='span' color='#393534'>
                {subtotalString}
              </Typography>
            </div>

            <div className={styles.calculationsRow}>
              <Typography variant={isDesktop ? 'body1' : 'body5'} component='span' color='#393534'>
                Discount
              </Typography>

              <Typography variant={isDesktop ? 'body1' : 'body5'} component='span' color='#393534'>
                -${subscriptionProration?.initialDiscount.toFixed(2)}
              </Typography>
            </div>

            <div className={styles.calculationsRow}>
              <Typography variant={isDesktop ? 'body1' : 'body5'} component='span' color='#393534'>
                Total
              </Typography>

              <Typography variant={isDesktop ? 'body1' : 'body5'} component='span' color='#393534'>
                ${subscriptionProration?.initialChargeTotal.toFixed(2)}
              </Typography>
            </div>

            <div className={styles.calculationsRow}>
              <Typography variant='body4' component='span'>
                Prorated Amount Due
              </Typography>

              <Typography variant='body4' component='span'>
                ${subscriptionProration?.initialChargeAmount.toFixed(2)}
              </Typography>
            </div>
          </div>

          <div className={styles.devider}></div>

          <div>
            <Typography variant={isDesktop ? 'body1' : 'body5'} component='span' color='#626363'>
              {renewsDate}
            </Typography>
          </div>

          <div className={styles.buttons}>
            <Button variant='contained' onClick={handleUpgrade}>
              Upgrade now
            </Button>
          </div>
        </Box>
      </Dialog>
    </Box>
  );
}
