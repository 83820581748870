import { useEffect, useRef } from 'react';
import { AudioTrack as IAudioTrack } from 'twilio-video';
import { useAppSettings } from 'hooks';

interface AudioTrackProps {
  track: IAudioTrack;
}

export function AudioTrack({ track }: AudioTrackProps) {
  const { activeSinkId, masterVolume } = useAppSettings();
  const audioEl = useRef<HTMLMediaElement>();

  useEffect(() => {
    audioEl.current = track.attach();
    document.body.appendChild(audioEl.current);
    return () =>
      track.detach().forEach((el) => {
        el.remove();

        // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
        // See: https://github.com/twilio/twilio-video.js/issues/1528
        el.srcObject = null;
      });
  }, [track]);

  useEffect(() => {
    if (audioEl.current) {
      audioEl.current.volume = masterVolume / 100;
    }
  }, [masterVolume]);

  useEffect(() => {
    audioEl.current?.setSinkId?.(activeSinkId);
  }, [activeSinkId]);

  return null;
}
