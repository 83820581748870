import React, { useCallback } from 'react';
import { Button, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { ModalTitle } from 'components/index';

import styles from './RemoveDialog.module.scss';

export interface RemoveDialogProps extends DialogProps {
  userName: string;
  onConfirm: () => void;
  onClose?: () => void;
}

export function RemoveDialog({ onConfirm, userName, onClose, ...rest }: RemoveDialogProps) {
  const onCopyToClipboard = useCallback(() => {
    onConfirm();
  }, []);

  return (
    <Dialog {...rest} className={styles.dialog}>
      <DialogTitle>
        <ModalTitle title='Remove' onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <Typography component='p' variant='body5'>
          Remove
          <Typography component='span' variant='body5' fontWeight={700}>
            &nbsp;{userName}&nbsp;
          </Typography>
          from the queue?
        </Typography>

        <Stack direction='row' spacing={'10px'} alignItems='center'>
          <Button variant='outlined' fullWidth className={styles.formButton} onClick={onClose}>
            Cancel
          </Button>
          <Button variant='contained' fullWidth className={styles.formButton} onClick={onCopyToClipboard}>
            Remove
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
