/* eslint-disable prettier/prettier */

import { useContext } from 'react';
import { TrackingAnalyticsContext } from 'context';

export function useTrackingAnalytics() {
    const context = useContext(TrackingAnalyticsContext);
    if (!context) {
        throw new Error('useTrackingAnalytics must be used within a TrackingAnalyticsProvider');
    }
    return context;
}
