import { useCallback } from 'react';
import { LocalAudioTrack } from 'twilio-video';
import { useVideo } from '../useVideo';
import { useIsTrackEnabled } from '../useIsTrackEnabled';

export function useLocalAudioToggle() {
  const { localTracks } = useVideo();
  const audioTrack = localTracks.find((track) => track.kind === 'audio') as LocalAudioTrack;
  const isEnabled = useIsTrackEnabled(audioTrack);

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable();
    }
  }, [audioTrack]);

  return [isEnabled, toggleAudioEnabled] as const;
}
