import Qs from 'qs';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, Method, ResponseType } from 'axios';
import { TokenResponse } from 'types';
import { TOKENS } from 'constants/index';

interface ApiRequest {
  url: string;
  method?: Method;
  payload?: any;
  headers?: Record<string, string>;
  params?: Record<string, string>;
  responseType?: ResponseType;
}

export interface IApi {
  request: <R>(request: ApiRequest) => Promise<R>;
  unauthRequest: <R>(request: ApiRequest) => Promise<R>;
}

class Api implements IApi {
  readonly adapter: AxiosInstance;

  constructor(baseURL: string) {
    this.adapter = axios.create({
      baseURL,
    });
  }

  unauthRequest = async <R>({
    url,
    method = 'get',
    payload,
    headers = {},
    params,
    responseType = 'json',
  }: ApiRequest): Promise<R> => {
    try {
      const request: AxiosRequestConfig = {
        url,
        method,
        data: payload,
        params,
        paramsSerializer: function (params) {
          return Qs.stringify(params, { arrayFormat: 'comma' });
        },
        headers: {
          ...headers,
          'app-id': '8fdac0f1-55a4-415f-aff0-3243aa3241d0',
        },
        responseType,
        withCredentials: true,
      };

      const response: AxiosResponse = await this.adapter.request(request);

      return Promise.resolve(response.data);
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log('error =', error);

      return Promise.reject(error);
    }
  };

  request = async <R>(apiRequest: ApiRequest): Promise<R> => {
    const tokensJson: string | null = window.localStorage.getItem(TOKENS);
    const tokens = tokensJson ? (JSON.parse(tokensJson) as TokenResponse) : null;

    return this.unauthRequest<R>({
      ...apiRequest,
      headers: { ...apiRequest.headers, Authorization: `Bearer ${tokens?.token}` },
    });
  };
}

export default new Api(process.env['REACT_APP_HIPAA_LINK_API_URI'] || '');
