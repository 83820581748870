import React from 'react';
import { Tooltip, Typography } from '@mui/material';

import styles from './ControlTooltip.module.scss';

type ControlTooltipProps = {
  children: React.ReactElement<any, any>;
  title: string;
  isDisabled?: boolean;
};

export function ControlTooltip({ children, title, isDisabled }: ControlTooltipProps) {
  return (
    <Tooltip
      title={
        title && (
          <Typography component='p' variant='body2' sx={{ color: '#FFF' }}>
            {title}
          </Typography>
        )
      }
      placement='top'
      sx={{ cursor: isDisabled ? 'initial' : 'pointer' }}
      classes={{ tooltip: styles.tooltip }}
    >
      {children}
    </Tooltip>
  );
}
