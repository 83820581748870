import { createContext } from 'react';
import { ApiUser, RegisterByInvitationRequest, TokenResponse } from 'types';

export interface IUserContext {
  isLoading: boolean;
  tokens: TokenResponse | null;
  logout(): void;
  login(tokenResponse: TokenResponse): Promise<ApiUser>;
  changeClinic(clinicId: string): Promise<ApiUser>;
  registerSecondAccount(organization: string): Promise<void>;
  registerByInvitation(registerRequest: RegisterByInvitationRequest): Promise<void>;
  disconnect(): void;
}

export const UserContext = createContext<IUserContext | null>(null);
export const UserConsumer = UserContext.Consumer;
