import { useCallback, useState } from 'react';
import { ListItem, ListItemText, List } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useAppDispatch, useAppSelector } from 'store';
import { removePatient, selectPatients } from 'store/patientQueue';
import { selectUser } from 'store/user';
import { createRoom, getRoomHistory } from 'store/call';
import { useDialog, useSignalrConnection, useVideo, useChat, useEMDR } from 'hooks';
import { IEMDRContext } from 'context';
import { HubPatient } from 'types';
import { getFullName, stringifyIdentity } from 'utils';
import { RequestPaymentDialog } from 'components';
import { StatusDialog, PatientListItem, ContactDialog } from './components';
import { Spinner } from '../Spinner';
import { RemoveDialog, SimpleInfoDialog } from '../Dialogs';

import styles from './PatientList.module.scss';

export enum PatientListDialogType {
  STATUS,
  RENAME,
  CONTACT,
  PAYMENT,
  REMOVE,
}

export function PatientList({
  isCallStarted = false,
  isListPinned = false,
}: {
  isCallStarted?: boolean;
  isListPinned?: boolean;
}) {
  const dispatch = useAppDispatch();
  const patients = useAppSelector(selectPatients);
  const user = useAppSelector(selectUser);
  const { enqueueSnackbar } = useSnackbar();
  const { connect: videoConnect, isConnecting, room } = useVideo();
  const { addPatientToRoomChat } = useChat();
  const { getToken, invitePatient, removePatientFromQueue } = useSignalrConnection();
  const { showDialog } = useDialog();
  const { state }: IEMDRContext = useEMDR();

  const [loading, setLoading] = useState<boolean>(false);

  const onStartCall = useCallback(
    (id: string) => {
      if (user && state === 'off') {
        setLoading(true);

        const identity = stringifyIdentity([user?.clinicUser.id || '', getFullName(user)]);
        dispatch(createRoom())
          .unwrap()
          .then((roomHistory) => {
            const roomId = roomHistory.room!;
            return (
              dispatch(getRoomHistory())
                .unwrap()
                .then(() => {
                  return (
                    getToken({ identity, roomId })
                      .then((token) => {
                        return videoConnect(token).then(() => {
                          addPatientToRoomChat(id, roomId);
                          invitePatient({ connectionId: id, roomId });
                          dispatch(removePatient({ connectionId: id }));
                        });
                      })
                      // eslint-disable-next-line no-console
                      .catch((e) => console.log(e))
                  );
                })
                // eslint-disable-next-line no-console
                .catch((e) => console.log(e))
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [getToken, invitePatient, addPatientToRoomChat, user],
  );

  const onInvitePatient = useCallback(
    (id: string) => {
      if (user) {
        if ((room?.participants?.size || 0) >= 15) {
          enqueueSnackbar('Max participation of 15 has been reached.', { variant: 'info' });
        } else if (state !== 'off') {
          const modal = showDialog(SimpleInfoDialog, {
            text: 'Please close EMDR to allow additional participants.',
            onConfirm: () => {
              modal.destroy();
            },
          });
        } else {
          invitePatient({ connectionId: id, roomId: room?.name || '' });
          addPatientToRoomChat(id, room?.name || '');
          dispatch(removePatient({ connectionId: id }));
        }
      }
    },
    [invitePatient, addPatientToRoomChat, user, room],
  );

  const onDialogOpen = useCallback((type: PatientListDialogType, patient: HubPatient) => {
    switch (type) {
      case PatientListDialogType.CONTACT: {
        const modal = showDialog(ContactDialog, {
          patient,
          onConfirm: () => {
            modal.destroy();
          },
        });
        break;
      }
      case PatientListDialogType.STATUS: {
        const modal = showDialog(StatusDialog, {
          patient,
          onConfirm: () => {
            modal.destroy();
          },
        });
        break;
      }
      case PatientListDialogType.PAYMENT: {
        const modal = showDialog(RequestPaymentDialog, {
          clientId: patient.connectionId,
          clientName: patient.userName,
          onConfirm: () => {
            modal.destroy();
          },
        });
        break;
      }
      case PatientListDialogType.REMOVE: {
        const modal = showDialog(RemoveDialog, {
          userName: patient.userName,
          onConfirm: () => {
            modal.destroy();
            removePatientFromQueue(patient.connectionId);
          },
        });
        break;
      }
      case PatientListDialogType.RENAME:
        // eslint-disable-next-line no-console
        console.log({ type, patient });
        break;
      default:
        break;
    }
  }, []);

  return (
    <List sx={{ width: '100%', bgcolor: 'transparent' }} className={styles.patientList}>
      {(isConnecting || loading) && <Spinner spinnerClassName={styles.spinner} />}
      {[...patients]
        .sort(
          (patientA: HubPatient, patientB: HubPatient) =>
            new Date(patientA.joinedOn).getTime() - new Date(patientB.joinedOn).getTime(),
        )
        .map((patient) => (
          <PatientListItem
            key={patient.connectionId}
            isCallStarted={isCallStarted}
            isListPinned={isListPinned}
            patient={patient}
            onStartCall={isCallStarted ? onInvitePatient : onStartCall}
            onDialogOpen={(type) => onDialogOpen(type, patient)}
          />
        ))}

      {patients.length === 0 && (
        <ListItem>
          <ListItemText primary='No clients in queue' />
        </ListItem>
      )}
    </List>
  );
}
