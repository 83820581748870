/* eslint-disable prettier/prettier */
import { useEffect, useRef } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import classnames from 'classnames';

import { Logo } from 'assets';
import { GRID_VIEW_THRESHOLD } from '../../../../constants';
import {
  useAppFeatureFlags,
  useAppSettings,
  useAudioDeviceDisconectedHandler,
  useDialog,
  useEMDR,
  useIsRecording,
  useParticipants,
  useRoomHost,
  useScreenShareParticipant,
  useScreenSize,
  useVideo,
  useWhiteboard,
} from 'hooks';
import { LayoutType } from 'types';
import { IEMDRContext, IWhiteboardContext } from 'context';
import { Chat, SimpleInfoDialog } from 'components';
import { WaitingQueue } from '../WaitingQueue';
import { MainControls } from '../MainControls';
import { SidebarControls } from '../SidebarControls';
import { RightSidebar } from '../RightSidebar';
import { ParticipantAudioTracks } from '../ParticipantAudioTracks';
import { SpeakerView } from '../SpeakerView';
import { GridView } from '../GridView';
import { BottomBar } from '../BottomBar';

import styles from './Room.module.scss';

export function Room(): JSX.Element {
  const { shouldShowMicSpeakerWarning } = useAppFeatureFlags();
  const { isHost } = useRoomHost();
  const { room } = useVideo();
  const { showDialog } = useDialog();
  const screenShareParticipant = useScreenShareParticipant();
  const { isGalleryViewActive, setLayoutType } = useAppSettings();
  const { isMobile, isDesktop } = useScreenSize();
  const participants = useParticipants();
  const { state: whiteboardState }: IWhiteboardContext = useWhiteboard();
  const { state: emdrState }: IEMDRContext = useEMDR();
  const isRecording = useIsRecording();

  useAudioDeviceDisconectedHandler();

  const isGalleryViewActiveRef = useRef(isGalleryViewActive);

  useEffect(() => {
    if (shouldShowMicSpeakerWarning) {
      const modal = showDialog(SimpleInfoDialog, {
        text: 'If you experience sound issues, please switch you microphone/speaker device in the settings on the bottom right corner.',
        onConfirm: () => {
          modal.destroy();
        },
      });
    }
  }, []);

  // Save the user's view setting whenever they change to speaker view or gallery view:
  useEffect(() => {
    isGalleryViewActiveRef.current = isGalleryViewActive;
  }, [isGalleryViewActive]);

  useEffect(() => {
    if (screenShareParticipant) {
      // When screensharing starts, save the user's previous view setting (speaker or gallery):
      const prevIsGalleryViewActive = isGalleryViewActiveRef.current;
      // Turn off gallery view so that the user can see the screen that is being shared:
      setLayoutType(LayoutType.SPEAKER);
      return () => {
        // If the user was using gallery view prior to screensharing, turn gallery view back on
        // once screensharing stops:
        if (prevIsGalleryViewActive) {
          setLayoutType(LayoutType.GRID);
        }
      };
    }
  }, [screenShareParticipant, setLayoutType, room]);

  useEffect(() => {
    if (participants.length < GRID_VIEW_THRESHOLD || whiteboardState !== 'off') {
      setLayoutType(LayoutType.SPEAKER);
    }
  }, [participants, whiteboardState]);

  return (
    <Box className={classnames(styles.room)}>
      <ParticipantAudioTracks />

      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-end'
        gap='18px'
        className={styles.logo}>
        {isRecording && isHost && (
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 600,
              color: '#81CFDD'
            }}>
            Transcribing…
          </Typography>
        )}
        <Logo
          sx={{
            width: '12.1rem',
            height: '2rem',
          }}
        />
      </Stack>

      <Box className={styles.mainContent}>
        {isGalleryViewActive ? <GridView /> : <SpeakerView />}
        {isDesktop && <RightSidebar />}
      </Box>
      <Box className={classnames(styles.controls, { [styles.controlsNotHost]: !isHost })}>
        {isHost && (
          <Box
            className={classnames(styles.waitingQueue, {
              [styles.waitingQueueGalery]: !isGalleryViewActive,
              [styles.waitingQueueEMDR]: emdrState !== 'off',
            })}
            zIndex={11}
          >
            <WaitingQueue />
          </Box>
        )}

        {isDesktop && <div></div>}

        <Box>
          <MainControls />
        </Box>

        {!isMobile && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SidebarControls />
          </Box>
        )}
      </Box>
      {!isDesktop && <BottomBar />}
      <Chat shouldIgnoreReceiveMessages={false} className={styles.chat} />
    </Box>
  );
}
