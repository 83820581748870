import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';

import { ILoginContext } from 'context';
import { useAppFeatureFlags, useLogin } from 'hooks';
import { ClinicSelector } from '../ClinicSelector';

import styles from './SelectClinicForm.module.scss';

const schema = Yup.object().shape({
  clinicId: Yup.string().required(),
});

export function SelectClinicForm() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { displayIconInOrganizationSelectionDropdownFeature } = useAppFeatureFlags();
  const { enqueueSnackbar } = useSnackbar();
  const { loginModelData, onSelectClinic }: ILoginContext = useLogin();

  const form = useForm<{ clinicId: string }>({
    resolver: yupResolver(schema),
    defaultValues: { clinicId: loginModelData.clinics![0].clinicId },
    mode: 'all',
  });

  const handleLogin = (): void => {
    if (form.formState.isValid) {
      onSelectClinic(form.getValues().clinicId).catch((error) => {
        enqueueSnackbar(error, { variant: 'error' });
      });
    }
  };

  return (
    <div className={styles.form}>
      <Typography variant={isMobile ? 'h2' : 'h1'} component={isMobile ? 'h2' : 'h1'}>
        Select Organization
      </Typography>

      <Typography variant='body2' component='span'>
        Select the organization you would like to login to. You can switch between them in the settings.
      </Typography>

      <Stack className={styles.row}>
        <ClinicSelector
          name='clinicId'
          control={form.control}
          options={loginModelData.clinics!}
          displayIcon={displayIconInOrganizationSelectionDropdownFeature}
        />
      </Stack>

      <Stack className={styles.row} alignItems='flex-end'>
        <Button
          variant='contained'
          onClick={handleLogin}
          disabled={!form.formState.isValid}
          className={styles.formButton}
          type='submit'
        >
          Continue
        </Button>
      </Stack>
    </div>
  );
}
