import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function EmailIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='22' height='18' viewBox='0 0 22 18' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.7 0.200195C20.5225 0.200195 22 1.67766 22 3.5002V14.5002C22 16.3227 20.5225 17.8002 18.7 17.8002H3.3C1.47746 17.8002 0 16.3227 0 14.5002V3.5002C0 1.67766 1.47746 0.200195 3.3 0.200195H18.7ZM19.8 3.861L11.7244 10.928C11.3442 11.2607 10.7922 11.2884 10.3834 11.0112L10.2756 10.928L2.2 3.8621V14.5002C2.2 15.1077 2.69249 15.6002 3.3 15.6002H18.7C19.3075 15.6002 19.8 15.1077 19.8 14.5002V3.861ZM18.128 2.4002H3.8698L11 8.63855L18.128 2.4002Z'
      />
    </SvgIcon>
  );
}
