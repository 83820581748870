import { useState, useRef, useEffect, MutableRefObject, useCallback } from 'react';
import { Typography, TextField, InputAdornment, IconButton } from '@mui/material';

import { AttachIcon, SendIcon } from 'assets';
import { useChatState } from 'hooks';

import styles from './ChatInput.module.scss';

const ALLOWED_FILE_TYPES =
  'audio/*, image/*, text/*, video/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document .xslx, .ppt, .pdf, .key, .svg, .csv, application/x-zip-compressed';

type ChatInputProps = {
  isGroup: boolean;
  onTyping(): void;
  sendMessage(message: string | FormData): Promise<number>;
};

export function ChatInput({ isGroup, onTyping, sendMessage }: ChatInputProps): JSX.Element {
  const { typingUserName } = useChatState(isGroup);

  const inputFile: MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);
  const [input, setInput] = useState<string>('');
  const [isSendingFile, setIsSendingFile] = useState<boolean>(false);
  const [sendingFileError, setSendingFileError] = useState<string | null>(null);
  const [typingDots, setTypingDots] = useState<string>('');

  useEffect(() => {
    if (typingUserName) {
      const timerId = setInterval(() => {
        setTypingDots((prevTypingDots: string) => {
          if (prevTypingDots.length >= 3) {
            return '';
          }

          return prevTypingDots + '.';
        });
      }, 500);

      return () => {
        clearInterval(timerId);
      };
    }
  }, [typingUserName]);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setInput(event.target.value);
    onTyping();
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleSandMessage();
    }
  };

  const handleSandMessage = (): void => {
    if (input.trim()) {
      sendMessage(input.trim());
      setInput('');
    }
  };

  const handleSelectFile = useCallback((): void => {
    onTyping();
    inputFile.current?.click();
  }, [inputFile, onTyping]);

  const handleSendFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file: File | undefined = event.target.files?.[0];
    if (file) {
      const formData: FormData = new FormData();
      formData.append('userfile', file);
      setIsSendingFile(true);
      setSendingFileError(null);
      sendMessage(formData)
        .catch((e: any) => {
          if (e.code === 413) {
            setSendingFileError('File size is too large. Maximum file size is 150MB');
          } else {
            setSendingFileError('There was a problem uploading the file. Please try again');
          }

          // eslint-disable-next-line no-console
          console.log('Sending file error: ', e);
        })
        .finally(() => {
          setIsSendingFile(false);
        });
    }
  };

  return (
    <div className={styles.chatInput}>
      <div className={styles.typing}>
        {typingUserName && (
          <Typography component='p' variant='body6' sx={{ color: '#626363' }}>
            <b>{typingUserName}</b>
            {` is typing${typingDots}`}
          </Typography>
        )}
      </div>
      <>{sendingFileError}</>
      <div className={styles.inputs}>
        <IconButton onClick={handleSelectFile} disabled={isSendingFile} className={styles.attachButton}>
          <>
            <AttachIcon color='primary' />
            <input type='file' hidden ref={inputFile} onChange={handleSendFile} accept={ALLOWED_FILE_TYPES} />
          </>
        </IconButton>

        <TextField
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          value={input}
          className={styles.textInput}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={handleSandMessage}>
                  <SendIcon color='primary' />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  );
}
