import { ComponentType, createContext } from 'react';

export interface IDialogContext {
  showDialog: ShowDialog;
  confirm(args: ConfirmDialogArgs): Promise<ConfirmResultEnum>;
}

export type ShowDialog = <T extends Record<string, any>>(
  component: ComponentType<T>,
  props?: Omit<T, 'open'>,
) => {
  destroy: () => void;
};

export type ConfirmDialogArgs = {
  content: ComponentType<any>;
  props?: Omit<any, 'open'>;
};

export enum ConfirmResultEnum {
  Confirm,
  Close,
  Cancel,
}

export const DialogContext = createContext<IDialogContext | null>(null);
export const DialogConsumer = DialogContext.Consumer;
