/* eslint-disable prettier/prettier */
import { FC } from 'react';
import { Button, Dialog, DialogContent, DialogProps, Stack, Typography } from '@mui/material';

export interface AINotesTranscriptionStopDialogProps extends DialogProps {
  onAINotesTranscriptionStopConfirmResult?(result: 'Confirmed' | 'Canceled'): void;
}

export const AINotesTranscriptionStopDialog: FC<AINotesTranscriptionStopDialogProps> = ({
  onAINotesTranscriptionStopConfirmResult,
  ...props
}) => {
  const handleSubmit = async (): Promise<void> => {
    onAINotesTranscriptionStopConfirmResult?.('Confirmed');
  };

  const handleCancel = (): void => {
    onAINotesTranscriptionStopConfirmResult?.('Canceled');
  };

  return (
    <Dialog
      {...props}
      sx={{
        '.MuiPaper-root': {
          width: 250,
        },
      }}
    >
      <DialogContent
        sx={() => ({
          padding: 20,
        })}
      >
        <Stack direction='column' alignItems='center' justifyContent='center' gap='15px'>
          <Typography
            sx={{
              fontSize: 15,
              fontWeight: 700,
              lineHeight: '20.43px',
            }}
          >
            Are you sure?
          </Typography>

          <Typography
            sx={{
              fontSize: 15,
              fontWeight: 400,
              lineHeight: '20.43px',
              textAlign: 'center'
            }}
          >
            AI Progress Notes will end and a report will be generated for this session.
          </Typography>

          <Stack
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '10px',
              width: '100%',
            }}
          >
            <Button variant='outlined' onClick={handleCancel}>
              Cancel
            </Button>

            <Button color='primary' variant='contained' onClick={handleSubmit}>
              Yes
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
