import { useMemo } from 'react';
import useWindowSize from 'use-window-size-v2';
import { Theme, useMediaQuery } from '@mui/material';

/*
  Returns the participant that is sharing their screen (if any). This hook assumes that only one participant
  can share their screen at a time.
*/
export function useScreenSize() {
  const { height, width } = useWindowSize();

  const isMobileVertical: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isMobileHorizontal: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.down('md')) && height < width;
  const isMobile: boolean = isMobileVertical || isMobileHorizontal;

  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md')) && height > width;
  const isBetweenMdAndLg = useMediaQuery((theme: Theme) => theme.breakpoints.between('md', 'lg'));

  const isDesktop: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const isTouchDevice: boolean = useMemo(
    () => 'ontouchstart' in window || navigator.maxTouchPoints > 0 || (navigator as any).msMaxTouchPoints > 0,
    [height],
  );

  return {
    height,
    width,
    isMobile,
    isMobileVertical,
    isMobileHorizontal,
    isTablet,
    isBetweenMdAndLg,
    isDesktop,
    isTouchDevice,
  };
}
