import { useCallback, useState } from 'react';

import { SELECTED_MASTER_VOLUME_KEY } from '../../constants';

export function useMasterVolume() {
  const [storedMasterVolume, setStoredMasterVolume] = useState<number>(
    parseFloat(window.localStorage.getItem(SELECTED_MASTER_VOLUME_KEY) || '75'),
  );

  const setMasterVolume = useCallback((newValue: number) => {
    setStoredMasterVolume(newValue);
    window.localStorage.setItem(SELECTED_MASTER_VOLUME_KEY, newValue.toString());
  }, []);

  return [storedMasterVolume, setMasterVolume] as const;
}
