import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function BookInfoIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='14' height='18' viewBox='0 0 14 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M0 2.63684C0 1.46533 0.949701 0.515625 2.12121 0.515625H11.8788C13.0503 0.515625 14 1.46533 14 2.63684V14.7277C14 15.0792 13.7151 15.3641 13.3636 15.3641H1.27273C1.27273 15.8327 1.65261 16.2126 2.12121 16.2126H13.3636C13.7151 16.2126 14 16.4975 14 16.849C14 17.2004 13.7151 17.4853 13.3636 17.4853H2.12121C0.949701 17.4853 0 16.5356 0 15.3641V2.63684ZM7 5.60653C7.46862 5.60653 7.84848 5.22665 7.84848 4.75805C7.84848 4.28945 7.46862 3.90956 7 3.90956C6.53138 3.90956 6.15152 4.28945 6.15152 4.75805C6.15152 5.22665 6.53138 5.60653 7 5.60653ZM6.36364 7.09138V11.3338C6.36364 11.6852 6.64856 11.9702 7 11.9702C7.35144 11.9702 7.63636 11.6852 7.63636 11.3338V7.09138C7.63636 6.73993 7.35144 6.45502 7 6.45502C6.64856 6.45502 6.36364 6.73993 6.36364 7.09138Z'
        fill='#626363'
      />
    </SvgIcon>
  );
}
