import React, { forwardRef, useMemo } from 'react';
import { Box, IconButton, styled, Typography } from '@mui/material';
import classnames from 'classnames';
import { GearIcon } from 'assets';
import { ControlTooltip } from 'components';

import styles from './ToggleSettingsButton.module.scss';

type ToggleSettingsButtonProps = {
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
  iconColor?: string;
  withTooltip?: boolean;
  onClick: () => void;
  withLabel?: boolean;
};

const GearIconStyled = styled(GearIcon)(({ theme }) => ({
  color: '#fff',
}));

export const ToggleSettingsButton = forwardRef<HTMLButtonElement, ToggleSettingsButtonProps>(
  (
    { disabled, className, iconClassName, iconColor, withTooltip, onClick, withLabel }: ToggleSettingsButtonProps,
    ref,
  ) => {
    const tooltipMessage = useMemo(() => {
      let tooltipMessage = '';

      if (withTooltip) {
        tooltipMessage = 'Settings';
      }

      return tooltipMessage;
    }, [withTooltip]);

    const sx = useMemo(
      () => ({
        backgroundColor: withLabel ? 'transparent !important' : 'rgb(0 0 0 / 50%) !important',
      }),
      [withLabel],
    );

    return (
      <ControlTooltip title={tooltipMessage} isDisabled={disabled}>
        <Box component='span' display='block' className={classnames({ [styles.deviceButton]: !withLabel }, className)}>
          <IconButton
            disabled={disabled}
            className={classnames(
              { [styles.deviceButton]: !withLabel, [styles.deviceButtonWithLabel]: withLabel },
              className,
            )}
            sx={sx}
            onClick={onClick}
            edge='end'
            aria-label='settings'
            ref={ref}
          >
            <GearIconStyled
              className={classnames(styles.icon, iconClassName)}
              sx={withLabel ? { color: iconColor || '#626363' } : {}}
            />
            {withLabel && (
              <Typography component='p' variant='body2' sx={{ color: '#393534' }} ml='10px'>
                Settings
              </Typography>
            )}
          </IconButton>
        </Box>
      </ControlTooltip>
    );
  },
);
