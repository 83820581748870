import { Box, Typography } from '@mui/material';

import { useAppSelector } from 'store';
import { InviteData, selectInvite } from 'store/invite';

import ProfileImage from '../../../../assets/images/LoginLeftImage.png';
import styles from './ProfileLayout.module.scss';

export const ProfileLayout = () => {
  const invite: InviteData = useAppSelector(selectInvite)!;

  return (
    <Box className={styles.layoutContainer}>
      <img className={styles.registerImage} src={ProfileImage} />
      <Typography variant='h1' className={styles.createRoomText}>
        Create Waiting Room
      </Typography>
      <Box className={styles.layoutItem}>
        <Typography variant='body2' className={styles.createRoomDesc}>
          This will be your room for {invite.organizationName}. This is separate from your room on your admin account.
        </Typography>
      </Box>
      <Box className={styles.layoutItem}>
        <Typography variant='body2'>
          You can easily switch between organizations on the top navigation or in settings
        </Typography>
      </Box>
    </Box>
  );
};
