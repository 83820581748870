import React, { useEffect, useMemo } from 'react';
import { Box, Card, CardContent, CardHeader, Hidden, Tab, Theme, useMediaQuery } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useVideo } from 'hooks';
import { VideoPreviewWithControls } from 'components';
import { EditWaitingRoom } from '../EditWaitingRoom';

import styles from './EditWaitingRoomContainer.module.scss';

export function EditWaitingRoomContainer() {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const { getAudioAndVideoTracks } = useVideo();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };

  useEffect(() => {
    getAudioAndVideoTracks();
  }, []);

  const cardHeaderTitle = useMemo(() => {
    let title: React.ReactNode;
    switch (true) {
      case isDesktop: {
        title = (
          <TabList onChange={handleChange} aria-label='Waiting room tabs'>
            <Tab label='My room' value='/' />
            <Tab label='Edit Waiting Room' value='/edit' />
          </TabList>
        );
        break;
      }
      default: {
        title = 'Edit Waiting Room';
        break;
      }
    }
    return title;
  }, [isDesktop]);

  return (
    <>
      <TabContext value={'/edit'}>
        <Card className={styles.main}>
          <CardHeader title={cardHeaderTitle} sx={{ padding: isDesktop ? 0 : '0 20px' }} />
          <CardContent className={styles.content}>
            <Box className={styles.contentWrapper} maxWidth={isDesktop ? 510 : '100%'}>
              <EditWaitingRoom />
            </Box>
            <Hidden only={['sm', 'xs']}>
              <Box className={styles.preview}>
                <VideoPreviewWithControls />
              </Box>
            </Hidden>
          </CardContent>
        </Card>
      </TabContext>
    </>
  );
}
