import { useState, useEffect, useMemo } from 'react';
import { Stack, Dialog, Button, Box, DialogContent, Typography, Divider } from '@mui/material';
import { useSnackbar } from 'notistack';

import { Info, ModalTitle, Spinner } from 'components';
import { withOutsideClickPreventer } from 'utils';
import { CheckIcon } from 'assets';
import {
  AINotesPurchaseItem,
  ApiUser,
  GetAllAINotesPurchaseItemsResponse,
  MakePurchaseResponse,
  MakePurchaseStatusCodes,
} from 'types';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { useApiRequests } from 'hooks';

import AISrc from 'assets/images/AI.png';

type PurchaseCreditsPopupProps = {
  open: boolean;
  onPurchased(itemsCreditsCount: number): void;
  onNoCard(): void;
  onClose(): void;
};

export const PurchaseCreditsPopup = ({ open, onPurchased, onNoCard, onClose }: PurchaseCreditsPopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const user: ApiUser = useAppSelector(selectUser)!;
  const { getAllAINotesItems, makePurchase } = useApiRequests();

  const [loading, setLoading] = useState<boolean>(true);
  const [items, setItems] = useState<AINotesPurchaseItem[]>([]);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const selectedItemPrice: string = useMemo(() => {
    return '$' + (items.find((item: AINotesPurchaseItem) => item.itemId === selectedItemId)?.rate.toFixed(2) ?? '0.00');
  }, [items, selectedItemId]);

  const selectedItemCreditsCount: number = useMemo(() => {
    return +(items.find((item: AINotesPurchaseItem) => item.itemId === selectedItemId)?.unit ?? 0);
  }, [items, selectedItemId]);

  const orderedItems: AINotesPurchaseItem[] = useMemo(() => {
    return items.sort((a: AINotesPurchaseItem, b: AINotesPurchaseItem) => +a.unit - +b.unit);
  }, [items]);

  useEffect(() => {
    getAllAINotesItems()
      .then((response: GetAllAINotesPurchaseItemsResponse) => setItems(response.items))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setSelectedItemId(items[1]?.itemId ?? null);
  }, [items]);

  const handlePurchase = async (): Promise<void> => {
    if (!selectedItemId) {
      return;
    }

    setLoading(true);
    makePurchase(selectedItemId)
      .then((response: MakePurchaseResponse) => {
        if (response.code === MakePurchaseStatusCodes.Success) {
          onPurchased(selectedItemCreditsCount);
        } else if (response.code === MakePurchaseStatusCodes.NoCard) {
          onNoCard();
        }
      })
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: 'error' });
      })
      .finally(() => setLoading(false));
  };

  const handleItemClick = (itemId: string) => () => {
    setSelectedItemId(itemId);
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: '80rem',
          maxWidth: '80rem',
          borderRadius: '1rem',
        },
      }}
      open={open}
      onClose={withOutsideClickPreventer(onClose)}
    >
      {loading && <Spinner small />}

      <DialogContent
        sx={({ breakpoints }) => ({
          padding: 0,
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          [breakpoints.down(breakpoints.values.md)]: {
            display: 'flex',
            flexDirection: 'column-reverse',
          },
        })}
      >
        <Stack
          gap='1.5rem'
          sx={({ breakpoints }) => ({
            padding: '5rem',
            [breakpoints.down(breakpoints.values.md)]: {
              padding: '2rem',
            },
          })}
        >
          <Stack direction='row' gap='0.5rem' alignItems='center'>
            <Box
              component='img'
              src={AISrc}
              sx={{
                width: '2.4rem',
                height: '2.4rem',
              }}
            />

            <Typography
              component='h2'
              variant='h2'
              sx={{
                fontWeight: 700,
              }}
            >
              AI Progress Notes
            </Typography>
          </Stack>

          <Stack gap='1rem'>
            <Stack direction='row' gap='1rem'>
              <CheckIcon stroke='black' strokeWidth='2' sx={{ width: '1.8rem', height: '1.8rem' }} />

              <Typography variant='body2'>Credits are owned by the Organization and shared among all users</Typography>
            </Stack>

            <Stack direction='row' gap='1rem'>
              <CheckIcon stroke='black' strokeWidth='2' sx={{ width: '1.8rem', height: '1.8rem' }} />

              <Typography variant='body2'>
                Starting AI Notes will <b>transcribe</b> and <b>redact PII</b> from your call.
              </Typography>
            </Stack>

            <Stack direction='row' gap='1rem'>
              <CheckIcon stroke='black' strokeWidth='2' sx={{ width: '1.8rem', height: '1.8rem' }} />

              <Typography variant='body2'>
                Choose which field your report should be written as, for example:{' '}
                <b>Cognitive Behavioral Therapist (CBT)</b>
              </Typography>
            </Stack>

            <Stack direction='row' gap='1rem'>
              <CheckIcon stroke='black' strokeWidth='2' sx={{ width: '1.8rem', height: '1.8rem' }} />

              <Typography variant='body2'>
                Report includes:{' '}
                <b>
                  Meeting Details, Summary, Sentiment Analysis, Mental Status Examination (MSE), Progress Note,
                  Treatment Plan
                </b>
              </Typography>
            </Stack>

            <Stack direction='row' gap='1rem'>
              <CheckIcon stroke='black' strokeWidth='2' sx={{ width: '1.8rem', height: '1.8rem' }} />

              <Typography variant='body2'>
                Select from <b>SOAP, DAP, BIRP, GIRP, PIRP, RIFT, CARE, STOP, MINT,</b> or <b>FORT</b> progress notes
              </Typography>
            </Stack>

            <Stack direction='row' gap='1rem'>
              <CheckIcon stroke='black' strokeWidth='2' sx={{ width: '1.8rem', height: '1.8rem' }} />

              <Typography variant='body2'>
                When the call ends, your report will be available for download within minutes.
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          sx={({ breakpoints }) => ({
            justifyContent: 'space-between',
            paddingInline: '5rem',
            paddingBlock: '3rem',
            boxShadow: '-2px 0px 8px 0px rgba(0, 0, 0, 0.1)',
            [breakpoints.down(breakpoints.values.md)]: {
              padding: '2rem',
              gap: '0.5rem',
            },
          })}
        >
          <Stack gap='1.5rem'>
            <ModalTitle title='Current Quantity' onClose={onClose} notTransformToUpper />

            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '1.2rem',
                lineHeight: '1.6rem',
                color: 'background: rgba(98, 99, 99, 1)',
              }}
            >
              <b>Total Available Credits:</b> {user?.clinicUser?.aiCreditsCount ?? 0}
            </Typography>

            <Stack gap='1.1rem' direction='row' alignItems='center'>
              <Typography variant='h4' component='h4'>
                Purchase Credits
              </Typography>

              <Info text='Need text' />
            </Stack>

            <Stack gap='0.7rem'>
              {orderedItems.map((item: AINotesPurchaseItem, index: number) => (
                <Stack
                  key={index}
                  direction='row'
                  gap='1rem'
                  sx={{
                    gap: '1rem',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '3rem',
                    borderRadius: '0.3rem',
                    border: '0.1rem solid rgba(209, 209, 209, 1)',
                    background: selectedItemId === item.itemId ? '#81CFDD' : 'auto',
                    cursor: 'pointer',
                  }}
                  onClick={handleItemClick(item.itemId)}
                >
                  <Typography variant='body5' fontWeight={700}>
                    {item.name}
                  </Typography>

                  <Typography variant='body5'>${item.rate}</Typography>
                </Stack>
              ))}
            </Stack>

            <Stack direction='row' justifyContent='space-between'>
              <Typography variant='body4'>Total</Typography>

              <Typography variant='body4'>{selectedItemPrice}</Typography>
            </Stack>

            <Divider />

            <Typography variant='body1'>One-time purchase. Purchase more as needed.</Typography>
          </Stack>

          <Stack
            sx={{
              display: 'grid',
              gap: '1rem',
              gridTemplateColumns: '1fr 1fr',
            }}
          >
            <Button variant='outlined' onClick={onClose}>
              Cancel
            </Button>

            <Button variant='contained' onClick={handlePurchase}>
              Pay {selectedItemPrice}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
