import { useCallback, useEffect, useRef, useState } from 'react';
import Video, { ConnectOptions, LocalTrack, Room } from 'twilio-video';

import { isMobile } from 'utils';
import { Callback } from 'types';

export function useRoom(localTracks: LocalTrack[], onError: Callback, options?: ConnectOptions) {
  const [room, setRoom] = useState<Room | null>(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const optionsRef = useRef(options);
  const localTracksRef = useRef(localTracks);

  useEffect(() => {
    // This allows the connect function to always access the most recent version of the options object. This allows us to
    // reliably use the connect function at any time.
    optionsRef.current = options;
  }, [options]);

  useEffect(() => {
    // This allows the connect function to always access the most recent version of the localTracks array. This allows us to
    // reliably use the connect function at any time.
    localTracksRef.current = localTracks;
  }, [localTracks]);

  const connect = useCallback(
    (token: string) => {
      setIsConnecting(true);

      return Video.connect(token, {
        ...optionsRef.current,
        tracks: localTracksRef.current,
        dominantSpeaker: true,
        networkQuality: {
          local: 1,
          remote: 1,
        },
      }).then(
        (newRoom) => {
          setRoom(newRoom);
          const disconnect = () => newRoom.disconnect();

          newRoom.setMaxListeners(20);

          newRoom.once('disconnected', () => {
            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => setRoom(null));
            window.removeEventListener('beforeunload', disconnect);

            if (isMobile) {
              window.removeEventListener('pagehide', disconnect);
            }
          });

          // @ts-ignore
          window.twilioRoom = newRoom;

          newRoom.localParticipant.videoTracks.forEach((publication) =>
            // All video tracks are published with 'low' priority because the video track
            // that is displayed in the 'MainParticipant' component will have it's priority
            // set to 'high' via track.setPriority()
            publication.setPriority('low'),
          );

          setIsConnecting(false);

          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener('beforeunload', disconnect);

          if (isMobile) {
            // Add a listener to disconnect from the room when a mobile user closes their browser
            window.addEventListener('pagehide', disconnect);
          }
        },
        (error) => {
          onError(error);
          setIsConnecting(false);
        },
      );
    },
    [localTracks, onError],
  );

  return { room, isConnecting, connect };
}
