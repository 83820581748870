import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function MovementInfinityIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='20' height='10' viewBox='0 0 20 10' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M14.7219 0C12.6563 0 11.0406 1.29063 10 2.45625C8.95938 1.29063 7.34375 0 5.27812 0C2.36875 0 0 2.24375 0 5C0 7.75625 2.36875 10 5.27812 10C7.34375 10 8.95938 8.70937 10 7.54375C11.0406 8.70937 12.6563 10 14.7219 10C17.6313 10 20 7.75625 20 5C20 2.24375 17.6313 0 14.7219 0ZM5.27812 7C4.02187 7 3 6.10312 3 5C3 3.89688 4.02187 3 5.27812 3C6.47187 3 7.57187 4.12813 8.21562 5C7.57812 5.8625 6.46875 7 5.27812 7ZM14.7219 7C13.5281 7 12.4281 5.87187 11.7844 5C12.4219 4.1375 13.5313 3 14.7219 3C15.9781 3 17 3.89688 17 5C17 6.10312 15.9781 7 14.7219 7Z' />
    </SvgIcon>
  );
}
