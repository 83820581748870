import { useMemo } from 'react';
import { Grow } from '@mui/material';
import classnames from 'classnames';

import { useRoomRightSidebar } from 'hooks';
import { GroupChat } from '../GroupChat';
import { ParticipantsMenu } from '../ParticipantsMenu';
import { NotesMenu } from '../NotesMenu';
import { BackgroundEffectsMenu } from '../BackgroundEffectsMenu';
import { ClientQueueMenu } from '../ClientQueueMenu';
import { SettingsMenu } from '../SettingsMenu';

import styles from './BottomBar.module.scss';

export function BottomBar(): JSX.Element {
  const { openedTab } = useRoomRightSidebar();

  const isSmallerWidth: boolean = useMemo(() => {
    return openedTab === 'settings' || openedTab === 'backgroundEffects';
  }, [openedTab]);

  return (
    <>
      {openedTab && (
        <Grow in={!!openedTab} style={{ transformOrigin: '0 0 0' }} {...(openedTab ? { timeout: 1000 } : {})}>
          <div
            className={classnames(styles.container, {
              [styles.containerSmallerWidth]: isSmallerWidth,
            })}
          >
            {openedTab === 'chat' && <GroupChat className={styles.chat} />}
            {openedTab === 'settings' && <SettingsMenu withLayoutSelection className={styles.settings} />}
            {openedTab === 'participants' && <ParticipantsMenu className={styles.participants} />}
            {openedTab === 'notes' && <NotesMenu className={styles.notes} />}
            {openedTab === 'backgroundEffects' && <BackgroundEffectsMenu className={styles.backgroundEffects} />}
            {openedTab === 'waitingQueue' && <ClientQueueMenu className={styles.backgroundEffects} />}
          </div>
        </Grow>
      )}
    </>
  );
}
