import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function VideoCameraOffIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='28' height='24' viewBox='0 0 28 24' fill='none' {...props}>
      <g clipPath='url(#clip0_1790_36732)'>
        <path d='M20.9498 21.0244L11.8623 11.0244L4.54978 2.98694L3.08728 1.38694H3.07478C3.07478 1.38694 3.02478 1.33694 3.01228 1.32444L2.31228 0.549445H2.29978C1.96228 0.174445 1.39978 0.149445 1.03728 0.486945C0.674776 0.824445 0.649776 1.38694 0.987276 1.74944L2.28728 3.14944L2.91228 3.83694C2.07478 4.54944 1.54978 5.61194 1.54978 6.79944V17.2369C1.54978 19.3994 3.29978 21.1494 5.46228 21.1494H17.2248C17.6623 21.1494 18.0748 21.0619 18.4623 20.9244L19.6373 22.2119H19.6498L20.3873 23.0369C20.7123 23.3994 21.2873 23.4244 21.6498 23.0994C22.0123 22.7744 22.0498 22.1994 21.7123 21.8369L20.9623 21.0119L20.9498 21.0244Z' />
        <path d='M24.3378 5.63789L21.1628 8.81289V6.75039C21.1628 4.58789 19.4128 2.83789 17.2503 2.83789H9.06276C8.22526 2.83789 7.77526 3.83789 8.33776 4.45039C10.2378 6.53789 14.5003 11.2129 17.4503 14.4504L21.1378 18.5004V15.1254L24.3253 18.3129C25.5628 19.5504 27.6753 18.6754 27.6753 16.9254V7.02539C27.6753 5.27539 25.5628 4.40039 24.3253 5.63789H24.3378Z' />
      </g>
      <defs>
        <clipPath id='clip0_1790_36732'>
          <rect width='26.925' height='23.1' fill='white' transform='translate(0.75 0.25)' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
