import { Box } from '@mui/material';

import { IEMDRContext, IWhiteboardContext } from 'context';
import { useEMDR, useWhiteboard } from 'hooks';
import { EMDRProviderContainer } from '../EMDRProviderContainer/EMDRProviderContainer';
import { WhiteboardContainer } from '../WhiteboardContainer';
import { MainParticipant } from '../MainParticipant';
import { ParticipantList } from '../ParticipantList';
import { ApiUser } from 'types';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';

import styles from './SpeakerView.module.scss';

export function SpeakerView(): JSX.Element {
  const { state: emdrState }: IEMDRContext = useEMDR();
  const { state: whiteboardState, isFullScreen: isWhiteboardFullScreen }: IWhiteboardContext = useWhiteboard();
  const user: ApiUser | null = useAppSelector(selectUser);

  return (
    <Box className={styles.container}>
      <ParticipantList />
      {whiteboardState === 'off' && (emdrState === 'off' || !user) && <MainParticipant />}
      {emdrState !== 'off' && user && <EMDRProviderContainer />}
      {whiteboardState !== 'off' && !isWhiteboardFullScreen && <WhiteboardContainer />}
    </Box>
  );
}
