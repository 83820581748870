import { useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, Theme, Typography, useMediaQuery } from '@mui/material';

import { FormInput } from 'components';
import { LoginRequest } from 'types';
import { ILoginContext } from 'context';
import { useLogin } from 'hooks';

import styles from './LoginForm.module.scss';

const schema = Yup.object().shape({
  email: Yup.string().email('Email must be valid').required('Email is a required field'),
  password: Yup.string().required('Password is a required field'),
});

export function LoginForm() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { onLogin, onForgotPassword }: ILoginContext = useLogin();

  const [error, setError] = useState<string | null>(null);
  const form = useForm<LoginRequest>({
    resolver: yupResolver(schema),
    defaultValues: {},
    mode: 'all',
  });

  const handleLogin = (): void => {
    if (form.formState.isValid) {
      onLogin(form.getValues()).catch((error) => {
        setError(error.message);
      });
    }
  };

  const handleForgotPassword = (): void => {
    onForgotPassword();
  };

  return (
    <div className={styles.form}>
      <Typography variant={isMobile ? 'h2' : 'h1'} component={isMobile ? 'h2' : 'h1'}>
        Login to Your Account
      </Typography>

      {error && (
        <Typography variant='body1' component='span' color='#FF3D3D'>
          {error}
        </Typography>
      )}

      <Stack className={styles.row}>
        <Stack direction='row' alignItems='center'>
          <Typography component='p' variant='body2'>
            Email <span className={styles.asterisk}>*</span>
          </Typography>
        </Stack>
        <FormInput name='email' control={form.control} errorHelperClassName={styles.errorMessage} />
      </Stack>

      <Stack className={styles.row}>
        <Stack direction='row' alignItems='center'>
          <Typography component='p' variant='body2'>
            Password <span className={styles.asterisk}>*</span>
          </Typography>
        </Stack>
        <FormInput name='password' control={form.control} errorHelperClassName={styles.errorMessage} type='password' />
      </Stack>

      <Stack direction='row' alignItems='left'>
        <Typography variant='body3' component='span' className={styles.forgotPassword} onClick={handleForgotPassword}>
          Forgot Password?
        </Typography>
      </Stack>

      <Stack className={styles.row}>
        <Button
          variant='contained'
          onClick={handleLogin}
          disabled={!form.formState.isValid}
          className={styles.formButton}
          type='submit'
        >
          Login
        </Button>
      </Stack>
    </div>
  );
}
