import { createContext } from 'react';
import { ApiUserTooltipStagePassedEnum } from 'types';

export interface IFirstLoginTooltipsContext {
  tooltipStagePassed: ApiUserTooltipStagePassedEnum;
  tooltipsState: number;
  accept(): Promise<void>;
}

export const FirstLoginTooltipsContext = createContext<IFirstLoginTooltipsContext | null>(null);
export const FirstLoginTooltipsConsumer = FirstLoginTooltipsContext.Consumer;
