import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function SpeakerViewIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='15' height='15' viewBox='0 0 15 15' fill='none' {...props}>
      <rect width='4.41174' height='4.41174' rx='2' />
      <rect x='5.29395' width='4.41174' height='4.41174' rx='2' />
      <rect x='10.5883' width='4.41174' height='4.41174' rx='2' />
      <rect x='0.00012207' y='5.29443' width='14.9999' height='9.70582' rx='2' />
    </SvgIcon>
  );
}
