import { Button, DialogContent, Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';

import styles from './WelcomeDialog.module.scss';

export interface WelcomeDialogProps extends DialogProps {
  onConfirm: () => void;
}

export function WelcomeDialog({ onConfirm, ...rest }: WelcomeDialogProps) {
  return (
    <Dialog {...rest} className={styles.dialog}>
      <DialogContent>
        <Typography component='h4' variant='h4'>
          Welcome!
        </Typography>

        <Typography component='p' variant='body2'>
          You are all set!
        </Typography>

        <Typography component='p' variant='body2'>
          Follow the help tips to get started with HIPAA LINK!
        </Typography>

        <Typography component='p' variant='body2'>
          Any questions? Email us at <a>support@hipaalink.net</a> <br />
          or check out our FAQs.
        </Typography>

        <Button variant='contained' onClick={onConfirm} className={styles.formButton}>
          Get started
        </Button>
      </DialogContent>
    </Dialog>
  );
}
