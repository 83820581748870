/* eslint-disable prettier/prettier */

import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Theme, Typography, useMediaQuery } from '@mui/material';

import { getIsAdminByRoles, getIsProviderRoles } from 'utils';
import { ApiUser, SubscriptionStatusEnum } from 'types';
import { useSubscription } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store';
import { getUser, selectUser } from 'store/user';
import { FormHeader } from '../FormHeader';
import { BackButton } from 'components';
import { FreeTrial } from './components/FreeTrial';
import { Live } from './components/Live';
import { useCancellationConfirmationPopup } from './components/CancellationConfirmation';

import styles from './SubscriptionSettings.module.scss';

type ProfileSettingsProps = {
  onBackToSettingsClick(): void;
};

export function SubscriptionSettings({ onBackToSettingsClick }: ProfileSettingsProps) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const subscription = useSubscription();
  const cancellationConfirmationPopup = useCancellationConfirmationPopup();
  const user: ApiUser = useAppSelector(selectUser)!;

  const isAdmin = useMemo<boolean>(() => {
    return getIsAdminByRoles(user?.clinicUser.roles);
  }, [user]);

  const isProvider = useMemo<boolean>(() => {
    return getIsProviderRoles(user?.clinicUser.roles);
  }, [user]);

  const isTrial = useMemo<boolean>(() => {
    return subscription?.subscriptionStatus === SubscriptionStatusEnum.Trial;
  }, [user]);

  const isExpired = useMemo<boolean>(() => {
    return subscription?.subscriptionStatus === SubscriptionStatusEnum.Expired;
  }, [user]);

  const isLive = useMemo<boolean>(() => {
    return subscription?.subscriptionStatus === SubscriptionStatusEnum.Live;
  }, [user]);

  const handleUnsubscribe = () => {
    cancellationConfirmationPopup.cancelCurrentSubscription().then(() => {
      dispatch(getUser()).unwrap();
    });
  };

  return (
    <>
      {isMobile && <BackButton text='Back to Settings' onClick={onBackToSettingsClick} />}
      <div className={styles.container}>
        <FormHeader items={[{ text: 'SUBSCRIPTIONS' }]} />
        
        <div className={styles.main}>
          {isProvider && (
            <Box className={styles.notAvailable}>
              <Typography variant='body1'>For information about subscriptions, contact your administrator.</Typography>
            </Box>
          )}

          {!isProvider && (isAdmin && (isTrial || isExpired) ? <FreeTrial /> : <Live />)}
        </div>

        {isAdmin && isLive && (
          <Button LinkComponent={Link} className={styles.unsubscribe} onClick={handleUnsubscribe}>
            Unsubscribe
          </Button>
        )}
      </div>
    </>
  );
}
