import { Button, DialogContent, Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { CheckCircleIcon } from 'assets';

import styles from './PaymentCompleteDialog.module.scss';

export interface PaymentCompleteDialogProps extends DialogProps {
  onConfirm: () => void;
}

export function PaymentCompleteDialog({ onConfirm, ...rest }: PaymentCompleteDialogProps) {
  return (
    <Dialog {...rest} className={styles.dialog}>
      <DialogContent>
        <CheckCircleIcon className={styles.icon} color='primary' />

        <Typography component='p' variant='body4'>
          Payment Complete!
        </Typography>

        <Button variant='contained' onClick={onConfirm} className={styles.formButton}>
          Done
        </Button>
      </DialogContent>
    </Dialog>
  );
}
