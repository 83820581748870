/* eslint-disable prettier/prettier */
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogContent, DialogProps, Divider, Stack, Switch, Typography } from '@mui/material';
import { AINotesGenerateNoteEnum, AINotesTypeOfVisitEnum, AINotesWrittenAsEnum } from 'types';
import { FormSelector, Info } from 'components';
import { AINotesTranscriptionSettingsSelectorWrapper } from './AINotesTranscriptionSettingsSelectorWrapper';
import { aiNotesTypeOfVisitOptions } from './aiNotesTypeOfVisitOptions';
import { aiNotesGenerateNoteOptions } from './aiNotesGenerateNoteOptions';
import { aiNotesWrittenAsOptions } from './aiNotesWrittenAsOptions';

type AINotesTranscriptionSettingsForm = {
  aiNotesTypeOfVisit: AINotesTypeOfVisitEnum;
  aiNotesWrittenAs: AINotesWrittenAsEnum;
  aiNotesGenerateNote: AINotesGenerateNoteEnum;
  aiNotesIncludeReductedTranscript: boolean;
};

export interface AINotesTranscriptionSettingsDialogProps extends DialogProps {
  aiNotesTypeOfVisit: AINotesTypeOfVisitEnum;
  aiNotesWrittenAs: AINotesWrittenAsEnum;
  aiNotesGenerateNote: AINotesGenerateNoteEnum;
  aiNotesIncludeReductedTranscript: boolean;
  aiCreditsCount: number;
  disableStartAINotesTranscription?: boolean;
  onAINotesTranscriptionConfirmResult?(result: {
    from?: AINotesTranscriptionSettingsForm;
    dialogResult: 'Confirmed' | 'Canceled';
  }): void;
}

export const AINotesTranscriptionSettingsDialog: FC<AINotesTranscriptionSettingsDialogProps> = ({
  disableStartAINotesTranscription,
  aiNotesTypeOfVisit,
  aiNotesWrittenAs,
  aiNotesGenerateNote,
  aiNotesIncludeReductedTranscript,
  aiCreditsCount,
  onAINotesTranscriptionConfirmResult,
  ...props
}) => {
  const form = useForm<AINotesTranscriptionSettingsForm>({
    resolver: yupResolver(
      Yup.object().shape({
        aiNotesTypeOfVisit: Yup.string(),
        aiNotesWrittenAs: Yup.string(),
        aiNotesGenerateNote: Yup.string(),
        aiNotesIncludeReductedTranscript: Yup.boolean(),
      }),
    ),
    mode: 'all',
    defaultValues: {
      aiNotesTypeOfVisit,
      aiNotesWrittenAs,
      aiNotesGenerateNote,
      aiNotesIncludeReductedTranscript,
    },
  });

  const handleSubmit = async (): Promise<void> => {
    onAINotesTranscriptionConfirmResult?.({
      dialogResult: 'Confirmed',
      from: {
        aiNotesTypeOfVisit: form.getValues('aiNotesTypeOfVisit'),
        aiNotesWrittenAs: form.getValues('aiNotesWrittenAs'),
        aiNotesGenerateNote: form.getValues('aiNotesGenerateNote'),
        aiNotesIncludeReductedTranscript: form.getValues('aiNotesIncludeReductedTranscript'),
      },
    });
  };

  const handleAINotesTranscriptionCancel = (): void => {
    onAINotesTranscriptionConfirmResult?.({ dialogResult: 'Canceled' });
  };

  return (
    <Dialog
      {...props}
      sx={{
        '.MuiPaper-root': {
          width: 347,
        },
      }}
    >
      <DialogContent
        sx={() => ({
          padding: '30px',
        })}
      >
        <Stack
          component='form'
          onSubmit={handleSubmit}
          direction='column'
          alignContent='center'
          justifyContent='center'
          gap='15px'
        >
          <Typography
            sx={{
              fontSize: 15,
              fontWeight: 700,
              lineHeight: '20.43px',
              textAlign: 'center',
            }}
          >
            AI Progress Notes
          </Typography>
          <Stack direction='column' alignContent='center' justifyContent='center' gap='20px'>
            <AINotesTranscriptionSettingsSelectorWrapper
              labelSlot='Type of Visit'
              selectorSlot={
                <FormSelector
                  variant='outlined'
                  name='aiNotesTypeOfVisit'
                  disabled={disableStartAINotesTranscription}
                  defaultValue={aiNotesTypeOfVisit}
                  control={form.control}
                  options={aiNotesTypeOfVisitOptions}
                  withoutNone
                />
              }
            />
            <AINotesTranscriptionSettingsSelectorWrapper
              labelSlot='Written as...'
              selectorSlot={
                <FormSelector
                  variant='outlined'
                  name='aiNotesWrittenAs'
                  disabled={disableStartAINotesTranscription}
                  defaultValue={aiNotesWrittenAs}
                  control={form.control}
                  options={aiNotesWrittenAsOptions}
                  withoutNone
                />
              }
            />
            <AINotesTranscriptionSettingsSelectorWrapper
              labelSlot='Generate Progress Note'
              selectorSlot={
                <FormSelector
                  variant='outlined'
                  name='aiNotesGenerateNote'
                  disabled={disableStartAINotesTranscription}
                  defaultValue={aiNotesGenerateNote}
                  control={form.control}
                  options={aiNotesGenerateNoteOptions}
                  withoutNone
                />
              }
            />
          </Stack>
          <Divider />
          <Stack
            direction='row'
            spacing={1}
            alignItems='center'
            justifyContent='space-between'
            sx={{
              height: 29,
            }}
          >
            <Stack direction='row' spacing={1} alignItems='center' justifyContent='center'>
              <Typography component='p' variant='body5' alignItems='center'>
                Include redacted transcript
              </Typography>
              <Info text='A fully redacted transcript of your call will be included in your report' />
            </Stack>
            <Switch
              disabled={disableStartAINotesTranscription}
              {...form.register('aiNotesIncludeReductedTranscript')}
              defaultChecked={aiNotesIncludeReductedTranscript}
              inputProps={{ 'aria-label': 'IncludeRedactedTranscript' }}
            />
          </Stack>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: '19.07px',
              textAlign: 'center',
            }}
          >
            {`Credits Remaining: ${aiCreditsCount}`}
          </Typography>
          <Stack
            direction='row'
            alignContent='center'
            justifyContent='space-between'
            sx={{
              width: '100%',
              gap: '10px',
            }}
          >
            <Button variant='outlined' sx={{ width: '100%' }} onClick={handleAINotesTranscriptionCancel}>
              Cancel
            </Button>
            <Button
              type='submit'
              color='primary'
              variant='contained'
              disabled={disableStartAINotesTranscription}
              onClick={handleSubmit}
              sx={{
                width: '100%',
              }}
            >
              Start Now
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
