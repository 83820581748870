import React, { useEffect, useMemo, useState } from 'react';
import { Box, Divider, Stack, styled, Typography } from '@mui/material';
import moment from 'moment';
import { HubPatient } from 'types';
import {
  AndroidIcon,
  AppleIcon,
  ChromeIcon,
  FirefoxIcon,
  LaptopIcon,
  MicIcon,
  MicOffIcon,
  MobilePhoneIcon,
  SafariIcon,
  TabletIcon,
  VideoCameraIcon,
  VideoCameraOffIcon,
  WindowsIcon,
} from 'assets';

import styles from './PatientStatus.module.scss';

export interface PatientStatusProps {
  patient: HubPatient;
}

const VideoCameraIconStyled = styled(VideoCameraIcon)(({ theme }) => ({
  color: '#3EB157',
}));

const VideoCameraOffIconStyled = styled(VideoCameraOffIcon)(({ theme }) => ({
  color: '#FF3C3C',
}));

const MicIconStyled = styled(MicIcon)(({ theme }) => ({
  color: '#3EB157',
}));

const MicOffIconStyled = styled(MicOffIcon)(({ theme }) => ({
  color: '#FF3C3C',
}));

const LaptopIconStyled = styled(LaptopIcon)(({ theme }) => ({
  color: '#626363',
}));

const TabletIconStyled = styled(TabletIcon)(({ theme }) => ({
  color: '#626363',
}));

const MobilePhoneIconStyled = styled(MobilePhoneIcon)(({ theme }) => ({
  color: '#626363',
}));

const WindowsIconStyled = styled(WindowsIcon)(({ theme }) => ({
  color: '#626363',
}));

export function PatientStatus({ patient }: PatientStatusProps) {
  const [waitingTime, setWaitingTime] = useState(0);

  useEffect(() => {
    const now = moment();
    const waitingMinutes = moment(patient.joinedOn, 'YYYY-MM-DDTHH:mm:ss.SSSSZ');
    const duration = moment.duration(now.diff(waitingMinutes, 'seconds'), 'seconds');

    const timer = setInterval(() => {
      duration.add(1, 'second');
      setWaitingTime(duration.minutes());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const deviceIcon = useMemo(() => {
    const deviceType = patient.device?.type?.toLowerCase();
    let icon = null;
    if (deviceType) {
      switch (true) {
        case deviceType.indexOf('tablet') !== -1: {
          icon = <TabletIconStyled />;
          break;
        }
        case deviceType.indexOf('mobile') !== -1: {
          icon = <MobilePhoneIconStyled />;
          break;
        }
        default: {
          icon = <LaptopIconStyled />;
          break;
        }
      }
    }
    return icon;
  }, [patient.device]);

  const operationSystemIcon = useMemo(() => {
    const operationSystem = patient.device?.operationSystem?.toLowerCase();
    let icon = null;
    if (operationSystem) {
      switch (true) {
        case operationSystem.indexOf('android') !== -1: {
          icon = <AndroidIcon />;
          break;
        }
        case operationSystem.indexOf('windows') !== -1: {
          icon = <WindowsIconStyled />;
          break;
        }
        default: {
          icon = <AppleIcon />;
          break;
        }
      }
    }
    return icon;
  }, [patient.device]);

  const browserIcon = useMemo(() => {
    const operationSystem = patient.device?.browser?.toLowerCase();
    let icon = null;
    if (operationSystem) {
      switch (true) {
        case operationSystem.indexOf('mozilla') !== -1:
        case operationSystem.indexOf('firefox') !== -1: {
          icon = <FirefoxIcon />;
          break;
        }
        case operationSystem.indexOf('safari') !== -1: {
          icon = <SafariIcon />;
          break;
        }
        default: {
          icon = <ChromeIcon />;
          break;
        }
      }
    }
    return icon;
  }, [patient.device]);

  return (
    <Box className={styles.container}>
      <Stack direction='column' spacing={'5px'} alignItems='flex-start'>
        <Typography component='p' variant='body2'>
          {patient.userName}
        </Typography>

        <Stack direction='row' spacing={'10px'} alignItems='center'>
          <Typography component='p' variant='body4' sx={{ color: '#3EB157' }}>
            {`Waiting ${waitingTime} min`}
          </Typography>
          {patient.callAccesses?.hasMicroAccess ? (
            <MicIconStyled className={styles.micIcon} />
          ) : (
            <MicOffIconStyled className={styles.micIcon} />
          )}
          {patient.callAccesses?.hasCameraAccess ? (
            <VideoCameraIconStyled className={styles.videoIcon} />
          ) : (
            <VideoCameraOffIconStyled className={styles.videoIcon} />
          )}
        </Stack>
      </Stack>
      <Divider />
      {patient.device && (
        <>
          <Stack direction='row' spacing={'10px'} alignItems='center'>
            {deviceIcon}
            <Typography component='p' variant='body2'>
              {patient.device.type}
            </Typography>
          </Stack>
          <Stack direction='row' spacing={'10px'} alignItems='center'>
            {operationSystemIcon}
            <Typography component='p' variant='body2'>
              {patient.device.operationSystem}
            </Typography>
          </Stack>
          <Stack direction='row' spacing={'10px'} alignItems='center'>
            {browserIcon}
            <Typography component='p' variant='body2'>
              {patient.device.browser}
            </Typography>
          </Stack>
        </>
      )}
    </Box>
  );
}
