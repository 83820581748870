import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function MessageCircleDisabledIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='42' height='42' viewBox='0 0 42 42' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx='21' cy='21' r='21' />
      <path d='M16.2139 16.2139H26.7476' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16.2139 20.4268H20.4274' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M12 14.1067V30.7426C12 31.2117 12.5673 31.4468 12.8991 31.1149L16.9583 27.0558C17.1559 26.8583 17.4238 26.7472 17.7032 26.7472H28.854C30.0175 26.7472 30.9607 25.804 30.9607 24.6405V14.1067C30.9607 12.9432 30.0175 12 28.854 12H14.1067C12.9432 12 12 12.9432 12 14.1067Z'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='transparent'
      />
    </SvgIcon>
  );
}
