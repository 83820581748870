/* eslint-disable prettier/prettier */

import { TagManagerArgs } from 'react-gtm-module';
import { TrackingEventsEnum } from 'types';

export const googleTagManagerInitializeArgs: TagManagerArgs = {
    gtmId: 'GTM-TB5H4F4'
};

export const googleEventIdMap: { [event in TrackingEventsEnum]: string } = {
    [TrackingEventsEnum.StartSignup]: 'AW-11158540419/IuhVCJDWy64YEIOh58gp',
    [TrackingEventsEnum.CompleteRegistration]: 'AW-11158540419/PunLCOjqrJoYEIOh58gp',
    [TrackingEventsEnum.InitiateCheckout]: 'AW-11158540419/qO-9COXqrJoYEIOh58gp',
    [TrackingEventsEnum.CompleteCheckout]: 'AW-11158540419/OMULCJjDy64YEIOh58gp',
};

export const facebookPixelId = '1256655811648483';