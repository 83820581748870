import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Hidden, Skeleton } from '@mui/material';

import { VideoPreviewWithControls } from 'components';
import { PatientWaitingRoom, ChatButton } from './components';
import { useChatNewMessageSound, useOpenChatOnNewMessage, useSignalrConnection } from 'hooks';
import { ISignalrConnectionContext } from 'context';
import { useAppDispatch, useAppSelector } from 'store';
import { clearPatientQueueStore } from 'store/patientQueue';
import { selectPatientIsDismissed } from 'store/patient';
import { getWaitingRoomProfile, selectWaitingRoomLoading, selectWaitingRoomProfile } from 'store/waitingRoom';

import styles from './LinkPage.module.scss';

export function LinkPage() {
  // will be moved to Chat component
  useChatNewMessageSound();
  useOpenChatOnNewMessage();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { link } = useParams();
  const waitingRoomProfile = useAppSelector(selectWaitingRoomProfile);
  const waitingRoomLoading = useAppSelector(selectWaitingRoomLoading);
  const isDismissed = useAppSelector(selectPatientIsDismissed);
  const { disconnect }: ISignalrConnectionContext = useSignalrConnection();

  const leaveWaitingQueue = useCallback(() => {
    dispatch(clearPatientQueueStore());
    disconnect();
  }, [dispatch]);

  useEffect(() => {
    if (link) {
      dispatch(getWaitingRoomProfile(link));
    }
  }, [link]);

  useEffect(() => {
    return () => {
      leaveWaitingQueue();
    };
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', leaveWaitingQueue);
    return () => {
      window.removeEventListener('beforeunload', leaveWaitingQueue);
    };
  }, [leaveWaitingQueue]);

  useEffect(() => {
    if (!waitingRoomLoading && !waitingRoomProfile) {
      navigate('/notFound');
    }
  }, [waitingRoomLoading, waitingRoomProfile]);

  return (
    <div className={styles.container}>
      {waitingRoomLoading || !waitingRoomProfile ? (
        <Skeleton sx={{ transform: 'none', height: '100%' }} />
      ) : (
        <PatientWaitingRoom profile={waitingRoomProfile} />
      )}

      {waitingRoomProfile && !isDismissed && (
        <>
          <Hidden mdDown>
            <Box className={styles.sidebar} maxWidth={240} height={'100%'}>
              {!isDismissed && <VideoPreviewWithControls />}
              {waitingRoomProfile?.providerId && <ChatButton provider={waitingRoomProfile!} />}
            </Box>
          </Hidden>

          <Hidden mdUp>
            {waitingRoomProfile?.providerId && (
              <ChatButton className={styles.chatButton} provider={waitingRoomProfile!} />
            )}
          </Hidden>
        </>
      )}
    </div>
  );
}
