import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ApiWaitingRoomLinkProfile, IBasicState } from 'types';
import { IApiRequests, useApiRequests } from 'hooks';

import { AppState } from '../index';

const ApiRequests: IApiRequests = useApiRequests();

export interface IWaitingRoomState extends IBasicState {
  data: ApiWaitingRoomLinkProfile | null;
  message: string | null;
  error: string | null;
}

const initialState: IWaitingRoomState = {
  data: null,
  loading: true,
  message: null,
  error: null,
};

export const getWaitingRoomProfile = createAsyncThunk<ApiWaitingRoomLinkProfile | null, string>(
  'waitingRoom/getByLink',
  async (data: string): Promise<ApiWaitingRoomLinkProfile | null> => ApiRequests.getProfileByLink(data),
);

export const waitingRoomSlice = createSlice({
  name: 'waitingRoom',
  initialState,
  reducers: {
    updateUnavailableProviderStatusInWaitingRoom: (state, action) => {
      if (state.data) {
        state.data.unavailable = action.payload;
      }
    },
    updateIsProviderInQueueStatusInWaitingRoom: (state, action) => {
      if (state.data) {
        state.data.isProviderInQueue = action.payload;
      }
    },
    clearWaitingRoomStore: (state) => {
      state.data = null;
    },
    clearWaitingRoomMessages: (state) => {
      state.message = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWaitingRoomProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWaitingRoomProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getWaitingRoomProfile.rejected, (state) => {
        state.loading = false;
      });
  },
});

// Actions
export const {
  updateUnavailableProviderStatusInWaitingRoom,
  updateIsProviderInQueueStatusInWaitingRoom,
  clearWaitingRoomStore,
  clearWaitingRoomMessages,
} = waitingRoomSlice.actions;

// Selectors
export const selectWaitingRoomProfile = (state: AppState) => state.waitingRoom.data;
export const selectWaitingRoomLoading = (state: AppState) => state.waitingRoom.loading;

export default waitingRoomSlice.reducer;
