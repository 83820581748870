/* eslint-disable prettier/prettier */
import { AINotesGenerateNoteEnum } from 'types';

export const aiNotesGenerateNoteOptions: Array<{ id: string; value: string }> = [
  {
    id: AINotesGenerateNoteEnum.BIRP.toString(),
    value: 'BIRP',
  },
  {
    id: AINotesGenerateNoteEnum.CARE.toString(),
    value: 'CARE',
  },
  {
    id: AINotesGenerateNoteEnum.DAP.toString(),
    value: 'DAP',
  },
  {
    id: AINotesGenerateNoteEnum.FORT.toString(),
    value: 'FORT',
  },
  {
    id: AINotesGenerateNoteEnum.GIRP.toString(),
    value: 'GIRP',
  },
  {
    id: AINotesGenerateNoteEnum.MINT.toString(),
    value: 'MINT',
  },
  {
    id: AINotesGenerateNoteEnum.PIRP.toString(),
    value: 'PIRP',
  },
  {
    id: AINotesGenerateNoteEnum.SIRP.toString(),
    value: 'SIRP',
  },
  {
    id: AINotesGenerateNoteEnum.PIE.toString(),
    value: 'PIE',
  },
  {
    id: AINotesGenerateNoteEnum.RIFT.toString(),
    value: 'RIFT',
  },
  {
    id: AINotesGenerateNoteEnum.SOAP.toString(),
    value: 'SOAP',
  },
  {
    id: AINotesGenerateNoteEnum.STOP.toString(),
    value: 'STOP',
  },
  {
    id: AINotesGenerateNoteEnum.Intake.toString(),
    value: 'Intake',
  },
  {
    id: AINotesGenerateNoteEnum.Group.toString(),
    value: 'Group',
  },
  {
    id: AINotesGenerateNoteEnum.Couples.toString(),
    value: 'Couples',
  },
  {
    id: AINotesGenerateNoteEnum.Psychotherapy.toString(),
    value: 'Psychotherapy',
  },
];
