import { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/system';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { IUserContext } from 'context';
import { Spinner } from 'components';
import { RegisterLayout } from './components/RegisterLayout';
import { ProfileLayout, RegisterProfileInfoStep, RegisterCreateRoomStep } from './components';
import { IApiRequests, useApiRequests, useUser } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store';
import { clearInvite, InviteData, selectInvite } from 'store/invite';
import { getUser, selectUser } from 'store/user';
import { ApiUser, RegisterByInvitationRequest, UpdateClinicUserRequest, UpdateUserRequest } from 'types';

import styles from './RegisterByInvitationPage.module.scss';

export function RegisterByInvitationPage() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();
  const invite: InviteData | null = useAppSelector(selectInvite);
  const user: ApiUser | null = useAppSelector(selectUser);
  const { registerByInvitation }: IUserContext = useUser();
  const { updateProfilePicture, updateClinicUser, updateUser, skipCreateRoom }: IApiRequests = useApiRequests();

  const [loading, setLoading] = useState<boolean>(true);

  const userInSystem: boolean = useMemo(() => {
    return invite?.userId === user?.id;
  }, [user, invite]);

  const stage: number = useMemo(() => {
    return userInSystem || user ? 2 : 1;
  }, [userInSystem, user]);

  const headerTitles: string[] = useMemo(() => {
    return userInSystem ? ['Profile Info', 'Create Room'] : ['Profile Info', 'Create Room'];
  }, [userInSystem]);

  useEffect(() => {
    if (invite) {
      setLoading(false);
    } else {
      navigate('/');
    }
  }, [invite]);

  const handleRegister = (model: RegisterByInvitationRequest): Promise<void> => {
    setLoading(true);

    return registerByInvitation(model)
      .catch((e) => {
        enqueueSnackbar(`Registration error: ${e.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCreateRoom = (
    profilePicture: File | null,
    user: UpdateUserRequest,
    clinicUser: UpdateClinicUserRequest,
  ): Promise<void> => {
    setLoading(true);

    const updateImagePromise = (): Promise<void> => {
      return updateProfilePicture(profilePicture).catch((error) => {
        return Promise.reject(`Unable to update profile picture: ${error.message}`);
      });
    };

    const updateClinicUserPromise = (): Promise<any> => {
      return updateClinicUser(clinicUser).catch((error: any) => {
        return Promise.reject(`Update clinic user failed: ${error.message}`);
      });
    };

    const updateUserPromise = (): Promise<any> => {
      return updateUser(user).catch((error: any) => {
        return Promise.reject(`Update user failed: ${error.message}`);
      });
    };

    const skipCreateRoomPromise = (): Promise<any> => {
      return skipCreateRoom().catch((error: any) => {
        return Promise.reject(`Update clinic user failed: ${error.message}`);
      });
    };

    return updateImagePromise()
      .then(() => updateClinicUserPromise())
      .then(() => skipCreateRoomPromise())
      .then(() => updateUserPromise())
      .then(() => dispatch(getUser()).unwrap())
      .then(() => {
        dispatch(clearInvite());
        navigate('../');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Spinner />}
      {!loading && (
        <Box className={styles.profileContainer}>
          <Box className={styles.profileInnerContainer}>
            <Box>
              <div className={styles.registerContainer}>
                <div className={styles.header}>
                  {headerTitles.map((title: string, index: number) => (
                    <div
                      key={index}
                      className={classnames(styles.titleContainer, {
                        [styles.titleContainerSelected]: stage > index,
                        [styles.titleContainerNextSelected]: stage > index + 1 || stage === 3,
                      })}
                    >
                      <div className={styles.titleContainerRound}>
                        {!isMobile && <div className={styles.number}>{index + 1}</div>}
                        <Typography variant={!isMobile ? 'body4' : 'body1'}>{title}</Typography>
                      </div>
                    </div>
                  ))}
                </div>

                <div className={styles.body}>
                  {stage === 1 && (
                    <Box className={styles.registerContainer}>
                      <Box className={styles.registerLayout}>
                        <RegisterLayout />
                      </Box>
                      <Box className={styles.registrationFormInner}>
                        <RegisterProfileInfoStep onRegister={handleRegister} />
                      </Box>
                    </Box>
                  )}
                  {stage === 2 && (
                    <Box className={styles.RoomContainer}>
                      <Box className={styles.profileLayout}>
                        <ProfileLayout />
                      </Box>
                      <Box className={styles.createRoomInner}>
                        <RegisterCreateRoomStep onSubmit={handleCreateRoom} />
                      </Box>
                    </Box>
                  )}
                </div>
              </div>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
