import { RefObject, useCallback, useEffect, useRef } from 'react';
import classnames from 'classnames';

import styles from './Selector.module.scss';

export type SelectOption = {
  id: string;
  value: string;
};

export type SelectorProps = {
  options: SelectOption[];
  value: string;
  className?: string;
  onChange(value: string): void;
};

export function Selector({ options, className, value, onChange }: SelectorProps) {
  const ref: RefObject<HTMLSelectElement> = useRef<HTMLSelectElement>(null);

  const _onChange = useCallback(
    (event: any) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  useEffect(() => {
    ref.current?.addEventListener('change', _onChange);

    return () => {
      ref.current?.removeEventListener('change', _onChange);
    };
  }, [_onChange]);

  return (
    <select ref={ref} className={classnames(styles.select, className)} value={value}>
      {options?.map((option) => (
        <option key={option.id} value={option.id}>
          {option.value}
        </option>
      ))}
    </select>
  );
}
