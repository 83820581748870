import { useState } from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Box } from '@mui/system';
import { Typography, Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useSelector } from 'react-redux';

import { selectUser } from 'store/user';
import { ModalTitle } from 'components';
import { withOutsideClickPreventer } from 'utils';

import styles from './TwoFactorPopupOff.module.scss';

type TwoFactorPopupOffProps = {
  open: boolean;
  onTurnOffTwoFactor: (code: string) => Promise<void>;
  onResendCode: () => void;
  onClose: () => void;
};

export const TwoFactorPopupOff = ({ open, onTurnOffTwoFactor, onResendCode, onClose }: TwoFactorPopupOffProps) => {
  const user: any = useSelector(selectUser);

  const [code, setCode] = useState<string>('');

  const handleInput = (value: string): void => {
    setCode(value);
  };

  const handleTurnOffTwoFactor = () => {
    onTurnOffTwoFactor(code);
  };

  return (
    <Dialog open={open} onClose={withOutsideClickPreventer(onClose)} className={styles.dialog}>
      <DialogTitle>
        <ModalTitle title='TURN OFF 2FA' onClose={onClose} />
      </DialogTitle>

      <DialogContent className={styles.dialogContent}>
        <Typography variant='body2'>
          A verification code has been sent to the phone number ending in {user?.PhoneNumber?.slice(-4)}.
        </Typography>

        <MuiOtpInput
          className={styles.input}
          value={code}
          onChange={handleInput}
          length={6}
          autoFocus
          TextFieldsProps={{ InputProps: { componentsProps: { input: { pattern: '\\d*' } } } }}
        />

        <Typography variant='body1'>
          {'Did not receive the code?\n Click '}
          <u onClick={onResendCode} className={styles.action}>
            here
          </u>
          {' to resend a code'}
        </Typography>

        <Box className={styles.buttonsContaine}>
          <Button variant='outlined' className={styles.button} onClick={onClose}>
            Cancel
          </Button>

          <Button variant='contained' className={styles.button} onClick={handleTurnOffTwoFactor}>
            Turn off 2FA
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
