import { useState, useCallback, useRef } from 'react';
import { LogLevels, Track, Room } from 'twilio-video';
import { ErrorCallback } from 'types';

interface MediaStreamTrackPublishOptions {
  name?: string;
  priority: Track.Priority;
  logLevel: LogLevels;
}

export function useScreenShareToggle(room: Room | null, onError: ErrorCallback) {
  const [isSharing, setIsSharing] = useState(false);
  const stopScreenShareVideoRef = useRef<() => void>(null!);
  const stopScreenShareAudioRef = useRef<() => void>(null!);

  const shareScreen = useCallback(() => {
    navigator.mediaDevices
      .getDisplayMedia({
        audio: {
          echoCancellation: true,
          channelCount: 1,
          noiseSuppression: true,
          autoGainControl: true,
        },
        video: true,
      })
      .then((stream) => {
        const tracks = stream.getTracks();
        const trackVideo = tracks.find((track) => track.kind === 'video')!;
        const trackAudio = tracks.find((track) => track.kind === 'audio');

        // All video tracks are published with 'low' priority. This works because the video
        // track that is displayed in the 'MainParticipant' component will have it's priority
        // set to 'high' via track.setPriority()
        room!.localParticipant
          .publishTrack(trackVideo, {
            name: 'screen', // Tracks can be named to easily find them later
            priority: 'low', // Priority is set to high by the subscriber when the video track is rendered
          } as MediaStreamTrackPublishOptions)
          .then((trackPublication) => {
            stopScreenShareVideoRef.current = () => {
              room!.localParticipant.unpublishTrack(trackVideo);
              // TODO: remove this if the SDK is updated to emit this event
              room!.localParticipant.emit('trackUnpublished', trackPublication);
              trackVideo.stop();

              setIsSharing(false);
            };

            trackVideo.onended = stopScreenShareVideoRef.current;
            setIsSharing(true);
          })
          .catch(onError);

        if (trackAudio) {
          room!.localParticipant
            .publishTrack(trackAudio, {
              name: 'screen_audio', // Tracks can be named to easily find them later
              priority: 'low', // Priority is set to high by the subscriber when the video track is rendered
            } as MediaStreamTrackPublishOptions)
            .then((trackPublication) => {
              stopScreenShareAudioRef.current = () => {
                room!.localParticipant.unpublishTrack(trackAudio);
                room!.localParticipant.emit('trackUnpublished', trackPublication);
                trackAudio.stop();
              };

              trackAudio.onended = stopScreenShareAudioRef.current;
            })
            .catch(onError);
        }
      })
      .catch((error) => {
        // Don't display an error if the user closes the screen share dialog
        if (
          error.message === 'Permission denied by system' ||
          (error.name !== 'AbortError' && error.name !== 'NotAllowedError')
        ) {
          onError(error);
        }
      });
  }, [room, onError]);

  const toggleScreenShare = useCallback(() => {
    if (room) {
      if (isSharing) {
        stopScreenShareVideoRef.current();
        if (stopScreenShareAudioRef.current) {
          stopScreenShareAudioRef.current();
        }
      } else {
        shareScreen();
      }
    }
  }, [isSharing, shareScreen, room]);

  return [isSharing, toggleScreenShare] as const;
}
