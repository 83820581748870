import { useMemo, useState } from 'react';
import { Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

import { FormHeader, HeaderItem } from '../FormHeader';
import { BackButton } from 'components';
import { BAAPannel, LoginPannel, SecurityPannel } from './components';

import styles from './AccountSettings.module.scss';

type ProfileSettingsProps = {
  onBackToSettingsClick(): void;
};

type Tab = 'Login' | 'Security' | 'BAA';

export function AccountSettings({ onBackToSettingsClick }: ProfileSettingsProps) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [tab, setTab] = useState<Tab | null>(isMobile ? null : 'Login');

  const headerItems: HeaderItem[] = useMemo(
    () => [
      {
        text: 'LOGIN',
        isSelected: tab === 'Login',
        onClick: () => setTab('Login'),
      },
      {
        text: 'SECURITY',
        isSelected: tab === 'Security',
        onClick: () => setTab('Security'),
      },
      {
        text: 'BAA',
        isSelected: tab === 'BAA',
        onClick: () => setTab('BAA'),
      },
    ],
    [tab],
  );

  const getMobileHeaderName = (): string => {
    switch (tab) {
      case 'Login':
        return 'LOGIN';
      case 'Security':
        return 'SECURITY';
      case 'BAA':
        return 'BAA';
      default:
        return 'ACCOUNT SETTINGS';
    }
  };

  const handleMyProfileClick = (): void => {
    setTab('Login');
  };

  const handleRoomSettingsClick = (): void => {
    setTab('Security');
  };

  const handleBAAClick = (): void => {
    setTab('BAA');
  };

  const handleBackToProfile = (): void => {
    setTab(null);
  };

  return (
    <>
      {!tab && isMobile && <BackButton text='Back to Settings' onClick={onBackToSettingsClick} />}
      {!!tab && isMobile && <BackButton text='Back to Account Settings' onClick={handleBackToProfile} />}

      <div className={styles.container}>
        {isMobile && (
          <>
            <FormHeader items={[{ text: getMobileHeaderName() }]} />

            {!tab && (
              <>
                <Stack className={styles.navigationItem} onClick={handleMyProfileClick}>
                  <Typography variant='body2'>Login</Typography>
                  <ChevronRight />
                </Stack>
                <Stack className={styles.navigationItem} onClick={handleRoomSettingsClick}>
                  <Typography variant='body2'>Security</Typography>
                  <ChevronRight />
                </Stack>
                <Stack className={styles.navigationItem} onClick={handleBAAClick}>
                  <Typography variant='body2'>BAA</Typography>
                  <ChevronRight />
                </Stack>
              </>
            )}

            {tab && (
              <div className={styles.main}>
                {tab === 'Login' && <LoginPannel />}
                {tab === 'Security' && <SecurityPannel />}
                {tab === 'BAA' && <BAAPannel />}
              </div>
            )}
          </>
        )}

        {!isMobile && (
          <>
            <FormHeader items={headerItems} />
            {tab === 'Login' && <LoginPannel />}
            {tab === 'Security' && <SecurityPannel />}
            {tab === 'BAA' && <BAAPannel />}
          </>
        )}
      </div>
    </>
  );
}
