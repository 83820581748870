import { Button, DialogContent, Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';

import styles from './SimpleInfoDialog.module.scss';

export interface SimpleInfoDialogProps extends DialogProps {
  title?: string;
  text?: string;
  buttonText?: string;
  children?: JSX.Element;
  afterOkButtonChildren?: JSX.Element | null;
  onConfirm: () => void;
}

export function SimpleInfoDialog({
  title,
  text,
  buttonText,
  children,
  afterOkButtonChildren,
  onConfirm,
  ...rest
}: SimpleInfoDialogProps) {
  return (
    <Dialog {...rest} className={styles.dialog}>
      <DialogContent>
        {title && (
          <Typography component='h4' variant='h4'>
            {title}
          </Typography>
        )}

        {text && (
          <Typography component='p' variant='body5'>
            {text}
          </Typography>
        )}

        {children}

        <Button variant='contained' onClick={onConfirm} className={styles.formButton}>
          {buttonText ? buttonText : 'Ok'}
        </Button>

        {afterOkButtonChildren}
      </DialogContent>
    </Dialog>
  );
}
