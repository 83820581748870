import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function AttachIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M16.5133 2.96647C15.8646 2.25926 14.6944 2.21588 13.9146 2.9957L6.27672 10.6336C6.0787 10.8316 6.0787 11.0824 6.27672 11.2804C6.47473 11.4784 6.72556 11.4784 6.92358 11.2804L13.2413 4.96268C13.4192 4.79092 13.6573 4.69588 13.9046 4.69802C14.1518 4.70017 14.3883 4.79934 14.5631 4.97417C14.738 5.149 14.8371 5.3855 14.8393 5.63274C14.8414 5.87998 14.7464 6.11817 14.5746 6.29601L8.2569 12.6137C8.04159 12.8353 7.78403 13.0114 7.49946 13.1317C7.21489 13.2519 6.90909 13.3139 6.60015 13.3139C6.2912 13.3139 5.9854 13.2519 5.70083 13.1317C5.41626 13.0114 5.1587 12.8353 4.94339 12.6137C4.72184 12.3984 4.54572 12.1409 4.42545 11.8563C4.30519 11.5717 4.24322 11.2659 4.24322 10.957C4.24322 10.648 4.30519 10.3422 4.42545 10.0577C4.54572 9.7731 4.72184 9.51554 4.94339 9.30023L12.5812 1.66238C14.0588 0.183837 16.4615 0.135747 17.89 1.67652C19.3535 3.156 19.3959 5.54637 17.8617 6.96927L8.91696 15.914C6.85191 17.9791 3.61384 17.9791 1.54879 15.914C-0.516263 13.849 -0.516263 10.6109 1.54879 8.54588L9.18664 0.908019C9.36449 0.736254 9.60268 0.641211 9.84991 0.643359C10.0972 0.645507 10.3337 0.744676 10.5085 0.919506C10.6833 1.09434 10.7825 1.33084 10.7846 1.57807C10.7868 1.82531 10.6917 2.0635 10.52 2.24134L2.88211 9.8792C1.55256 11.2088 1.55256 13.2512 2.88211 14.5807C4.21167 15.9103 6.25409 15.9103 7.58364 14.5807L16.5416 5.62275L16.5708 5.59446C17.2781 4.94665 17.3214 3.77552 16.5416 2.9957L16.5133 2.96647Z' />
    </SvgIcon>
  );
}
