import { createContext } from 'react';

export interface IAppFeatureFlagsContext {
  participantRenameFeature: boolean;
  displayIconInOrganizationSelectionDropdownFeature: boolean;
  displayDescriptionInOrganizationDetailsFormFeature: boolean;
  displayOrganizationLogoInputInOrganizationDetailsFormFeature: boolean;
  shouldShowMicSpeakerWarning: boolean;
}

export const AppFeatureFlagsContext = createContext<IAppFeatureFlagsContext | null>(null);
export const AppFeatureFlagsConsumer = AppFeatureFlagsContext.Consumer;
