import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function HexagonIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M10.4258 1.46084L18.4258 5.95343V14.8903L10.4258 19.3829L2.42578 14.8903V5.95343L10.4258 1.46084Z'
        fill='none'
        strokeWidth='2'
      />
    </SvgIcon>
  );
}
