import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function UnknownFileIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='64' height='73' viewBox='0 0 64 73' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.78798 0.00799561C2.59056 0.00799561 0 2.59856 0 5.79598V67.212C0 70.4095 2.59056 73 5.78798 73H58.003C61.1982 73 63.791 70.4095 63.791 67.212V23.1736L42.2281 0.00799561H5.78798V0.00799561Z'
        fill='#81CFDD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M63.8083 23.2158V24.3565H49.2083C49.2083 24.3565 42.0081 22.9192 42.2169 16.7057C42.2169 16.7057 42.4541 23.2158 49.0657 23.2158H63.8083Z'
        fill='#5C96A0'
      />
      <path
        opacity='0.5'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M42.2461 0V16.6099C42.2461 18.499 43.5054 23.2158 49.209 23.2158H63.809L42.2461 0Z'
        fill='white'
      />
    </SvgIcon>
  );
}
