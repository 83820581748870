import { useCallback } from 'react';
import { Moment } from 'moment';
import { saveAs } from 'file-saver';
import uuid from 'react-uuid';

const GOOGLE_HREF = 'https://calendar.google.com/calendar/u/0/r/eventedit';
const OUTLOOK_HREF = 'https://outlook.office.com/calendar/0/action/compose/';

export type CreateCalendarInviteType = {
  createGoogleEvent(startAt: Moment, endAt: Moment, summary: string, description: string, location: string): void;
  createOutlookEvent(startAt: Moment, endAt: Moment, summary: string, description: string, location: string): void;
  createICalEvent(startAt: Moment, endAt: Moment, summary: string, description: string, location: string): void;
};

export function useCreateCalendarInvite(): CreateCalendarInviteType {
  const createGoogleEvent = useCallback(
    (startAt: Moment, endAt: Moment, summary: string, description: string, location: string): void => {
      const datesAsSearchParams = `${startAt.format('YYYYMMDDTHHmmss')}/${endAt.format('YYYYMMDDTHHmmss')}`;

      const searchParams = new URLSearchParams();
      searchParams.append('dates', datesAsSearchParams);
      searchParams.append('text', summary);
      searchParams.append('details', description);
      searchParams.append('location', location);

      const url = `${GOOGLE_HREF}?${searchParams.toString()}`;

      window.open(url, '_blank');
    },
    [],
  );

  const createOutlookEvent = useCallback(
    (startAt: Moment, endAt: Moment, summary: string, description: string, location: string): void => {
      const startAtAsSearchParams = startAt.format('YYYY-MM-DDTHH:mm:ssZ');
      const endAtAsSearchParams = endAt.format('YYYY-MM-DDTHH:mm:ssZ');

      const searchParams = new URLSearchParams();
      searchParams.append('rru', 'addevent');
      searchParams.append('startdt', startAtAsSearchParams);
      searchParams.append('enddt', endAtAsSearchParams);
      searchParams.append('subject', summary);
      searchParams.append('body', description);
      searchParams.append('location', location);

      const url = `${OUTLOOK_HREF}?${searchParams.toString()}`;

      window.open(url, '_blank');
    },
    [],
  );

  const createICalEvent = useCallback(
    (startAt: Moment, endAt: Moment, summary: string, description: string, location: string): void => {
      const calendarEvent: string = [
        'BEGIN:VCALENDAR',
        'PRODID:Calendar',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        'UID:' + uuid(),
        'CLASS:PUBLIC',
        'DESCRIPTION:' + description.replaceAll('\n', '\\n'),
        'DTSTART;VALUE=DATE-TIME:' + startAt.format('YYYYMMDDTHHmmss'),
        'DTEND;VALUE=DATE-TIME:' + endAt.format('YYYYMMDDTHHmmss'),
        'SUMMARY;LANGUAGE=en-us:' + summary,
        'LOCATION:' + location,
        'TRANSP:TRANSPARENT',
        'END:VEVENT',
        'END:VCALENDAR',
      ].join('\n');

      const blob = new Blob([calendarEvent], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, 'event-schedule.ics');
    },
    [],
  );

  return { createGoogleEvent, createOutlookEvent, createICalEvent };
}
