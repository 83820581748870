/* eslint-disable prettier/prettier */
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { Box, Button, DialogContent, Typography } from '@mui/material';
import { ConfirmResultEnum } from 'context';

import styles from './PostCancellationPopup.module.scss';

export interface PostCancellationPopupProps extends DialogProps {
  onConfirm(confirmResult: ConfirmResultEnum): void;
};

export const PostCancellationPopup = ({ onConfirm, ...rest }: PostCancellationPopupProps) => {
  const handleClose = () => {
    onConfirm(ConfirmResultEnum.Close);
  };
  return (
    <Dialog {...rest} className={styles.dialog}>
      <DialogContent>

        <Typography component='p' variant='body4'>
          We are sorry to see you go!
        </Typography>

        <Typography component='p' variant='body2'>
          If you change your mind, click <span style={{ color: '#FF3D3D', fontWeight: 700 }}>Keep my plan</span> any time to stay with HIPAA LINK.
        </Typography>

        <Box component='div' className={styles.actions}>
          <Button variant='contained' onClick={handleClose} className={styles.formButton}>
            Ok
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
};