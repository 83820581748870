import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function MenuIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='21' height='19' viewBox='0 0 21 19' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M19.227 3.47887H1.77271C0.790899 3.47887 0 2.70282 0 1.73944C0 0.776056 0.790899 0 1.77271 0H19.227C20.2088 0 20.9997 0.776056 20.9997 1.73944C20.9997 2.70282 20.2088 3.47887 19.227 3.47887ZM20.9997 9.5C20.9997 8.53662 20.2088 7.76056 19.227 7.76056H1.77271C0.790899 7.76056 0 8.53662 0 9.5C0 10.4634 0.790899 11.2394 1.77271 11.2394H19.227C20.2088 11.2394 20.9997 10.4634 20.9997 9.5ZM20.9997 17.2606C20.9997 16.2972 20.2088 15.5211 19.227 15.5211H1.77271C0.790899 15.5211 0 16.2972 0 17.2606C0 18.2239 0.790899 19 1.77271 19H19.227C20.2088 19 20.9997 18.2239 20.9997 17.2606Z'
        fill='#81CFDD'
      />
    </SvgIcon>
  );
}
