import { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { SimpleInfoDialog, Spinner } from 'components';
import { IUserContext } from 'context';
import { IApiRequests, useApiRequests, useDialog, useUser } from 'hooks';
import { updateInvite, selectInvite, InviteData, clearInvite } from 'store/invite';
import { selectUser } from 'store/user';
import { useAppDispatch, useAppSelector } from 'store';
import { ApiUser, InvitationInfoResponse } from 'types';

import invitation from '../../assets/images/invitation.jpg';
import styles from './InvitationPage.module.scss';

export const InvitationPage = () => {
  const invite: InviteData | null = useAppSelector(selectInvite);
  const user: ApiUser | null = useAppSelector(selectUser);
  const dispatch: any = useAppDispatch();
  const { invitationDecline, invitationInfo, invitationAccept }: IApiRequests = useApiRequests();
  const navigate = useNavigate();
  const { showDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const { logout }: IUserContext = useUser();

  const search = useLocation().search;
  const updateSearch = search.replace('?userId', '&userId');
  const invitationId: string | null = new URLSearchParams(updateSearch).get('invitationId');
  const userId: string | null = new URLSearchParams(updateSearch).get('userId');

  const [isValid, setIsValid] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!invitationId) {
      setIsValid(false);
      setLoading(false);
      return;
    }

    invitationInfo(invitationId)
      .then((data: InvitationInfoResponse) => {
        setIsValid(data.isValid);
        setLoading(false);
        if (data.isValid) {
          dispatch(
            updateInvite({
              invitationId,
              email: data?.email,
              organizationName: data?.organization,
              firstName: data?.firstName,
              lastName: data?.lastName,
              userId,
            }),
          );

          if (!userId && user) {
            enqueueSnackbar(`Invitation for user with email ${data?.email}. You are logged out`, { variant: 'info' });
            logout();
            return;
          }

          if (userId && userId !== user?.id) {
            enqueueSnackbar(`Invitation for user with email ${data?.email}. Please login`, { variant: 'info' });
            logout();
            navigate('/login');
          }
        }
      })
      .catch((e) => {
        enqueueSnackbar(`Fetching invitation info error: ${e.message}`, { variant: 'error' });
        navigate('/');
      });
  }, []);

  const handleCancel = (): void => {
    invitationDecline(invitationId!)
      .then(() => {
        dispatch(clearInvite());
        navigate('/');

        const modal = showDialog(SimpleInfoDialog, {
          title: 'Declined',
          text: `Thank you! We will let ${invite!.organizationName} know.`,
          onConfirm: () => {
            modal.destroy();
          },
        });
      })
      .catch((e) => {
        enqueueSnackbar(`Decline invitation error: ${e.message}`, { variant: 'error' });
      });
  };

  const handleRedirectHomeExpired = (): void => {
    navigate('/');
  };

  const handleAccept = async (): Promise<void> => {
    if (userId && userId === user?.id) {
      await invitationAccept(invitationId!);
    }
    navigate('/registerByInvitation');
  };

  return (
    <>
      {loading && <Spinner />}
      {!loading && (
        <Box className={styles.invitationContainer}>
          <Box>
            <Box className={styles.invitationInnerContainer}>
              <img className={styles.invitationImage} src={invitation} alt='' />
              <Typography className={styles.invitationTitle} variant='body2'>
                {isValid
                  ? `You’ve been invited to join ${invite?.organizationName} on HIPAA LINK!`
                  : 'This invitation is no longer valid.'}
              </Typography>

              {isValid ? (
                <>
                  <Typography variant='body1'>
                    As part of {invite?.organizationName} organization, you can create your own room and profile for
                    your clients. You can belong to as many organizations as you wish.
                  </Typography>
                  <Typography variant='body1'>Would you like to join {invite?.organizationName}?</Typography>
                  <Box className={styles.buttonsContainer}>
                    <Button className={styles.buttonsContainerItem} variant='outlined' onClick={handleCancel}>
                      Decline
                    </Button>
                    <Button className={styles.buttonsContainerItem} variant='contained' onClick={handleAccept}>
                      Accept
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  <Button
                    className={styles.buttonsContainerItemNoValid}
                    variant='contained'
                    onClick={handleRedirectHomeExpired}
                  >
                    Ok
                  </Button>
                  <Typography variant='body1'>
                    If you feel this is in error, please contact the administrator of the organization that invited you.
                  </Typography>
                </>
              )}
            </Box>
            <Box>
              <Typography className={styles.wantToKnowHyppaText}>
                Want to know more about HIPAA LINK? Click <a href='https://www.hipaalink.net/'>here</a>
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
