import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function TriangleIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M9.41358 3.86995L9.41369 3.86974C9.84754 3.08847 10.1192 2.60447 10.3513 2.30153C10.3797 2.26447 10.4047 2.23395 10.4263 2.20893C10.448 2.23402 10.473 2.26465 10.5016 2.3019C10.7339 2.60481 11.0057 3.08865 11.4401 3.86983C11.4402 3.86996 11.4402 3.87009 11.4403 3.87022L18.5665 16.6995L18.5668 16.7001C18.9804 17.4435 19.2322 17.9022 19.3589 18.2396C19.3746 18.2813 19.3866 18.3167 19.3959 18.3466C19.3653 18.353 19.3285 18.3597 19.2845 18.3662C18.9277 18.4192 18.4043 18.4219 17.5533 18.4219H3.29831C2.44722 18.4219 1.92383 18.4192 1.56704 18.3662C1.52305 18.3597 1.48625 18.353 1.45568 18.3466C1.46492 18.3167 1.47696 18.2813 1.49262 18.2396C1.61939 17.9022 1.87121 17.4435 2.28477 16.7001L2.28503 16.6996L9.41358 3.86995ZM10.5354 2.10295C10.5354 2.10331 10.5324 2.10553 10.5266 2.10855C10.5326 2.10411 10.5355 2.1026 10.5354 2.10295ZM10.3261 2.10886C10.3202 2.10582 10.3172 2.10358 10.3172 2.10323C10.3171 2.10287 10.32 2.10438 10.3261 2.10886ZM19.4255 18.483C19.4253 18.4828 19.425 18.4793 19.4254 18.4732C19.426 18.4802 19.4258 18.4832 19.4255 18.483ZM19.521 18.3109C19.5262 18.3075 19.5294 18.3061 19.5297 18.3062C19.53 18.3064 19.5274 18.308 19.521 18.3109ZM1.32187 18.3062C1.32217 18.3061 1.32535 18.3075 1.33052 18.3109C1.32415 18.308 1.32157 18.3064 1.32187 18.3062ZM1.42618 18.4732C1.4266 18.4794 1.4263 18.4828 1.42604 18.483C1.42578 18.4832 1.42557 18.4802 1.42618 18.4732Z'
        fill='none'
        strokeWidth='2'
      />
    </SvgIcon>
  );
}
