import React, { forwardRef, useMemo } from 'react';
import { Box, IconButton } from '@mui/material';
import classnames from 'classnames';
import { DotMenuIcon, DotMenuVerticalIcon } from 'assets';
import { ControlTooltip } from 'components';
import { useScreenSize } from 'hooks';

import styles from './ToggleDotMenuButton.module.scss';

type ToggleDotMenuButtonProps = {
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
  withTooltip?: boolean;
  iconColor?: string;
  onClick: () => void;
};

export const ToggleDotMenuButton = forwardRef<HTMLButtonElement, ToggleDotMenuButtonProps>(
  ({ disabled, className, iconClassName, withTooltip, iconColor, onClick }: ToggleDotMenuButtonProps, ref) => {
    const { isMobile } = useScreenSize();

    const tooltipMessage = useMemo(() => {
      let tooltipMessage = '';

      if (withTooltip) {
        tooltipMessage = 'More';
      }

      return tooltipMessage;
    }, [withTooltip]);

    return (
      <ControlTooltip title={tooltipMessage} isDisabled={disabled}>
        <Box component='span' display='block' className={classnames(styles.deviceButton, className)}>
          <IconButton
            disabled={disabled}
            className={classnames(styles.deviceButton, className)}
            sx={{
              backgroundColor: 'rgb(0 0 0 / 50%) !important',
            }}
            onClick={onClick}
            edge='end'
            aria-label='menu'
            ref={ref}
          >
            {isMobile ? (
              <DotMenuVerticalIcon
                className={classnames(styles.icon, iconClassName)}
                sx={{ color: iconColor || '#979797' }}
              />
            ) : (
              <DotMenuIcon className={classnames(styles.icon, iconClassName)} sx={{ color: iconColor || '#979797' }} />
            )}
          </IconButton>
        </Box>
      </ControlTooltip>
    );
  },
);
