import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function CircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M1.42578 10.4219C1.42578 5.44997 5.45387 1.42188 10.4258 1.42188C15.3977 1.42188 19.4258 5.44997 19.4258 10.4219C19.4258 15.3938 15.3977 19.4219 10.4258 19.4219C5.45387 19.4219 1.42578 15.3938 1.42578 10.4219Z'
        strokeWidth='2'
        fill='none'
      />
    </SvgIcon>
  );
}
