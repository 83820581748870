import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Dialog, Typography, Button } from '@mui/material';
import { FormInput } from 'components';

import styles from './PaymentMethodPopup.module.scss';

type PaymentMethodPopupProps = {
  open: boolean;
  close: () => void;
};

type PaymentMethodProps = {
  CardHolderName: string;
  cardNumber: string;
  ExpirationDate: string;
  CVV: string;
};

const schema = Yup.object().shape({
  CardHolderName: Yup.string().required(),
  CardNumber: Yup.string().required(),
  ExpirationDate: Yup.string().required(),
  CVV: Yup.string().required(),
});

export const PaymentMethodPopup = (props: PaymentMethodPopupProps) => {
  const form = useForm<PaymentMethodProps>({
    resolver: yupResolver(schema),
    defaultValues: {},
    mode: 'all',
  });

  return (
    <Box>
      <Dialog sx={{ '& .MuiPaper-root': { padding: '20px', width: '40rem' } }} open={props.open} onClose={props.close}>
        <Box className={styles.password_popup_header_box}>
          <Typography variant='body2' className={styles.password_main_title}>
            ADD PAYMENT METHOD
          </Typography>
          <CloseIcon onClick={props.close} className={styles.closeIcon} />
        </Box>
        <Box>
          <Typography variant='body2' className={styles.typography}>
            Cardholder Name <span className={styles.asterisk}>*</span>
          </Typography>
          <FormInput name='CardHolderName' control={form.control} type='text' />
        </Box>
        <Box>
          <Typography variant='body2' className={styles.typography}>
            Card Number <span className={styles.asterisk}>*</span>
          </Typography>
          <FormInput name='CardNumber' control={form.control} type='text' />
        </Box>
        <Box className={styles.flexContainer}>
          <Box>
            <Typography variant='body2' className={styles.typography}>
              Expiration Date <span className={styles.asterisk}>*</span>
            </Typography>
            <FormInput name='ExpirationDate' control={form.control} type='text' />
          </Box>
          <Box>
            <Typography variant='body2' className={styles.typography}>
              CVV <span className={styles.asterisk}>*</span>
            </Typography>
            <FormInput name='CVV' control={form.control} type='text' />
          </Box>
        </Box>
        <Button variant='contained' className={styles.button}>
          Add payment method
        </Button>
      </Dialog>
    </Box>
  );
};
