import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function ZoomInIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M20.0002 1.25C20.0002 0.918482 19.8685 0.600539 19.634 0.366118C19.3996 0.131697 19.0817 0 18.7501 0H12.5C12.1684 0 11.8505 0.131697 11.6161 0.366118C11.3816 0.600539 11.2499 0.918482 11.2499 1.25C11.2499 1.58153 11.3816 1.89947 11.6161 2.13389C11.8505 2.36831 12.1684 2.50001 12.5 2.50001H15.7125L11.6124 6.61252C11.4953 6.72872 11.4023 6.86698 11.3388 7.0193C11.2753 7.17163 11.2427 7.33501 11.2427 7.50002C11.2427 7.66504 11.2753 7.82842 11.3388 7.98075C11.4023 8.13307 11.4953 8.27132 11.6124 8.38753C11.7286 8.50469 11.8669 8.59768 12.0192 8.66114C12.1716 8.7246 12.3349 8.75727 12.5 8.75727C12.665 8.75727 12.8284 8.7246 12.9807 8.66114C13.133 8.59768 13.2713 8.50469 13.3875 8.38753L17.5001 4.27501V7.50002C17.5001 7.83154 17.6318 8.14949 17.8662 8.38391C18.1006 8.61833 18.4186 8.75003 18.7501 8.75003C19.0817 8.75003 19.3996 8.61833 19.634 8.38391C19.8685 8.14949 20.0002 7.83154 20.0002 7.50002V1.25Z'
        fill='#393534'
      />
      <path
        d='M8.38772 11.6129C8.27151 11.4958 8.13326 11.4028 7.98093 11.3393C7.8286 11.2758 7.66522 11.2432 7.5002 11.2432C7.33518 11.2432 7.17179 11.2758 7.01946 11.3393C6.86714 11.4028 6.72888 11.4958 6.61267 11.6129L2.50007 15.7129V12.5004C2.50007 12.1689 2.36837 11.851 2.13394 11.6165C1.89951 11.3821 1.58156 11.2504 1.25003 11.2504C0.918504 11.2504 0.600553 11.3821 0.366126 11.6165C0.1317 11.851 0 12.1689 0 12.5004V18.7504C0 19.082 0.1317 19.3999 0.366126 19.6343C0.600553 19.8687 0.918504 20.0004 1.25003 20.0004H7.5002C7.83173 20.0004 8.14968 19.8687 8.3841 19.6343C8.61853 19.3999 8.75023 19.082 8.75023 18.7504C8.75023 18.4189 8.61853 18.101 8.3841 17.8665C8.14968 17.6321 7.83173 17.5004 7.5002 17.5004H4.27511L8.38772 13.3879C8.50488 13.2717 8.59788 13.1335 8.66134 12.9811C8.7248 12.8288 8.75748 12.6654 8.75748 12.5004C8.75748 12.3354 8.7248 12.172 8.66134 12.0197C8.59788 11.8674 8.50488 11.7291 8.38772 11.6129Z'
        fill='#393534'
      />
    </SvgIcon>
  );
}
