import { createContext } from 'react';
import { RemoteParticipant } from 'twilio-video';

export interface IParticipantContext {
  galleryViewParticipants: RemoteParticipant[];
  speakerViewParticipants: RemoteParticipant[];
  gridViewParticipants: RemoteParticipant[];
}

export const ParticipantContext = createContext<IParticipantContext | null>(null);
export const ParticipantConsumer = ParticipantContext.Consumer;
