import { Container, Typography } from '@mui/material';
import classnames from 'classnames';

import { useVideoBackgroundEffect } from 'hooks';
import { Spinner } from 'components/Spinner';

import styles from './BackgroundEffectsList.module.scss';

import img1 from 'assets/images/videoBackgrounds/1.jpg';
import img2 from 'assets/images/videoBackgrounds/2.jpg';
import img3 from 'assets/images/videoBackgrounds/3.jpg';
import img4 from 'assets/images/videoBackgrounds/4.jpg';
import img5 from 'assets/images/videoBackgrounds/5.jpg';
import img6 from 'assets/images/videoBackgrounds/6.jpg';
import img7 from 'assets/images/videoBackgrounds/7.jpg';
import img8 from 'assets/images/videoBackgrounds/8.jpg';
import img9 from 'assets/images/videoBackgrounds/9.jpg';
import img10 from 'assets/images/videoBackgrounds/10.jpg';
import img11 from 'assets/images/videoBackgrounds/11.jpg';
import img12 from 'assets/images/videoBackgrounds/12.jpg';
import img13 from 'assets/images/videoBackgrounds/13.jpg';
import img14 from 'assets/images/videoBackgrounds/14.jpg';
import img15 from 'assets/images/videoBackgrounds/15.jpg';
import img16 from 'assets/images/videoBackgrounds/16.jpg';
import img17 from 'assets/images/videoBackgrounds/17.jpg';
import img18 from 'assets/images/videoBackgrounds/18.jpg';
import img19 from 'assets/images/videoBackgrounds/19.jpg';
import img20 from 'assets/images/videoBackgrounds/20.jpg';
import img21 from 'assets/images/videoBackgrounds/21.jpg';
import img22 from 'assets/images/videoBackgrounds/22.jpg';
import img23 from 'assets/images/videoBackgrounds/23.jpg';
import img24 from 'assets/images/videoBackgrounds/24.jpg';
import img25 from 'assets/images/videoBackgrounds/25.jpg';
import img26 from 'assets/images/videoBackgrounds/26.jpg';
import img27 from 'assets/images/videoBackgrounds/27.jpg';
import img28 from 'assets/images/videoBackgrounds/28.jpg';
import img29 from 'assets/images/videoBackgrounds/29.jpg';
import img30 from 'assets/images/videoBackgrounds/30.jpg';
import img31 from 'assets/images/videoBackgrounds/31.jpg';
import img32 from 'assets/images/videoBackgrounds/32.jpg';
import img33 from 'assets/images/videoBackgrounds/33.jpg';
import img34 from 'assets/images/videoBackgrounds/34.jpg';
import img35 from 'assets/images/videoBackgrounds/35.jpg';
import img36 from 'assets/images/videoBackgrounds/36.jpg';

type BackgroundEffectsListType = {
  itemClassName?: string;
};

export function BackgroundEffectsList({ itemClassName: itemClassname }: BackgroundEffectsListType) {
  const { loading, videoBackgroundEffect, clearEffect, blur, setImage } = useVideoBackgroundEffect();

  const imgs: string[] = [
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
    img27,
    img28,
    img29,
    img30,
    img31,
    img32,
    img33,
    img34,
    img35,
    img36,
  ];

  return (
    <Container className={styles.container}>
      {loading && <Spinner small />}

      <div
        onClick={clearEffect}
        className={classnames(styles.item, itemClassname, { [styles.selectedItem]: videoBackgroundEffect === null })}
      >
        <div>
          <Typography variant='body2' component='p' color='white'>
            None
          </Typography>
        </div>
      </div>

      <div
        onClick={blur}
        className={classnames(styles.item, itemClassname, { [styles.selectedItem]: videoBackgroundEffect === 'blur' })}
      >
        <div>
          <Typography variant='body2' component='p' color='white'>
            Blur
          </Typography>
          <img src={img1} />
        </div>
      </div>

      {imgs.map((img: string, index: number) => (
        <div
          key={index}
          onClick={() => setImage(index)}
          className={classnames(styles.item, itemClassname, { [styles.selectedItem]: videoBackgroundEffect === index })}
        >
          <div>
            <img src={img} />
          </div>
        </div>
      ))}
    </Container>
  );
}
