import { useEffect, useRef, useState } from 'react';
import { Typography, Avatar, Box, Popper, ClickAwayListener, Grid } from '@mui/material';

import { ChevronRightIcon, CrownIcon } from 'assets';
import { Transitions } from 'components';
import { selectUser } from 'store/user';
import { useAppSelector } from 'store';
import { useAzureAsset } from 'hooks';
import { ClinicListItemResponse } from 'types';
import { OrganizationInfo } from '../OrganizationInfo';
import { getFullName } from 'utils';

import styles from './ProfileSelector.module.scss';

type ProfileSelector = {
  clinicsList: ClinicListItemResponse[];
  onChangeClinic(clinicId: string): Promise<void>;
};

export function ProfileSelector({ clinicsList, onChangeClinic }: ProfileSelector) {
  const user = useAppSelector(selectUser);

  const [open, setOpen] = useState(false);
  const prevOpen = useRef(open);
  const anchorRef = useRef<HTMLDivElement>(null);
  const avatarUrl = useAzureAsset(user?.linkToProfilePicture);

  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current && anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeClinic = (clinicId: string): void => {
    onChangeClinic(clinicId);
  };

  return (
    <div className={styles.container}>
      <div onClick={handleToggle} ref={anchorRef} className={styles.profile}>
        <Avatar className={styles.profilePicture} src={avatarUrl} />

        <Grid className={styles.texts}>
          <Typography component='p' variant='body2' className={styles.name}>
            {getFullName(user)}
          </Typography>

          <Typography component='p' variant='body1' color='#979797'>
            {user?.clinicUser.organization}
          </Typography>

          <CrownIcon />
        </Grid>

        <ChevronRightIcon className={styles.arrowIcon} style={{ transform: open ? 'rotate(180deg)' : '' }} />
      </div>
      <Popper
        placement='bottom-start'
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box className={styles.menu}>
                <Box className={styles.menuHeader}>
                  <Typography component='p' variant='body2' className={styles.name}>
                    Switch to another organization
                  </Typography>
                </Box>

                <Box className={styles.menuBody}>
                  {clinicsList.map((clinic: ClinicListItemResponse) => (
                    <div key={clinic.clinicId} onClick={() => handleChangeClinic(clinic.clinicId)}>
                      <OrganizationInfo
                        organization={clinic.organization}
                        isAdmin={clinic.isAdmin}
                        membersCount={clinic.membersCount}
                        className={styles.organization}
                      />
                    </div>
                  ))}
                </Box>
              </Box>
            </ClickAwayListener>
          </Transitions>
        )}
      </Popper>
    </div>
  );
}
