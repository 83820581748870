import { Grow } from '@mui/material';
import { useRoomRightSidebar } from 'hooks';

import { GroupChat } from '../GroupChat';
import { ParticipantsMenu } from '../ParticipantsMenu';
import { NotesMenu } from '../NotesMenu';
import { BackgroundEffectsMenu } from '../BackgroundEffectsMenu';
import { SettingsMenu } from '../SettingsMenu';

import styles from './RightSidebar.module.scss';

export function RightSidebar(): JSX.Element {
  const { openedTab } = useRoomRightSidebar();

  return (
    <>
      {openedTab && (
        <Grow in={!!openedTab} style={{ transformOrigin: '0 0 0' }} {...(openedTab ? { timeout: 1000 } : {})}>
          <div className={styles.container}>
            {openedTab === 'chat' && <GroupChat />}
            {openedTab === 'settings' && <SettingsMenu withLayoutSelection />}
            {openedTab === 'participants' && <ParticipantsMenu />}
            {openedTab === 'notes' && <NotesMenu />}
            {openedTab === 'backgroundEffects' && <BackgroundEffectsMenu />}
          </div>
        </Grow>
      )}
    </>
  );
}
