import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function ChevronRightIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='10' height='6' viewBox='0 0 10 6' fill='none' {...props}>
      <path d='M10 1.13513L8.83333 0L5 3.72973L1.16667 0L-9.53674e-07 1.13513L5 6L10 1.13513Z' />
    </SvgIcon>
  );
}
