import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { useSnackbar } from 'notistack';

import { IEMDRContext, ISignalrConnectionContext } from 'context';
import { WhiteboardContext, WhiteboardState } from './context';
import { useAppSettings, useEMDR, useSignalrConnection, useVideo } from 'hooks';
import { selectWaitingRoomProfile } from 'store/waitingRoom';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import {
  ApiUser,
  ApiWaitingRoomLinkProfile,
  ItemAddedMessage,
  ItemUndoedMessage,
  ItemsAddedMessage,
  AllItemsMessage,
  LayoutType,
  WhiteboardItem,
} from 'types';
import { deserializeItem, noop, serializeItem } from 'utils';

type WhiteboardProviderProps = {
  children?: JSX.Element;
};

export function WhiteboardProvider({ children }: WhiteboardProviderProps) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    startWhiteboard: startWhiteboardQueueHub,
    addEventListener,
    removeEventListener,
  }: ISignalrConnectionContext = useSignalrConnection();
  const { isSharingScreen, room, toggleScreenShare } = useVideo();
  const { state: emdrState, stopEmdr }: IEMDRContext = useEMDR();
  const { setLayoutType } = useAppSettings();

  const user: ApiUser | null = useAppSelector(selectUser);
  const waitingRoomProfile: ApiWaitingRoomLinkProfile | null = useAppSelector(selectWaitingRoomProfile);

  const connectionToWhiteboardHub = useRef<HubConnection | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [isStarted, setIsStarted] = useState<boolean>(false);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [localItems, setLocalItems] = useState<WhiteboardItem[]>([]);
  const [localUndoedItems, setLocalUndoedItems] = useState<WhiteboardItem[]>([]);
  const [allItems, setAllItems] = useState<WhiteboardItem[]>([]);

  const state: WhiteboardState = useMemo(() => {
    if (!isStarted) {
      return 'off';
    }

    if (loading) {
      return 'loading';
    }

    return 'on';
  }, [loading, isStarted]);

  const toggleFullScreen = useCallback(() => {
    setIsFullScreen((prevState: boolean) => !prevState);
  }, []);

  const disconnect = useCallback((): void => {
    connectionToWhiteboardHub.current?.stop();
    connectionToWhiteboardHub.current = null;

    setIsStarted(false);
  }, [connectionToWhiteboardHub]);

  const onItemAdded = useCallback((message: ItemAddedMessage): void => {
    if (message.connectionId === connectionToWhiteboardHub.current!.connectionId) {
      return;
    }

    const item: WhiteboardItem = deserializeItem(message.itemInJson)!;
    setAllItems((prevAllItems: WhiteboardItem[]) => [...prevAllItems, item]);
  }, []);

  const onItemsAdded = useCallback((message: ItemsAddedMessage): void => {
    if (message.connectionId === connectionToWhiteboardHub.current!.connectionId) {
      setLoading(false);
    }

    const items: WhiteboardItem[] = message.itemsInJson.map((itemInJson: string) => deserializeItem(itemInJson)!);
    setAllItems((prevAllItems: WhiteboardItem[]) => [...prevAllItems, ...items]);
  }, []);

  const onItemUndoed = useCallback((message: ItemUndoedMessage): void => {
    if (message.connectionId === connectionToWhiteboardHub.current!.connectionId) {
      return;
    }

    setAllItems((prevAllItems: WhiteboardItem[]) => [
      ...prevAllItems.filter((item: WhiteboardItem) => item.id !== message.itemId),
    ]);
  }, []);

  const onAllItems = useCallback((message: AllItemsMessage): void => {
    setAllItems(message.itemsInJson.map((itemInJson: string) => deserializeItem(itemInJson)!));
  }, []);

  const onAllItemsRemoved = useCallback((): void => {
    setAllItems([]);
    setLocalItems([]);
    setLocalUndoedItems([]);
  }, []);

  const onStopped = useCallback((): void => {
    disconnect();
    setAllItems([]);
    setLocalItems([]);
    setLocalUndoedItems([]);
  }, [disconnect]);

  const listenWhiteboardHub = useCallback(
    (hubConnection: HubConnection) => {
      if (hubConnection) {
        hubConnection.on('itemAdded', onItemAdded);

        hubConnection.on('itemsAdded', onItemsAdded);

        hubConnection.on('itemUndoed', onItemUndoed);

        hubConnection.on('allItems', onAllItems);

        hubConnection.on('allItemsRemoved', onAllItemsRemoved);

        hubConnection.on('stopped', onStopped);
      }
    },
    [onItemAdded, onItemsAdded, onItemUndoed, onAllItems, onAllItemsRemoved, onStopped],
  );

  const connectToWhiteboardHub = useCallback((): Promise<void> => {
    if (connectionToWhiteboardHub.current) {
      return Promise.resolve();
    }

    const queryString = {
      clinicUserId: user?.clinicUser.id ?? waitingRoomProfile!.providerId,
    };
    const urlParams: string = new URLSearchParams(queryString).toString();

    const hubConnection: HubConnection = new HubConnectionBuilder()
      .withUrl(`${process.env['REACT_APP_HIPAA_LINK_API_URI']}whiteboard?${urlParams}`)
      .build();

    listenWhiteboardHub(hubConnection);

    hubConnection.onclose(() => {
      disconnect();
    });

    return hubConnection
      .start()
      .then(() => {
        connectionToWhiteboardHub.current = hubConnection;
        setIsStarted(true);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        throw error;
      });
  }, [user, waitingRoomProfile, listenWhiteboardHub]);

  const onWhiteboardStarted = useCallback((): void => {
    setLoading(true);

    connectToWhiteboardHub()
      .catch((error) => {
        enqueueSnackbar(`connectToWhiteboardHub error: ${error.message}`, { variant: 'error' });
      })
      .finally(() => setLoading(false));
  }, [connectToWhiteboardHub]);

  useEffect(() => {
    const onParticipantAdded = (): void => {
      if (connectionToWhiteboardHub.current) {
        startWhiteboardQueueHub();
      }
    };

    room?.on('participantConnected', onParticipantAdded);

    return () => {
      room?.off('participantConnected', onParticipantAdded);
    };
  }, [room]);

  useEffect(() => {
    return () => disconnect();
  }, []);

  useEffect(() => {
    addEventListener('whiteboardStarted', onWhiteboardStarted);

    return () => {
      removeEventListener('whiteboardStarted', onWhiteboardStarted);
    };
  }, [onWhiteboardStarted]);

  useEffect(() => {
    const onEmdrStarted = (): void => {
      if (connectionToWhiteboardHub.current) {
        if (user) {
          connectionToWhiteboardHub.current?.invoke('stop').catch(noop);
        } else {
          disconnect();
        }
      }
    };
    addEventListener('emdrStarted', onEmdrStarted);

    return () => {
      removeEventListener('emdrStarted', onEmdrStarted);
    };
  }, [disconnect]);

  useEffect(() => {
    const onCallEnded = (): void => {
      if (connectionToWhiteboardHub.current) {
        if (user) {
          connectionToWhiteboardHub.current?.invoke('stop');
        } else {
          disconnect();
        }
      }
    };
    addEventListener('callEnded', onCallEnded);

    return () => {
      removeEventListener('callEnded', onCallEnded);
    };
  }, [disconnect]);

  useEffect(() => {
    addEventListener('removedFromCall', disconnect);

    return () => {
      removeEventListener('removedFromCall', disconnect);
    };
  }, [disconnect]);

  const startWhiteboard = (): void => {
    if (user && !connectionToWhiteboardHub.current) {
      startWhiteboardQueueHub();

      if (isSharingScreen) {
        toggleScreenShare();
      }

      if (emdrState !== 'off') {
        stopEmdr();
      }

      setLayoutType(LayoutType.SPEAKER);
    }
  };

  const stopWhiteboard = (): void => {
    if (user && connectionToWhiteboardHub.current) {
      connectionToWhiteboardHub.current?.invoke('stop').catch(noop);
    }
  };

  const addItem = useCallback(
    (newItem: WhiteboardItem): void => {
      setAllItems((prevAllItems: WhiteboardItem[]) => [...prevAllItems, newItem]);
      setLocalItems((prevLocalItems: WhiteboardItem[]) => [...prevLocalItems, newItem]);

      setLocalUndoedItems([]);

      const newItemInJson: string = serializeItem(newItem);
      connectionToWhiteboardHub.current!.invoke('addItem', { itemInJson: newItemInJson, itemId: newItem.id });
    },
    [connectionToWhiteboardHub],
  );

  const undo = useCallback((): void => {
    if (localItems.length) {
      const itemId: string = localItems[localItems.length - 1].id;

      const lastLocalItems: WhiteboardItem[] = localItems.filter(
        (localItem: WhiteboardItem) => localItem.id === itemId,
      );

      setLocalItems((prevLocalItems: WhiteboardItem[]) => [
        ...prevLocalItems.filter((localItem: WhiteboardItem) => localItem.id !== itemId),
      ]);
      setLocalUndoedItems((prevLocalUndoedItems: WhiteboardItem[]) => [...prevLocalUndoedItems, ...lastLocalItems]);
      setAllItems((prevLocalItems: WhiteboardItem[]) => [
        ...prevLocalItems.filter((localItem: WhiteboardItem) => localItem.id !== itemId),
      ]);

      connectionToWhiteboardHub.current!.invoke('undo', itemId);
    }
  }, [localItems, connectionToWhiteboardHub]);

  const redo = useCallback(() => {
    if (localUndoedItems.length) {
      const itemId: string = localUndoedItems[localUndoedItems.length - 1].id;
      const lastLocalUndoedItems: WhiteboardItem[] = localUndoedItems.filter(
        (localUndoedItem: WhiteboardItem) => localUndoedItem.id === itemId,
      );

      setLocalUndoedItems((prevLocalUndoedItems: WhiteboardItem[]) => [
        ...prevLocalUndoedItems.filter((undoedItem: WhiteboardItem) => undoedItem.id !== itemId),
      ]);
      setLocalItems((prevLocalItems: WhiteboardItem[]) => [...prevLocalItems, ...lastLocalUndoedItems]);

      setLoading(true);

      const newItemsInJson: string[] = lastLocalUndoedItems.map((item: WhiteboardItem) => serializeItem(item));
      connectionToWhiteboardHub.current!.invoke('addItems', { itemsInJson: newItemsInJson, itemId });
    }
  }, [localUndoedItems, connectionToWhiteboardHub]);

  const clearCnavas = useCallback(() => {
    if (user) {
      setAllItems([]);
      setLocalItems([]);
      setLocalUndoedItems([]);

      connectionToWhiteboardHub.current!.invoke('removeAllItems');
    }
  }, [user, connectionToWhiteboardHub]);

  return (
    <WhiteboardContext.Provider
      value={{
        state,
        isFullScreen,
        items: allItems,
        canUndo: !!localItems.length,
        canRedo: !!localUndoedItems.length,
        startWhiteboard,
        stopWhiteboard,
        toggleFullScreen,
        addItem,
        undo,
        redo,
        clearCnavas,
      }}
    >
      {children}
    </WhiteboardContext.Provider>
  );
}
