import React, { useMemo } from 'react';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { SnackbarProvider, useSnackbar, SnackbarKey } from 'notistack';
import { Button } from '@mui/material';
import 'swiper/scss';
import 'swiper/scss/free-mode';
import 'swiper/scss/scrollbar';
import 'swiper/scss/navigation';
import Router from './Router';
import { getTheme } from 'theme';
import store from 'store';

import 'styles/app.scss';

function App() {
  const themeProvider = useMemo(() => getTheme('dark'), []);

  const DismissAction = ({ id }: { id: SnackbarKey }) => {
    const { closeSnackbar } = useSnackbar();

    return (
      <React.Fragment>
        <Button
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
          }}
          onClick={() => closeSnackbar(id)}
        />
      </React.Fragment>
    );
  };
  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themeProvider}>
          <SnackbarProvider
            maxSnack={3}
            preventDuplicate
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            action={(key) => <DismissAction id={key} />}
          >
            <>
              <CssBaseline />
              <Router />
            </>
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
}

export default App;
