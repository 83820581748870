import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function ObjectShapeMoonIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M10.7795 19.9999H10.6793C9.3052 19.9894 7.94701 19.7048 6.68426 19.1628C5.4215 18.6207 4.27956 17.8321 3.32531 16.8431C1.55579 14.9503 0.549914 12.469 0.501809 9.87797C0.453703 7.28699 1.36678 4.77001 3.06481 2.81279C4.15878 1.58648 5.53404 0.644185 7.07243 0.0668567C7.25194 -0.00223418 7.44753 -0.018184 7.63585 0.0209117C7.82417 0.0600074 7.99726 0.152496 8.13445 0.287333C8.26151 0.416935 8.35099 0.578622 8.39334 0.755128C8.43569 0.931634 8.42932 1.11633 8.37491 1.2895C7.82582 2.79368 7.71703 4.42332 8.06131 5.98717C8.40559 7.55102 9.18865 8.98422 10.3186 10.1186C11.4591 11.2453 12.8953 12.026 14.461 12.37C16.0267 12.7141 17.6578 12.6076 19.1654 12.0628C19.3449 11.9996 19.5386 11.9888 19.7239 12.0317C19.9093 12.0746 20.0786 12.1694 20.2121 12.305C20.3455 12.4406 20.4376 12.6114 20.4776 12.7975C20.5176 12.9835 20.5038 13.1771 20.4378 13.3556C19.926 14.7222 19.1265 15.9629 18.0934 16.9934C17.1324 17.9518 15.9915 18.7108 14.7364 19.2267C13.4812 19.7427 12.1365 20.0054 10.7795 19.9999Z' />
    </SvgIcon>
  );
}
