import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Hidden,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';

import { DEFAULT_WELCOME_MESSAGE, DEFAULT_UNAVAILABLE_MESSAGE } from 'constants/data';
import { useAppDispatch, useAppSelector } from 'store';
import {
  dismiss,
  selectPatient,
  clearPatientStore,
  selectPatientIsDismissed,
  setPatient as setPatientInStore,
} from 'store/patient';
import { ApiWaitingRoomLinkProfile, Patient } from 'types';
import { CircleCrossIcon, EmailIcon, HeavyCheckIcon, HospitalIcon } from 'assets';
import { useAzureAsset, useChat, useDialog, useLocalStorageState, useSignalrConnection, useVideo } from 'hooks';
import { MediaContainer, SettingsDialog, VideoPreviewWithControls, InfoDialog, TwoOptionsDialog } from 'components';
import { isPermissionDenied, noop, replaceURLsInString, stringifyIdentity } from 'utils';
import { WelcomeDialog } from '../WelcomeDialog';
import { CheckedInDialog } from '../CheckedInDialog';
import { EndCallDialog } from '../EndCallDialog';
import {
  updateIsProviderInQueueStatusInWaitingRoom,
  updateUnavailableProviderStatusInWaitingRoom,
} from 'store/waitingRoom';

import styles from './PatientWaitingRoom.module.scss';

type PatientConnectionStage = 'none' | 'patientDataAdded' | 'settingsCofigured' | 'checkFormClosed';

type PatientWaitingRoomProps = {
  profile: ApiWaitingRoomLinkProfile;
};

export function PatientWaitingRoom({ profile }: PatientWaitingRoomProps) {
  const dispatch = useAppDispatch();
  const patientInStore = useAppSelector(selectPatient);
  const isDismissed = useAppSelector(selectPatientIsDismissed);
  const navigate = useNavigate();
  const {
    updateCallData,
    events,
    signalrConnectionId,
    getToken,
    getIsProviderConnectedToQueue,
    disconnect,
    addEventListener,
    removeEventListener,
  } = useSignalrConnection();

  const {
    connect: videoConnect,
    isAcquiringLocalTracks,
    room,
    removeLocalVideoTrack,
    removeLocalAudioTrack,
  } = useVideo();
  const { showDialog } = useDialog();
  const avatarUrl = useAzureAsset(profile.linkToProfilePicture);
  const { identity, conversation, openChat } = useChat();
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md') && theme.breakpoints.up('sm'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [previousPatientDataToReconnect, setPreviousPatientDataToReconnect] = useLocalStorageState<Patient | null>(
    'previousPatientDataToReconnect',
  );
  const [patient, setPatient] = useState<Patient | null>(null);
  const [patientConnectionStage, setPatientConnectionStage] = useState<PatientConnectionStage>('none');
  const doNotHandleDisconnectionFromSignalr: MutableRefObject<boolean> = useRef<boolean>(false);
  const localTracksStartedAcquiring: MutableRefObject<boolean> = useRef<boolean>(false);

  const profileFullName = useMemo(() => {
    let fullName = profile.preferedDisplayName;
    if (!fullName) {
      fullName = [profile.title, profile.firstName, profile.lastName].filter((t) => t && t).join(' ');

      if (profile.suffix) {
        fullName += `, ${profile.suffix}`;
      }
    }

    return fullName;
  }, [profile]);

  const unavailableMessage = useMemo(() => {
    //eslint-disable-next-line
    return Boolean(profile.unavailableMessage) ? profile.unavailableMessage : DEFAULT_UNAVAILABLE_MESSAGE;
  }, [profile]);

  const welcomeMessage = useMemo(() => {
    //eslint-disable-next-line
    return Boolean(profile.welcomeMessage) ? profile.welcomeMessage : DEFAULT_WELCOME_MESSAGE;
  }, [profile]);

  ////////////////////////////////////////////////
  /// Events listening
  ////////////////////////////////////////////////

  const onKicked = useCallback(() => {
    doNotHandleDisconnectionFromSignalr.current = true;
    dispatch(dismiss());
    dispatch(clearPatientStore());
    disconnect();
    removeLocalAudioTrack();
    removeLocalVideoTrack();

    room?.disconnect();
    const modal = showDialog(InfoDialog, {
      title: 'You have been removed from the call. \n' + 'If you feel this is in error, please contact your provider. ',
      onConfirm: () => {
        modal.destroy();
        navigate('/login');
      },
    });
  }, [room, removeLocalAudioTrack, removeLocalVideoTrack]);

  const onCallEnded = useCallback(() => {
    doNotHandleDisconnectionFromSignalr.current = true;
    dispatch(dismiss());
    dispatch(clearPatientStore());
    disconnect();
    removeLocalAudioTrack();
    removeLocalVideoTrack();

    room?.disconnect();
    const modal = showDialog(EndCallDialog, {
      onConfirm: () => {
        modal.destroy();
      },
    });
  }, [room, removeLocalAudioTrack, removeLocalVideoTrack]);

  const onDisconnected = useCallback(() => {
    if (doNotHandleDisconnectionFromSignalr.current) {
      return;
    }

    dispatch(dismiss());
    dispatch(clearPatientStore());
    disconnect();

    removeLocalAudioTrack();
    removeLocalVideoTrack();

    room?.disconnect();

    const modal = showDialog(InfoDialog, {
      title: 'You have been removed from the waiting room. If you feel this is in error, please contact your provider.',
      onConfirm: () => {
        modal.destroy();
        navigate('/login');
      },
    });
  }, [room, patient, removeLocalAudioTrack, removeLocalVideoTrack]);

  const onReconnected = useCallback(() => {
    if (doNotHandleDisconnectionFromSignalr.current) {
      return;
    }

    dispatch(dismiss());
    dispatch(clearPatientStore());

    removeLocalAudioTrack();
    removeLocalVideoTrack();

    room?.disconnect();

    const modal = showDialog(TwoOptionsDialog, {
      title: 'Disconnected',
      text: 'You have been disconnected. Probably because of bad internet connection.',
      confirmText: 'Reconnect',
      onConfirm: () => {
        setPreviousPatientDataToReconnect({ ...patient!, id: uuid() });
        modal.destroy();
        window.location.reload();
      },
    });
  }, [room, patient, removeLocalAudioTrack, removeLocalVideoTrack]);

  useEffect(() => {
    addEventListener('removedFromCall', onKicked);
    addEventListener('callEnded', onCallEnded);
    addEventListener('disconnected', onDisconnected);
    addEventListener('reconnected', onReconnected);

    return () => {
      removeEventListener('removedFromCall', onKicked);
      removeEventListener('callEnded', onCallEnded);
      removeEventListener('disconnected', onDisconnected);
      removeEventListener('reconnected', onReconnected);
    };
  }, [onKicked, onCallEnded, onDisconnected, onReconnected]);

  const onStartCall = (roomId: string, id: string) => {
    if (patientInStore && id) {
      const identity = stringifyIdentity([id, patientInStore.userName]);

      getToken({ identity, roomId })
        .then((token) => {
          videoConnect(token);
        })
        .catch(noop);
    }
  };

  useEffect(() => {
    events.on('joinCall', onStartCall);
    events.on('updatedUnavailableProviderStatus', (unavailable: boolean) =>
      dispatch(updateUnavailableProviderStatusInWaitingRoom(unavailable)),
    );
    events.on('isProviderInQueueSent', (isProviderInQueue: boolean) =>
      dispatch(updateIsProviderInQueueStatusInWaitingRoom(isProviderInQueue)),
    );
  }, [events]);

  ////////////////////////////////////////////////
  /// Preparing patinet to connection
  ////////////////////////////////////////////////

  const updatePatientData = async () => {
    const isCameraPermissionDenied = await isPermissionDenied('camera');
    const isMicrophonePermissionDenied = await isPermissionDenied('microphone');
    updateCallData({
      callAccesses: { hasMicroAccess: !isMicrophonePermissionDenied, hasCameraAccess: !isCameraPermissionDenied },
    });
  };

  useEffect(() => {
    if (signalrConnectionId && patientConnectionStage === 'settingsCofigured') {
      updatePatientData().catch(noop);
      getIsProviderConnectedToQueue();

      const modal = showDialog(CheckedInDialog, {
        onConfirm: () => {
          modal.destroy();
          setPatientConnectionStage('checkFormClosed');
        },
      });
    }
  }, [patient, signalrConnectionId, patientConnectionStage]);

  useEffect(() => {
    if (
      patientConnectionStage === 'patientDataAdded' &&
      !isAcquiringLocalTracks &&
      localTracksStartedAcquiring.current
    ) {
      const modal = showDialog(SettingsDialog, {
        onConfirm: async () => {
          dispatch(setPatientInStore(patient!));
          setPatientConnectionStage('settingsCofigured');

          modal.destroy();
        },
        onClose: () => {
          dispatch(setPatientInStore(patient!));
          setPatientConnectionStage('settingsCofigured');
        },
      });
    }

    if (!localTracksStartedAcquiring.current && isAcquiringLocalTracks) {
      localTracksStartedAcquiring.current = true;
    }
  }, [patientConnectionStage, isAcquiringLocalTracks]);

  useEffect(() => {
    if (patientConnectionStage === 'none') {
      if (previousPatientDataToReconnect) {
        setPatient(previousPatientDataToReconnect);
        dispatch(setPatientInStore(previousPatientDataToReconnect));
        setPreviousPatientDataToReconnect(null);
        setPatientConnectionStage('settingsCofigured');
        return;
      }

      const modal = showDialog(WelcomeDialog, {
        avatarUrl,
        organizationName: profile.organization,
        profileFullName,
        onConfirm: (patient: Patient) => {
          setPatient(patient);
          setPatientConnectionStage('patientDataAdded');
          modal.destroy();
          setTimeout(() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          }, 300);
        },
      });
    }
  }, [patientConnectionStage, previousPatientDataToReconnect]);

  const handleMessageClick = (): void => {
    if (!conversation && !profile.unavailable && profile.isProviderInQueue) {
      openChat(profile.providerId, identity);
    }
  };

  return (
    <>
      {patient && !isDismissed && <MediaContainer />}

      <Hidden smUp>
        <Box>{!isDismissed && <VideoPreviewWithControls />}</Box>
      </Hidden>

      <Card
        className={classnames(styles.main, {
          [styles.mainTablet]: isTablet,
          [styles.mainMobile]: isMobile,
        })}
      >
        <CardHeader title={profile.organization} />
        <CardContent
          className={classnames(styles.content, {
            [styles.contentTablet]: isTablet,
            [styles.contentMobile]: isMobile,
          })}
        >
          <Stack direction='row' spacing={'20px'} alignItems='flex-start' justifyContent='space-between'>
            <Stack direction='column' spacing={'20px'}>
              {patient && !isDismissed && (
                <Typography component='p' variant='h2'>
                  {`Hi, ${patient.userName}`}
                </Typography>
              )}
              {!isDismissed &&
                profile.isProviderInQueue !== undefined &&
                (profile.unavailable || !profile.isProviderInQueue ? (
                  <Typography component='p' variant='body2'>
                    {unavailableMessage}
                  </Typography>
                ) : (
                  <Typography component='p' variant='body2'>
                    {welcomeMessage}
                  </Typography>
                ))}

              <Hidden smDown>
                <Stack direction='row' spacing={'20px'} alignItems='flex-start'>
                  <Stack spacing={'10px'} alignItems='flex-start'>
                    <Avatar
                      sx={{
                        maxWidth: 150,
                        maxHeight: 150,
                        minWidth: 100,
                        minHeight: 100,
                        width: '100%',
                        height: '100%',
                        flex: 1,
                        borderRadius: 3,
                      }}
                      variant='square'
                      src={avatarUrl}
                    >
                      {profile.firstName}
                    </Avatar>

                    {!isDismissed && (
                      <Button
                        variant='contained'
                        disabled={profile.unavailable || !profile.isProviderInQueue}
                        className={styles.messageBtn}
                        onClick={handleMessageClick}
                      >
                        Message
                      </Button>
                    )}
                  </Stack>

                  <Stack direction='column' spacing={'20px'}>
                    <Stack spacing={'7px'}>
                      <Typography component='p' variant='h4'>
                        {profileFullName}
                      </Typography>

                      <Typography component='p' variant='body2' color='#979797'>
                        <i>{profile.speciality}</i>
                      </Typography>
                    </Stack>

                    {!isDismissed &&
                      profile.isProviderInQueue !== undefined &&
                      (profile.unavailable || !profile.isProviderInQueue ? (
                        <Stack direction='row' spacing='10px' alignItems='center'>
                          <CircleCrossIcon sx={{ color: '#626363', fontSize: '1.6rem' }} />
                          <Typography component='p' variant='body2'>
                            Unavailable
                          </Typography>
                        </Stack>
                      ) : (
                        <Stack direction='row' spacing='10px' alignItems='center'>
                          <HeavyCheckIcon />
                          <Typography component='p' variant='body2'>
                            Available
                          </Typography>
                        </Stack>
                      ))}

                    <Stack direction='row' spacing={'10px'} alignItems='center'>
                      <HospitalIcon className={styles.hospitalIcon} />
                      <Typography component='p' variant='body2'>
                        {profile.organization}
                      </Typography>
                    </Stack>

                    {profile.displayEmail && (
                      <Stack direction='row' spacing={'10px'} alignItems='center'>
                        <EmailIcon className={styles.emailIcon} color='primary' />
                        <Typography component='p' variant='body2'>
                          {profile.email}
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </Hidden>

              <Hidden smUp>
                <Stack direction='column' spacing={'15px'} alignItems='flex-start'>
                  <Stack direction='row' spacing={'15px'}>
                    <Avatar
                      sx={{
                        width: 100,
                        height: 100,
                        flex: 1,
                      }}
                      variant='square'
                      src={avatarUrl}
                    >
                      {profile.firstName}
                    </Avatar>

                    <Stack spacing={'10px'} justifyContent='flex-end'>
                      {!isDismissed &&
                        profile.isProviderInQueue !== undefined &&
                        (profile.unavailable || !profile.isProviderInQueue ? (
                          <Stack direction='row' spacing='11px' alignItems='center'>
                            <CircleCrossIcon sx={{ color: '#626363', fontSize: '1.6rem' }} />
                            <Typography component='p' variant='body2'>
                              Unavailable
                            </Typography>
                          </Stack>
                        ) : (
                          <Stack direction='row' spacing='11px' alignItems='center'>
                            <HeavyCheckIcon />
                            <Typography component='p' variant='body2'>
                              Available
                            </Typography>
                          </Stack>
                        ))}

                      {!isDismissed && (
                        <Button
                          variant='contained'
                          disabled={profile.unavailable || !profile.isProviderInQueue}
                          className={styles.messageBtn}
                          onClick={handleMessageClick}
                        >
                          Message
                        </Button>
                      )}
                    </Stack>
                  </Stack>

                  <Typography component='p' variant='h4'>
                    {profileFullName}
                  </Typography>

                  <Stack direction='row' spacing={'20px'} alignItems='center'>
                    <HospitalIcon className={styles.hospitalIcon} />
                    <Typography component='p' variant='body2'>
                      {profile.organization}
                    </Typography>
                  </Stack>

                  {profile.displayEmail && (
                    <Stack direction='row' spacing={'20px'} alignItems='center'>
                      <EmailIcon className={styles.emailIcon} color='primary' />
                      <Typography component='p' variant='body2'>
                        {profile.email}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </Hidden>
            </Stack>
            <Hidden mdUp smDown>
              <Box className={styles.preview}>
                {!isDismissed && (
                  <VideoPreviewWithControls
                    previewClassName={styles.videoPreview}
                    controlsClassName={styles.videoControls}
                  />
                )}
              </Box>
            </Hidden>
          </Stack>

          <Stack spacing='15px' alignItems='flex-start'>
            <Typography component='pre' variant='body1' whiteSpace='pre-wrap' className={classnames(styles.bio)}>
              {replaceURLsInString(profile.biography || '')}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
