import { useMemo, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

import { FormHeader, HeaderItem } from '../FormHeader';
import { BackButton } from 'components';
import { AcceptPaymentsPanel, OrganizationPanel } from './components';

import styles from './YourOrganization.module.scss';

type OrganizationProps = {
  onBackToSettingsClick(): void;
};

type Tab = 'organizationDetails' | 'acceptPayments';

export function YourOrganizationSettings({ onBackToSettingsClick }: OrganizationProps) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const hash = useLocation().hash;

  const [tab, setTab] = useState<Tab | null>(isMobile ? null : 'organizationDetails');

  const headerItems: HeaderItem[] = useMemo(
    () => [
      {
        text: 'ORGANIZATION DETAILS',
        isSelected: tab === 'organizationDetails',
        onClick: () => setTab('organizationDetails'),
      },
      {
        text: 'ACCEPT PAYMENTS',
        isSelected: tab === 'acceptPayments',
        onClick: () => setTab('acceptPayments'),
      },
    ],
    [tab],
  );

  const getMobileHeaderName = (): string => {
    switch (tab) {
      case 'organizationDetails':
        return 'ORGANIZATION DETAILS';
      case 'acceptPayments':
        return 'ACCEPT PAYMENTS';
      default:
        return 'YOUR ORGANIZATION';
    }
  };

  useEffect(() => {
    if (hash && hash.substring(1) === 'acceptPayments') {
      setTab('acceptPayments');
    } else {
      setTab('organizationDetails');
    }
  }, [hash]);

  const handleOrganizationDetails = (): void => {
    setTab('organizationDetails');
  };

  const handleAcceptPayments = (): void => {
    setTab('acceptPayments');
  };

  const handleBackToOrganization = (): void => {
    setTab(null);
  };

  return (
    <>
      {!tab && isMobile && <BackButton text='Back to Settings' onClick={onBackToSettingsClick} />}
      {!!tab && isMobile && <BackButton text='Back to Your Organization' onClick={handleBackToOrganization} />}

      <div className={styles.container}>
        {isMobile && (
          <>
            <FormHeader items={[{ text: getMobileHeaderName() }]} />

            {!tab && (
              <>
                <Stack className={styles.navigationItem} onClick={handleOrganizationDetails}>
                  <Typography variant='body2'>Organization Details</Typography>
                  <ChevronRight />
                </Stack>
                <Stack className={styles.navigationItem} onClick={handleAcceptPayments}>
                  <Typography variant='body2'>Accept Payments</Typography>
                  <ChevronRight />
                </Stack>
              </>
            )}

            {tab && (
              <div className={styles.main}>
                {tab === 'organizationDetails' && <OrganizationPanel />}
                {tab === 'acceptPayments' && <AcceptPaymentsPanel />}
              </div>
            )}
          </>
        )}

        {!isMobile && (
          <>
            <FormHeader items={headerItems} />
            <div className={styles.main}>
              {tab === 'organizationDetails' && <OrganizationPanel />}
              {tab === 'acceptPayments' && <AcceptPaymentsPanel />}
            </div>
          </>
        )}
      </div>
    </>
  );
}
