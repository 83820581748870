import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function ObjectShapeFlowerIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <mask
        id='mask0_12694_130949'
        // style='mask-type:luminance'
        maskUnits='userSpaceOnUse'
        x='11'
        y='4'
        width='10'
        height='10'
      >
        <path d='M11.6123 4.74316H20.0009V13.1434H11.6123V4.74316Z' />
      </mask>
      <path d='M15.8173 13.1335C18.1282 13.1335 20.0014 11.2602 20.0014 8.94913C20.0014 6.6381 18.1282 4.76465 15.8173 4.76465C13.5066 4.76465 11.6333 6.6381 11.6333 8.94913C11.6333 11.2602 13.5066 13.1335 15.8173 13.1335Z' />
      <path d='M10.0146 8.94895C12.3253 8.94895 14.1986 7.07559 14.1986 4.76456C14.1986 2.45353 12.3253 0.580078 10.0146 0.580078C7.70375 0.580078 5.83057 2.45353 5.83057 4.76456C5.83057 7.07559 7.70375 8.94895 10.0146 8.94895Z' />
      <path d='M4.18403 13.1325C6.49469 13.1325 8.36806 11.2592 8.36806 8.94815C8.36806 6.63713 6.49469 4.76367 4.18403 4.76367C1.87318 4.76367 0 6.63713 0 8.94815C0 11.2592 1.87318 13.1325 4.18403 13.1325Z' />
      <path d='M6.20502 19.4245C8.51578 19.4245 10.3891 17.5512 10.3891 15.2401C10.3891 12.9291 8.51578 11.0557 6.20502 11.0557C3.89418 11.0557 2.021 12.9291 2.021 15.2401C2.021 17.5512 3.89418 19.4245 6.20502 19.4245Z' />
      <path d='M13.7827 19.4245C16.0935 19.4245 17.9667 17.5512 17.9667 15.2401C17.9667 12.9291 16.0935 11.0557 13.7827 11.0557C11.4718 11.0557 9.59863 12.9291 9.59863 15.2401C9.59863 17.5512 11.4718 19.4245 13.7827 19.4245Z' />
      <path d='M10.0013 15.0277C12.3717 15.0277 14.2935 13.1058 14.2935 10.735C14.2935 8.36419 12.3717 6.44238 10.0013 6.44238C7.6308 6.44238 5.70898 8.36419 5.70898 10.735C5.70898 13.1058 7.6308 15.0277 10.0013 15.0277Z' />
    </SvgIcon>
  );
}
