import { useEffect, useState } from 'react';
import { Participant } from 'twilio-video';

export function useParticipantNQ(participant: Participant) {
  const [nq, setNQ] = useState<number>(participant.networkQualityLevel ?? 5);

  useEffect(() => {
    const handleNetworkQualityLevelChanged = (networkQualityLevel: number): void => setNQ(networkQualityLevel);

    participant.on('networkQualityLevelChanged', handleNetworkQualityLevelChanged);
    return () => {
      participant.off('networkQualityLevelChanged', handleNetworkQualityLevelChanged);
    };
  }, [participant]);

  return nq;
}
