import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function WhiteboardStarIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M6.63025 6.60496L7.15035 6.52913L7.38284 6.05775L9.82377 1.1086C9.847 1.06156 9.87301 1.03923 9.89539 1.02601C9.92145 1.01063 9.95687 0.999866 9.99812 1C10.0395 1.00014 10.0765 1.01124 10.1046 1.02791C10.1291 1.04247 10.1548 1.06555 10.1767 1.10955C10.1768 1.10972 10.1769 1.10989 10.177 1.11007L12.6172 6.05775L12.8496 6.52913L13.3697 6.60496L18.8314 7.40122L18.8321 7.40132C18.9903 7.42426 19.0564 7.62273 18.9398 7.73632L18.9395 7.7366L14.9881 11.5871L14.6113 11.9542L14.7004 12.4726L15.6349 17.9111C15.6349 17.9112 15.6349 17.9112 15.6349 17.9112C15.6437 17.9628 15.6358 17.9957 15.6255 18.0192C15.6136 18.0466 15.5913 18.0761 15.5576 18.1003C15.5239 18.1246 15.4872 18.1374 15.4549 18.1403C15.4266 18.1429 15.3933 18.1392 15.3514 18.1172L15.3512 18.1171L10.4653 15.5489L10 15.3044L9.53473 15.5489L4.64875 18.1171L4.64562 18.1188C4.60405 18.1408 4.57156 18.1443 4.54431 18.1418C4.51294 18.139 4.4768 18.1265 4.44307 18.1022C4.40946 18.078 4.38687 18.0483 4.37467 18.0202C4.3642 17.9961 4.35632 17.9627 4.36512 17.9112C4.36512 17.9112 4.36512 17.9112 4.36513 17.9111L5.29959 12.4726L5.38866 11.9542L5.01193 11.5871L1.06053 7.7366L1.06025 7.73632C0.943589 7.62273 1.00971 7.42426 1.16785 7.40132L1.16858 7.40122L6.63025 6.60496Z'
        strokeWidth='2'
        fill='none'
      />
    </SvgIcon>
  );
}
