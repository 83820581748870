import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  LOGIN_SUCCESS_CODE,
  LOGIN_SELECT_CLINIC_CODE,
  LOGIN_VERIFY_PHONE_CODE,
  LOGIN_EMAIL_NOT_CONFIRMED_CODE,
  LOGIN_LICENSE_REQUIRED_CODE,
} from '../../constants';
import { SubscriptionRequiredDialog, VerifyEmailDialog } from 'components';
import { IApiRequests, useApiRequests, useDialog, useUser } from 'hooks';
import { ApiUser, ClinicListItemResponse, LoginRequest, LoginResponse } from 'types';
import { LoginContext } from './context';
import { IUserContext } from 'context';
import { useAppDispatch, useAppSelector } from 'store';
import { clearInvite, InviteData, selectInvite } from 'store/invite';

type LoginProviderProps = {
  children?: JSX.Element;
};

export type LoginModelData = {
  email: string | null;
  password: string | null;
  phone: string | null;
  clinics: ClinicListItemResponse[] | null;
  selectClinicVerificationCode: string | null;
  is2Fa: boolean;
  isForgotPassword: boolean;
  loginResetPasswordData: LoginResetPasswordData | null;
};

export type LoginResetPasswordData = {
  userId: string;
  token: string;
  phone: string;
};

const defaultLoginModelData: LoginModelData = {
  email: null,
  password: null,
  phone: null,
  clinics: null,
  selectClinicVerificationCode: null,
  is2Fa: false,
  isForgotPassword: false,
  loginResetPasswordData: null,
};

export type LoginPageState = 'login' | '2fa' | 'selectClinic' | 'forgotPassword' | 'resetPassword';

export function LoginProvider({ children }: LoginProviderProps) {
  const navigate = useNavigate();
  const { showDialog } = useDialog();
  const dispatch = useAppDispatch();
  const invite: InviteData | null = useAppSelector(selectInvite);

  const { login, loginWithVerificationCode, sendResetPassword, resetPassword, resendConfirmEmail }: IApiRequests =
    useApiRequests();
  const { login: userLogin }: IUserContext = useUser();

  const [loading, setLoading] = useState<boolean>(false);
  const [loginModelData, setLoginModelData] = useState<LoginModelData>(defaultLoginModelData);

  const loginPageState: LoginPageState = useMemo(() => {
    if (loginModelData.loginResetPasswordData) return 'resetPassword';
    if (loginModelData.isForgotPassword) return 'forgotPassword';
    if (loginModelData.clinics) return 'selectClinic';
    if (loginModelData.is2Fa) return '2fa';
    return 'login';
  }, [loginModelData]);

  const navigateDependOnInvitation = useCallback(
    (user: ApiUser): void => {
      if (invite?.userId && user?.id) {
        if (invite!.userId === user!.id) {
          navigate(`/invite?invitationId=${invite.invitationId}?userId=${user.id}`);
          return;
        } else {
          dispatch(clearInvite());
        }
      }

      navigate('/');
    },
    [invite],
  );

  const onLogin = async (model: LoginRequest): Promise<void> => {
    setLoading(true);
    setLoginModelData({ ...defaultLoginModelData });

    try {
      const response: LoginResponse = await login(model);

      switch (response.code) {
        case LOGIN_SUCCESS_CODE:
          userLogin(response).then(navigateDependOnInvitation);
          break;
        case LOGIN_SELECT_CLINIC_CODE:
          setLoginModelData({
            ...defaultLoginModelData,
            email: model.email,
            clinics: response.clinics,
            selectClinicVerificationCode: response.verificationCode!,
          });
          break;
        case LOGIN_VERIFY_PHONE_CODE:
          setLoginModelData({
            ...defaultLoginModelData,
            is2Fa: true,
            email: model.email,
            password: model.password!,
            phone: response.phoneNumber!,
          });
          break;
        case LOGIN_EMAIL_NOT_CONFIRMED_CODE:
          showDialog(VerifyEmailDialog, {
            email: model.email,
            onConfirm: () => resendConfirmEmail(model.email),
          });
          break;
        case LOGIN_LICENSE_REQUIRED_CODE:
          // eslint-disable-next-line no-case-declarations
          const modal: any = showDialog(SubscriptionRequiredDialog, {
            organization: '',
            isProvider: true,
            onConfirm: () => {
              modal.destroy();
            },
          });
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  const on2FaLogin = async (code: string): Promise<void> => {
    setLoading(true);

    try {
      const response: LoginResponse = await login({
        email: loginModelData.email!,
        password: loginModelData.password!,
        twoFactorCode: code,
      });

      switch (response.code) {
        case LOGIN_SUCCESS_CODE:
          userLogin(response).then(navigateDependOnInvitation);
          break;
        case LOGIN_SELECT_CLINIC_CODE:
          setLoginModelData({
            ...defaultLoginModelData,
            email: loginModelData.email,
            clinics: response.clinics,
            selectClinicVerificationCode: response.verificationCode!,
          });
          break;
        case LOGIN_LICENSE_REQUIRED_CODE:
          // eslint-disable-next-line no-case-declarations
          const modal: any = showDialog(SubscriptionRequiredDialog, {
            organization: '',
            isProvider: true,
            onConfirm: () => {
              modal.destroy();
            },
          });
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  const on2faLoginResendCode = async (): Promise<void> => {
    setLoading(true);

    try {
      await login({ email: loginModelData.email!, password: loginModelData.password! });
    } finally {
      setLoading(false);
    }
  };

  const onSelectClinic = async (clinicId: string): Promise<void> => {
    setLoading(true);

    try {
      const response: LoginResponse = await loginWithVerificationCode({
        email: loginModelData.email!,
        verificationCode: loginModelData.selectClinicVerificationCode!,
        clinicId: clinicId,
      });

      switch (response.code) {
        case LOGIN_SUCCESS_CODE:
          userLogin(response).then(navigateDependOnInvitation);
          break;
        case LOGIN_LICENSE_REQUIRED_CODE:
          // eslint-disable-next-line no-case-declarations
          const modal: any = showDialog(SubscriptionRequiredDialog, {
            organization: loginModelData.clinics?.find((clinic: ClinicListItemResponse) => clinic.clinicId === clinicId)
              ?.organization,
            isProvider: true,
            onConfirm: () => {
              modal.destroy();
            },
          });
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  const onForgotPassword = (): void => {
    setLoginModelData({ ...defaultLoginModelData, isForgotPassword: true });
  };

  const onSendResetPassword = (email: string): Promise<void> => {
    setLoading(true);

    return sendResetPassword(email)
      .then(() => Promise.resolve())
      .finally(() => {
        setLoading(false);
      });
  };

  const onResetPasswordEmailOpen = (userId: string, token: string, phone: string): void => {
    setLoginModelData({ ...defaultLoginModelData, loginResetPasswordData: { userId, token, phone } });
  };

  // temporary hidden
  // const onResetPasswordFormSendVarificationCode = (twoFactor: TwoFactor): Promise<void> => {
  //   setLoading(true);

  //   return sendCodeToPhone(twoFactor).finally(() => {
  //     setLoading(false);
  //   });
  // };

  // const onResetPasswordFormSubmitCode = (code: string, token: string): Promise<boolean> => {
  //   setLoading(true);

  //   return preVerifyPhoneCode(loginModelData.loginResetPasswordData!.phone, code, token).finally(() => {
  //     setLoading(false);
  //   });
  // };

  const onResetPassword = (password: string, passwordConfirmation: string): Promise<void> => {
    setLoading(true);

    return resetPassword({
      password,
      passwordConfirmation,
      userId: loginModelData.loginResetPasswordData!.userId,
      emailToken: loginModelData.loginResetPasswordData!.token,
    }).finally(() => {
      setLoading(false);
    });
  };

  const onResetPasswordCancel = (): void => {
    setLoginModelData(defaultLoginModelData);
  };

  return (
    <LoginContext.Provider
      value={{
        loading,
        loginModelData,
        loginPageState,
        onLogin,
        on2FaLogin,
        on2faLoginResendCode,
        onSelectClinic,
        onForgotPassword,
        onSendResetPassword,
        onResetPasswordEmailOpen,
        onResetPassword,
        onResetPasswordCancel,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
}
