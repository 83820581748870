import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Theme, useMediaQuery } from '@mui/material';

import { useRegistration } from 'hooks';
import { LeftSideType, RegisterLayout, Spinner } from 'components';
import { CreateRoomForm, LeftPromocodeContainer, RegisterForm, SelectPlanForm } from './components';
import { IRegistrationContext } from 'context';

import styles from './RegisterPage.module.scss';

export function RegisterPage() {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { loading, stage, isCoupon, setCoupon }: IRegistrationContext = useRegistration();

  const { search } = useLocation();
  const promocode: string | null = new URLSearchParams(search).get('promocode');

  const headers: string[] = useMemo(() => {
    return isCoupon ? ['Profile Info', 'Create Room', 'Redeem Offer'] : ['Profile Info', 'Create Room', 'Get Started'];
  }, [isCoupon]);

  const leftSideType: LeftSideType = useMemo(() => {
    if (isCoupon && stage === 1) {
      return 'promo';
    }

    return '30DaysFree';
  }, [isCoupon, stage]);

  const hideLeftSide: boolean = useMemo(() => {
    return isMobile && stage !== 1;
  }, [isMobile, stage]);

  useEffect(() => {
    if (promocode) {
      setCoupon(promocode);
    }
  }, [search]);

  return (
    <RegisterLayout
      leftSideType={leftSideType}
      headers={headers}
      selectedHeader={stage}
      leftSide={isCoupon ? <LeftPromocodeContainer /> : null}
      hideLeftSide={hideLeftSide}
    >
      <>
        <div className={styles.registerContainer}>
          {loading && <Spinner small />}
          {stage === 1 && <RegisterForm />}
          {stage === 2 && <CreateRoomForm />}
          {stage === 3 && <SelectPlanForm />}
        </div>
      </>
    </RegisterLayout>
  );
}
