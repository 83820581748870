import React from 'react';
import { LocalAudioTrack } from 'twilio-video';
import { MenuItem, Select } from '@mui/material';
import { ChevronRightIcon } from 'assets';
import { useDevices, useLocalStorageState, useMediaStreamTrack, useVideo } from 'hooks';

import { SELECTED_AUDIO_INPUT_KEY } from '../../../constants';

export function AudioInputList() {
  const { audioInputDevices } = useDevices();
  const { localTracks } = useVideo();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSelectedAudioInputDevice] = useLocalStorageState<string>(SELECTED_AUDIO_INPUT_KEY, 'default');

  const localAudioTrack = localTracks.find((track) => track.kind === 'audio') as LocalAudioTrack;
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

  function replaceTrack(newDeviceId: string) {
    setSelectedAudioInputDevice(newDeviceId);
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
  }

  const isDisabled = audioInputDevices.length <= 1;

  return (
    <>
      <Select
        fullWidth
        variant='outlined'
        disabled={isDisabled}
        IconComponent={ChevronRightIcon}
        value={!isDisabled ? localAudioInputDeviceId || '' : 'default'}
        onChange={(e) => replaceTrack(e.target.value as string)}
        sx={{
          svg: {
            width: '10px',
          },
        }}
      >
        {!isDisabled ? (
          audioInputDevices.map((device) => (
            <MenuItem value={device.deviceId} key={device.deviceId}>
              {device.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem value='default'>{localAudioTrack?.mediaStreamTrack.label || 'No Local Audio'}</MenuItem>
        )}
      </Select>
    </>
  );
}
