import { MutableRefObject, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Theme, Typography, useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';

import { CheckCircleIcon } from 'assets';
import {
  SUCCESS_OPERATION_ACTION_TYPE_CARD_CHANGE,
  SUCCESS_OPERATION_ACTION_TYPE_SUBSCRIPTION_CHANGE,
} from 'constants/payment';
import { SuccessInfoDialog } from 'components';
import { useApiRequests, useDialog, useTrackingAnalytics } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store';
import { getUser, selectUser } from 'store/user';

import styles from './SuccessPaymentPage.module.scss';
import { ApiUser, TrackingEventsEnum } from 'types';

type ActionData = {
  title: string;
  buttonText: string;
  bodyText: string;
};

export function SuccessPaymentPage() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { showDialog } = useDialog();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const trackingAnalyticsContext = useTrackingAnalytics();
  const { enqueueSnackbar } = useSnackbar();
  const { postCheckoutSubscriptionUpdate } = useApiRequests();
  const user: ApiUser | null = useAppSelector(selectUser);

  const postChackputStarted: MutableRefObject<boolean> = useRef<boolean>(false);

  const { search } = useLocation();
  const type: string | null = new URLSearchParams(search).get('type');
  const redirectTo: string | null = new URLSearchParams(search).get('redirectTo');
  const redirectToHash: string | null = new URLSearchParams(search).get('redirectToHash');
  const hostedpageId: string | null = new URLSearchParams(search).get('hostedpage_id');
  const navigateTo = `/${redirectTo ?? ''}${redirectToHash ? '#' + redirectToHash! : ''}`;

  const actionData: ActionData = useMemo(() => {
    switch (type) {
      case SUCCESS_OPERATION_ACTION_TYPE_CARD_CHANGE:
        return {
          title: 'Payment method changed!',
          bodyText: 'Your payment method is changed.',
          buttonText: 'Ok',
        };
      case SUCCESS_OPERATION_ACTION_TYPE_SUBSCRIPTION_CHANGE:
      default:
        return {
          title: 'Payment successful!',
          bodyText: 'Your account is now active and\nready for use.',
          buttonText: 'Get started',
        };
    }
  }, [type]);

  useEffect(() => {
    if (!user || postChackputStarted.current) {
      return;
    }

    postChackputStarted.current = true;

    const postCheckoutCallback = (): Promise<void> => {
      if (!hostedpageId) {
        return Promise.resolve();
      }

      return postCheckoutSubscriptionUpdate(hostedpageId ?? '').then((data) => {
        const amount = data.subscription?.amount ?? 0;
        // const intervalUnit = data.subscription?.intervalUnit === 'Months' ? 12 : 1;

        trackingAnalyticsContext.trackEvent(TrackingEventsEnum.CompleteCheckout, {
          email: user.email,
          value: amount,
          currency: 'USD',
          // predicted_ltv: amount * intervalUnit,
        });
      });
    };

    postCheckoutCallback()
      .then(() => {
        return dispatch(getUser()).unwrap();
      })
      .then(() => {
        if (isMobile) {
          return;
        }

        const modal = showDialog(SuccessInfoDialog, {
          buttonText: actionData.buttonText,
          title: actionData.title,
          body: (
            <>
              <Typography component='p' variant='body5'>
                {actionData.bodyText}
              </Typography>
              <Typography component='p' variant='body5'>
                Thank you for choosing HIPAA LINK.
              </Typography>
            </>
          ),
          onConfirm: () => {
            modal.destroy();
          },
        });
        navigate(navigateTo);
      })
      .catch((error) => {
        enqueueSnackbar('postCheckoutSubscriptionUpdate error:' + error.message, { variant: 'error' });
      });
  }, [user]);

  const handleGetStarted = (): void => {
    navigate(navigateTo);
  };

  if (!isMobile) return null;

  return (
    <Box className={styles.container}>
      <CheckCircleIcon className={styles.icon} color='primary' />

      <Typography component='p' variant='body4'>
        {actionData.title}
      </Typography>

      <Typography component='p' variant='body2'>
        {actionData.bodyText}
      </Typography>

      <Typography component='p' variant='body2'>
        Thank you for choosing HIPAA LINK.
      </Typography>

      <Button variant='contained' onClick={handleGetStarted} className={styles.formButton}>
        {actionData.buttonText}
      </Button>
    </Box>
  );
}
