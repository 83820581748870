/* eslint-disable prettier/prettier */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Theme, useMediaQuery } from '@mui/material';

import {
  AccountSettings,
  LeftMenu,
  ProfileSettings,
  StaffSettings,
  AINotesSettings,
  SubscriptionSettings,
  YourOrganizationSettings,
} from './components';
import { useSubscription } from 'hooks';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { getIsAdminByRoles } from 'utils';

import styles from './SettingsPage.module.scss';
import { CancellationConfirmationPopupProvider } from './components/SubscriptionSettings/components/CancellationConfirmation/CancellationConfirmationPopupProvider';

export type MenuState =
  | 'profile'
  | 'accountSettings'
  | 'yourOrganization'
  | 'acceptPayments'
  | 'staff'
  | 'aiNotes'
  | 'subscriptions';

export function SettingsPage() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const subscription = useSubscription();
  const user = useAppSelector(selectUser);

  const hash = useLocation().hash;
  const [menuState, _setMenuState] = useState<MenuState | null>(hash ? (hash.substring(1) as MenuState) : null);

  const isAdmin = useMemo<boolean>(() => {
    return getIsAdminByRoles(user?.clinicUser.roles || []);
  }, [user]);

  // commented temporary
  // const isExpired = useMemo<boolean>(() => {
  //   return (
  //     subscription?.subscriptionStatus === SubscriptionStatusEnum.Expired ||
  //     subscription?.subscriptionStatus === SubscriptionStatusEnum.TrialExpired
  //   );
  // }, [user]);

  const setMenuState = useCallback(
    (state: MenuState | null): void => {
      if (hash !== state) {
        navigate('#' + state);
      }
      _setMenuState(state);
    },
    [hash],
  );

  // commented temporary
  // useEffect(() => {
  //   if (!isMobile) {
  //     if (isAdmin && subscription?.hasSubscription && isExpired) {
  //       const confirmationProps = {
  //         title: 'Subscription Expired',
  //         message: 'Upgrade now to continue using HIPAA LINK.',
  //       };
  //       confirm({
  //         content: ConfirmationDialog,
  //         props: confirmationProps,
  //       }).then(() => setMenuState('subscriptions'));
  //       return;
  //     }
  //   }
  // }, []);

  useEffect(() => {
    _setMenuState(hash ? (hash.substring(1) as any) : null);
  }, [hash]);

  useEffect(() => {
    if (!isMobile && !menuState) {
      setMenuState('profile');
    }
  }, [menuState]);

  const handleMenuStateChange = (state: MenuState | null): void => {
    if (isAdmin && subscription?.hasSubscription && subscription?.isSubscriptionActive) {
      setMenuState(state);
      return;
    }

    // commented temporary
    // const isAllowedMenuItem = state === 'yourOrganization' || state === 'staff' || state === 'subscriptions';
    // if (isAdmin && !isAllowedMenuItem && subscription?.hasSubscription && !subscription?.isSubscriptionActive) {
    //   setMenuState(state);
    //   const confirmationProps = {
    //     title: 'Subscription Expired',
    //     message: 'Upgrade now to continue using HIPAA LINK.',
    //   };
    //   confirm({ content: ConfirmationDialog, props: confirmationProps }).then(() => setMenuState('subscriptions'));
    //   return;
    // }

    // if (isAdmin && isAllowedMenuItem && subscription?.hasSubscription && !subscription?.isSubscriptionActive) {
    //   setMenuState(state);
    //   return;
    // }

    if (isAdmin && !subscription?.hasSubscription) {
      // TODO: what need to do in this case?
      // For now
      setMenuState(state);
      return;
    }

    setMenuState(state);
  };

  const handleBackToSettingsClick = (): void => {
    navigate('/settings');
  };

  return (
    <div className={styles.settingsPage}>
      {(!isMobile || !menuState) && <LeftMenu menuState={menuState} onMenuStateChange={handleMenuStateChange} />}

      {(!isMobile || !!menuState) && (
        <div className={styles.main}>
          {menuState === 'profile' && <ProfileSettings onBackToSettingsClick={handleBackToSettingsClick} />}
          {menuState === 'accountSettings' && <AccountSettings onBackToSettingsClick={handleBackToSettingsClick} />}
          {(menuState === 'yourOrganization' || menuState === 'acceptPayments') && (
            <YourOrganizationSettings onBackToSettingsClick={handleBackToSettingsClick} />
          )}
          {menuState === 'staff' && <StaffSettings onBackToSettingsClick={handleBackToSettingsClick} />}
          {menuState === 'aiNotes' && <AINotesSettings onBackToSettingsClick={handleBackToSettingsClick} />}
          {menuState === 'subscriptions' && (
            <CancellationConfirmationPopupProvider>
              <SubscriptionSettings onBackToSettingsClick={handleBackToSettingsClick} />
            </CancellationConfirmationPopupProvider>
          )}
        </div>
      )}
    </div>
  );
}
