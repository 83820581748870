import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function RactangleIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M2.56864 1.42188H18.2829C18.9137 1.42188 19.4258 1.93398 19.4258 2.56473V18.279C19.4258 18.9098 18.9137 19.4219 18.2829 19.4219H2.56864C1.93789 19.4219 1.42578 18.9098 1.42578 18.279V2.56473C1.42578 1.93398 1.93789 1.42188 2.56864 1.42188Z'
        strokeWidth='2'
        fill='none'
      />
    </SvgIcon>
  );
}
