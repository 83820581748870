import { useCallback, useMemo, useRef } from 'react';
import { Box, IconButton, styled, Typography } from '@mui/material';
import classnames from 'classnames';

import { useDevices, useLocalVideoToggle } from 'hooks';
import { VideoCameraIcon, VideoCameraOffIcon } from 'assets';
import { ControlTooltip } from 'components';

import styles from './ToggleVideoButton.module.scss';

type ToggleVideoButtonProps = {
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
  withTooltip?: boolean;
  withLabel?: boolean;
};

const VideoIcon = styled(VideoCameraIcon)(({ theme }) => ({
  color: '#fff',
}));

const VideoOffIcon = styled(VideoCameraOffIcon)(({ theme }) => ({
  color: '#fff',
}));

export function ToggleVideoButton({
  disabled,
  className,
  iconClassName,
  withTooltip,
  withLabel,
}: ToggleVideoButtonProps) {
  const { videoEnabled, toggleVideoEnabled } = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  const tooltipMessage = useMemo(() => {
    let tooltipMessage = '';

    if (withTooltip) {
      tooltipMessage = 'Camera';
    }

    return tooltipMessage;
  }, [withTooltip, videoEnabled]);

  const sx = useMemo(
    () => ({
      backgroundColor: `${videoEnabled ? 'rgb(0 0 0 / 50%)' : '#FF3D3D'} !important`,
      '&:hover': {
        backgroundColor: videoEnabled ? 'rgb(0 0 0 / 50%)' : '#FF3D3D',
      },
    }),
    [videoEnabled],
  );

  return (
    <ControlTooltip title={tooltipMessage} isDisabled={!videoEnabled}>
      <Box component='span' display='block'>
        <IconButton
          disabled={!hasVideoInputDevices || disabled}
          className={classnames(
            { [styles.deviceButton]: !withLabel, [styles.deviceButtonWithLabel]: withLabel },
            className,
          )}
          sx={!withLabel ? sx : {}}
          onClick={toggleVideo}
          edge='end'
          aria-label='notification'
        >
          {videoEnabled ? (
            <VideoIcon className={classnames(styles.icon, iconClassName)} sx={withLabel ? { color: '#3EB157' } : {}} />
          ) : (
            <VideoOffIcon
              className={classnames(styles.icon, iconClassName)}
              sx={withLabel ? { color: '#FF3D3D' } : {}}
            />
          )}
          {withLabel && (
            <Typography component='p' variant='body2' sx={{ color: '#393534' }} ml='10px'>
              Camera
            </Typography>
          )}
        </IconButton>
      </Box>
    </ControlTooltip>
  );
}
