import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function ZoomIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.71777 0C14.6883 0 18.7178 4.02944 18.7178 9C18.7178 11.1248 17.9815 13.0776 16.7501 14.6172L20.4249 18.2929C20.8154 18.6834 20.8154 19.3166 20.4249 19.7071C20.0644 20.0676 19.4972 20.0953 19.1049 19.7903L19.0107 19.7071L15.3349 16.0323C13.7954 17.2637 11.8426 18 9.71777 18C4.74721 18 0.717773 13.9706 0.717773 9C0.717773 4.02944 4.74721 0 9.71777 0ZM9.71777 2C5.85178 2 2.71777 5.13401 2.71777 9C2.71777 12.866 5.85178 16 9.71777 16C13.5838 16 16.7178 12.866 16.7178 9C16.7178 5.13401 13.5838 2 9.71777 2ZM9.71777 5C10.2701 5 10.7178 5.44772 10.7178 6V8H12.7178C13.2701 8 13.7178 8.44772 13.7178 9C13.7178 9.55228 13.2701 10 12.7178 10H10.7178V12C10.7178 12.5523 10.2701 13 9.71777 13C9.16549 13 8.71777 12.5523 8.71777 12V10H6.71777C6.16549 10 5.71777 9.55228 5.71777 9C5.71777 8.44772 6.16549 8 6.71777 8H8.71777V6C8.71777 5.44772 9.16549 5 9.71777 5Z'
      />
    </SvgIcon>
  );
}
