import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useUser } from 'hooks';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { IUserContext } from 'context';

import { Spinner } from '../Spinner';

export function RequireNotAuth() {
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const { isLoading }: IUserContext = useUser();

  useEffect(() => {
    if (user && !isLoading) {
      navigate('../');
    }
  }, []);

  if (isLoading) return <Spinner />;
  return <Outlet />;
}
