import { createContext } from 'react';
import { Conversation } from '@twilio/conversations';
import { ConversationUnreadMessagesData } from './hooks';

export interface IChatContext {
  conversation: Conversation | null;
  roomConversation: Conversation | null;
  identity: string;
  isConnected: boolean;
  allConversations: Conversation[];
  conversationsUnreadMessagesData: Map<string, ConversationUnreadMessagesData>;
  lastUpdatedChat: Conversation | null;
  openChat(staffId: string, patientId: string): void;
  openChatByUniqueName(uniqueName: string): void;
  openRoomChat(roomName: string): void;
  addPatientToRoomChat(patientId: string, roomName: string): void;
  closeChat(): void;
  closeRoomChat(): void;
  sendMessage(text: string): void;
  sendMessageToRoomChat(text: string): void;
}

export const ChatContext = createContext<IChatContext | null>(null);
export const ChatConsumer = ChatContext.Consumer;
