import React, { useEffect, useRef, useState } from 'react';
import { Box, ClickAwayListener, Popper } from '@mui/material';
import { ToggleDotMenuButton, Transitions } from 'components';

import styles from './DotMenu.module.scss';

type DotMenuProps = {
  children?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
  popperClassName?: string;
  contentBgColor?: string;
  withTooltip?: boolean;
};

export function DotMenu(props: DotMenuProps) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const prevOpen = useRef(open);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current && anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <ToggleDotMenuButton
        onClick={handleToggle}
        ref={anchorRef}
        disabled={props.disabled}
        className={props.className}
        iconClassName={props.iconClassName}
        iconColor='#FFF'
        withTooltip={!open && props.withTooltip}
      />

      <Popper
        placement='top'
        className={props.popperClassName}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              enabled: true,
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box
                onClick={() => setOpen(false)}
                className={styles.menu}
                sx={{ backgroundColor: props.contentBgColor || 'rgba(0, 0, 0, 0.5);' }}
              >
                {props.children}
              </Box>
            </ClickAwayListener>
          </Transitions>
        )}
      </Popper>
    </>
  );
}
