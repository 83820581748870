import { useCallback } from 'react';
import { Howl } from 'howler';

import { useEMDR } from 'hooks';
import { IEMDRContext } from 'context';

export type AudioType = {
  playSound(newDuration: number, newDirection: number): void;
  stopSound(): void;
};

export function useAudio(): AudioType {
  const { settings, audiosRef }: IEMDRContext = useEMDR();

  const playSound = useCallback(
    (newDuration: number, newDirection: number) => {
      Object.values(audiosRef.current).forEach((sound) => {
        if (sound && sound instanceof Howl) {
          sound.stop();
        }
      });

      const bufferKey = (newDirection === 0 ? 'left' : 'right') + settings.sound;
      const sound = audiosRef.current[bufferKey];
      if (sound && sound instanceof Howl) {
        sound.fade(0, 1, 100);
        sound.fade(1, 0, newDuration - 100);
        sound.play();
        sound.on('end', () => {
          sound.stop();
        });
      }
    },
    [settings.sound],
  );

  const stopSound = useCallback((): void => {
    Object.values(audiosRef.current).forEach((sound) => {
      if (sound && sound instanceof Howl) {
        sound.stop();
      }
    });
  }, []);

  return { playSound, stopSound };
}
