import { Button, DialogContent, Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';

import { noop } from 'utils';

import styles from './SubscriptionRequiredDialog.module.scss';

export interface SubscriptionRequiredDialogProps extends DialogProps {
  isProvider: boolean;
  isCancelled?: boolean;
  organization?: string;
  onConfirm: () => void;
}

export function SubscriptionRequiredDialog({
  onConfirm,
  isProvider,
  isCancelled = false,
  organization,
  ...rest
}: SubscriptionRequiredDialogProps) {
  return (
    <Dialog {...rest} className={styles.dialog} onClose={noop}>
      <DialogContent>
        <Typography component='h4' variant='h4' color='#FF3D3D'>
          {isProvider || isCancelled ? 'Subscription Required' : 'Subscription Expired'}
        </Typography>

        <Typography component='p' variant='body2'>
          {isProvider
            ? `You do not have a seat${
                organization ? ' with ' + organization : ''
              }. Please contact your administrator for more information.`
            : 'Please upgrade to continue with HIPAA LINK.'}
        </Typography>

        <Button variant='contained' onClick={onConfirm} className={styles.formButton}>
          Ok
        </Button>
      </DialogContent>
    </Dialog>
  );
}
