/* eslint-disable prettier/prettier */

import { ComponentType, MutableRefObject, useCallback, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { v4 as uuidv4 } from 'uuid';

import { withOutsideClickPreventer } from 'utils';
import { ConfirmDialogArgs, ConfirmResultEnum, DialogContext, ShowDialog } from './context';

type DialogProviderProps = {
  children?: JSX.Element;
};

type AppDialog = {
  component: ComponentType<any>;
  props?: Record<string, any>;
};

export function DialogProvider({ children }: DialogProviderProps) {
  const portalElement: HTMLElement | null = document.getElementById('portal-dialog');
  const resolveConfirmRef: MutableRefObject<any> = useRef<any>(null);

  const [dialogs, setDialogs] = useState<Map<string, AppDialog>>(new Map());

  const showDialog = useCallback<ShowDialog>((component, props) => {
    const id = uuidv4();

    const newDialogs = new Map(dialogs);
    newDialogs.set(id, {
      component,
      props: { ...props, open: true },
    });
    setDialogs(newDialogs);

    return {
      destroy: () => destroy(id),
    };
  }, []);

  const destroy = useCallback((key: string, confirmResult: ConfirmResultEnum = ConfirmResultEnum.Close) => {
    const newDialogs = new Map(dialogs);
    newDialogs.delete(key);
    setDialogs(newDialogs);
    if (resolveConfirmRef?.current) {
      resolveConfirmRef.current(confirmResult);
    }
  }, []);

  const confirm = useCallback<(args: ConfirmDialogArgs) => Promise<ConfirmResultEnum>>((args) => {
    const id = uuidv4();
    const newDialogs = new Map(dialogs);

    newDialogs.set(id, {
      component: args.content,
      props: {
        ...args.props,
        open: true,
        onConfirm: (confirmResult: ConfirmResultEnum) => destroy(id, confirmResult),
      },
    });

    setDialogs(newDialogs);

    return new Promise((result) => {
      resolveConfirmRef.current = result;
    });
  }, []);

  const render = () =>
    [...dialogs.entries()].map(([key, { component: Component, props }]) => {
      const handleClose = (...args: any[]) => {
        destroy(key);

        if (props && props.onClose) {
          props.onClose(...args);
        }
      };

      return <Component {...props} key={key} onClose={withOutsideClickPreventer(handleClose)} />;
    });

  return (
    <DialogContext.Provider
      value={{
        showDialog,
        confirm,
      }}
    >
      {children}
      {createPortal(render(), portalElement!)}
    </DialogContext.Provider>
  );
}
