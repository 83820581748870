import { useRef, useMemo } from 'react';
import UAParser from 'ua-parser-js';

export function useUserAgentInfo() {
  const parser = useRef<UAParser>(new UAParser());

  const { version: browserVersion, name: browserName } = parser.current.getBrowser();
  const { version: osVersion, name: osName } = parser.current.getOS();
  const { type: deviceType, vendor: deviceVendor, model: deviceModel } = parser.current.getDevice();
  const type = useMemo(
    () => (deviceType ? [deviceType, deviceVendor, deviceModel].filter((t) => t && t).join(' ') : 'Laptop/Desktop'),
    [deviceType, deviceVendor, deviceModel],
  );
  const os = useMemo(() => [osName, osVersion].filter((t) => t && t).join(' '), [osName, osVersion]);
  const browser = useMemo(() => {
    return [browserName, browserVersion].filter((t) => t && t).join(' ');
  }, [browserName, browserVersion]);

  return { type, os, browser };
}
