import React from 'react';
import { Button, DialogContent, DialogTitle, Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';

import { ITimer, useTimer } from 'hooks';

import styles from './VerifyEmailDialog.module.scss';

const RESEND_TIMER_DURATION = 60;

export interface VerifyEmailDialogProps extends DialogProps {
  email: string;
  initialTimerValue?: number;
  onConfirm: () => Promise<void>;
}

export function VerifyEmailDialog({ onConfirm, email, initialTimerValue, onClose, ...rest }: VerifyEmailDialogProps) {
  const { seconds, startTimer }: ITimer = useTimer(initialTimerValue ?? 0);

  const handleResend = (): void => {
    startTimer(RESEND_TIMER_DURATION);
    onConfirm();
  };

  return (
    <Dialog {...rest} className={styles.dialog}>
      <DialogContent>
        <Typography component='h4' variant='h4'>
          Verify your email!
        </Typography>

        <Typography component='p' variant='body2'>
          In order to be HIPAA Compliant, we need to verify your email.
          <br />
          We sent an email to: <b>{email}</b>
        </Typography>

        <Typography component='p' variant='body2'>
          Just click on the link to complete your verification.
          <br />
          If you don’t see it, you may need to check your spam folder.
        </Typography>

        <Button variant='contained' onClick={handleResend} className={styles.formButton} disabled={!!seconds}>
          {`Resend link${seconds ? ` (${seconds})` : ''}`}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
