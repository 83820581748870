import { Box, CircularProgress } from '@mui/material';
import classNames from 'classnames';

import styles from './Spinner.module.scss';

type SpinnerPropsType = {
  spinnerClassName?: string;
  fixed?: boolean;
  small?: boolean;
};

export default function Spinner(props: SpinnerPropsType): JSX.Element {
  const { fixed = false, small = false, spinnerClassName } = props;
  return (
    <Box className={classNames(styles.root, { [styles.spinnerFixed]: fixed, [styles.small]: small })}>
      <CircularProgress className={classNames(styles.spinner, spinnerClassName)} />
    </Box>
  );
}
