import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { HubConnectionBuilder, HubConnection } from '@microsoft/signalr';

import { useAppDispatch, useAppSelector } from 'store';
import { getUser, selectUser } from 'store/user';
import { selectWaitingRoomProfile } from 'store/waitingRoom';

import {
  IApiRequests,
  useApiRequests,
  useShowForcedRotatePhoneDialog,
  useShowMobileLandscapeWarningDialog,
  useSignalrConnection,
  useVideo,
  useEMDRImagesAndAudioPreload,
} from 'hooks';
import { deserializeSettings, deserializeSettingsPresets, serializeSettings, serializeSettingsPresets } from 'utils';
import {
  ApiUser,
  ApiWaitingRoomLinkProfile,
  DurationTypeEnum,
  EMDRCounterSettings,
  EMDRSettings,
  MovementEnum,
  ShapeEnum,
} from 'types';
import { ISignalrConnectionContext } from 'context';
import { EMDRContext, EMDRState } from './context';

type EMDRProviderProps = {
  children?: JSX.Element;
};

const soundNames: string[] = ['Ding', 'Light bell', 'Soft chime', 'Pop echo', 'Beep', 'Click', 'Sustained'];

const defaultEMDRSettings: EMDRSettings = {
  backgroundColor: '000000',
  objectColor: '42E2FF',
  movement: MovementEnum.LeftToRight,
  shape: ShapeEnum.Circle,
  size: 3,
  sound: null,
  speed: 50,
  counterType: DurationTypeEnum.Manual,
};

const defaultEMDRCounterSettings: EMDRCounterSettings = {
  type: DurationTypeEnum.Manual,
  passes: 0,
  time: 0,
};

export function EMDRProvider({ children }: EMDRProviderProps) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    startEmdr: startEmdrQueueHub,
    addEventListener,
    removeEventListener,
  }: ISignalrConnectionContext = useSignalrConnection();
  const { isSharingScreen, toggleScreenShare, room } = useVideo();
  const { updateUser }: IApiRequests = useApiRequests();
  const dispatch = useAppDispatch();

  const showMobileLandscapeWarningDialog = useShowMobileLandscapeWarningDialog();
  const showForcedRotatePhoneDialog = useShowForcedRotatePhoneDialog();

  const user: ApiUser | null = useAppSelector(selectUser);
  const waitingRoomProfile: ApiWaitingRoomLinkProfile | null = useAppSelector(selectWaitingRoomProfile);

  const connectionToEMDRHub = useRef<HubConnection | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [isPlay, setIsPlay] = useState<boolean>(false);
  const [isStarted, setIsStarted] = useState<boolean>(false);
  const [presets, setPresets] = useState<EMDRSettings[]>([
    defaultEMDRSettings,
    defaultEMDRSettings,
    defaultEMDRSettings,
  ]);
  const [emdrSettings, setEmdrSettings] = useState<EMDRSettings>(defaultEMDRSettings);
  const [emdrCounterSettings, setEmdrCounterSettings] = useState<EMDRCounterSettings>(defaultEMDRCounterSettings);
  const [showDurationCountdown, setShowDurationCountdown] = useState<boolean>(true);

  const emdrState: EMDRState = useMemo(() => {
    if (!connectionToEMDRHub.current) {
      return 'off';
    }

    if (!isStarted) {
      return 'off';
    }

    if (loading) {
      return 'loading';
    }

    return isPlay ? 'play' : 'pause';
  }, [loading, connectionToEMDRHub, isPlay, isStarted]);

  const { imagesRef, audiosRef, isImgPresetsLoaded } = useEMDRImagesAndAudioPreload({ state: emdrState });

  const listenEmdrHub = (hubConnection: HubConnection) => {
    if (hubConnection) {
      hubConnection.on('played', function () {
        setIsPlay(true);
      });

      hubConnection.on('paused', function () {
        setIsPlay(false);
      });

      hubConnection.on('settingsUpdated', function (settings: string) {
        setEmdrSettings(deserializeSettings(settings) ?? defaultEMDRSettings);
      });

      hubConnection.on('stoped', function () {
        setIsPlay(false);
        setIsStarted(false);
        disconnect();

        if (!user) {
          showForcedRotatePhoneDialog(false);
        }
      });
    }
  };

  const connectToEMDRHub = useCallback((): Promise<void> => {
    if (connectionToEMDRHub.current) {
      return Promise.resolve();
    }

    const queryString = {
      clinicUserId: user?.clinicUser.id ?? waitingRoomProfile!.providerId,
    };
    const urlParams: string = new URLSearchParams(queryString).toString();

    const hubConnection: HubConnection = new HubConnectionBuilder()
      .withUrl(`${process.env['REACT_APP_HIPAA_LINK_API_URI']}emdr?${urlParams}`)
      .build();

    listenEmdrHub(hubConnection);

    hubConnection.onclose(() => {
      disconnect();
    });

    return hubConnection
      .start()
      .then(() => {
        connectionToEMDRHub.current = hubConnection;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        throw error;
      });
  }, [user, waitingRoomProfile, connectionToEMDRHub, listenEmdrHub]);

  const onEmdrStarted = useCallback((): void => {
    setLoading(true);

    if (!user) {
      showForcedRotatePhoneDialog(true);
    }

    connectToEMDRHub()
      .then(() => {
        setIsStarted(true);

        if (user) {
          if (room) {
            showMobileLandscapeWarningDialog(room?.sid);
          }

          // timeout because safari client do not receive first updateSettings event
          // possible fix on BE: add settingsModel data for play method and Played event
          setTimeout(() => {
            connectionToEMDRHub.current
              ?.invoke('updateSettings', serializeSettings(presets[0]))
              // eslint-disable-next-line no-console
              .catch((e) => console.error(e));
          }, 1000);
          return (
            connectionToEMDRHub.current
              ?.invoke('updateSettings', serializeSettings(presets[0]))
              // eslint-disable-next-line no-console
              .catch((e) => console.error(e))
          );
        }
      })
      .catch((error) => {
        enqueueSnackbar(`connectToEMDRHub error: ${error.message}`, { variant: 'error' });
      })
      .finally(() => setLoading(false));
  }, [connectToEMDRHub, showMobileLandscapeWarningDialog]);

  const disconnect = useCallback((): void => {
    connectionToEMDRHub.current?.stop();
    connectionToEMDRHub.current = null;
  }, []);

  const play = (): void => {
    if (user) {
      connectionToEMDRHub.current?.invoke('play');
    }
  };

  const pause = (): void => {
    if (user) {
      connectionToEMDRHub.current?.invoke('pause');
    }
  };

  const changeSettings = (settings: EMDRSettings): void => {
    if (user) {
      connectionToEMDRHub.current?.invoke('updateSettings', serializeSettings(settings));
    }
  };

  const startEmdr = (): void => {
    if (user && !connectionToEMDRHub.current) {
      startEmdrQueueHub();
      if (isSharingScreen) {
        toggleScreenShare();
      }
    }
  };

  const stopEmdr = (): void => {
    if (user && connectionToEMDRHub.current) {
      connectionToEMDRHub.current?.invoke('stop');
    }
  };

  const updatePresets = (number: number): void => {
    const newPresets: EMDRSettings[] = presets.map((preset: EMDRSettings, index: number) => {
      return index + 1 === number
        ? { ...emdrSettings, counterType: emdrCounterSettings.type ?? DurationTypeEnum.Manual }
        : preset;
    });

    updateUser({ emdrSettings: serializeSettingsPresets(newPresets) })
      .then(() => dispatch(getUser()).unwrap())
      .catch((error: any) => {
        return Promise.reject(`Update user failed: ${error.message}`);
      });
  };

  const switchPreset = (number: number): void => {
    connectionToEMDRHub.current?.invoke('updateSettings', serializeSettings(presets[number - 1]));
  };

  const toggleShowDurationCountdown = (): void => {
    setShowDurationCountdown((prev) => !prev);
  };

  useEffect(() => {
    addEventListener('emdrStarted', onEmdrStarted);

    return () => {
      removeEventListener('emdrStarted', onEmdrStarted);
    };
  }, [onEmdrStarted]);

  useEffect(() => {
    const onCallEnded = (): void => {
      if (connectionToEMDRHub.current) {
        if (user) {
          // eslint-disable-next-line no-console
          connectionToEMDRHub.current?.invoke('stop').catch(console.error);
        } else {
          setIsPlay(false);
          setIsStarted(false);
          disconnect();
        }
      }
    };
    addEventListener('callEnded', onCallEnded);

    return () => {
      removeEventListener('callEnded', onCallEnded);

      if (!user) {
        showForcedRotatePhoneDialog(false);
      }
    };
  }, [user, disconnect]);

  useEffect(() => {
    const onRemovedFromCall = (): void => {
      setIsPlay(false);
      setIsStarted(false);
      disconnect();
    };
    addEventListener('removedFromCall', onRemovedFromCall);

    return () => {
      removeEventListener('removedFromCall', onRemovedFromCall);

      if (!user) {
        showForcedRotatePhoneDialog(false);
      }
    };
  }, [disconnect]);

  useEffect(() => {
    if (user) {
      const presets: EMDRSettings[] | null = deserializeSettingsPresets(user.emdrSettings);
      if (presets) {
        setPresets(presets);
      }
    }
  }, [user]);

  useEffect(() => {
    return () => {
      disconnect();

      if (!user) {
        showForcedRotatePhoneDialog(false);
      }
    };
  }, []);

  return (
    <EMDRContext.Provider
      value={{
        state: emdrState,
        settings: emdrSettings,
        soundNames,
        counterSettings: emdrCounterSettings,
        showDurationCountdown,
        imagesRef,
        audiosRef,
        isImgPresetsLoaded,

        setEmdrCounterSettings,
        startEmdr,
        stopEmdr,
        play,
        pause,
        changeSettings,
        updatePresets,
        switchPreset,
        toggleShowDurationCountdown,
      }}
    >
      {children}
    </EMDRContext.Provider>
  );
}
