import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function CalendarIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='20' height='22' viewBox='0 0 20 22' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M16.343 3.74023H3.67068C2.67086 3.74023 1.86035 4.55075 1.86035 5.55056V18.2228C1.86035 19.2227 2.67086 20.0332 3.67068 20.0332H16.343C17.3428 20.0332 18.1533 19.2227 18.1533 18.2228V5.55056C18.1533 4.55075 17.3428 3.74023 16.343 3.74023Z'
        strokeWidth='2'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M13.6279 1.93066V5.55132' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6.38672 1.93066V5.55132' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M1.86035 9.17188H18.1533' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIcon>
  );
}
