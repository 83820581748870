import React from 'react';
import { Button, DialogContent, DialogTitle } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { ModalTitle, PatientStatus } from 'components';
import { HubPatient } from 'types';

import styles from './StatusDialog.module.scss';

export interface StatusDialogProps extends DialogProps {
  patient: HubPatient;
  onConfirm: () => void;
}

export function StatusDialog({ onConfirm, patient, onClose, ...rest }: StatusDialogProps) {
  return (
    <Dialog {...rest} className={styles.dialog}>
      <DialogTitle>
        <ModalTitle title='Status' onClose={onConfirm} />
      </DialogTitle>
      <DialogContent>
        <PatientStatus patient={patient} />

        <Button variant='contained' fullWidth className={styles.formButton} onClick={onConfirm}>
          Ok
        </Button>
      </DialogContent>
    </Dialog>
  );
}
