import { useCallback, useEffect, useState } from 'react';

import { useDevices } from '../useDevices';
import { useLocalStorageState } from 'hooks/useLocalStorageState';
import { SELECTED_AUDIO_OUTPUT_KEY } from '../../constants';

export function useActiveSinkId() {
  const { audioOutputDevices } = useDevices();
  const [activeSinkId, _setActiveSinkId] = useState('default');
  const [selectedAudioOutputDevice, setSelectedAudioOutputDevice] = useLocalStorageState<string>(
    SELECTED_AUDIO_OUTPUT_KEY,
    'default',
  );

  const setActiveSinkId = useCallback((sinkId: string) => {
    setSelectedAudioOutputDevice(sinkId);
    _setActiveSinkId(sinkId);
  }, []);

  useEffect(() => {
    const hasSelectedAudioOutputDevice = audioOutputDevices.some(
      (device) => selectedAudioOutputDevice && device.deviceId === selectedAudioOutputDevice,
    );
    if (hasSelectedAudioOutputDevice) {
      _setActiveSinkId(selectedAudioOutputDevice!);
    }
  }, [audioOutputDevices]);

  return [activeSinkId, setActiveSinkId] as const;
}
