import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function LocalPhoneIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='14' height='15' viewBox='0 0 14 15' fill='none' {...props}>
      <path d='M13.841 12.0066V12.0036C13.841 12.0036 11.1884 9.3589 11.1868 9.35761C10.9765 9.14758 10.6402 9.15213 10.4333 9.35901L9.2123 10.5828C8.73764 11.0567 8.12558 10.6361 6.95073 9.6799C6.17343 9.04723 5.2791 8.19648 4.50367 7.23731C3.95949 6.56434 3.47827 5.85762 3.9754 5.36151C3.97669 5.36022 5.20139 4.14091 5.20116 4.13963L5.2021 4.13706C5.42187 3.91768 5.40057 3.5816 5.19987 3.38103V3.378C5.19987 3.378 2.47066 0.657153 2.46913 0.655518C2.25883 0.445602 1.92261 0.450275 1.71559 0.656919L0.485622 1.88464C-0.02111 2.51766 -1.14727 5.53462 3.68073 10.4745C8.76678 15.6787 12.0487 14.5654 12.6137 13.9782C12.6137 13.9782 13.8424 12.7663 13.8424 12.7651L13.8435 12.7626C14.0632 12.5431 14.0416 12.2073 13.841 12.0066Z' />
    </SvgIcon>
  );
}
