import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function ArrowForwardIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M12 21.4725L16.9447 16.5L12 11.5275L13.5223 10L20 16.5L13.5223 23L12 21.4725Z' fill='#626363' />
    </SvgIcon>
  );
}
