import { Button, DialogContent, Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';

import styles from './LogoutRequiredDialog.module.scss';
import { InfoIcon } from 'assets';

export interface LogoutRequiredDialogProps extends DialogProps {
  onConfirm: () => void;
  onClose?: () => void;
}

export function LogoutRequiredDialog({ onConfirm, onClose, ...rest }: LogoutRequiredDialogProps) {
  return (
    <Dialog {...rest} className={styles.dialog}>
      <DialogContent>
        <InfoIcon className={styles.icon} />

        <Typography color='error' variant='body4'>
          Logout Required
        </Typography>

        <Typography variant='body2'>
          In order to visit another provider’s room, please log out of your account first.
        </Typography>

        <div className={styles.buttons}>
          <Button variant='outlined' onClick={onClose}>
            Go Back
          </Button>

          <Button variant='contained' onClick={onConfirm}>
            Logout
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
