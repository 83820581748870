import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, InputAdornment, TextField } from '@mui/material';
import * as Yup from 'yup';
import classnames from 'classnames';

import { FormErrorHelperText } from '../FormErrorHelperText';
import { InviteClientPopup } from 'pages/LinkPage/components';
import { ConfirmResultEnum, IDialogContext } from 'context';
import { useDialog } from 'hooks';
import { InviteIcon } from 'assets';
import { SuccessInfoDialog } from 'components';

import styles from './MyRoomLink.module.scss';

type MyRoomLinkProps = {
  type: 'copy' | 'edit';
  value?: string;
  placeholder?: string;
  className?: string;
  onClick?: (value?: string) => void;
  showButton?: boolean;
};

const schema = Yup.string()
  .required('Please set unique URL for your waiting room')
  .matches(/^(([a-z]|\d|_)+([a-z]|\d|-|_)*)$/i, 'This URL contains characters not allowed. Please try again.')
  .max(128, 'Max length is 128 character');

export const MyRoomLink = forwardRef<unknown, MyRoomLinkProps>(
  ({ value, placeholder, className, onClick, type, ...rest }: MyRoomLinkProps, ref) => {
    const { confirm, showDialog }: IDialogContext = useDialog();

    const [link, setLink] = useState(value);
    const [error, setError] = useState('');
    const inputRef: React.RefObject<HTMLDivElement> = React.useRef(null);

    useEffect(() => {
      setLink(value);
    }, [value]);

    const btnText: string = useMemo(() => {
      if (type === 'copy') {
        return 'Copy link';
      }

      return value === link && value ? 'Edit' : 'Save';
    }, [value, link]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      validate(value);
      setLink(value);
    };

    const handleClick = useCallback(() => {
      if (type === 'copy') {
        onClick && onClick(link);
        return;
      }

      if (value !== link && validate(link)) {
        onClick && onClick(link);
        return;
      }

      if (value === link) {
        inputRef.current!.focus();
      }
    }, [value, link]);

    const validate = (value?: string): boolean => {
      let isValid = false;
      try {
        schema.validateSync(value);
        setError('');
        isValid = true;
      } catch (e: any) {
        setError(e.message);
      }
      return isValid;
    };

    const handleInviteClick = async (): Promise<void> => {
      const confirmationResult = await confirm({ content: InviteClientPopup });
      if (confirmationResult === ConfirmResultEnum.Confirm) {
        const modal = showDialog(SuccessInfoDialog, {
          buttonText: 'Done',
          title: 'Success!',
          text: 'Your invitation has been sent!',
          onConfirm: () => {
            modal.destroy();
          },
        });
      }
    };

    return (
      <Box
        ref={ref}
        {...rest}
        className={classnames(className, styles.link, {
          [styles.withInvite]: type === 'copy',
        })}
      >
        <TextField
          placeholder={placeholder}
          inputRef={inputRef}
          disabled={type === 'copy'}
          variant='outlined'
          value={link}
          onChange={handleChange}
          className={styles.textField}
          InputProps={{
            startAdornment: <InputAdornment position='start'>{window.location.origin}/</InputAdornment>,
          }}
        />

        <Button variant='contained' onClick={handleClick} disabled={type === 'edit' && error !== ''}>
          {btnText}
        </Button>

        {type === 'copy' && (
          <Button variant='outlined' onClick={handleInviteClick} startIcon={<InviteIcon color='primary' />}>
            Invite
          </Button>
        )}

        <FormErrorHelperText className={styles.errorHelper}>{error}</FormErrorHelperText>
      </Box>
    );
  },
);
