/* eslint-disable prettier/prettier */
import { AINotesWrittenAsEnum } from 'types';

export const aiNotesWrittenAsOptions: Array<{ id: string, value: string }> = [
  {
    id: AINotesWrittenAsEnum.AcademicCounselor.toString(),
    value: 'Academic Counselor'
  },
  {
    id: AINotesWrittenAsEnum.AddictionCounselor.toString(),
    value: 'Addiction Counselor'
  },
  {
    id: AINotesWrittenAsEnum.AdlerianTherapist.toString(),
    value: 'Adlerian Therapist'
  },
  {
    id: AINotesWrittenAsEnum.AdolescentTherapist.toString(),
    value: 'Adolescent Therapist'
  },
  {
    id: AINotesWrittenAsEnum.AdultTherapist.toString(),
    value: 'Adult Therapist'
  },
  {
    id: AINotesWrittenAsEnum.AnimalAssistedTherapist.toString(),
    value: 'Animal-Assisted Therapist'
  },
  {
    id: AINotesWrittenAsEnum.AppliedPsychologist.toString(),
    value: 'Applied Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.ArtTherapist.toString(),
    value: 'Art Therapist'
  },
  {
    id: AINotesWrittenAsEnum.AviationPsychologist.toString(),
    value: 'Aviation Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.BehavioralAnalyst.toString(),
    value: 'Behavioral Analyst'
  },
  {
    id: AINotesWrittenAsEnum.BehavioralNeuroscientist.toString(),
    value: 'Behavioral Neuroscientist'
  },
  {
    id: AINotesWrittenAsEnum.BehavioralTherapist.toString(),
    value: 'Behavioral Therapist'
  },
  {
    id: AINotesWrittenAsEnum.BiofeedbackTherapist.toString(),
    value: 'Biofeedback Therapist'
  },
  {
    id: AINotesWrittenAsEnum.BodyPsychotherapist.toString(),
    value: 'Body Psychotherapist'
  },
  {
    id: AINotesWrittenAsEnum.CareerCounselor.toString(),
    value: 'Career Counselor'
  },
  {
    id: AINotesWrittenAsEnum.CertifiedAlcoholAndDrugCounselor.toString(),
    value: 'Certified Alcohol and Drug Counselor (CADC)'
  },
  {
    id: AINotesWrittenAsEnum.CertifiedEatingDisorderSpecialist.toString(),
    value: 'Certified Eating Disorder Specialist (CEDS)'
  },
  {
    id: AINotesWrittenAsEnum.ChildAndAdolescentPsychiatrist.toString(),
    value: 'Child and Adolescent Psychiatrist'
  },
  {
    id: AINotesWrittenAsEnum.ChildAndFamilySocialWorker.toString(),
    value: 'Child and Family Social Worker'
  },
  {
    id: AINotesWrittenAsEnum.ChildTherapist.toString(),
    value: 'Child Therapist'
  },
  {
    id: AINotesWrittenAsEnum.ClinicalHypnotherapist.toString(),
    value: 'Clinical Hypnotherapist'
  },
  {
    id: AINotesWrittenAsEnum.ClinicalNeuropsychologist.toString(),
    value: 'Clinical Neuropsychologist'
  },
  {
    id: AINotesWrittenAsEnum.ClinicalPsychologist.toString(),
    value: 'Clinical Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.ClinicalSocialWorker.toString(),
    value: 'Clinical Social Worker (LCSW)'
  },
  {
    id: AINotesWrittenAsEnum.CognitiveBehavioralTherapist.toString(),
    value: 'Cognitive Behavioral Therapist (CBT)'
  },
  {
    id: AINotesWrittenAsEnum.CommunityPsychiatricNurse.toString(),
    value: 'Community Psychiatric Nurse'
  },
  {
    id: AINotesWrittenAsEnum.CommunityPsychologist.toString(),
    value: 'Community Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.ComparativePsychologist.toString(),
    value: 'Comparative Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.CompassionFocusedTherapist.toString(),
    value: 'Compassion-Focused Therapist'
  },
  {
    id: AINotesWrittenAsEnum.ConsultingPsychologist.toString(),
    value: 'Consulting Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.ConsumerPsychologist.toString(),
    value: 'Consumer Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.CorrectionalPsychologist.toString(),
    value: 'Correctional Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.CounselingPsychologist.toString(),
    value: 'Counseling Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.CoupleTherapist.toString(),
    value: 'Couple Therapist'
  },
  {
    id: AINotesWrittenAsEnum.CriminalPsychologist.toString(),
    value: 'Criminal Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.CrisisCounselor.toString(),
    value: 'Crisis Counselor'
  },
  {
    id: AINotesWrittenAsEnum.DanceMovementPsychotherapist.toString(),
    value: 'Dance/Movement Psychotherapist'
  },
  {
    id: AINotesWrittenAsEnum.DanceMovementTherapist.toString(),
    value: 'Dance/Movement Therapist'
  },
  {
    id: AINotesWrittenAsEnum.DepthPsychotherapist.toString(),
    value: 'Depth Psychotherapist'
  },
  {
    id: AINotesWrittenAsEnum.DevelopmentalPsychologist.toString(),
    value: 'Developmental Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.DialecticalBehaviorTherapist.toString(),
    value: 'Dialectical Behavior Therapist (DBT)'
  },
  {
    id: AINotesWrittenAsEnum.DisabilityCounselor.toString(),
    value: 'Disability Counselor'
  },
  {
    id: AINotesWrittenAsEnum.DomesticViolenceCounselor.toString(),
    value: 'Domestic Violence Counselor'
  },
  {
    id: AINotesWrittenAsEnum.EatingDisorderCounselor.toString(),
    value: 'Eating Disorder Counselor'
  },
  {
    id: AINotesWrittenAsEnum.EducationalPsychologist.toString(),
    value: 'Educational Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.EmotionallyFocusedTherapist.toString(),
    value: 'Emotionally Focused Therapist'
  },
  {
    id: AINotesWrittenAsEnum.EngineeringPsychologist.toString(),
    value: 'Engineering Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.EnvironmentalPsychologist.toString(),
    value: 'Environmental Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.EquineAssistedPsychotherapist.toString(),
    value: 'Equine-Assisted Psychotherapist'
  },
  {
    id: AINotesWrittenAsEnum.Ergonomist.toString(),
    value: 'Ergonomist'
  },
  {
    id: AINotesWrittenAsEnum.EvolutionaryPsychologist.toString(),
    value: 'Evolutionary Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.ExercisePsychologist.toString(),
    value: 'Exercise Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.ExistentialTherapist.toString(),
    value: 'Existential Therapist'
  },
  {
    id: AINotesWrittenAsEnum.ExperimentalPsychologist.toString(),
    value: 'Experimental Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.ExpressiveArtsTherapist.toString(),
    value: 'Expressive Arts Therapist'
  },
  {
    id: AINotesWrittenAsEnum.ExpressiveTherapist.toString(),
    value: 'Expressive Therapist'
  },
  {
    id: AINotesWrittenAsEnum.EMDRTherapist.toString(),
    value: 'EMDR Therapist'
  },
  {
    id: AINotesWrittenAsEnum.FamilySystemsTherapist.toString(),
    value: 'Family Systems Therapist'
  },
  {
    id: AINotesWrittenAsEnum.FamilyTherapist.toString(),
    value: 'Family Therapist'
  },
  {
    id: AINotesWrittenAsEnum.FeministTherapist.toString(),
    value: 'Feminist Therapist'
  },
  {
    id: AINotesWrittenAsEnum.ForensicCounselor.toString(),
    value: 'Forensic Counselor'
  },
  {
    id: AINotesWrittenAsEnum.ForensicPsychologist.toString(),
    value: 'Forensic Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.ForensicSocialWorker.toString(),
    value: 'Forensic Social Worker'
  },


  {
    id: AINotesWrittenAsEnum.GeneticCounselor.toString(),
    value: 'Genetic Counselor'
  },
  {
    id: AINotesWrittenAsEnum.GeriatricCounselor.toString(),
    value: 'Geriatric Counselor'
  },
  {
    id: AINotesWrittenAsEnum.GeriatricPsychiatrist.toString(),
    value: 'Geriatric Psychiatrist'
  },
  {
    id: AINotesWrittenAsEnum.GeriatricSocialWorker.toString(),
    value: 'Geriatric Social Worker'
  },
  {
    id: AINotesWrittenAsEnum.Geropsychologist.toString(),
    value: 'Geropsychologist'
  },
  {
    id: AINotesWrittenAsEnum.GestaltTherapist.toString(),
    value: 'Gestalt Therapist'
  },
  {
    id: AINotesWrittenAsEnum.GlobalPsychologist.toString(),
    value: 'Global Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.GriefCounselor.toString(),
    value: 'Grief Counselor'
  },
  {
    id: AINotesWrittenAsEnum.GroupTherapist.toString(),
    value: 'Group Therapist'
  },
  {
    id: AINotesWrittenAsEnum.HealthPsychologist.toString(),
    value: 'Health Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.HospiceAndPalliativeCareSocialWorker.toString(),
    value: 'Hospice and Palliative Care Social Worker'
  },
  {
    id: AINotesWrittenAsEnum.HumanFactorsPsychologist.toString(),
    value: 'Human Factors Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.HumanisticTherapist.toString(),
    value: 'Humanistic Therapist'
  },
  {
    id: AINotesWrittenAsEnum.Hypnotherapist.toString(),
    value: 'Hypnotherapist'
  },
  {
    id: AINotesWrittenAsEnum.ImagoRelationshipTherapist.toString(),
    value: 'Imago Relationship Therapist'
  },
  {
    id: AINotesWrittenAsEnum.IndustrialOrganizationalPsychologist.toString(),
    value: 'Industrial-Organizational Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.IntegrativeBodyPsychotherapist.toString(),
    value: 'Integrative Body Psychotherapist'
  },
  {
    id: AINotesWrittenAsEnum.IntegrativeHealthCoach.toString(),
    value: 'Integrative Health Coach'
  },
  {
    id: AINotesWrittenAsEnum.IntegrativeTherapist.toString(),
    value: 'Integrative Therapist'
  },
  {
    id: AINotesWrittenAsEnum.InternalFamilySystemsTherapist.toString(),
    value: 'Internal Family Systems Therapist'
  },
  {
    id: AINotesWrittenAsEnum.InternationalPsychologist.toString(),
    value: 'International Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.InvestigativePsychologist.toString(),
    value: 'Investigative Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.JungianAnalyst.toString(),
    value: 'Jungian Analyst'
  },
  {
    id: AINotesWrittenAsEnum.JungianTherapist.toString(),
    value: 'Jungian Therapist'
  },
  {
    id: AINotesWrittenAsEnum.LegalPsychologist.toString(),
    value: 'Legal Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.LGBTQAffirmativeTherapist.toString(),
    value: 'LGBTQ+ Affirmative Therapist'
  },
  {
    id: AINotesWrittenAsEnum.LGBTQCounselor.toString(),
    value: 'LGBTQ+ Counselor'
  },
  {
    id: AINotesWrittenAsEnum.LicensedProfessionalCounselor.toString(),
    value: 'Licensed Professional Counselor (LPC)'
  },
  {
    id: AINotesWrittenAsEnum.MarriageAndFamilyTherapist.toString(),
    value: 'Marriage and Family Therapist (MFT)'
  },
  {
    id: AINotesWrittenAsEnum.MentalHealthAdministrator.toString(),
    value: 'Mental Health Administrator'
  },
  {
    id: AINotesWrittenAsEnum.MentalHealthAdvocate.toString(),
    value: 'Mental Health Advocate'
  },
  {
    id: AINotesWrittenAsEnum.MentalHealthConsultant.toString(),
    value: 'Mental Health Consultant'
  },
  {
    id: AINotesWrittenAsEnum.MentalHealthCounselor.toString(),
    value: 'Mental Health Counselor'
  },
  {
    id: AINotesWrittenAsEnum.MentalHealthEducator.toString(),
    value

      : 'Mental Health Educator'
  },
  {
    id: AINotesWrittenAsEnum.MentalHealthNurse.toString(),
    value: 'Mental Health Nurse'
  },
  {
    id: AINotesWrittenAsEnum.MentalHealthResearcher.toString(),
    value: 'Mental Health Researcher'
  },
  {
    id: AINotesWrittenAsEnum.MentalHealthSocialWorker.toString(),
    value: 'Mental Health Social Worker'
  },
  {
    id: AINotesWrittenAsEnum.MentalHealthSpeaker.toString(),
    value: 'Mental Health Speaker'
  },
  {
    id: AINotesWrittenAsEnum.MentalHealthTechnician.toString(),
    value: 'Mental Health Technician'
  },
  {
    id: AINotesWrittenAsEnum.MentalHealthTrainer.toString(),
    value: 'Mental Health Trainer'
  },
  {
    id: AINotesWrittenAsEnum.MentalHealthWriter.toString(),
    value: 'Mental Health Writer'
  },
  {
    id: AINotesWrittenAsEnum.MilitaryPsychologist.toString(),
    value: 'Military Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.MindfulnessTherapist.toString(),
    value: 'Mindfulness Therapist'
  },
  {
    id: AINotesWrittenAsEnum.MulticulturalTherapist.toString(),
    value: 'Multicultural Therapist'
  },
  {
    id: AINotesWrittenAsEnum.MusicTherapist.toString(),
    value: 'Music Therapist'
  },
  {
    id: AINotesWrittenAsEnum.NarrativeTherapist.toString(),
    value: 'Narrative Therapist'
  },
  {
    id: AINotesWrittenAsEnum.NeurofeedbackTherapist.toString(),
    value: 'Neurofeedback Therapist'
  },
  {
    id: AINotesWrittenAsEnum.Neuropsychologist.toString(),
    value: 'Neuropsychologist'
  },
  {
    id: AINotesWrittenAsEnum.OccupationalHealthPsychologist.toString(),
    value: 'Occupational Health Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.OccupationalTherapist.toString(),
    value: 'Occupational Therapist'
  },
  {
    id: AINotesWrittenAsEnum.PediatricPsychologist.toString(),
    value: 'Pediatric Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.PeerSupportSpecialist.toString(),
    value: 'Peer Support Specialist'
  },
  {
    id: AINotesWrittenAsEnum.PerformancePsychologist.toString(),
    value: 'Performance Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.PersonCenteredTherapist.toString(),
    value: 'Person-Centered Therapist'
  },
  {
    id: AINotesWrittenAsEnum.PlayTherapist.toString(),
    value: 'Play Therapist'
  },
  {
    id: AINotesWrittenAsEnum.PoliticalPsychologist.toString(),
    value: 'Political Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.PositivePsychologist.toString(),
    value: 'Positive Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.PositivePsychologyPractitioner.toString(),
    value: 'Positive Psychology Practitioner'
  },
  {
    id: AINotesWrittenAsEnum.PsychiatricMentalHealthNursePractitioner.toString(),
    value: 'Psychiatric Mental Health Nurse Practitioner (PMHNP-BC)'
  },
  {
    id: AINotesWrittenAsEnum.PsychiatricNurse.toString(),
    value: 'Psychiatric Nurse'
  },
  {
    id: AINotesWrittenAsEnum.PsychiatricNursePractitioner.toString(),
    value: 'Psychiatric Nurse Practitioner (PMHNP)'
  },
  {
    id: AINotesWrittenAsEnum.PsychiatricTechnician.toString(),
    value: 'Psychiatric Technician'
  },
  {
    id: AINotesWrittenAsEnum.Psychiatrist.toString(),
    value: 'Psychiatrist'
  },
  {
    id: AINotesWrittenAsEnum.Psychoanalyst.toString(),
    value: 'Psychoanalyst'
  },
  {
    id: AINotesWrittenAsEnum.Psychodramatist.toString(),
    value: 'Psychodramatist'
  },
  {
    id: AINotesWrittenAsEnum.PsychodynamicTherapist.toString(),
    value: 'Psychodynamic Therapist'
  },
  {
    id: AINotesWrittenAsEnum.Psychologist.toString(),
    value: 'Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.RationalEmotiveAndCognitiveBehaviorTherapist.toString(),
    value: 'Rational Emotive & Cognitive Behavior Therapist (RE&CBT)'
  },
  {
    id: AINotesWrittenAsEnum.RationalEmotiveBehaviorTherapist.toString(),
    value: 'Rational Emotive Behavior Therapist (REBT)'
  },
  {
    id: AINotesWrittenAsEnum.RehabilitationCounselor.toString(),
    value: 'Rehabilitation Counselor'
  },
  {
    id: AINotesWrittenAsEnum.RehabilitationPsychologist.toString(),
    value: 'Rehabilitation Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.RelationalTherapist.toString(),
    value: 'Relational Therapist'
  },
  {
    id: AINotesWrittenAsEnum.ResiliencePsychologist.toString(),
    value: 'Resilience Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.SchemaTherapist.toString(),
    value: 'Schema Therapist'
  },
  {
    id: AINotesWrittenAsEnum.SchoolCounselor.toString(),
    value: 'School Counselor'
  },
  {
    id: AINotesWrittenAsEnum.SchoolPsychologist.toString(),
    value: 'School Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.SchoolSocialWorker.toString(),
    value: 'School Social Worker'
  },
  {
    id: AINotesWrittenAsEnum.SeniorTherapist.toString(),
    value: 'Senior Therapist'
  },
  {
    id: AINotesWrittenAsEnum.SensorimotorPsychotherapist.toString(),
    value: 'Sensorimotor Psychotherapist'
  },
  {
    id: AINotesWrittenAsEnum.SexTherapist.toString(),
    value: 'Sex Therapist'
  },
  {
    id: AINotesWrittenAsEnum.SocialPsychologist.toString(),
    value: 'Social Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.SolutionFocusedBriefTherapist.toString(),
    value: 'Solution-Focused Brief Therapist'
  },
  {
    id: AINotesWrittenAsEnum.SomaticExperiencingPractitioner.toString(),
    value: 'Somatic Experiencing Practitioner'
  },
  {
    id: AINotesWrittenAsEnum.SpacePsychologist.toString(),
    value: 'Space Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.SportsPsychologist.toString(),
    value: 'Sports Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.SubstanceAbuseCounselor.toString(),
    value: 'Substance Abuse Counselor'
  },
  {
    id: AINotesWrittenAsEnum.SubstanceAbuseSocialWorker.toString(),
    value: 'Substance Abuse Social Worker'
  },
  {
    id: AINotesWrittenAsEnum.SuicidePreventionCounselor.toString(),
    value: 'Suicide Prevention Counselor'
  },
  {
    id: AINotesWrittenAsEnum.SystemicTherapist.toString(),
    value: 'Systemic Therapist'
  },
  {
    id: AINotesWrittenAsEnum.TrafficPsychologist.toString(),
    value: 'Traffic Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.TranspersonalPsychologist.toString(),
    value: 'Transpersonal Psychologist'
  },
  {
    id: AINotesWrittenAsEnum.TranspersonalTherapist.toString(),
    value: 'Transpersonal Therapist'
  },
  {
    id: AINotesWrittenAsEnum.TraumaCounselor.toString(),
    value: 'Trauma Counselor'
  },
  {
    id: AINotesWrittenAsEnum.TraumaTherapist.toString(),
    value: 'Trauma Therapist'
  },
  {
    id: AINotesWrittenAsEnum.VocationalCounselor.toString(),
    value: 'Vocational Counselor'
  }
];