/* eslint-disable prettier/prettier */
import { AINotesTypeOfVisitEnum } from 'types';

export const aiNotesTypeOfVisitOptions: Array<{ id: string, value: string }> = [
  {
    id: AINotesTypeOfVisitEnum.FollowUp.toString(),
    value: 'Follow-up'
  },
  {
    id: AINotesTypeOfVisitEnum.Consultation.toString(),
    value: 'Consultation'
  },
  {
    id: AINotesTypeOfVisitEnum.Intake.toString(),
    value: 'Intake'
  },
  {
    id: AINotesTypeOfVisitEnum.Discharge.toString(),
    value: 'Discharge'
  },
  {
    id: AINotesTypeOfVisitEnum.Termination.toString(),
    value: 'Last/Termination'
  },
  {
    id: AINotesTypeOfVisitEnum.CouplesCounseling.toString(),
    value: 'Couples Counseling'
  },
  {
    id: AINotesTypeOfVisitEnum.FamilyTherapy.toString(),
    value: 'Family Therapy'
  },
  {
    id: AINotesTypeOfVisitEnum.ChildTherapy.toString(),
    value: 'Child Therapy'
  },
  {
    id: AINotesTypeOfVisitEnum.GroupTherapy.toString(),
    value: 'Group Therapy'
  },
];
