import { useState } from 'react';

import { RoomRightSidebarContext, OpenedTab } from './context';
import { useChat, useVideo } from 'hooks';

type RoomRightSidebarProviderProps = {
  children?: JSX.Element;
};

export function RoomRightSidebarProvider({ children }: RoomRightSidebarProviderProps) {
  const { openRoomChat, closeRoomChat } = useChat();
  const { room } = useVideo();

  const [openedTab, setOpenedTab] = useState<OpenedTab>(null);

  const toggleSettings = (): void => {
    if (openedTab === 'settings') {
      setOpenedTab(null);
    } else {
      setOpenedTab('settings');
    }
  };

  const toggleParticipants = (): void => {
    if (openedTab === 'participants') {
      setOpenedTab(null);
    } else {
      setOpenedTab('participants');
    }
  };

  const toggleNotes = (): void => {
    if (openedTab === 'notes') {
      setOpenedTab(null);
    } else {
      setOpenedTab('notes');
    }
  };

  const toggleChat = (): void => {
    if (openedTab === 'chat') {
      closeRoomChat();
      setOpenedTab(null);
    } else {
      openRoomChat(room!.name);
      setOpenedTab('chat');
    }
  };

  const toggleBackgroundEffects = (): void => {
    if (openedTab === 'backgroundEffects') {
      setOpenedTab(null);
    } else {
      setOpenedTab('backgroundEffects');
    }
  };

  const toggleWaitingQueue = (): void => {
    if (openedTab === 'waitingQueue') {
      setOpenedTab(null);
    } else {
      setOpenedTab('waitingQueue');
    }
  };

  const hideSidebar = (): void => {
    setOpenedTab(null);
  };

  return (
    <RoomRightSidebarContext.Provider
      value={{
        openedTab,
        hideSidebar,
        toggleSettings,
        toggleChat,
        toggleParticipants,
        toggleBackgroundEffects,
        toggleNotes,
        toggleWaitingQueue,
      }}
    >
      {children}
    </RoomRightSidebarContext.Provider>
  );
}
