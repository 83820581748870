import * as Yup from 'yup';
import 'yup-phone';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/system';
import { Typography, Button, Dialog, DialogTitle, DialogContent } from '@mui/material';

import { FormInput, ModalTitle } from 'components';
import { TwoFactor } from 'types';
import { withOutsideClickPreventer } from 'utils';

import styles from './SendCodePopup.module.scss';

const phoneSchema = Yup.object().shape({
  phone: Yup.string().phone('', false, 'Must be a valid phone number'),
});

type SendCodePopupProps = {
  open: boolean;
  onSubmit: (phone: string, twoFactor: TwoFactor) => void;
  onClose: () => void;
};

export const SendCodePopup = ({ open, onSubmit, onClose }: SendCodePopupProps) => {
  const phoneForm = useForm<{ phone: string }>({
    resolver: yupResolver(phoneSchema),
    defaultValues: {},
    mode: 'all',
  });

  const handleCall = () => {
    if (phoneForm.formState.isValid) {
      const phone: string = phoneForm.getValues().phone;
      onSubmit(phone, 'call');
    }
  };

  const handleText = () => {
    if (phoneForm.formState.isValid) {
      const phone: string = phoneForm.getValues().phone;
      onSubmit(phone, 'sms');
    }
  };

  return (
    <Dialog open={open} onClose={withOutsideClickPreventer(onClose)} className={styles.dialog}>
      <DialogTitle>
        <ModalTitle title='SETUP 2FA' onClose={onClose} />
      </DialogTitle>

      <DialogContent className={styles.dialogContent}>
        <Typography variant='body2'>
          For additional security and account recovery, HIPAA compliance encourages a second form of authentication.
        </Typography>

        <Typography variant='body2'>We will not use this information to contact you without your consent.</Typography>

        <FormInput
          placeholder='Enter phone number'
          name='phone'
          type='phone'
          prefix='+1'
          maxLength={13}
          control={phoneForm.control}
        />

        <Typography variant='body4'>Select how you want to receive your security code:</Typography>

        <Box className={styles.buttonsContaine}>
          <Button disabled={!phoneForm.formState.isValid} variant='contained' onClick={handleCall}>
            Call
          </Button>

          <Button disabled={!phoneForm.formState.isValid} variant='contained' onClick={handleText}>
            Text
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
