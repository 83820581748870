import classnames from 'classnames';

import styles from './ReconnectingAnimation.module.scss';

export function ReconnectingAnimation() {
  return (
    <div className={styles.container}>
      <div className={classnames(styles.ball, styles.ball1)}></div>
      <div className={classnames(styles.ball, styles.ball2)}></div>
      <div className={classnames(styles.ball, styles.ball3)}></div>
    </div>
  );
}
