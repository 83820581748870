import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function ItalicIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='17' height='20' viewBox='0 0 17 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M15.2656 1.54025H5.76915C5.34868 1.54025 5.01025 1.19157 5.01025 0.771103C5.01025 0.350634 5.35894 0.00195312 5.7794 0.00195312H15.2656C15.6861 0.00195312 16.0348 0.350634 16.0348 0.771103C16.0348 1.19157 15.6861 1.54025 15.2656 1.54025Z' />
      <path d='M10.6486 20.0002H1.15221C0.731738 20.0002 0.383057 19.6515 0.383057 19.2311C0.383057 18.8106 0.731738 18.4619 1.15221 18.4619H10.6383C11.0588 18.4619 11.4075 18.8106 11.4075 19.2311C11.4075 19.6515 11.0691 20.0002 10.6486 20.0002Z' />
      <path d='M5.90162 20C5.84009 20 5.77856 19.9897 5.71703 19.9795C5.30681 19.8769 5.05043 19.4565 5.15298 19.0463L9.76788 0.586657C9.87044 0.176444 10.2807 -0.0799397 10.7011 0.0226136C11.1113 0.125167 11.3677 0.545635 11.2652 0.955849L6.65026 19.4154C6.55797 19.7641 6.25031 20 5.90162 20Z' />
    </SvgIcon>
  );
}
