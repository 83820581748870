/* eslint-disable prettier/prettier */
import { ChangeEvent, FC, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  Stack,
  Typography,
  Checkbox,
  FormControlLabel,
  Box,
  useMediaQuery,
  Theme,
} from '@mui/material';
import { CheckboxCheckedIcon, CheckboxIcon } from 'assets';

export interface AINotesTermsAndConditionsConfirmationDialogProps extends DialogProps {
  onConfirm(): void;
  onCancel(): void;
}

export const AINotesTermsAndConditionsConfirmationDialog: FC<AINotesTermsAndConditionsConfirmationDialogProps> = ({
  onConfirm,
  onCancel,
  ...props
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setIsChecked(event.target.checked);
  };

  const handleConfirm = async (): Promise<void> => {
    onConfirm?.();
  };

  const Appendix1 = () => (
    <>
      <h1>APPENDIX 1</h1>
      <h3>Data Processing Agreement</h3>
      <p>
        This Data Processing Agreement (“DPA”) is incorporated into and made part of the Terms of Service (“Terms”)
        between you (“Customer”) and HIPAALINK.net, Inc. (“Company”). Unless otherwise defined in this DPA, capitalized
        terms will have the meaning given to them in the Terms. This DPA prevails over any conflicting term of the
        Terms, but does not otherwise modify the Terms.
      </p>

      <h2>1. Definitions</h2>
      <h3>1.1 In this DPA</h3>
      <p>
        a. “Controller,” “Data Subject,” “Personal Data,” “Personal Data Breach,” “Processing,” “Processor,” and
        “Supervisory Authority,” have the meaning given to them in the GDPR.{' '}
      </p>
      <p>
        b. “Customer Personal Data” means any Data that constitutes Personal Data, the Processing of which is subject to
        Data Protection Law, for which Customer or Customer’s customers are the Controller, and which is Processed by
        Company to provide the Service;
      </p>
      <p>
        c. “Data Protection Law” means the General Data Protection Regulation (EU) 2016/679 (“GDPR”), the United Kingdom
        General Data Protection Regulation, and e-Privacy Directive 2002/58/EC (as amended by Directive 2009/136/EC),
        and their national implementations in the European Economic Area (“EEA”), Switzerland and the United Kingdom,
        each as applicable, and as may be amended or replaced from time to time;
      </p>
      <p>
        d. “Data Subject Rights” means Data Subjects’ rights to information, access, rectification, erasure,
        restriction, portability, objection, and not to be subject to automated individual decision-making in accordance
        with Data Protection Law;
      </p>
      <p>
        e. “International Data Transfer” means any transfer of Customer Personal Data from the EEA, Switzerland or the
        United Kingdom to an international organization or to a country outside of the EEA, Switzerland and the United
        Kingdom;
      </p>
      <p>f. “Subprocessor” means a Processor engaged by Company to Process Customer Personal Data; and </p>
      <p>
        g. “Standard Contractual Clauses” means the clauses annexed to EU Commission Implementing Decision EU 2021/914
        of June 4, 2021 on standard contractual clauses for the transfer of personal data to third countries pursuant to
        Regulation (EU) 2016/679 of the European parliament and of the Council;
      </p>
      <p>
        h. “UK Addendum” means the addendum to the Standard Contractual Clauses issued by the UK Information
        Commissioner under Section 119A(1) of the UK Data Protection Act 2018 (version B1.0, in force March 21, 2022).
      </p>

      <h2>2. Scope and applicability</h2>
      <p>2.1 This DPA applies to Processing of Customer Personal Data by Company to provide the Service. </p>
      <p>
        2.2 The subject matter, nature and purpose of the Processing, the types of Customer Personal Data and categories
        of Data Subjects are set out in Appendix 5.
      </p>
      <p>
        2.3 Customer is a Controller and appoints Company as a Processor on behalf of Customer in relation to the
        purposes set out in Appendix 5. Customer is responsible for compliance with the requirements of Data Protection
        Law applicable to Controllers.
      </p>
      <p>
        2.4 If Customer is a Processor on behalf of other Controller(s), then Customer is the single point of contact
        for Company; must obtain all necessary authorizations from such other Controller(s); undertakes to issue all
        instructions and exercise all rights on behalf of such other Controller(s); and is responsible for compliance
        with the requirements of Data Protection Law applicable to Processors.
      </p>
      <p>
        2.5 Customer acknowledges that Company may Process Personal Data relating to the operation, support, or use of
        the Service for its own business purposes, such as billing, account management, data analysis, benchmarking,
        technical support, product development, and compliance with law. Company is the Controller for such Processing
        and will Process such data in accordance with Data Protection Law. Such Processing shall not be subject to the
        terms of this DPA.
      </p>

      <h2>3. Instructions</h2>
      <p>
        3.1 Company will Process Customer Personal Data to provide the Service and in accordance with Customer’s
        documented instructions.
      </p>
      <p>
        3.2 The Controller’s instructions are documented in this DPA, the Terms, and any applicable statement of work.{' '}
      </p>
      <p>
        3.3 Customer may reasonably issue additional instructions as necessary to comply with Data Protection Law.
        Company may charge a reasonable fee to comply with any additional instructions.
      </p>
      <p>
        3.4 Unless prohibited by applicable law, Company will inform Customer if Company is subject to a legal
        obligation that requires Company to Process Customer Personal Data in contravention of Customer’s documented
        instructions.
      </p>

      <h2>4. Personnel</h2>
      <p>
        4.1 Company personnel authorized to Process Customer Personal Data are subject to an obligation of
        confidentiality.
      </p>

      <h2>5. Security and Personal Data Breaches</h2>
      <p>
        5.1 Taking into account the state-of-the-art, the costs of implementation and the nature, scope, context and
        purposes of Processing as well as the risk of varying likelihood and severity for the rights and freedoms of
        natural persons, Company will implement appropriate technical and organizational measures to provide a level of
        security appropriate to the risk, including the measures listed in Appendix 3.
      </p>
      <p>
        5.2 Customer acknowledges that the security measures in Appendix 3 are appropriate in relation to the risks
        associated with Customer’s intended Processing, and will notify Company prior to any intended Processing for
        which Company’s security measures may not be appropriate.
      </p>
      <p>
        5.3 Company will notify Customer without undue delay after becoming aware of a Personal Data Breach involving
        Customer Personal Data. If Company’s notification is delayed, it will be accompanied by reasons for the delay.
      </p>

      <h2>6. Subprocessing</h2>
      <p>6.1 Customer hereby authorizes Company to engage Subprocessors.</p>
      <p>
        6.2 Company will enter into a written agreement with Subprocessors which imposes the same obligations as
        required by Data Protection Law.{' '}
      </p>
      <p>
        6.3 Customer may object to the addition of a Subprocessor based on reasonable grounds relating to a potential or
        actual violation of Data Protection Law by providing written notice detailing the grounds of such objection
        within thirty (30) days following Company’s notification of the intended change. Customer and Company will work
        together in good faith to address Customer’s objection. If Company chooses to retain the Subprocessor, Company
        will inform Customer at least thirty (30) days before authorizing the Subprocessor to Process Customer Personal
        Data, and Customer may immediately discontinue using the relevant part of the Service, and may terminate the
        relevant part of the Service within thirty (30) days.
      </p>

      <h2>7. Assistance</h2>
      <p>
        7.1 Taking into account the nature of the Processing, and the information available to Company, Company will
        assist Customer, including, as appropriate, by implementing technical and organizational measures, with the
        fulfillment of Customer’s own obligations under Data Protection Law to: comply with requests to exercise Data
        Subject Rights; conduct data protection impact assessments, and prior consultations with Supervisory
        Authorities; and notify a Personal Data Breach.
      </p>
      <p>
        7.2 Company will maintain records of Processing of Customer Personal Data in accordance with Data Protection
        Law.
      </p>
      <p>
        7.3 Company may charge a reasonable fee for assistance under this Section 7. If Company is at fault, Company and
        Customer shall each bear their own costs related to assistance.
      </p>

      <h2>8. Compliance</h2>
      <p>8.1 Company shall maintain a program to provide compliance with the obligations set out in this DPA.</p>

      <h2>9. International Data Transfers</h2>
      <p>
        9.1 Customer hereby authorizes Company to perform International Data Transfers to any country deemed adequate by
        the EU Commission; on the basis of appropriate safeguards in accordance with Data Protection Law or pursuant to
        the Standard Contractual Clauses and UK Addendum referred to in Section 9.2 and 9.3 respectively.
      </p>
      <p>
        9.2. By signing this DPA, Customer and Company hereby agree to conclude the provisions of module two (controller
        to processor) of the Standard Contractual Clauses, which are hereby incorporated into this DPA and completed as
        follows:
        <ul>
          <li>The optional Clause 7 is kept;</li>
          <li>
            In Clause 9, Option 1 is struck and Option 2 is kept , and Customer may object to the addition of a
            Subprocessor within (30) days.
          </li>
          <li>In Clause 11, the optional language is struck;</li>
          <li>
            In Clauses 17 and 18, the governing law and the competent courts are those of the country in which Customer
            is established.
          </li>
          <li>Appendixes 2, 3 and 4 to these terms of service</li>
        </ul>
      </p>
      <p>
        9.3. By signing this DPA, Customer and Company conclude the UK Addendum which is hereby incorporated and applies
        to International Data Transfers outside the United Kingdom. Part 1 of the UK Addendum is completed as follows:
        (i) in Table 1, the “Exporter” is Customer and the “Importer” is Company, their details are set forth in this
        DPA and the Terms; (ii) in Table 2, the first option is selected and the “Approved EU SCCs” are the Standard
        Contractual Clauses referred to in Section 9.2 of this DPA; (iii) in Table 3, Annexes 1 (A and B), II and III to
        the “Approved EU SCCs” are Appendixes 2, 3 and 4 to the Terms; and (iv) in Table 4, both the “Importer” and the
        “Exporter” can terminate the UK Addendum.
      </p>
      <p>
        9.4. If the Standard Contractual Clauses or the UK Addendum are amended, updated, or invalidated, Customer and
        Company will work together in good faith to reasonably resolve such non-compliance.{' '}
      </p>

      <h2>10. Notifications</h2>
      <p>
        10.1 Customer will send notifications, requests and instructions under this DPA to Company’s legal department
        via email to legal@HIPAALINK.net. Company will send notifications under this DPA to Customer’s contact email
        address.
      </p>

      <h2>11. Liability</h2>
      <p>
        11.1 To the extent permitted by applicable law, where Company has paid damages or fines, Company is entitled to
        claim back from Customer that part of the compensation, damages or fines, corresponding to Customer’s part of
        responsibility for the damages or fines.
      </p>

      <h2>12. Termination and return or deletion</h2>
      <p>12.1 This DPA is terminated upon the termination of the Terms. </p>
      <p>
        12.2 Upon termination of the Terms of Service, the Company will, upon Customer’s request, return Customer
        Personal Data in Company’s possession to the Customer or securely destroy such Customer Personal Data unless
        applicable laws prevent the Company from returning or destroying all or part of Customer Personal Data.
      </p>

      <h2>13. Modification of this DPA</h2>
      <p>13.1 This DPA may only be modified by a written amendment signed by both Company and Customer.</p>

      <h2>14. Invalidity and severability</h2>
      <p>
        14.1 If any provision of this DPA is found by any court or administrative body of competent jurisdiction to be
        invalid or unenforceable, then the invalidity or unenforceability of such provision does not affect any other
        provision of this DPA and all provisions not affected by such invalidity or unenforceability will remain in full
        force and effect.
      </p>
    </>
  );

  const Appendix2 = () => (
    <>
      <h1>APPENDIX 2</h1>
      <h3>A. List of Parties</h3>
      <p>
        Data exporter(s): [Identity and contact details of the data exporter(s) and, where applicable, of its/their data
        protection officer and/or representative in the European Union]
      </p>
      <p>
        1. Name: As included in the Terms
        <br /> Address: As included in the Terms Contact person’s name, position and contact details: As included in the
        Terms Activities relevant to the data transferred under these Clauses: As included in the Terms Signature and
        date: As included in the Terms Role: Controller{' '}
      </p>
      <p>
        Data importer(s): [Identity and contact details of the data importer(s), including any contact person with
        responsibility for data protection]
      </p>
      <p>
        2. Name: HIPAALINK.net, Inc. Address: As included in the Terms Contact person’s name, position and contact
        details: As included in the Terms Activities relevant to the data transferred under these Clauses: As included
        in the Terms Signature and date: As included in the Terms Role: Processor
      </p>
      <p>Categories of data subjects whose personal data is transferred</p>
      <ol>
        <li>Employees, contractors, or students of Organizations who use the Service</li>
        <li>Other meeting participants where the Service is used by one of the above data subjects</li>
      </ol>
      <p>Categories of personal data transferred</p>
      <ol>
        <li>Registration information including email address and volunteered first and last name</li>
        <li>Audio recordings stored by the user that may include personal data</li>
      </ol>
      <p>
        Personal data transferred (if applicable) and applied restrictions or safeguards that fully take into
        consideration the nature of the data and the risks involved, such as for instance strict purpose limitation,
        access restrictions (including access only for staff having followed specialized training), keeping a record of
        access to the data, restrictions for onward transfers or additional security measures.
      </p>
      <ol>
        <li>
          The Service may Process voiceprints to recognize users and automatically tag their name within the transcript.
        </li>
      </ol>
      <p>The frequency of the transfer (e.g. whether the data is transferred on a one-off or continuous basis).</p>
      <p>Continuous</p>
      <p>Nature of the processing</p>
      <p>Processing operations as detailed in the Terms</p>
      <p>Purpose(s) of the data transfer and further processing</p>
      <ol>
        <li>
          The Service creates legible notes from recorded voice conversations using third-party Artificial Intelligence
          technologies for automated speech recognition (ASR), keyword extraction and voice to text synchronization.
          Data may be ingested directly via recording using a mobile or web application, upload of audio files or
          synchronization with HIPAA LINK to store or capture recorded audio. Audio is processed in Twilio’s cloud
          infrastructure, delivered to AssemblyAI for transcription and processing, then delivered to the HIPAA LINK
          application where it may be consumed or downloaded in a variety of formats.
        </li>
      </ol>
      <p>
        The period for which the personal data will be retained, or, if that is not possible, the criteria used to
        determine that period
      </p>
      <p>As detailed in the Terms.‍ </p>

      <h2>C. COMPETENT SUPERVISORY AUTHORITY‍</h2>
      <p>As detailed in Appendix 1.</p>
      <p>
        The competent authority for data transfers subject to the UK Addendum is the Information Commissioner’s Office
        in the UK.
      </p>
    </>
  );

  const Appendix3 = () => (
    <>
      <h1>APPENDIX 3</h1>

      <p>
        The Service is provisioned using a cloud-based platform and we employ best practices and appropriate technical
        and organizational measures to safeguard Personal Data. The Company regularly monitors compliance with these
        measures. A summary of security measures includes:
      </p>

      <h3>Physical Access Controls</h3>
      <p>
        User Content is hosted by Microsoft Azure (Azure), which is certified SOC 2 Type 2. Azure maintains a list of
        certifications and third-party assessments. The Azure infrastructure is managed in Microsoft’s controlled data
        centers throughout the world and the data centers are secured with physical controls to prevent unauthorized
        access.
      </p>

      <h3>System Access Controls</h3>
      <p>
        We maintain separate production and development/staging environments. Access to production environments is
        limited to authorized personnel and access is logged.{' '}
      </p>

      <h3>Data Access Controls</h3>
      <p>
        To troubleshoot and address Customer issue(s), the Company support team will request and obtain explicit
        permission from Customer and approval from appropriate system administrator(s) before accessing specific User
        Content that is related to the Customer reported issue(s). Access to User Content to troubleshoot customer
        issues is further restricted to select support personnel assisting with the issue(s). These types of support
        requests are logged.
      </p>

      <h3>Transmission Controls</h3>
      <p>
        Communication over the internet with Company via web application are transmitted over secure encrypted
        connection. Company uses HTTPS for communications and industry standard encryption algorithms for stored User
        Content and passwords.
      </p>

      <h3>Input Controls</h3>
      <p>
        Customer is authenticated by password. Two factor authentication may be enabled. Company uses web cookies to
        validate signed in Customers.
      </p>

      <h3>Data Backups</h3>
      <p>We have daily backups of databases and User Content is stored in persistent storage.</p>

      <h3>Data Segregation</h3>
      <p>
        Company logically segregates Customer Personal Data so that the Customers will only be able to access their own
        data and not Personal Data belonging to other Customers.
      </p>
    </>
  );

  const Appendix4 = () => (
    <>
      <h1>APPENDIX 4</h1>
      <h3>List of subprocessors‍</h3>
      <p>
        The controller has authorized the use of the subprocessors listed on the page
        https://www.HIPAALINK.net/subprocessors.
      </p>
    </>
  );

  const Appendix5 = () => (
    <>
      <h1>APPENDIX 5</h1>
      <h3>Description of the processing</h3>
      <h3>1. Data Subjects</h3>
      <p>
        The Customer Personal Data Processed concern the following categories of Data Subjects:
        <ol>
          <li>Employees, contractors, or students of Organizations who use the Service</li>
          <li>Other meeting participants where the Service is used by one of the above data subjects</li>
        </ol>
      </p>

      <h3>2. Categories of Customer Personal Data</h3>
      <p>
        The Customer Personal Data Processed concern the following categories of data:
        <ol>
          <li>Registration information including email address and volunteered first and last name</li>
          <li>Audio recordings stored by the user that may include personal data</li>
        </ol>
      </p>

      <h3>3. Sensitive data</h3>
      <p>
        The Customer Personal Data Processed concern the following special categories of data:
        <ol>
          <li>
            The Service may Process voiceprints to recognize users and automatically tag each user as a redacted Speaker
            within the transcript.
          </li>
        </ol>
      </p>

      <h3>4. Processing operations</h3>
      <p>
        The Customer Personal Data will be subject to the following basic Processing activities:
        <ol>
          <li>
            The Service creates legible notes from recorded voice conversations using third-party Artificial
            Intelligence technologies for automated speech recognition (ASR), keyword extraction and voice to text
            synchronization. Data may be ingested directly via recording using a mobile or web application, upload of
            audio files or synchronization with HIPAA LINK to store or capture recorded audio. Audio is processed in
            Twilio’s cloud infrastructure, delivered to AssemblyAI for transcription and processing, then delivered to
            the HIPAA LINK application where it may be consumed or downloaded in a variety of formats.
          </li>
        </ol>
      </p>
    </>
  );

  const Appendix6 = () => (
    <>
      <h1>APPENDIX 6</h1>
      <h3>U.S. STATE PRIVACY LAW DATA PROCESSING AGREEMENT</h3>
      <p>
        This U.S. Privacy Law Data Processing Agreement (“U.S. State DPA”) is incorporated and made part of the Terms of
        Service (the “Terms”) between you, on behalf of you and your affiliates (“Customer”), and HIPAA LINK (“Vendor”)
        (each a “Party” and collectively the “Parties”) for so long as Vendor processes Personal Data on behalf of
        Customer. This U.S. State DPA prevails over any conflicting terms of the Terms.{' '}
      </p>

      <h2>1. Definitions. For the purposes of this U.S. State DPA</h2>
      <p>
        1.1. “State Privacy Laws” means, collectively, all U.S. state privacy laws and their implementing regulations,
        as amended or superseded from time to time, that apply generally to the processing of individuals’ Personal Data
        and that do not apply solely to specific industry sectors (e.g., financial institutions), specific demographics
        (e.g., children), or specific classes of information (e.g., health or biometric information). State Privacy Laws
        include the following:
      </p>
      <p>
        1.1.1. California Consumer Privacy Act of 2018 as amended by the California Privacy Rights Act of 2020
        (California Civil Code §§ 1798.100 to 1798.199) (“CPRA”);
      </p>
      <p>1.1.2. Colorado Privacy Act (Colorado Rev. Stat. §§ 6-1-1301 to 6-1-1313) (“ColoPA”);</p>
      <p>1.1.3. Connecticut Personal Data Privacy and Online Monitoring Act (Public Act No. 22-15) (“CPOMA”); </p>
      <p>1.1.4. Utah Consumer Privacy Act (Utah Code Ann. §§ 13-61-101 to 13-61-404) (“UCPA”); and</p>
      <p>1.1.5. Virginia Consumer Data Protection Act (Virginia Code Ann. §§ 59.1-575 to 59.1-585) (“VCDPA”).</p>
      <p>
        1.2. “Personal Data” means information that identifies, relates to, describes, is reasonably capable of being
        associated with, or could reasonably be linked, directly or indirectly, with an identified or identifiable
        natural person. Where applicable, Personal Data shall be interpreted consistent with the same or similar term
        under State Privacy Laws.
      </p>
      <p>1.3. “Share,” “Shared,” and “Sharing” have the meaning defined in the CPRA.</p>
      <p>1.4. “Sale” and “Selling” have the meaning defined in the State Privacy Laws.</p>
      <p>1.5. “Controller” means “Controller” or “Business” as those terms are defined in the State Privacy Laws.</p>
      <p>
        1.6. “Processor” means “Processor,” “Service Provider,” or “Contractor” as those terms are defined in the State
        Privacy Laws.
      </p>
      <p>1.7. “Consumer” has the meaning defined in the State Privacy Laws. </p>
      <p>1.8. “Processing,” “Process,” and “Processed” have the meaning defined in the State Privacy Laws. </p>
      <p>
        1.9. “Company Personal Data” means Personal Data provided by Company to, or which is collected on behalf of
        Company by, Vendor to provide services to Company pursuant to the Terms.
      </p>
      <p>
        1.10. In the event of a conflict in the meanings of defined terms in the State Privacy Laws, the meaning from
        the law applicable to the state of residence of the relevant Consumer applies.
      </p>

      <h2>2. Scope, Roles, and Termination.</h2>
      <p>
        2.1. Applicability - This U.S. State DPA applies only to Vendor’s Processing of Company Personal Data for the
        nature, purposes, and duration set forth in Appendix 1 and Appendix 5.
      </p>
      <p>
        2.2. Roles of the Parties - For the purposes of the Terms and this U.S. State DPA, Company is the Party
        responsible for determining the purposes and means of Processing Company Personal Data as the Controller and
        appoints Vendor as a Processor to Process Company Personal Data on behalf of Company for the limited and
        specific purposes set forth in Appendix 5.
      </p>
      <p>
        2.3. Obligations at Termination - Upon termination of the Terms, except as set forth therein or herein, Vendor
        will discontinue Processing and destroy or return Company Personal Data in its or its subcontractors and
        sub-processors possession without undue delay. Vendor may retain Company Personal Data to the extent required by
        law but only to the extent and for such period as required by such law and always provided that Vendor shall
        ensure the confidentiality of all such Company Personal Data.
      </p>

      <h2>3. Compliance.</h2>
      <p>
        3.1. Compliance with Obligations - In addition to the representations and warranties set forth in the Terms,
        Vendor further represents and warrants that Vendor, its employees, agents, subcontractors, and sub-processors
        (a) shall comply with the obligations of the State Privacy Laws, (b) shall provide the level of privacy
        protection required by the State Privacy Laws, (c) shall provide Company with all reasonably-requested
        assistance to enable Company to fulfill its own obligations under the State Privacy Laws, and (d) understand and
        shall comply with this U.S. State DPA. Upon the reasonable request of Company, Vendor shall make available to
        Company all information in Vendor’s possession necessary to demonstrate Vendor’s compliance with this
        subsection.
      </p>
      <p>
        3.2. Compliance Assurance - Company has the right to take reasonable and appropriate steps to ensure that Vendor
        uses Company Personal Data consistent with Company’s obligations under applicable State Privacy Laws and the
        security measures attached hereto at Appendix 3 and incorporated herein.
      </p>
      <p>
        3.3. Compliance Monitoring - Company has the right to monitor Vendor’s compliance with this U.S. State DPA
        through measures, including, but not limited to, ongoing manual reviews, automated scans, regular assessments,
        audits, or other annual technical and operational testing at least once every 12 months.
      </p>
      <p>
        3.4. Compliance Remediation - Vendor shall notify Company no later than five business days after determining
        that it can no longer meet its obligations under applicable State Privacy Laws. Upon receiving notice from
        Vendor in accordance with this subsection, Company may direct Vendor to take reasonable and appropriate steps to
        stop and remediate unauthorized use of Company Personal Data.
      </p>

      <h2>4. Restrictions on Processing.</h2>
      <p>
        4.1. Limitations on Processing - Vendor will Process Company Personal Data solely as instructed in the Terms and
        this U.S. State DPA. Except as expressly permitted by the State Privacy Laws, Vendor is prohibited from (i)
        Selling or Sharing Company Personal Data, (ii) retaining, using, or disclosing Company Personal Data for any
        purpose other than for the specific purpose of performing the Services specified in Appendix 5, (iii) retaining,
        using, or disclosing Company Personal Data outside of the direct business relationship between the Parties, and
        (iv) combining Company Personal Data with Personal Data obtained from, or on behalf of, sources other than
        Company, except as expressly permitted under applicable State Privacy Laws.{' '}
      </p>
      <p>
        4.2. Confidentiality - Vendor shall ensure that its employees, agents, subcontractors, and sub-processors are
        subject to a duty of confidentiality with respect to Company Personal Data.{' '}
      </p>
      <p>
        4.3. Subcontractors; Sub-processors -Vendor’s current subcontractors and sub-processors are available at
        https://www.HIPAALINK/subprocessors. Vendor shall notify Company of any intended changes concerning the addition
        or replacement of subcontractors or sub-processors. Further, Vendor shall ensure that Vendor’s subcontractors or
        sub-processors who Process Company Personal Data on Vendor’s behalf agree in writing to the same or equivalent
        restrictions and requirements that apply to Vendor in this U.S. State DPA and the Terms with respect to Company
        Personal Data, as well as to comply with the applicable State Privacy Laws.{' '}
      </p>
      <p>
        4.4. Right to Object - Company may object in writing to Vendor’s appointment of a new subcontractor or
        sub-processor on reasonable grounds by notifying Vendor in writing within 30 calendar days of receipt of notice
        in accordance with Section 4.3. In the event Company objects, the Parties shall discuss Company’s concerns in
        good faith with a view to achieving a commercially reasonable resolution.
      </p>

      <h2>5. Consumer Rights.</h2>
      <p>
        5.1. Vendor shall provide commercially reasonable assistance to Company for the fulfillment of Company’s
        obligations to respond to State Privacy Law-related Consumer rights requests regarding Company Personal Data.
      </p>
      <p>
        5.2. Company shall inform Vendor of any Consumer request made pursuant to the State Privacy Laws that they must
        comply with. Company shall provide Vendor with the information necessary for Vendor to comply with the request.{' '}
      </p>
      <p>
        5.3. Vendor shall not be required to delete any Company Personal Data to comply with a Consumer’s request
        directed by Company if retaining such information is specifically permitted by applicable State Privacy Laws;
        provided, however, that in such case, Vendor will promptly inform Company of the exceptions relied upon under
        applicable State Privacy Laws and Vendor shall not use Company Personal Data retained for any purpose other than
        provided for by that exception.
      </p>

      <h2>6. Deletion of Company Personal Data.</h2>
      <p>
        6.1. Upon direction by Company, and in any event no later than 30 days after receipt of a request from Company,
        Vendor shall promptly delete Company Personal Data as directed by Company, unless Vendor is required by law to
        retain such data, in which case Vendor shall, on ongoing basis, isolate and protect the security and
        confidentiality of such Personal Data and prevent any further processing except to the extent required by such
        law and shall destroy or return to Company all other Personal Data not required to be retained by Vendor by law.{' '}
      </p>

      <h2>7. Deidentified Data.</h2>
      <p>
        7.1. In the event that Company discloses or makes available Deidentified data (as such term is defined in the
        State Privacy Laws) to Vendor, Vendor shall not attempt to reidentify the information
      </p>

      <h2>8. Security.</h2>
      <p>
        8.1. Vendor and Company shall implement and maintain no less than commercially reasonable security procedures
        and practices, appropriate to the nature of the information, to protect Company Personal Data from unauthorized
        access, destruction, use, modification, or disclosure.
      </p>
      <p>8.2. Vendor shall fully comply with the security measures attached at Appendix 3.</p>

      <h2>9. Sale of Data.</h2>
      <p>
        9.1. The Parties acknowledge and agree that the exchange of Personal Data between the Parties does not form part
        of any monetary or other valuable consideration exchanged between the Parties with respect to the Terms or this
        U.S. State DPA.{' '}
      </p>

      <h2>10. Changes to Applicable Privacy Laws.</h2>
      <p>
        10.1. The Parties agree to cooperate in good faith to enter into additional terms to address any modifications,
        amendments, or updates to applicable statutes, regulations or other laws pertaining to privacy and information
        security, including, where applicable, the State Privacy Laws.
      </p>
    </>
  );

  return (
    <Dialog
      fullScreen={isMobile}
      {...props}
      sx={{
        zIndex: 9999,
        '.MuiPaper-root': {
          maxWidth: '1100px',
          width: '100%',
        },
      }}
    >
      <DialogContent
        sx={({ breakpoints }) => ({
          padding: '37px 29px 27px 46px',
          maxWidth: '100%',
          width: '100%',
          [breakpoints.down(599)]: {
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridTemplateRows: '.6fr auto .6fr',
            padding: '37px 29px 27px 36px',
          },
          [breakpoints.down(391)]: {
            gridTemplateRows: '.4fr auto .3fr',
            paddingBlock: '27px',
            paddingInlineStart: '16px',
            paddingInlineEnd: '14px',
          },
        })}
      >
        <Stack
          direction='column'
          justifyContent='space-between'
          gap='8px'
          sx={({ breakpoints }) => ({
            [breakpoints.down(599)]: {
              gridColumnStart: 1,
              gridColumnEnd: 2,
              gridRowStart: 1,
              gridRowEnd: 2,
            },
          })}
        >
          <Typography
            sx={{
              color: '#81CFDD',
              fontSize: '24px',
              fontWeight: 700,
              lineHeight: '36px',
              textAlign: 'left',
            }}
          >
            Terms of Service
          </Typography>
        </Stack>
        <Box
          sx={({ breakpoints }) => ({
            height: 408,
            overflow: 'auto',
            marginBlockStart: '18px',
            marginBlockEnd: '36px',
            paddingRight: '46px',
            [breakpoints.down(768)]: {
              paddingRight: '18px',
              marginBlockEnd: '18px',
            },
            [breakpoints.down(599)]: {
              gridColumnStart: 1,
              gridColumnEnd: 2,
              gridRowStart: 2,
              gridRowEnd: 3,
              height: 'calc(100vh - 286px)',
            },
          })}
        >
          <Typography
            component='p'
            sx={{
              color: '#979797',
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '25.5px',
              textAlign: 'justify',
              a: {
                color: 'blue',
                textDecoration: 'underline',
                margin: 0,
              },
            }}
          >
            <>
              <p>
                <strong>Effective April 24, 2024</strong>
              </p>
              <p>
                [See also{' '}
                <a href='https://www.hipaalink.net/privacy-policy/' target='_blank'>
                  Privacy Policy
                </a>
                ]
              </p>
              <p>
                Welcome, and thank you for your interest in HIPAALINK.net, Inc. (“HIPAA LINK,” “we,” or “us”) and our AI
                Progress Notes tool, the “Service”). These Terms of Service are a legally binding contract between you
                and HIPAA LINK regarding your use of the Service. As used herein, “you” or “your” means any entity,
                university, organization, or company accessing or using the Service (“Organization”) as well as any
                individual end user accessing and using the Service, as applicable and hereby agreeing to these Terms.
              </p>
              <p>
                <strong>PLEASE READ THE FOLLOWING TERMS CAREFULLY.</strong>
              </p>
              <p>
                <strong>
                  BY CLICKING “I ACCEPT,” OR BY DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING OR USING THE SERVICE
                </strong>
                , YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A CONDITION TO YOUR USE OF THE SERVICE, YOU AGREE
                TO BE BOUND BY, THE FOLLOWING TERMS AND CONDITIONS, INCLUDING HIPAA LINK’S{' '}
                <a href='https://www.hipaalink.net/privacy-policy/' target='_blank'>
                  PRIVACY POLICY
                </a>{' '}
                (TOGETHER, THESE “<strong>TERMS</strong>”). IF YOU ARE NOT ELIGIBLE, OR DO NOT AGREE TO THE TERMS, THEN
                YOU DO NOT HAVE OUR PERMISSION TO USE THE SERVICE. YOUR USE OF THE SERVICE, AND HIPAA LINK’S PROVISION
                OF THE SERVICE TO YOU, CONSTITUTES AN AGREEMENT BY HIPAA LINK AND BY YOU TO BE BOUND BY THESE TERMS.
              </p>
              <p>
                <strong>ARBITRATION NOTICE.</strong> Except for certain kinds of disputes described in Section 19, you
                agree that disputes arising under these Terms will be resolved by binding, individual arbitration, and
                BY ACCEPTING THESE TERMS, YOU AND HIPAA LINK ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO
                PARTICIPATE IN ANY CLASS ACTION OR REPRESENTATIVE PROCEEDING. YOU AGREE TO GIVE UP YOUR RIGHT TO GO TO
                COURT to assert or defend your rights under this contract (except for matters that may be taken to small
                claims court). Your rights will be determined by a NEUTRAL ARBITRATOR and NOT a judge or jury. (See
                Section 19.)
              </p>
              <p>
                You must be at least 13 years old to use the Service. By agreeing to these Terms, you represent and
                warrant to us that: (a) you are at least 13 years old; (b) you have not previously been suspended or
                removed from the Service; and (c) your registration and your use of the Service is in compliance with
                any and all applicable laws and regulations. If you are an Organization, the individual accepting these
                Terms on your behalf represents and warrants that they have authority to bind you to these Terms and you
                agree to be bound by these Terms.
              </p>
              <h2>1. HIPAA LINK Service Overview</h2>
              <p>
                The Service enables users to transform recorded voice conversations into transcribed notes. You may
                choose whether to use the free credits of the Service (“Free Credits”) or the prepaid service credits of
                the Service for which you may be required to pay fees (the “Prepaid Service”).
              </p>
              <h2>2. Eligibility.</h2>
              <p>
                You must be at least 18 years old to use the Service. By agreeing to these Terms, you represent and
                warrant to us that: (a) you are at least 18 years old; (b) you have not previously been suspended or
                removed from the Service; and (c) your registration and your use of the Service is in compliance with
                any and all applicable laws and regulations. If you are an Organization, the individual accepting these
                Terms on your behalf represents and warrants that they have authority to bind you to these Terms and you
                agree to be bound by these Terms.
              </p>
              <h2>3. Accounts and Registration.</h2>
              <h3>3.1 General.</h3>
              <p>
                To access the Service, you must register for an account. When you register for an account, you may be
                required to provide us with some information about yourself, such as your name, email address, phone
                number, or other contact information. If you wish to designate individual users to access and use the
                Service under your account (such users, “Authorized Users”), you may also be required to provide us with
                some information about such Authorized Users. You agree that the information you provide to us is
                accurate and that you will keep it accurate and up-to-date at all times, and that you have all necessary
                rights and approvals to provide such information.
              </p>
              <h3>3.2 Authorized Users.</h3>
              <p>
                a. If you are the owner of the account, you are responsible for: (i) identifying and authenticating all
                of your Authorized Users (ii) approving access by your Authorized Users to the Service and designating
                appropriate access controls in connection with your account, (iii) controlling against unauthorized
                access by your Authorized Users, and (iv) all activities that occur under your Authorized Users’
                usernames, passwords or accounts as a result of your Authorized Users’ access and use of the Service.
              </p>
              <p>
                b. If you received your credentials to access and use the Service (including certain features thereof)
                from your Organization or otherwise are accessing the Service under your Organization’s account, you
                acknowledge and agree that your access to the Service as provided by your Organization is subject to the
                agreement between HIPAA LINK and your Organization with respect to the Service. You also acknowledge
                that your Organization may terminate the access to the Service that they provide to you at any time.
                HIPAA LINK will have no liability to you in connection with any such removal or termination.
              </p>
              <p>c. When you join HIPAA LINK, you must do the following:</p>
              <ul>
                <li>
                  Use it in compliance with your organization’s terms and policies. Please note that HIPAA LINK accounts
                  are subject to the Organization’s control. The Organization administrators may prevent you from later
                  disassociating your account from the Organization account. They may also restrict or terminate your
                  access to a HIPAA LINK account.
                </li>
                <li>
                  Acknowledge that all the data under your account is owned by the Organization and the Organization
                  administrators have the right to access, disclose, restrict and remove information in your account.
                  This includes the data that predates when you joined the Organization account.
                </li>
              </ul>
              <h2>4. General Payment Terms.</h2>
              <p>
                This Service may require you to pay fees. Before you pay any fees, you will have the opportunity to
                review and accept the fees that you will be charged. All fees are in U.S. Dollars and are
                non-refundable.
              </p>
              <h3>4.1 Price.</h3>
              <p>
                HIPAA LINK may offer the option to prepay for certain Services through the purchase of credits (“Prepaid
                Service Credits”). Prepaid Service Credits represent the amount you have paid in advance for use of the
                respective Service.
              </p>
              <p>
                HIPAA LINK may also offer Service Credits free of charge as part of a promotional program rather than
                for purchase (“Promo Service Credits”). Promo Service Credits will not be applied against any sales,
                use, gross receipts, or similar transaction-based taxes that may be applicable to you.
              </p>
              <p>
                Service Credits are not legal tender or currency; are not redeemable, refundable, or exchangeable for
                any sum of money or monetary value; have no equivalent value in fiat currency; do not act as a
                substitute for fiat currency; and do not constitute or confer upon you any personal property right.
                Service Credits are non-transferable and may be used only in connection with the applicable Service.
              </p>
              <p>All sales of the Service, including sales of prepaid Services, are final.</p>
              <p>
                Your available Service Credit balance may be reviewed in your HIPAA LINK account. You are solely
                responsible for verifying that the proper amount of Service Credits has been added to or deducted from
                your balance. Your Service Credit balance is not a bank account, digital wallet, stored value account,
                or other payment device.
              </p>
              <p>
                We prohibit and do not recognize any purported transfers, sales, gifts, or trades of Service Credits.
                Evidence of any attempt to use, sell, or transfer Service Credits in any manner violates these Terms and
                may result in revocation, termination, or cancellation of the Service Credits and/or your use of the
                Services without refund and/or immediate suspension or termination of your account.
              </p>
              <p>
                We reserve the right to suspend or terminate your use of the Services in accordance with the Agreement,
                including suspending or terminating your Service Credit balance, in compliance with applicable law.
              </p>
              <p>
                We may modify these Terms at any time by posting a revised version on our website or by emailing the
                email associated with your account. The modified Terms will become effective upon posting or, if we
                notify you by email, as stated in the email message. By receiving or using any Service Credit after the
                effective date of any modification to these Terms, you agree to be bound by the modified Terms.
              </p>
              <h3>4.2 Authorization.</h3>
              <p>
                You authorize HIPAA LINK and its third party payment processors to charge all sums for the orders that
                you make and any level of Service you select as described in these Terms or published by HIPAA LINK,
                including all applicable taxes, to the payment method specified in your account. If you pay any fees
                with a credit card, HIPAA LINK or its third party payment processors may seek pre-authorization of your
                credit card account prior to your purchase to verify that the credit card is valid and has the necessary
                funds or credit available to cover your purchase.
              </p>
              <h2>5. Licenses.</h2>
              <h3>5.1 Limited License.</h3>
              <p>
                Subject to your complete and ongoing compliance with these Terms, HIPAA LINK grants you and/or your
                Organization a limited, non-exclusive, non-transferable, non-sublicensable, revocable license to access
                and use the Service.
              </p>
              <h3>5.2 License Restrictions.</h3>
              <p>
                Except and solely to the extent such a restriction is impermissible under applicable law, you may not:
                (a) reproduce, distribute, publicly display, or publicly perform the Service; (b) make modifications to
                the Service; (c) interfere with or circumvent any feature of the Service, including any security or
                access control mechanism; (d) access or use the Service in violation of any usage restrictions or other
                limitations associated with the level of Service you (or your Organization) have selected to access and
                purchased, if applicable. If you are prohibited under applicable law from using the Service, you may not
                use it.
              </p>
              <h3>5.3 Feedback.</h3>
              <p>
                If you choose to provide input and suggestions regarding problems with or proposed modifications or
                improvements to the Service (<strong>“Feedback”</strong>), then you hereby grant HIPAA LINK an
                unrestricted, perpetual, irrevocable, non-exclusive, fully-paid, royalty-free right to exploit the
                Feedback in any manner and for any purpose, including to improve the Service and create other products
                and services.
              </p>
              <h2>6. Ownership; Proprietary Rights.</h2>
              <p>
                The Service is owned and operated by HIPAA LINK. The visual interfaces, graphics, design, compilation,
                information, data, computer code (including source code or object code), products, software, services,
                and all other elements of the Service (<strong>“Materials”</strong>) provided by HIPAA LINK are
                protected by intellectual property and other laws. All Materials included in the Service are the
                property of HIPAA LINK or its third party licensors. Except as expressly authorized by HIPAA LINK, you
                may not make use of the Materials. HIPAA LINK reserves all rights to the Materials not granted expressly
                in these Terms.
              </p>
              <h2>7. Government Rights.</h2>
              <p>
                HIPAA LINK provides the Service, including any related software, data, and technology, for ultimate
                government end use solely in accordance with the following: The government hereby agrees that the
                Service qualify as “commercial” computer software. Government technical data and software rights related
                to the Service include only those rights customarily provided to the public as defined in these Terms.
                This customary commercial license is provided in accordance with FAR 12.211 (Technical Data) and FAR
                12.212 (Software) and, for Department of Defense transactions, DFAR 252.227-7015 (Technical Data –
                Commercial Items) and DFAR 227.7202-3 (Rights in Commercial Computer Software or Computer Software
                Documentation). If a government agency has a need for rights not granted under these Terms, it must
                negotiate with HIPAA LINK to determine if there are acceptable terms for granting those rights, and a
                mutually acceptable written addendum specifically granting those rights must be included in any
                applicable agreement.
              </p>
              <h2>8. Third Party Terms.</h2>
              <h2>8.1 Third Party Services and Linked Websites.</h2>
              <p>
                HIPAA LINK may provide tools through the Service that enable you to export information, including User
                Content, to third party services, including through features that may allow you to link your account on
                HIPAA LINK with an account on the third-party service, such as Twitter or Facebook, or through our
                implementation of third party buttons (such as “like” or “share” buttons). By using one of these tools,
                you agree that HIPAA LINK may transfer that information to the applicable third-party service. Third
                party services are not under HIPAA LINK’s control, and, to the fullest extent permitted by law, HIPAA
                LINK is not responsible for any third-party service’s use of your exported information. The Service may
                also contain links to third party websites. Linked websites are not under HIPAA LINK’s control, and
                HIPAA LINK is not responsible for their content.
              </p>
              <h2>9. User Content</h2>
              <h3>9.1 Voice Recordings.</h3>
              <p>
                The Service may allow you to record individual conversations and/or upload recorded conversations (
                <strong>“User Content”</strong>). The laws regarding the notice and notification requirements of such
                recorded conversations vary by location. You acknowledge and agree that you are solely responsible for
                providing any notices to, and obtaining consent from, individuals in connection with any recordings as
                required under applicable state and federal law.
              </p>
              <h3>9.2 Limited License Grant to HIPAA LINK.</h3>
              <p>
                Customer retains all ownership rights to the User Content processed using the service. You grant HIPAA
                LINK a worldwide, non-exclusive, royalty-free, fully paid right and license (with the right to
                sublicense) to host, store, transfer, display, perform, reproduce, modify, export, process, transform,
                and distribute your User Content, in whole or in part, in any media formats and through any media
                channels now known or hereafter developed in a manner that is under your control. Customer may delete
                User Content from their account where it may be stored for a specified period unless they choose to
                delete it. Once it has been permanently deleted from the user account either by direct action by the
                user or on expiration of the specified retention period, no record of the User Content is retained and
                the User Content cannot be recreated by the service.
              </p>
              <h3>9.3 License Grant to Users.</h3>
              <p>
                By providing User Content to or via the Service to other users of the Service, you grant those users a
                non-exclusive license to access, use, modify, and distribute that User Content as permitted by these
                Terms and the functionality of the Service.
              </p>
              <h3>9.4 User Content Representations and Warranties.</h3>
              <p>
                HIPAA LINK disclaims any and all liability in connection with User Content. You are solely responsible
                for your User Content and the consequences of providing User Content via the Service. By providing User
                Content via the Service, you affirm, represent, and warrant that:
              </p>
              <p>
                a. you are the creator and owner of the User Content, or have the necessary licenses, rights, consents,
                and permissions to authorize HIPAA LINK and users of the Service to use and distribute your User Content
                as necessary to exercise the licenses granted by you in this Section, in the manner contemplated by
                HIPAA LINK, the Service, and these Terms;
              </p>
              <p>
                b. your User Content, and the use of your User Content as contemplated by these Terms, does not and will
                not: (i) infringe, violate, or misappropriate any third party right, including any copyright, trademark,
                patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property
                or proprietary right; (ii) slander, defame, libel, or invade the right of privacy, publicity or other
                property rights of any other person; or (iii) cause HIPAA LINK to violate any law or regulation; and
              </p>
              <p>
                c. your User Content could not be deemed by a reasonable person to be objectionable, profane, indecent,
                pornographic, harassing, threatening, embarrassing, hateful, or otherwise inappropriate.
              </p>
              <h3>9.5 User Content Disclaimer.</h3>
              <p>
                We are under no obligation to edit or control User Content that you or other users post or publish, and
                will not be in any way responsible or liable for User Content. HIPAA LINK may, however, at any time and
                without prior notice, screen, remove, edit, or block any User Content that in our sole judgment violates
                these Terms or is otherwise objectionable. You understand that when using the Service you will be
                exposed to User Content from a variety of sources and acknowledge that User Content may be inaccurate,
                offensive, indecent, or objectionable. You agree to waive, and do waive, any legal or equitable right or
                remedy you have or may have against HIPAA LINK with respect to User Content. If notified by a user or
                content owner that User Content allegedly does not conform to these Terms, we may investigate the
                allegation and determine in our sole discretion whether to remove the User Content, which we reserve the
                right to do at any time and without notice. For clarity, HIPAA LINK does not permit copyright-infringing
                activities on the Service.
              </p>
              <h3>9.6 Monitoring Content.</h3>
              <p>
                HIPAA LINK does not control and does not have any obligation to monitor: (a) User Content; (b) any
                content made available by third parties; or (c) the use of the Service by its users. HIPAA LINK is not
                responsible for the use of any User Content by users or any third parties. You acknowledge and agree
                that HIPAA LINK reserves the right to, and may from time to time, monitor any and all information
                transmitted or received through the Service for operational and other purposes. If at any time HIPAA
                LINK chooses to monitor the content, HIPAA LINK still assumes no responsibility or liability for content
                or any loss or damage incurred as a result of the use of content.
              </p>
              <h3>9.7 Machine Learning.</h3>
              <p>
                HIPAA LINK shall have the right to collect and analyze data and other information relating to the
                provision, use and performance of various aspects of the Service and related systems and technologies (“
                <strong>Usage Data</strong>”). The Service may be implemented using machine learning systems with
                features and implementations designed to generate statistics, calibrate data models, and improve
                algorithms in the course of processing User Content and Usage Data (“<strong>Machine Learning</strong>
                ”). Nothing in these Terms prohibits Company from using such Machine Learning for testing, tuning,
                optimizing, validating, or otherwise enhancing the analytics, models, or algorithms underlying the
                Service. Nothing in these Terms gives you any rights in or to any part of the Service or the Machine
                Learning generated by Company or the Machine Learning generated in the course of providing the Service.
              </p>
              <h2>10. Communications.</h2>
              <h3>10.1 Email.</h3>
              <p>
                We may send you emails in the course of delivering the service to notify when User Content has been
                processed, shared with you or for other operational purposes.
              </p>
              <h2>11. Prohibited Conduct.</h2>
              <p>BY USING THE SERVICE YOU AGREE NOT TO:</p>
              <p>
                a. use the Service for any illegal purpose or in violation of any local, state, national, or
                international law;
              </p>
              <p>b. use the Service or any portion thereof for the direct or indirect benefit of any third parties; </p>
              <p>
                c. use the Service in connection with any direct or indirect commercial purposes, including in
                connection with any paid transcription workflow or as a value-added component of a commercial product or
                service;
              </p>
              <p>d. harass, threaten, demean, embarrass, or otherwise harm any other user of the Service;</p>
              <p>
                e. violate, or encourage others to violate, any right of a third party (including by act or omission),
                including by infringing or misappropriating any third party intellectual property or proprietary right;
              </p>
              <p>
                f. interfere with security-related features of the Service, including by: (i) disabling or circumventing
                features that prevent or limit use or copying of any content; or (ii) reverse engineering or otherwise
                attempting to discover the source code of any portion of the Service except to the extent that the
                activity is expressly permitted by applicable law;
              </p>
              <p>
                g. interfere with the operation of the Service or any user’s enjoyment of the Service, including by: (i)
                uploading or otherwise disseminating any virus, adware, spyware, worm, or other malicious code; (ii)
                making any unsolicited offer or advertisement to another user of the Service; (iii) collecting personal
                information about another user or third party without consent; or (iv) interfering with or disrupting
                any network, equipment, or server connected to or used to provide the Service;
              </p>
              <p>
                h. perform any fraudulent activity including impersonating any person or entity, claiming a false
                affiliation, accessing any other Service account without permission, or falsifying your age or date of
                birth;
              </p>
              <p>
                i. sell or otherwise transfer the access granted under these Terms or any Materials (as defined in
                Section 6) or any right or ability to view, access, or use any Materials; or
              </p>
              <p>
                j. attempt to do any of the acts described in this Section 11 or assist or permit any person in engaging
                in any of the acts described in this Section 11.
              </p>
              <h2>12. Digital Millennium Copyright Act.</h2>
              <h3>12.1 DMCA Notification.</h3>
              <p>
                We comply with the provisions of the Digital Millennium Copyright Act applicable to Internet service
                providers (17 U.S.C. §512, as amended). If you have an intellectual property rights-related complaint
                about material posted on the Service, you may contact our Designated Agent at the following address:
              </p>
              <p>
                <strong>HIPAALINK.net, Inc.</strong>
                <br />
                ATTN: Legal Department (Copyright Notification)
                <br />
                744 South Street #1081 <br />
                Philadelphia, PA 19147 <br />
                Email: contact@HIPAALINK.net <br />
                Any notice alleging that materials hosted by or distributed through the Service infringe intellectual
                property rights must include the following information:
              </p>
              <p>
                a. an electronic or physical signature of the person authorized to act on behalf of the owner of the
                copyright or other right being infringed;
              </p>
              <p>
                b. a description of the copyrighted work or other intellectual property that you claim has been
                infringed;
              </p>
              <p>
                c. a description of the material that you claim is infringing and where it is located on the Service;{' '}
              </p>
              <p>d. your address, telephone number, and email address;</p>
              <p>
                e. a statement by you that you have a good faith belief that the use of the materials on the Service of
                which you are complaining is not authorized by the copyright owner, its agent, or the law; and
              </p>
              <p>
                f. a statement by you that the above information in your notice is accurate and that, under penalty of
                perjury, you are the copyright or intellectual property owner or authorized to act on the copyright or
                intellectual property owner’s behalf.
              </p>
              <h3>12.2 Repeat Infringers.</h3>
              <p>
                HIPAA LINK will promptly terminate the accounts of users that are determined by HIPAA LINK to be repeat
                infringers.
              </p>
              <h2>13. Modification of these Terms.</h2>
              <p>
                ‍We reserve the right to change these Terms on a going-forward basis at any time. Please check these
                Terms periodically for changes. If a change to these Terms materially modifies your rights or
                obligations, we may require that you accept the modified Terms in order to continue to use the Service.
                Material modifications are effective upon your acceptance of the modified Terms. Immaterial
                modifications are effective upon publication. Except as expressly permitted in this Section 13, these
                Terms may be amended only by a written agreement signed by authorized representatives of the parties to
                these Terms. Disputes arising under these Terms will be resolved in accordance with the version of these
                Terms that was in effect at the time the dispute arose.
              </p>
              <h2>14.Term, Termination and Modification of the Service.</h2>
              <h3>14.1 Term.</h3>
              <p>
                These Terms are effective beginning when you accept the Terms or first download, install, access, or use
                the Service, and ending when terminated as described in Section 14.2.
              </p>
              <h3>14.2 Termination.</h3>
              <p>
                If you violate any provision of these Terms, your authorization to access the Service and these Terms
                automatically terminate. In addition, HIPAA LINK may, at its sole discretion, terminate these Terms or
                your account on the Service, or suspend or terminate your access to the Service, at any time for any
                reason or no reason, with or without notice. You may terminate your account and these Terms at any time
                by contacting customer service at support@HIPAALINK.net.
              </p>
              <h3>14.3 Effect of Termination.</h3>
              <p>
                Upon termination of these Terms: (a) your license rights will terminate and you must immediately cease
                all use of the Service; (b) you will no longer be authorized to access your account or the Service; (c)
                you must pay HIPAA LINK any unpaid amount that was due prior to termination; and (d) all payment
                obligations accrued prior to termination and Sections [5.3, 6, 7, 10, 14.3, 16, 17, 18, 19, and 20] will
                survive.
              </p>
              <h3>14.4 Modification of the Service.</h3>
              <p>
                HIPAA LINK reserves the right to modify or discontinue the Service at any time (including by limiting or
                discontinuing certain features of the Service), temporarily or permanently, without notice to you. HIPAA
                LINK will have no liability for any change to the Service or any suspension or termination of your
                access to or use of the Service.
              </p>
              <h2>15. Indemnity.</h2>
              <p>
                To the fullest extent permitted by law, you are responsible for your use of the Service, and you will
                defend and indemnify HIPAA LINK and its officers, directors, employees, consultants, affiliates,
                subsidiaries and agents (together, the “<strong>HIPAA LINK Entities</strong>”) from and against every
                claim brought by a third party, and any related liability, damage, loss, and expense, including
                reasonable attorneys’ fees and costs, arising out of or connected with: (a) your unauthorized use of, or
                misuse of, the Service; (b) your violation of any portion of these Terms, any representation, warranty,
                or agreement referenced in these Terms, or any applicable law or regulation; (c) your violation of any
                third party right, including any intellectual property right or publicity, confidentiality, other
                property, or privacy right; (d) the nature of content of Data processed by the Service; or (e) any
                dispute or issue between you and any third party. We reserve the right, at our own expense, to assume
                the exclusive defense and control of any matter otherwise subject to indemnification by you (without
                limiting your indemnification obligations with respect to that matter), and in that case, you agree to
                cooperate with our defense of those claims.
              </p>
              <h2>16. Disclaimers; No Warranties.</h2>
              <p>
                THE SERVICE AND ALL MATERIALS AND CONTENT AND TRANSCRIPTIONS AVAILABLE THROUGH THE SERVICE ARE PROVIDED
                “AS IS” AND ON AN “AS AVAILABLE” BASIS. HIPAA LINK DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
                OR IMPLIED, RELATING TO THE SERVICE AND ALL MATERIALS AND CONTENT AND TRANSCRIPTIONS AVAILABLE THROUGH
                THE SERVICE, INCLUDING: (A) ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                TITLE, QUIET ENJOYMENT, OR NON-INFRINGEMENT; AND (B) ANY WARRANTY ARISING OUT OF COURSE OF DEALING,
                USAGE, OR TRADE. HIPAA LINK DOES NOT WARRANT THAT THE SERVICE OR ANY PORTION OF THE SERVICE (OR YOUR
                ACCESS THERETO), OR ANY DATA, MATERIALS OR CONTENT OFFERED THROUGH OR PROCESSED BY THE SERVICE, WILL BE
                UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS, AND HIPAA LINK DOES NOT
                WARRANT THAT ANY OF THOSE ISSUES WILL BE CORRECTED. HIPAA LINK IS NOT RESPONSIBLE FOR THE FAILURE TO
                STORE OR MAINTAIN ANY USER DATA, CONTENT OR TRANSCRIPTIONS, USER COMMUNCIATIONS, ACCOUNT INFORMATION, OR
                PERSONAL SETTINGS. HIPAA LINK MAKES NO WARRANTY ABOUT THE COMPLETENESS OR ACCURACY OF THE TRANSCRIPTION.
              </p>
              <p>
                NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE SERVICE OR HIPAA LINK
                ENTITIES OR ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE SERVICE WILL CREATE ANY WARRANTY REGARDING
                ANY OF THE HIPAA LINK ENTITIES OR THE SERVICE THAT IS NOT EXPRESSLY STATED IN THESE TERMS. WE ARE NOT
                RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT FROM THE SERVICE AND YOUR DEALING WITH ANY OTHER SERVICE
                USER. YOU UNDERSTAND AND AGREE THAT YOU USE ANY PORTION OF THE SERVICE AT YOUR OWN DISCRETION AND RISK,
                AND THAT WE ARE NOT RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR
                MOBILE DEVICE USED IN CONNECTION WITH THE SERVICE) OR ANY LOSS OF, USE OR DISCLOSURE OF DATA, INCLUDING
                USER CONTENT.
              </p>
              <p>
                THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THIS SECTION APPLY TO THE FULLEST EXTENT PERMITTED BY
                LAW. HIPAA LINK does not disclaim any warranty or other right that HIPAA LINK is prohibited from
                disclaiming under applicable law.
              </p>
              <h2>17. Limitation of Liability.</h2>
              <p>
                TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE HIPAA LINK ENTITIES BE LIABLE TO YOU FOR
                ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOSS OF
                PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS) ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE OF,
                OR YOUR INABILITY TO ACCESS OR USE, THE SERVICE OR ANY MATERIALS OR CONTENT ON THE SERVICE, WHETHER
                BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR ANY OTHER LEGAL THEORY, AND
                WHETHER OR NOT ANY HIPAA LINK ENTITY HAS BEEN INFORMED OF THE POSSIBILITY OF DAMAGE.
              </p>
              <p>
                EXCEPT AS PROVIDED IN SECTION 19.5 AND TO THE FULLEST EXTENT PERMITTED BY LAW, THE AGGREGATE LIABILITY
                OF THE HIPAA LINK ENTITIES TO YOU FOR ALL CLAIMS ARISING OUT OF OR RELATING TO THE USE OF OR ANY
                INABILITY TO USE ANY PORTION OF THE SERVICE OR OTHERWISE UNDER THESE TERMS, WHETHER IN CONTRACT, TORT,
                OR OTHERWISE, IS LIMITED TO THE GREATER OF: (A) THE AMOUNT YOU HAVE PAID TO HIPAA LINK FOR ACCESS TO AND
                USE OF THE SERVICE IN THE 12 MONTHS PRIOR TO THE EVENT OR CIRCUMSTANCE GIVING RISE TO CLAIM; OR (B)
                $100.
              </p>
              <p>
                EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR
                EXCLUSION OF DAMAGES IS INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER THESE TERMS.
                THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE
                PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS. THE LIMITATIONS IN THIS
                SECTION 17 WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
              </p>
              <h2>18. Privacy and Information Security.</h2>
              <h3>18.1 Privacy Policy.</h3>
              <p>
                You acknowledge and agree that except as described in these Terms, any User Content, data, recordings,
                and information you enter into or upload to the Service or that we collect in connection with the
                Service (“<strong>Data</strong>”) will be processed as described in the HIPAA LINK{' '}
                <a href='https://www.hipaalink.net/privacy-policy/' target='_blank'>
                  Privacy Policy
                </a>
                . Please read the{' '}
                <a href='https://www.hipaalink.net/privacy-policy/' target='_blank'>
                  Privacy Policy
                </a>{' '}
                carefully.
              </p>
              <h3>18.2 Data Processing.</h3>
              <p>
                To the extent Data falls within the scope of the General Data Protection Regulation or the United
                Kingdom General Data Protection Regulation, the terms of the Data Processing Attachment in{' '}
                <strong>Appendix 1</strong> of these Terms (“<strong>DPA</strong>”) apply to the processing of any
                Customer Personal Data (as defined in the DPA). To the extent Data falls within the scope of any U.S.
                state privacy laws or their implementing regulations, the terms of the U.S. State Data Processing
                Agreement in Appendix 6 (“<strong>U.S. State DPA</strong>”) apply to the processing of any Personal Data
                (as defined in the U.S. State DPA).
              </p>
              <h3>18.3 Data.</h3>
              <p>
                As between you and HIPAA LINK, data that you enter into or upload to the Service is and will remain
                owned by you. You hereby grant HIPAA LINK the right to collect, process, transmit, store, use, and
                disclose data to provide the Service and as otherwise set forth in these Terms and the{' '}
                <a href='https://www.hipaalink.net/privacy-policy/' target='_blank'>
                  HIPAA LINK Privacy Policy
                </a>
                . When you own or join a HIPAA LINK Organization, your data is owned by the Organization, as applicable,
                as specified in section 3.2 above.
              </p>
              <h3>18.4 Use of Aggregated Data.</h3>
              <p>
                You acknowledge and agree that HIPAA LINK may collect, create, process, transmit, store, use, and
                disclose aggregated and/or deidentified data derived from Data or use of the Services (“
                <strong>Aggregated Data</strong>”) for its business purposes, including for machine learning and
                training, industry analysis, benchmarking, and analytics. All Aggregated Data will be in an aggregated
                and/or deidentified form only and will not identify you. Nothing in these Terms gives you any rights in
                or to any part of the Service or Aggregated Data.
              </p>
              <h3>18.5 Compliance.</h3>
              <p>
                You are solely responsible (a) for Data as entered into, supplied, accessed, or used by you and (b) for
                complying with any privacy and data protection laws and regulations applicable to Data or your use of
                the Service. You represent and warrant that you have obtained and will maintain all rights, consents,
                and authorizations required to grant HIPAA LINK the rights and licenses set forth in Section 18 and to
                enable HIPAA LINK to exercise its rights under the same without violation or infringement of the rights
                of any third party.
              </p>
              <h3>18.6 Information Security.</h3>
              <p>
                HIPAA LINK will employ commercially reasonable security measures that are designed to protect Data in
                its possession or control against unlawful or unauthorized access, use, alteration, or disclosure.{' '}
              </p>
              <h2>19. Dispute Resolution and Arbitration.</h2>
              <h3>19.1 Generally.</h3>
              <p>
                In the interest of resolving disputes between you and HIPAA LINK in the most expedient and
                cost-effective manner, and except as described in Section 19.2 and 19.3, you and HIPAA LINK agree that
                every dispute arising in connection with these Terms will be resolved by binding arbitration.
                Arbitration is less formal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a
                judge or jury, may allow for more limited discovery than in court, and can be subject to very limited
                review by courts. Arbitrators can award the same damages and relief that a court can award. This
                agreement to arbitrate disputes includes all claims arising out of or relating to any aspect of these
                Terms, whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory,
                and regardless of whether a claim arises during or after the termination of these Terms. YOU UNDERSTAND
                AND AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND HIPAA LINK ARE EACH WAIVING THE RIGHT TO A TRIAL
                BY JURY OR TO PARTICIPATE IN A CLASS ACTION.
              </p>
              <h3>19.2 Exceptions.</h3>
              <p>
                Despite the provisions of Section 19.1, nothing in these Terms will be deemed to waive, preclude, or
                otherwise limit the right of either party to: (a) bring an individual action in small claims court; (b)
                pursue an enforcement action through the applicable federal, state, or local agency if that action is
                available; (c) seek injunctive relief in a court of law in aid of arbitration; or (d) to file suit in a
                court of law to address an intellectual property infringement claim.
              </p>
              <h3>19.3 Opt-Out.</h3>
              <p>
                If you do not wish to resolve disputes by binding arbitration, you may opt out of the provisions of this
                Section 19 within 30 days after the date that you agree to these Terms by sending a letter to HIPAA
                LINK, Inc., Attention: Legal Department -Arbitration Opt-Out, 744 South Street #1081, Philadelphia, PA
                19147 that specifies: your full legal name, the email address associated with your account on the
                Service, and a statement that you wish to opt out of arbitration (“Opt-Out Notice”). Once HIPAA LINK
                receives your Opt-Out Notice, Section 19 will be void and any action arising out of these Terms will be
                resolved as set forth in Section 20.2. The remaining provisions of these Terms will not be affected by
                your Opt-Out Notice.
              </p>
              <h3>19.4 Arbitrator.</h3>
              <p>
                Any arbitration between you and HIPAA LINK will be settled under the Federal Arbitration Act and
                administered by the American Arbitration Association (“<strong>AAA”</strong>) under its Consumer
                Arbitration Rules (collectively, “<strong>AAA Rules</strong>”) as modified by these Terms. The AAA Rules
                and filing forms are available online at{' '}
                <a href='www.adr.org' target='_blank'>
                  www.adr.org
                </a>
                , by calling the AAA at 1-800-778-7879, or by contacting HIPAA LINK. The arbitrator has exclusive
                authority to resolve any dispute relating to the interpretation, applicability, or enforceability of
                this binding arbitration agreement.
              </p>
              <h3>19.5 Notice of Arbitration; Process.</h3>
              <p>
                A party who intends to seek arbitration must first send a written notice of the dispute to the other
                party by certified U.S. Mail or by Federal Express (signature required) or, only if that other party has
                not provided a current physical address, then by electronic mail (“
                <strong>Notice of Arbitration</strong>”). HIPAA LINK’s address for Notice is: HIPAALINK.net, Inc., 744
                South Street #1081, Philadelphia, PA 19147. The Notice of Arbitration must: (a) describe the nature and
                basis of the claim or dispute; and (b) set forth the specific relief sought (“<strong>Demand</strong>”).
                The parties will make good faith efforts to resolve the claim directly, but if the parties do not reach
                an agreement to do so within 30 days after the Notice of Arbitration is received, you or HIPAA LINK may
                commence an arbitration proceeding. All arbitration proceedings between the parties will be confidential
                unless otherwise agreed by the parties in writing. During the arbitration, the amount of any settlement
                offer made by you, or HIPAA LINK must not be disclosed to the arbitrator until after the arbitrator
                makes a final decision and award, if any. If the arbitrator awards you an amount higher than the last
                written settlement amount offered by HIPAA LINK in settlement of the dispute prior to the award, HIPAA
                LINK will pay to you the higher of: (i) the amount awarded by the arbitrator; or (ii) $10,000.
              </p>
              <h3>19.6 Fees.</h3>
              <p>
                If you commence arbitration in accordance with these Terms, HIPAA LINK will reimburse you for your
                payment of the filing fee, unless your claim is for more than $10,000, in which case the payment of any
                fees will be decided by the AAA Rules. Any arbitration hearing will take place at a location to be
                agreed upon in Philadelphia, PA, but if the claim is for $10,000 or less, you may choose whether the
                arbitration will be conducted: (a) solely on the basis of documents submitted to the arbitrator; (b)
                through a non-appearance based telephone hearing; or (c) by an in-person hearing as established by the
                AAA Rules in the county (or parish) of your billing address. If the arbitrator finds that either the
                substance of your claim or the relief sought in the Demand is frivolous or brought for an improper
                purpose (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b), then the
                payment of all fees will be governed by the AAA Rules. In that case, you agree to reimburse HIPAA LINK
                for all monies previously disbursed by it that are otherwise your obligation to pay under the AAA Rules.
                Regardless of the manner in which the arbitration is conducted, the arbitrator must issue a reasoned
                written decision sufficient to explain the essential findings and conclusions on which the decision and
                award, if any, are based. The arbitrator may make rulings and resolve disputes as to the payment and
                reimbursement of fees or expenses at any time during the proceeding and upon request from either party
                made within 14 days of the arbitrator’s ruling on the merits.
              </p>
              <h3>19.7 No Class Actions.</h3>
              <p>
                YOU AND HIPAA LINK AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL
                CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
                Further, unless both you and HIPAA LINK agree otherwise, the arbitrator may not consolidate more than
                one person’s claims and may not otherwise preside over any form of a representative or class proceeding.
              </p>
              <h3>19.8 Modifications to this Arbitration Provision.</h3>
              <p>
                If HIPAA LINK makes any future change to this arbitration provision, other than a change to HIPAA LINK’s
                address for Notice of Arbitration, you may reject the change by sending us written notice within 30 days
                of the change to HIPAA LINK’s address for Notice of Arbitration, in which case your account with HIPAA
                LINK will be immediately terminated and this arbitration provision, as in effect immediately prior to
                the changes you rejected will survive.
              </p>
              <h3>19.9 Enforceability.</h3>
              <p>
                If Section 19.7 or the entirety of this Section 19 is found to be unenforceable, or if HIPAA LINK
                receives an Opt-Out Notice from you, then the entirety of this Section 19 will be null and void and, in
                that case, exclusive jurisdiction and venue described in Section 20.2 will govern any action arising out
                of or related to these Terms.
              </p>

              <h2>20. Miscellaneous</h2>
              <h3>20.1 General Terms.</h3>
              <p>
                These Terms, together with the{' '}
                <a href='https://www.hipaalink.net/privacy-policy/' target='_blank'>
                  Privacy Policy
                </a>
                Privacy Policy and any other agreements expressly incorporated by reference into these Terms, are the
                entire and exclusive understanding and agreement between you and HIPAA LINK regarding your use of the
                Service. You may not assign or transfer these Terms or your rights under these Terms, in whole or in
                part, by operation of law or otherwise, without our prior written consent. We may assign these Terms at
                any time without notice or consent. The failure to require performance of any provision will not affect
                our right to require performance at any other time after that, nor will a waiver by us of any breach or
                default of these Terms, or any provision of these Terms, be a waiver of any subsequent breach or default
                or a waiver of the provision itself. Use of section headers in these Terms is for convenience only and
                will not have any impact on the interpretation of any provision. Throughout these Terms the use of the
                word “including” means “including but not limited to”. If any part of these Terms is held to be invalid
                or unenforceable, the unenforceable part will be given effect to the greatest extent possible, and the
                remaining parts will remain in full force and effect.
              </p>
              <h3>20.2 Governing Law.</h3>
              <p>
                These Terms are governed by the laws of the State of Pennsylvania without regard to conflict of law
                principles. You and HIPAA LINK submit to the personal and exclusive jurisdiction of the state courts and
                federal courts located within Philadelphia, PA for resolution of any lawsuit or court proceeding
                permitted under these Terms. We operate the Service from our offices in Pennsylvania, and we make no
                representation that Materials included in the Service are appropriate or available for use in other
                locations.
              </p>
              <h3>20.3 Additional Terms.</h3>
              <p>
                Your use of the Service is subject to all additional terms, policies, rules, or guidelines applicable to
                the Service or certain features of the Service that we may post on or link to from the Service (the “
                <strong>Additional Terms</strong>”). All Additional Terms are incorporated by this reference into, and
                made a part of, these Terms.
              </p>
              <h3>20.4 Consent to Electronic Communications.</h3>
              <p>
                By using the Service, you consent to receiving certain electronic communications from us as further
                described in our{' '}
                <a href='https://www.hipaalink.net/privacy-policy/' target='_blank'>
                  Privacy Policy
                </a>
                . Please read our{' '}
                <a href='https://www.hipaalink.net/privacy-policy/' target='_blank'>
                  Privacy Policy
                </a>{' '}
                to learn more about our electronic communications practices. You agree that any notices, agreements,
                disclosures, or other communications that we send to you electronically will satisfy any legal
                communication requirements, including that those communications be in writing.
              </p>
              <h3>20.5 Contact Information.</h3>
              <p>
                The Service is offered by HIPAA LINK, Inc. You may contact us by emailing us at support@HIPAALINK.net.
              </p>
              <h3>20.6 Notice to California Residents.</h3>
              <p>
                If you are a California resident, under California Civil Code Section 1789.3, you may contact the
                Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer
                Affairs in writing at 1625 N. Market Blvd., Suite S-202, Sacramento, California 95834, or by telephone
                at (800) 952-5210 in order to resolve a complaint regarding the Service or to receive further
                information regarding use of the Service.
              </p>

              <Appendix1 />
              <Appendix2 />
              <Appendix3 />
              <Appendix4 />
              <Appendix5 />
              <Appendix6 />
            </>
          </Typography>
        </Box>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          gap='16px'
          sx={({ breakpoints }) => ({
            [breakpoints.down(768)]: {
              flexDirection: 'column',
              paddingRight: '18px',
            },
            [breakpoints.down(599)]: {
              gridColumnStart: 1,
              gridColumnEnd: 2,
              gridRowStart: 3,
              gridRowEnd: 4,
            },
          })}
        >
          <Stack direction='row'>
            <FormControlLabel
              sx={({ breakpoints }) => ({
                [breakpoints.down(768)]: {
                  marginRight: 0,
                },
              })}
              label={
                <Typography
                  sx={({ breakpoints }) => ({
                    color: '#132F41',
                    fontSize: '17px',
                    fontWeight: 700,
                    lineHeight: '25.5px',
                    textAlign: 'left',
                    [breakpoints.down(768)]: {
                      textAlign: 'justify',
                    },
                  })}
                >
                  I confirm that I have read and accept these terms and conditions.
                </Typography>
              }
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  icon={<CheckboxIcon />}
                  checkedIcon={<CheckboxCheckedIcon />}
                />
              }
            />
          </Stack>
          <Stack direction='row' alignItems='center' justifyContent='space-between' gap='8px'>
            <Button
              variant='text'
              sx={{
                width: 167,
                height: 44,
                fontWeight: 600,
                fontSize: 17,
                borderRadius: 1,
              }}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              disabled={!isChecked}
              sx={{
                width: 167,
                height: 44,
                fontWeight: 600,
                fontSize: 17,
                borderRadius: 1,
                ':disabled': {
                  opacity: '0.25 !important',
                  backgroundColor: '#81CFDD !important',
                },
              }}
              onClick={handleConfirm}
            >
              Accept
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
