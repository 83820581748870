import { useCallback } from 'react';
import { Participant } from 'twilio-video';
import { Typography } from '@mui/material';

import { useAppSettings, useDialog, useMutedParticipants, useSelectedParticipant, useSignalrConnection } from 'hooks';
import { ParticipantDotMenuContext } from './context';
import { ISignalrConnectionContext } from 'context/signalrConnection';
import { TwoOptionsDialog } from 'components';
import { parseParticipantIdentity } from 'utils';
import { LayoutType } from 'types';
import { IMutedParticipantsContext } from 'context/mutedParticipants';

interface ParticipantDotMenuProviderProps {
  children?: JSX.Element;
}

export enum ParticipantDotMenuDialogType {
  RENAME,
  SPOTLIGHT,
  REMOVE,
  MUTE,
}

export function ParticipantDotMenuProvider({ children }: ParticipantDotMenuProviderProps) {
  const { showDialog } = useDialog();
  const { removeParticipantFromCall }: ISignalrConnectionContext = useSignalrConnection();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const { isGalleryViewActive, setLayoutType } = useAppSettings();
  const { mutedParticipantsId, muteParticipant }: IMutedParticipantsContext = useMutedParticipants();

  const onDialogOpen = useCallback(
    (type: ParticipantDotMenuDialogType, participant: Participant) => {
      switch (type) {
        case ParticipantDotMenuDialogType.RENAME:
          break;

        case ParticipantDotMenuDialogType.MUTE: {
          const [id] = parseParticipantIdentity(participant);
          const isMuted: boolean = mutedParticipantsId.some((mutedParticipantId: string) => mutedParticipantId === id);

          muteParticipant(id, !isMuted);

          break;
        }

        case ParticipantDotMenuDialogType.SPOTLIGHT: {
          const [, identity] = parseParticipantIdentity(participant);
          const unspotlight: boolean = participant === selectedParticipant;
          const modal = showDialog(TwoOptionsDialog, {
            title: unspotlight ? 'Unspotlight' : 'Spotlight',
            text: (
              <Typography variant='body2' component='p'>
                {unspotlight ? 'Unspotlight ' : 'Spotlight '} <b>{identity}</b> ?
              </Typography>
            ),
            confirmText: 'Yes',
            onConfirm: () => {
              modal.destroy();
              setSelectedParticipant(unspotlight ? null : participant);

              if (isGalleryViewActive) {
                setLayoutType(LayoutType.SPEAKER);
              }
            },
          });
          break;
        }

        case ParticipantDotMenuDialogType.REMOVE: {
          const [id, identity] = parseParticipantIdentity(participant);
          const modal = showDialog(TwoOptionsDialog, {
            title: 'REMOVE',
            text: (
              <Typography variant='body2' component='p'>
                Remove <b>{identity}</b> from the call ?
              </Typography>
            ),
            confirmText: 'Remove',
            onConfirm: () => {
              modal.destroy();
              removeParticipantFromCall(id);
            },
          });
          break;
        }
        default:
          break;
      }
    },
    [selectedParticipant, isGalleryViewActive, mutedParticipantsId],
  );

  return (
    <ParticipantDotMenuContext.Provider
      value={{
        onDialogOpen,
      }}
    >
      {children}
    </ParticipantDotMenuContext.Provider>
  );
}
