import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function CheckboxCheckedIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='19' height='19' viewBox='0 0 19 19' fill='none' {...props}>
      <rect x='0.5' y='0.5' width='18' height='18' rx='2.5' fill='white' stroke='#D1D1D1' />
      <path
        d='M4 9.5L7.66667 14L15 5'
        stroke='#81CFDD'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}
