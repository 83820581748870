import { MutableRefObject, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import uuid from 'react-uuid';

import { WhiteboardItemTypeEnum, WhiteboardTextItemData } from 'types';
import { useScreenSize, useWhiteboard } from 'hooks';

const LINE_HEIGHT_COEFFICIENT = 1.5;
const TEXT_INPUT_OFFSET_COEFFICIENT_FROM_LEFT_SIDE = 0.1;

const defaultTextProperties: TextProperties = {
  width: 200,
  texts: [],
  font: 'Arial, Helvetica, sans-serif',
  size: 12,
  color: '#000000',
  isBold: false,
  isItalic: false,
  aligment: 'left',
};

export type TextProperties = {
  width: number;
  texts: string[];
  font: string;
  size: number;
  color: string;
  isBold: boolean;
  isItalic: boolean;
  aligment: 'center' | 'left' | 'right';
};

export type TextProps = {
  ctx: MutableRefObject<CanvasRenderingContext2D | null>;
  canvasRef: RefObject<HTMLCanvasElement>;
  isTextSelected: boolean;
  zoom: number;
  canvasPosition: number[];
  moveTo(x: number, y: number): void;
};

export type TextType = {
  properties: TextProperties;
  drawingText: WhiteboardTextItemData | null;
  drawText(textData: WhiteboardTextItemData): void;
  changeTextProperties(properties: TextProperties): void;
};

export function useText({ ctx, canvasRef, zoom, canvasPosition, isTextSelected, moveTo }: TextProps): TextType {
  const { isTouchDevice, isMobile } = useScreenSize();
  const { addItem } = useWhiteboard();

  const [drawingText, setDrawingText] = useState<WhiteboardTextItemData | null>(null);
  const [properties, setProperties] = useState<TextProperties>(defaultTextProperties);

  const textData: MutableRefObject<WhiteboardTextItemData | null> = useRef<WhiteboardTextItemData | null>(null);
  const currentTextId = useRef<string | null>(null);
  const awayClickedAt = useRef<Date | null>(null);

  const drawText = useCallback(
    (textData: WhiteboardTextItemData) => {
      if (!textData.texts.length) {
        return;
      }

      let xDependOnAlign: number = textData.x;
      if (textData.aligment === 'right') {
        xDependOnAlign = textData.x + textData.width;
      }
      if (textData.aligment === 'center') {
        xDependOnAlign = textData.x + textData.width / 2;
      }

      const x: number = (xDependOnAlign - canvasPosition[0]) * zoom;
      const y: number = (textData.y - canvasPosition[1]) * zoom;
      const width: number = textData.width * zoom;
      const fontSize: number = textData.size * zoom;
      const lineHeight: number = fontSize * LINE_HEIGHT_COEFFICIENT;

      ctx.current!.font = `${textData.isBold ? 'bold ' : ''}${
        textData.isItalic ? 'italic ' : ''
      }${fontSize}px/${LINE_HEIGHT_COEFFICIENT} ${textData.font}`;
      ctx.current!.fillStyle = textData.color;
      ctx.current!.textAlign = textData.aligment;
      ctx.current!.textBaseline = 'hanging';

      const drawAt = (texts: string[], x: number, y: number): void => {
        if (!texts.length) {
          return;
        }

        for (let i = 1; i <= texts[0].length; i++) {
          const substring = texts[0].substring(0, i);

          if (ctx.current!.measureText(substring).width > width) {
            ctx.current!.fillText(texts[0].substring(0, i - 1), x, y);
            drawAt([texts[0].substring(i - 1), ...texts.slice(1)], x, y + lineHeight);
            return;
          }
        }
        ctx.current!.fillText(texts[0], x, y);
        drawAt(texts.slice(1), x, y + lineHeight);
      };

      drawAt(textData.texts, x, y);
    },
    [zoom, canvasPosition],
  );

  const changeTextProperties = useCallback((properties: TextProperties) => {
    setProperties(properties);
  }, []);

  const startText = useCallback(
    (clientX: number, clientY: number) => {
      const canvasRect: DOMRect = canvasRef.current!.getBoundingClientRect();
      const x: number = Math.round((clientX - canvasRect.left) / zoom + canvasPosition[0]);
      const y: number = Math.round((clientY - canvasRect.top) / zoom + canvasPosition[1]);

      currentTextId.current = uuid();
      textData.current = {
        ...properties,
        x,
        y,
      };
      setDrawingText(textData.current);

      if (isMobile) {
        const moveToX: number = x - (canvasRef.current!.width / zoom) * TEXT_INPUT_OFFSET_COEFFICIENT_FROM_LEFT_SIDE;
        const moveToY: number = y - canvasRef.current!.height / (2 * zoom);
        moveTo(moveToX, moveToY);
      }
    },
    [zoom, canvasPosition, properties, isMobile, moveTo],
  );

  const startTextMouse = useCallback(
    (event: MouseEvent) => {
      startText(event.clientX, event.clientY);
    },
    [startText],
  );

  const startTextTouch = useCallback(
    (event: TouchEvent) => {
      if (event.changedTouches.length > 1) {
        return;
      }

      startText(event.changedTouches.item(0)!.clientX, event.changedTouches.item(0)!.clientY);
    },
    [startText],
  );

  const actionAwayEditText = useCallback(() => {
    awayClickedAt.current = new Date();
  }, []);

  const stopEditText = useCallback(() => {
    if (!awayClickedAt.current || awayClickedAt.current!.getTime() + 200 < new Date().getTime()) {
      return;
    }

    addItem({
      id: currentTextId.current!,
      type: WhiteboardItemTypeEnum.Text,
      data: {
        x: textData.current!.x,
        y: textData.current!.y,
        width: properties.width,
        texts: properties.texts,
        font: properties.font,
        size: properties.size,
        color: properties.color,
        isBold: properties.isBold,
        isItalic: properties.isItalic,
        aligment: properties.aligment,
      },
    });

    textData.current = null;
    setDrawingText(textData.current);
    setProperties((prevProperties: TextProperties) => ({ ...prevProperties, texts: [] }));
  }, [properties, addItem]);

  useEffect(() => {
    if (isMobile) {
      setProperties((prevProperties: TextProperties) => ({ ...prevProperties, size: 24 }));
    }
  }, [isMobile]);

  useEffect(() => {
    if (isTextSelected && !drawingText) {
      if (isTouchDevice) {
        canvasRef.current!.addEventListener('touchend', startTextTouch);
        canvasRef.current!.addEventListener('mouseup', startTextMouse);
        return () => {
          canvasRef.current?.removeEventListener('touchend', startTextTouch);
          canvasRef.current?.removeEventListener('mouseup', startTextMouse);
        };
      } else {
        canvasRef.current!.addEventListener('mouseup', startTextMouse);

        return () => {
          canvasRef.current?.removeEventListener('mouseup', startTextMouse);
        };
      }
    }
  }, [isTextSelected, drawingText, isTouchDevice, startTextMouse, startTextTouch]);

  useEffect(() => {
    if (isTextSelected && drawingText) {
      if (isTouchDevice) {
        canvasRef.current!.addEventListener('touchstart', actionAwayEditText);
        canvasRef.current!.addEventListener('touchend', stopEditText);
        canvasRef.current!.addEventListener('mousedown', actionAwayEditText);
        canvasRef.current!.addEventListener('mouseup', stopEditText);

        return () => {
          canvasRef.current?.removeEventListener('touchstart', actionAwayEditText);
          canvasRef.current?.removeEventListener('touchend', stopEditText);
          canvasRef.current?.removeEventListener('mousedown', actionAwayEditText);
          canvasRef.current?.removeEventListener('mouseup', stopEditText);
        };
      } else {
        canvasRef.current!.addEventListener('mousedown', actionAwayEditText);
        canvasRef.current!.addEventListener('mouseup', stopEditText);

        return () => {
          canvasRef.current?.removeEventListener('mousedown', actionAwayEditText);
          canvasRef.current?.removeEventListener('mouseup', stopEditText);
        };
      }
    }
  }, [isTextSelected, drawingText, isTouchDevice, actionAwayEditText, stopEditText]);

  useEffect(() => {
    if (drawingText && !isTextSelected) {
      addItem({
        id: currentTextId.current!,
        type: WhiteboardItemTypeEnum.Text,
        data: {
          x: textData.current!.x,
          y: textData.current!.y,
          width: properties.width,
          texts: properties.texts,
          font: properties.font,
          size: properties.size,
          color: properties.color,
          isBold: properties.isBold,
          isItalic: properties.isItalic,
          aligment: properties.aligment,
        },
      });

      textData.current = null;
      setDrawingText(textData.current);
      setProperties((prevProperties: TextProperties) => ({ ...prevProperties, texts: [] }));
    }
  }, [isTextSelected, drawingText, properties]);

  return {
    properties,
    drawingText,
    drawText,
    changeTextProperties,
  };
}
