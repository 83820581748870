import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function CrownIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M8 1L11.1111 5.5L15 2.5L13.4444 10H2.55556L1 2.5L4.88889 5.5L8 1Z'
        fill='#FFD600'
        stroke='#FFD600'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  );
}
