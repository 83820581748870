import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function PinIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='15' height='18' viewBox='0 0 15 18' fill='none' {...props}>
      <path d='M9.16871 12.84L13.8849 4.79867C14.1218 4.39493 13.9812 3.87868 13.5711 3.64558L9.11541 1.11323C8.70526 0.880136 8.18087 1.01847 7.94403 1.4222L3.22784 9.4635L9.16871 12.84ZM9.16871 12.84L6.19829 11.1518L9.16871 12.84ZM9.16871 12.84L11.3966 14.1062L9.16871 12.84ZM3.22784 9.4635L1 8.1973L3.22784 9.4635ZM3.22784 9.4635L6.19829 11.1518L3.22784 9.4635ZM6.19829 11.1518L2.76831 17L6.19829 11.1518Z' />
      <path
        d='M6.19829 11.1518L2.76831 17M9.16871 12.84L13.8849 4.79867C14.1218 4.39493 13.9812 3.87868 13.5711 3.64558L9.11541 1.11323C8.70526 0.880136 8.18087 1.01847 7.94403 1.4222L3.22784 9.4635L9.16871 12.84ZM9.16871 12.84L6.19829 11.1518L9.16871 12.84ZM9.16871 12.84L11.3966 14.1062L9.16871 12.84ZM3.22784 9.4635L1 8.1973L3.22784 9.4635ZM3.22784 9.4635L6.19829 11.1518L3.22784 9.4635Z'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8.22157 1.71118L3.16602 8.82229L9.66602 12.3778L13.9993 3.84451L8.22157 1.71118Z' />
    </SvgIcon>
  );
}
