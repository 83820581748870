import React from 'react';
import classnames from 'classnames';
import { Button, Checkbox, DialogContent, FormControlLabel, Typography, useMediaQuery, Theme } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';

import { noop } from 'utils';
import { CheckboxCheckedIcon, CheckboxIcon } from 'assets';
import { DeviceSelectionContainer } from '../DeviceSelectionContainer';

import styles from './SettingsDialog.module.scss';

export interface SettingsDialogProps extends DialogProps {
  onConfirm: () => void;
}

export function SettingsDialog({ onConfirm, ...rest }: SettingsDialogProps) {
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Dialog
      {...rest}
      onClose={noop}
      className={classnames(styles.dialog, {
        [styles.dialogMobile]: isMobile,
      })}
    >
      <DialogContent className={styles.content}>
        <DeviceSelectionContainer withVideoPreview withAudioDiagnostic />

        <Button
          variant='contained'
          onClick={onConfirm}
          className={classnames(styles.formButton, {
            [styles.formButtonTablet]: isTablet,
          })}
        >
          Ok
        </Button>

        <FormControlLabel
          className={styles.checkboxContainer}
          control={
            <Checkbox
              className={styles.checkbox}
              icon={<CheckboxIcon className={styles.checkboxIcon} />}
              checkedIcon={<CheckboxCheckedIcon className={styles.checkboxIcon} />}
            />
          }
          label={
            <Typography component='p' variant='body5' sx={{ color: '#979797' }}>
              Skip future device checks
            </Typography>
          }
        />
      </DialogContent>
    </Dialog>
  );
}
