import { MutableRefObject, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { AudioInputTest, DiagnosticError, testAudioInputDevice } from '@twilio/rtc-diagnostics';
import { Typography } from '@mui/material';

import { MicIcon } from 'assets';
import { SELECTED_AUDIO_INPUT_KEY } from 'constants/';
import { useLocalStorageState, useScreenSize } from 'hooks';

import styles from './AudioDiagnostic.module.scss';

const INPUT_TEST_DURATION = 2000;
const AUDIO_LEVEL_THRESHOLD = 200;

type DiagnosticState = 'testing' | 'none';

export function AudioDiagnostic() {
  const { isDesktop } = useScreenSize();

  const [selectedAudioInputDevice] = useLocalStorageState<string>(SELECTED_AUDIO_INPUT_KEY);

  const [inputLevel, setInputLevel] = useState<number>(0);
  const [diagnosticState, setDiagnosticState] = useState<DiagnosticState>('none');
  const [error, setError] = useState<string>('');

  const audioInputTest: MutableRefObject<AudioInputTest | null> = useRef<AudioInputTest | null>(null);
  const progressRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const prevSelectedInputDeviceRef: MutableRefObject<string | null> = useRef<string | null>(null);
  const streams: MutableRefObject<MediaStream[]> = useRef<MediaStream[]>([]);

  const getAudioLevelPercentage = (level: number): number => {
    return (level * 100) / AUDIO_LEVEL_THRESHOLD;
  };

  const getErrorMessage = (error: DiagnosticError) => {
    let message = 'An unknown error has occurred';

    if (error) {
      if (error.domError && error.domError.code === 0) {
        return 'Unable to Access Media: The user has denied permission to use video. Please grant permission to the browser to access the camera.';
      }

      message = error.domError ? error.domError.toString() : error.message;
    }
    return message;
  };

  const testAudioInput = useCallback(() => {
    const options: AudioInputTest.Options = {
      duration: INPUT_TEST_DURATION,
      getUserMedia: () => {
        return navigator.mediaDevices
          .getUserMedia({ audio: { echoCancellation: true, deviceId: selectedAudioInputDevice } })
          .then((stream: MediaStream) => {
            streams.current.push(stream);
            return stream;
          });
      },
    };
    audioInputTest.current?.stop();
    audioInputTest.current = testAudioInputDevice(options);

    setDiagnosticState('testing');

    audioInputTest.current.on(AudioInputTest.Events.Volume, (value: number) => {
      setInputLevel(getAudioLevelPercentage(value));
    });

    audioInputTest.current.on(AudioInputTest.Events.End, () => {
      setDiagnosticState('none');
    });

    audioInputTest.current.on(AudioInputTest.Events.Error, (diagnosticError: DiagnosticError) => {
      setError(getErrorMessage(diagnosticError));
    });
  }, [selectedAudioInputDevice]);

  useEffect(() => {
    if (diagnosticState === 'none' && !error) {
      testAudioInput();
    }
  }, [diagnosticState, error, testAudioInput]);

  useEffect(() => {
    if (prevSelectedInputDeviceRef.current !== selectedAudioInputDevice && diagnosticState !== 'none') {
      audioInputTest.current?.stop();
      setDiagnosticState('none');
      prevSelectedInputDeviceRef.current = selectedAudioInputDevice;
    }
  }, [selectedAudioInputDevice, diagnosticState]);

  useEffect(() => {
    if (progressRef.current) {
      progressRef.current!.style.width = inputLevel + '%';
    }
  }, [inputLevel]);

  useEffect(() => {
    return () => {
      audioInputTest.current?.stop();

      // audioInputTest.current?.stop() does not work correctly, so we need to stop streamTracks manually
      streams.current.forEach((stream: MediaStream) => {
        stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
      });
    };
  }, []);

  return error ? (
    <div>
      <Typography variant='body' color='error' component='span'>
        {error}
      </Typography>
    </div>
  ) : (
    <div>
      <div className={styles.title}>
        <Typography component='p' variant={isDesktop ? 'body5' : 'body2'}>
          Audio
        </Typography>
      </div>

      <div className={styles.audioLevel}>
        <MicIcon />

        <div className={styles.progressBar}>
          <div ref={progressRef} className={styles.progress}></div>
        </div>
      </div>
    </div>
  );
}
