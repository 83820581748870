import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function CrossCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx='20.8867' cy='21' r='17' fill='white' />
      <path
        d='M35.6771 6.20801C27.5521 -1.91699 14.2188 -1.91699 6.09375 6.20801C-2.03125 14.333 -2.03125 27.6663 6.09375 35.7913C14.2188 43.9163 27.3437 43.9163 35.4687 35.7913C43.5937 27.6663 43.8021 14.333 35.6771 6.20801ZM26.7187 29.7497L20.8854 23.9163L15.0521 29.7497L12.1354 26.833L17.9688 20.9997L12.1354 15.1663L15.0521 12.2497L20.8854 18.083L26.7187 12.2497L29.6354 15.1663L23.8021 20.9997L29.6354 26.833L26.7187 29.7497Z'
        fill='#81CFDD'
      />
    </SvgIcon>
  );
}
