import { IconButton, Typography } from '@mui/material';

import { MinusButtonIcon, PlusButtonIcon } from 'assets';

import styles from './SeatsCountSelector.module.scss';

type SeatsCountSelectorProps = {
  count: number;
  decreaseDisabled?: boolean;
  onIncrease(): void;
  onDecrease(): void;
};

export function SeatsCountSelector({ count, decreaseDisabled, onDecrease, onIncrease }: SeatsCountSelectorProps) {
  return (
    <div className={styles.seatsCountSelector}>
      <IconButton onClick={onDecrease} disabled={!!decreaseDisabled}>
        <MinusButtonIcon color={decreaseDisabled ? 'disabled' : 'primary'} />
      </IconButton>

      <div className={styles.seatsCount}>
        <Typography variant='body5' component='span'>
          {count}
        </Typography>
      </div>

      <IconButton onClick={onIncrease}>
        <PlusButtonIcon color='primary' />
      </IconButton>
    </div>
  );
}
