import { ZipFileIcon, Mp4FileIcon, ImageFileIcon, UnknownFileIcon } from 'assets';

export function getFileIconByType(type: string): JSX.Element {
  switch (type) {
    case 'application/x-zip-compressed':
      return <ZipFileIcon />;
    case 'video/mp4':
      return <Mp4FileIcon />;
    case 'image/jpeg':
    case 'image/svg+xml':
    case 'image/png':
      return <ImageFileIcon />;
    default:
      return <UnknownFileIcon />;
  }
}
