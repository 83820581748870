import { Dispatch, MutableRefObject, SetStateAction, createContext } from 'react';
import { EMDRCounterSettings, EMDRSettings } from 'types';

export type EMDRState = 'loading' | 'off' | 'play' | 'pause';

export interface IEMDRContext {
  state: EMDRState;
  showDurationCountdown: boolean;
  settings: EMDRSettings;
  counterSettings: EMDRCounterSettings;
  soundNames: string[];
  imagesRef: MutableRefObject<{ [key: string]: HTMLImageElement }>;
  audiosRef: MutableRefObject<{ [key: string]: Howl }>;
  isImgPresetsLoaded: boolean;

  setEmdrCounterSettings: Dispatch<SetStateAction<EMDRCounterSettings>>;
  startEmdr(): void;
  stopEmdr(): void;
  play(): void;
  pause(): void;
  changeSettings(settings: EMDRSettings): void;
  updatePresets(number: number): void;
  switchPreset(number: number): void;
  toggleShowDurationCountdown(): void;
}

export const EMDRContext = createContext<IEMDRContext | null>(null);
export const EMDRConsumer = EMDRContext.Consumer;
