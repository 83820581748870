import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function MicIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='19' height='25' viewBox='0 0 19 25' fill='none' {...props}>
      <path d='M4.35644 12.5V5.35714C4.35644 4.65363 4.4983 3.95701 4.77392 3.30705C5.04954 2.65709 5.45353 2.06653 5.96281 1.56907C6.47209 1.07161 7.07669 0.67701 7.7421 0.407788C8.4075 0.138567 9.12068 0 9.84091 0C10.5611 0 11.2743 0.138567 11.9397 0.407788C12.6051 0.67701 13.2097 1.07161 13.719 1.56907C14.2283 2.06653 14.6323 2.65709 14.9079 3.30705C15.1835 3.95701 15.3254 4.65363 15.3254 5.35714V12.5009C15.3253 13.9217 14.7473 15.2842 13.7187 16.2888C12.6901 17.2934 11.295 17.8577 9.84047 17.8576C8.38589 17.8575 6.99094 17.2929 5.96249 16.2882C4.93404 15.2835 4.35632 13.9208 4.35644 12.5V12.5ZM18.1176 12.5061C17.8771 12.4825 17.6366 12.552 17.448 12.6998C17.2594 12.8475 17.1376 13.0616 17.1089 13.2961C16.9083 15.0418 16.0561 16.6542 14.7153 17.825C13.3745 18.9958 11.639 19.643 9.84051 19.6429C8.04203 19.6427 6.30665 18.9954 4.96598 17.8244C3.62531 16.6534 2.77335 15.041 2.57292 13.2952C2.54341 13.0611 2.42141 12.8476 2.23295 12.7002C2.0445 12.5527 1.80451 12.4831 1.56422 12.5061C1.32315 12.5323 1.10258 12.6508 0.95094 12.8357C0.799301 13.0206 0.728998 13.2568 0.755472 13.4923C0.988157 15.5184 1.92722 17.4047 3.41509 18.8346C4.90295 20.2645 6.84906 21.151 8.92683 21.3454V24.1071C8.92683 24.3439 9.02314 24.571 9.19456 24.7385C9.36599 24.9059 9.59848 25 9.84091 25C10.0833 25 10.3158 24.9059 10.4873 24.7385C10.6587 24.571 10.755 24.3439 10.755 24.1071V21.3455C12.8331 21.1516 14.7796 20.2651 16.2676 18.8349C17.7555 17.4047 18.6944 15.5179 18.9264 13.4914C18.9528 13.256 18.8825 13.02 18.7308 12.8352C18.5792 12.6504 18.3586 12.5321 18.1176 12.5061V12.5061Z' />
    </SvgIcon>
  );
}
