import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function CheckIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='13' height='10' viewBox='0 0 13 10' fill='none' {...props}>
      <path
        d='M1.55225 5.11765L5.00001 9.23529L11.8955 1'
        stroke={props.stroke ?? '#626363'}
        strokeWidth={props.strokeWidth ?? '1.5'}
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </SvgIcon>
  );
}
