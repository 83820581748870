import { MutableRefObject, useEffect, useRef } from 'react';
import { RemoteParticipant } from 'twilio-video';
import { Conversation } from '@twilio/conversations';

import { useParticipants, useVideo } from 'hooks';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';
import { noop, parseIdentity } from 'utils';

const CONVERSATION_LIFETIME_IN_MILLISECONDS = 86400000; // 1 day
// const CONVERSATION_LIFETIME_IN_MILLISECONDS = 60000; // 1 minute

type ChatConversationsDeletionProps = {
  allConversations: Conversation[];
};

export function useChatConversationsDeletion({ allConversations }: ChatConversationsDeletionProps) {
  const { room } = useVideo();
  const user = useAppSelector(selectUser);
  const participants: RemoteParticipant[] = useParticipants();

  const prevParticipants: MutableRefObject<RemoteParticipant[]> = useRef<RemoteParticipant[]>([]);

  useEffect(() => {
    if (prevParticipants.current !== participants && user) {
      const removedParticipantsChatsNames: string[] = [];

      prevParticipants.current.forEach((prevParticipant: RemoteParticipant) => {
        if (!participants.includes(prevParticipant)) {
          removedParticipantsChatsNames.push(
            `$chat-${user.clinicUser.id}-${parseIdentity(prevParticipant.identity)[0]}`,
          );
        }
      });

      allConversations.forEach((conversation: Conversation) => {
        if (removedParticipantsChatsNames.includes(conversation?.uniqueName ?? '')) {
          conversation.delete().catch(noop);
          conversation.leave().catch(noop);
        }
      });
    }

    prevParticipants.current = participants;
  }, [user, participants, allConversations]);

  // If user reload a page and have patients in waiting room, conversations with this patients will not delete
  // This useEffect use for deleting or leaving all conversation which was created more than 1 day ago
  useEffect(() => {
    if (user) {
      const currentDateTime: Date = new Date(user?.clinicUser?.subscriptionInfo?.serverTime ?? new Date());

      allConversations.forEach((conversation: Conversation) => {
        if (
          (conversation.dateCreated?.getTime() ?? 0) + CONVERSATION_LIFETIME_IN_MILLISECONDS <
          currentDateTime.getTime()
        ) {
          conversation.delete().catch(noop);
          conversation.leave().catch(noop);
        }
      });
    }
  }, [allConversations, user]);

  // This useEffect use for deleting all group chats when room is closed
  useEffect(() => {
    if (!room && user) {
      allConversations.forEach((conversation: Conversation) => {
        if (!conversation.uniqueName?.startsWith('$chat')) {
          conversation.delete().catch(noop);
          conversation.leave().catch(noop);
        }
      });
    }
  }, [allConversations, room, user]);

  return {};
}
