import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export function UserIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='88' height='100' viewBox='0 0 88 100' fill='none' {...props}>
      <path d='M44 50C57.8875 50 69.1429 38.8086 69.1429 25C69.1429 11.1914 57.8875 0 44 0C30.1125 0 18.8571 11.1914 18.8571 25C18.8571 38.8086 30.1125 50 44 50ZM61.6 56.25H58.3196C53.9589 58.2422 49.1071 59.375 44 59.375C38.8929 59.375 34.0607 58.2422 29.6804 56.25H26.4C11.825 56.25 0 68.0078 0 82.5V90.625C0 95.8008 4.22321 100 9.42857 100H78.5714C83.7768 100 88 95.8008 88 90.625V82.5C88 68.0078 76.175 56.25 61.6 56.25Z' />
    </SvgIcon>
  );
}
