import { useEffect, useMemo } from 'react';
import { Dialog, Theme, useMediaQuery } from '@mui/material';

import { IEMDRContext, IVideoContext } from 'context';
import { useEMDR, useVideo } from 'hooks';
import { EMDRContainer } from 'components';
import { ApiUser } from 'types';
import { useAppSelector } from 'store';
import { selectUser } from 'store/user';

import styles from './EMDRContainerFullScreen.module.scss';

export function EMDRContainerFullScreen() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { room }: IVideoContext = useVideo();

  const { state, settings }: IEMDRContext = useEMDR();
  const user: ApiUser | null = useAppSelector(selectUser);

  const showEMDR: boolean = useMemo(() => {
    return state === 'play' && !user && !isMobile && !!room;
  }, [state, user, isMobile, room]);

  useEffect(() => {
    if (showEMDR) {
      const html = document.querySelector('html')!;
      html.style.backgroundColor = '#' + settings.backgroundColor;
    } else {
      const html = document.querySelector('html')!;
      html.style.backgroundColor = '#fafafa';
    }
  }, [showEMDR, settings]);

  return (
    <Dialog fullScreen open={showEMDR}>
      <EMDRContainer fullScreen={true} />
    </Dialog>
  );
}
