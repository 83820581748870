import { Theme, Typography, useMediaQuery } from '@mui/material';
import classnames from 'classnames';

import { CheckIcon } from 'assets';
import { HIPAA_BENEFITS } from 'constants/texts';

import styles from './RegisterLayout.module.scss';
import image from '../../assets/images/RegistrationLeftImage.png';
import girlImg from '../../assets/images/girl.png';

export type LeftSideType = '30DaysFree' | 'promo' | 'benefits';

type RegisterLayoutProps = {
  children: JSX.Element;
  leftSideType: LeftSideType;
  headers: string[];
  selectedHeader: number;
  leftSide?: JSX.Element | null;
  hideLeftSide?: boolean;
};

export function RegisterLayout({
  children,
  leftSide,
  leftSideType,
  headers,
  selectedHeader,
  hideLeftSide,
}: RegisterLayoutProps) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.header}>
          {headers.map((title: string, index: number) => (
            <div
              key={index}
              className={classnames(styles.titleContainer, {
                [styles.titleContainerSelected]: selectedHeader > index,
                [styles.titleContainerNextSelected]: selectedHeader > index + 1 || selectedHeader === 3,
              })}
            >
              <div className={styles.titleContainerRound}>
                {!isMobile && <div className={styles.number}>{index + 1}</div>}
                <Typography variant={!isMobile ? 'body4' : 'body1'}>{title}</Typography>
              </div>
            </div>
          ))}
        </div>

        {!hideLeftSide && leftSideType === 'benefits' && (
          <div className={styles.leftSideBenefits}>
            <img src={image} />

            <Typography variant={isMobile ? 'h3' : 'h2'} component={isMobile ? 'h3' : 'h2'}>
              Welcome!
            </Typography>

            <Typography variant={isMobile ? 'h3' : 'h2'} component={isMobile ? 'h3' : 'h2'}>
              Benefits of HIPAA<span className={styles.link}>LINK</span>
            </Typography>

            <ul className={styles.dataText}>
              {HIPAA_BENEFITS.map((dataTextItem, index) => (
                <li key={index}>
                  <span className={styles.dataCheckIcon}>
                    <CheckIcon />
                  </span>

                  <Typography variant={isMobile ? 'body2' : 'body5'} component='span'>
                    {dataTextItem}
                  </Typography>
                </li>
              ))}
            </ul>
          </div>
        )}

        {!hideLeftSide && leftSideType === '30DaysFree' && (
          <div className={styles.leftSide30DaysFree}>
            <Typography variant='h2' component='h2'>
              Get started with your
            </Typography>

            <Typography variant='h1' component='h1'>
              30-Day Free Trial Now!
            </Typography>

            <div className={styles.box}>
              <img src={girlImg} className={styles.girl} />

              <div className={styles.texts}>
                <Typography variant='body2' component='span' className={styles.comment}>
                  “HIPAA LINK has been a game changer for my therapy practice - the platform is easy to use and all the
                  features I need to connect with my clients securely are readily available.”
                </Typography>

                <Typography variant='body4' component='span'>
                  Jessica Tran, LCSW
                </Typography>

                <Typography variant='body4' component='span' color='#81CFDD'>
                  Psychotherapist
                </Typography>
              </div>
            </div>
          </div>
        )}

        {!hideLeftSide && leftSideType === 'promo' && leftSide}

        <div className={styles.rightSide}>{children}</div>
      </div>
    </div>
  );
}
