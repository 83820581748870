import { useCallback, useEffect, useMemo } from 'react';
import { Box, Theme, useMediaQuery } from '@mui/material';
import classnames from 'classnames';
import {
  AINotesMenu,
  NotesDialog,
  NotificationSettings,
  PatientQueue,
  VideoPreviewWithControls,
  WelcomeDialog,
} from 'components';
import { MyWaitingRoom } from './components';
import { IFirstLoginTooltipsContext, ISignalrConnectionContext } from 'context';
import {
  useChatNewMessageSound,
  useDialog,
  useFirstLoginTooltips,
  useOpenChatOnNewMessage,
  useSignalrConnection,
  useSubscription,
  useVideo,
} from 'hooks';
import { ApiUser, ApiUserTooltipStagePassedEnum, SubscriptionStatusEnum } from 'types';
import { useAppDispatch, useAppSelector } from 'store';
import { getRoomHistory } from 'store/call';
import { selectUser } from 'store/user';

import styles from './ProviderRoomPage.module.scss';

export function ProviderRoomPage() {
  // will be moved to Chat component
  useChatNewMessageSound();
  useOpenChatOnNewMessage();

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'md'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const dispatch = useAppDispatch();
  const user: ApiUser | null = useAppSelector(selectUser);
  const subscription = useSubscription();

  const {
    signalrConnectionId,
    events,
    getPatientsList,
    addEventListener,
    removeEventListener,
  }: ISignalrConnectionContext = useSignalrConnection();
  const { showDialog } = useDialog();
  const { room } = useVideo();

  const { tooltipsState, accept }: IFirstLoginTooltipsContext = useFirstLoginTooltips();

  const shouldShowWelcomeDialog = useMemo(() => {
    return user?.tooltipStagePassed === ApiUserTooltipStagePassedEnum.None && !tooltipsState;
  }, [user, tooltipsState]);

  const shouldShowTrialWelcomeDialog = useMemo(() => {
    return shouldShowWelcomeDialog && subscription?.subscriptionStatus === SubscriptionStatusEnum.Trial;
  }, [subscription, shouldShowWelcomeDialog]);

  const showWelcomeDialog = () => {
    const modal = showDialog(WelcomeDialog, {
      onConfirm: () => {
        modal.destroy();
        accept();
      },
    });
  };

  useEffect(() => {
    if (shouldShowTrialWelcomeDialog) {
      showWelcomeDialog();
    } else if (shouldShowWelcomeDialog) {
      accept();
    }
  }, [user, subscription]);

  useEffect(() => {
    if (room) {
      return () => {
        room?.disconnect();
      };
    }
  }, [room]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, 500);
  }, []);

  useEffect(() => {
    if (signalrConnectionId) {
      getPatientsList();
    }
  }, [signalrConnectionId]);

  useEffect(() => {
    events.on('updatedStatusDataSent', getPatientsList);
  }, [events]);

  const onCallEnded = useCallback(() => {
    dispatch(getRoomHistory()).then(() => {
      const modal = showDialog(NotesDialog, {
        onConfirm: () => {
          modal.destroy();
        },
      });
    });
  }, []);

  useEffect(() => {
    addEventListener('callEnded', onCallEnded);

    return () => {
      removeEventListener('callEnded', onCallEnded);
    };
  }, [onCallEnded]);

  return (
    <Box
      className={classnames(styles.container, {
        [styles.containerRoomNameNull]: !user?.clinicUser.roomName,
      })}
    >
      {isDesktop && (
        <>
          <PatientQueue
            className={styles.patientQueue}
            additionalMenuSlot={<AINotesMenu aiCreditsCount={user?.clinicUser.aiCreditsCount} />}
          />
          <MyWaitingRoom className={styles.waitingRoom} />
        </>
      )}

      {isTablet && (
        <>
          {user?.clinicUser.roomName && (
            <>
              <PatientQueue
                className={styles.patientQueue}
                additionalMenuSlot={<AINotesMenu aiCreditsCount={user?.clinicUser.aiCreditsCount} />}
              />
              <VideoPreviewWithControls />
            </>
          )}
          <MyWaitingRoom className={styles.waitingRoom} />
        </>
      )}

      {isMobile && (
        <>
          {user?.clinicUser.roomName && (
            <>
              <MyWaitingRoom className={styles.waitingRoom} />

              <VideoPreviewWithControls />

              <PatientQueue
                className={styles.patientQueue}
                additionalMenuSlot={<AINotesMenu aiCreditsCount={user?.clinicUser.aiCreditsCount} />}
              />

              <div className={styles.notificationSettingsContainer}>
                <NotificationSettings />
              </div>
            </>
          )}

          {!user?.clinicUser.roomName && <MyWaitingRoom className={styles.waitingRoom} />}
        </>
      )}
    </Box>
  );
}
