import { MutableRefObject, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Theme, Typography, useMediaQuery } from '@mui/material';

import { CheckCircleIcon } from 'assets';
import { STRIPE_ACCOUNT_ONBORDING_COMPLEATED, STRIPE_ACCOUNT_ONBORDING_REJECTED } from 'constants/';
import { SuccessInfoDialog } from 'components';
import { useDialog } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store';
import { getUser, selectUser } from 'store/user';
import { AcceptPaymentStatusEnum, ApiUser } from 'types';

import styles from './StripeReturnPage.module.scss';

type ActionData = {
  title: string;
  buttonText: string;
  bodyText: string;
  bodyTextBottom: string | null;
  success: boolean;
};

export function StripeReturnPage() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { showDialog } = useDialog();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user: ApiUser | null = useAppSelector(selectUser);

  const postChackputStarted: MutableRefObject<boolean> = useRef<boolean>(false);

  const { search } = useLocation();
  const type: string | null = new URLSearchParams(search).get('type');
  const redirectTo: string | null = new URLSearchParams(search).get('redirectTo');
  const redirectToHash: string | null = new URLSearchParams(search).get('redirectToHash');
  const navigateTo = `/${redirectTo ?? ''}${redirectToHash ? '#' + redirectToHash! : ''}`;

  const actionData: ActionData = useMemo(() => {
    switch (type) {
      case STRIPE_ACCOUNT_ONBORDING_COMPLEATED:
        return {
          title:
            user?.acceptPaymentStatus === AcceptPaymentStatusEnum.Connected
              ? 'Accept Payments Activated!'
              : 'Accept Payments Is Pending',
          bodyText:
            user?.acceptPaymentStatus === AcceptPaymentStatusEnum.Connected
              ? 'You can now request payments from your clients in the queue or during a call.'
              : 'Stripe may need more information to complete their onboarding process.',
          bodyTextBottom:
            user?.acceptPaymentStatus === AcceptPaymentStatusEnum.Connected
              ? 'Don’t forget, you can allow Staff members to accept payments too!'
              : 'Click Reconnect to try again.',
          buttonText: 'Close',
          success: user?.acceptPaymentStatus === AcceptPaymentStatusEnum.Connected,
        };
      case STRIPE_ACCOUNT_ONBORDING_REJECTED:
      default:
        return {
          title: 'Account onbording rejected!',
          bodyText: 'Onbording not finished',
          buttonText: 'Go To Settings',
          bodyTextBottom: null,
          success: false,
        };
    }
  }, [type, user]);

  useEffect(() => {
    if (!user || postChackputStarted.current) {
      return;
    }

    postChackputStarted.current = true;

    dispatch(getUser())
      .unwrap()
      .then(() => {
        if (isMobile) {
          return;
        }

        const modal = showDialog(SuccessInfoDialog, {
          buttonText: actionData.buttonText,
          title: actionData.title,
          hideIcon: !actionData.success,
          body: (
            <>
              <Typography component='p' variant='body5'>
                {actionData.bodyText}
              </Typography>
              <Typography component='p' variant='body5'>
                {actionData.bodyTextBottom ?? 'Thank you for choosing HIPAA LINK.'}
              </Typography>
            </>
          ),
          onConfirm: () => {
            modal.destroy();
          },
        });
        navigate(navigateTo);
      });
  }, [user, actionData]);

  const handleGetStarted = (): void => {
    navigate(navigateTo);
  };

  if (!isMobile) return null;

  return (
    <Box className={styles.container}>
      <CheckCircleIcon className={styles.icon} color='primary' />

      <Typography component='p' variant='body4'>
        {actionData.title}
      </Typography>

      <Typography component='p' variant='body2'>
        {actionData.bodyText}
      </Typography>

      <Typography component='p' variant='body2'>
        {actionData.bodyTextBottom ?? 'Thank you for choosing HIPAA LINK.'}
      </Typography>

      <Button variant='contained' onClick={handleGetStarted} className={styles.formButton}>
        {actionData.buttonText}
      </Button>
    </Box>
  );
}
