import { createContext } from 'react';
import { RegisterRequest, UpdateClinicUserRequest, UpdateUserRequest } from 'types';

export interface IRegistrationContext {
  stage: number;
  isCoupon: boolean;
  loading: boolean;
  setCoupon(promocode: string): void;
  onRegisterFormSubmit(model: RegisterRequest): Promise<void>;
  onCreateRoomFormSubmit(
    profilePicture: File | null,
    user: UpdateUserRequest,
    clinicUser: UpdateClinicUserRequest,
  ): Promise<void>;
  onCreateRoomFormSkip(): Promise<void>;
  onCreateRoomFormBack(): void;
  onUpdateSubscription(planCode: string, quantity: number, coupon: string): Promise<void>;
  onLeaveOnTrial(): void;
  // onVerifyPhoneFormSubmit(phone: string, code: string): Promise<void>;
  // onVerifyPhoneFormSendCode(phone: string, twoFactor: TwoFactor): Promise<void>;
}

export const RegistrationContext = createContext<IRegistrationContext | null>(null);
export const RegistrationConsumer = RegistrationContext.Consumer;
